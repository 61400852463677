import React, { useEffect } from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button/Button';
import { v2_custom_reports_path } from '@utils/routes';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomReportProps } from '../Report/CustomReport';
import { CustomReportingGlobalQuery, CustomReportingQuery, ReportMetricData } from '../types';
import styles from './custom-reporting-filters.module.scss';
import { useBookOptions, useBuyersOptions, useContractsBasedOptions, useContractsBasedStores } from './hooks';
import { FOCUS_OPTIONS, DATE_SPLIT_OPTIONS, SPLIT_BY_OPTIONS } from './options';
import { CustomReportingFiltersType } from './types';

const CustomReportingFilters = ({
  onReportGenerate,
}: {
  onReportGenerate: (reportData: CustomReportProps) => void;
}) => {
  const { control, unregister, handleSubmit, watch, getValues, formState } = useForm<CustomReportingFiltersType>({
    shouldUnregister: true,
    defaultValues: {
      focus: 'contract_type',
      date_split: 'full',
      dates: [dayjs().startOf('year').toDate(), dayjs().startOf('year').add(1, 'year').toDate()],
    },
  });

  const { data: booksOptions, isLoading: bookOptionsAreLoading } = useBookOptions();
  const { data: contractsBasedStores, isLoading: ContractsBasedOptionsAreLoading } = useContractsBasedStores();
  const {
    contracts: contractOptions,
    installations: installationsOptions,
    countries: countriesOptions,
  } = useContractsBasedOptions(contractsBasedStores);
  const { data: buyersOptions, isLoading: buyersOptionsAreLoading } = useBuyersOptions();

  const onSubmit: SubmitHandler<CustomReportingFiltersType> = async ({ dates, ...formData }) => {
    const start_date = dates[0];
    const end_date = dates[1];
    const filters = Object.entries(formData.filters).reduce<Partial<CustomReportingQuery['filters']>>(
      (obj, [key, value]) => (value?.length ? { ...obj, [key]: value } : obj),
      {},
    );
    const url = v2_custom_reports_path({ ...formData, filters, start_date, end_date });
    const response = await fetch(url);
    const data: ReportMetricData[] = await response.json();

    onReportGenerate({
      data,
      query: {
        focus: formData.focus,
        split_by: formData.split_by,
        date_split: formData.date_split,
      } as CustomReportingGlobalQuery,
    });
  };

  useEffect(() => {
    const subscription = watch(({ focus }, { name }) => {
      if (name !== 'focus') return;

      if (focus === 'buyer_name') unregister(['filters.contract_types', 'split_by']);
      if (focus === 'contract_type') unregister(['filters.buyer_names', 'split_by']);
    });

    return () => subscription.unsubscribe();
  }, [unregister, watch]);

  const contractsFilter = (
    <ControlledSelect
      isMulti
      isLoading={ContractsBasedOptionsAreLoading}
      name="filters.contract_types"
      control={control}
      options={contractOptions}
      placeholder="common.filters.contract_type"
      label="common.filters.contract_type"
      className={styles.select}
    />
  );

  const buyersFilter = (
    <ControlledSelect
      isMulti
      isLoading={buyersOptionsAreLoading}
      name="filters.buyer_names"
      control={control}
      options={buyersOptions || []}
      placeholder="reporting.buyer_name"
      label="reporting.buyer_name"
      className={styles.select}
    />
  );
  const focusValue = getValues('focus');

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={classNames(styles.wrapper, 'd-flex align-items-end')}>
        <ControlledSelect
          name="focus"
          control={control}
          options={FOCUS_OPTIONS}
          placeholder="common.focus"
          label="common.focus"
          className={styles.select}
        />
        {focusValue === 'contract_type' && contractsFilter}
        {focusValue === 'buyer_name' && buyersFilter}
        <ControlledSelect
          options={countriesOptions}
          isCountry
          control={control}
          isMulti
          name="filters.countries"
          label="common.country"
          className={styles.select}
        />
        <ControlledSelect
          isMulti
          isLoading={bookOptionsAreLoading}
          name="filters.book_uuids"
          control={control}
          options={booksOptions || []}
          placeholder="common.book"
          label="common.book"
          className={styles.select}
        />
        <ControlledSelect
          isMulti
          isLoading={ContractsBasedOptionsAreLoading}
          name="filters.installation_uuids"
          control={control}
          options={installationsOptions}
          placeholder="common.filters.installation_name"
          label="common.filters.installation_name"
          className={styles.select}
        />
        <ControlledSelect
          isClearable
          name="split_by"
          control={control}
          options={SPLIT_BY_OPTIONS.filter(({ value }) => value !== focusValue)}
          placeholder="common.split_by"
          label="common.split_by"
          className={styles.select}
        />
        <ControlledRadioButtons
          name="date_split"
          control={control}
          options={DATE_SPLIT_OPTIONS}
          label="common.granularity"
        />
        <ControlledDatePicker
          name="dates"
          control={control}
          picks="months"
          selectsRange
          label="common.period"
          className={styles.datepicker}
        />
        <Button
          className={styles.generate}
          isLoading={formState.isSubmitting}
          variant="primary-2"
          size="xs"
          text="common.generate"
          type="submit"
          disabled={formState.isSubmitting}
        />
      </div>
    </form>
  );
};

export default CustomReportingFilters;
