import React from 'react';
import styles from './contract-number-cell.module.scss';

export const ContractNumberCell = ({ contractNumber }: { contractNumber: string }) => {
  return (
    <div className={styles['contract-number-cell']}>
      <span>N°</span> : {contractNumber}
    </div>
  );
};
