import fetchWrapper from '@utils/fetch';
import { formatSystemDate } from '@utils/formatters';
import { v2_exchange_rates_path } from '@utils/routes';
import type { Currency } from '@utils/types/currency';

export type ExchangeRate = {
  source_currency: Currency;
  destination_currency: Currency;
  rate: number | null;
  start_date: string;
  end_date: string;
  source: string | null;
  user_defined: boolean;
};

export const getExchangeRates = async (startDate: Date, endDate: Date, currency: Currency): Promise<ExchangeRate[]> => {
  const response = await fetchWrapper(
    v2_exchange_rates_path({
      params: {
        start_date: formatSystemDate(startDate),
        end_date: formatSystemDate(endDate),
        list_of_currencies: [currency],
      },
    }),
  );
  const data = await response.json();

  return data;
};
