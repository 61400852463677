import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useDynamicOptions } from '@GDM/Filters';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

const getOptions = (invoices: MeterInvoice[]): Option<MeterInvoice['installation_name']>[] => {
  const installationNames = Array.from(new Set(invoices.map((invoice) => invoice.installation_name)));

  return installationNames.map((name) => ({ value: name, label: name })).sort(sortOptionsByLabelAsc);
};

export const InstallationFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'installation_name', filteredInvoices, allInvoices);

  return (
    <ControlledSelect
      control={form.control}
      name="installation_name"
      full
      classNamePrefix="filter-installation-name"
      placeholder="common.filters.installation_name"
      options={options}
      isMulti
      inline
    />
  );
};
