import React from 'react';
import { ContractType } from '@utils/types/contract';

export type ViewOption = { chart: string; table: string };
export type FormatOption = { monthly: string; cumulated: string };

export type ContextType<TView, TFormat> = {
  contracts: {
    selected: ContractType | '';

    select: (contract: ContractType | '') => void;
  };
  view: {
    selected: keyof TView;
    options: TView;

    select: (view: keyof TView) => void;
  };
  format: {
    selected: keyof TFormat;
    options: TFormat;

    select: (view: keyof TFormat) => void;
  };
};

const filtersContext = React.createContext<ContextType<ViewOption, FormatOption>>({
  contracts: {
    selected: '',
    select: () => {},
  },
  view: {
    options: { chart: '', table: '' },
    selected: 'chart',
    select: () => {},
  },
  format: {
    options: { monthly: '', cumulated: '' },
    selected: 'monthly',
    select: () => {},
  },
});

export default filtersContext;
