import { MeterInvoice } from '@utils/types/meter-invoice';
import { InvoiceFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

export default function matchInvoiceSources(meterInvoice: MeterInvoice, { sources }: InvoiceFilters): boolean {
  if (!sources.length) {
    return true;
  }

  const match = sources.some((source) => {
    if (!source) {
      return true;
    }

    if (source === 'index') {
      return meterInvoice.volume_source === 'index';
    } else {
      return meterInvoice.volume_production_load_curve_source === source && meterInvoice.volume_source !== 'index';
    }
  });

  return match;
}
