import FrenchLocale from 'date-fns/locale/fr';
import * as es from './es';
import * as fr from './fr';
import * as it from './it';
import { DateLocale, LocaleTypes } from './types';

export const LOCALES: Record<LocaleTypes, DateLocale> = {
  fr: {
    code: 'fr',
    localize: {
      ...(FrenchLocale.localize as NonNullable<typeof FrenchLocale.localize>),
      day: (n) => fr.days[Number(n)],
      month: (n) => fr.shortMonths[Number(n)],
    },
    match: FrenchLocale.match,
    formatLong: FrenchLocale.formatLong,
  },
  es: {
    code: 'es',
    localize: {
      ...(FrenchLocale.localize as NonNullable<typeof FrenchLocale.localize>),
      day: (n) => es.days[Number(n)],
      month: (n) => es.shortMonths[Number(n)],
    },
    match: FrenchLocale.match,
    formatLong: FrenchLocale.formatLong,
  },
  it: {
    code: 'it',
    localize: {
      ...(FrenchLocale.localize as NonNullable<typeof FrenchLocale.localize>),
      day: (n) => it.days[Number(n)],
      month: (n) => it.shortMonths[Number(n)],
    },
    match: FrenchLocale.match,
    formatLong: FrenchLocale.formatLong,
  },
};
