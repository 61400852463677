import { MandateFormType } from '@context/AdMeterForm.context';
import { MandateBody } from '../types';

export const formatUpsertMandateBody = (form: MandateFormType) => {
  const data: MandateBody = {
    mandate: {
      mandataire: form.signatoryName,
      signatory_name: form.signatoryName,
      signatory_email: form.signatoryEmail,
      sirets: form.sirets
        .split(',')
        .flatMap((s) => s.split('\n'))
        .filter((s) => s !== '')
        .map((s) => s.replace(/^\s+|\s+$/g, '')),
    },
  };

  if (form.mandateId && form.mandateId !== 'select_mandate' && form.mandateId !== 'new_mandate') {
    data.mandate.uuid = form.mandateId;
  }
  if (form.signType === 'online') data.online_sign = true;

  return data;
};

export const formatUploadBody = (form: Partial<MandateFormType>) => {
  const formData = new FormData();
  if (form.mandateId && form.mandateId !== 'select_mandate' && form.mandateId !== 'new_mandate') {
    if (form.mandateFile) formData.append('file', form.mandateFile);
    formData.append('mandate_id', form.mandateId);
  }

  return formData;
};
