import React, { useEffect, useState, useContext, useCallback } from 'react';
import { InvoiceFilters } from '@components/invoices/InvoiceFilters';
import { Restricted } from '@components/Restricted';
import { selectInvoicesContext } from '@context/selectInvoices.context';
import { DatePickerProps } from '@GDM/DatePicker';
import { VisibilityState } from '@tanstack/react-table';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import HistoryModal from './HistoryModal';
import styles from './invoices.module.scss';
import { InvoicesConsumer, InvoicesProvider } from './invoicesContext';
import { InvoicesFiltersProvider } from './invoicesFiltersContext';
import InvoicesTable, { getVisibilityState } from './InvoicesTable';
import { InvoiceTableProps } from './InvoicesTable/InvoicesTable';
import { NominateModal } from './NominateModal';
import { SendInvoiceModal } from './SendInvoiceModal';
import { SwapMeterModalProvider } from './SwapMeterModal/SwapMeterModalProvider';
import { UpdateModal } from './UpdateModal';
import { InvoicesNumberingProvider } from './utils/useInvoiceNumbering';

const InvoicePageWrapper = ({
  invoices,
  invoiceType,
  isLoading,
  startDate,
  endDate,
  yearlyDate,
  isLight,
  sortByDefault,
  hideBookSelect,
  isDateRange,
  onDateChange,
  picks,
  direction,
}: {
  invoices: MeterInvoice[];
  invoiceType: MeterInvoiceSource;
  isLoading: boolean;
  isLight?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  yearlyDate?: boolean;
  sortByDefault?: InvoiceTableProps['sortByDefault'];
  hideBookSelect?: boolean;
  isDateRange?: boolean;
  onDateChange?: (startDate: Date | null, endDate?: Date | null) => void;
  picks?: DatePickerProps['picks'];
  direction: Contract['direction'] | 'all';
}) => {
  const { unselectAllInvoices } = useContext(selectInvoicesContext);
  const [clickedInvoice, setClickedInvoice] = useState<MeterInvoice | null>(null);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isNominateEdfOaModalOpen, setIsNominateEdfOaModalOpen] = useState(false);
  const [isNominateCegedimModalOpen, setIsNominateCegedimModalOpen] = useState(false);
  const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(getVisibilityState(isTableExpanded));

  const toggleColumnVisibility = useCallback((state: boolean) => {
    setColumnVisibility(getVisibilityState(state));
    setIsTableExpanded(state);
  }, []);

  const toggleUpdateModal = useCallback(
    () =>
      setIsUpdateModalOpen((prevOpen) => {
        if (prevOpen) {
          setClickedInvoice(null);
        }

        return !prevOpen;
      }),
    [],
  );
  const toggleSendModal = useCallback((value?: boolean) => {
    setIsSendModalOpen((prevIsSendModalOpen) => (typeof value === 'boolean' ? value : !prevIsSendModalOpen));
  }, []);
  const toggleNominateEdfOaModal = useCallback(
    () => setIsNominateEdfOaModalOpen((prevIsNominateModalOpen) => !prevIsNominateModalOpen),
    [],
  );
  const toggleNominateCegedimModal = useCallback(
    () => setIsNominateCegedimModalOpen((prevIsNominateModalOpen) => !prevIsNominateModalOpen),
    [],
  );

  useEffect(() => {
    unselectAllInvoices();
  }, [startDate, unselectAllInvoices]);

  return (
    <InvoicesFiltersProvider>
      <InvoicesProvider invoices={invoices} key={invoices?.map((i) => i.uuid).join(',') || null}>
        {/* MODALS */}
        {historyModalIsOpen && clickedInvoice && (
          <HistoryModal
            isOpen={historyModalIsOpen}
            setIsOpen={setHistoryModalIsOpen}
            clickedInvoice={clickedInvoice}
            invoiceType={invoiceType}
            Table={InvoicesTable}
            direction={direction}
          />
        )}
        <InvoicesNumberingProvider invoiceType={invoiceType} direction={direction}>
          <SwapMeterModalProvider invoices={invoices}>
            <SendInvoiceModal isOpen={isSendModalOpen} toggle={toggleSendModal} />
            <NominateModal isOpen={isNominateCegedimModalOpen} toggle={toggleNominateCegedimModal} source="cegedim" />
            <NominateModal isOpen={isNominateEdfOaModalOpen} toggle={toggleNominateEdfOaModal} source="edf_oa" />

            <Restricted permissions={compact([invoiceType != 'index' && 'display:edit_invoices'])}>
              <UpdateModal
                isOpen={isUpdateModalOpen}
                toggle={toggleUpdateModal}
                invoice={clickedInvoice}
                key={clickedInvoice?.uuid}
                direction={direction}
              />
            </Restricted>

            <div data-cy={`invoice-${invoiceType}-tab`} className={styles['invoice-tab']}>
              <InvoiceFilters
                isLight={!!isLight}
                isDateRange={isDateRange}
                startDate={startDate}
                endDate={endDate}
                onDateChange={onDateChange}
                hideBookSelect={hideBookSelect}
                invoiceType={invoiceType}
                yearlyDate={yearlyDate}
                picks={picks || (invoiceType === 'index' || invoiceType === 'rec' ? 'months' : 'default')}
                direction={direction}
              />
              <InvoicesConsumer>
                {({ filteredInvoices }) => (
                  <InvoicesTable
                    direction={direction}
                    invoices={filteredInvoices}
                    loading={isLoading}
                    invoiceType={invoiceType}
                    isTableExpanded={isTableExpanded}
                    setClickedInvoice={setClickedInvoice}
                    setHistoryModalIsOpen={setHistoryModalIsOpen}
                    setIsUpdateModalOpen={setIsUpdateModalOpen}
                    columnVisibility={direction === 'buy' ? undefined : columnVisibility}
                    setIsTableExpanded={toggleColumnVisibility}
                    startDate={startDate}
                    endDate={isDateRange ? endDate : dayjs(startDate).add(1, 'month').toDate()}
                    toggleEmailModal={() => toggleSendModal(true)}
                    toggleNominateEdfOaModal={toggleNominateEdfOaModal}
                    toggleNominateCegedimModal={toggleNominateCegedimModal}
                    sortByDefault={sortByDefault}
                    showActions
                  />
                )}
              </InvoicesConsumer>
            </div>
          </SwapMeterModalProvider>
        </InvoicesNumberingProvider>
      </InvoicesProvider>
    </InvoicesFiltersProvider>
  );
};

export default InvoicePageWrapper;
