import React from 'react';
import { useSelectedGrid } from '@pages/Installations/Installation/context/useSelectedGrid.context';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';
import { IpCheckbox } from '../../fields/IpCheckbox';
import { BaseFields } from './BaseFields';
import { DefaultSubSection } from './DefaultSubSection';

export const DirectConnection = ({ children }: { children?: React.ReactNode }) => {
  const { control } = useInstallationForm();
  const { selectedGrid } = useSelectedGrid();

  const isMoxa = selectedGrid?.name === 'MOXA';
  const DEFAULT_TRUE_FOR_GRIDS = ['MOXA'];

  return (
    <>
      <Controller
        control={control}
        name="via_direct_connection"
        defaultValue={DEFAULT_TRUE_FOR_GRIDS.includes(selectedGrid?.name || '') || false}
        render={({ field }) => (
          <DefaultSubSection
            title="admin.installations.via_direct_connection"
            isOpen={field.value}
            onChange={isMoxa ? undefined : field.onChange}
            name="direct_connection"
          >
            <IpCheckbox />

            <BaseFields />

            {children}
          </DefaultSubSection>
        )}
      />
    </>
  );
};
