import React from 'react';
import { Input } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { getImageTooltip } from '../../utils/getImageTooltip';
import { SimulationForm } from '../../utils/simulations.types';

export const OrientationInput = () => {
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('orientation');
  const { control, watch } = useFormContext<SimulationForm>();
  const tracker = watch('tracker');
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="orientation"
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error ? 'pricing.form.errors.orientation' : null}
          suffix="pricing.project.in_degree"
          type="number"
          step="1"
          full
          size="lg"
          value={field.value ?? ''}
          tooltip={
            tracker
              ? `${t('pricing.project.tracker_tooltip')} <br> ${getImageTooltip('/img/axe_tracker.png')}`
              : `${t('pricing.project.orientation_tooltip')} <br> ${getImageTooltip('/img/orientation.jpg')}`
          }
          label={tracker ? 'pricing.project.tracker_axis' : 'pricing.project.orientation'}
        />
      )}
    />
  );
};
