import React from 'react';
import { Input } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { Controller, useFormContext } from 'react-hook-form';
import { OwnerFiltersType } from './OwnerFilters';

export const OwnerErpFilter = ({ onChange }: { onChange: (params: OwnerFiltersType) => void }) => {
  const { t } = useTranslation();
  const formContext = useFormContext<OwnerFiltersType>();

  return (
    <Controller
      name="erp_code"
      control={formContext.control}
      render={({ field }) => (
        <Input
          value={field.value}
          placeholder={t('owner.erp_code')}
          data-cy="owner-filter-erp-code"
          full
          icon="Search"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            onChange({ ...formContext.getValues(), erp_code: e.currentTarget.value });
            field.onChange(e.currentTarget.value);
          }}
        />
      )}
    />
  );
};
