import React from 'react';
import { Section, sectionStyles } from '@GDM/forms';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { SectionHeader } from '../../components/SectionHeader';
import { AllocationRound } from './components/AllocationRound';
import { CalculationBase } from './components/CalculationBase';
import { CfdLccd } from './components/CfdLccd';
import { CrInvoicingType } from './components/CrInvoicingType';
import { ParticipatoryFinancing } from './components/ParticipatoryFinancing';
import { PowerAugmentation } from './components/PowerAugmentation';
import { SchemeAndSubType } from './components/SchemeAndSubType';

export const ContractParams = ({
  title,
  readOnly,
  form: { control, watch },
  globals: { installation },
}: ContractFormSectionProps) => {
  const [power_augmentation, startDate, endDate, contractType] = watch([
    'power_augmentation',
    'start_date',
    'end_date',
    'type',
  ]);
  const energyType = installation?.energy;

  const contractsWithSchemesAndSubTypes = ['ContractCrEdf', 'ContractOa'];
  const hasSchemeAndSubType = contractsWithSchemesAndSubTypes.includes(contractType || '');

  const schemeAndSubType = hasSchemeAndSubType && (
    <SchemeAndSubType control={control} readOnly={readOnly} contractType={contractType} energyType={energyType} />
  );

  const contractsWithCalculationBase = ['ContractOa'];
  const hasCalculationBase = contractsWithCalculationBase.includes(contractType || '');
  const calcBase = watch('calc_base');
  const calculationBase = hasCalculationBase && (
    <CalculationBase control={control} readOnly={readOnly} calcBase={calcBase} />
  );

  const contractsWithParticipatoryFinancing = ['ContractCrEdf'];
  const hasParticipatoryFinancing = contractsWithParticipatoryFinancing.includes(contractType || '');
  const participatoryFinancing = hasParticipatoryFinancing && (
    <ParticipatoryFinancing control={control} readOnly={readOnly} />
  );

  const contractsWithCrInvoicingType = ['ContractCr'];
  const hasCrInvoicingType = contractsWithCrInvoicingType.includes(contractType || '');
  const crInvoicingType = hasCrInvoicingType && <CrInvoicingType control={control} readOnly={readOnly} />;

  const contractsWithPowerAugmentation = ['ContractOa'];
  const hasPowerAugmentation = contractsWithPowerAugmentation.includes(contractType || '');
  const powerAugmentation = hasPowerAugmentation && calcBase === 'index' && (
    <PowerAugmentation
      control={control}
      readOnly={readOnly}
      isActive={Boolean(power_augmentation)}
      startDate={startDate}
      endDate={endDate}
    />
  );

  const contractsWithCfdLccd = ['ContractSwapUk'];
  const hasCfdLccd = contractsWithCfdLccd.includes(contractType || '');
  const cfDLccd = hasCfdLccd && <CfdLccd control={control} readOnly={readOnly} />;

  const contractsWithAllocationRound = ['ContractSwapUk'];
  const hasAllocationRound = contractsWithAllocationRound.includes(contractType || '');
  const allocationRound = hasAllocationRound && <AllocationRound control={control} readOnly={readOnly} />;

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section>
          <div className="p-3">
            {schemeAndSubType}
            {calculationBase}
            {participatoryFinancing}
            {crInvoicingType}
            {(hasCfdLccd || hasAllocationRound) && (
              <div className={sectionStyles.row}>
                {cfDLccd}
                {allocationRound}
              </div>
            )}
          </div>
          {powerAugmentation}
        </Section>
      }
    />
  );
};
