import React, { useState } from 'react';
import { DefinitionModalBody } from '@GDM/DefinitionList/modal/DefinitionModalBody';
import { DefinitionModalFooter } from '@GDM/DefinitionList/modal/DefinitionModalFooter';
import { Select } from '@GDM/forms/Select/Select';
import { useMutation } from '@tanstack/react-query';
import fetchWrapper from '@utils/fetch';
import { change_grid_connection_type_v2_dashboard_meter_index_path } from '@utils/routes';
import { Option } from '@utils/types/common-types';
import Installation from '@utils/types/installation';

type GridConnectionModalProps = {
  installationName: string;
  gridConnectionType: Installation['grid_connection_type'] | undefined;
  setGridConnectionType: (gridConnectionType: Installation['grid_connection_type']) => void;
};

export const GridConnectionModal = ({
  installationName,
  gridConnectionType,
  setGridConnectionType,
}: GridConnectionModalProps) => {
  const [localGridConnection, setLocalGridConnection] = useState(gridConnectionType);

  const mutation = useMutation({
    mutationFn: ({
      installationName,
      gridConnectionType,
    }: Pick<GridConnectionModalProps, 'gridConnectionType' | 'installationName'>) => {
      return fetchWrapper(change_grid_connection_type_v2_dashboard_meter_index_path(), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ meter: installationName, grid_connection_type: gridConnectionType }),
      });
    },
    onSuccess: () => {
      if (!localGridConnection) return;
      setGridConnectionType(localGridConnection);
    },
  });

  return (
    <>
      <DefinitionModalBody>
        <Select
          options={GRID_CONNECTION_OPTIONS}
          selectedOption={localGridConnection}
          isSearchable
          onChange={(option) => setLocalGridConnection(option?.value)}
        />
      </DefinitionModalBody>
      <DefinitionModalFooter
        save={() => {
          if (!localGridConnection) return;
          mutation.mutate({ installationName, gridConnectionType: localGridConnection });
        }}
        isLoading={mutation.isPending}
        isDisabled={!installationName || !localGridConnection}
      />
    </>
  );
};

const GRID_CONNECTION_OPTIONS: Option<Installation['grid_connection_type']>[] = [
  { label: 'common.high_voltage', value: 'high_voltage' },
  { label: 'common.low_voltage_above_36kVA', value: 'low_voltage_above_36kVA' },
  { label: 'common.low_voltage_under_36kVA', value: 'low_voltage_under_36kVA' },
];
