import type { FieldValues } from 'react-hook-form';
import type { FormField } from '../form.types';

export const getDefaultValues = <T extends FieldValues>(commonFields: FormField<T>[]): Partial<T> => {
  const newDefaultValues: Partial<T> = commonFields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: field.defaultValue,
    }),
    {},
  );

  return newDefaultValues;
};
