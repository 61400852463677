import React from 'react';
import { Icon } from '@GDM/Icon';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import range from 'lodash/range';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { chevron, header, select, next } from './date-picker.module.scss';

interface HeaderProps extends ReactDatePickerCustomHeaderProps {
  maxDate?: Date | null;
  minDate?: Date | null;
  yearOnly?: boolean;
  locale?: string;
  'data-cy'?: string;
}

/**
 * Generates a list of month names based on the locale.
 *
 * TODO: consider moving to a utils file.
 */
const getLocalizedMonthList = (locale = 'en'): string[] => {
  const year = new Date().getFullYear();
  const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const formatter = new Intl.DateTimeFormat([locale || 'en', 'en'], { month: 'long' });

  const getMonthName = (monthIndex: number) => capitalize(formatter.format(new Date(year, monthIndex)));

  return monthList.map(getMonthName);
};

export const DatePickerHeader = ({
  maxDate,
  minDate,
  locale,
  yearOnly,
  'data-cy': dataCy,
  ...datePickerProps
}: HeaderProps) => {
  const { date, decreaseMonth, increaseMonth, decreaseYear, increaseYear, changeYear, changeMonth } = datePickerProps;

  minDate = minDate || new Date('2000-01-01');
  maxDate = maxDate || new Date(new Date().setFullYear(new Date().getFullYear() + 25));

  const minYear = minDate.getFullYear();
  const maxYear = maxDate.getFullYear();

  const months = getLocalizedMonthList(locale);

  const decreaseDisabled = yearOnly ? date.getFullYear() <= minYear : dayjs(date).startOf('month').toDate() <= minDate;
  const increaseDisabled = yearOnly ? date.getFullYear() >= maxYear : dayjs(date).endOf('month').toDate() >= maxDate;

  const years = range(minYear, maxYear + 1).reverse();

  const selectedYear =
    date.getFullYear() <= maxDate.getFullYear() && date.getFullYear() >= minDate.getFullYear()
      ? date.getFullYear()
      : '';

  return (
    <div className={`${header} mb-2`}>
      <button
        disabled={decreaseDisabled}
        className={`${chevron} ml-2`}
        data-cy="prev-month"
        onClick={yearOnly ? decreaseYear : decreaseMonth}
        type="button"
      >
        <Icon name="ChevronLeft" size={20} />
      </button>

      {!yearOnly && (
        <select
          className={select}
          value={months[date.getMonth()]}
          onChange={(e) => changeMonth(months.indexOf(e.target.value))}
          data-cy={`${dataCy}-month-picker`}
        >
          {months.map((month) => (
            <option value={month} key={month}>
              {month}
            </option>
          ))}
        </select>
      )}

      <select
        className={select}
        value={selectedYear}
        onChange={(e) => changeYear(Number(e.target.value) || maxYear)}
        data-cy={`${dataCy}-year-picker`}
      >
        <option>-</option>
        {years.map((year) => (
          <option value={year} key={year}>
            {year}
          </option>
        ))}
      </select>

      <button
        disabled={increaseDisabled}
        className={`${chevron} ${next} mr-2`}
        data-cy="next-month"
        onClick={yearOnly ? increaseYear : increaseMonth}
        type="button"
      >
        <Icon name="ChevronRight" size={20} />
      </button>
    </div>
  );
};
