import { v2_set_language_path } from '@utils/routes';
import { Locale } from '@utils/types/common-types';
import { useRequest, post } from '../useRequest';

const useSetLanguage = () => {
  const req = useRequest(v2_set_language_path(), post, true);

  const changeLanguage = (locale: Locale) => {
    req.execute?.({ language_locale: locale });
  };

  return { loading: req.loading, loaded: req.loaded, changeLanguage };
};

export default useSetLanguage;
