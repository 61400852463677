import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useCurrency } from '@utils/string';
import { CannibalizationChart } from './CannibalizationChart';

export const CannibalizationImpactDeviationFullMerchantChart = ({ percentage }: { percentage?: boolean }) => {
  const project = useProjectContext();
  const { t } = useTranslation();
  const {
    full_merchant_cannibalized_distribution_cat,
    full_merchant_cannibalized_distribution,
    full_merchant_abs_price_cannibalized_distribution,
  } = project?.full_merchant || {};
  const currency = useCurrency();

  if (
    !full_merchant_cannibalized_distribution ||
    !full_merchant_abs_price_cannibalized_distribution ||
    !full_merchant_cannibalized_distribution_cat
  )
    return null;

  const data = percentage ? full_merchant_cannibalized_distribution : full_merchant_abs_price_cannibalized_distribution;
  const title = percentage ? t('pricing.project.market_capture_factor') : t('pricing.project.capture_price');

  const categories = Object.values(full_merchant_cannibalized_distribution_cat).map((cat) => cat?.toString() || '');

  return (
    <CannibalizationChart
      q50={data.q50}
      q1090={data.q1090}
      q2575={data.q2575}
      title={title}
      categories={categories}
      valueSuffix={percentage ? '%' : ` ${currency}/MWh`}
    />
  );
};
