import React, { useState } from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { QfuForm } from '../QfuForm';
import { TanPhiForm } from '../TanPhiForm';
import { useReactiveForm } from '../useReactiveForm';
import styles from './instruction.module.scss';

export const Instruction = ({ index }: { index: number }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { watch } = useReactiveForm();
  const { t } = useTranslation();
  const { locale } = useUser();

  const isQfu = watch('type') === 'qfu';
  const isTanPhi = watch('type') === 'tan_phi';
  const instructions = watch('instructions');

  if ((!isQfu && !isTanPhi) || !instructions) return null;

  const toggle = () => setIsOpen((isOpen) => !isOpen);

  const convertDate = (date?: `${number}${number}-${number}${number}`): string => {
    // Even though the date should be interpreted correctly, I arbitrarily added the `2020-` to make sure.
    // The year doesn't appear so it doesn't matter which one it is.
    return new Intl.DateTimeFormat(locale, { month: 'short', day: '2-digit' }).format(new Date(`2020-${date}`));
  };

  let seasonName = `${t('common.period')} ${index + 1}`;
  if (instructions[index].start_month_day === '11-01' && instructions[index].end_month_day === '04-01')
    seasonName = t('common.winter');
  if (instructions[index].start_month_day === '04-01' && instructions[index].end_month_day === '11-01')
    seasonName = t('common.summer');

  return (
    <div>
      {instructions.length > 1 && (
        <div className={classNames(styles.header, 'mt-3', 'pt-3')}>
          <span>
            <b>{seasonName}</b> &ndash; {convertDate(instructions[index].start_month_day)} /{' '}
            {convertDate(instructions[index].end_month_day)}
          </span>

          <Button
            tooltip={isOpen ? 'common.close' : 'common.show'}
            className="ml-1"
            icon={isOpen ? 'ChevronsUp' : 'ChevronsDown'}
            floating
            onClick={toggle}
          />
        </div>
      )}
      <div className={classNames(styles.content, isOpen && styles['is-open'])}>
        {isQfu && <QfuForm index={index} />}
        {isTanPhi && <TanPhiForm index={index} />}
      </div>
    </div>
  );
};
