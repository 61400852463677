import React, { useMemo, useState } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { ColumnDef, getPaginationRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { IndexData } from '@utils/types/installation_index';
import { Line } from '../index-tab.definitions';
import { indexTableColumns } from './indexTableColumns';

export const IndexTable = ({ indexData, periods }: { indexData: IndexData; periods: string[] }) => {
  const columns: ColumnDef<Line>[] = useMemo(() => indexTableColumns(periods), [periods]);
  const data: Line[] = useMemo(() => {
    const _data: Line[] = [];

    indexData.forEach((point) => {
      const line: Line = {
        date: point[0] * 1000,
      };

      periods.forEach((period, index) => {
        line[period] = point[index + 1];
      });

      _data.push(line);
    });

    return _data;
  }, [indexData, periods]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const table = useReactTable({
    columns,
    data,
    state: { pagination },
    enableSorting: false,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      <TableActions>
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};
