import React, { useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { AltContent } from '@GDM/AltContent';
import { Button } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Toggle } from '@GDM/Toggle';
import { useM0Data } from '@hooks/requests/m0';
import { Book } from '@utils/types/book';
import MarketPlayer from '@utils/types/market_player';
import { Meter } from '@utils/types/meter';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { M0Card, M0Plot, M0Table } from './content';
import { csvExport } from './csvExport';
import { DateSelector } from './header/DateSelector';
import { EnergySelector } from './header/EnergySelector';
import { m0DeviationContext } from './m0-deviation.context';
import styles from './m0-deviation.module.scss';
import { M0DeviationContextType } from './m0-deviation.types';

type Props = {
  book?: Book | null;
  meter?: Meter | null;
  marketPlayer?: MarketPlayer | null;
};

export const M0Deviation = ({ meter, book, marketPlayer }: Props) => {
  const user = useUser();

  const [energy, setEnergy] = useState<M0DeviationContextType['energy']>(null);
  const [startDate, setStartDate] = useState<M0DeviationContextType['startDate']>(
    marketPlayer
      ? dayjs().startOf('month').subtract(2, 'month').toDate()
      : dayjs().startOf('month').subtract(1, 'year').toDate(),
  );
  const [endDate, setEndDate] = useState<M0DeviationContextType['endDate']>(
    dayjs().startOf('month').subtract(1, 'month').toDate(),
  );
  const [showChart, setShowChart] = useState<M0DeviationContextType['showChart']>(true);
  const [showTable, setShowTable] = useState<M0DeviationContextType['showTable']>(true);

  const source: 'installation' | 'book' | 'market_player' | null = meter
    ? 'installation'
    : book
    ? 'book'
    : marketPlayer
    ? 'market_player'
    : null;
  const identifier = meter?.name || book?.uuid || marketPlayer?.id || null;

  const { isLoading, data } = useM0Data({
    startDate,
    endDate,
    identifier,
    source,
    energy,
  });

  const tableData = useMemo(() => {
    const m0ByInstallation = data?.m0ByInstallation || {};

    if (book || marketPlayer)
      return (
        Object.values(m0ByInstallation)
          .flatMap((installationData) => Object.values(installationData))
          .filter((row) => row.date && row.date === 'TOTAL') ?? []
      );
    if (meter)
      return (
        Object.values(m0ByInstallation)
          .flatMap((installationData) => Object.values(installationData))
          .filter((row) => row.date && row.date !== 'TOTAL') ?? []
      );

    return [];
  }, [data, book, meter, marketPlayer]);

  const csvData = useMemo(
    () =>
      Object.values(data?.m0ByInstallation || {})
        .flatMap((installationData) => Object.values(installationData))
        .filter((row) => row.date && row.date !== 'TOTAL') ?? [],
    [data],
  );

  const currency = data?.m0Aggregated['TOTAL']?.currency || 'EUR';

  const hasData = Object.values(data?.m0Aggregated || {}).length > 1;
  const m0DeviationContextValue = useMemo<M0DeviationContextType>(() => {
    const context: M0DeviationContextType = {
      endDate,
      energy,
      m0ByInstallation: data?.m0ByInstallation || {},
      m0Aggregated: data?.m0Aggregated || {},
      tableData,
      csvData,
      setEnergy,
      setEndDate,
      setShowChart,
      setShowTable,
      setStartDate,
      showChart,
      showTable,
      source,
      startDate,
      currency,
    };

    return context;
  }, [endDate, energy, data, currency, csvData, setEnergy, showChart, showTable, source, startDate, tableData]);

  const handleCsvExportClick = () => {
    const name = meter?.name || book?.name || marketPlayer?.short_name;
    const fileName =
      `Streem_Market_Perf_${name}_` +
      `${dayjs(startDate).format('YYYY-MM')}_` +
      `${dayjs(endDate).format('YYYY-MM')}.csv`;

    fileDownload(
      csvExport(csvData, data?.m0Aggregated?.['TOTAL']?.currency || 'EUR', user),
      fileName,
      'text/csv;charset=utf-8',
      '\uFEFF',
    );
  };

  return (
    <m0DeviationContext.Provider value={m0DeviationContextValue}>
      <div className={styles['header-container']}>
        <div className={styles['filter-container']}>
          <DateSelector />
          {(book || marketPlayer) && <EnergySelector energies={book?.installation_energies || ['solar', 'wind']} />}
          <Toggle value={showTable} label="common.table" onChange={setShowTable} />
          <Toggle value={showChart} label="common.chart" onChange={setShowChart} />
        </div>

        {!user?.limited && (
          <Button
            icon="Download"
            text="common.download"
            variant="primary-2"
            size="xxs"
            disabled={isLoading || Object.values(data?.m0ByInstallation || {}).length == 0}
            onClick={handleCsvExportClick}
          />
        )}
      </div>

      {hasData && !isLoading && (
        <>
          <div className="mb-4">
            <M0Card />
          </div>
          {showChart && (
            <div className="mb-4">
              <M0Plot />
            </div>
          )}
          {showTable && (
            <div className="mb-4">
              <M0Table />
            </div>
          )}
        </>
      )}
      {!hasData && !isLoading && <AltContent />}
      {isLoading && <Spinner size="sm" />}
    </m0DeviationContext.Provider>
  );
};
