import { ContractForm, ContractSubPeriodFormBody } from '@utils/types/contract';
import { serializeProfileHedge } from './serializeProfileHedgeCap';
import { serializeSubPeriod } from './serializeSubPeriod';

/**
 * We check if the current sub-period `id` exists in the original `contract_sub_periods` object.
 * If it doesn't, it means the `id` was generated by the client and therefore shouldn't be sent to the server.
 * The backend will then create a new `contract_sub_period` object.
 *
 * We need to generate this `id` on client side, because it's the only way to identify a generated sub-period,
 * and React can render the list without side effects.
 *
 * We also need to delete this id because the backend will throw an error if it doesn't find an object with this id.
 */
export const serializeSubPeriods = (data: ContractForm): ContractSubPeriodFormBody[] => {
  const existingContractSubPeriods = new Map(
    data.contract_sub_periods?.map((sub_period) => [sub_period.id, sub_period]),
  );
  const formContractSubPeriods = new Map(
    data.contract_sub_periods_attributes?.map((sub_period) => [sub_period.id, sub_period]),
  );
  // const existingContractSubPeriodsIds = data.contract_sub_periods?.map((sub_period) => sub_period.id) || [];
  // const formContractSubPeriodsIds = data.contract_sub_periods_attributes?.map((sub_period) => sub_period.id) || [];

  // Explicitly destroy the non mentioned sub periods
  const contractSubPeriodsToDestroy: ContractSubPeriodFormBody[] = (data.contract_sub_periods || [])
    .filter((sub_period) => !formContractSubPeriods.has(sub_period.id))
    .map((sub_period) => {
      return serializeSubPeriod(sub_period, { _destroy: '1' });
    });

  const contractSubPeriodsToCreate: ContractSubPeriodFormBody[] = (data.contract_sub_periods_attributes || [])
    .filter((sub_period) => !existingContractSubPeriods.has(sub_period.id))
    .map((sub_period) => {
      return serializeSubPeriod(sub_period, {
        id: null,
      });
    });

  const contractSubPeriodsToUpdate: ContractSubPeriodFormBody[] = (data.contract_sub_periods_attributes || [])
    .filter((sub_period) => existingContractSubPeriods.has(sub_period.id))
    .map((sub_period) => {
      return serializeSubPeriod(sub_period, {
        profile_hedge_attributes: serializeProfileHedge(
          existingContractSubPeriods.get(sub_period.id)?.profile_hedge_attributes,
          sub_period.profile_hedge_attributes,
        ),
      });
    });

  const contract_sub_periods_attributes: ContractSubPeriodFormBody[] = [
    ...contractSubPeriodsToCreate,
    ...contractSubPeriodsToUpdate,
    ...contractSubPeriodsToDestroy,
  ];

  return contract_sub_periods_attributes;
};
