import type { EventType } from '@utils/types/event';
import classNames from 'classnames';
import { dangerLevels } from './getHighestDangerLevel';
import styles from './map.module.scss';

export const getColorClassNameForIcon = (eventTypes: EventType[]): string => {
  const level = Math.max(...eventTypes.map((eventType) => dangerLevels[eventType] || 0)) || 5;

  return classNames(styles[`danger-level-${level}`]);
};
