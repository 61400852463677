export const IT_PRICE_AREAS = ['IT_CALA', 'IT_CNOR', 'IT_CSUD', 'IT_NORD', 'IT_SARD', 'IT_SICI', 'IT_SUD'];
export const SPOT_SOURCES = {
  BE: ['eex'],
  DE: ['eex'],
  ES: ['eex'],
  FR: ['eex'],
  IT: ['gme', 'entsoe'],
  NL: ['eex'],
  PL: ['eex', 'tge_hourly'],
  PT: ['eex'],
  UK: ['epex', 'imrp', 'n2ex'],
  FI: ['n2ex'],
} as const;
