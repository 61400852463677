import { createContext } from 'react';

export type PortfolioTableFilters = {
  clientId: boolean;
  commissioningDate: boolean;
};

export type PortfolioTableContext = {
  infoFilters: PortfolioTableFilters;
  setInfoFilters: (filters: Partial<PortfolioTableFilters>) => void;
};

export const portfolioTableContext = createContext<PortfolioTableContext>({
  infoFilters: {
    clientId: false,
    commissioningDate: false,
  },
  setInfoFilters: () => {},
});
