import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Row } from '@tanstack/react-table';
import { formatNumber } from '@utils/formatters';
import { Simulation } from '@utils/types/simulation';
import round from 'lodash/round';

// compute the max q75 value
const computeQ75Max = (simulations: Simulation[], isFullMerchant?: boolean): number => {
  let amplitudeArray: number[] = [];

  if (isFullMerchant) {
    amplitudeArray = simulations.map((s) => s?.results?.local_capture_factors?.q75 || 0.9);
  } else {
    amplitudeArray = simulations.map((s) => s?.results?.deviation_factors?.q75 || 0.01);
  }
  if (amplitudeArray.length === 0) {
    return 0.01;
  }

  return Math.max(...amplitudeArray);
};

// compute the min q25 value
const computeQ25Min = (simulations: Simulation[], isFullMerchant?: boolean): number => {
  let q25Array: number[] = [];

  if (isFullMerchant) {
    q25Array = simulations.map((s) => s?.results?.local_capture_factors?.q25 || 0.75);
  } else {
    q25Array = simulations.map((s) => s?.results?.deviation_factors?.q25 || 0.01);
  }

  if (q25Array.length === 0) {
    return 0.01;
  }

  return Math.min(...q25Array);
};

const computeAmplitude = (q25Min: number, q75Max: number): number => {
  return Math.abs(q75Max - q25Min);
};

const numberOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

const ConfidenceCell = ({
  simulations,
  isFullMerchant,
  row,
}: {
  simulations: Simulation[];
  row: Row<Simulation>;
  isFullMerchant?: boolean;
}) => {
  const { locale } = useUser();
  const q25Min = useMemo(() => computeQ25Min(simulations, isFullMerchant), [simulations, isFullMerchant]);
  const q75Max = useMemo(() => computeQ75Max(simulations, isFullMerchant), [simulations, isFullMerchant]);
  const amplitude = useMemo(() => computeAmplitude(q25Min, q75Max), [q25Min, q75Max]);
  const deviation = isFullMerchant
    ? row.original?.results?.local_capture_factors
    : row.original?.results?.deviation_factors;

  if (deviation) {
    if (deviation.q50 || deviation.q50 === 0) {
      const color = deviation.q50 >= 0 ? 'primary-1' : 'secondary';
      const q75 = deviation.q75 ?? 0;
      const q25 = deviation.q25 ?? 0;
      const width = q75 > 0 && q25 <= 0 ? Math.abs(q25) + q75 : q75 - q25;
      const width_first_bar = ((q25 - q25Min) * 100) / amplitude > 55 ? 55 : ((q25 - q25Min) * 100) / amplitude;
      const real_width = (width * 100) / amplitude;
      const fake_width = real_width >= 45 ? real_width : 45;
      const diff_q25_q50 = deviation.q50 - q25;
      const percent_q50 = (diff_q25_q50 * 100) / width;
      const min = round(q25 * 100, 2);
      const max = round(q75 * 100, 2);

      if ((min || min === 0) && (max || max === 0)) {
        return (
          <div className="progress" style={{ backgroundColor: 'transparent' }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${width_first_bar}%`, background: 'none' }}
            />
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                position: 'relative',
                width: `${fake_width}%`,
                background: `var(--${color})`,
                borderRadius: '.25rem',
              }}
            >
              <div style={{ position: 'absolute', zIndex: 1, top: '50%', left: '2px', color: 'white' }}>
                {formatNumber(min, locale, numberOptions)}%
              </div>
              <div style={{ position: 'absolute', zIndex: 1, top: '50%', right: '2px', color: 'white' }}>
                {formatNumber(max, locale, numberOptions)}%
              </div>
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: 'var(--gray)',
                  marginLeft: `${Math.abs(percent_q50)}%`,
                  width: '3px',
                  height: '100%',
                }}
              />
            </div>
          </div>
        );
      }
    }
  }

  return null;
};
export default ConfidenceCell;
