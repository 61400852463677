import React from 'react';
import { Col, Row } from '@GDM/layout';
import { MaxValue } from '../MaxValue';
import { useReactiveForm } from '../useReactiveForm';
import { Voltage } from '../Voltage';

/**
 * Fields that are the same for all Q=f(u) instructions. Like p_max for example can't
 * be different from one instruction to another.
 */
export const CommonQfuFields = () => {
  const { watch } = useReactiveForm();

  if (watch('type') !== 'qfu') return null;

  return (
    <Row className="mt-3">
      <Col>
        <MaxValue />
      </Col>
      <Col>
        <Voltage />
      </Col>
    </Row>
  );
};
