import React from 'react';
import { Input } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import { Controller } from 'react-hook-form';
import { useReactiveForm } from '../useReactiveForm';

export const QfuForm = ({ index }: { index: number }) => {
  const { control, watch } = useReactiveForm();

  const isRequired = watch('type') === 'qfu';

  return (
    <Row className="mt-3">
      <Col>
        <Controller
          name={`instructions.${index}.q_max`}
          control={control}
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              value={field.value ?? ''}
              label="Qmax_contract ="
              type="number"
              placeholder="0.6"
              step={0.1}
              suffix="× PRacc"
              onChange={(e) => field.onChange(e.target.value)}
              data-cy="q_max"
              full
            />
          )}
        />
      </Col>
      <Col>
        <Controller
          name={`instructions.${index}.q_min`}
          control={control}
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              value={field.value ?? ''}
              label="Qmin_contract ="
              type="number"
              placeholder="0.3"
              step={0.1}
              suffix="× PRacc × -1"
              onChange={(e) => field.onChange(e.target.value)}
              data-cy="q_min"
              full
            />
          )}
        />
      </Col>
    </Row>
  );
};
