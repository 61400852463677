import { useCallback } from 'react';
import { useUser } from '@context/User.context';
import { Locale, Primitive } from '@utils/types/common-types';
import get from 'lodash/get';
import en from '../../../config/locales/en.yml';
import es from '../../../config/locales/es.yml';
import fr from '../../../config/locales/fr.yml';
import it from '../../../config/locales/it.yml';
import pl from '../../../config/locales/pl.yml';
import pt from '../../../config/locales/pt.yml';

const langMap: Record<Locale, Record<string, string>> = {
  fr: fr.fr,
  en: en.en,
  es: es.es,
  it: it.it,
  pl: pl.pl,
  pt: pt.pt,
};

type ReplaceMethod = (str: string, replacementArgs: Record<string, Primitive>) => string;

type TranslationRecord = { [K in string]: TranslationData };
type TranslationData = string | number | TranslationRecord;

/**
 * A function to emulate rails translation string template replacement on front-end
 * Eg: replaceArgs('Hello %{name}', { name: 'John' }) => 'Hello John'
 * @param str The string to replace
 * @param replacementArgs Object of value to insert in strign
 * @returns A formatted string
 */
const replaceArgs: ReplaceMethod = (str, replacementArgs) => {
  let newString = str;

  for (const key in replacementArgs) {
    const regex = new RegExp(`%{${key}}`);

    newString = newString.replace(regex, (replacementArgs[key] || '').toString());
  }

  return newString;
};

/**
 * Find the string in the lang map
 * @param lang Lang Map
 * @param key The key of the string
 * @param replacementArgs Map of argument to put in place of template in string
 * @returns {string}
 */
const findTranslationString = (
  lang: TranslationRecord,
  key?: string | null,
  replacementArgs?: Record<string, Primitive>,
): string => {
  if (!key) return '';

  let item = get(lang, key) || key;

  if (typeof item !== 'string') {
    return key;
  }

  if (replacementArgs) {
    item = replaceArgs(item, replacementArgs);
  }

  return item;
};

export type TranslateFn = (key?: string | null, replacementArgs?: Record<string, Primitive>) => string;

/**
 * React Hook to get translated string from file
 */
type UseTranslationHookSignature = (locale?: Locale) => {
  t: TranslateFn;
  tLocalized: TranslateFn;
};

const useTranslation: UseTranslationHookSignature = (localeParam) => {
  const user = useUser();
  const locale = localeParam || user?.locale || 'fr';
  const keySuffix = user?.geolocation?.[0] === 'DE' ? '_de' : '';
  const IS_UK_USER = user.geolocation?.[0] === 'UK';

  const t = useCallback<TranslateFn>(
    (key, replacementArgs) => {
      const langFile = langMap[locale];

      if (!key) return '';
      let translatedString = findTranslationString(langFile, key, replacementArgs);
      if (IS_UK_USER) translatedString = translatedString.replaceAll('€', '£');

      return translatedString;
    },
    [IS_UK_USER, locale],
  );

  /**
   * This method is used to get the localized translation based on user geolocation rather than user locale
   * ex: M0 in french is Markwert in german, so if the user is using german data, we want to display Markwert
   * for now it's working only for germany
   */
  const tLocalized = useCallback<TranslateFn>(
    (key, replacementArgs) => {
      return t(`${key}${keySuffix}`, replacementArgs);
    },
    [t, keySuffix],
  );

  return { t, tLocalized };
};

export default useTranslation;

/**
 * Method to use when hooks are not available (when function is not a react component)
 */
export const tString =
  (locale: Locale = 'fr'): TranslateFn =>
  (key, replacementArgs): string => {
    const langFile = langMap[locale];

    return findTranslationString(langFile, key, replacementArgs);
  };
