import React from 'react';
import { Option } from '@utils/types/common-types';
import { ContractForm, AllocationRound as AllocationRoundType } from '@utils/types/contract';
import { ALLOCATION_ROUND } from '@utils/types/contract/constants/pricing';
import { Control } from 'react-hook-form';
import { ListInput } from '../../../components/Inputs/List';

export const AllocationRound = ({ control, readOnly }: { control: Control<ContractForm>; readOnly: boolean }) => {
  const options: Option<AllocationRoundType>[] = ALLOCATION_ROUND.map((allocation_round) => ({
    value: allocation_round,
    label: allocation_round,
  }));

  return (
    <ListInput
      id="AllocationRoundPicker"
      name="allocation_round"
      control={control}
      options={options}
      label="Allocation Round"
      classNamePrefix="select-allocation-round"
      readOnly={readOnly}
    />
  );
};
