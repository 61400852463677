import React from 'react';
import { Input, Section, sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { useHelpBoxContext } from '@pages/Contracts/Contract/contexts/helpBoxContext';
import { VAT_RATES, VAT_EXEMPTION_MESSAGES } from '@utils/constants/countries/vat';
import { formatNumber } from '@utils/formatters';
import type { ContractForm } from '@utils/types/contract';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { ListInput } from '../../components/Inputs/List';
import { SectionHeader } from '../../components/SectionHeader';

export const Taxes = ({
  title,
  readOnly,
  globals: { installation, buyer },
  form: { control, watch },
}: ContractFormSectionProps) => {
  const { t } = useTranslation();
  const contractType = watch('type');
  const scheme = watch('scheme');
  const rate = VAT_RATES[(installation?.country || 'FR') as keyof typeof VAT_RATES] * 100;
  const country = buyer?.country || '';
  const message = VAT_EXEMPTION_MESSAGES[country] || '';

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="p-3">
          <div className={sectionStyles.row}>
            {contractType === 'ContractSupplyAuxiliaries' ? (
              <AuxSupplyTaxes scheme={scheme} />
            ) : (
              <ListInput
                id="VatRate"
                name="include_vat"
                control={control}
                options={[
                  { label: t('contracts.apply_vat', { rate }), value: true },
                  { label: message ? t('contracts.exclude_vat', { message }) : 'common.no', value: false },
                ]}
                label="contracts.vat_application"
                readOnly={readOnly}
              />
            )}
          </div>
        </Section>
      }
    />
  );
};

const AuxSupplyTaxes = ({ scheme }: { scheme: ContractForm['scheme'] }) => {
  const { handleFocus, handleBlur } = useHelpBoxContext();

  return (
    <>
      {scheme === 'unique_contract' && (
        <Input
          label="sales_management.cta"
          value={formatNumber(21.93) || ''}
          suffix="%"
          readOnly
          onFocus={handleFocus({
            title: 'sales_management.cta',
            text: 'sales_management.cta_info',
          })}
          onBlur={handleBlur}
          full
          size="lg"
        />
      )}
      <Input
        label="sales_management.standard_rate_vat"
        value="20"
        suffix="%"
        readOnly
        onFocus={handleFocus({
          title: 'sales_management.standard_rate_vat',
          text: 'sales_management.standard_rate_vat_info',
        })}
        onBlur={handleBlur}
        full
        size="lg"
      />
      <Input
        label="sales_management.reduced_rate_vat"
        value={formatNumber(5.5) || ''}
        suffix="%"
        readOnly
        onFocus={handleFocus({
          title: 'sales_management.reduced_rate_vat',
          text: 'sales_management.reduced_rate_vat_info',
        })}
        onBlur={handleBlur}
        full
        size="lg"
      />
    </>
  );
};
