import React, { useCallback, useState } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Modal } from '@GDM/Modal';
import { EventType } from '@utils/types/event';
import { Control } from 'react-hook-form';
import { EventDescription } from './EventDescription';
import { NotificationFormData } from './NotificationFormModal';

const MONITORING_EVENTS: EventType[] = [
  'ProductionStop',
  'HighUnderPerformance',
  'OutOfRangeReactive',
  'CommunicationFailure',
  'UnderPerformance',
] as const;

const SALES_FOCUS_EVENTS: EventType[] = [
  'AnnualProductionExceedsCap',
  'AnnualProductionExceedsDafr',
  'InvoiceMissingData',
  'ProductionOnNegativePrice',
  'DailyProductionOnNegativePrice',
  'HighDiffPublishedProduction',
] as const;

export const EventTypesSelect = ({
  control,
  isEditing,
}: {
  control: Control<NotificationFormData>;
  isEditing: boolean;
}) => {
  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(false);

  const toggleInfoPanel = useCallback(() => setIsInfoPanelOpen((prev) => !prev), []);
  const user = useUser();
  const monitoringEventsAuthorized = user?.isAuthorized(['display:view_monitoring']) ? MONITORING_EVENTS : [];
  const salesFocusEventsAuthorized = user?.isAuthorized(['display:view_salesfocus']) ? SALES_FOCUS_EVENTS : [];

  return (
    <div>
      <ControlledSelect
        size="lg"
        control={control}
        label="admin.alerts.alert_type"
        options={[...monitoringEventsAuthorized, ...salesFocusEventsAuthorized].map((type) => ({
          value: type,
          label: `admin.alerts.events.${type}`,
          info: `admin.alerts.events.${type}_text`,
        }))}
        rules={{ required: !isEditing }}
        name="event_types"
        showAllTags
        full
        isMulti
        isClearable
      />

      <Button
        variant="link"
        onClick={() => setIsInfoPanelOpen((prev) => !prev)}
        text="admin.alerts.show_alert_types_info"
        icon="ExternalLink"
      />

      <Modal
        isOpen={isInfoPanelOpen}
        toggle={toggleInfoPanel}
        size="lg"
        header="admin.alerts.alert_type"
        cancelButtonText="common.close"
      >
        <div className="pb-4">
          <div className="tt:uppercase fw:700 mb-2">Monitoring</div>
          <div className="d-flex flex-column gap-4">
            {MONITORING_EVENTS.map((type) => (
              <EventDescription key={type} eventType={type} />
            ))}
          </div>
        </div>
        <div className="pt-4">
          <div className="tt:uppercase fw:700 mb-2">Salesfocus</div>
          <div className="d-flex flex-column gap-4">
            {SALES_FOCUS_EVENTS.map((type) => (
              <EventDescription key={type} eventType={type} />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};
