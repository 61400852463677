import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { CancelInvoiceButton } from './buttons/CancelInvoiceButton';
import { EditInvoiceButton } from './buttons/EditInvoiceButton';
import { OpenHistoryModalButton } from './buttons/OpenHistoryModalButton';
import { ReactivateInvoiceButton } from './buttons/ReactivateButtonInvoice';
import { SwapMeterButton } from './buttons/SwapMeterButton';
import { UnapproveInvoiceButton } from './buttons/UnapproveInvoiceButton';
import { UnsendToERPButton } from './buttons/UnsendToERPButton';

export const ActionCell = ({
  row: { original },
  setHistoryModalIsOpen,
  setClickedInvoice,
  handleEditClick,
  tabType,
}: CellContext<MeterInvoice, unknown> & {
  setHistoryModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setClickedInvoice?: (invoice: MeterInvoice) => void;
  handleEditClick: (invoice: MeterInvoice) => void;
  tabType: MeterInvoice['volume_source'];
}) => {
  if (!setClickedInvoice) return null;

  return (
    <>
      <OpenHistoryModalButton
        invoice={original}
        setHistoryModalIsOpen={setHistoryModalIsOpen}
        setClickedInvoice={setClickedInvoice}
      />
      <EditInvoiceButton invoice={original} tabType={tabType} handleEditClick={handleEditClick} className="ml-2" />
      {tabType === 'index' && <SwapMeterButton invoice={original} className="ml-2" />}
      <UnapproveInvoiceButton invoice={original} className="ml-2" />
      <CancelInvoiceButton invoice={original} className="ml-2" />
      <UnsendToERPButton invoice={original} className="ml-2" />
      <ReactivateInvoiceButton invoice={original} className="ml-2" />
    </>
  );
};
