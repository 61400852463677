import { tString } from '@hooks/useTranslation';
import jsonToCsv, { type HeadCsv } from '@utils/json-to-csv';
import { Locale } from '@utils/types/common-types';
import { InstallationWithMeterInfo } from '@utils/types/installation';

export const loadInstallationCsvExport = (installations: InstallationWithMeterInfo[], locale: Locale): string => {
  const t = tString(locale);
  const metersWithPrmAsString = installations.map((installations) => {
    return {
      ...installations,
      meter: installations.meter
        ? {
            ...installations.meter,
            prexpected: installations.energy !== 'solar' ? '' : installations.meter.prexpected,
            prm: installations.meter.prm ? installations.meter.prm.toString() : '',
          }
        : null,
    };
  });

  return jsonToCsv(metersWithPrmAsString, [
    { label: t('common.name'), key: 'name' },
    { label: t('common.client_id'), key: 'external_ref' },
    { label: t('sales_management.erp_code'), key: 'erp_code' },
    { label: t('common.ref_prm'), key: 'meter.prm' },
    { label: t('common.energy_type'), key: 'energy' },
    { label: t('common.pmax'), key: 'p_max' },
    { label: t('common.latitude'), key: 'latitude' },
    { label: t('common.longitude'), key: 'longitude' },
    { label: t('common.expected_pr'), key: 'meter.prexpected' },
    { label: t('common.siret'), key: 'siret' },
    { label: t('sales_management.commissioning_date'), key: 'meter.commissioning_date' },
    { label: t('common.grid'), key: 'meter.grid_name' },
    { label: t('common.address'), key: 'address' },
    {
      label: t('contracts.status'),
      key: 'status',
      transform: (_, row) => (row.status === 'draft' ? t('common.draft') : t('common.active')),
    },
  ] satisfies HeadCsv<InstallationWithMeterInfo>[]);
};
