import dayjs from 'dayjs';
import type { Granularity } from './granularities';

export const defaultDates: Record<Granularity, { startDate: Date; endDate: Date }> = {
  monthly: {
    startDate: dayjs().subtract(6, 'month').startOf('month').toDate(),
    endDate: dayjs().startOf('month').toDate(),
  },
  daily: {
    startDate: dayjs().startOf('day').subtract(1, 'month').toDate(),
    endDate: dayjs().endOf('day').toDate(),
  },
  hourly: {
    startDate: dayjs().startOf('day').subtract(7, 'day').toDate(),
    endDate: dayjs().endOf('day').toDate(),
  },
};
