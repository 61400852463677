import { DataList, IndexData } from '@utils/DashboardMeter/useIndexInvoicingData';
import uniq from 'lodash/uniq';

export const getDataKeys = (invoicingData: DataList): Array<keyof IndexData> => {
  const keys = Object.keys(invoicingData) as Array<keyof DataList>;

  return uniq(
    invoicingData
      ? keys.flatMap((key) => {
          const data = invoicingData[key].data;

          return data ? (Object.keys(data) as Array<keyof IndexData>) : [];
        })
      : [],
  );
};
