import { UseFormReturn } from 'react-hook-form';
import { SimulationFormField, SimulationForm } from './simulations.types';

export const isRequiredForValue = (
  field: SimulationFormField,
  getValues: UseFormReturn<SimulationForm>['getValues'],
): boolean => {
  if (!field.requiredForFieldValues) return false;

  const fieldKeys = Object.keys(field.requiredForFieldValues) as Array<
    keyof SimulationFormField['requiredForFieldValues']
  >;

  const required = fieldKeys.every((fieldKey) => {
    const value = getValues(fieldKey);

    return field.requiredForFieldValues?.[fieldKey] === value;
  });

  return required;
};
