import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';

export const useNominationStatus = () => {
  const { filteredInvoices } = useInvoicesContext();
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();

  const cannotBeNominatedToCegedim =
    (filteredInvoices: MeterInvoice[]) =>
    (uuid: string): boolean => {
      const invoice = filteredInvoices.find((m) => m.uuid === uuid);

      return Boolean(!invoice || invoice.status !== 'approved' || invoice.contract_invoicing_platform !== 'cegedim');
    };

  const allSelectedInvoicesCanBeNominatedOnCegedim =
    selectedInvoicesUuids.length > 0 &&
    selectedInvoicesUuids.every((uuid) => !cannotBeNominatedToCegedim(filteredInvoices)(uuid));

  return { allSelectedInvoicesCanBeNominatedOnCegedim };
};
