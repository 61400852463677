import React from 'react';
import { NoWrapHeadCell } from '@GDM/Table';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { CountryCode } from '@utils/types/countries';
import { getColumnHeader } from '../helpers/getColumnHeader';
import { getGroupLabel } from '../helpers/getGroupLabel';
import { RevenueContextType, TableData } from '../revenue.types';
import { FirstColumn, ValueCell } from './Cells';

export function buildColumnList(input: {
  geolocation: CountryCode;
  groupBy: RevenueContextType['groupBy'];
  isLoading: boolean;
  periods: string[];
  resolution: RevenueContextType['resolution'];
}): ColumnDef<TableData>[] {
  const { geolocation, groupBy, isLoading, periods, resolution } = input;

  const FirstColumnCell = (row: CellContext<TableData, unknown>) => {
    const label = getGroupLabel(groupBy, row.row.original.label, geolocation);

    return <FirstColumn row={row} label={label} isLoading={isLoading} />;
  };

  const columnList: ColumnDef<TableData>[] = [{ header: '', id: 'expander', cell: FirstColumnCell }];

  if (periods.length) {
    periods.forEach((period, i) => {
      const isInTheFuture = new Date(period) > new Date();

      const formattedHeader = isLoading
        ? '--'
        : `${getColumnHeader(period, resolution, i)}${isInTheFuture ? ' *' : ''}`;

      columnList.push({
        header: () => <NoWrapHeadCell label={formattedHeader ?? '--'} />,
        id: period,
        cell: ({ row }) => {
          return <ValueCell row={row} isLoading={isLoading} period={period} />;
        },
      });
    });
  }

  return columnList;
}
