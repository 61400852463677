import React from 'react';
import { toDate } from '@utils/formatters';
import { isValidDate } from '@utils/isValidDate';
import { DatePickerProps } from './date-picker.types';

const dateParserMap: Record<NonNullable<DatePickerProps['picks']>, RegExp> = {
  default: new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/),
  months: new RegExp(/[0-9]{4}-[0-9]{2}/),
  years: new RegExp(/[0-9]{4}/),
  datetime: new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/),
};

export function dateParser(
  e: React.ChangeEvent<HTMLInputElement>,
  picks?: DatePickerProps['picks'],
): { value: Date | [Date, Date] | null; shouldChange: boolean } {
  const inputValue = e?.target?.value;
  if (!inputValue && typeof inputValue !== 'string' && typeof inputValue !== 'number')
    return { value: null, shouldChange: false };

  const values = inputValue.split('/').map((v) => v.trim());
  const isRange = values.length === 2;

  // Will only parse dates like this: 2023-12-31
  const dateFormat = dateParserMap[picks || 'default'];

  let value = null;

  if (!isRange) {
    value = dateFormat.test(values[0]) && isValidDate(values[0]) ? toDate(values[0]) : null;
  } else {
    value = isRange
      ? (values.map((d) => (dateFormat.test(d) && isValidDate(d) ? toDate(d) : null)) as [Date, Date])
      : null;
  }

  const shouldChange = (Array.isArray(value) && value.every(Boolean)) || (!Array.isArray(value) && isValidDate(value));

  return { value, shouldChange };
}
