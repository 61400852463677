import React, { ReactNode } from 'react';
import { Icon } from '@GDM/Icon';
import { ProgressCircle } from '@GDM/ProgressCircle';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './nav.module.scss';

export const Nav = ({ children }: { children: ReactNode }) => {
  return <div className={classNames(styles.card, styles.nav)}>{children}</div>;
};

type NavItemProps = {
  title: string;
  isValid?: boolean;
  progress?: number;
};

export const NavItem = ({ title, isValid, progress }: NavItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['nav-item']}>
      <span className={styles.title}>{t(title)}</span>
      <div className={styles.progress}>
        {isValid ? <Icon name="CheckCircle" size={20} /> : <ProgressCircle value={progress} animated={true} />}
      </div>
    </div>
  );
};
