import { Contract } from '@utils/types/contract';

export const sortContractsByStartDate = (a: Contract, b: Contract) => {
  if (a.start_date < b.start_date) {
    return -1;
  }

  if (a.start_date > b.start_date) {
    return 1;
  }

  return 0;
};
