import pick from 'lodash/pick';
import { type InstallationFormRequestBody } from '../installation.types';

export const formatInstallationBody = (values: Partial<InstallationFormRequestBody>) => {
  const clonedValues: Partial<InstallationFormRequestBody> = { ...values };

  if (clonedValues.owner_id === 'new_owner') {
    delete clonedValues.owner_id;
  }

  const installation = pick(clonedValues, ['name', 'energy', 'country', 'uuid', 'owner_id', 'status']);

  return installation;
};
