/**
 * Similar to Java's String.hashCode(), returns a hash code for a given string.
 *
 * The hash code for a string object is computed as:
 * ```js
 * string[0]*31^(n-1) + string[1]*31^(n-2) + ... + string[n-1]
 * ```
 * where `string[i]` is the `i`th character of the given string, `n` is the length of the string,
 * and `^` is the exponentiation.
 * @param input {string}
 * @returns hashCode {number}
 */
export function stringToHash(input: string): number {
  let hash = 0;

  /**
   * The Math.imul() function returns the result of the C-like 32-bit multiplication
   * of the two given parameters.
   *
   * The `| 0` part forces `hash` to be a 32-bit number, optimizing for speed in JS engines.
   */
  for (let i = 0; i < input.length; i++) {
    hash = (Math.imul(31, hash) + input.charCodeAt(i)) | 0;
  }

  return hash;
}
