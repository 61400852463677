import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

export const StatusFilter = () => {
  const { filterContracts } = useConsumeContracts();
  const form = useFormContext<ContractsFiltersType>();

  const options: Option<Partial<Contract['status']>>[] = [
    { value: 'draft', label: 'common.draft' },
    { value: 'complete', label: 'contracts.validated' },
  ];

  return (
    <Controller
      name="status"
      control={form.control}
      render={({ field }) => (
        <Select
          classNamePrefix="contracts-filters-status"
          placeholder="contracts.status"
          selectedOption={field.value}
          options={options}
          isClearable
          onChange={(option: Option<Partial<Contract['status']>> | null) => {
            filterContracts({ ...form.getValues(), status: option?.value || null, last_selected_filter: 'status' });
            field.onChange(option?.value);
            form.setValue('last_selected_filter', option?.value ? 'status' : null);
          }}
        />
      )}
    />
  );
};
