import React, { ReactNode } from 'react';
import { sectionStyles } from '@GDM/forms';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { ContractForm } from '@utils/types/contract';
import classNames from 'classnames';
import { Control } from 'react-hook-form';
import styles from './hedge-block.module.scss';

export const HedgeBlock = ({
  index,
  control,
  readOnly,
  currency,
  pMax,
  averagePower,
  minDate,
  maxDate,
  isCollapsed,
  header,
}: {
  index: number;
  isCollapsed: boolean;
  header: ReactNode;
  minDate: Date;
  maxDate: Date;
  pMax?: number;
  averagePower?: number;
  readOnly: boolean;
  control: Control<ContractForm>;
  currency: ContractForm['currency'];
}) => {
  return (
    <div className={classNames(styles.container, { [styles['is-collapsed']]: isCollapsed })}>
      {header}
      <div className={classNames(sectionStyles.row, styles['block-container'])}>
        <DateInput
          id="HedgeBlockDates"
          name={`hedge_blocks_attributes.${index}.dates`}
          control={control}
          label="sales_management.sub_period.start_end_dates"
          minDate={minDate}
          maxDate={maxDate}
          data-cy={`hedge-dates-picker-${index}`}
          picks="months"
          selectsRange
          showPeriodBadge
          readOnly={readOnly}
          rules={{ required: true }}
          startKey="start_date"
          endKey="end_date"
        />
        <NumericInput
          id="HedgeBlockPrice"
          name={`hedge_blocks_attributes.${index}.price`}
          control={control}
          data-cy={`hedge-display-price-${index}`}
          label="common.price"
          suffix={`${getSymbol(currency)}/MWh`}
          autoComplete="off"
          readOnly={readOnly}
          step={0.01}
          required
          rules={{ required: true }}
        />
        <NumericInput
          id="HegdeBlockAveragePower"
          name={`hedge_blocks_attributes.${index}.average_power`}
          control={control}
          data-cy={`hedge-display-average-power-${index}`}
          label="sales_management.average_power"
          max={pMax}
          suffix="MW"
          autoComplete="off"
          tooltip={
            typeof pMax === 'number' && typeof averagePower === 'number' && averagePower > pMax
              ? 'sales_management.info.value_higher_than_installation_p_max'
              : undefined
          }
          rules={{ required: true }}
        />
      </div>
    </div>
  );
};
