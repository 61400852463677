import { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { DashboardViewAllData } from '@pages/Portfolio/portfolio.definitions';
import { usePortfolio } from '@pages/Portfolio/usePortfolio';
import { ColumnDef } from '@tanstack/react-table';
import { COLUMNS } from './ColumnList';
import { usePortfolioTable } from './context/usePortfolioTable';

export function useColumns(): ColumnDef<DashboardViewAllData>[] {
  const { isAuthorized } = useUser();
  const { onlyConsumers, dataType } = usePortfolio();
  const { infoFilters } = usePortfolioTable();
  const hasSalesfocus = isAuthorized(['display:view_salesfocus']);
  const hasMonitoring = isAuthorized(['display:view_monitoring']);
  const hasPerformance = isAuthorized(['display:view_monitoring_dashboard_meter_performances']);

  const columns = useMemo<ColumnDef<DashboardViewAllData>[]>(() => {
    const {
      NAME,
      MAX_POWER,
      CAPACITY_FACTOR,
      PEAK_POWER,
      BUSINESS_PLAN,
      MEAN,
      ENERGY,
      PERFORMANCE,
      CONTRACT_TYPE,
      COVERAGE,
      LAST_READING,
      CHECKBOX,
      COMMISSIONING_DATE,
    } = COLUMNS;

    if (onlyConsumers) {
      return [NAME, MAX_POWER, MEAN, ENERGY, PERFORMANCE, COVERAGE, LAST_READING];
    }

    return [
      ...(dataType !== 'books' ? [CHECKBOX] : []),
      NAME,
      ...(dataType !== 'books' && infoFilters.commissioningDate ? [COMMISSIONING_DATE] : []),
      ...(dataType !== 'books' && hasSalesfocus ? [CONTRACT_TYPE] : []),
      ...(dataType !== 'books' && hasSalesfocus ? [BUSINESS_PLAN] : []),
      PEAK_POWER,
      MAX_POWER,
      ENERGY,
      ...(dataType !== 'books' && hasMonitoring ? [CAPACITY_FACTOR] : []),
      ...(hasPerformance ? [PERFORMANCE] : []),
      COVERAGE,
      ...(dataType !== 'books' ? [LAST_READING] : []),
    ];
  }, [dataType, hasMonitoring, hasPerformance, hasSalesfocus, infoFilters.commissioningDate, onlyConsumers]);

  return columns;
}
