import getContractTypeLabel from '@utils/contract/getLabel';
import { ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { OverviewFields, RevenueContextType } from '../revenue.types';

export const getGroupLabel = (group?: RevenueContextType['groupBy'], label?: string, country?: CountryCode): string => {
  if (!label) return 'common.revenue';

  if (Object.values(OverviewFields).includes(label))
    return `monitoring.installation.revenue.${label.toLocaleLowerCase()}`;

  if (group === 'contract_type') return country ? getContractTypeLabel(label as ContractType, country) : '';
  if (group === 'installation') return label;

  // If it's anything else, return a translation string
  return `monitoring.installation.revenue.${label.toLocaleLowerCase()}`;
};
