import React, { useEffect, useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter, useDefinitionModal } from '@GDM/DefinitionList';
import { Select } from '@GDM/forms';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { change_type_facturation_v2_dashboard_meter_index_path } from '@utils/routes';
import { Option } from '@utils/types/common-types';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { InvoicingTypes } from './invoicing-type.types';

export const InvoicingTypeModal = ({ meter }: { meter: Meter }) => {
  const { t } = useTranslation();
  const { facturation_type, setInvoicingTypeRawString } = useMeterInfo();
  const [localInvoicingType, setLocalInvoicingType] = useState<string | undefined>(facturation_type);
  const { toggle } = useDefinitionModal();

  const {
    loading: isLoading,
    error,
    execute,
    data: response,
  } = useRequest<{ type_facturation: string }>(
    change_type_facturation_v2_dashboard_meter_index_path({
      meter: meter?.name,
      type_facturation: localInvoicingType,
    }),
    'GET',
    true,
  );

  const saveUpdate = () => {
    execute?.();
  };

  useEffect(() => {
    if (response) {
      setInvoicingTypeRawString?.(response.type_facturation);
      toggle?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const errorMessage = error?.message ? 'monitoring.installation.invalid_invoicing_type' : null;

  const options: Option<keyof typeof InvoicingTypes>[] = [
    { value: 'cdc2h', label: t(InvoicingTypes['cdc2h']) },
    { value: 'cdc0h', label: t(InvoicingTypes['cdc0h']) },
    { value: 'cdc0h_feed-in_tariff', label: t(InvoicingTypes['cdc0h_feed-in_tariff']) },
    { value: 'index_monthly', label: t(InvoicingTypes['index_monthly']) },
    { value: 'index_yearly', label: t(InvoicingTypes['index_yearly']) },
    { value: 'index_half_yearly', label: t(InvoicingTypes['index_half_yearly']) },
  ];

  return (
    <>
      <DefinitionModalBody>
        <Select
          options={options}
          selectedOption={localInvoicingType}
          onChange={(option) => setLocalInvoicingType(option?.value)}
        />
      </DefinitionModalBody>
      <DefinitionModalFooter error={errorMessage} save={saveUpdate} isLoading={isLoading} closeOnSave={false} />
    </>
  );
};
