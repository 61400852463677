import React from 'react';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { UpdateModalIndex } from './UpdateModalIndex';
import { UpdateModalLoadCurve } from './UpdateModalLoadCurve';

export type UpdateModalProps = {
  isOpen: boolean;
  toggle: () => void;
  invoice: MeterInvoice | null;
  direction: Contract['direction'] | 'all';
};

export const UpdateModal = (props: UpdateModalProps) => {
  if (!props.invoice) return null;
  if (props.invoice.contract_calc_base === 'index') return <UpdateModalIndex {...props} />;

  return <UpdateModalLoadCurve {...props} />;
};
