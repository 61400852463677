import Installation from '@utils/types/installation';
import { InstallationOption } from './book-form.types';

export const installationToOption = (installation: Installation): InstallationOption => ({
  label: installation.name,
  value: installation.name,
  energy: installation.energy,
  has_meter: installation.has_meter,
  status: installation.status,
});
