import React, { useState } from 'react';
import { Button } from '@GDM/Button';
import type { Currency } from '@utils/types/currency';
import { CurrencyModal } from './CurrencyModal';

export const CurrencyModalButton = ({
  startDate,
  endDate,
  currency,
}: {
  startDate: Date;
  endDate: Date;
  currency: Currency;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <div>
      <CurrencyModal isOpen={isOpen} toggle={toggle} startDate={startDate} endDate={endDate} currency={currency} />
      <Button icon="DollarSign" size="xxs" tooltip="common.exchange_rates" variant="primary-2" onClick={toggle} />
    </div>
  );
};
