import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { DatePicker } from '@GDM/DatePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

export const DateFilter = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts } = useConsumeContracts();

  return (
    <Controller
      control={form.control}
      name="dates"
      render={({ field }) => (
        <DatePicker
          startDate={field.value[0]}
          endDate={field.value[1]}
          selectsRange
          hasPreDefinedPeriods
          data-cy="contracts-filter-date"
          isClearable
          onChange={(dates) => {
            field.onChange(dates);

            if ((dates[0] && dates[1]) || (!dates[0] && !dates[1])) {
              filterContracts({ ...form.getValues(), dates });
            }
          }}
        />
      )}
    />
  );
};
