import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { formatDate } from '@utils/formatParseDate';

export const DateCell = ({
  value,
  getValue,
  format,
  placeholder,
}: {
  getValue?: () => unknown;
  value?: string | number | Date;
  format?: string;
  placeholder?: string;
}) => {
  const { t } = useTranslation();
  const valueToUse = value || getValue?.();
  const canBeDate = valueToUse instanceof Date || typeof valueToUse === 'string' || typeof valueToUse === 'number';

  if (!canBeDate || !valueToUse || valueToUse === '1970-01-01')
    return <span className="fw:400">{typeof placeholder === 'string' ? t(placeholder) : null}</span>;

  return <>{formatDate(new Date(valueToUse), format || 'YYYY-MM-DDTHH:mm')}</>;
};
