import { Contract } from '@utils/types/contract';
import { MeterInvoice } from '@utils/types/meter-invoice';

export default function matchInstallationName(names: string[] | undefined, obj: Contract | MeterInvoice): boolean {
  const contract = obj as Contract;

  if (contract?.installation?.name) {
    return names?.includes(contract.installation.name) || false;
  }

  const meterInvoice = obj as MeterInvoice;

  if (meterInvoice?.installation_name) {
    return names?.includes(meterInvoice.installation_name) || false;
  }

  return true;
}
