import React from 'react';
import { Select } from '@GDM/forms';
import { EnergyType, Option } from '@utils/types/common-types';
import { useM0Deviation } from '../useM0Deviation';

type Props = { energies: EnergyType[] };

export const EnergySelector = ({ energies }: Props) => {
  const { energy, setEnergy } = useM0Deviation();
  let options: Option<EnergyType | null>[] = [
    { label: 'common.wind.eolian', value: 'wind' },
    { label: 'common.pv.short', value: 'solar' },
  ];
  options = options.filter((option) => option.value && energies.includes(option.value));
  const onChange = (option: Option<EnergyType | null> | null): void => setEnergy?.(option?.value || null);

  return (
    <Select isClearable placeholder="common.technology" onChange={onChange} options={options} selectedOption={energy} />
  );
};
