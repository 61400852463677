import { CountryCode } from '@utils/types/countries';
// eslint-disable-next-line no-restricted-imports
import * as icons from 'react-feather';

export type ValidFlags = keyof typeof OtherAvailableFlags | keyof typeof EasterEggFlags | CountryCode;

export type IconNames = keyof Omit<typeof icons, 'Icon' | 'IconProps'> | ValidFlags;

export enum OtherAvailableFlags {
  EU = 'EU',
  XX = 'XX',
}

export enum EasterEggFlags {
  BZH = 'BZH',
  HN = 'HN',
  IE = 'IE',
  US = 'US',
}
