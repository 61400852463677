import React from 'react';
import { Card } from '@GDM/Card';
import type { Granularity } from './constants/granularities';
import type { NegativeHour } from './helpers/getFormattedNegativeHours';
import type { NegativePrice } from './negative-prices.types';
import { NegativePricesChart } from './NegativePricesChart';
import { Widgets } from './Widgets';

export const NegativePricesChartTab = ({
  granularity,
  negativeHours,
  negativePrices,
  isLoading,
  startDate,
  endDate,
  wrap,
  showRealCovering,
}: {
  granularity: Granularity;
  negativeHours: NegativeHour[];
  negativePrices: NegativePrice[];
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  wrap?: boolean;
  showRealCovering: boolean;
}) => {
  const chart = (
    <NegativePricesChart
      negativeHours={negativeHours}
      granularity={granularity}
      isLoading={isLoading}
      startDate={startDate}
      endDate={endDate}
    />
  );

  return (
    <>
      {negativeHours && wrap && (
        <Card>
          <div className="w-100">{chart}</div>
        </Card>
      )}
      {negativeHours && !wrap && chart}
      {negativeHours && (
        <Widgets negativeHours={negativeHours} negativePrices={negativePrices} showRealCovering={showRealCovering} />
      )}
    </>
  );
};
