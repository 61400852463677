/**
 * Takes a string like `?param1=test&param2=test2` and returns an object `{ param1: 'test', param2: ['test2', 'test3] }`.
 */
export function searchParamsStringToObject(searchString: string): Record<string, string | string[]> | null {
  if (!searchString) return null;

  const params = new URLSearchParams(searchString);

  // The added complexity is that Object.fromEntries(params) wouldn't work with query strings that contain arrays
  // e.g: `param_array[]=abc&param_array[]=def&not_array_param=ghi`. Because of that, we have to iterate an recreate
  // a slightly more complex object through these steps.
  return [...Array.from(params.entries())].reduce<Record<string, string | string[]>>((acc, entry) => {
    const [key, value] = entry;
    const currentValue = acc[key];

    if (!currentValue) return { ...acc, [key]: value };

    // if the current key is already an array, we'll add the value to it
    if (Array.isArray(currentValue)) return { ...acc, [key]: [...currentValue, value] };

    return { ...acc, [key]: [currentValue, value] };
  }, {} as Record<string, string | string[]>);
}
