import React from 'react';
import { Table, TableHead, TableBody } from '@GDM/Table';
import { TableFooter } from '@GDM/Table/TableFooter/TableFooter';
import { FocusType } from '@pages/CustomReporting/types';
import { useReactTable, getCoreRowModel, getExpandedRowModel, getSortedRowModel } from '@tanstack/react-table';
import styles from '../custom-reporting-report.module.scss';
import { FullPeriodReportTableData, TimeBasedReportTableData } from './types';

export const TimeBasedReportTable = ({
  columns,
  rows,
  focus,
}: Omit<TimeBasedReportTableData, 'isTimeBased' | 'aggregations'> & { focus: FocusType }) => {
  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
      sorting: [{ id: focus, desc: false }],
    },
    getSubRows: (row) => row.nested_data || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table className={styles.table}>
      <TableHead table={table} />
      <TableBody table={table} />
      <TableFooter table={table} />
    </Table>
  );
};

export const FullPeriodReportTable = ({
  columns,
  rows,
  focus,
}: Omit<FullPeriodReportTableData, 'isTimeBased' | 'aggregations'> & { focus: FocusType }) => {
  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
      sorting: [{ id: focus, desc: false }],
    },
    getSubRows: (row) => row.nested_data || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table className={styles.table}>
      <TableHead table={table} />
      <TableBody table={table} />
      <TableFooter table={table} />
    </Table>
  );
};
