import { useState } from 'react';
import { CommonRequestHookResponse } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { v2_ppa_projects_upload_loadcurve_file_path } from '@utils/routes';
import { isAxiosError } from 'axios';

/**
 * This hook exists because useRequest cannot handle formdata and it's not trivial to implement.ƒ
 */
export const useUploadCsv = <T extends unknown[] | Record<string, unknown>>(simulationRef: string | null) => {
  const [request, setRequest] = useState<CommonRequestHookResponse<T>>({
    data: null,
    loading: false,
    error: null,
    loaded: false,
    errors: null,
    status: null,
  });

  const upload = async (data: FormData) => {
    const uploadCsv = async () => {
      setRequest((request) => ({ ...request, loading: true }));

      try {
        const response = await axiosInstance(false).post(v2_ppa_projects_upload_loadcurve_file_path(), data, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { simulationRef: simulationRef },
        });

        setRequest({ data: response.data, loading: false, error: null, loaded: true, status: response.status });
      } catch (error) {
        if (isAxiosError(error)) {
          setRequest({
            data: null,
            loading: false,
            error: { code: error.response?.data?.code, message: error.response?.data?.message },
            loaded: false,
            status: error.response?.status ?? null,
          });
        } else {
          setRequest({
            data: null,
            loading: false,
            error: { code: 'errors.unknown_error', message: 'errors.unknown_error' },
            loaded: false,
            status: null,
          });
        }
      }
    };

    uploadCsv();
  };

  return {
    upload,
    ...request,
  };
};
