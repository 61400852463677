import React from 'react';
import { Restricted } from '@components/Restricted';
import { Col, Row } from '@GDM/layout';
import useM0Aggreg from '@hooks/m0s/useM0Aggreg';
import useM0ByType from '@hooks/m0s/useM0ByType';
import { useNationalPricesContext } from '../context';
import { M0Aggreg } from './M0Aggreg';

export const M0Aggregators = () => {
  const { selectedCountry } = useNationalPricesContext();
  const { rawM0s, solarData, windData, loading } = useM0Aggreg(selectedCountry);
  const { wind, solar, loadingWind, loadingSolar } = useM0ByType(selectedCountry);

  return (
    <Restricted permissions={['display:view_salesfocus']}>
      <Row className="mt-3">
        <Col>
          <M0Aggreg
            data={solarData}
            base={solar}
            type="solar"
            aggregatorsMarketResults={rawM0s}
            loading={loadingSolar || loading}
          />
        </Col>
        <Col>
          <M0Aggreg
            data={windData}
            base={wind}
            type="wind"
            aggregatorsMarketResults={rawM0s}
            loading={loadingWind || loading}
          />
        </Col>
      </Row>
    </Restricted>
  );
};
