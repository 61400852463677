import React, { useMemo } from 'react';
import { Button } from '@GDM/Button';
import { Select, useInternalFormContext } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import type { InstallationForm } from '@pages/Installations/Installation/installation.types';
import { useOwnerFormQuery } from '@pages/Installations/Installation/useOwnerFormQuery';
import { Option } from '@utils/types/common-types';
import MarketPlayer from '@utils/types/market_player';
import { Controller, type ControllerRenderProps } from 'react-hook-form';

type MarketPlayerOption = Option<MarketPlayer['id'] | 'new_owner'>;

export const OwnerPicker = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('owner_id');
  const { country } = useInternalFormContext();
  const ownerId = form.watch('owner_id');

  const {
    query: { data: owners, isLoading },
  } = useOwnerFormQuery();

  const ownersByCountry = useMemo(() => owners?.filter((owner) => owner.country === country) || [], [owners, country]);

  const showNewOner = ownerId === 'new_owner';

  const options: Option<MarketPlayer['id'] | 'new_owner'>[] = useMemo(
    () =>
      ownersByCountry?.map((owner) => ({
        label: `${owner.short_name || owner.long_name} (${owner.company_number})`,
        value: owner.id,
      })) || [],
    [ownersByCountry],
  );

  /**
   * This select contains a hidden option `new_owner` that is used to show the new owner input,
   * We don't want to have it in the options list
   * To make it work with the form definition (onlyForFields, see: app/react/pages/GDM/Installations/Installation/constants/common-fields.ts)
   * We set this value when the user clicks on the button
   */
  const handleNewOwnerClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
    e.preventDefault();

    /**
     * If the user selects an owner after filling the new owner inputs, we clean the new owner inputs to avoid side effects
     */
    if (showNewOner) {
      return form.setValue('owner_id', null);
    }

    form.setValue('owner_id', 'new_owner');
  };

  const handleChange =
    (field: ControllerRenderProps<InstallationForm, 'owner_id'>) => (option: MarketPlayerOption | null) => {
      if (option?.value && option.value !== 'new_owner') {
        form.setValue('company_number', '');
        form.setValue('short_name', '');
      }

      field.onChange(option?.value ?? null);
    };

  return (
    <Controller
      control={form.control}
      name="owner_id"
      rules={rules}
      render={({ field, fieldState }) => (
        <div className="w-100">
          <Select
            name={field.name}
            options={options}
            onChange={handleChange(field)}
            selectedOption={field.value}
            isDisabled={disabled || isLoading || ownerId === 'new_owner'}
            size="lg"
            label="common.owner"
            classNamePrefix="owner_id-picker"
            full
            isClearable
            isLoading={isLoading}
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message}
          />
          <Button
            variant={!showNewOner ? 'link' : 'link-secondary'}
            text={!showNewOner ? 'admin.installations.new_owner' : 'common.cancel'}
            icon={!showNewOner ? 'PlusSquare' : 'XSquare'}
            className="mt-2"
            onClick={handleNewOwnerClick}
          />
        </div>
      )}
    />
  );
};
