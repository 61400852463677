const fetchWrapper = (input: RequestInfo | URL, init?: RequestInit) => {
  const CSRF_TOKEN = document.querySelector<HTMLMetaElement>('meta[name=csrf-token]')?.content || '';

  return fetch(input, { ...init, headers: { ...init?.headers, 'X-CSRF-TOKEN': CSRF_TOKEN, REACT: 'true' } });
};

export default fetchWrapper;

export type ReqError = {
  code: string;
  message: string;
  errors?: Array<Partial<BaseError>>;
};

export type BaseError = {
  resource: string;
  field: string;
  code: string;
  count: string;
  date: string;
};
