import React from 'react';
import { BookInformations } from '@pages/Simulations/Simulation/tabs/Characteristics/BookInformations';
import { useProjectContext } from '../../utils/project.context';
import { InstallationInformations } from './InstallationInformations/InstallationInformations';

export const Characteristics = () => {
  const project = useProjectContext();
  const isBook = project.isBook;

  return (
    <div>
      {isBook && <BookInformations />}
      {!isBook && <InstallationInformations />}
    </div>
  );
};
