import { useUser } from '@context/User.context';
import { CountryCode } from '@utils/types/countries';

type CurrencyDef = { symbol: string; code: 'GBP' | 'EUR' };
type CurrencyDict = { default: CurrencyDef } & { [key in CountryCode]?: CurrencyDef };

const CURRENCY_DICT: CurrencyDict = {
  UK: { symbol: '£', code: 'GBP' },
  default: { symbol: '€', code: 'EUR' },
};

export function useCurrency<T extends 'symbol' | 'code'>(
  type: T = 'symbol' as T,
): T extends 'code' ? 'GBP' | 'EUR' : string {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { geolocation } = useUser();
  const defs = CURRENCY_DICT[geolocation?.[0] || 'default'];

  return (defs ? defs[type] : CURRENCY_DICT.default[type]) as T extends 'code' ? 'GBP' | 'EUR' : string;
}
