const formatMonthName = (locale: string, date: Date): string => {
  const formatter = new Intl.DateTimeFormat([locale || 'en', 'en'], { month: 'short' });

  return formatter.format(date).toLocaleUpperCase();
};

export const getLocalizedMonthList = (locale = 'en'): string[] => {
  const year = new Date().getFullYear();
  const monthList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const getMonthName = (monthIndex: number) => formatMonthName(locale, new Date(year, monthIndex));

  return monthList.map(getMonthName);
};
