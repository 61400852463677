import React from 'react';
import { Icon } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const SourceCell = ({ row: { original } }: CellContext<MeterInvoice, unknown>) => {
  const source = original.volume_source; // index or loadcurve
  const loadcurveSource = original.volume_production_load_curve_source;
  const iconName = source === 'index' ? 'List' : source === 'load_curve' ? 'Activity' : undefined;
  const tooltip = source === 'load_curve' && !loadcurveSource ? 'sales_management.ten_minutes_points' : undefined;
  const text =
    source === 'load_curve' && loadcurveSource
      ? `sales_management.load_curve_source.${loadcurveSource}`
      : source === 'index'
      ? 'sales_management.index'
      : undefined;

  return (
    <div className="ta:center d-flex flex-column justify-content-center align-items-center">
      {iconName && <Icon name={iconName} size={16} title={tooltip} />}
      {text && <Text className="tt:uppercase" size="xs" text={text} />}
    </div>
  );
};
