import { useQuery } from '@tanstack/react-query';
import { v2_meter_reactive_analyses_path } from '@utils/routes';
import { QfuData } from '@utils/types/qfuData';
import { ProcessedReactiveData, ReactiveData } from '@utils/types/reactiveData';

export const useReactiveAnalyses = (identifier: string, start: Date | null, stop: Date | null, isBook = false) => {
  const errors: Record<number, string> = {
    404: 'errors.installation.not_found',
    401: 'errors.unauthorized',
  };

  const defaultError = 'errors.unknown_error';

  return useQuery<ProcessedReactiveAnalysesResponse>({
    queryKey: ['reactive_analyses', identifier, start, stop],
    queryFn: async () => {
      const url = v2_meter_reactive_analyses_path(identifier, { start, stop });
      const response = await fetch(url);
      const data: RawReactiveAnalysesResponse = await response.json();

      if (data.tan_phi && !response.ok) {
        const error = errors[response.status] || defaultError;

        return Promise.reject(error);
      }

      if (data.tan_phi) {
        return {
          ...data,
          tan_phi: data.tan_phi.map((row) =>
            data.price !== null ? { ...row, penalty: data.price * row.total_facture_kwh } : row,
          ),
        };
      }

      return data;
    },
    enabled: !isBook && Boolean(start && stop),
  });
};

type RawReactiveAnalysesResponse =
  | {
      price: number | null;
      tan_phi: null;
      q_fu: QfuData;
    }
  | {
      price: number | null;
      tan_phi: ReactiveData[];
      q_fu: null;
    };

type ProcessedReactiveAnalysesResponse =
  | {
      price: number | null;
      tan_phi: null;
      q_fu: QfuData;
    }
  | {
      price: number | null;
      tan_phi: ProcessedReactiveData[];
      q_fu: null;
    };
