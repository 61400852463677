import React from 'react';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import styles from './card.module.scss';

export const CardTitle = ({
  children,
  tooltip,
  title,
}: React.PropsWithChildren<{ title?: string | JSX.Element; tooltip?: string }>) => {
  const { t } = useTranslation();

  return (
    <div className={styles['card-title']}>
      <span title={typeof title === 'string' ? t(title) : undefined} className="ellipsis">
        {typeof title === 'string' ? t(title) : title}
      </span>
      {tooltip && <Icon name="Info" size={12} title={tooltip} className="ml-1" />}
      {children}
    </div>
  );
};
