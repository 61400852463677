import React, { createContext, useContext, ReactNode, FocusEvent } from 'react';
import { HelpBoxProps } from '@GDM/HelpBox/HelpBox';

type HelpBoxContext = {
  handleFocus: (props: HelpBoxProps) => (e: FocusEvent) => void;
  handleBlur: () => void;
};

const helpBoxContext = createContext<HelpBoxContext>({
  handleBlur: () => {},
  handleFocus: () => () => {},
});

export const HelpBoxProvider = ({ children, ...value }: HelpBoxContext & { children: ReactNode }) => {
  return <helpBoxContext.Provider value={value}>{children}</helpBoxContext.Provider>;
};

export const useHelpBoxContext = () => useContext(helpBoxContext);
