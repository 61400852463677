import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import dayjs from 'dayjs';
import { useM0Deviation } from '../useM0Deviation';

export const DateSelector = () => {
  const { startDate, setStartDate, endDate, setEndDate } = useM0Deviation();

  const handleDatesChange = (dateRange: [Date | null, Date | null]): void => {
    if (Array.isArray(dateRange)) {
      const [startDate, endDate] = dateRange;
      setStartDate?.(startDate);
      setEndDate?.(endDate);
    }
  };

  return (
    <div>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        maxDate={dayjs().startOf('month').subtract(1, 'month').toDate()}
        picks="months"
        onChange={handleDatesChange}
        selectsRange={true}
      />
    </div>
  );
};
