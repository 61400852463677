import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from '@utils/types/user';
import ExportForm from './ExportForm';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Export = ({ user }: { user: User }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ExportForm user={user} />
    </QueryClientProvider>
  );
};

export default Export;
