import { PlotSeriesOptions } from 'highcharts';

export const getGapSizeOptions = ({
  isDaily,
  isMonthly,
  frequency,
}: {
  isDaily: boolean;
  isMonthly: boolean;
  frequency: number;
}): Pick<PlotSeriesOptions, 'gapSize' | 'gapUnit'> => {
  if (isDaily) {
    return {
      gapSize: 24 * 3600 * 1000,
      gapUnit: 'value',
    };
  }

  if (isMonthly) {
    return {
      gapSize: 30 * 24 * 3600 * 1000,
      gapUnit: 'value',
    };
  }

  return { gapSize: frequency, gapUnit: 'relative' };
};
