import React from 'react';
import { CurrencyCell, NumberCell } from '@GDM/Table';
import { tString } from '@hooks/useTranslation';
import { ColumnDef } from '@tanstack/react-table';
import { Locale } from '@utils/types/common-types';
import { SubscriptionInvoice } from '@utils/types/subscriptions-invoice';
import dayjs from 'dayjs';

export const getSubscriptionsColumns = (locale: Locale): ColumnDef<SubscriptionInvoice>[] => {
  const t = tString(locale);

  const columns: ColumnDef<SubscriptionInvoice>[] = [
    {
      header: 'common.date',
      accessorKey: 'date',
      cell: ({ getValue }) => <>{dayjs(getValue<string>(), 'MM/YYYY').format('YYYY-MM')}</>,
    },
    { header: 'admin.subscriptions.nb_meters', accessorKey: 'nbcompteurs', cell: NumberCell },
    {
      header: 'admin.subscriptions.unit_price',
      accessorKey: 'unitprice',
      cell: ({ row: { original } }) => <CurrencyCell value={original.unitprice} showUnit currency="EUR" />,
    },
    {
      header: 'admin.subscriptions.total_before_tax',
      accessorKey: 'price',
      cell: ({ row: { original } }) => <CurrencyCell value={original.price} showUnit currency="EUR" />,
    },
    {
      header: 'admin.subscriptions.total_with_vat',
      accessorKey: 'stripeamount',
      cell: ({ row: { original } }) => <CurrencyCell value={original.stripeamount} showUnit currency="EUR" />,
    },
    {
      header: 'admin.subscriptions.vat',
      accessorKey: 'tva',
      cell: ({ row: { original } }) => <CurrencyCell value={original.tva} showUnit currency="EUR" />,
    },
    {
      header: 'PDF',
      accessorKey: 'ref',
      cell: ({ getValue }) => (
        <a href={`../../invoices/show.pdf?ref=${getValue<SubscriptionInvoice['ref']>()}`}>
          {t('admin.subscriptions.download')}
        </a>
      ),
    },
  ];

  return columns;
};
