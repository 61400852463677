import React from 'react';
import { Hr } from '@GDM/Hr';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { IpCheckbox } from '../fields/IpCheckbox';
import { KeyInput } from '../fields/KeyInput';
import MandateSelect from '../fields/MandateSelect/MandateSelect';
import { MeterTypeSelect } from '../fields/MeterTypeSelect';
import { NumberInput } from '../fields/NumberInput';
import { PrmInput } from '../fields/PrmInput/PrmInput';
import { ProductionCheckbox } from '../fields/ProductionCheckbox';
import { ShuntInput } from '../fields/ShuntInput';

export const Enedis = () => {
  const { watch } = useInstallationForm();
  const ip = watch('boitier_ip');

  return (
    <div>
      <div className="p-3">
        <MandateSelect />
      </div>
      <Hr />

      <div className="p-3 d-flex flex-column gap-3 hide-empty">
        <PrmInput />
        <ProductionCheckbox />
        <IpCheckbox />

        {!ip && (
          <div className="hide-empty">
            <div className="d-flex gap-3 hide-empty">
              <KeyInput />
              <NumberInput />
            </div>

            <div className="d-flex gap-3 hide-empty">
              <ShuntInput />
              <MeterTypeSelect />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
