import React, { useState } from 'react';
import { Checkbox } from '@GDM/forms';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { PortfolioTableFilters } from '../context/portfolio.context';
import { usePortfolioTable } from '../context/usePortfolioTable';

export const Settings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { infoFilters, setInfoFilters } = usePortfolioTable();

  // Some accounts have a lot of items, so we use setTimeout to 'make' the request handle like an
  // async function, which allows us to set a loading state.
  const updateTableFilters = throttle((filter: keyof PortfolioTableFilters, value: boolean) => {
    setIsLoading(true);
    setTimeout(() => {
      setInfoFilters({ [filter]: value });
      setIsLoading(false);
    });
  }, 1000);

  return (
    <div className={classNames('d-flex flex-column gap-1', { unavailable: isLoading })}>
      <Checkbox
        label="common.client_id"
        checked={infoFilters.clientId}
        onChange={(v) => updateTableFilters('clientId', v.currentTarget.checked)}
        disabled={isLoading}
        name="client-id"
      />
      <Checkbox
        label="sales_management.commissioning_date"
        checked={infoFilters.commissioningDate}
        onChange={(v) => updateTableFilters('commissioningDate', v.currentTarget.checked)}
        disabled={isLoading}
        name="commissioning-date"
      />
    </div>
  );
};
