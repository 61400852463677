import React from 'react';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import styles from '../SubPeriod/sub-period.module.scss';

export const SubPeriodHeader = ({
  title,
  badgeLabel,
  datesLabel,
  insertBefore,
  remove,
  toggle,
  isCollapsed,
  error,
  readOnly,
  removeIsDisabled,
  insertBeforeIsDisabled,
}: {
  title: string;
  badgeLabel?: string;
  datesLabel?: string;
  isCollapsed: boolean;
  toggle: () => void;
  insertBefore: () => void;
  remove: () => void;
  error?: FieldError;
  readOnly: boolean;
  removeIsDisabled?: boolean;
  insertBeforeIsDisabled?: boolean;
}) => {
  return (
    <div className={classNames(styles.header, isCollapsed && styles['is-collapsed'])}>
      <div className={styles.title}>
        <span onClick={toggle}>{title}</span>
        {isCollapsed && (
          <div className={classNames(styles['collapsed-dates'], error && styles['has-error'])}>
            <span className={styles['collapsed-prefix']}>&ndash;&nbsp;</span>
            <Badge variant={error ? 'danger' : 'primary-1'} label={badgeLabel} /> <span>{datesLabel}</span>
            {error && (
              <Icon
                name="AlertCircle"
                title="common.contains_errors"
                size={14}
                className={styles['collapsed-warning']}
              />
            )}
          </div>
        )}
      </div>
      {!readOnly && (
        <>
          <Button
            icon="Plus"
            title="sales_management.add_period_before"
            onClick={insertBefore}
            disabled={insertBeforeIsDisabled}
            floating
          />
          <Button
            icon="Trash2"
            variant="secondary"
            title="common.delete"
            data-cy="delete-sub-period"
            onClick={remove}
            disabled={removeIsDisabled}
            className="ml-1"
            floating
          />
        </>
      )}
    </div>
  );
};
