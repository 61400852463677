import { constrainFractionDigits, toDate } from '@utils/formatters';
import { ContractForm, HedgeSubPeriodForm } from '@utils/types/contract';

export function parseHedgeBlocks(blocks?: HedgeSubPeriodForm[]): ContractForm['hedge_blocks_attributes'] | null {
  if (!blocks) return null;

  return (
    blocks.map((block) => ({
      ...block,
      average_power: block.average_power ? constrainFractionDigits(block.average_power) : block.average_power,
      dates: {
        start_date: toDate(block.start_date),
        end_date: toDate(block.end_date),
      },
    })) || []
  );
}
