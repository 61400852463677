import Installation from '@utils/types/installation';
import { UseFormReturn } from 'react-hook-form';
import { isExcludedForCountries } from './isExcludedForCountries';
import { isRequiredForValue } from './isRequiredForValue';
import { SimulationFormField, SimulationForm } from './simulations.types';

export const isFieldRequired = (
  field: SimulationFormField,
  getValues: UseFormReturn<SimulationForm>['getValues'],
  installation: Installation,
) => {
  if (
    isExcludedForCountries(field, installation) ||
    !installation.country ||
    (field.onlyForCountries && !field.onlyForCountries.includes(installation.country))
  )
    return false;

  return Boolean(isRequiredForValue(field, getValues) || field?.required || field?.rules?.required);
};
