import React from 'react';
import { Section, sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { Option } from '@utils/types/common-types';
import { PaymentDelay as PaymentDelayType } from '@utils/types/contract/paymentDelay';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { ListInput } from '../../components/Inputs/List';
import { NumericInput } from '../../components/Inputs/Numeric';
import { SectionHeader } from '../../components/SectionHeader';

export const PaymentDelay = ({ title, readOnly, form: { control, watch } }: ContractFormSectionProps) => {
  const { t } = useTranslation();

  const delayType = watch('payment_delay_attributes.delay_type');

  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="p-3">
          <div className={sectionStyles['row-3']}>
            <ListInput
              id="PaymentDelayType"
              name="payment_delay_attributes.delay_type"
              control={control}
              options={PAYMENT_DELAY_OPTIONS}
              label="payment_delay.delay_type"
              readOnly={readOnly}
              helpBox={{ title: 'payment_delay.delay_type', text: 'payment_delay.tooltip.delay_type' }}
              defaultValue="net"
            />
            <NumericInput
              id="PaymentDelay"
              name="payment_delay_attributes.delay"
              control={control}
              label="payment_delay.delay"
              suffix="common.days"
              rules={{ min: 0 }}
              min={0}
              step={1}
              readOnly={readOnly}
              tooltip={t('payment_delay.tooltip.delay')}
              defaultValue={30}
            />
            <NumericInput
              id="PaymentDeadline"
              containerClassName="w-100"
              name="payment_delay_attributes.deadline"
              control={control}
              rules={{ min: 1, max: 31 }}
              label="payment_delay.deadline"
              min={1}
              max={31}
              step={1}
              tooltip={t('payment_delay.tooltip.deadline')}
              suffix={t('payment_delay.deadline_suffix')}
              disabled={delayType !== 'end_of_month'}
              readOnly={readOnly}
            />
          </div>
        </Section>
      }
    />
  );
};

const PAYMENT_DELAY_OPTIONS: Option<PaymentDelayType['delay_type']>[] = [
  { label: 'payment_delay.label.net', value: 'net' },
  { label: 'payment_delay.label.end_of_month', value: 'end_of_month' },
];
