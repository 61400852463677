import { useState } from 'react';
import dayjs from 'dayjs';
import { initEndDate, initStartDate } from '../constants';

/**
 * This method is used to keep track of the last dates that were queried.
 * If one of the date is null, we will return the last cached dates because we don't want @tanstack/query to change the query key
 * and returning no data, while the user is still picking a date.
 */
export const useQueryDates = (startDate: Date | null, endDate: Date | null): readonly [Date, Date] => {
  // if one of the dates is null, we will return last cached dates
  const [[cachedStartDate, cachedEndDate], setCachedDates] = useState<readonly [Date, Date]>([
    startDate || initStartDate,
    endDate || initEndDate,
  ]);

  if (!startDate || !endDate) return [cachedStartDate, cachedEndDate];

  const newDates = [startDate || cachedStartDate, endDate || cachedEndDate] as const;
  const datesDiff = dayjs(newDates[0]).diff(cachedStartDate) || dayjs(newDates[1]).diff(cachedEndDate);

  if (Math.abs(datesDiff) > 0) {
    setCachedDates(newDates);
  }

  return newDates;
};
