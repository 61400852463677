import React, { useMemo, useState } from 'react';
import { Definition, DefinitionList } from '@GDM/DefinitionList';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import {
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import { all_v2_comptes_path } from '@utils/routes';
import { SubscriptionInvoice } from '@utils/types/subscriptions-invoice';
import { User } from '@utils/types/user';
import { getSubscriptionsColumns } from './getSubscriptionsColumns';

export const Subscriptions = ({ user }: { user: User }) => {
  const { t } = useTranslation(user.locale);
  const columns = useMemo<ColumnDef<SubscriptionInvoice>[]>(() => getSubscriptionsColumns(user.locale), [user.locale]);
  const invoicesRequest = useRequest<SubscriptionInvoice[]>(all_v2_comptes_path(), 'GET');

  const invoiceList = useMemo(() => invoicesRequest.data || [], [invoicesRequest.data]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const table = useReactTable({
    data: invoiceList,
    columns,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { sorting: [{ id: 'date', desc: false }] },
  });

  const pageSize = pagination.pageSize;

  return (
    <Page user={user} title="sidebar.admin_settings.subscriptions" isLoading={invoicesRequest.loading}>
      <div className="p-4">
        <div className="mb-3 fw:700 tt:uppercase">{t('admin.subscriptions.invoicing')}</div>

        <DefinitionList>
          <Definition label="admin.subscriptions.company" value={user?.societe} data-cy="subscriptions-company-name" />
          <Definition label="invoice.siren" value={user?.siren} data-cy="subscriptions-company-siren" />
          <Definition
            label="admin.subscriptions.address"
            value={user?.adresse}
            data-cy="subscriptions-company-address"
          />
          <Definition label="common.email" value={user?.email} data-cy="subscriptions-company-email" />
        </DefinitionList>

        <Table className="mt-4">
          <TableHead table={table} />
          <TableBody table={table} data-cy="subscriptions-table" />
        </Table>

        {invoiceList.length > pageSize && (
          <TableActions className="mt-3">
            <TablePageSizeSelect
              pageSize={pageSize}
              setPageSize={table.setPageSize}
              totalEntries={invoiceList.length}
            />
            <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
          </TableActions>
        )}
      </div>
    </Page>
  );
};
