import debounce from 'lodash/debounce';

/**
 * THIS IS A TEMPORARY SOLUTION TO DISPLAY UK CURRENCY FOR UK USERS.
 */
export const updateCurrency = debounce(() => {
  const elements = document.getElementsByTagName('*');

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    for (let j = 0; j < element.childNodes.length; j++) {
      const node = element.childNodes[j];

      // TEXT_NODE
      if (node.nodeType === 3) {
        const text = node.nodeValue;
        const replacedText = text?.replace(/€/gi, '£');

        if (replacedText && replacedText !== text) {
          element.replaceChild(document.createTextNode(replacedText), node);
        }
      }
    }
  }
}, 750);
