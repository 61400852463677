import React from 'react';
import { FilterContainer } from '@GDM/Filters';
import filterStyles from '@GDM/Filters/filters.module.scss';
import { Input, RadioButtons, Select } from '@GDM/forms';
import { energyOptions } from '@utils/constants/energyOptions';
import { Option } from '@utils/types/common-types';
import { usePortfolio } from '../usePortfolio';
import { useFilters } from './useFilters';

export const InstallationFilters = () => {
  const { data } = usePortfolio();
  const { type, setType, installationName, setInstallationName, bookName, setBookName } = useFilters();

  const bookNames = Array.from<string>(
    new Set(data?.reduce<string[]>((acc, { books }) => [...acc, ...(books || [])], [])) || [],
  );
  const bookOptions: Option[] = bookNames?.map((book) => ({ label: book, value: book }));

  return (
    <>
      <FilterContainer size="fit">
        <Input
          icon="Search"
          autoComplete="off"
          placeholder="common.filters.installation_name"
          value={installationName}
          onChange={(e) => {
            setInstallationName?.(e.target.value);
          }}
        />
      </FilterContainer>
      <FilterContainer size="fit">
        <Select
          className={filterStyles['filter-container']}
          placeholder="common.filters.book_name"
          options={bookOptions}
          selectedOption={bookName}
          onChange={(option) => setBookName?.(option?.value || '')}
          isClearable
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons options={energyOptions} selected={type} onChange={(e) => setType?.(e)} />
      </FilterContainer>
    </>
  );
};
