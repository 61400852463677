import { useUser } from '@context/User.context';
import useTranslation from '@hooks/useTranslation';
import { ReportMetric, CustomReportingGlobalQuery } from '@pages/CustomReporting/types';
import { isQueryTimeBased } from '@pages/CustomReporting/utils';
import { useCurrency } from '@utils/string';
import { CustomReportProps } from '../CustomReport';
import { turnReportItemNameIntoLabel, formatReportDate, formatReportValue } from '../utils';
import { ChartProps, MetricChartData } from './types';
import { prepareChartData } from './utils';

export const useReportCharts = ({
  data,
  query,
  metrics,
}: {
  metrics: Record<ReportMetric, boolean>;
} & CustomReportProps): ChartProps[] => {
  const isTimeBased = isQueryTimeBased(query);
  const isSplit = Boolean(query.split_by);
  const initialSeriesList = {} as Record<ReportMetric, MetricChartData>;

  const chartDataPerMertric: Record<ReportMetric, MetricChartData> = data.reduce(
    (obj, { metric, items }) => ({
      ...obj,
      [metric]: {
        metric,
        ...prepareChartData(items, metric, query),
      },
    }),
    initialSeriesList,
  );

  const { t } = useTranslation();
  const user = useUser();

  const transformName = (name: string, query: CustomReportingGlobalQuery, metricLabel: string): string | undefined => {
    const isTimeBased = isQueryTimeBased(query);
    const isSplit = Boolean(query.split_by);

    if (!isTimeBased && !isSplit) return t(metricLabel);
    if (!isTimeBased) return t(turnReportItemNameIntoLabel(name, query.split_by || query.focus, user.main_country));

    return t(turnReportItemNameIntoLabel(name, query.focus, user.main_country));
  };

  const transformCategory = (category: string) => {
    if (!isTimeBased && query.focus === 'contract_type') {
      return t(turnReportItemNameIntoLabel(category, query.focus, user.main_country));
    }

    if (isTimeBased) return formatReportDate(category, query.date_split);

    return category;
  };

  const fromChartDataToChartProps = (
    { metric, series, categories }: MetricChartData,
    { title, metricLabel, unit }: { title: string; metricLabel: string; unit?: string },
  ) => {
    return (
      metrics[metric] && {
        metric: metric,
        options: {
          title: {
            text: title,
            align: 'left',
          },
          xAxis: {
            type: 'category',
            categories: categories.map(transformCategory),
          },
          yAxis: {
            title: {
              text: null,
            },
          },
          plotOptions: {
            column: {
              colorByPoint: !isTimeBased && !isSplit,
              minPointLength: 3,
              stacking: 'normal',
            },
          },
        },
        series: series.map(({ name, data, ...serie }) => ({
          ...serie,
          tooltip: { valueSuffix: unit },
          name: transformName(name, query, metricLabel),
          data: data.map((value) => value && Number(formatReportValue(value, metric))),
        })),
      }
    );
  };

  const currency = useCurrency('symbol');

  return [
    fromChartDataToChartProps(chartDataPerMertric.revenue, {
      title: `${t('common.revenue')} (k€)`,
      metricLabel: 'common.revenue',
      unit: `k${currency}`,
    }),
    fromChartDataToChartProps(chartDataPerMertric.production, {
      title: `${t('common.production')} (GWh)`,
      metricLabel: 'common.production',
      unit: 'GWh',
    }),
    fromChartDataToChartProps(chartDataPerMertric.contract_nb, {
      title: t('common.contract_nb'),
      metricLabel: 'common.contract_nb',
    }),
    fromChartDataToChartProps(chartDataPerMertric.unit_price, {
      title: `${t('common.price')} (€/MWh)`,
      metricLabel: 'common.price',
      unit: `${currency}/MWh`,
    }),
    fromChartDataToChartProps(chartDataPerMertric.business_plan, {
      title: `${t('monitoring.installation.revenue.business_plan')} (MWh)`,
      metricLabel: 'monitoring.installation.revenue.business_plan',
      unit: 'MWh',
    }),
    fromChartDataToChartProps(chartDataPerMertric.power, {
      title: `${t('common.power')} (MW)`,
      metricLabel: 'common.power',
      unit: 'MW',
    }),
  ].filter(Boolean) as ChartProps[];
};
