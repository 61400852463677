import React from 'react';
import { Input, RadioButtons, RadioOption } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import { Controller } from 'react-hook-form';
import { ReactiveForm } from '../types';
import { useReactiveForm } from '../useReactiveForm';

export const TanPhiForm = ({ index }: { index: number }) => {
  const { control, trigger, watch } = useReactiveForm();

  const directionOptions: RadioOption<NonNullable<ReactiveForm['instructions']>[number]['injection']>[] = [
    { value: true, label: 'monitoring.installation.inject' },
    { value: false, label: 'monitoring.installation.withdraw' },
  ];

  const isRequired = watch('type') === 'tan_phi';

  return (
    <>
      <Row className="mt-4 mb-3">
        <Col xl={6}>
          <Controller
            name={`instructions.${index}.injection`}
            control={control}
            rules={{ validate: (v) => typeof v === 'boolean' }}
            render={({ field }) => (
              <RadioButtons
                options={directionOptions}
                selected={field.value}
                name={`injection-${index}`}
                onChange={(option) => field.onChange(option)}
              />
            )}
          />
        </Col>
      </Row>

      <div className="mb-3">
        <Row>
          <Col>
            <Controller
              name={`instructions.${index}.tanphi_min`}
              control={control}
              rules={{ required: isRequired, min: 0, max: Number(watch(`instructions.${index}.tanphi_max`) || 1) }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value ?? ''}
                  label="common.min"
                  type="number"
                  placeholder="0.00"
                  step={0.01}
                  max={1}
                  min={0}
                  onChange={(e) => field.onChange(e.target.value)}
                  hasError={!!fieldState.error}
                  data-cy="tg-phi-min"
                  full
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name={`instructions.${index}.tanphi_max`}
              control={control}
              rules={{ required: isRequired, min: Number(watch(`instructions.${index}.tanphi_min`) || 0), max: 1 }}
              render={({ field, fieldState }) => (
                <Input
                  value={field.value ?? ''}
                  label="common.max"
                  type="number"
                  placeholder="0.00"
                  step={0.01}
                  max={1}
                  min={0}
                  onChange={async (e) => {
                    field.onChange(e.target.value);
                    await trigger(`instructions.${index}.tanphi_min`);
                    trigger(`instructions.${index}.tanphi_min`);
                  }}
                  hasError={!!fieldState.error}
                  data-cy="tg-phi-max"
                  full
                />
              )}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
