/**
 * @GDM_Description remove the isGDM condition and the legacy block
 */
import React, { useContext } from 'react';
import localeContext from '@context/locale.context';
import { Badge } from '@GDM/Badge';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';
import getInvoiceDeferredStatus from 'pages/sales-management/invoicing/utils/getInvoiceDeferredStatus';
import getInvoiceType from 'pages/sales-management/invoicing/utils/getInvoiceType';

export const InvoiceTypeCell = ({ row: { original } }: CellContext<MeterInvoice, unknown>) => {
  const locale = useContext(localeContext);
  const { invoiceType, tooltip: invoiceTypeTooltip } = getInvoiceType(original, locale);
  const deferredStatus = getInvoiceDeferredStatus(original, locale);

  return (
    <div>
      {invoiceType && (
        <Badge tooltip={invoiceTypeTooltip} label={invoiceType} variant={original.cancelled ? 'danger' : undefined} />
      )}
      {deferredStatus && !original.cancelled && <Badge>{deferredStatus}</Badge>}
    </div>
  );
};
