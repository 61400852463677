import { createContext, Dispatch, SetStateAction } from 'react';
import type { ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { EventType } from '@utils/types/event';
import { TypeFilter } from '../portfolio.definitions';

export type FiltersContext = {
  type: TypeFilter;
  setType?: Dispatch<SetStateAction<TypeFilter>>;
  country: CountryCode | null;
  setCountry?: Dispatch<SetStateAction<FiltersContext['country']>>;
  installationName: string;
  setInstallationName?: Dispatch<SetStateAction<string>>;
  commissioningDates: [string, string] | null;
  setCommissioningDates?: Dispatch<SetStateAction<[string, string] | null>>;
  clientId: string;
  setClientId?: Dispatch<SetStateAction<string>>;
  bookName: string;
  setBookName?: Dispatch<SetStateAction<string>>;
  events?: EventType[];
  setEvents?: Dispatch<SetStateAction<FiltersContext['events']>>;
  filters?: {
    puiCrete: [number, number];
    energy: [number, number];
    capacityFactor: [number, number];
    coverage: [number, number];
  };
  setFilters?: Dispatch<SetStateAction<FiltersContext['filters']>>;
  contractTypes: (ContractType | null)[];
  setContractTypes: Dispatch<SetStateAction<(ContractType | null)[]>>;
};

export const filtersContext = createContext<FiltersContext>({
  type: 'all',
  country: null,
  installationName: '',
  bookName: '',
  commissioningDates: null,
  clientId: '',
  contractTypes: [],
  setContractTypes: () => {
    throw new Error('setContractTypes is not implemented');
  },
});
