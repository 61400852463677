import React from 'react';
import { Badge } from '@GDM/Badge';
import type { Contract } from '@utils/types/contract';
import dayjs from 'dayjs';

export const VolumeCell = ({ contract }: { contract: Contract }) => {
  const currentSubPeriod = contract.contract_sub_periods?.find((subPeriod) => {
    const isBetween = dayjs().isBetween(dayjs(subPeriod.start_date), dayjs(subPeriod.end_date), 'month', '[]');

    return isBetween;
  });

  const volumeRatio =
    currentSubPeriod?.volume_ratio && contract.delivery_profile === 'pay_as_percentage_based_profile'
      ? currentSubPeriod?.volume_ratio * 100
      : null;

  return (
    <div className="d-flex align-items-center gap-2">
      <Badge label={`sales_management.${contract.delivery_profile}`} />
      {contract.power_augmentation_percentage && (
        <Badge tooltip="sales_management.power_augmentation_title">{contract.power_augmentation_percentage}%</Badge>
      )}
      {volumeRatio && <Badge tooltip="sales_management.percentage_based_tooltip">{volumeRatio}%</Badge>}
    </div>
  );
};
