import React from 'react';
import classNames from 'classnames';
import { Modal as ModalLegacy, ModalProps } from 'reactstrap';
import styles from './modal.module.scss';

export const ModalWrapper = ({ children, className, ...props }: ModalProps) => {
  return (
    <ModalLegacy
      wrapClassName={classNames(styles.wrapper, className)}
      modalClassName={styles.modal}
      contentClassName={styles['modal-content']}
      backdropClassName={styles['backdrop']}
      centered
      {...props}
    >
      {children}
    </ModalLegacy>
  );
};
