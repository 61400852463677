/**
 * THIS FILE IS AUTO_GENERATED DO NOT UPDATE IT
 * RUN THE FOLLOWING COMMAND TO GENERATE IT : rails runner "./scripts/front_static/generate_meter_models.rb"
 */

export const meterModels = [
  'AM_206',
  'E650',
  'E850',
  'SL7000',
  'MT830',
  'MT831',
  'ZMD405',
  'ZMD410',
  'LZQJ-XC',
  'PROMETER_W',
] as const;
