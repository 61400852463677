import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import { get_index_facturation_data_v2_dashboard_meter_index_path } from '@utils/routes';
import { IndexType } from '@utils/types/installation_index';
import dayjs from 'dayjs';

export type IndexData = DataLast['data'] & {
  type: IndexType;
};

type DataLast = {
  typecompteur: string;
  type: IndexType;
  compteur: string;
  date: string;
  data?: {
    P: number;
    HPH: number;
    HCH: number;
    HPE: number;
    HCE: number;
    total: string;
  };
};

export type DataList = {
  [key in IndexType]: DataLast;
};

type ResponseBodyInvoicingData = {
  all_data_last: DataList;
  all_data_prev: DataList;
  all_data_dif: DataList;
};

export const useIndexInvoicingData = (date?: Date | null): CommonRequestHookResponse<ResponseBodyInvoicingData> => {
  const params = new URLSearchParams(window.location.search.substring(1));
  const meterName = params.has('meter') ? params.get('meter') : null;
  const formattedDate = dayjs(date).format('MM/YYYY');
  const requestParams = { date: formattedDate, ...(meterName ? { refcompteur: meterName } : {}) } as Record<
    string,
    string
  >;

  const urlParams = new URLSearchParams(requestParams).toString();
  const url = `${get_index_facturation_data_v2_dashboard_meter_index_path()}?${urlParams}`;

  return useRequest<ResponseBodyInvoicingData>(url, 'GET', false);
};
