import React from 'react';
import MarketPlayer from '@utils/types/market_player';
import { RevenueProvider } from './context';
import { RevenueContextType } from './revenue.types';
import { RevenueContent } from './RevenueContext';

export const Revenue = ({
  sourceType,
  identifier,
  marketPlayer,
}: {
  sourceType: RevenueContextType['sourceType'];
  identifier?: string;
  marketPlayer?: MarketPlayer;
}) => {
  return (
    <RevenueProvider sourceType={sourceType} identifier={identifier} marketPlayer={marketPlayer}>
      <RevenueContent />
    </RevenueProvider>
  );
};
