import { useState, useCallback } from 'react';

/**
 * @deprecated use react-hook-form `useForm` instead
 */
export const useFormLegacy = <T extends Record<string, string | boolean | null | File>, K extends keyof T>(
  value: T,
): [T, React.Dispatch<React.SetStateAction<T>>, (key: keyof T, value: T[K]) => void] => {
  const [formData, setFormData] = useState<T>(value);

  const setFormField = useCallback(
    (key: keyof T, value: T[K]) => {
      const clonedFormData = { ...formData };

      clonedFormData[key] = value;

      setFormData(clonedFormData);
    },
    [value],
  );

  return [formData, setFormData, setFormField];
};
