import React, { DetailedHTMLProps, InputHTMLAttributes, forwardRef, useContext, useState } from 'react';
import { Badge } from '@GDM/Badge';
import { Input } from '@GDM/forms';
import classNames from 'classnames';
import { dateParser } from './date-parser';
import { datePickerContext } from './date-picker.context';
import styles from './date-picker.module.scss';
import { PreDefinedPeriods } from './date-picker.types';

const parseValue = (value: InputHTMLAttributes<HTMLInputElement>['value']): string | null =>
  typeof value === 'string' ? value.replace(' - ', ' / ') : null;

export const DisplayInput = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { isClearable?: boolean }
>((props, ref) => {
  const { isClearable, ...HTMLProps } = props;
  const parsedPropValue = parseValue(props.value);
  const [rawValue, setRawValue] = useState<string | null>(parsedPropValue);
  const [displayValue, setDisplayValue] = useState<string | null>(parsedPropValue);

  if (parsedPropValue !== rawValue) {
    setRawValue(parsedPropValue);
    setDisplayValue(parsedPropValue);
  }

  const { hasError, onChange, picks, placeholderText, selectedPeriod, setSelectedPeriod, showPeriodBadge, size } =
    useContext(datePickerContext);

  const displayInputPrefix = !!showPeriodBadge && !!selectedPeriod && (
    <Badge label={selectedPeriod} variant={hasError ? 'danger' : 'primary-1'} />
  );

  return (
    <Input
      {...HTMLProps}
      full
      containerClassName={classNames(isClearable && styles['is-clearable'])}
      hasError={hasError}
      icon="Calendar"
      placeholder={placeholderText}
      prefix={displayInputPrefix || null}
      ref={ref}
      size={size}
      value={displayValue ?? ''}
      onChange={(v) => {
        setDisplayValue(v.target.value);
        const { shouldChange, value } = dateParser(v, picks);

        if (shouldChange) {
          onChange?.(value as Date & [Date | null, Date | null]);
          setSelectedPeriod?.(PreDefinedPeriods.Custom);
        }
      }}
    />
  );
});
