import { useUser } from '@context/User.context';
import useTranslation from '@hooks/useTranslation';
import { useCurrency } from '@utils/string';
import { DataTypeFiltersForm as DataTypeFiltersForm } from '@utils/types/dataFilter';
import { DataPeriod } from '@utils/types/dataPeriod';
import type { Meter } from '@utils/types/meter';
import { TimeSeries } from '@utils/types/timeSeries';
import { UseFormReturn } from 'react-hook-form';
import { chartColors } from '../../constants';
import type { ProductionFilters } from '../../Production';
import { ProductionTimeSeriesRecord } from '../../production.types';
import { exportCountrySourceFromKey } from '../MarketPriceCurveSelect';

/**
 * Exports a named list of curve (data source) definitions to be used in the
 * Production chart. The list is constricted to only Highcharts Series options types
 * and when exported, intellisense will provide the key names explicitly.
 * */
export const useSeriesList = ({
  data,
  dataPeriod,
  availability,
  inverterAll,
  performanceRatios,
  meter,
  showForecast,
  form,
  selectedMarketPriceCurve,
  marketPricesCurve,
}: {
  availability?: TimeSeries;
  data?: Partial<ProductionTimeSeriesRecord>;
  dataPeriod: DataPeriod;
  inverterAll?: TimeSeries;
  performanceRatios: TimeSeries | null;
  meter?: Meter;
  showForecast: boolean;
  form: UseFormReturn<DataTypeFiltersForm>;
  selectedMarketPriceCurve: ProductionFilters['selectedMarketPriceCurve'];
  marketPricesCurve: TimeSeries;
}) => {
  const { t } = useTranslation();
  const currency = useCurrency();
  const { permissions } = useUser();
  const { watch: getFilterValues } = form;

  const isMonthly = dataPeriod === 'monthly';
  const isDaily = dataPeriod === 'daily';
  const isPeriodInMinutes = dataPeriod === 'minutes';

  const showPotentialPower =
    permissions?.includes('display:view_monitoring_dashboard_meter_potential_power') &&
    getFilterValues('showPotentialPower');
  const showAvailability = getFilterValues('showPotentialPower') && isDaily;
  const showPerformanceRatio = isDaily && showPotentialPower;

  // Please keep this list sorted alphabetically
  const definitions = {
    AVAILABILITY: {
      type: 'column',
      name: t('monitoring.installation.availability'),
      color: chartColors.availabilityColor,
      yAxis: showPerformanceRatio && showAvailability ? 'AVAILABILITY' : undefined,
      data: availability,
      visible: showAvailability,
      tooltip: { valueSuffix: '%' },
    },
    BILLABLE_PRODUCTION: {
      name: t('monitoring.installation.prod_billable'),
      data: data?.invoicedActiveEnergyProduction?.values,
      type: 'column',
      zIndex: -1,
      tooltip: { valueSuffix: ' kWh' },
    },
    BUSINESS_PLAN: {
      color: chartColors.businessPlanColor,
      opacity: 0.9,
      name: t('monitoring.bp.title'),
      lineWidth: 3,
      data: data?.businessPlanActiveEnergyProduction?.values,
      type: 'line',
      zIndex: 5,
      visible: permissions?.includes('display:view_monitoring_dashboard_meter_business_plan') && isMonthly,
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
      gapSize: 0,
    },
    CONSUMPTION: {
      lineWidth: 1.2,
      color: chartColors.consoColor,
      name: t('monitoring.installation.consumption'),
      data: data?.rawActiveEnergyConsumption?.values,
      type: isMonthly ? 'column' : 'line',
      zIndex: 1,
      visible: getFilterValues('showConso'),
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    CORRECTED_HISTORY: {
      lineWidth: 0.8,
      dashStyle: 'Dash',
      color: chartColors.correctedProductionHistoryColor,
      name: t('monitoring.installation.prod_corrected_history'),
      data: data?.corrected5YearsAverageActiveEnergyProduction?.values,
      type: 'line',
      zIndex: 1,
      visible: getFilterValues('showProdCorrectedHistory') && !isMonthly,
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    CORRECTED_PRODUCTION: {
      color: chartColors.correctedProductionColor,
      name: t('monitoring.installation.prod_corrected'),
      lineWidth: 4,
      opacity: 0.5,
      data: data?.correctedActiveEnergyProduction?.values,
      type: 'line',
      zIndex: 1,
      visible: getFilterValues('showProdCorrected') && !isMonthly,
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    EXTRAPOLATED_PRODUCTION: {
      lineWidth: 0.8,
      dashStyle: 'Dash',
      color: chartColors.extrapolatedProductionColor,
      name: t('monitoring.installation.prod_extrapolated'),
      zIndex: 0,
      data: data?.extrapolatedActiveEnergyProduction?.values,
      type: 'line',
      visible: getFilterValues('showProdExtrapolated') && dataPeriod === 'minutes',
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    FORECAST: {
      color: chartColors.forecastColor,
      name: t('monitoring.installation.prod_forecast'),
      dashStyle: 'Dash',
      lineWidth: 0.5,
      data: data?.forecastedActiveEnergyProduction?.values,
      type: 'line',
      zIndex: 1,
      visible: !showForecast ? false : getFilterValues('showProdForecast'),
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    DISPATCHED_PRODUCTION: {
      color: chartColors.dispatchColor,
      name: t('monitoring.installation.prod_dispatch'),
      dashStyle: 'LongDashDot',
      lineWidth: 0.5,
      data: data?.dispatchedActiveEnergyProduction?.values,
      type: 'line',
      zIndex: 1,
      visible: !showForecast ? false : getFilterValues('showProdDispatch'),
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    MARKET_PRICES: {
      name: `${t('common.market_prices')} (${exportCountrySourceFromKey(selectedMarketPriceCurve).label} ${
        exportCountrySourceFromKey(selectedMarketPriceCurve).country
      })`,
      lineWidth: 1.2,
      color: chartColors.marketPricesColor,
      yAxis: getFilterValues('showMarketPrices') ? 'MARKET_PRICES' : undefined,
      type: 'line',
      zIndex: 1,
      visible: getFilterValues('showMarketPrices'),
      data: marketPricesCurve,
      tooltip: { valueSuffix: ` ${currency}/MWh` },
    },
    PERFORMANCE_RATIO: {
      name: t('monitoring.installation.pr'),
      type: 'column',
      color: chartColors.marketPricesColor,
      zIndex: 10,
      yAxis: showPerformanceRatio && showAvailability ? 'PERFORMANCE_RATE' : undefined,
      data: performanceRatios ?? undefined,
      visible: showPerformanceRatio,
      tooltip: { valueSuffix: '%' },
    },
    POTENTIAL_POWER: {
      color: chartColors.potentialPowerColor,
      fillColor: 'var(--grey-light-alpha)',
      name: meter?.installation?.energy === 'wind' ? t('common.benchmark') : t('monitoring.installation.productible'),
      lineWidth: 1.2,
      data: data?.potentialActiveEnergyProduction?.values,
      type: isMonthly ? 'column' : 'area',
      zIndex: -1,
      visible: showPotentialPower,
      states: { inactive: { opacity: 1 } },
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    PRODUCTION: {
      color: chartColors.productionColor,
      lineWidth: 1.2,
      name: t(isMonthly ? 'monitoring.installation.raw_load_curve' : 'monitoring.installation.load_curve'),
      data: data?.rawActiveEnergyProduction?.values,
      type: isMonthly ? 'column' : 'line',
      zIndex: isMonthly ? -1 : 10,
      visible: getFilterValues('showProduction'),
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    REACTIVE_MINUS: {
      color: chartColors.reactiveLightColor,
      name: t('monitoring.installation.reactive_minus'),
      lineWidth: 1.2,
      data: data?.rawReactiveNegativeEnergyProduction?.values,
      type: isMonthly ? 'column' : 'line',
      visible:
        permissions?.includes('display:view_monitoring_dashboard_meter_reactive') &&
        getFilterValues('showReactive') &&
        !isMonthly,
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kVAR' : ' kVARh' },
    },
    REACTIVE_PLUS: {
      color: chartColors.reactiveColor,
      name: t('monitoring.installation.reactive_plus'),
      lineWidth: 1.2,
      data: data?.rawReactivePositiveEnergyProduction?.values,
      type: isMonthly ? 'column' : 'line',
      visible:
        permissions?.includes('display:view_monitoring_dashboard_meter_reactive') &&
        getFilterValues('showReactive') &&
        !isMonthly,
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kVAR' : ' kVARh' },
    },
    SCADA: {
      name: t('common.scada'),
      data: inverterAll,
      color: chartColors.scadaColor,
      lineWidth: 2,
      zIndex: 3,
      // If the only load curve is coming from inverters we display monthly scada data as a lines
      // because inverter API only sends realtime data
      type: isMonthly && data?.rawActiveEnergyProduction ? 'column' : 'line',
      // Since inverter data is not monthly we want to hide it except when it is the only load curve
      visible: (!isMonthly || !data?.rawActiveEnergyProduction) && getFilterValues('showInverters'),
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    SELF_CONSUMPTION: {
      lineWidth: 1.2,
      color: chartColors.selfConsumptionColor,
      name: t('monitoring.installation.self_consumption'),
      data: data?.rawActiveEnergySelfConsumption?.values,
      zIndex: 0,
      type: 'area',
      visible: getFilterValues('showSelfConsumption'),
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    SURPLUS: {
      lineWidth: 1.2,
      color: chartColors.surplusColor,
      name: t('monitoring.installation.surplus'),
      data: data?.rawActiveEnergySurplus?.values,
      type: 'line',
      zIndex: 1,
      visible: getFilterValues('showSurplus'),
      tooltip: { valueSuffix: isPeriodInMinutes ? ' kW' : ' kWh' },
    },
    TENSION: {
      color: chartColors.tensionColor,
      name: t('monitoring.installation.tension'),
      yAxis: getFilterValues('showTension') ? 'TENSION' : undefined,
      lineWidth: 1.5,
      data: data?.rawTensionProduction?.values,
      type: 'line',
      zIndex: 1,
      visible: getFilterValues('showTension'),
      tooltip: { valueSuffix: ' V' },
    },
  } as const satisfies Record<string, Highcharts.SeriesOptionsType>;

  return { definitions };
};
