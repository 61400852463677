import React from 'react';
import { ValueWithUnit } from '@GDM/ValueWithUnit';

export const EnergyCell = ({ color, value }: { value: number | null; color?: 'red' | 'green' | 'default' }) => {
  return (
    <div className="ta:right">
      <ValueWithUnit value={value} unit="kWh" className={`text-${color}`} />
    </div>
  );
};
