import React from 'react';
import { Input, useInternalFormContext } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';

export const SerialNumberInput = () => {
  const { disabled } = useInstallationField('serial_number');
  const form = useInstallationForm();
  const { country } = useInternalFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="serial_number"
      control={form.control}
      rules={{ required: ['IT', 'PT'].includes(country || '') }}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          label="admin.installations.meter_serial_nb"
          size="lg"
          full
          data-cy="meter-serial_number"
          disabled={disabled}
          hasError={!!fieldState.error}
          errorMessage={
            fieldState.error?.type === 'required'
              ? t('common.is_required_dynamic', { key: t('admin.installations.meter_serial_nb') })
              : fieldState.error?.message
          }
        />
      )}
    />
  );
};
