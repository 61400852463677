import React from 'react';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './alt-content.module.scss';

/** Displays a box with the desired text centered (or 'No data' by default). */
export const AltContent = ({
  children,
  className,
  label,
  'data-cy': dataCy,
}: React.PropsWithChildren<{
  className?: string;
  label?: string;
  'data-cy'?: string;
}>) => {
  const { t } = useTranslation();
  const classList = classNames('p-2', styles?.['alt-content'], className);

  return (
    <div className={classList} data-cy={dataCy}>
      {children || (label && t(label)) || t('common.no_data')}
    </div>
  );
};
