import { COUNTRY_CODES } from '@utils/constants/countries';
import type { OwnerFormField } from '../ownerForm.types';

const everyCountriesExceptFrance = COUNTRY_CODES.filter((country) => country !== 'FR');

export const COMMON_FIELDS: OwnerFormField[] = [
  { name: 'country', required: true },
  { name: 'company_number', required: true },
  { name: 'bank' },
  { name: 'erp_code' },
  { name: 'swift_bic' },
  { name: 'invoicing_emails' },
  { name: 'iban' },
  {
    name: 'short_name',
    required: true,
    info: { title: 'owner.short_name', text: 'admin.installations.owner_short_name_info' },
    onlyForCountries: everyCountriesExceptFrance,
  },
  { name: 'long_name', required: true, onlyForCountries: everyCountriesExceptFrance },
  { name: 'street_address', required: true, onlyForCountries: everyCountriesExceptFrance },
  { name: 'postcode', required: true, onlyForCountries: everyCountriesExceptFrance },
  { name: 'vat_number', required: true, onlyForCountries: everyCountriesExceptFrance },
  { name: 'city', onlyForCountries: everyCountriesExceptFrance },
];
