import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ContractForm } from '@utils/types/contract/form';
import { Control } from 'react-hook-form';
import { ListInput } from '../../../components/Inputs/List';

export const CalculationBase = ({
  control,
  readOnly,
  calcBase,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
  calcBase: ContractForm['calc_base'] | undefined;
}) => {
  return (
    <div className={sectionStyles.row}>
      <ListInput
        id="CalcBasePicker"
        name="calc_base"
        control={control}
        options={[
          { value: 'load_curve', label: 'common.load_curve' },
          { value: 'index', label: 'common.index' },
        ]}
        label="sales_management.calc_base_select"
        classNamePrefix="select-calc-base"
        readOnly={readOnly}
        isSearchable
      />
      {calcBase === 'load_curve' && (
        <ListInput
          id="CutTimePicker"
          name="cut_time"
          control={control}
          options={[
            { value: '00:00', label: '00:00' },
            { value: '02:00', label: '02:00' },
          ]}
          label="sales_management.cut_time"
          classNamePrefix="select-frequency"
          readOnly={readOnly}
          isSearchable
        />
      )}
      {calcBase === 'index' && (
        <ListInput
          id="InvoicingPeriodPicker"
          name="invoicing_period"
          control={control}
          options={[
            { value: 'monthly', label: 'common.index_monthly' },
            { value: 'half_yearly', label: 'common.index_half_yearly' },
            { value: 'yearly', label: 'common.index_yearly' },
          ]}
          label="common.index_type"
          classNamePrefix="select-frequency"
          readOnly={readOnly}
          isSearchable
          defaultValue="monthly"
        />
      )}
    </div>
  );
};
