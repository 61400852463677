/**
 * Provides a date formatted like a user would want, _e.g_ 12/12/2022
 */
export const formatDisplayDate = (input: string | Date): string => {
  const date = new Date(input);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
