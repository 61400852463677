import React, { useMemo } from 'react';
import { Alert } from '@GDM/Alert';
import { Badge } from '@GDM/Badge';
import { useRequest } from '@hooks/useRequest';
import Page from '@pages/Page';
import { iconDictionary } from '@utils/constants';
import { by_name_v2_books_path, v2_compteur_path, v2_installation_path } from '@utils/routes';
import { getTimezone, getUserTimezone } from '@utils/timezones';
import Book from '@utils/types/book';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import { Meter } from '@utils/types/meter';
import { User } from '@utils/types/user';
import isEmpty from 'lodash/isEmpty';
import { dashboardMeterContext, DashboardMeterContextType } from './dashboardMeter.context';
import { TabContainer } from './TabContainer';
import { TabNav } from './TabNav';

const DashboardMeter = ({ user }: { user: User }) => {
  const queryParams = new URLSearchParams(location.search);
  const bookName = queryParams.get('book');
  const meterName = queryParams.get('meter');
  const installationName = queryParams.get('installation');

  const METER_REQ_PARAMS = {
    with_has_bp: true,
    with_reactive_constraint: true,
    with_has_only_dummy_contracts: true,
    with_nb_ongoing_events: true,
  };
  const BOOK_REQ_PARAMS = {
    name: bookName,
    with_has_only_dummy_contracts: true,
    with_has_m0: true,
    with_nb_ongoing_events: true,
  };

  const {
    data: meter,
    loading: meterLoading,
    error: meterError,
  } = useRequest<Meter>(meterName ? v2_compteur_path(meterName, { params: METER_REQ_PARAMS }) : '', 'GET');

  const { data: book, loading: bookLoading } = useRequest<Book>(
    bookName ? by_name_v2_books_path(BOOK_REQ_PARAMS) : '',
    'GET',
  );

  const { data: installation, loading: installationLoading } = useRequest<InstallationWithMeterInfo>(
    installationName ? v2_installation_path(installationName) : '',
    'GET',
  );

  // set timezone
  let timezone = getUserTimezone(user);
  if (meter && meter.country) timezone = getTimezone(meter.country);
  if (book && book.installation_countries.length > 0) timezone = getTimezone(book.installation_countries[0]);

  let showForecast = false;
  if (user.permissions?.includes('display:forecast')) {
    if (bookName) showForecast = true;
    if (meterName && meter?.installation?.show_forecast) showForecast = true;
  }

  const contextValue = useMemo<DashboardMeterContextType>(() => {
    return { book, meter, installation, showForecast, timezone };
  }, [book, installation, meter, showForecast, timezone]);

  const isLoading = Boolean(
    (meterName && meterLoading) || (bookName && bookLoading) || (installationName && installationLoading),
  );
  const noData = !bookName && !installationName && (!meterName || isEmpty(meter)) && !isLoading;

  let titleIcon = iconDictionary.other;
  if (bookName) titleIcon = iconDictionary.book;
  if (installationName) titleIcon = iconDictionary[installation?.energy || 'other'];
  if (meter?.installation?.energy === 'solar') titleIcon = iconDictionary.solar;
  if (meter?.installation?.energy === 'wind') titleIcon = iconDictionary.wind;
  if (meter?.installation?.energy === 'hydro') titleIcon = iconDictionary.hydro;

  return (
    <Page
      title={meterName || bookName || installationName || 'errors.installation.not_found'}
      titleIcon={titleIcon}
      user={user}
      isLoading={isLoading}
      layout={noData ? 'no-background' : 'classic'}
      subtitle={installation?.status === 'draft' && <Badge label="common.draft" variant="danger" />}
    >
      <dashboardMeterContext.Provider value={contextValue}>
        {noData && <Alert variant="warning" label={meterError?.code || 'errors.installation.not_found_message'} />}
        {!noData && !isLoading && (
          <div className="pt-1">
            <TabNav>
              <div className="p-4">
                <TabContainer />
              </div>
            </TabNav>
          </div>
        )}
      </dashboardMeterContext.Provider>
    </Page>
  );
};

export default DashboardMeter;
