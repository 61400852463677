import { tString } from '@hooks/useTranslation';
import jsonToCsv, { type HeadCsv } from '@utils/json-to-csv';
import { Locale } from '@utils/types/common-types';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice } from '@utils/types/meter-invoice';
import compact from 'lodash/compact';
import getCommonFields from './commonFields';

const loadCurveCsvExport = (
  filteredInvoices: MeterInvoice[],
  locale: Locale,
  direction: Contract['direction'] | 'all',
): string => {
  const t = tString(locale);

  const fields = compact<HeadCsv<MeterInvoice>>([
    ...getCommonFields(locale, direction),
    { label: t('sales_management.buyer'), key: 'contract_buyer_short_name' },
    direction !== 'buy' ? { label: t('monitoring.invoicing.delta_index'), key: 'volume_delta_prod_vs_index' } : null,
    direction !== 'buy' ? { label: t('monitoring.invoicing.raw_curve'), key: 'volume_delta_prod_vs_load_curve' } : null,
    direction !== 'buy' ? { label: t('monitoring.invoicing.clipping'), key: 'volume_clipped' } : null,
    direction !== 'buy'
      ? { label: t('monitoring.invoicing.negative_price'), key: 'volume_produced_at_neg_prices' }
      : null,
    direction !== 'buy' ? { label: t('monitoring.invoicing.dispo_enedis'), key: 'volume_coverage' } : null,
    direction !== 'buy' ? { label: t('monitoring.invoicing.dispo_enedis_raw'), key: 'volume_raw_coverage' } : null,
    direction !== 'buy' ? { label: t('monitoring.invoicing.invoicing_type'), key: 'invoicing_type' } : null,
    direction !== 'buy'
      ? {
          label: t('monitoring.invoicing.report'),
          key: 'deferred_status',
          transform: (v) => (v == 'deferred' || v == 'partially_deferred' ? 'yes' : ''),
        }
      : null,
  ]);

  return jsonToCsv(filteredInvoices, fields);
};

export default loadCurveCsvExport;
