import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@GDM/Button/Button';
import { Section, sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { endOfPreviousMonth } from '@utils/date/endOfPreviousMonth';
import { startOfNextMonth } from '@utils/date/startOfNextMonth';
import { formatSystemDate } from '@utils/formatters/formatSystemDate';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useFieldArray } from 'react-hook-form';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { NumericInput } from '../../components/Inputs/Numeric';
import { SectionHeader } from '../../components/SectionHeader';
import { HedgeBlock } from './components/HedgeBlock';
import { HedgeBlockHeader } from './components/HedgeBlockHeader/HedgeBlockHeader';
import { makeHedgeBlock } from './helpers/makeHedgeBlock';
import actionsStyles from './styles/hedge-blocks-actions.module.scss';

export const HedgeBlocks = ({
  title,
  readOnly,
  form: { control, trigger, getFieldState, watch },
  globals: { installation },
}: ContractFormSectionProps) => {
  const [currency, startDate, endDate] = watch(['currency', 'start_date', 'end_date']);
  const contractStartDate = startDate || new Date();
  const contractEndDate = endDate || new Date();

  const [collapsed, setCollapsed] = useState<Set<string>>(() => {
    if ((hedgeBlocks?.fields && hedgeBlocks.fields.length > 5) || readOnly) {
      return new Set(hedgeBlocks?.fields.map((p) => p.field_internal_id));
    }

    return new Set();
  });

  const hedgeBlocks = useFieldArray({
    control: control,
    name: 'hedge_blocks_attributes',
    keyName: 'field_internal_id',
  });

  const collapseAll = useCallback(() => {
    setCollapsed(new Set(hedgeBlocks?.fields.map((p) => p.field_internal_id)));
  }, [hedgeBlocks.fields]);

  const unCollapseAll = (): void => {
    setCollapsed(new Set());
  };

  const toggleBlock = (id: string): void => {
    setCollapsed((collapsedSet) => {
      const successfullyUnCollapsed = collapsedSet.delete(id);

      if (successfullyUnCollapsed) {
        return new Set(collapsedSet);
      }

      return new Set(collapsedSet.add(id));
    });
  };

  const insertBlockBefore = (index: number): void => {
    const newBlock = makeHedgeBlock({
      start_date: formatSystemDate(startOfNextMonth(contractStartDate)),
      end_date: formatSystemDate(endOfPreviousMonth(contractEndDate)),
    });

    if (newBlock) hedgeBlocks.insert(index, newBlock);
    trigger('hedge_blocks_attributes');
  };

  const appendHedgeBlock = async (): Promise<void> => {
    let start = formatSystemDate(startOfNextMonth(contractStartDate));
    const end = formatSystemDate(endOfPreviousMonth(contractEndDate));

    const lastBlock = hedgeBlocks.fields.at(-1);
    const lastBlockEndDate = dayjs(lastBlock?.dates?.end_date).tz('UTC').endOf('day').toDate();

    const lastPossibleBlockEndDate = endOfPreviousMonth(contractEndDate);
    const nextPossibleBlockStartDate = startOfNextMonth(lastBlock?.dates?.end_date);

    if (lastBlockEndDate < lastPossibleBlockEndDate) start = formatSystemDate(nextPossibleBlockStartDate);

    const block = makeHedgeBlock({
      start_date: start,
      end_date: end,
    });

    if (block) hedgeBlocks.append(block);

    // This makes sure the validation is triggered in the next loop, which is needed to make
    // sure the new hedge dates are validated.
    if (await trigger('hedge_blocks_attributes')) trigger('hedge_blocks_attributes');
  };

  const removeBlock = (index: number): void => {
    hedgeBlocks.remove(index);
    trigger('hedge_blocks_attributes');
  };

  useEffect(() => {
    if ((hedgeBlocks?.fields && hedgeBlocks.fields.length > 5) || readOnly) collapseAll();
  }, [collapseAll, hedgeBlocks.fields, readOnly]);

  const { t } = useTranslation();

  return (
    <FormSectionLayout
      title={title}
      headerActions={
        hedgeBlocks?.fields.length > 0 && (
          <SectionHeader>
            <Button
              tooltip="common.un_collapse_all"
              icon="ChevronsDown"
              floating
              onClick={unCollapseAll}
              disabled={collapsed.size === 0}
            />
            <Button
              tooltip="common.collapse_all"
              className="ml-1"
              icon="ChevronsUp"
              floating
              onClick={collapseAll}
              disabled={collapsed.size === hedgeBlocks.fields.length}
            />
          </SectionHeader>
        )
      }
      body={
        <Section className="p-0">
          <div
            className={classNames(sectionStyles.row, {
              'p-3': hedgeBlocks?.fields.length > 0,
              'pb-2': hedgeBlocks?.fields.length > 0,
            })}
          >
            {Boolean(hedgeBlocks?.fields.length) && (
              <NumericInput
                id="SpotFee"
                name="hedge_contract.spot_fee"
                control={control}
                label="common.spot_fee"
                step={0.01}
                min={0}
                tooltip="sales_management.hedge_info.spot_fee"
                data-cy="hedge-spot-fee"
                suffix={`${getSymbol(currency)}/MWh`}
                autoComplete="off"
                readOnly={readOnly}
              />
            )}
          </div>

          <div className={sectionStyles['section']}>
            {hedgeBlocks?.fields?.map((block, index) => {
              const minDate = startOfNextMonth(contractStartDate);
              const maxDate = endOfPreviousMonth(contractEndDate);
              const isCollapsed = collapsed.has(block.field_internal_id);
              const blockId = block.field_internal_id;
              const blockError = getFieldState(`hedge_blocks_attributes.${index}`)?.error;
              const title = `${t('sales_management.block')} ${index + 1}`;
              const badgeLabel = block.market_future?.name;
              const price = Number(block.price ?? '-');
              const averagePower = Number(block.average_power ?? '-');
              const startDate = block.dates?.start_date;
              const endDate = block.dates?.end_date;
              const installationPMax = (installation as InstallationWithMeterInfo)?.p_max;

              return (
                <HedgeBlock
                  key={blockId}
                  index={index}
                  isCollapsed={isCollapsed}
                  minDate={minDate}
                  maxDate={maxDate}
                  pMax={installationPMax}
                  averagePower={averagePower}
                  control={control}
                  readOnly={readOnly}
                  currency={currency}
                  header={
                    <HedgeBlockHeader
                      title={title}
                      badgeLabel={badgeLabel}
                      price={price}
                      averagePower={averagePower}
                      startDate={startDate}
                      endDate={endDate}
                      isCollapsed={isCollapsed}
                      toggle={() => toggleBlock(blockId)}
                      insertBefore={() => insertBlockBefore(index)}
                      remove={() => removeBlock(index)}
                      error={blockError}
                      readOnly={readOnly}
                      currency={currency}
                    />
                  }
                />
              );
            })}
          </div>

          {!readOnly && (
            <div
              className={classNames(sectionStyles['section-footer'], {
                [sectionStyles['no-border']]: !hedgeBlocks?.fields?.length,
              })}
            >
              <div className={classNames('p-3', actionsStyles.wrapper)}>
                <Button
                  size="sm"
                  variant="primary-2"
                  text="sales_management.add_hedge_block"
                  onClick={appendHedgeBlock}
                  data-cy="add-hedge-block"
                />
              </div>
            </div>
          )}
        </Section>
      }
    />
  );
};
