import { InverterData } from '@utils/types/inverterData';

export const getInverterSeriesFromDate = (inverterData: InverterData): Highcharts.SeriesOptionsType[] | undefined => {
  if (!inverterData) return;

  const invertersList = inverterData['invlist'];
  const invertersMaxPower = inverterData['puissinvlist'];

  if (!invertersList) return;

  const series = invertersList.map<Highcharts.SeriesOptionsType>((inverterName: string) => {
    const data = inverterData?.['invdata']?.[inverterName]?.map((d: [number, number]) => [d[0] * 1000, d[1]]);

    return {
      name: inverterName,
      data,
      max_power: invertersMaxPower[inverterName],
      type: 'line',
      tooltip: { valueSuffix: ' kW' },
    };
  });

  return series;
};
