import { ColumnDef, Row } from '@tanstack/react-table';

/**
 * sort an array of rows by absolute value and by putting falsy values at the end
 */
function absoluteSortTable<T extends Record<string, unknown>>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: ColumnDef<T>['id'],
  desc?: boolean,
): number {
  const a =
    rowA
      .getAllCells()
      .find((cell) => cell.column.id === columnId)
      ?.getValue() ?? null;
  const b =
    rowB
      .getAllCells()
      .find((cell) => cell.column.id === columnId)
      ?.getValue() ?? null;

  if (a !== 0 && !a && b !== 0 && !b) {
    return 0;
  }

  if (a !== 0 && !a) {
    return desc ? -1 : 1;
  }

  if (b !== 0 && !b) {
    return desc ? 1 : -1;
  }

  if (a < b) return -1;
  if (a > b) return 1;

  return 0;
}

export default absoluteSortTable;
