import type { AcceptableUnits } from '@GDM/ValueWithUnit';
import { specialUnitsMap } from '@GDM/ValueWithUnit/config';
import { tString } from '@hooks/useTranslation';
import get from 'lodash/get';
import replaceAccents from './replaceAccents';
import { Locale } from './types/common-types';
import RecursiveKeyOf from './types/RecursiveKeyOf';

const DEFAULT_DELIMITER = ';';

export type HeadCsv<JSONObjectType extends Record<string, unknown>> = {
  label: string;
  key?: RecursiveKeyOf<JSONObjectType>;
  value?: string;
  getValue?: (row: JSONObjectType) => string;
  // value must be infered
  transform?: (value: JSONObjectType[RecursiveKeyOf<JSONObjectType>], row: JSONObjectType) => string | number;
  unit?: AcceptableUnits;
};

type JSONToCSVOptions = {
  keepAccents?: boolean;
  delimiter?: string;
  locale?: Locale;
};

function writeHeader<JSONObjectType extends Record<string, unknown>>(
  head: HeadCsv<JSONObjectType>[],
  delimiter: string = DEFAULT_DELIMITER,
  locale?: JSONToCSVOptions['locale'],
) {
  const t = tString(locale);

  if (head.length > 0) {
    let line = '';

    head.forEach((item, index) => {
      line += t(item.label);

      if (item.unit) {
        line += ` (${specialUnitsMap.get(item.unit)?.default ?? item.unit})`;
      }

      if (index === head.length - 1) {
        line += '\n';
      } else {
        line += delimiter;
      }
    });

    return line;
  }

  return '';
}

// convert json to csv
function jsonToCsv<JSONObjectType extends Record<string, unknown>>(
  json: JSONObjectType[],
  header: HeadCsv<JSONObjectType>[],
  options?: JSONToCSVOptions,
): string {
  let csv = '';
  const delimiter = options?.delimiter || DEFAULT_DELIMITER;

  csv += writeHeader(header, delimiter, options?.locale);

  json.forEach((item) => {
    header.forEach((headItem, index) => {
      let value = null;

      if (headItem.value) {
        value = headItem.value;
      } else if (headItem.getValue) {
        value = headItem.getValue(item);
      } else if (headItem.key !== '') {
        value = headItem.key ? get(item, headItem.key, '') : '';

        if (headItem.transform) value = `${headItem.transform(value, item)}`;
      }

      if (value && +value && options?.locale && !headItem.transform) {
        // value is number
        value = Intl.NumberFormat(options.locale).format(value);

        if (options.locale === 'fr') value = value.replace(/\s/g, '');
      }

      csv += `${value || ''}`;

      if (index < header.length - 1) {
        csv += delimiter;
      }
    });

    csv += '\n';
  });

  return options?.keepAccents ? csv : replaceAccents(csv);
}

export default jsonToCsv;
