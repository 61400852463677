import React from 'react';
import { Toggle } from '@GDM/Toggle';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const TrackerToggle = () => {
  const { control } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('tracker');

  return (
    <Controller
      control={control}
      name="tracker"
      rules={rules}
      render={({ field }) => (
        <Toggle label="pricing.project.tracker" value={field.value} onChange={(value) => field.onChange(value)} />
      )}
    />
  );
};
