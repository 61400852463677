import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from '@utils/types/user';
import { InstallationForm } from './InstallationForm';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Installation = ({ user, name }: { user: User; name?: string }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <InstallationForm user={user} name={name} />
    </QueryClientProvider>
  );
};
