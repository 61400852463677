import { SimulationFormField } from '../utils/simulations.types';
import { commonFields } from './common-fields';

export const solarFields: SimulationFormField[] = [
  ...commonFields,
  { name: 'tracker' },
  {
    name: 'orientation',
    requiredForFieldValues: { 'installation_attributes.energy': 'solar' },
    rules: { min: -180, max: 180 },
  },
  {
    name: 'total_power_solar',
    requiredForFieldValues: { 'installation_attributes.energy': 'solar' },
    rules: { min: 10 },
  },
  {
    name: 'inclination',
    requiredForFieldValues: { 'installation_attributes.energy': 'solar', tracker: false },
    rules: { min: 0, max: 90 },
  },
  {
    name: 'maxangle_tracker_morning',
    requiredForFieldValues: { 'installation_attributes.energy': 'solar', tracker: true },
    rules: { min: 0, max: 90 },
  },
  {
    name: 'maxangle_tracker_evening',
    requiredForFieldValues: { 'installation_attributes.energy': 'solar', tracker: true },
    rules: { min: 0, max: 90 },
  },
];
