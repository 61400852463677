import React, { useEffect, useRef, useState } from 'react';
import { DropDownMenu } from '@GDM/DropDownMenu';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import styles from './button.module.scss';

export const DropDownButton = (
  props: ButtonProps & {
    className?: string;
    label?: string;
    position?: 'right';
    noChevron?: boolean;
    dropDownMenuClassName?: string;
    buttonClassName?: string;
    'data-cy'?: string;
  },
) => {
  const {
    label,
    className,
    buttonClassName,
    noChevron,
    dropDownMenuClassName,
    position,
    'data-cy': dataCy,
    ...buttonProps
  } = props;
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dropDown = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const triggerMenu = () => {
    setIsOpen((prevOpen) => {
      // If there's not enough space on the right, move the dropdown to the left
      if (!prevOpen && position === 'right') {
        // It has to be in the next tick, otherwise the dropdown won't be displayed yet
        setTimeout(() => {
          if (dropDown.current && ref.current) {
            const overflow = ref.current.offsetLeft + ref.current.offsetWidth - dropDown.current?.offsetWidth;

            if (overflow < 0) {
              dropDown.current.style.left = '0';
              dropDown.current.style.right = 'auto';
            }
          }
        });
      }

      return !prevOpen;
    });
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className={classNames(styles['dropdown'], className)}>
      <Button
        {...buttonProps}
        className={classNames(buttonClassName, { [styles.hover]: isOpen, [styles['icon-only']]: !label })}
        onClick={triggerMenu}
        data-cy={dataCy}
      >
        {!buttonProps.floating && !!label && t(label)}
        {!noChevron && <Icon name="ChevronDown" size={16} className={classNames(label && 'ml-2')} />}
      </Button>
      <DropDownMenu
        className={classNames(styles['dropdown-menu'], dropDownMenuClassName, {
          [styles['position-right']]: position === 'right',
        })}
        isOpen={isOpen}
        ref={dropDown}
      >
        {props.children}
      </DropDownMenu>
    </div>
  );
};
