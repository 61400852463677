import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { ProgressCard, ValueCard } from '@GDM/Card';
import { Colon } from '@GDM/Colon';
import { Col, Row } from '@GDM/layout';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import { getSmartTranslation } from '@utils/getSmartTranslation';
import { isANumber } from '@utils/isANumber';
import { useRevenueContext } from './hooks/useRevenueContext';

export const RevenueWidgets = () => {
  const { overview, selectedCurrency } = useRevenueContext();
  const { t } = useTranslation();
  const user = useUser();

  const revenue = useMemo(() => {
    if (!overview) return null;

    const revenueEntry = overview.find(({ field }) => field === 'revenue');

    return revenueEntry ? revenueEntry.data : null;
  }, [overview]);

  const hasValues: boolean = useMemo(() => {
    return revenue?.some((dataPoint) => isANumber(dataPoint.value)) ?? false;
  }, [revenue]);

  const totalIncome: number | null = useMemo(() => {
    return revenue?.reduce((total, dataPoint) => total + (Number(dataPoint.value) || 0), 0) ?? null;
  }, [revenue]);

  const performance: number | null = useMemo(() => {
    const businessPlanData = overview?.find(({ field }) => field === 'business_plan');

    if (!businessPlanData || !revenue) return null;

    const { income, bp } = businessPlanData.data.reduce<{ income: number; bp: number }>(
      (acc, curr) => {
        if (!curr.value) return acc;

        const revenueData = revenue.find((r) => r.period === curr.period);

        if (!revenueData?.value) return acc;

        return {
          income: acc.income + (Number(revenueData.value) || 0),
          bp: acc.bp + (Number(curr.value) || 0),
        };
      },
      { income: 0, bp: 0 },
    );

    const budgetPerformance = bp && income ? (income / bp) * 100 : null;

    return budgetPerformance;
  }, [overview, revenue]);

  const amountOfDataPoints: number = useMemo(() => {
    return revenue?.length || 0;
  }, [revenue]);

  if (!revenue || revenue.length === 0 || !hasValues) return null;

  return (
    <Row className="mt-4">
      <Col lg="4" className="d-flex">
        <ValueCard title={getSmartTranslation(user, 'common.revenue')} value={totalIncome} unit={selectedCurrency}>
          <div className="mt-2">
            {t('monitoring.installation.revenue.average_income')}
            <Colon />
            &nbsp;
            <ValueWithUnit
              data-cy="average-income"
              value={totalIncome ? totalIncome / amountOfDataPoints : null}
              unit={selectedCurrency}
            />
          </div>
        </ValueCard>
      </Col>
      {performance !== null && (
        <Col lg="4" className="d-flex" data-cy="performance">
          <ProgressCard title="monitoring.installation.revenue.performance_vs_budget" value={performance || 0} />
        </Col>
      )}
    </Row>
  );
};
