import React, { useState } from 'react';
import { Definition } from '@GDM/DefinitionList';
import Installation from '@utils/types/installation';
import { GridConnectionModal } from './GridConnectionModal';

export const GridConnectionDefinition = ({
  installationName,
  gridConnectionType,
}: {
  installationName: string;
  gridConnectionType: Installation['grid_connection_type'] | undefined;
}) => {
  const [localGridConnectionType, setLocalGridConnectionType] = useState(gridConnectionType);

  return (
    <Definition
      label="monitoring.installation.grid_injection_connection"
      value={localGridConnectionType ? `common.${localGridConnectionType}` : 'common.none'}
      modal={{
        node: (
          <GridConnectionModal
            installationName={installationName}
            gridConnectionType={localGridConnectionType}
            setGridConnectionType={setLocalGridConnectionType}
          />
        ),
        title: 'monitoring.installation.grid_injection_connection',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
