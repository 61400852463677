import { InvoiceRevenueRequestParameters } from '../revenue.types';

/**
 * Removes null values from the dictionary of parameters.
 */
export const removeInvalidParams = (params: InvoiceRevenueRequestParameters) => {
  const cleanedParams = Object.fromEntries(
    Object.entries(params).filter((entry) => !!entry[1]),
  ) as InvoiceRevenueRequestParameters;

  return cleanedParams;
};
