import React, { useDeferredValue, useEffect } from 'react';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { ContractForm } from '@utils/types/contract';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import styles from './hedge-profile-caps.module.scss';

export const HedgeProfileCaps = ({
  subPeriodIndex,
  className,
  startDate,
  endDate,
  readOnly,
  form: { control, getValues },
}: {
  subPeriodIndex: number;
  className?: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  readOnly: boolean;
  form: UseFormReturn<ContractForm>;
}) => {
  const startDateTimestamp = startDate?.getTime();
  const endDateTimestamp = endDate?.getTime();

  const { fields, append, replace } = useFieldArray({
    control,
    shouldUnregister: true,
    name: `contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.profile_hedge_caps_attributes`,
    keyName: 'hedge_profile_cap_id',
  });

  const period = getValues(`contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.period`);

  const deferedPeriod = useDeferredValue(period);
  const periodChanged = period !== deferedPeriod;

  useEffect(() => {
    if (!startDateTimestamp || !endDateTimestamp) return;
    const notReadyToGeneratePeriods = fields.length !== 0 || readOnly;
    if (!periodChanged && notReadyToGeneratePeriods) return;

    const periods =
      period === 'monthly'
        ? getMonthlyPeriods(startDateTimestamp, endDateTimestamp)
        : getYearlyPeriods(startDateTimestamp, endDateTimestamp);

    replace([]);
    periods.forEach((period) => append(period));
  }, [append, endDateTimestamp, fields.length, period, periodChanged, readOnly, replace, startDateTimestamp]);

  const formatMap = { yearly: 'YYYY-MM-DD', monthly: 'YYYY-MM' };
  const format = formatMap[period];

  return (
    <div className={classNames(styles['container'], className)}>
      {fields.map(({ start_date, end_date, hedge_profile_cap_id }, index) => (
        <div className={classNames(styles['wrapper'])} key={hedge_profile_cap_id}>
          <div className={classNames(styles['prefix'], 'fw:700')}>
            <span>{dayjs(start_date).format(format)}</span>
            {period === 'yearly' && (
              <>
                <span>&gt;</span>
                <span>{dayjs(end_date).format('YYYY-MM-DD')}</span>
              </>
            )}
          </div>
          <NumericInput
            id="cap"
            className={styles['input']}
            rules={{ required: true }}
            name={`contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.profile_hedge_caps_attributes.${index}.cap`}
            min={1}
            suffix="kWh"
            data-cy={`contract_sub_periods_attributes.${subPeriodIndex}.profile_hedge_attributes.profile_hedge_caps_attributes.${index}.cap`}
            readOnly={readOnly}
            full
            control={control}
          />
        </div>
      ))}
    </div>
  );
};

function getMonthlyPeriods(start: number, end: number) {
  const periods: { start_date: Date; end_date: Date }[] = [];
  let current = dayjs(start);
  const last = dayjs(end);
  let nextIsLast = false;

  while (!nextIsLast) {
    const potentialNext = current.endOf('month');
    const next = potentialNext.isBefore(last) ? potentialNext : last;
    periods.push({ start_date: current.toDate(), end_date: next.toDate() });

    current = next.add(1, 'day');
    nextIsLast = next === last;
  }

  return periods;
}

function getYearlyPeriods(start: number, end: number) {
  const periods: { start_date: Date; end_date: Date }[] = [];
  let current = dayjs(start);
  const last = dayjs(end);
  let nextIsLast = false;

  while (!nextIsLast) {
    const potentialNext = current.add(1, 'year').subtract(1, 'day');
    const next = potentialNext.isBefore(last) ? potentialNext : last;
    periods.push({ start_date: current.toDate(), end_date: next.toDate() });

    current = next.add(1, 'day');
    nextIsLast = next === last;
  }

  return periods;
}
