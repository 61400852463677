import React from 'react';
import OptionEnergy from '@GDM/forms/Select/CustomComponents/OptionEnergy/OptionEnergy';
import { GroupedSelect } from '@GDM/forms/Select/GroupedSelect';
import { Text } from '@GDM/Text';
import { Toggle } from '@GDM/Toggle';
import useTranslation from '@hooks/useTranslation';
import { Option } from '@utils/types/common-types';
import { Controller, useFormContext } from 'react-hook-form';
import { UserFormType } from '../../types';

export const InstallationsBooksSelect = ({
  options,
}: {
  options: {
    label: string;
    options: Option[];
  }[];
}) => {
  const { control, watch, clearErrors, formState } = useFormContext<UserFormType>();
  const { t } = useTranslation();

  const internal = watch('internal');
  const allSelected = watch('access_admin_installations');

  const hasError = !!formState.errors.installations;

  const isOptionDisabled = (option: UserFormType['installations'][number]) => {
    if (!internal) {
      const currentTotalSize = watch('installations').reduce((prev, curr) => prev + curr.installations_size, 0);

      return currentTotalSize >= 5 || option.installations_size + currentTotalSize > 5;
    }

    return false;
  };

  return (
    <>
      <div className="fw:600 mb-2">{t('common.installations')}</div>
      <Controller
        name="access_admin_installations"
        control={control}
        render={({ field }) => (
          <Toggle
            label="admin.sub_client.same_access_as_admin"
            tooltip="admin.sub_client.same_access_as_admin_info"
            value={field.value}
            onChange={(e) => {
              if (e) clearErrors('installations');
              field.onChange(e);
            }}
          />
        )}
      />

      {!allSelected && (
        <Controller
          rules={{ required: true }}
          name="installations"
          control={control}
          render={({ field, fieldState }) => (
            <GroupedSelect
              {...field}
              value={field.value}
              classNamePrefix="add-user-info-installations"
              options={options}
              hasError={!!fieldState.error}
              className="mt-1"
              isOptionDisabled={isOptionDisabled as (option: Option) => boolean}
              components={{ Option: OptionEnergy }}
              isMulti
            />
          )}
        />
      )}
      {hasError && (
        <Text
          icon="AlertCircle"
          type="danger"
          text="admin.sub_client.needs_installations_or_all"
          className="mt-2 text-small"
          multiline
        />
      )}
    </>
  );
};
