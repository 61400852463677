import { useCallback, useContext, useMemo } from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { EnergyType } from '@utils/types/common-types';
import dayjs from 'dayjs';
import { revenueReportingContext } from '../../context';
import {
  NationalPriceResultStatus,
  RevenueReportField,
  RevenueReportUnit,
  RevenueReportingContext,
  SiteRevenueReport,
} from '../../reporting.types';

const propsToDisplay: readonly RevenueReportField[] = [
  'aggregation_fee_unit_price',
  'business_plan_delta_production',
  'hedge_production',
  'hedge_unit_price',
  'local_value_solar',
  'local_value_wind',
  'market_revenue',
  'market_value_base',
  'market_value_solar',
  'market_value_wind',
  'negative_price_production',
  'subsidy_revenue',
  'total_production',
  'total_revenue',
  'total_unit_price_solar',
  'total_unit_price_wind',
] as const;

const sitePropsToDisplay: readonly SiteRevenueReport['data'][number]['field'][] = [
  'business_plan_revenue',
  'total_cumulative_production',
  'total_cumulative_revenue',
  'total_production',
  'total_revenue',
  'total_unit_price',
] as const;

type ReportDictionary<T extends string = typeof propsToDisplay[number]> = Record<
  T,
  { value: number | null; unit: RevenueReportUnit; status?: NationalPriceResultStatus }
>;

type SiteReportDictionary = ReportDictionary<typeof sitePropsToDisplay[number]> & {
  ytdProduction: Array<{ production: number; date: string }>;
};

export const useRevenueReportingContext = (): RevenueReportingContext & {
  current?: ReportDictionary;
  ytd?: ReportDictionary;
  totalProductionForSelectedYear?: number;
  split?: Array<{
    installationName: string;
    installationEnergy?: EnergyType;
    data: SiteReportDictionary;
  }>;
} => {
  const context = useContext(revenueReportingContext);
  const { installations } = useInstallationsContext();
  const currentPeriod = dayjs(context.selectedDate).startOf('month').format('YYYY-MM-DD');

  const makeReportDictionary: <T extends ReportDictionary | SiteReportDictionary>(
    dict: T,
    current: T extends ReportDictionary
      ? NonNullable<RevenueReportingContext['monthly' | 'cumulative']>[number]
      : NonNullable<RevenueReportingContext['splitData']>[number]['data'][number],
  ) => T = useCallback(
    (dict, current) => {
      const item = current.data.find((v) => v.period === currentPeriod);
      const value = item && item?.value !== null ? Number(item.value) : null;
      const unit = item?.unit ?? null;
      const status = item?.status || null;

      return item && [...propsToDisplay, ...sitePropsToDisplay].includes(current.field)
        ? { ...dict, [current.field]: { value, unit, status } }
        : dict;
    },
    [currentPeriod],
  );

  const current = context.monthly?.reduce(makeReportDictionary, {} as ReportDictionary);

  const ytd = context.cumulative?.reduce(makeReportDictionary, {} as ReportDictionary);

  const split = useMemo(
    () =>
      context.splitData
        ?.map((d) => {
          const installation = installations?.find((i) => i.uuid === d.installation_uuid);
          const installationName = installation?.name || '--';
          const installationEnergy = installation?.energy;

          let currentData = d.data.reduce(makeReportDictionary, {} as SiteReportDictionary);

          const ytdProduction: SiteReportDictionary['ytdProduction'] | undefined = d.data
            .find((i) => i.field === 'total_production')
            ?.data.map((d) => ({ production: +d.value, date: d.period }));

          if (ytdProduction) currentData = { ...currentData, ytdProduction };

          return {
            installationName,
            installationEnergy,
            data: currentData,
          };
        })
        .filter((i) => Boolean(i.installationEnergy)),
    [context.splitData, installations, makeReportDictionary],
  );

  const totalProductionForSelectedYearRef = useMemo(
    () => context.cumulative?.find((v) => v.field === 'total_production')?.data?.at(-1)?.value,
    [context.cumulative],
  );

  const hasTotalProductionForYear =
    totalProductionForSelectedYearRef !== undefined && totalProductionForSelectedYearRef !== null;

  return {
    ...context,
    current,
    split,
    totalProductionForSelectedYear: hasTotalProductionForYear ? +totalProductionForSelectedYearRef : undefined,
    ytd,
  };
};
