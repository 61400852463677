import React from 'react';
import { GroupedSelect } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import { PricingMap } from '../utils/pricing-maps.types';
import { usePricingMapsContext } from '../utils/pricingMaps.context';
import { useSelectedPricingMapContext } from '../utils/selectedPricingMap.context';

export const RunSelect = ({
  onChange,
  selectedOption,
  country,
}: {
  onChange: (option: Option<string> | null) => void;
  selectedOption: string | null;
  country: CountryCode | null;
}) => {
  const { pricingMaps } = usePricingMapsContext();
  const { selectPricingMap } = useSelectedPricingMapContext();

  const groups = Array.from(new Set(pricingMaps?.map((map) => map.tech) || []));
  const maps: PricingMap[] = [];
  const filteredMaps = pricingMaps?.filter((m) => m.country === country) || [];

  filteredMaps.forEach((map) => {
    const existingMapIndex = maps.findIndex((m) => m.name === map.name);
    const existingMap = maps[existingMapIndex];

    if (!existingMap) {
      maps.push(map);
    } else if (existingMap.id < map.id) {
      maps[existingMapIndex] = map;
    }
  });

  const groupedOptions = groups.map((tech) => ({
    label: tech,
    options: maps
      .filter((map) => map.tech === tech)
      .map((map) => ({ label: map.name, value: map.id ? map.id.toString() : '' })),
  }));

  const handleChange = (option: Option<string> | null) => {
    onChange(option);
    selectPricingMap(option?.value ? parseInt(option.value) : null);
  };

  const selected = groupedOptions
    .flatMap((group) => {
      const option = group.options.find((option) => option.value === selectedOption);

      if (!option) return null;

      return option;
    })
    .filter(Boolean)[0];

  return <GroupedSelect options={groupedOptions} onChange={handleChange} value={selected} />;
};
