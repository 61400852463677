import React, { useCallback, useState } from 'react';
import { SubUsersProvider } from '@context/admin/users/SubUsersProvider';
import { Button } from '@GDM/Button';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import { get_givable_permisions_v2_users_path } from '@utils/routes';
import { StreemModule } from '@utils/types/common-types';
import { Role } from '@utils/types/role';
import { SubUser, User } from '@utils/types/user';
import UsersModal from './UsersModal';
import { UsersTable } from './UsersTable';
export type GiveablePermissions = { [key in StreemModule]: Role[] };

type Props = { user: User };

const Users = ({ user }: Props) => {
  const [selectedSubUserId, setSelectedSubUserId] = useState<string | null>(null);
  const { t } = useTranslation(user.locale);
  const { data: giveablePermissions, loading } = useRequest<GiveablePermissions>(
    get_givable_permisions_v2_users_path(),
    'GET',
  );

  const handleModalOpen = (subUser: SubUser) => {
    if (subUser) setSelectedSubUserId(subUser.uuid);
  };

  const toggle = useCallback(() => {
    setSelectedSubUserId((prevId) => (prevId !== null ? null : ''));
  }, []);

  return (
    <SubUsersProvider toggle={toggle}>
      <Page
        user={user}
        title={t('admin.sub_client.user_management_title')}
        header={
          <>
            <div className="d-flex">
              <Button
                data-cy="add-user"
                size="xxs"
                className="ml-auto"
                onClick={toggle}
                variant="primary-2"
                icon="Plus"
                text="common.add_new"
              />
            </div>
            <UsersModal
              toggle={toggle}
              selectedSubUserId={selectedSubUserId}
              key={selectedSubUserId}
              giveablePermissions={
                giveablePermissions || {
                  monitoring: [],
                  pricing: [],
                  salesfocus: [],
                }
              }
            />
            <UsersTable onModalOpen={handleModalOpen} isLoading={loading} />
          </>
        }
      />
    </SubUsersProvider>
  );
};

export default Users;
