import React from 'react';
import { SitesReports } from '../SitesReports';

const ReportPageSiteBySite = ({ showAll }: { showAll: boolean }) => {
  return (
    <div className="SitesReportsContainer">
      <SitesReports disablePadding printable showAll={showAll} />
    </div>
  );
};

export default ReportPageSiteBySite;
