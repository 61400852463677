import { User } from '@utils/types/user';
import { COMMON_FIELDS } from '../constants/common-fields';
import { InstallationForm } from '../installation.types';

export const getDefaultValues = (user: User): Partial<InstallationForm> => {
  const newDefaultValues: Partial<InstallationForm> = COMMON_FIELDS.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: field.defaultValue,
    }),
    {},
  );

  if (user.main_country && user.main_country) {
    newDefaultValues.country = user.main_country;
  }

  const productionValue = user.account_type === 'producer';

  newDefaultValues.producteur = productionValue;

  return newDefaultValues;
};
