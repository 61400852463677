import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import compact from 'lodash/compact';
import { isEditable } from 'pages/sales-management/invoicing/utils/isEditable';

export const EditInvoiceButton = ({
  invoice,
  tabType,
  handleEditClick,
  className,
}: {
  invoice: MeterInvoice;
  tabType: MeterInvoiceSource;
  handleEditClick: (invoice: MeterInvoice) => void;
  className?: string;
}) => {
  if (!isEditable(invoice, tabType)) return null;

  return (
    <Restricted permissions={compact([tabType !== 'index' && 'display:edit_invoices'])}>
      <Button
        floating
        icon="Edit3"
        onClick={() => handleEditClick(invoice)}
        className={className}
        data-cy="edit-invoice-button"
      />
    </Restricted>
  );
};
