import React, { useEffect } from 'react';
import { Input, sectionStyles } from '@GDM/forms';
import { useHelpBoxContext } from '@pages/Contracts/Contract/contexts/helpBoxContext';
import { ContractForm } from '@utils/types/contract';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import { Control } from 'react-hook-form';
import { DateInput } from '../../../components/Inputs/Date';
import { NumericInput } from '../../../components/Inputs/Numeric';
import { TextInput } from '../../../components/Inputs/Text';
import { ConfigurableField } from '../GeneralInfo';

export const ConfigurableFields = ({
  control,
  readOnly,
  installation,
  configurableFields,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
  installation: InstallationWithMeterInfo | undefined | null;
  configurableFields: Set<ConfigurableField>;
}) => {
  useEffect(() => {
    const configurableFieldsFromLocalStorage = localStorage.getItem('configurableFields');
    const serializedConfigurableFields = [...configurableFields].sort().join(',');

    if (configurableFieldsFromLocalStorage !== serializedConfigurableFields) {
      localStorage.setItem('configurableFields', serializedConfigurableFields);
    }
  }, [configurableFields]);

  // Usually you don't need to use this hook, because Contract Form inputs have a helpBox prop that already manages that
  // But in this case we have a base Input that has to display a help box, so we need to manage it manually
  const { handleFocus, handleBlur } = useHelpBoxContext();

  return (
    <div className={sectionStyles.row}>
      {configurableFields.has('erp_code') && (
        <TextInput
          id="ErpCode"
          name="erp_code"
          control={control}
          label="sales_management.configurable_fields.erp_code"
          size="lg"
          readOnly={readOnly}
          full
          helpBox={{
            title: 'sales_management.configurable_fields.erp_code',
            text: 'sales_management.configurable_fields.erp_code_info',
          }}
        />
      )}
      {configurableFields.has('card_i') && (
        <TextInput
          id="CardI"
          name="card_i"
          control={control}
          label="sales_management.configurable_fields.card_i"
          data-cy="card-i-input"
          size="lg"
          readOnly={readOnly}
          full
        />
      )}
      {configurableFields.has('_non_contract_props.installation.owner_erp_code') && (
        <Input
          // READONLY FIELD
          name="_non_contract_props.installation.owner_erp_code"
          value={installation?.owner_erp_code ?? ''}
          label="sales_management.configurable_fields._non_contract_props.installation.owner_erp_code"
          size="lg"
          readOnly
          full
        />
      )}
      {configurableFields.has('_non_contract_props.installation.external_ref') && (
        <Input
          // READONLY FIELD
          name="_non_contract_props.installation.external_ref"
          value={installation?.external_ref ?? ''}
          label="sales_management.configurable_fields._non_contract_props.installation.external_ref"
          size="lg"
          readOnly
          full
          onBlur={handleBlur}
          onFocus={handleFocus({
            title: 'sales_management.configurable_fields._non_contract_props.installation.external_ref',
            text: 'sales_management.configurable_fields._non_contract_props.installation.external_ref_info',
          })}
        />
      )}
      {configurableFields.has('signature_date') && (
        <DateInput
          id="SignatureDatePicker"
          name="signature_date"
          control={control}
          label="sales_management.configurable_fields.signature_date"
        />
      )}
      {configurableFields.has('p_max') && (
        <NumericInput
          id="PMax"
          name="p_max"
          control={control}
          label="sales_management.p_max"
          suffix="kW"
          data-cy="p-max"
        />
      )}
    </div>
  );
};
