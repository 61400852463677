import { CSVExportPeriod, InvoiceRevenueDataPoint, RevenueContextType } from '../revenue.types';
import { isGroupedData } from './type-helpers';

export const getPeriodsFromRevenue = (revenue: RevenueContextType['revenue']): CSVExportPeriod[] => {
  if (!revenue) return [];

  const concatenatedPeriods: { label: string; index: number }[] = [];
  const addDataPoint = (dataPoint: InvoiceRevenueDataPoint, index: number) => {
    if (!concatenatedPeriods.find((p) => p.label === dataPoint?.period))
      concatenatedPeriods.push({ label: dataPoint.period, index });
  };

  if (isGroupedData(revenue)) {
    revenue.forEach((item) => item.values?.forEach(addDataPoint));
  } else {
    revenue.forEach(addDataPoint);
  }

  return concatenatedPeriods;
};
