import { Path, useFormContext } from 'react-hook-form';
import { FilterType } from './filters.types';

export const useShouldUseCache = <T>(name: keyof T) => {
  const form = useFormContext<FilterType<T>>();
  const key: keyof FilterType<T> = 'last_selected_filter';
  const lastSelectedFilter = form.watch(key as Path<FilterType<T>>);

  return lastSelectedFilter === name;
};
