import React from 'react';
import { Currency } from '@utils/types/currency';
import ProductionChart from '../charts/ProductionChart';
import MarketData from '../MarketData';
import MonthlyResults from '../MonthlyResults';
import YearlyResults from '../YearlyResults';

/**
 * TODO: after full react migration, we will need to import `app/react/pages/sales-management/portfolio/components/pdf/ReportPageCharts.tsx` here
 * it's not done now as there is too much styles overridings in the pdf css and in the current css
 */

const ReportPageCharts = ({ currency }: { currency: Currency }) => {
  return (
    <main>
      <div className="reports">
        <div className="report">
          <h5>Résultats mensuels</h5>
          <MonthlyResults printable />
        </div>

        <div className="report">
          <h5>Résultats annuels</h5>
          <YearlyResults printable />
        </div>
      </div>

      <hr />

      <div className="market">
        <h5>Prix Marché</h5>

        <MarketData printable />
      </div>

      <hr />

      <div className="market">
        <h5>Production vs Revenues</h5>
        <ProductionChart height={250} disablePadding printable currency={currency} />
      </div>
    </main>
  );
};

export default ReportPageCharts;
