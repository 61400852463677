import React, { useContext } from 'react';
import { Button } from '@GDM/Button';
import SelectedRowsContext from './state/selectedRows';

const CreateBookButton = ({ toggle }: { toggle: () => void }) => {
  const { selectedRows } = useContext(SelectedRowsContext);
  const selectedRowsNameSet = new Set(selectedRows.map((row) => row.name));

  if (selectedRowsNameSet.size < 2) {
    return null;
  }

  return (
    <Button
      variant="primary-2"
      onClick={() => toggle()}
      tooltip="monitoring.books.create_book"
      size="xxs"
      icon="Book"
    />
  );
};

export default CreateBookButton;
