import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ContractForm } from '@utils/types/contract';
import { Control } from 'react-hook-form';
import { BooleanInput } from '../../../components/Inputs/Boolean';
import { DateInput } from '../../../components/Inputs/Date';
import { NumericInput } from '../../../components/Inputs/Numeric';

export const PowerAugmentation = ({
  control,
  readOnly,
  isActive,
  startDate,
  endDate,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
  isActive: boolean;
  startDate: Date | null;
  endDate: Date | null;
}) => {
  return (
    <div className={sectionStyles['section-footer']}>
      <div className="p-3 pb-0">
        <div className={sectionStyles.row}>
          <BooleanInput
            id="PowerAugmentationToggle"
            name="power_augmentation"
            control={control}
            label="sales_management.power_augmentation"
            readOnly={readOnly}
          />
        </div>
        {isActive && (
          <div className={sectionStyles.row}>
            <DateInput
              id="PowerAugmentationStartDate"
              name="power_augmentation_start_date"
              control={control}
              rules={{
                validate: (value: Date | null) => {
                  if (!value || !startDate || !endDate) return true;

                  // value should be in between start_date and end_date, if not returns error as string
                  if (startDate && endDate && value && (value < startDate || value > endDate)) {
                    return 'sales_management.power_augmentation_start_date_error';
                  }

                  return true;
                },
              }}
              label="sales_management.power_augmentation_start_date"
              readOnly={readOnly}
              minDate={startDate}
              maxDate={endDate}
              defaultValue={startDate}
            />
            <NumericInput
              id="PowerAugmentationPercentage"
              name="power_augmentation_percentage"
              rules={{ min: 0, max: 100 }}
              control={control}
              label="common.percentage"
              readOnly={readOnly}
              min={0}
              max={100}
              suffix="%"
              tooltip="sales_management.power_augmentation_percentage"
            />
          </div>
        )}
      </div>
    </div>
  );
};
