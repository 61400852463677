import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { update_status_v2_meter_invoicing_index_path } from '@utils/routes';
import type { MeterInvoice } from '@utils/types/meter-invoice';
import { InvoiceDestructiveActionButton } from './InvoiceDestructiveActionButton';

export const UnsendToERPButton = ({ className, invoice }: { className?: string; invoice: MeterInvoice }) => {
  const { t } = useTranslation();

  if (invoice.status !== 'sent_to_erp') return null;

  return (
    <InvoiceDestructiveActionButton
      modalTitle="sales_management.usend_to_erp_title"
      confirmationText={t('sales_management.confirm_unsend_to_erp', {
        newStatus: t('common.filters.invoice_status.generated'),
      })}
      invoice={invoice}
      path={update_status_v2_meter_invoicing_index_path({
        params: { invoices: { ids: [invoice.uuid] }, event: 'revert_send_to_erp' },
      })}
      method="patch"
      submitButtonInfo={t('sales_management.revert_to_generated')}
      permissions={['display:edit_invoices']}
      data-cy="invoice-unsend_to_erp"
      className={className}
      icon="CornerUpLeft"
    />
  );
};
