/* HOOKS */

/* UTILS */
import { useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { v2_export_invoice_reports_fec_path } from '@utils/routes';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

type Props = {
  startDate?: Date | null;
  endDate?: Date | null;
};

const fetchDownloadFecReport = async ({ url }: { url: string }) => {
  const response = await axiosInstance(false)({ url, method: 'GET', responseType: 'blob' });
  if (response.headers['content-disposition']) {
    const fileName = response.headers['content-disposition'].split('"')[1];

    fileDownload(response.data, fileName);
  }

  return response.data;
};

export const useDownloadFecReport = ({ startDate, endDate }: Props) => {
  return useRequest(
    v2_export_invoice_reports_fec_path({
      params: {
        start_date: dayjs(startDate).format('YYYY-MM'),
        end_date: dayjs(endDate).format('YYYY-MM'),
      },
    }),
    fetchDownloadFecReport,
    true,
  );
};
