import React, { type TextareaHTMLAttributes } from 'react';
import { Textarea } from '@GDM/forms/Textarea';
import type { TextAreaProps } from '@GDM/forms/Textarea/Textarea';
import { Controller, FieldPath, type ControllerProps, type FieldValues } from 'react-hook-form';

export function ControlledTextarea<T extends FieldValues, U extends FieldPath<T>>({
  name,
  control,
  rules,
  shouldUnregister,
  defaultValue,
  ...textAreaProps
}: Omit<TextAreaProps, 'value' | 'onChange'> &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size' | 'prefix'> &
  Omit<ControllerProps<T, U>, 'render'>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <Textarea
          {...textAreaProps}
          onChange={(event) => field.onChange(event.target.value)}
          value={field.value}
          hasError={Boolean(fieldState.error)}
          errorMessage={fieldState.error?.message}
          data-cy={name}
        />
      )}
    />
  );
}
