import axiosInstance from '@utils/axiosInstance';
import axios, { AxiosResponse } from 'axios';
import { RequestParams, Response } from './requests.types';

export const fetch = async <T>({ url, options }: RequestParams<T>): Response<T> =>
  axiosInstance(false)
    .get<T>(url, {
      ...options,
      headers: { accept: 'application/json' },
    })
    .then((d) => ({ data: d.data, status: d.status }))
    .catch((error) => {
      // Ignore Axios cancellation errors because we are using AbortController instead of Axios cancellation tokens
      if (axios.isCancel(error)) return null;

      throw error;
    });

export const patch = async <S, T>({ url, body, options }: RequestParams<T>): Response<S> => {
  return axiosInstance(false)
    .patch<T, AxiosResponse<S>>(url, body, {
      ...options,
      headers: { accept: 'application/json' },
    })
    .then((d) => ({ data: d.data, status: d.status }))
    .catch((error) => {
      // Ignore Axios cancellation errors because we are using AbortController instead of Axios cancellation tokens
      if (axios.isCancel(error)) return null;

      throw error;
    });
};

export const post = async <S, T>({ url, body, options }: RequestParams<T>): Response<S> => {
  return axiosInstance(false)
    .post<T, AxiosResponse<S>>(url, body, {
      ...options,
      headers: { accept: 'application/json' },
    })
    .then((d) => ({ data: d.data, status: d.status }))
    .catch((error) => {
      // Ignore Axios cancellation errors because we are using AbortController instead of Axios cancellation tokens
      if (axios.isCancel(error)) return null;

      throw error;
    });
};

export const put = async <S, T>({ url, body, options }: RequestParams<T>): Response<S> => {
  return axiosInstance(false)
    .put<T, AxiosResponse<S>>(url, body, {
      ...options,
      headers: { accept: 'application/json' },
    })
    .then((d) => ({ data: d.data, status: d.status }))
    .catch((error) => {
      // Ignore Axios cancellation errors because we are using AbortController instead of Axios cancellation tokens
      if (axios.isCancel(error)) return null;

      throw error;
    });
};

export const destroy = <T>({ url, options }: RequestParams<T>): Response<T> =>
  axiosInstance(false)
    .delete<T>(url, {
      ...options,
      headers: { accept: 'application/json' },
    })
    .then((d) => ({ data: d.data, status: d.status }))
    .catch((error) => {
      // Ignore Axios cancellation errors because we are using AbortController instead of Axios cancellation tokens
      if (axios.isCancel(error)) return null;

      throw error;
    });
