import { createContext } from 'react';
import Book from '@utils/types/book';
import { Timezone } from '@utils/types/countries';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import { Meter } from '@utils/types/meter';

export type DashboardMeterContextType = {
  book?: Book | null;
  meter?: Meter | null;
  installation?: InstallationWithMeterInfo | null;
  showForecast?: boolean;
  timezone?: Timezone;
};

export const dashboardMeterContext = createContext<DashboardMeterContextType>({});
