import React, { InputHTMLAttributes } from 'react';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './checkbox.module.scss';

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  label?: string;
  name?: string;
  tooltip?: string;
  color?: string;
  labelMultiLine?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ className, color, label, name, tooltip, disabled, labelMultiLine, ...allProps }, ref) => {
    const { t } = useTranslation();
    const classList = classNames(
      styles?.['checkbox-label'],
      { [styles?.['is-disabled']]: disabled, [styles['default-color']]: !color },
      className,
    );

    return (
      <label className={classList} title={t(label)}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...allProps}
          ref={ref}
          type="checkbox"
          name={name}
          data-cy={name}
          disabled={disabled}
          style={color ? { backgroundColor: color, borderColor: color } : undefined}
        />
        {label && (
          <span className={classNames('ml-2', styles['label-text'], labelMultiLine && styles['label-text-multiline'])}>
            {t(label)}
          </span>
        )}
        {tooltip && <Icon name="Info" size={14} title={tooltip} className={styles.tooltip} />}
      </label>
    );
  },
);
