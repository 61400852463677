import React from 'react';
import { LightCell } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { IndexData } from '@utils/DashboardMeter/useIndexInvoicingData';

export const TypeCell = (props: CellContext<IndexData, unknown>) => {
  const { t } = useTranslation();

  return <LightCell value={t(`monitoring.installation.label_${props.getValue()}`)} />;
};
