import React, { useEffect } from 'react';
import { Alert } from '@GDM/Alert';
import { AltContent } from '@GDM/AltContent';
import { Spinner } from '@GDM/Spinner';
import { useRequest } from '@hooks/useRequest';
import { useBSM } from '@pages/BSM/context';
import { internal_activity_tracks_path } from '@utils/routes';
import { ActivityTrack } from '@utils/types/activity-track';
import { ActivityTracksTable } from './ActivityTracksTable';

export const ActivityTracking = () => {
  const { activityTracks, setActivityTracks } = useBSM();
  const { data, loading, loaded, error } = useRequest<ActivityTrack[]>(internal_activity_tracks_path(), 'GET');

  useEffect(() => {
    if (loaded) setActivityTracks?.(data);
  }, [data, loaded, setActivityTracks]);

  if (error) return <Alert variant="danger" label={error.code || 'Something went wrong with the request'} />;

  if (loading)
    return (
      <div className="ta:center">
        <Spinner />
      </div>
    );

  if (!activityTracks || !activityTracks.length) return <AltContent />;

  return <ActivityTracksTable data={activityTracks} />;
};
