import React, { useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { patch, useRequest } from '@hooks/useRequest';
import { getErrorMessage } from '@utils/parsers';
import { v2_installation_path } from '@utils/routes';
import Installation from '@utils/types/installation';
import { useInstallationInfo } from '../useInstallationInfo';

export const ClientIdModal = ({ name }: { name: string }) => {
  const { client_id, setClientId } = useInstallationInfo();
  const [localClientId, setLocalClientId] = useState(client_id ?? null);

  const { loading: isLoading, execute } = useRequest<Installation>(
    v2_installation_path(name),
    ({ url }) =>
      patch<Installation, { installation: Partial<Installation> }>({
        url,
        body: {
          installation: {
            external_ref: localClientId ?? undefined,
          },
        },
      }),
    true,
  );

  const saveUpdate = async () => {
    try {
      const response = await execute?.();
      setClientId?.(localClientId ?? null);

      if (typeof response === 'string') return response;
    } catch (e) {
      return getErrorMessage(e);
    }
  };

  return (
    <>
      <DefinitionModalBody>
        <Input
          value={localClientId ?? ''}
          full
          placeholder="14030219"
          onChange={(e) => setLocalClientId(e.target.value)}
        />
      </DefinitionModalBody>
      <DefinitionModalFooter save={saveUpdate} isLoading={isLoading} />
    </>
  );
};
