import React, { useRef } from 'react';
import { useUser } from '@context/User.context';
import { Card } from '@GDM/Card';
import { Chart, exportCSVChartAction } from '@GDM/Chart';
import { ChartRefContent } from '@GDM/Chart/chart.types';
import { getDefaultOptions } from '@GDM/Chart/default-options';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { appendColon } from '@utils/string';
import { useNationalPricesContext } from './context';

export const HistoricM0Chart = () => {
  const { tLocalized } = useTranslation();
  const { locale, timezone } = useUser();
  const ref = useRef<ChartRefContent>(null);
  const { selectedCountry, m0Data, currency } = useNationalPricesContext();
  const currencySymbol = getSymbol(currency);

  const baseloadCurrentIndex = m0Data && m0Data?.base?.length >= 2 ? m0Data.base.length - 2 : null;
  const solarCurrentIndex = m0Data && m0Data?.solar?.length >= 2 ? m0Data.solar.length - 2 : null;
  const windCurrentIndex = m0Data && m0Data?.wind?.length >= 2 ? m0Data.wind.length - 2 : null;

  const options: Highcharts.Options = {
    chart: { type: 'spline', height: 570 },
    xAxis: { gridLineWidth: 1, crosshair: true },
    yAxis: [
      {
        opposite: true,
        title: {
          text: tLocalized('sales_management.national_prices.chart.historic_market_price', {
            unit: `${currencySymbol}/MWh`,
          }),
        },
        height: 300,
        gridLineWidth: 1,
      },
      {
        opposite: true,
        title: {
          text: tLocalized('sales_management.national_prices.chart.historic_market_spread_vs_baseload', {
            unit: `${currencySymbol}/MWh`,
          }),
        },
        height: 200,
        top: 320,
        offset: 0,
        gridLineWidth: 1,
      },
    ],
    exporting: { filename: 'market_prices' },
    tooltip: {
      ...getDefaultOptions(timezone, locale),
      pointFormatter() {
        return `<span style="color: ${this.color}">●</span> ${appendColon(
          this.series.name,
          locale,
        )} <b>${new Intl.NumberFormat(locale, { maximumFractionDigits: 8 }).format(this.y ?? 0)} ${
          this.series.options.tooltip?.valueSuffix || ''
        }</b><br/>`;
      },
    },
  };

  const series: Highcharts.Options['series'] = [
    {
      type: 'spline',
      color: 'var(--chart-purple)',
      cropThreshold: 59999,
      name:
        selectedCountry === 'FR'
          ? tLocalized('sales_management.national_prices.chart.m0_baseload')
          : tLocalized('sales_management.national_prices.chart.market_value'),
      data: m0Data?.base,
      zoneAxis: 'x',
      zones:
        baseloadCurrentIndex && m0Data?.base?.[baseloadCurrentIndex]?.[0]
          ? [{ value: m0Data.base[baseloadCurrentIndex][0] }, { dashStyle: 'Dot' }]
          : undefined,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
    {
      type: 'spline',
      color: 'var(--chart-solar)',
      cropThreshold: 59999,
      name: tLocalized('sales_management.national_prices.chart.m0_solar'),
      data: m0Data?.solar,
      zoneAxis: 'x',
      zones:
        solarCurrentIndex && m0Data?.solar?.[solarCurrentIndex]?.[0]
          ? [{ value: m0Data.solar[solarCurrentIndex][0] }, { dashStyle: 'Dot' }]
          : undefined,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
    {
      type: 'spline',
      color: 'var(--chart-wind)',
      cropThreshold: 59999,
      name: tLocalized('sales_management.national_prices.chart.m0_wind'),
      data: m0Data?.wind,
      zoneAxis: 'x',
      zones:
        windCurrentIndex && m0Data?.wind?.[windCurrentIndex]?.[0]
          ? [{ value: m0Data.wind[windCurrentIndex][0] }, { dashStyle: 'Dot' }]
          : undefined,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
    {
      type: 'spline',
      color: 'var(--chart-purple-alpha)',
      cropThreshold: 59999,
      name:
        selectedCountry === 'FR'
          ? tLocalized('sales_management.national_prices.chart.m0_baseload_yearly_avg')
          : tLocalized('sales_management.national_prices.chart.market_value_yearly_avg'),
      data: m0Data?.base_avg,
      lineWidth: 0.8,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
    {
      type: 'spline',
      color: 'var(--chart-solar-alpha)',
      cropThreshold: 59999,
      name: tLocalized('sales_management.national_prices.chart.m0_solar_yearly_avg'),
      data: m0Data?.solar_avg,
      lineWidth: 0.8,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
    {
      type: 'line',
      color: 'var(--chart-wind-alpha)',
      cropThreshold: 59999,
      name: tLocalized('sales_management.national_prices.chart.m0_wind_yearly_avg'),
      data: m0Data?.wind_avg,
      lineWidth: 0.8,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
    {
      type: 'column',
      color: 'var(--chart-solar)',
      cropThreshold: 59999,
      name: tLocalized('sales_management.national_prices.chart.m0_solar_vs_baseload'),
      data: m0Data?.dev_solar,
      yAxis: 1,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
    {
      type: 'column',
      color: 'var(--chart-wind)',
      cropThreshold: 59999,
      name: tLocalized('sales_management.national_prices.chart.m0_wind_vs_baseload'),
      data: m0Data?.dev_wind,
      yAxis: 1,
      tooltip: { valueSuffix: `${currencySymbol}/MWh` },
    },
  ];

  if (!m0Data) return null;

  return (
    <Card className="mt-3">
      <div className="w-100">
        <Chart
          options={options}
          series={series}
          data-cy="historic-m0-chart"
          hideLegend
          handleDownloadCsv={exportCSVChartAction(ref)}
          ref={ref}
        />
      </div>
    </Card>
  );
};
