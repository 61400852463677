import { createContext, Dispatch, SetStateAction } from 'react';
import { RequestError } from '@hooks/useRequest';
import { DashboardViewAllData, DataType, PortFolioDisplayType } from './portfolio.definitions';

export type PortFolioContextType = {
  data?: DashboardViewAllData[];
  onlyConsumers: boolean | null;
  dataType: DataType;
  selectedDate?: string;
  loading?: boolean;
  error?: string | RequestError;
  errorBanner?: string;
  sum_realtime_portfolio?: number;
  realtime_ratio?: number;
  setDataType?: Dispatch<SetStateAction<PortFolioContextType['dataType']>>;
  portfolio?: DashboardViewAllData[];
  setPortfolio?: Dispatch<SetStateAction<PortFolioContextType['portfolio']>>;
  displayMode: PortFolioDisplayType;
  setDisplayMode?: Dispatch<SetStateAction<PortFolioContextType['displayMode']>>;
  startDate?: string;
  setStartDate?: Dispatch<SetStateAction<PortFolioContextType['startDate']>>;
  endDate?: string;
  setEndDate?: Dispatch<SetStateAction<PortFolioContextType['endDate']>>;
};

export const portfolioContext = createContext<PortFolioContextType>({
  displayMode: 'table',
  dataType: 'installations',
  onlyConsumers: false,
});
