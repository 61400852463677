import React, { useState } from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { formatSystemDate, toDate } from '@utils/formatters';
import { useFilters } from './useFilters';

export const CommissioningDatesFilter = () => {
  const { commissioningDates, setCommissioningDates } = useFilters();
  const [dates, setDates] = useState<[Date | null, Date | null]>([
    commissioningDates?.[0] ? toDate(commissioningDates[0]) : null,
    commissioningDates?.[1] ? toDate(commissioningDates[1]) : null,
  ]);

  const onChange = (dates: [Date | null, Date | null]) => {
    if (dates.every((d) => !d)) setCommissioningDates?.(null);
    if (dates.every(Boolean)) setCommissioningDates?.([formatSystemDate(dates[0]), formatSystemDate(dates[1])]);
    setDates(dates);
  };

  return (
    <DatePicker
      placeholderText="monitoring.installation.commissioning_date_title"
      onChange={onChange}
      startDate={dates[0]}
      endDate={dates[1]}
      selectsRange={true}
      data-cy="commissioning-dates-filter"
      isClearable
    />
  );
};
