import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { getClassificationValue } from '@pages/Contracts/Contract/Form/sections/ContractType/utils/getClassificationValue';
import { Contract } from '@utils/types/contract';
import type { DeepPartial } from 'react-hook-form';

export default function matchClassification(contract: Contract, filters: DeepPartial<ContractsFiltersType>): boolean {
  const classification = getClassificationValue(contract.type);

  if (
    !filters.classification ||
    (filters.classification === 'physical' && classification === 'physical') ||
    (filters.classification === 'financial' && classification === 'financial')
  ) {
    return true;
  }

  return false;
}
