import { useCallback, useEffect, useRef, useState } from 'react';
import axiosInstance from '@utils/axiosInstance';
import { AxiosResponseLoadingState } from '@utils/types/useAxios';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import useTranslation from './useTranslation';

type Request<CustomResponse> = {
  data: CustomResponse | any;
  response: AxiosResponse | null;
  loading: AxiosResponseLoadingState;
  statusCode: number | null;
  error: {
    message: string;
    type: string | null;
    code?: string;
    extra?: string[];
  } | null;
};

interface ExtendedAxiosRequestConfigDeprecated extends AxiosRequestConfig {
  isV2?: boolean;
}

type Execute = (options?: ExtendedAxiosRequestConfigDeprecated) => void;

/**
 * @deprecated use useRequest instead
 */
function useAxiosDeprecated<CustomResponse>(
  axiosOptions: ExtendedAxiosRequestConfigDeprecated,
): [Request<CustomResponse>, Execute] {
  const [request, setRequest] = useState<Request<CustomResponse>>({
    response: null,
    data: null,
    loading: null,
    error: null,
    statusCode: null,
  });
  const isMountedRef = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const execute = useCallback(
    async (options?: ExtendedAxiosRequestConfigDeprecated) => {
      setRequest({ response: null, data: null, loading: 'LOADING', error: null, statusCode: null });

      const _axiosInstance = axiosInstance(axiosOptions.isV2);
      try {
        const response = await _axiosInstance({ ...axiosOptions, ...options });
        if (isMountedRef.current) {
          setRequest({ response, data: response.data, loading: 'LOADED', error: null, statusCode: response.status });
        }
      } catch (error: unknown) {
        if (
          error &&
          typeof error === 'object' &&
          'response' in error &&
          error.response &&
          typeof error.response === 'object' &&
          'data' in error.response &&
          error.response.data &&
          (typeof error.response.data === 'object' || typeof error.response.data === 'string')
        ) {
          const data = error.response.data;

          let message: string = t('errors.unknown_error');
          let code = 'errors.unknown_error';

          if (typeof data === 'object') {
            if ('message' in error && typeof error.message === 'string') message = error.message;
            if ('message' in data && typeof data.message === 'string') message = data.message;

            if ('code' in error && typeof error.code === 'string') code = error.code;
            if ('code' in data && typeof data.code === 'string') code = data.code;
          }

          if (data instanceof Blob) {
            try {
              message = JSON.parse(await data.text())?.message;
              code = JSON.parse(await data.text())?.code;
            } catch (jsonError) {
              console.warn("The response data wasn't wasn't properly formatted JSON."); // eslint-disable-line no-console
              if (!message) message = t('errors.unknown_error');
            }
          }

          if (!isMountedRef.current) return;

          const defaultErrorObject: Request<CustomResponse> = {
            response: null,
            data: null,
            loading: 'ERROR',
            error: {
              message: typeof message === 'string' ? message : 'errors.unknown_error',
              type: null,
              code: typeof code === 'string' ? code : 'errors.unknown_error',
            },
            statusCode: 404,
          };

          if (!data) return setRequest(defaultErrorObject);

          const statusCode =
            'status' in error.response && typeof error.response.status === 'number' ? error.response.status : null;

          if ((typeof data === 'object' && 'status' in data && data.status === 'ERROR') || statusCode === 500) {
            return setRequest({
              ...defaultErrorObject,
              data: typeof data === 'object' ? data : null,
              error: {
                message: defaultErrorObject.error?.message || 'errors.unknown_error',
                type: typeof data === 'object' && 'type' in data && data.type ? `${data.type}` : '',
                code: typeof data === 'object' && 'code' in data && data.code ? `${data.code}` : '',
                extra:
                  typeof data === 'object' && 'extra' in data && Array.isArray(data.extra) ? data.extra : undefined,
              },
              statusCode,
            });
          }

          setRequest({
            ...defaultErrorObject,
            data: data,
            statusCode,
          });
        }
      }
    },
    [axiosOptions, t],
  );

  return [request, execute];
}

export default useAxiosDeprecated;
