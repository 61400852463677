import React from 'react';
import { NoWrapHeadCell } from '@GDM/Table';
import { ColumnDef } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';
import { User } from '@utils/types/user';
import { ActionsCell } from './cells/ActionsCell';
import { CapacityValueCell } from './cells/CapacityValueCell';
import { EventTypeCell } from './cells/EventTypeCell';
import { InstallationCell } from './cells/InstallationCell';
import { PmaxCell } from './cells/PmaxCell';
import { StatusCell } from './cells/StatusCell';
import { TimeCell } from './cells/TimeCell';

export type ColumnWithClassName = ColumnDef<Unavailability> & { className?: string };

export const getColumns = (user: User): ColumnWithClassName[] => {
  const cols: ColumnWithClassName[] = [
    {
      accessorFn: (unavailability) => unavailability.installation.name,
      header: 'common.installation',
      cell: InstallationCell,
      id: 'installation_name',
    },
    { header: () => <NoWrapHeadCell label="common.start_date" />, accessorKey: 'start_date', cell: TimeCell },
    { header: () => <NoWrapHeadCell label="common.end_date" />, accessorKey: 'end_date', cell: TimeCell },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.capacity_value" tooltip="MW" />,
      cell: CapacityValueCell,
      accessorKey: 'real_capacity_value',
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.p_max" tooltip="MW" />,
      cell: PmaxCell,
      accessorKey: 'p_max',
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.event_type" />,
      accessorKey: 'event_type',
      cell: EventTypeCell,
    },
    {
      header: () => <NoWrapHeadCell label="monitoring.unavailabilities.comment" />,
      accessorKey: 'comment',
    },
    {
      header: () => (
        <NoWrapHeadCell
          label={
            user.account_type == 'aggregator'
              ? 'monitoring.unavailabilities.producer'
              : 'monitoring.unavailabilities.aggregator'
          }
          tooltip="monitoring.unavailabilities.warnings.counterpart_null_case"
        />
      ),
      accessorKey: 'counterpart_name',
    },
    {
      header: () => <NoWrapHeadCell label="common.status_label" />,
      accessorKey: 'status',
      cell: StatusCell,
    },
    {
      header: () => <NoWrapHeadCell label="common.updated_at" />,
      accessorKey: 'updated_at',
      cell: TimeCell,
    },
    {
      header: () => <></>,
      id: 'actions',
      enableSorting: false,
      cell: ActionsCell,
    },
  ];

  return cols;
};
