import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { indexTabContext } from './index-tab.context';
import { IndexTabContextType } from './index-tab.definitions';
import { IndexFilters } from './IndexFilters';
import { CurrentTab } from './tabs/CurrentTab';
import { InvoicingTab } from './tabs/InvoicingTab';

export const IndexTab = () => {
  const [selectedTab, setSelectedTab] = useState<IndexTabContextType['selectedTab']>('current');
  const [currentDates, setCurrentDates] = useState<IndexTabContextType['currentDates']>({
    startDate: dayjs().subtract(7, 'd').toDate(),
    endDate: new Date(),
  });
  const [invoicingDate, setInvoicingDate] = useState<Date | null>(dayjs().subtract(1, 'month').toDate());
  const [selectedTypeParam, setSelectedTypeParam] =
    useState<IndexTabContextType['selectedTypeParam']>('prod_indexactif');
  const [highlightedRow, setHighlightedRow] = useState<IndexTabContextType['highlightedRow']>();

  const indexTabContextValue = useMemo<IndexTabContextType>(
    () => ({
      selectedTab,
      setSelectedTab,
      invoicingDate,
      setInvoicingDate,
      currentDates,
      setCurrentDates,
      selectedTypeParam,
      setSelectedTypeParam,
      highlightedRow,
      setHighlightedRow,
    }),
    [currentDates, highlightedRow, invoicingDate, selectedTab, selectedTypeParam],
  );

  return (
    <indexTabContext.Provider value={indexTabContextValue}>
      <IndexFilters />
      {selectedTab === 'current' && <CurrentTab />}
      {selectedTab === 'invoicing' && <InvoicingTab />}
    </indexTabContext.Provider>
  );
};
