import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import { Actions } from '@components/invoices/Actions';
import localeContext from '@context/locale.context';
import { useUser } from '@context/User.context';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
  VisibilityState,
  ColumnDef,
} from '@tanstack/react-table';
import { sortByDate } from '@utils/sorters';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import getColumns from './getColumns';
import styles from './invoice-table.module.scss';

export type InvoiceTableProps = {
  invoices: MeterInvoice[];
  invoiceType: MeterInvoiceSource;
  loading?: boolean;
  setClickedInvoice?: Dispatch<SetStateAction<MeterInvoice | null>>;
  setHistoryModalIsOpen?: Dispatch<SetStateAction<boolean>>;
  setIsUpdateModalOpen?: Dispatch<SetStateAction<boolean>>;
  isModal?: boolean;
  isLight?: boolean;
  isTableExpanded?: boolean;
  showActions?: boolean;
  columnVisibility?: VisibilityState;
  setIsTableExpanded?: (state: boolean) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  toggleEmailModal?: () => void;
  toggleNominateCegedimModal?: () => void;
  toggleNominateEdfOaModal?: () => void;
  reverseSort?: boolean;
  sortByDefault?: keyof MeterInvoice;
  wrapperClassName?: string;
  direction: Contract['direction'] | 'all';
};

const InvoiceTable = ({
  invoices,
  invoiceType,
  loading,
  setClickedInvoice,
  setHistoryModalIsOpen,
  setIsUpdateModalOpen,
  isModal,
  isTableExpanded,
  isLight,
  columnVisibility,
  setIsTableExpanded,
  startDate,
  endDate,
  toggleEmailModal,
  toggleNominateCegedimModal,
  toggleNominateEdfOaModal,
  showActions,
  reverseSort,
  sortByDefault,
  wrapperClassName,
  direction,
}: InvoiceTableProps) => {
  const locale = useContext(localeContext);
  const user = useUser();

  const handleEditClick = useCallback(
    (invoice: MeterInvoice) => {
      setClickedInvoice?.(invoice);
      setIsUpdateModalOpen?.(true);
    },
    [setClickedInvoice, setIsUpdateModalOpen],
  );

  const columns: ColumnDef<MeterInvoice>[] = useMemo(
    () =>
      getColumns(invoiceType, direction, {
        user,
        locale,
        isModal: !!isModal,
        isTableExpanded: !!isTableExpanded,
        isLight: !!isLight,
        setClickedInvoice,
        handleEditClick,
        setHistoryModalIsOpen,
      }),
    [
      isModal,
      locale,
      isTableExpanded,
      invoiceType,
      isLight,
      setClickedInvoice,
      user,
      handleEditClick,
      setHistoryModalIsOpen,
      direction,
    ],
  );

  const data = useMemo(() => {
    if (reverseSort) {
      return invoices.sort((a, b) => sortByDate(a.start_date, b.start_date));
    }

    return invoices;
  }, [invoices, reverseSort]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const table = useReactTable({
    columns,
    data,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination, columnVisibility },
    initialState: {
      sorting: [
        reverseSort ? { id: 'start_date', desc: false } : { id: 'end_date', desc: true },
        ...(!reverseSort ? [{ id: 'installation_name', desc: false }] : []),
        ...(sortByDefault ? [{ id: sortByDefault, desc: true }] : []),
      ],
    },
  });

  const pageSize = pagination.pageSize;

  const hasActions = data.length && table.getAllColumns().some((c) => c.id === 'actions');

  return (
    <>
      {showActions && (
        <Actions
          setIsTableExpanded={setIsTableExpanded}
          isTableExpanded={!!isTableExpanded}
          invoiceType={invoiceType}
          startDate={startDate}
          endDate={endDate}
          toggleEmailModal={toggleEmailModal}
          toggleNominateCegedimModal={toggleNominateCegedimModal}
          toggleNominateEdfOaModal={toggleNominateEdfOaModal}
          page={table.getPaginationRowModel().flatRows}
          className={classNames(isLight && 'pr-0')}
          direction={direction}
        />
      )}

      <div className={classNames(styles['invoice-table'], wrapperClassName)}>
        <Table data-cy="invoices-table" hasActions={!!hasActions}>
          <TableHead table={table} small />
          <TableBody table={table} isLoading={loading} />
        </Table>

        {data?.length > 10 && (
          <TableActions>
            <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
            <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
          </TableActions>
        )}
      </div>
    </>
  );
};

export default InvoiceTable;
