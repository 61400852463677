import { getErrorList } from './get-error-list';

export function getErrorMessage(e: unknown): string {
  let message = 'errors.unknown_error';

  if (e instanceof Error) message = e.message;

  if (e && typeof e === 'object') {
    const errors = getErrorList(e);
    if (
      Array.isArray(errors) &&
      typeof errors[0] === 'object' &&
      'message' in errors[0] &&
      typeof errors[0].message === 'string'
    ) {
      message = errors[0].message;
    }
  }

  return message;
}
