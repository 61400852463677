/**
 * Checks if input is a valid date (`Invalid Date` is still a Date object).
 */
export const isValidDate = (d?: Date | string | number | null): boolean => {
  if (!d) return false;

  const date = new Date(d);

  return date instanceof Date && !isNaN(date.getTime());
};
