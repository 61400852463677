import React from 'react';
import { ModalFooter as ModalFooterLegacy, ModalFooterProps } from 'reactstrap';
import styles from './modal.module.scss';

export const ModalFooter = ({ children, ...props }: ModalFooterProps) => {
  return (
    <ModalFooterLegacy className={styles['modal-footer']} {...props}>
      {children}
    </ModalFooterLegacy>
  );
};
