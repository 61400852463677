import { tString } from '@hooks/useTranslation';
import jsonToCsv from '@utils/json-to-csv';
import { Locale } from '@utils/types/common-types';
import { Owner } from '@utils/types/market_player';

export const loadOwnerCsvExport = (owners: Owner[], locale: Locale): string => {
  const t = tString(locale);

  return jsonToCsv(
    owners,
    [
      { label: t('owner.short_name'), key: 'short_name' },
      { label: t('owner.long_name'), key: 'long_name' },
      { label: t('common.country'), key: 'country' },
      { label: t('owner.company_number'), key: 'company_number' },
      { label: t('owner.erp_code'), key: 'erp_code' },
      { label: t('common.iban'), key: 'iban' },
      { label: t('owner.swift_bic'), key: 'swift_bic' },
      { label: t('owner.invoicing_email'), key: 'invoicing_emails' },
    ],
    { delimiter: ';' },
  );
};
