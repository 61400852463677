import React from 'react';
import { useUser } from '@context/User.context';
import { Permission } from '@utils/types/permission';

type RestrictedProps = {
  permissions: Permission[];
  fallback?: React.ReactNode;
  bypassRights?: boolean;
};

/**
 * Component that renders a children only if the user has the required permissions.
 */
export const Restricted = ({
  permissions,
  children,
  fallback,
  bypassRights = false,
}: React.PropsWithChildren<RestrictedProps>) => {
  const user = useUser();

  if (!user?.isAuthorized(permissions) && !bypassRights) {
    return fallback ? <>{fallback}</> : null;
  }

  return <>{children}</>;
};
