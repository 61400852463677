import React, { useContext } from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { EventType } from '@utils/types/unavailability';
import uniqBy from 'lodash/uniqBy';
import { Controller, useFormContext } from 'react-hook-form';
import { unavailabilityFiltersContext } from './context';
import { UnavailabilitiesFiltersType } from './type/filters';

export const EventFilter = () => {
  const { control } = useFormContext<UnavailabilitiesFiltersType>();
  const { filteredUnavailabilities } = useContext(unavailabilityFiltersContext);

  if (!filteredUnavailabilities) return null;

  const options: Option<EventType>[] = [
    ...uniqBy(filteredUnavailabilities, 'event_type').map((unavailability) => ({
      label: `monitoring.unavailabilities.${unavailability.event_type}`,
      value: unavailability.event_type,
    })),
  ];

  return (
    <Controller
      name="event"
      control={control}
      render={({ field }) => (
        <Select
          placeholder="monitoring.unavailabilities.event_type"
          options={options}
          selectedOption={field.value}
          onChange={(opt) => field.onChange(opt?.value)}
          isClearable
        />
      )}
    />
  );
};
