import React, { useEffect } from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { DropDownButton } from '@GDM/Button';
import { useInvoicePayment } from '@hooks/requests/invoices/useInvoicePayment';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import useTranslation from '@hooks/useTranslation';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import styles from '../actions.module.scss';
import SendToERPButton from './SendToERPButton';

export const PaymentButton = () => {
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { updateInvoices } = useInvoicesContext();
  const { data, markAsAccepted, markAsPaid, loading } = useInvoicePayment(selectedInvoicesUuids);
  const { t } = useTranslation();
  const { disabled: disabledOfftaker } = usePermittedStatus('accepted_offtaker');
  const { disabled: disabledPaid } = usePermittedStatus('paid');
  const { disabled: disabledSendToERP } = usePermittedStatus('sent_to_erp');

  useEffect(() => {
    if (data) {
      updateInvoices(data);
    }
  }, [data, updateInvoices]);

  return (
    <DropDownButton
      size="xxs"
      variant="primary-2"
      className="ml-2"
      dropDownMenuClassName={styles.dynamic}
      icon="DollarSign"
      noChevron
      disabled={disabledOfftaker && disabledPaid && disabledSendToERP}
      tooltip="monitoring.invoicing.payment_tracking"
      data-cy="payment-button-dropdown"
      isLoading={!!loading}
    >
      <SendToERPButton />
      <button
        className={styles['menu-element']}
        onClick={markAsAccepted}
        disabled={disabledOfftaker}
        data-cy="mark-as-accepted"
      >
        {t('monitoring.invoicing.accept_offtaker')}
      </button>
      <button className={styles['menu-element']} onClick={markAsPaid} disabled={disabledPaid} data-cy="mark-as-paid">
        {t('monitoring.invoicing.accept_paid')}
      </button>
    </DropDownButton>
  );
};
