import React, { useContext } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useUser } from '@context/User.context';
import { Modal } from '@GDM/Modal';
import useTranslation from '@hooks/useTranslation';
import { filteredEventsContext } from '../filtered-events.context';
import { EventRow } from './EventRow';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const EventsInfoModal = ({ isOpen, setIsOpen }: Props) => {
  const toggle = () => setIsOpen(false);
  const { t } = useTranslation();
  const { geolocation } = useUser();
  const { type } = useContext(filteredEventsContext);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" header="monitoring.alerts.modal.title" scrollable>
      {type === 'monitoring' && (
        <>
          <div className="tt:uppercase fw:700">{t('monitoring.alerts.modal.level_1')}</div>
          <EventRow
            eventType="ProductionStop"
            title={t('monitoring.alerts.modal.production_stop')}
            description={t('monitoring.alerts.modal.production_stop_text')}
          />
          <EventRow
            eventType="HighUnderPerformance"
            title={t('monitoring.alerts.modal.high_under_performance')}
            description={t('monitoring.alerts.modal.high_under_performance_text')}
          />
          <EventRow
            eventType="OutOfRangeReactive"
            title={t('monitoring.alerts.modal.out_of_range_reactive')}
            description={t('monitoring.alerts.modal.out_of_range_reactive_text')}
          />
          <div className="tt:uppercase fw:700 mt-4">{t('monitoring.alerts.modal.level_2')}</div>

          <EventRow
            eventType="CommunicationFailure"
            title={t('monitoring.alerts.modal.communication_failure')}
            description={t('monitoring.alerts.modal.communication_failure_text')}
          />
          <EventRow
            eventType="UnderPerformance"
            title={t('monitoring.alerts.modal.under_performance')}
            description={t('monitoring.alerts.modal.under_performance_text')}
          />
        </>
      )}

      {type === 'sales' && (
        <>
          <EventRow
            eventType="AnnualProductionExceedsCap"
            title={t('monitoring.alerts.modal.prod_annual_exceeds_cap')}
            description={t('monitoring.alerts.modal.prod_annual_exceeds_cap_text')}
          />
          {/* TODO: FAT HACK FOR SPANISH DEMO */}
          {geolocation?.[0] !== 'ES' && (
            <EventRow
              eventType="AnnualProductionExceedsDafr"
              title={t('monitoring.alerts.modal.prod_annual_exceeds_dafr')}
              description={t('monitoring.alerts.modal.prod_annual_exceeds_dafr_text')}
            />
          )}

          <EventRow
            eventType="ProductionOnNegativePrice"
            title={t('monitoring.alerts.modal.prod_on_negative_price')}
            description={t('monitoring.alerts.modal.prod_on_negative_price_text')}
          />
          <EventRow
            eventType="InvoiceMissingData"
            title={t('monitoring.alerts.modal.invoice_missing_data')}
            description={t('monitoring.alerts.modal.invoice_missing_data_text')}
          />
          <EventRow
            eventType="HighDiffPublishedProduction"
            title={t('monitoring.alerts.modal.high_diff_published_production')}
            description={t('monitoring.alerts.modal.high_diff_published_production_text')}
          />
        </>
      )}
    </Modal>
  );
};
