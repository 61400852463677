import { isANumber } from '@utils/isANumber';
import { CSVExportColumn, CSVExportPeriod, InvoiceRevenueGroupedDataPoint, RevenueContextType } from '../revenue.types';

export function formatGroupedDataForExport(
  periods: CSVExportPeriod[],
  columns: CSVExportColumn[],
  overview: RevenueContextType['overview'],
  revenue?: InvoiceRevenueGroupedDataPoint[] | null,
): { [key: string]: string | number }[] {
  return [...periods].map((period) => {
    const periodDataPoint = [...columns].reduce((acc, curr) => {
      if (!curr.key || curr.key === 'period') return acc;

      let value: number | string | null = null;
      const subKeySplit = curr.key?.split('.') ?? [];

      // If there's a . in the key, it means it's a sub key
      if (revenue && subKeySplit.length > 1) {
        let subGroupItem: NonNullable<InvoiceRevenueGroupedDataPoint['sub_groups']>[number] | null = null;

        for (const item of revenue) {
          if (item.label === subKeySplit[0]) {
            subGroupItem = item.sub_groups?.find((subGroup) => subGroup.label === subKeySplit[1]) || null;
            break;
          }
        }

        const subGroupRevenue = subGroupItem?.values[period.index].revenue;

        if (subGroupRevenue !== undefined) {
          value = subGroupRevenue;
        }
      } else {
        value =
          revenue?.find((item) => item.label === curr.key)?.values[period.index]?.revenue ??
          overview?.find((item) => item.field === curr.key)?.data[period.index]?.value ??
          null;
      }

      return {
        ...acc,
        [curr.key]: isANumber(value) ? Number(Number(value).toFixed(2)) : '',
      };
    }, {});

    return { period: period.label, ...periodDataPoint };
  });
}
