import React from 'react';
import { Step } from '@GDM/forms';
import { MeterSettingsSection } from '@pages/Installations/Installation/sections/MeterSettingsSection';
import { OwnerSection } from '@pages/Installations/Owner/OwnerForm/sections/OwnerSection';
import { StepKey } from '../installation.types';
import { InstallationSettingsSection } from '../sections/InstallationSettingsSection';

export const stepKeys = ['installation', 'owner', 'meter'] as const;

// List of countries that have the meter step
export const meterStepCountries = ['FR', 'ES', 'IT'];

export const steps: { [key in StepKey]?: Step } = {
  installation: { title: 'common.installation', progress: 0, component: <InstallationSettingsSection /> },
  owner: { title: 'common.owner', progress: 0, isValid: true, component: <OwnerSection hasOwnerSelect /> },
  meter: { title: 'common.meter', progress: 0, component: <MeterSettingsSection /> },
};
