import React from 'react';
import { updateStatus } from '@api/invoices';
import { Restricted } from '@components/Restricted';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { Button } from '@GDM/Button';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import { useMutation } from '@tanstack/react-query';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';

const SendToERPButton = () => {
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { updateInvoices } = useInvoicesContext();
  const { disabled } = usePermittedStatus('sent_to_erp');
  const mutation = useMutation({
    mutationFn: async () => updateStatus('send_to_erp', selectedInvoicesUuids),
    onSuccess: (invoices) => {
      updateInvoices(invoices);
    },
  });

  return (
    <Restricted permissions={['display:non_engie']}>
      <Button
        variant="sub-button"
        text="monitoring.invoicing.mark_as_sent_erp"
        onClick={() => mutation.mutate()}
        isLoading={mutation.isPending}
        disabled={disabled}
      />
    </Restricted>
  );
};

export default SendToERPButton;
