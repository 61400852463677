import React from 'react';
import { Icon } from '@GDM/Icon';
import { LightDefinition } from '@GDM/LightDefinitionList';
import getContractTypeLabel from '@utils/contract/getLabel';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const InvoiceInformationDefinitionList = ({
  invoice,
  className,
}: {
  invoice: MeterInvoice;
  className?: string;
}) => {
  return (
    <div className={className}>
      <LightDefinition label="common.installation" value={invoice.installation_name} />
      <LightDefinition
        label="common.contract"
        value={getContractTypeLabel(invoice.contract_type, invoice.installation_country)}
      />
      <LightDefinition
        label="common.dates"
        value={
          <>
            {invoice.start_date}
            <Icon name="ArrowRight" size={14} className="mx-1" />
            {invoice.end_date}
          </>
        }
      />
    </div>
  );
};
