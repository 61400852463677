import { RefObject, useEffect, useMemo } from 'react';

export const useResizeObserver = (
  callBack: (entries: ResizeObserverEntry[]) => void,
  ref: RefObject<HTMLElement>,
): void => {
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        callBack(entries);
      }),
    [callBack],
  );

  useEffect(() => {
    const { current } = ref;

    if (current) {
      resizeObserver.observe(current);
    }

    return () => {
      if (current) {
        resizeObserver.unobserve(current);
      }
    };
  }, [ref, resizeObserver]);
};
