import React, { useContext } from 'react';
import localeContext from '@context/locale.context';
import { Button, type ButtonProps } from '@GDM/Button';
import type { Contract } from '@utils/types/contract';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import invoicesContext from 'pages/sales-management/invoicing/invoicesContext';
import indexCsvExport from 'pages/sales-management/invoicing/utils/csvExports/indexCsvExport';
import loadCurveCsvExport from 'pages/sales-management/invoicing/utils/csvExports/loadCurveCsvExport';

const DownloadCsvButton = ({
  invoiceType,
  startDate,
  endDate,
  variant,
  direction,
}: {
  invoiceType: MeterInvoiceSource;
  startDate?: Date | null;
  endDate?: Date | null;
  variant: ButtonProps['variant'];
  direction: Contract['direction'] | 'all';
}) => {
  const locale = useContext(localeContext);
  const { filteredInvoices } = useContext(invoicesContext);

  const handleExportCsvLoadCurveClick = () => {
    fileDownload(
      loadCurveCsvExport(filteredInvoices, locale, direction),
      'facturation.csv',
      'text/csv;charset=utf-8',
      '\uFEFF',
    );
  };

  const handleExportCsvIndexClick = () => {
    const start = dayjs(startDate).format('YYYYMM');
    const end = dayjs(endDate).format('YYYYMM');

    const fileName = `facturation_index_${start}_${end}.csv`;

    fileDownload(indexCsvExport(filteredInvoices, locale), fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <Button
      onClick={invoiceType === 'index' ? handleExportCsvIndexClick : handleExportCsvLoadCurveClick}
      variant={variant}
      size={variant !== 'sub-button' ? 'xxs' : undefined}
      icon={variant !== 'sub-button' ? 'Download' : undefined}
      text={variant !== 'sub-button' ? 'common.download' : 'common.csv'}
    />
  );
};

export default DownloadCsvButton;
