import React from 'react';
import { Section } from '@GDM/forms/ProgressStepper/Section/Section';
import { URL } from '@utils/regexes';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { TextInput } from '../../components/Inputs/Text';
import { SectionHeader } from '../../components/SectionHeader';

export const AdditionalInfo = ({ title, readOnly, form: { control } }: ContractFormSectionProps) => {
  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="p-3">
          <TextInput
            id="ExternalPdfLink"
            name="external_pdf_link"
            control={control}
            label="sales_management.external_pdf_link"
            readOnly={readOnly}
            rules={{ pattern: URL }}
            data-cy="pdf-link"
          />
        </Section>
      }
    />
  );
};
