import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Alert } from '@GDM/Alert';
import { Button } from '@GDM/Button';
import { Input, Select } from '@GDM/forms';
import { Col, Row } from '@GDM/layout';
import { useModal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { Option } from '@utils/types/common-types';
import { ContractSubPeriod } from '@utils/types/contract';
import { MarketFuture } from '@utils/types/market_future';
import uniqWith from 'lodash/uniqWith';
import { selectedContractsContext } from '../context';
import styles from '../contracts.module.scss';
import useMassEditContracts, { BodyType } from '../utils/useMassEditContracts';
import { MassEditTable } from './MassEditTable';

type SubPeriodForm = Partial<Record<keyof ContractSubPeriod, string>>;

export const SubPeriodPropertyForm = ({
  propertyToUpdate,
  onAfterUpdate,
}: {
  propertyToUpdate: keyof ContractSubPeriod;
  onAfterUpdate: () => void;
}) => {
  const { contracts, update, loading, error, finished } = useMassEditContracts();
  const { selectedContracts } = useContext(selectedContractsContext);
  const { replaceContracts } = useConsumeContracts();
  const { toggle } = useModal();

  const { t } = useTranslation();
  const selectedKey = propertyToUpdate;

  // Filters
  const [selectedMarketFuture, setSelectedMarketFuture] = React.useState<MarketFuture | null>(null);
  const [formState, setFormState] = useState<SubPeriodForm>({ [selectedKey]: '' });

  const isOnlySoa = selectedContracts.map((c) => c.type).every((ct) => ct === 'ContractSoa');

  const selectedSubPeriods = selectedContracts.flatMap(
    (c) =>
      c.contract_sub_periods
        .filter((csp) => !selectedMarketFuture || csp.market_future_id === selectedMarketFuture?.id)
        .map((csp) => csp.id)
        .filter(Boolean) as string[],
  );

  const marketFutures: MarketFuture[] = useMemo(
    () =>
      // use sub period market futures and take the uniq set
      uniqWith(
        selectedContracts.flatMap((c) => c.contract_sub_periods.map((cp) => cp.market_future).filter((mf) => mf)),
        (marketFuture1, marketFuture2) => marketFuture1?.id === marketFuture2?.id, // uniq by id
      ).filter(Boolean) as MarketFuture[],
    [selectedContracts],
  );

  const MARKET_FUTURES_OPTIONS: Option<string | null>[] = [
    { label: t('common.all'), value: null },
    ...marketFutures.map((mf) => ({ label: mf.name, value: mf.id })),
  ];

  const handleMarketFuturChange = (marketFuture?: MarketFuture) => {
    setSelectedMarketFuture(marketFuture || null);
  };

  const setValue = (value: string | boolean | null) => {
    setFormState((_formState) => {
      const clonedFormState = { ..._formState };

      clonedFormState[selectedKey] = value as string;

      return clonedFormState;
    });
  };

  const handleSave = () => {
    const body: BodyType = {
      contract_ids: selectedContracts.map((c) => c.id),
      contract_sub_period_ids: selectedSubPeriods,
    };

    if (formState.display_price) {
      body.display_price = +formState.display_price;
    }

    update(body);
  };

  useEffect(() => {
    if (finished && contracts) {
      replaceContracts(contracts);
      onAfterUpdate();
    }
  }, [finished, onAfterUpdate, replaceContracts, contracts]);

  return (
    <>
      <Row>
        <Col md={6}>
          <Select
            label="sales_management.market_future"
            options={MARKET_FUTURES_OPTIONS}
            onChange={(option) => handleMarketFuturChange(marketFutures.find((mf) => mf.id === option?.value))}
            selectedOption={selectedMarketFuture?.id}
          />
        </Col>

        <Col md={6}>
          <Input
            label="sales_management.mass_update_value"
            onChange={(e) => setValue(e.currentTarget.value)}
            value={formState[selectedKey]}
            autoComplete="off"
          />
        </Col>
      </Row>

      {!isOnlySoa && <Alert className="mt-4" label="sales_management.mass_update_warning_not_soa" variant="danger" />}

      <Alert className="mb-4 mt-2" label="sales_management.mass_update_warning_update" variant="danger" />

      <div className="tt:uppercase fw:700" style={{ fontSize: '0.75rem' }}>
        {t('sales_management.mass_update_current_data')}
      </div>

      <MassEditTable
        contracts={selectedContracts}
        newPrice={formState?.display_price ?? ''}
        newMarketFuture={selectedMarketFuture}
      />

      <div className={styles['modal-footer']}>
        {error && <Text text={t(error, { prop: '"price"' })} className="mt-2" type="danger" />}

        <Button size="sm" type="button" variant="link" onClick={toggle} text="common.cancel" />

        <Button
          size="sm"
          variant="primary-2"
          disabled={loading}
          onClick={handleSave}
          isLoading={loading}
          text="common.save"
        />
      </div>
    </>
  );
};
