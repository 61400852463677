import React, { useState } from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { formatSystemDate, toDate } from '@utils/formatters';
import { setQueryParams } from '@utils/queryParams';
import dayjs from 'dayjs';
import { usePortfolio } from '../usePortfolio';

export const DatesFilter = () => {
  const { startDate, endDate, setEndDate, setStartDate } = usePortfolio();
  const [dates, setDates] = useState<[Date | null, Date | null]>([
    startDate ? toDate(startDate) : null,
    endDate ? toDate(endDate) : null,
  ]);

  const handleDatesChange = () => {
    let first_date = formatSystemDate(toDate(dates[0]));
    const last_date = formatSystemDate(toDate(dates[1]));

    if (dayjs(last_date).diff(first_date, 'day') > 60)
      first_date = formatSystemDate(dayjs(last_date).subtract(60, 'day').toDate());

    if (formatSystemDate(startDate) !== first_date || formatSystemDate(endDate) !== last_date) {
      setStartDate?.(first_date);
      setEndDate?.(last_date);
      setDates([toDate(first_date), toDate(last_date)]);
      setQueryParams({ start_date: first_date, end_date: last_date });
    }
  };

  return (
    <DatePicker
      onCalendarClose={handleDatesChange}
      onChange={(dates) => setDates(dates)}
      maxDate={new Date()}
      startDate={dates[0]}
      endDate={dates[1]}
      selectsRange={true}
      data-cy="dates"
    />
  );
};
