import React, { useEffect, useState } from 'react';

export default function useIsOnScreen<T extends Element>(ref: React.MutableRefObject<T> | React.RefObject<T>): boolean {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return isIntersecting;
}
