import { useMemo } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { ColumnDef } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import { CONTRACT_COLUMNS } from './ColumnList';

export function useColumns() {
  const { infoFilters } = useConsumeContracts();

  const columns = useMemo<ColumnDef<Contract>[]>(() => {
    return [
      CONTRACT_COLUMNS.CHECKBOX,
      CONTRACT_COLUMNS.TYPE,
      ...(infoFilters.country ? [CONTRACT_COLUMNS.COUNTRY] : []),
      CONTRACT_COLUMNS.INSTALLATION,
      ...(infoFilters.classification ? [CONTRACT_COLUMNS.CLASSIFICATION] : []),
      ...(infoFilters.commissioningDate ? [CONTRACT_COLUMNS.COMMISSIONING_DATE] : []),
      ...(infoFilters.cardI ? [CONTRACT_COLUMNS.CARD_I] : []),
      ...(infoFilters.p_max ? [CONTRACT_COLUMNS.P_MAX] : []),
      ...(infoFilters.seller ? [CONTRACT_COLUMNS.SELLER] : []),
      ...(infoFilters.sellerSiren ? [CONTRACT_COLUMNS.SELLER_SIREN] : []),
      ...(infoFilters.buyer ? [CONTRACT_COLUMNS.BUYER] : []),
      ...(infoFilters.horizon ? [CONTRACT_COLUMNS.HORIZON] : []),
      ...(infoFilters.volume ? [CONTRACT_COLUMNS.VOLUME] : []),
      ...(infoFilters.pricing ? [CONTRACT_COLUMNS.PRICING] : []),
      CONTRACT_COLUMNS.ACTIONS,
    ];
  }, [infoFilters]);

  return { columns };
}
