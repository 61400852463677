import { createContext } from 'react';
import { MonitoringInvoicesFiltersContext } from '../invoices.types';

export const monitoringInvoicesFiltersContext = createContext<MonitoringInvoicesFiltersContext>({
  filters: {
    installationName: '',
    invoicingType: 'All',
  },
  setFilters: () => {},
});
