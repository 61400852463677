import React from 'react';
import { Progress } from '@GDM/Progress';

export const CoverageCell = ({ value, getValue }: { value?: number | null; getValue?: () => unknown }) => {
  value = value || (getValue?.() as number) || 0;

  const adjustedCoverage = value > 98 ? 100 : value;

  return <Progress value={adjustedCoverage} dangerThreshold={50} />;
};
