import React, { useMemo } from 'react';
import { sectionStyles } from '@GDM/forms';
import { EnergyType } from '@utils/types/common-types';
import { Option } from '@utils/types/common-types';
import { ContractType, ContractForm } from '@utils/types/contract';
import {
  ContractSubType,
  contractCrEdfHydroSubTypes,
  contractCrEdfSolarSubTypes,
  contractCrEdfWindSubTypes,
  contractOaSubTypes,
} from '@utils/types/contract_sub_type';
import { Control } from 'react-hook-form';
import { ListInput } from '../../../components/Inputs/List';

export const SchemeAndSubType = ({
  control,
  readOnly,
  contractType,
  energyType,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
  contractType: ContractType | null;
  energyType: EnergyType | undefined;
}) => {
  const subTypesOptions = useMemo<Option<ContractForm['sub_type']>[]>(() => {
    let subTypes: ContractSubType[] = [];
    if (contractType && energyType) subTypes = SUB_TYPES[contractType]?.[energyType] || [];
    if (!subTypes.length && contractType) subTypes = SUB_TYPES[contractType]?.['default'] || [];

    return subTypes.map((i) => ({ value: i, label: i.toUpperCase() }));
  }, [contractType, energyType]);

  return (
    <div className={sectionStyles.row}>
      <ListInput
        id="SchemePicker"
        name="dispositif"
        control={control}
        options={[
          { value: 'ao_cre', label: 'sales_management.dispositif_ao_cre' },
          { value: 'guichet_ouvert', label: 'sales_management.dispositif_guichet_ouvert' },
        ]}
        label="sales_management.dispositif"
        classNamePrefix="select-scheme"
        readOnly={readOnly}
        helpBox={{ title: 'sales_management.dispositif', text: 'contracts.info.dispositif' }}
      />
      <ListInput
        id="SubTypePicker"
        name="sub_type"
        control={control}
        options={subTypesOptions}
        label="sales_management.sub_type_select"
        classNamePrefix="select-sub-type"
        readOnly={readOnly}
        isSearchable
        helpBox={{ title: 'sales_management.sub_type_select', text: 'contracts.info.sub_type' }}
      />
    </div>
  );
};

const SUB_TYPES: Partial<Record<ContractType, { [key in EnergyType | 'default']?: ContractSubType[] }>> = {
  ContractOa: {
    default: [...contractOaSubTypes],
  },
  ContractCrEdf: {
    wind: [...contractCrEdfWindSubTypes],
    solar: [...contractCrEdfSolarSubTypes],
    hydro: [...contractCrEdfHydroSubTypes],
  },
};
