import React from 'react';
import classNames from 'classnames';
import styles from './dropdown-menu.module.scss';

type DropDownMenuProps = {
  isOpen: boolean;
  className?: string;
  unstyled?: boolean;
};

export const DropDownMenu = React.forwardRef<HTMLDivElement, React.PropsWithChildren<DropDownMenuProps>>(
  ({ isOpen, children, className, unstyled }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          styles['dropdown-menu'],
          isOpen && styles['dropdown-menu-open'],
          unstyled && styles.unstyled,
          className,
        )}
      >
        {children}
      </div>
    );
  },
);
