import { createContext, useContext } from 'react';

type CreateModalContext = {
  isOpen: boolean;
  toggle?: () => void;
};

export const createModalContext = createContext<CreateModalContext>({
  isOpen: false,
});

export const useCreateModalContext = (): CreateModalContext => useContext(createModalContext);
