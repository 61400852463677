import { UserCtxType } from '@context/User.context';
import { tString, TranslateFn } from '@hooks/useTranslation';
import getContractTypeLabel from '@utils/contract/getLabel';
import { isExpired, isNotStarted, isOngoing } from '@utils/filters/isDateStatus';
import jsonToCsv, { HeadCsv } from '@utils/json-to-csv';
import { Contract, ContractType } from '@utils/types/contract';
import { getAggregFeeCsv } from './getAggregFeeCsv';
import { getLatestUnitPrice } from './getLatestUnitPrice';
import { getRefPriceTagCsv } from './getRefPriceTagCsv';

const getDateStatus = (contract: Contract, t: TranslateFn): string => {
  if (isOngoing(contract)) return t('contracts.ongoing');
  if (isNotStarted(contract)) return t('contracts.not_started');
  if (isExpired(contract)) return t('contracts.expired');

  return '';
};

export const csvExport = (contracts: Contract[], user: UserCtxType): string => {
  const t = tString(user.locale);
  const headers: HeadCsv<Contract>[] = [
    {
      key: 'type',
      label: 'sales_management.contract_type',
      transform: (value) => t(getContractTypeLabel(value as ContractType, user.main_country ?? null)),
    },
    {
      label: 'common.status_label',
      getValue: (contract) => getDateStatus(contract, t),
    },
    { key: 'contract_nb', label: 'sales_management.contract_nb' },
    { key: 'card_i', label: 'sales_management.card_i' },
    { key: 'installation.country', label: 'common.country' },
    { key: 'installation.name', label: 'common.installation' },
    { key: 'p_max', label: `${t('common.capacity')} (kW)` },
    { key: 'seller.short_name', label: 'sales_management.seller' },
    { key: 'buyer.short_name', label: 'sales_management.buyer' },
    { key: 'installation.external_ref', label: 'common.client_id' },
    { label: 'common.start_date', key: 'start_date' },
    { label: 'common.end_date', key: 'end_date' },
    { label: 'common.price_type', transform: (_, contract) => getRefPriceTagCsv(contract, user) },
    { label: 'sales_management.aggreg_fee', transform: (_, contract) => getAggregFeeCsv(contract, user).value },
    { label: 'common.unit', transform: (_, contract) => getAggregFeeCsv(contract, user).unit },
    {
      label: 'sales_management.latest_unit_price',
      transform: (_, contract) => getLatestUnitPrice(contract, user).value,
    },
    {
      label: 'common.unit',
      transform: (_, contract) => getLatestUnitPrice(contract, user).unit,
    },
  ];

  return jsonToCsv(contracts, headers, { delimiter: ';', locale: user.locale, keepAccents: true });
};
