import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const TotalPowerWindInput = () => {
  const { control } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('total_power_wind');

  return (
    <Controller
      control={control}
      name="total_power_wind"
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error ? 'pricing.form.errors.total_power_wind' : null}
          type="number"
          step="1"
          full
          size="lg"
          suffix="kW"
          label="common.total_power"
          value={field.value ?? ''}
          data-cy="total_power_wind"
        />
      )}
    />
  );
};
