import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { Contract } from '@utils/types/contract';
import type { DeepPartial } from 'react-hook-form';

export default function isBuySell(contract: Contract, filters: DeepPartial<ContractsFiltersType>): boolean {
  if (
    !filters.direction ||
    (filters.direction === 'buy' && contract.direction === 'buy') ||
    (filters.direction === 'sell' && contract.direction === 'sell')
  ) {
    return true;
  }

  return false;
}
