import { useCallback } from 'react';
import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { generate_v2_meter_invoicing_index_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { AxiosResponse } from 'axios';

type GenerateInvoiceRequestBody = {
  invoices: Array<{ id: string; invoice_nb?: string; order_number?: string | null }>;
};

const generateInvoice =
  (body: GenerateInvoiceRequestBody) =>
  async ({ url }: { url: string }) => {
    const request = await axiosInstance(false).post<GenerateInvoiceRequestBody, AxiosResponse<MeterInvoice[]>>(
      url,
      body,
      {
        /** this is specific for this endpoint, we cannot use our custom post for this */
        validateStatus: (status) => status < 206,
      },
    );

    return { data: request.data, status: request.status };
  };

export const useGenerateInvoice = (body: GenerateInvoiceRequestBody): CommonRequestHookResponse<MeterInvoice[]> => {
  const generate = useCallback(({ url }: { url: string }) => generateInvoice(body)({ url }), [body]);

  return useRequest(generate_v2_meter_invoicing_index_path({ with_email: false }), generate, true);
};
