import React from 'react';
import getMeterType from '@utils/getMeterType';
import { EventIcon, TypeIcon } from '@utils/getTypeMeterIcons';
import classNames from 'classnames';
import { DashboardViewAllData } from '../portfolio.definitions';
import styles from './map.module.scss';

export const PopupTitle = ({ meter }: { meter: DashboardViewAllData }) => {
  const event_types = meter.event_types;

  if (event_types && event_types.includes('HighUnderPerformance') && event_types.includes('UnderPerformance'))
    event_types.splice(event_types.indexOf('UnderPerformance'), 1);

  return (
    <div className={classNames(styles['popup-title'], 'my-3')}>
      <TypeIcon type={getMeterType(meter)} />
      <div className={styles['popup-title-name']}>
        <span className="pr-1">{meter.ref_centrale}</span>
        {event_types?.map((a) => (
          <React.Fragment key={a}>
            <EventIcon type={a} />
            &nbsp;
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
