import React, { useMemo, useState } from 'react';
import { useSubUsersContext } from '@context/admin/users/subusers.context';
import { Modal } from '@GDM/Modal';
import { Tabs } from '@GDM/Tabs';
import useTranslation from '@hooks/useTranslation';
import { SubUserWithAdditionalInfo } from '@utils/types/user';
import { generateEmptySubUser } from '@utils/users/generateUsers';
import { UserInfos } from './form/UserInfos';
import UserPermissions from './form/UserPermissions';
import { GiveablePermissions } from './Users';

enum TabNames {
  Information,
  Permissions,
}

const UsersModal = ({
  toggle,
  selectedSubUserId,
  giveablePermissions,
}: {
  toggle: () => void;
  selectedSubUserId: string | null;
  giveablePermissions: GiveablePermissions;
}) => {
  const { subUsersWithAdditionalInfo } = useSubUsersContext();
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] = useState(TabNames.Information);
  const selectedSubUser = useMemo<SubUserWithAdditionalInfo>(
    () => subUsersWithAdditionalInfo.find((su) => su.uuid === selectedSubUserId) || generateEmptySubUser(),
    [subUsersWithAdditionalInfo, selectedSubUserId],
  );
  const canHavePermissionsEdited = useMemo<boolean>(
    () => !!selectedSubUser?.uuid && !selectedSubUser?.limited,
    [selectedSubUser],
  );

  return (
    <Modal
      isOpen={selectedSubUserId !== null}
      toggle={toggle}
      header={selectedSubUserId ? t('admin.sub_client.edit_sub_account') : t('admin.sub_client.add_sub_account')}
      hideCancelButton
      data-cy="user-modal"
    >
      <Tabs
        tabs={[
          { id: TabNames.Information, name: t('admin.sub_client.tabs.information') },
          ...(canHavePermissionsEdited
            ? [{ id: TabNames.Permissions, name: t('admin.sub_client.tabs.permissions') }]
            : []),
        ]}
        onTabChange={setTabSelected}
        className="mb-3"
      />

      {tabSelected === TabNames.Information && <UserInfos selectedSubUser={selectedSubUser} onClose={toggle} />}

      {tabSelected === TabNames.Permissions && canHavePermissionsEdited && (
        <UserPermissions giveablePermissions={giveablePermissions} selectedSubUser={selectedSubUser} onClose={toggle} />
      )}
    </Modal>
  );
};

export default UsersModal;
