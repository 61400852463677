import React from 'react';
import { HeadCell } from '@GDM/Table';
import { usePortfolio } from '@pages/Portfolio/usePortfolio';

export const PortfolioTranslatedHeader = ({
  name,
  tooltip,
  'data-cy': dataCy,
}: {
  name: string;
  tooltip?: string;
  'data-cy'?: string;
}) => {
  const { dataType } = usePortfolio();

  const label = name === 'name' ? `monitoring.portfolio.table.name.${dataType}` : name;

  return <HeadCell tooltip={tooltip} label={label} data-cy={dataCy} />;
};
