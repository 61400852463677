import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  v2_installation_m0_statistics_path,
  v2_book_m0_statistics_path,
  v2_market_player_m0_statistics_path,
} from '@utils/routes';
import { EnergyType } from '@utils/types/common-types';
import { M0Aggregated, M0Data } from '@utils/types/m0';
import dayjs from 'dayjs';

type Props = {
  identifier: string | null;
  source: 'installation' | 'book' | 'market_player' | null;
  energy?: EnergyType | null;
  startDate: Date | null;
  endDate: Date | null;
};

type Data = {
  m0ByInstallation: M0Data;
  m0Aggregated: M0Aggregated;
};

export const useM0Data = ({ identifier, source, energy, startDate, endDate }: Props): UseQueryResult<Data> => {
  return useQuery({
    queryKey: ['getM0Data', identifier, source, startDate, endDate, energy],
    queryFn: async () => {
      const routesMap = {
        installation: v2_installation_m0_statistics_path,
        book: v2_book_m0_statistics_path,
        market_player: v2_market_player_m0_statistics_path,
      };

      const getUrl = source ? routesMap[source] : null;
      if (!getUrl || !identifier) return;

      const params = {
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        energy,
      };
      const url = getUrl(identifier, params);
      const response = await fetch(url);
      const m0Data: M0Data = await response.json();
      const m0Aggregated = m0Data?.['AGGREGATED'] || {};
      const m0ByInstallation = Object.keys(m0Data)
        .filter((key) => key !== 'AGGREGATED')
        .reduce((obj: M0Data, key: string) => {
          obj[key] = m0Data[key];

          return obj;
        }, {});

      return { m0Aggregated, m0ByInstallation };
    },
    enabled: Boolean(identifier && source && startDate && endDate),
  });
};
