import { InvoiceRevenueDataPoint, Point } from '../revenue.types';

export const mapToDataPoint = (item: InvoiceRevenueDataPoint): Point => {
  const y = Number(item.revenue ?? item.value);

  return {
    x: new Date(item.period).valueOf(),
    y: isNaN(y) ? null : y,
    // If it's forecast
    opacity: new Date(item.period).valueOf() > Date.now() ? '0.5' : undefined,
  };
};
