import React, { useEffect, useState } from 'react';
import { FullFormType, MandateFormType } from '@context/AdMeterForm.context';
import { Alert } from '@GDM/Alert';
import { Button } from '@GDM/Button';
import { Col, Row } from '@GDM/layout';
import { Text } from '@GDM/Text';
import useAxiosDeprecated from '@hooks/useAxios';
import { useFormLegacy } from '@hooks/useFormLegacy';
import useTranslation from '@hooks/useTranslation';
import { useInstallationsPageContext } from '../context/installations-page.context';
import GeneratePDFButton from './buttons/GeneratePDFButton';
import SaveMandateBtn from './buttons/SaveMandateBtn';
import SelectMandate from './fields/SelectMandate';
import SignatoryEmailInput from './fields/SignatoryEmailInput';
import SignatoryNameInput from './fields/SignatoryNameInput';
import SignInput from './fields/SignInput';
import SiretsInput from './fields/SiretsInput';
import UploadMandateInput from './fields/UploadMandateInput';
import styles from './installations.module.scss';
import RenderStatus from './text/RenderStatus';
import RequestStatusMessage from './text/RequestStatusMessage';
import { formatUploadBody, formatUpsertMandateBody } from './utils/formatBody';

export type FormMandatesProps = {
  allowSelect: boolean;
  onParentFormChange?: (
    key: keyof FullFormType | null,
    value: FullFormType[keyof FullFormType] | Partial<FullFormType>,
  ) => void;
  selectedMandate: MandateFormType | null;
  onClose?: () => void;
};

/**
 * Component to edit or create a mandate
 */
const FormMandates = ({ onParentFormChange, selectedMandate, allowSelect, onClose }: FormMandatesProps) => {
  const { addMandate } = useInstallationsPageContext();

  const defaultValues: MandateFormType = {
    mandateId: 'select_mandate',
    signatoryEmail: '',
    signatoryName: '',
    sirets: '',
    mandateFile: null,
    status: '',
    mandataire: '',
    signType: 'online',
  };
  const [form, setForm, setFormData] = useFormLegacy(defaultValues);

  const [requestMandate, upsertMandate] = useAxiosDeprecated({ url: '/mandates', method: 'POST' });
  const [requestUpload, upload] = useAxiosDeprecated({ url: '/mandates/upload', method: 'POST' });

  const [allowEdit, setAllowEdit] = useState(true);
  const [valuesHasChanged, setValuesHasChanged] = useState(false);
  const disabled = !allowEdit;
  const toRenew = form.status === 'to_renew' || form.status === 'expired' || form.status === 'signed';
  const requiredFields =
    !form.sirets || !form.signatoryName || !form.signatoryEmail || requestMandate.loading === 'LOADING';
  const { t } = useTranslation();

  /**
   * EFFECTS
   */
  useEffect(() => {
    if (!allowSelect || form.mandateId === 'new_mandate') {
      setAllowEdit(true);
    } else {
      setAllowEdit(false);
    }
  }, [form.mandateId]);

  useEffect(() => {
    if (selectedMandate) {
      setForm((previous) => ({ ...previous, ...selectedMandate }));
    }
  }, [selectedMandate]);

  useEffect(() => {
    if (requestMandate.loading === 'LOADED' && requestMandate.data) {
      setForm({
        ...form,
        mandateId: requestMandate.data.mandate.uuid,
        status: requestMandate.data.mandate.status,
        mandataire: requestMandate.data.mandate.mandataire,
      });
      if (onParentFormChange) {
        onParentFormChange(null, {
          status: requestMandate.data.mandate.status,
          mandateId: requestMandate.data.mandate.uuid,
          mandataire: requestMandate.data.mandate.mandataire,
        });
      }
      addMandate(requestMandate.data.mandate);
    }

    if (requestMandate.loading === 'LOADED' && form.mandateFile && !requestUpload.loading) {
      upload({
        data: formatUploadBody({ mandateId: requestMandate.data.mandate.uuid, mandateFile: form.mandateFile || null }),
      });
    }

    if (requestMandate.loading === 'ERROR') {
      setAllowEdit(true);
    }
  }, [requestMandate]);

  useEffect(() => {
    if (requestUpload.loading === 'LOADED' && requestUpload.data) {
      setForm({ ...form, mandateId: requestUpload.data.mandate.uuid, status: requestUpload.data.mandate.status });
      if (onParentFormChange) {
        onParentFormChange(null, {
          status: requestUpload.data.mandate.status,
          mandateId: requestUpload.data.mandate.uuid,
        });
      }
      addMandate(requestUpload.data.mandate);
    }
  }, [requestUpload]);

  /**
   * ACTIONS
   */
  const handleChange =
    (key: keyof MandateFormType) =>
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      setFormData(key, event.target.value);
      if (onParentFormChange) onParentFormChange(key, event.target.value);
      setValuesHasChanged(true);
    };

  /**
   * Handle the automatic selection of the mandate
   * @param {InputEvent} event Input event
   */
  const handleMandateChange = (mandateForm: MandateFormType) => {
    setForm({ ...mandateForm, signType: form.signType });
    if (onParentFormChange) onParentFormChange(null, mandateForm);
    setValuesHasChanged(true);
  };

  const handleSave = () => {
    const data = formatUpsertMandateBody(form);
    upsertMandate({ data });
    setAllowEdit(false);
  };

  return (
    <div className={styles['form-part']}>
      <div className="mt-4 mb-2 tt:uppercase">
        <span>{t('common.mandate')}</span> {form.status ? <RenderStatus status={form.status} /> : null}
      </div>

      {allowSelect ? <SelectMandate value={form.mandateId} onChange={handleMandateChange} /> : null}

      <div className={form.mandateId !== 'select_mandate' ? 'd-block' : 'd-none'}>
        <Row>
          <SignatoryNameInput value={form.signatoryName} onChange={handleChange('signatoryName')} disabled={disabled} />
          <SignatoryEmailInput
            value={form.signatoryEmail}
            onChange={handleChange('signatoryEmail')}
            disabled={disabled}
          />
          <SiretsInput value={form.sirets} onChange={handleChange('sirets')} disabled={disabled || toRenew} />

          <SignInput value={form.signType || ''} onChange={(value) => setFormData('signType', value ?? null)} />

          {form.signType === 'offline' ? (
            <UploadMandateInput
              form={form}
              setFormData={setFormData}
              setValuesHasChanged={setValuesHasChanged}
              disabled={disabled}
            />
          ) : null}
        </Row>

        {allowEdit && form.mandateId !== 'new_mandate' && (
          <Alert
            size="sm"
            variant="danger"
            label="admin.installations.siren_edit_warning"
            className="mb-4 text-small"
            full
          />
        )}

        <Row>
          <Col className="d-flex flex-row mb-3 justify-content-end align-items-center" md={12}>
            {(!form.sirets || !form.signatoryName || !form.signatoryEmail) && (
              <div style={{ flex: 1 }}>
                <Text size="sm" text="admin.installations.all_fields_required" type="danger" />
              </div>
            )}

            {requestMandate.error?.message && (
              <div style={{ flex: 1, overflow: 'hidden' }} className="mr-2">
                <Text size="sm" text={requestMandate.error?.message} type="danger" />
              </div>
            )}

            <div className="d-flex">
              {form.signType === 'offline' ? <GeneratePDFButton form={form} /> : null}

              {onClose && <Button variant="link" size="sm" text="common.cancel" onClick={onClose} className="mr-2" />}
              <SaveMandateBtn
                onSave={handleSave}
                setAllowEdit={setAllowEdit}
                allowEdit={allowEdit}
                loading={requestMandate.loading}
                disabled={disabled}
                toRenew={toRenew}
                requiredFields={requiredFields}
                valuesHasChanged={valuesHasChanged}
              />
            </div>
          </Col>

          <Col md={12}>
            {requestMandate.error?.type === 'IN-01' && (
              <p className="text-red">{t('admin.installations.errors.mandate_not_found')}</p>
            )}
            {requestMandate.error?.type === 'IN-02' && (
              <p className="text-red">{t('admin.installations.errors.mandate_expired')}</p>
            )}
            <RequestStatusMessage status={requestMandate.loading}>
              {t('admin.installations.correctly_saved')}
            </RequestStatusMessage>
            {form.signType === 'online' ? (
              <RequestStatusMessage status={requestMandate.loading}>
                {t('admin.installations.will_receive_mail')}
              </RequestStatusMessage>
            ) : null}
          </Col>

          <Col md={12}>
            <RequestStatusMessage status={requestUpload.loading}>
              {t('admin.installations.correctly_inserted')}
            </RequestStatusMessage>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FormMandates;
