import React, { Component, ReactNode } from 'react';
import { Alert } from '@GDM/Alert';
import { tString } from '@hooks/useTranslation';
import { Locale } from '@utils/types/common-types';

interface Props {
  children?: ReactNode;
  locale?: Locale;
}

interface State {
  hasError: boolean;
  errorMessage?: string;
  locale?: Locale;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    locale: this.props.locale,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error): void {
    this.setState((state) => ({ ...state, errorMessage: error.message }));
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="my-4">
          {this.state.errorMessage && (
            <Alert variant="danger" className="mb-3">
              {tString(this.state.locale)('errors.this_happened')} <code>{this.state.errorMessage}</code>
            </Alert>
          )}
          <br />
          <Alert variant="danger">
            {tString(this.state.locale)('errors.unexpected')}. {tString(this.state.locale)('errors.try_again_later')}
          </Alert>
        </div>
      );
    }

    return this.props.children || null;
  }
}
