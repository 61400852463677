import React from 'react';
import { Badge } from '@GDM/Badge';
import { TypeIcon } from '@utils/getTypeMeterIcons';
import { SearchbarOption } from 'pages/ui/Searchbar/SearchbarInput';
import { components, GroupBase, OptionProps } from 'react-select';

export const CustomOption: React.ComponentType<OptionProps<SearchbarOption, boolean, GroupBase<SearchbarOption>>> = (
  props,
) => {
  return (
    <components.Option {...props}>
      <div
        className={props.isDisabled ? '' : 'text-default'}
        style={{ fontWeight: 500, cursor: props.isDisabled ? 'not-allowed' : 'pointer' }}
      >
        {props?.data?.energy ? (
          <span style={{ width: '25px', display: 'inline-block' }}>
            <TypeIcon type={props.data.energy} />
          </span>
        ) : null}
        {props.data.label}
        {props.data?.status === 'draft' && <Badge className="ml-2" variant="danger" label="common.draft" />}
      </div>
    </components.Option>
  );
};
