import dayjs from 'dayjs';
import { PreDefinedPeriods } from '../date-picker.types';

const yesterday = dayjs().subtract(1, 'day');
const oneWeekAgo = dayjs().subtract(1, 'week');
const oneMonthAgo = dayjs().subtract(1, 'month');
const oneYearAgo = dayjs().subtract(1, 'year');

export const PERIOD_MAP: Map<PreDefinedPeriods, [Date, Date]> = new Map([
  [PreDefinedPeriods.Today, [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()]],
  [PreDefinedPeriods.Yesterday, [yesterday.startOf('day').toDate(), yesterday.endOf('day').toDate()]],
  [PreDefinedPeriods.LastWeek, [oneWeekAgo.startOf('week').toDate(), oneWeekAgo.endOf('week').toDate()]],
  [PreDefinedPeriods.CurrentWeek, [dayjs().startOf('week').toDate(), dayjs().endOf('week').toDate()]],
  [PreDefinedPeriods.LastMonth, [oneMonthAgo.startOf('month').toDate(), oneMonthAgo.endOf('month').toDate()]],
  [PreDefinedPeriods.CurrentMonth, [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]],
  [PreDefinedPeriods.LastYear, [oneYearAgo.startOf('year').toDate(), oneYearAgo.endOf('year').toDate()]],
  [PreDefinedPeriods.Last7Days, [dayjs().subtract(7, 'day').toDate(), new Date()]],
  [PreDefinedPeriods.Last30Days, [dayjs().subtract(30, 'day').toDate(), new Date()]],
]);
