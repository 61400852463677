import { isValidDate } from '@utils/isValidDate';
import { HedgeSubPeriodForm } from '@utils/types/contract';
import { v4 as uuid } from 'uuid';

export const makeHedgeBlock = ({ start_date, end_date }: Partial<HedgeSubPeriodForm>): HedgeSubPeriodForm | null => {
  if (!start_date || !end_date || ![start_date, end_date].every(isValidDate)) return null;

  return {
    id: uuid(),
    start_date,
    end_date,
    dates: {
      start_date: new Date(start_date),
      end_date: new Date(end_date),
    },
    price: null,
    average_power: null,
  };
};
