import React, { useCallback, useEffect, useState } from 'react';
import { subUsersContext } from '@context/admin/users/subusers.context';
import { useSubUsersWithAdditionalInfo } from '@hooks/requests/useSubUsersWithAdditionalInfo';
import { SubUserWithAdditionalInfo } from '@utils/types/user';

type Props = {
  toggle: () => void;
};

export const SubUsersProvider = ({ children, toggle }: React.PropsWithChildren<Props>) => {
  const [subUsers, setSubUsers] = useState<SubUserWithAdditionalInfo[]>([]);
  const { data, loaded, loading: isSubUsersLoading } = useSubUsersWithAdditionalInfo();

  const addSubUser = useCallback(
    (subUser: SubUserWithAdditionalInfo) => {
      setSubUsers((prevUsers) => [...prevUsers, subUser]);
      toggle();
    },
    [toggle],
  );

  const deleteSubUser = useCallback((subUserId: string) => {
    setSubUsers((prevUsers) => {
      const userIndex = prevUsers.findIndex((u) => u.uuid === subUserId);

      if (userIndex > -1) {
        return prevUsers.filter((_, idx) => idx !== userIndex);
      }

      return prevUsers;
    });
  }, []);

  const updateSubUser = useCallback(
    (subUser: SubUserWithAdditionalInfo) => {
      setSubUsers((prevUsers) => {
        const userIndex = prevUsers.findIndex((u) => u.uuid === subUser.uuid);

        if (userIndex > -1) {
          return prevUsers.map((u, idx) => (idx === userIndex ? { ...u, ...subUser } : u));
        }

        return prevUsers;
      });

      toggle();
    },
    [toggle],
  );

  useEffect(() => {
    if (loaded && data?.sub_users) setSubUsers(data?.sub_users);
  }, [data, loaded]);

  return (
    <subUsersContext.Provider
      value={{
        subUsersWithAdditionalInfo: subUsers,
        installations: data?.installations ?? [],
        books: data?.books ?? [],
        addSubUser,
        updateSubUser,
        deleteSubUser,
        isLoading: isSubUsersLoading,
      }}
    >
      {children}
    </subUsersContext.Provider>
  );
};
