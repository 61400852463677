import React, { FocusEvent, UIEvent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Aside } from './Aside';
import { useProgressStepper } from './context';
import { Nav } from './Nav';
import styles from './progress-stepper.module.scss';
import { Steps } from './Steps';

export const ProgressStepperContent = ({ className }: { className?: string }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { setInfoBox, infoBox, infoBoxDefinitions, showNav, containerMaxWidth } = useProgressStepper();

  /** Display help box if there is one. */
  const handleFocus = useCallback(
    (e: FocusEvent<HTMLInputElement, Element>) => {
      if (!infoBoxDefinitions) return;
      const fieldName = e.target.name || e.target.id;
      const infoBoxDefinition = infoBoxDefinitions[fieldName];
      const offsetTop = e.target.getBoundingClientRect().top + window.scrollY;

      if (infoBoxDefinition) setInfoBox?.({ ...infoBoxDefinition, offsetTop, initialScrollTop: scrollPosition });
      else setInfoBox?.(undefined);
    },
    [infoBoxDefinitions, scrollPosition, setInfoBox],
  );

  /** Hide the box if there's no help info to display. */
  const handleBlur = useCallback(() => {
    if (!infoBoxDefinitions) return;

    // Because the blur event is triggered before the next element's focus event, and because in this case
    // we want to know what the next focused element is, we use requestAnimationFrame, which makes sure the
    // browser is ready to trigger the repaint and allows us to use document.activeElement on the correct element
    requestAnimationFrame(() => {
      const fieldName = (document.activeElement as HTMLInputElement).name || document.activeElement?.id;
      const infoBoxDefinition = fieldName ? infoBoxDefinitions[fieldName] : null;

      if (!infoBoxDefinition && setInfoBox) setTimeout(setInfoBox, 100); // Leave the user enough time to interact with the tooltip (e.g links.)
    });
  }, [infoBoxDefinitions, setInfoBox]);

  /** Reposition info box on scroll if need be. */
  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const scrollTop = (e.target as HTMLElement).scrollTop;
      setScrollPosition(scrollTop);

      if (!infoBox) return;

      setInfoBox?.((infoBox) => {
        const newScrollTop = (infoBox?.offsetTop ?? 0) - (scrollTop - (infoBox?.initialScrollTop ?? 0));

        return { ...infoBox, initialScrollTop: scrollTop, offsetTop: newScrollTop };
      });
    },
    [infoBox, setInfoBox],
  );

  return (
    <div className={classNames(styles.wrapper, className)}>
      {showNav && (
        <div className={styles.nav}>
          <Nav />
          <Aside />
        </div>
      )}

      <div
        className={styles.main}
        style={containerMaxWidth ? { maxWidth: `${containerMaxWidth}rem` } : undefined}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onScroll={handleScroll}
      >
        <Steps />
      </div>
    </div>
  );
};
