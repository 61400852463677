import React from 'react';
import { AltContent } from '@GDM/AltContent';

type Props = { colSpan?: number; 'data-cy'?: string };

export const NoDataTBody = ({ colSpan, 'data-cy': dataCy }: Props) => (
  <tbody data-cy={dataCy}>
    <tr>
      <td colSpan={colSpan || 100} className="ta:center p-3">
        <AltContent />
      </td>
    </tr>
  </tbody>
);
