import React from 'react';
import { NumberCell } from '@GDM/Table';
import { Simulation } from '@utils/types/simulation';

export const MarketDeviationFactorCell = ({
  simulation,
  fullMerchant,
}: {
  simulation: Simulation;
  fullMerchant?: boolean;
}) => {
  const factor =
    (fullMerchant
      ? simulation.results?.local_capture_factors?.q50 || 0
      : simulation.results?.deviation_factors?.q50 || 0) * 100;

  if (!factor) return null;

  return (
    <NumberCell
      color={factor >= 0 ? 'green' : 'red'}
      value={factor}
      unit="percentage"
      numberOptions={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
    />
  );
};
