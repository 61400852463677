import React from 'react';
import { Icon } from '@GDM/Icon';
import { Point, divIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { Marker as LeafLetMarker, MarkerProps } from 'react-leaflet';
import styles from './map.module.scss';

export const Marker = ({
  color,
  children,
  position,
}: {
  color?: string;
} & MarkerProps) => {
  return (
    <LeafLetMarker
      position={position}
      icon={divIcon({
        className: '',
        html: renderToString(<Icon name="MapPin" size={28} color={color} fill={color ? '' : 'var(--bg)'} />),
        popupAnchor: new Point(0, -20),
        iconAnchor: new Point(14, 20),
      })}
    >
      <div className={styles.marker}>{children}</div>
    </LeafLetMarker>
  );
};
