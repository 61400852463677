import React from 'react';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import * as icons from 'react-feather'; // eslint-disable-line no-restricted-imports
import { COUNTRIES } from './flags';
import styles from './icon.module.scss';
import { IconNames, ValidFlags } from './icon.types';

export const Icon = ({
  className,
  name,
  title,
  ...props
}: {
  name: IconNames;
  title?: string;
} & icons.IconProps) => {
  const { t } = useTranslation();
  const classList = classNames(styles.icon, { rotate: name === 'Loader' }, className);

  if (Object.keys(COUNTRIES).includes(name))
    return (
      <img
        alt={`(${name})`}
        src={COUNTRIES[name as ValidFlags]}
        height={props.size}
        className={classList}
        title={t(title)}
      />
    );

  const IconComponent = icons[name as keyof Omit<typeof icons, 'Icon' | 'IconProps'>];

  if (name && !IconComponent) {
    // eslint-disable-next-line no-console
    console.warn(
      `The icon you selected is not available, are you sure you didn't mean '${
        name?.charAt(0)?.toUpperCase() + name?.slice(1)
      }'?`,
    );

    return null;
  }

  if (!IconComponent) return null;

  return (
    <Tooltip tooltip={title}>
      <IconComponent role="img" className={classList} {...props} />
    </Tooltip>
  );
};
