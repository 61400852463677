import React, { useState, useEffect, useMemo } from 'react';
import { fetchNegativePrices } from '@api/negativePrices';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import { useQuery } from '@tanstack/react-query';
import { formatSystemDate } from '@utils/formatters';
import { capitalizeFirstLetter } from '@utils/string';
import type { CountryCode } from '@utils/types/countries';
import type Installation from '@utils/types/installation';
import fileDownload from 'js-file-download';
import { useForm } from 'react-hook-form';
import { defaultDates } from './constants/default-values';
import type { Granularity } from './constants/granularities';
import { Header } from './Header';
import { HelpBox } from './HelpBox';
import { exportCsv } from './helpers/exportCsv';
import { getFormattedNegativeHours } from './helpers/getFormattedNegativeHours';
import { useFilterNegativePrices } from './helpers/useFilterNegativePrices';
import styles from './negative-prices.module.scss';
import type { NegativePricesFilters } from './negative-prices.types';
import { NegativePricesChartTab } from './NegativePricesChartTab';
import { NegativePricesTableTab } from './NegativePricesTableTab';

const NEGATIVE_PRICES_SHOW_HELP_LOCAL_STORAGE_KEY = 'dashboard_meter.negative_price.show_help';

export const NegativePrices = ({
  resourceName,
  resourceId,
  isBook = false,
  wrapChart = false,
  hasMultipleInstallations = false,
  hasFrenchInstallation = false,
  isSolar,
}: {
  resourceName?: string;
  resourceId?: string;
  isBook?: boolean;
  wrapChart?: boolean;
  hasMultipleInstallations?: boolean;
  hasFrenchInstallation?: boolean;
  isSolar: boolean;
}) => {
  const { locale } = useUser();
  const [showHelpBox, setShowHelpBox] = useState(
    localStorage.getItem(NEGATIVE_PRICES_SHOW_HELP_LOCAL_STORAGE_KEY) !== 'off',
  );

  const filtersForm = useForm<NegativePricesFilters>({
    values: {
      dates: [defaultDates['monthly'].startDate, defaultDates['monthly'].endDate],
      displayMode: 'chart',
      granularity: 'monthly',
      hideZeroProduction: false,
      energy: 'all',
      installations: [],
      books: [],
      countries: [],
      excludeNightHours: Boolean(isSolar),
      splitByInstallation: false,
      onlyNonOa: false,
    },
  });
  const { setValue, watch } = filtersForm;
  const filters = filtersForm.watch();
  const {
    dates: [startDate, endDate],
    displayMode,
    granularity,
    splitByInstallation,
  } = filters;

  const groupBy = splitByInstallation ? 'installation' : 'date';

  const name = resourceId;

  const negativePricesRequest = useNegativePrices(
    startDate,
    endDate,
    granularity,
    name,
    isBook,
    filters.excludeNightHours,
  );

  const filteredNegativePrices = useFilterNegativePrices(negativePricesRequest.data, filters);

  const negativeHours = useMemo(() => {
    return getFormattedNegativeHours(filteredNegativePrices, granularity, groupBy);
  }, [filteredNegativePrices, granularity, groupBy]);

  const installations = useMemo(() => {
    return (
      negativePricesRequest.data
        ?.map((price) => price.installation)
        ?.filter((installation): installation is Installation => Boolean(installation)) ?? []
    );
  }, [negativePricesRequest.data]);

  const allCountries = useMemo<Set<CountryCode>>(() => {
    return new Set(
      installations
        .map((installation) => installation.country)
        .filter((country): country is CountryCode => Boolean(country)),
    );
  }, [installations]);

  useEffect(() => {
    setValue('countries', [...allCountries]);
  }, [allCountries, setValue]);

  useEffect(() => {
    const subscription = watch(({ displayMode }, { name }) => {
      if (name === 'displayMode' && displayMode === 'chart') {
        setValue('hideZeroProduction', false);
      } else if (name === 'displayMode' && displayMode === 'table') {
        setValue('hideZeroProduction', true);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleDownload = () => {
    const data = exportCsv(negativeHours, filteredNegativePrices, filters, isBook || hasMultipleInstallations, locale);
    const [startDate, endDate] = filtersForm.getValues('dates');
    const formattedGranularity = capitalizeFirstLetter(granularity) + '_';
    const resource = resourceName ? `${resourceName}_` : '';
    const start = formatSystemDate(startDate) + '_';
    const end = formatSystemDate(endDate);
    const fileName = `Streem_Negative_Prices_${formattedGranularity}${resource}${start}${end}.csv`;

    fileDownload(data, fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  useEffect(() => {
    const subscription = watch(({ granularity, displayMode }, { name }) => {
      /**
       * Here we fallback the value of the granularity to 'monthly' if the displayMode is 'chart'
       * and the current granularity is 'hourly' because the chart only supports 'daily' & 'monthly' granularity
       */
      if (name !== 'displayMode' || displayMode !== 'chart' || granularity !== 'hourly') return;

      setValue('granularity', 'monthly');
      setValue('dates', [defaultDates['monthly'].startDate, defaultDates['monthly'].endDate]);
    });

    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  return (
    <>
      <Header
        form={filtersForm}
        installations={installations}
        isLoading={negativePricesRequest.isLoading}
        showBookFilter={hasMultipleInstallations}
        showEnergyFilter={isBook || hasMultipleInstallations}
        showOaFilter={hasFrenchInstallation && (isBook || hasMultipleInstallations)}
        isSolar={isSolar}
      />

      <div className="d-flex flex-row justify-content-end align-items-center mb-3">
        {!showHelpBox && (
          <Icon
            className={styles['show-info-button']}
            name="HelpCircle"
            title="common.show_help"
            size={16}
            onClick={() => {
              setShowHelpBox(true);
              localStorage.setItem(NEGATIVE_PRICES_SHOW_HELP_LOCAL_STORAGE_KEY, 'on');
            }}
          />
        )}
        <Button variant="primary-2" onClick={handleDownload} text="common.download_csv" size="xxs" icon="Download" />
      </div>

      {showHelpBox && (
        <HelpBox
          onDismiss={() => {
            setShowHelpBox(false);
            localStorage.setItem(NEGATIVE_PRICES_SHOW_HELP_LOCAL_STORAGE_KEY, 'off');
          }}
        />
      )}

      {negativePricesRequest.isError && <Text className="mb-3" text="errors.unknown_error" type="danger" />}

      {displayMode === 'chart' && (
        <NegativePricesChartTab
          isLoading={negativePricesRequest.isLoading}
          negativeHours={negativeHours}
          negativePrices={negativePricesRequest.data ?? []}
          granularity={granularity}
          startDate={startDate}
          endDate={endDate}
          wrap={wrapChart}
          showRealCovering={isBook}
        />
      )}

      {displayMode === 'table' && (
        <NegativePricesTableTab
          isLoading={negativePricesRequest.isLoading}
          negativeHours={negativeHours}
          prices={filteredNegativePrices}
          granularity={granularity}
          showInstallation={isBook || hasMultipleInstallations}
          groupBy={groupBy}
        />
      )}
    </>
  );
};

const useNegativePrices = (
  startDate: Date | null,
  endDate: Date | null,
  granularity: Granularity,
  name?: string,
  isBook?: boolean,
  excludeNightHours?: boolean,
) => {
  const request = useQuery({
    queryKey: ['installation_negative_price_hours', name, startDate, endDate, granularity, excludeNightHours],
    queryFn: () => fetchNegativePrices(startDate, endDate, granularity, name, isBook, excludeNightHours),
    enabled: Boolean(startDate) && Boolean(endDate) && Boolean(granularity),
    staleTime: 0,
  });

  return request;
};
