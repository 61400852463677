import React, { useState } from 'react';
import { Restricted } from '@components/Restricted';
import { AltContent } from '@GDM/AltContent';
import { Button } from '@GDM/Button';
import { Card } from '@GDM/Card';
import { Col, Row } from '@GDM/layout';
import { Spinner } from '@GDM/Spinner';
import { MonthlyMarketResultAggregatorPlayer } from '@hooks/m0s/useM0Aggreg';
import useTranslation from '@hooks/useTranslation';
import { MonthlyMarketResult, MonthlyMarketResultAggregator } from '@utils/types/m0';
import { M0AggregChart } from './M0AggregChart';
import { M0AggregFormModal } from './M0AggregFormModal';

type M0AggregProps = {
  data: MonthlyMarketResultAggregatorPlayer | null;
  base: MonthlyMarketResult[];
  type: 'solar' | 'wind';
  aggregatorsMarketResults: MonthlyMarketResultAggregator[] | null;
  loading: boolean;
};

export const M0Aggreg = ({ data, base, type, aggregatorsMarketResults, loading }: M0AggregProps) => {
  const { t } = useTranslation();
  const title =
    type === 'solar' ? t('sales_management.m0_aggregators_solar') : t('sales_management.m0_aggregators_wind');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggle = () => setIsModalOpen((s) => !s);

  const hasData = data ? Object.values(data)[0]?.data.length > 0 : false;

  return (
    <>
      <M0AggregFormModal
        isOpen={isModalOpen}
        toggle={toggle}
        solar={type === 'solar'}
        aggregatorsMarketResults={aggregatorsMarketResults}
      />
      <Row>
        <Col>
          <Card
            data-cy="m0-aggreg-card"
            title={
              <div className="d-flex align-items-center w-100">
                {title}
                <Restricted permissions={['display:edit_salesfocus']}>
                  <Button
                    size="xxs"
                    icon="Plus"
                    className="ml-3"
                    variant="primary-2"
                    onClick={toggle}
                    tooltip="sales_management.m0_aggregators_modal_title"
                    data-cy="edit-m0"
                  />
                </Restricted>
              </div>
            }
          >
            {loading && <Spinner className="mt-3" />}
            {!!hasData && !!data && !!base && !loading && (
              <div className="w-100">
                <M0AggregChart data={data} base={base} solar={type === 'solar'} />
              </div>
            )}
            {(!hasData || !base) && !loading && <AltContent />}
          </Card>
        </Col>
      </Row>
    </>
  );
};
