import React from 'react';
import { Button } from '@GDM/Button';
import { CellContext } from '@tanstack/react-table';
import { Subscription } from '@utils/types/subscription';
import { useSubscriptions } from '../context';

export const ActionsCell = ({ row: { original } }: React.PropsWithChildren<CellContext<Subscription, unknown>>) => {
  const { setSelectedSubscription } = useSubscriptions();

  const onView = () => {
    window.location.hash = original.uuid;
    setSelectedSubscription?.(original);
  };

  return (
    <div className="ta:right">
      <Button
        className="ml-2"
        href={`/internal/subscriptions/${original.uuid}/edit`}
        icon="Edit3"
        variant="primary-2"
        floating
      />
      <Button className="ml-2" onClick={onView} icon="Eye" variant="primary-2" floating />
    </div>
  );
};
