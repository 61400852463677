import React from 'react';
import { Badge } from '@GDM/Badge';
import useTranslation from '@hooks/useTranslation';
import { getClassificationValue } from '@pages/Contracts/Contract/Form/sections/ContractType/ContractType';
import type { ContractType } from '@utils/types/contract';

export const ClassificationCell = ({ contractType }: { contractType: ContractType }) => {
  const { t } = useTranslation();

  const classification = getClassificationValue(contractType);

  return <Badge label={t(`sales_management.${classification}`)} />;
};
