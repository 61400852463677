import { HedgeProfile, HedgeProfileCap } from '@utils/types/contract';

export const serializeProfileHedge = (
  profileFromModel: HedgeProfile | undefined,
  profileFromForm: HedgeProfile | undefined,
): HedgeProfile | undefined => {
  const nbOfModelPeriods = profileFromModel?.profile_hedge_caps_attributes.length;
  const nbOfFormPeriods = profileFromForm?.profile_hedge_caps_attributes.length;
  if (!nbOfModelPeriods) return profileFromForm;
  if (!nbOfFormPeriods) return;

  const profile_hedge_caps_attributes: HedgeProfileCap[] = [];
  let formProfile: undefined | (HedgeProfileCap & { _destroy?: '1' });
  let modelProfile: undefined | (HedgeProfileCap & { _destroy?: '1' });

  for (let index = 0; index < Math.max(nbOfFormPeriods, nbOfModelPeriods); index++) {
    formProfile = profileFromForm.profile_hedge_caps_attributes[index];
    modelProfile = profileFromModel.profile_hedge_caps_attributes[index];

    const currentProfile: HedgeProfileCap & { _destroy?: '1' } =
      modelProfile?.id && !formProfile
        ? { ...modelProfile, _destroy: '1' }
        : {
            id: modelProfile?.id,
            start_date: formProfile?.start_date,
            end_date: formProfile?.end_date,
            cap: formProfile?.cap || modelProfile?.cap,
          };

    profile_hedge_caps_attributes.push(currentProfile);
  }

  return {
    ...profileFromForm,
    profile_hedge_caps_attributes,
  };
};
