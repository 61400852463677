import React from 'react';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './light-definition-list.module.scss';

export const LightDefinition = ({
  className,
  label,
  value,
}: {
  className?: string;
  label: string;
  value: string | number | React.ReactNode;
}) => {
  const { t } = useTranslation();
  const definitionValue = typeof value === 'string' ? t(value) : value;

  return (
    <div className={classNames(styles['light-definition'], className)}>
      <dt>{t(label)}</dt>
      <dd>{definitionValue}</dd>
    </div>
  );
};
