import dayjs from 'dayjs';
import { RequestCachedItem } from './requests.types';
import { searchParamsStringToObject } from './search-params-string-to-object';

/**
 * Stores requests in a runtime object. The top-level keys are url strings. Sub-items are strings
 * representing the params sent to each url. _e.g_:
 * ```txt
 * '/v2/some-url-1/':
 * ├─ '{ param1: value; param2: value }':
 * |  ├─ ['values', 'values', ...]
 * ├─ '{ param1: value; param2: value }':
 *    ├─ ['values', 'values', ...]
 *
 * ```
 *
 * @prop `cacheInvalidationTimeout` manually sets the cache invalidation timeout. Defaults to 1 hour.
 */
export class RequestCache {
  constructor(public cacheInvalidationTimeout?: number) {}

  private CACHE: Record<string, Record<string, RequestCachedItem>> = {};

  private get expiryDate(): Date {
    return this.cacheInvalidationTimeout
      ? dayjs().add(this.cacheInvalidationTimeout, 'millisecond').toDate()
      : dayjs().add(1, 'hour').toDate();
  }

  /**
   * Gets the url key (top-level) and request key for a specific request.
   * The param object is stringified and the letters are sorted so that two param objects
   * with the same items but in a different order have the same key.
   * @returns `{ requestKey, urlKey }` where `requestKey` is the individual request key and `urlKey` is the top-level one.
   */
  private getKeys(url: string, params?: Record<string, string>): { requestKey: string; urlKey: string } {
    const urlKey = params ? url : url.split('?')[0];
    const requestKey = JSON.stringify(params || searchParamsStringToObject(url.split('?')[1]))
      .replace(/[:,'"{}-]/g, '')
      .split('')
      .sort()
      .join('')
      .trim();

    return { requestKey, urlKey };
  }

  /**
   * Looks for a previously stored request. If the url has search params, it will split it and use them as a key.
   * @returns the request item or `null` if it finds nothing.
   */
  public get(options: { url: string; params?: Record<string, string> }): RequestCachedItem['data'] | null {
    const { requestKey, urlKey } = this.getKeys(options.url, options.params);
    const item = this.CACHE[urlKey];

    return item && item[requestKey] && item[requestKey].expires > new Date() ? item[requestKey].data : null;
  }

  /**
   * @returns an item's expiry date. Used for testing purposes.
   */
  public getItemExpiryDate(options: { url: string; params?: Record<string, string> }): Date | null {
    const { requestKey, urlKey } = this.getKeys(options.url, options.params);
    const item = this.CACHE[urlKey];

    if (!item || !item[requestKey]) return null;

    return item[requestKey].expires;
  }

  /**
   * Adds a request result to the cache. If the url has search params, it will split it and use them as a key.
   * If the same request has already been stored, it will be overwritten with the value provided here.
   */
  public set(options: { url: string; params?: Record<string, string>; data: RequestCachedItem['data'] }): void {
    const { requestKey, urlKey } = this.getKeys(options.url, options.params);
    const entry = { data: options.data, expires: this.expiryDate };

    if (!this.CACHE[urlKey]) {
      this.CACHE[urlKey] = { [requestKey]: entry };
    } else {
      this.CACHE[urlKey][requestKey] = entry;
    }
  }
}
