import { useMutation } from '@tanstack/react-query';
import fetchWrapper, { type ReqError } from '@utils/fetch';
import { v2_contract_path, v2_contracts_path } from '@utils/routes';
import type { Contract } from '@utils/types/contract';
import { ContractFormBody } from '@utils/types/contract/form';

export function useSubmitMutation(contractUuid: string | undefined) {
  const createMutation = useMutation<Contract, ReqError, ContractFormBody>({ mutationFn: createContract });

  const updateMutation = useMutation<Contract, ReqError, ContractFormBody>({ mutationFn: updateContract });

  return contractUuid ? updateMutation : createMutation;
}

const createContract = async (contract: ContractFormBody): Promise<Contract> => {
  const req = await fetchWrapper(v2_contracts_path(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ contract }),
  });

  let data;

  try {
    data = await req.json();
  } catch (error) {
    throw {
      code: 'errors.unknown_error',
      message: 'errors.unknown_error',
      errors: [],
    } satisfies ReqError;
  }

  if (!req.ok) {
    throw { code: data.code, message: data.message, errors: data.errors } satisfies ReqError;
  }

  redirectToContracts();

  return data;
};

const updateContract = async (contract: ContractFormBody): Promise<Contract> => {
  const req = await fetchWrapper(v2_contract_path(contract.id), {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ contract }),
  });

  const data = await req.json();

  if (!req.ok && data.message) {
    throw { code: data.code, message: data.message, errors: data.errors } satisfies ReqError;
  }

  if (!req.ok) {
    throw {
      code: 'errors.unknown_error',
      message: 'errors.unknown_error',
      errors: [],
    } satisfies ReqError;
  }

  redirectToContracts();

  return data;
};

const redirectToContracts = () => setTimeout(() => (window.location.href = '/v2/contracts'), 1500);
