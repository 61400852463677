import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const getOptions = (contracts: Contract[]) => {
  const installations = Array.from(new Set(contracts.map((contract) => contract.installation.name)));

  const options: Option<string>[] = installations
    .map((installation) => ({ label: installation, value: installation }))
    .sort(sortOptionsByLabelAsc);

  return options;
};

export const InstallationFilter = () => {
  const { t } = useTranslation();
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();

  const options: Option<string>[] = useDynamicOptions(getOptions, 'installation_name', contracts, allContracts);

  return (
    <Controller
      name="installation_name"
      control={form.control}
      render={({ field }) => (
        <Select
          isMulti
          placeholder={t('common.filters.installation_name')}
          options={options}
          isSearchable
          selectedOptions={field.value}
          inline
          classNamePrefix="contracts-filter-installation_name"
          onChange={(values) => {
            const internalValues = values?.map((v) => v.value) ?? [];

            filterContracts({
              ...form.getValues(),
              installation_name: internalValues,
              last_selected_filter: 'installation_name',
            });
            field.onChange(internalValues);
            form.setValue('last_selected_filter', internalValues.length > 0 ? 'installation_name' : null);
          }}
        />
      )}
    />
  );
};
