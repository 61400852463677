import React from 'react';
import classNames from 'classnames';
import { Col as ReactStrapCol, ColProps } from 'reactstrap'; // eslint-disable-line no-restricted-imports
import styles from './col.module.scss';

export const Col = ({
  className,
  shrink,
  noWrap,
  ...props
}: {
  className?: string;
  shrink?: boolean;
  noWrap?: boolean;
} & ColProps) => {
  const classList = classNames(className, styles.col, shrink && styles['shrink'], noWrap && styles['no-wrap']);

  return (
    <ReactStrapCol {...props} className={classList}>
      {props.children}
    </ReactStrapCol>
  );
};
