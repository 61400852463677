import React from 'react';
import { Textarea } from '@GDM/forms/Textarea';
import { Controller } from 'react-hook-form';
import styles from '../subscription-form.module.scss';
import { useSubscriptionForm } from '../useSubscriptionForm';

export const ExtraInfo = () => {
  const { control, readonly } = useSubscriptionForm();

  return (
    <div className={styles.section}>
      <Controller
        control={control}
        name="additional_info"
        render={({ field, fieldState }) => (
          <Textarea
            {...field}
            value={field.value ?? ''}
            size="lg"
            hasError={!!fieldState.error}
            readOnly={readonly}
            full
          />
        )}
      />
    </div>
  );
};
