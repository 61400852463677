import React from 'react';
import { useUser } from '@context/User.context';
import { Colon } from '@GDM/Colon';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { Owner } from '@utils/types/market_player';
import { parseInvoiceFormat } from './parseInvoiceFormat';

export const AutoNumberingCell = ({ row: { original } }: CellContext<Owner, unknown>) => {
  const { t } = useTranslation();
  const { locale } = useUser();

  return (
    <>
      {original.initial_invoice_number && (
        <div>
          {t('owner.initial_invoice_number')}
          <Colon /> <span className="fw:400">{original.initial_invoice_number}</span>
        </div>
      )}
      {original.invoice_numbering_date_ref && (
        <div>
          {t('owner.invoice_date_ref')}
          <Colon /> <span className="fw:400">{original.invoice_numbering_date_ref}</span>
        </div>
      )}
      {original.invoice_numbering_format && (
        <div>
          {t('owner.invoice_numbering_format')}
          <Colon /> <span className="fw:400">{parseInvoiceFormat(original.invoice_numbering_format, locale)}</span>
        </div>
      )}
    </>
  );
};
