import React from 'react';
import { ModalBody as ModalBodyLegacy, ModalBodyProps } from 'reactstrap';
import styles from './modal.module.scss';

export const ModalBody = ({ children, ...props }: ModalBodyProps) => {
  return (
    <ModalBodyLegacy className={styles.body} {...props}>
      {children}
    </ModalBodyLegacy>
  );
};
