import React, { useMemo, useState } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Contract, ContractInstallation, ContractSubPeriod } from '@utils/types/contract';
import { MarketFuture } from '@utils/types/market_future';
import { TypeCell } from '../Table/Cells';

type TableData = ContractSubPeriod & {
  contract: {
    contract_nb: string;
    contract_type: string;
  };
  display_price: number | null;
  installation: ContractInstallation;
};

type Props = {
  contracts: Contract[];
  newPrice: string;
  newMarketFuture: MarketFuture | null;
};

export const MassEditTable = ({ contracts, newPrice, newMarketFuture }: Props) => {
  const data = useMemo<TableData[]>(() => {
    return contracts.flatMap((contract) => {
      const { contract_nb, type: contract_type, installation } = contract;

      // filter sub periods within newMarketFuture dates

      return contract.contract_sub_periods
        .filter((csp) => {
          /**
           * If you select a market future, it will only show the sub-periods of the selected market future.
           * Otherwise, it will show all sub-periods.
           */
          if (!newMarketFuture || newMarketFuture.id === csp.market_future_id) {
            return true;
          }

          return false;
        })
        .map((csp) => ({
          ...csp,
          contract: { contract_nb, contract_type },
          installation,
        }));
    });
  }, [contracts, newMarketFuture]);

  const columns = useMemo<ColumnDef<TableData>[]>(() => {
    const cols: ColumnDef<TableData>[] = [
      {
        id: 'contract_type',
        header: 'common.contract',
        cell: ({ row: { original } }) => {
          const contract = original.contract;

          return <TypeCell contract={contract} />;
        },
      },
      {
        header: 'common.installation',
        cell: ({ row: { original } }) => {
          return (
            <MeterNameCell
              hasMeter={original.installation.has_meter}
              installation_name={original.installation.name}
              installation_energy={original.installation.energy}
            />
          );
        },
      },
      {
        header: 'sales_management.current_price',
        cell: ({ row: { original } }) => (
          <p className="text-red">
            <b>{original.display_price}</b>
          </p>
        ),
      },
      {
        header: 'sales_management.new_price',
        cell: () => (
          <p className="text-green">
            <b>{newPrice}</b>
          </p>
        ),
      },
      { header: 'common.start_date', accessorKey: 'start_date' },
      { header: 'common.end_date', accessorKey: 'end_date' },
    ];

    return cols;
  }, [newPrice]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const table = useReactTable({
    columns,
    data,
    state: { pagination },
    initialState: { sorting: [{ id: 'start_date', desc: true }] },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const pageSize = pagination.pageSize;

  return (
    <div className="mt-3">
      <Table>
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      <TableActions className="p-0">
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={contracts.length} />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </div>
  );
};
