import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { useNumbers } from '@hooks/useNumbers';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { CoordinatesModal } from './CoordinatesModal';

export const CoordinatesDefinition = ({ meter }: { meter: Meter }) => {
  const { lat, lng } = useMeterInfo();
  const { format } = useNumbers(1);

  return (
    <Definition
      label="common.coordinates"
      value={lat && lng ? `${format(+lat)}°, ${format(+lng)}°` : '-'}
      tooltip="common.lat_lng"
      data-cy="coordinates-definition"
      modal={{
        node: <CoordinatesModal meter={meter} />,
        title: 'common.coordinates',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
