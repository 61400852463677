import React from 'react';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import type { Timezone } from '@utils/types/countries';
import { InverterData } from '@utils/types/inverterData';
import { getInverterSeriesFromDate } from '../utils/getInverterSeriesFromDate';

export const InvertersChart = ({ inverterData, timezone }: { inverterData: InverterData; timezone?: Timezone }) => {
  const { t } = useTranslation();
  const series = getInverterSeriesFromDate(inverterData);

  const options: Highcharts.Options = {
    title: { text: t('common.inverters') },
    xAxis: {
      type: 'datetime',
      gridLineWidth: 1,
      dateTimeLabelFormats: {
        second: '%H:%M',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e %b',
      },
    },
    yAxis: { title: { text: 'kW' } },
  };

  return <Chart series={series} options={options} data-cy="inverter-chart" timezone={timezone} hideLegend />;
};
