import { useCallback, useState } from 'react';
import axiosInstance from '@utils/axiosInstance';
import { mass_update_v2_contracts_path } from '@utils/routes';
import { Contract } from '@utils/types/contract';
import { AxiosResponse } from 'axios';

export type BodyType = {
  contract_ids: string[];
  contract_sub_period_ids: string[];
  display_price?: number;
};

const fetch = (body: BodyType) => {
  return axiosInstance(false)
    .post<BodyType, AxiosResponse<Contract[]>>(mass_update_v2_contracts_path(), body, {
      headers: { accept: 'application/json' },
    })
    .then((d) => d.data);
};

const useMassEditContracts = () => {
  const [contracts, setContracts] = useState<Contract[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const update = useCallback((body: BodyType) => {
    setLoading(true);
    setError(null);
    setFinished(false);

    fetch(body)
      .then((d) => {
        setLoading(false);
        setFinished(true);
        setContracts(d);
      })
      .catch((e) => {
        setLoading(false);
        setError(e?.response?.data?.errors?.[0]?.code || e?.message);
      });
  }, []);

  return { contracts, update, loading, error, finished };
};

export default useMassEditContracts;
