import axios, { AxiosInstance } from 'axios';

const axiosInstance = (isV2 = true): AxiosInstance => {
  const CSRF_TOKEN = document.querySelector<HTMLMetaElement>('meta[name=csrf-token]')?.content || '';
  const BASE_URL = `${window.location.origin}${isV2 ? '/v2' : ''}`; // TODO: store in a env variable

  return axios.create({ baseURL: BASE_URL, headers: { 'X-CSRF-TOKEN': CSRF_TOKEN, REACT: 'true' } });
};

export default axiosInstance;
