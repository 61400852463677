import { useCallback, useContext } from 'react';
import { ProgressStepperContextType } from '../progress-stepper';
import { progressStepperContext } from './progress-stepper.context';

export const useProgressStepper = (): Omit<ProgressStepperContextType, 'setSteps'> & {
  markAsValid: (key: string, value?: boolean) => void;
  updateProgress: (key: string, value: number) => void;
} => {
  const context = useContext(progressStepperContext);
  const { setSteps } = context;

  const markAsValid = useCallback(
    (key: string, value?: boolean) => {
      setSteps?.((steps) => {
        if (!steps?.[key]) return steps;

        return {
          ...steps,
          [key]: {
            ...steps[key],
            isValid: value !== undefined && value !== null ? value : true,
          },
        };
      });
    },
    [setSteps],
  );

  const updateProgress = useCallback(
    (key: string, value: number) => {
      const progress = value > 100 ? 100 : value < 0 ? 0 : value;

      setSteps?.((steps) => {
        if (!steps?.[key]) return steps;

        return {
          ...steps,
          [key]: {
            ...steps[key],
            progress,
          },
        };
      });
    },
    [setSteps],
  );

  return {
    ...context,
    markAsValid,
    updateProgress,
  };
};
