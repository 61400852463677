import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useSelectedYear } from '@pages/Simulations/Simulation/utils/selectedYear.context';
import { ConfidenceValue } from '../ConfidenceValue';
import styles from './full-merchant-definition.module.scss';

export const NationalCapturePriceDefinition = () => {
  const project = useProjectContext();
  const { selectedYear } = useSelectedYear();
  const { forward_prices } = project?.full_merchant || {};
  const { full_merchant_cannibalized_distribution_cat, national_vs_baseload } = project?.full_merchant || {};

  if (!selectedYear) return null;

  const yearIndex = full_merchant_cannibalized_distribution_cat?.[selectedYear as unknown as number];
  const forwardPrice = forward_prices?.[yearIndex as unknown as number];
  const nationalVsBaseloadYear = national_vs_baseload?.[yearIndex as unknown as number];
  const capturePrice = (forwardPrice || 0) + (nationalVsBaseloadYear?.q50 || 0);
  const variant = nationalVsBaseloadYear?.q50 && nationalVsBaseloadYear.q50 > 0 ? 'primary-1' : 'secondary';

  return (
    <Definition
      label="pricing.project.national_capture_price"
      value={
        <div className={styles['multiline-definition-value']}>
          <ValueWithUnit value={capturePrice} unit="EUR/MWh" />
          <div className={styles['definition-range']}>
            <ValueWithUnit valueVariant={variant} value={nationalVsBaseloadYear?.q50 || null} unit="EUR/MWh" />
            <ConfidenceValue
              className="ml-2"
              values={[{ value: nationalVsBaseloadYear?.q25 || null }, { value: nationalVsBaseloadYear?.q75 || null }]}
              append="pricing.project.vs_baseload"
            />
          </div>
        </div>
      }
    />
  );
};
