import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { ErpCodeModal } from './ErpCodeModal';

export const ErpCodeDefinition = ({ meter }: { meter: Meter }) => {
  const { erpCode } = useMeterInfo();

  return (
    <Definition
      label="sales_management.erp_code"
      value={erpCode || 'common.none'}
      modal={{
        node: <ErpCodeModal meter={meter} />,
        title: 'sales_management.erp_code',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
