import { UserCtxType } from '@context/User.context';
import { tString } from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import jsonToCsv, { HeadCsv } from '@utils/json-to-csv';
import { Locale } from '@utils/types/common-types';
import { Currency } from '@utils/types/currency';
import { M0Row } from '@utils/types/m0';

const transformNumber = (value: number, locale: Locale) => {
  const options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  return Intl.NumberFormat(locale, options).format(value).replace(/\s/g, '');
};

export const csvExport = (m0Rows: M0Row[], currency: Currency, user: UserCtxType): string => {
  const locale = user.locale;
  const t = tString(locale);

  const priceUnit = `(${getSymbol(currency)}/MWh)`;
  const powerUnit = '(kW)';
  const productionUnit = '(kWh)';

  const headers: HeadCsv<M0Row>[] = [
    {
      key: 'installation_name',
      label: 'common.installation',
    },
    {
      key: 'installation_external_ref',
      label: 'common.client_id',
    },
    {
      key: 'installation_p_max',
      label: `${t('common.power')} ${powerUnit}`,
      transform: (value) => transformNumber(value as number, locale),
    },
    {
      key: 'date',
      label: 'common.date',
    },
    {
      key: 'production',
      label: `${t('common.production')} ${productionUnit}`,
      transform: (value) => transformNumber(value as number, locale),
    },
    {
      key: 'm0',
      label: `${t('pricing.market_value')} ${priceUnit}`,
      transform: (value) => transformNumber(value as number, locale),
    },
    {
      key: 'capture_price',
      label: `${t('monitoring.installation.capture_price')} ${priceUnit}`,
      transform: (value) => transformNumber(value as number, locale),
    },
    {
      key: 'm0_deviation',
      label: `${t('monitoring.installation.m0_deviation')} ${priceUnit}`,
      transform: (value) => transformNumber(value as number, locale),
    },
    {
      key: 'm0_deviation_no_prod_neg_price',
      label: `${t('monitoring.installation.m0_deviation_no_prod_neg_price')} ${priceUnit}`,
      transform: (value) => transformNumber(value as number, locale),
    },
  ];

  return jsonToCsv(m0Rows, headers, { delimiter: ';', locale, keepAccents: true });
};
