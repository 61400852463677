import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Input } from '@GDM/forms';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { formatDisplayDate } from '@utils/formatters';
import { MeterInvoice } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import { Controller, UseFormReturn } from 'react-hook-form';
import styles from './swap-meter-modal.module.scss';
import { useSwapMeterModal } from './swapMeterModal.context';
import type { SwapMeterForm as SwapMeterFormType } from './swapMeterModal.types';

export const SwapMeterForm = ({ invoice, form }: { invoice: MeterInvoice; form: UseFormReturn<SwapMeterFormType> }) => {
  const { t } = useTranslation();
  const { error } = useSwapMeterModal();

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div>
        <Text
          text={t('sales_management.swap_meter_modal.meter_switch_description', {
            meterName: invoice?.installation_name,
            startDate: invoice && formatDisplayDate(invoice.start_date),
            endDate: invoice && formatDisplayDate(invoice.end_date),
          })}
          multiline
        />
      </div>

      <div className="mt-3">
        <Controller
          name="change_date"
          control={form.control}
          rules={{
            required: true,
            validate: (value) => {
              if (
                dayjs(value).endOf('d').isBefore(invoice.start_date) ||
                dayjs(value).startOf('d').isAfter(invoice.end_date)
              ) {
                return 'sales_management.swap_meter_modal.meter_switch_change_date_error';
              }
            },
          }}
          render={({ field, fieldState }) => (
            <DatePicker
              label="sales_management.swap_meter_modal.date_change"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              errorMessage={fieldState.error?.message}
              hasError={!!fieldState.error}
            />
          )}
        />
      </div>

      <div className={styles.row}>
        <Controller
          name="last_production_index"
          control={form.control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              full
              type="number"
              label="sales_management.swap_meter_modal.last_production_index"
              errorMessage={fieldState.error?.message}
              hasError={!!fieldState.error}
              suffix="kWh"
              {...field}
            />
          )}
        />

        <Controller
          name="first_production_index"
          control={form.control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              full
              type="number"
              label="sales_management.swap_meter_modal.first_production_index"
              errorMessage={fieldState.error?.message}
              hasError={!!fieldState.error}
              suffix="kWh"
              {...field}
            />
          )}
        />
      </div>

      <div className={styles.row}>
        <Controller
          name="last_consumption_index"
          control={form.control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              full
              type="number"
              label="sales_management.swap_meter_modal.last_consumption_index"
              errorMessage={fieldState.error?.message}
              hasError={!!fieldState.error}
              suffix="kWh"
              {...field}
            />
          )}
        />

        <Controller
          name="first_consumption_index"
          control={form.control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              full
              type="number"
              label="sales_management.swap_meter_modal.first_consumption_index"
              errorMessage={fieldState.error?.message}
              hasError={!!fieldState.error}
              suffix="kWh"
              {...field}
            />
          )}
        />
      </div>

      {error && (
        <div className="mt-3">
          <Text text={error.code} type="danger" />
        </div>
      )}
    </form>
  );
};
