import React, { useMemo } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell/MeterNameCell';
import { Table, TableBody, TableHead, NumberCell } from '@GDM/Table';
import { TableFooter } from '@GDM/Table/TableFooter';
import useTranslation from '@hooks/useTranslation';
import {
  ColumnDef,
  getCoreRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { EnergyType, ENERGY_TYPES } from '@utils/types/common-types';
import { BookInstallation, EnergyTypeSummaryTableRow } from '../characteristics.types';

export const EnergyTypeSummaryTable = ({ installations }: { installations: BookInstallation[] }) => {
  const { t } = useTranslation();

  const energyMix = useMemo<EnergyTypeSummaryTableRow[]>(() => {
    const initialMix: Record<EnergyType, { nbOfInstallations: number; power: number }> = {
      wind: { nbOfInstallations: 0, power: 0 },
      solar: { nbOfInstallations: 0, power: 0 },
      hydro: { nbOfInstallations: 0, power: 0 },
      book: { nbOfInstallations: 0, power: 0 },
      other: { nbOfInstallations: 0, power: 0 },
    };

    const mixObject = installations.reduce<Record<EnergyType, { nbOfInstallations: number; power: number }>>(
      (currentMix, { energy, p_max }) => {
        return {
          ...currentMix,
          [energy]: {
            nbOfInstallations: currentMix[energy].nbOfInstallations + 1,
            power: Number(currentMix[energy].power) + Number(p_max),
          },
        };
      },
      initialMix,
    );

    return ENERGY_TYPES.map((energy) => ({
      energy,
      nbOfInstallations: mixObject[energy].nbOfInstallations,
      power: mixObject[energy].power,
    })).filter(({ nbOfInstallations }) => nbOfInstallations > 0);
  }, [installations]);

  const totalPower = useMemo(
    () => energyMix.reduce((sum, installation) => sum + (installation.power || 0), 0),
    [energyMix],
  );

  const totalNumberOfInstallations = useMemo(
    () => energyMix.reduce((sum, installation) => sum + (installation.nbOfInstallations || 0), 0),
    [energyMix],
  );

  const columns = useMemo<ColumnDef<EnergyTypeSummaryTableRow>[]>(
    () =>
      [
        {
          header: 'common.energy_type',
          accessorKey: 'energy',
          cell: ({ row: { original } }) => (
            <MeterNameCell installation_name={t(`energy.${original.energy}`)} installation_energy={original.energy} />
          ),
          footer: 'common.total',
        },
        {
          header: 'common.power_rating',
          accessorKey: 'power',
          cell: ({ row: { original } }) => <NumberCell value={original.power} unit="kW" />,
          footer: () => <NumberCell value={totalPower} unit="kW" />,
        },
        {
          header: 'pricing.project.n_installations',
          accessorKey: 'nbOfInstallations',
          footer: () => <NumberCell value={totalNumberOfInstallations} />,
          cell: ({ row: { original } }) => <NumberCell value={original.nbOfInstallations} />,
        },
      ] satisfies ColumnDef<EnergyTypeSummaryTableRow>[],
    [t, totalNumberOfInstallations, totalPower],
  );

  const table = useReactTable({
    columns,
    data: energyMix,
    initialState: { sorting: [{ id: 'nbOfInstallations', desc: true }] },
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table>
      <TableHead table={table} />
      <TableBody table={table} />
      <TableFooter table={table} />
    </Table>
  );
};
