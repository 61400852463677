import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import { iconDictionary } from '@utils/constants';
import type { EnergyType, Option } from '@utils/types/common-types';
import type Installation from '@utils/types/installation';
import classNames from 'classnames';
import styles from './option-energy.module.scss';

export const FormattedOptionEnergy = (
  data: Option & { energy?: EnergyType; status?: Installation['status'] },
): JSX.Element => {
  return (
    <div className="d-flex align-items-center" title={data.label}>
      <Icon name={iconDictionary[data.energy || 'other']} size={14} />
      <span className={classNames(styles.label, 'ml-2')}>{data.label}</span>
      {data.status === 'draft' && <Badge className="ml-2" label="common.draft" variant="danger" />}
    </div>
  );
};
