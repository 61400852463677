import React, { useCallback, useEffect } from 'react';
import { getOwners } from '@api/owners';
import { Spinner } from '@GDM/Spinner';
import { useQuery } from '@tanstack/react-query';
import { Owner } from '@utils/types/market_player';
import { OwnersContextType } from './owner.types';
import { ownersContext } from './owners.context';

export const OwnerProvider = ({ children }: React.PropsWithChildren) => {
  const query = useQuery<Owner[]>({
    queryKey: ['owners'],
    queryFn: () => getOwners(),
  });

  const [owners, setOwners] = React.useState<Owner[]>([]);
  const [allOwners, setAllOwners] = React.useState<Owner[]>([]);

  useEffect(() => {
    if (query.data) {
      setOwners(query.data);
      setAllOwners(query.data);
    }
  }, [query.data]);

  const updateOwner = useCallback((owner: Owner) => {
    setOwners((prev) => {
      const newOwners = prev.map((o) => (o.id === owner.id ? owner : o));

      return newOwners;
    });
  }, []);

  const contextValue = React.useMemo<OwnersContextType>(
    () => ({ owners, updateOwner, setOwners, allOwners }),
    [owners, updateOwner, setOwners, allOwners],
  );

  if (query.isPending) {
    return (
      <div className="ta:center my-3">
        <Spinner />
      </div>
    );
  }

  return <ownersContext.Provider value={contextValue}>{children}</ownersContext.Provider>;
};
