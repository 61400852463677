import React from 'react';
import { Card } from '@GDM/Card';
import { specialUnitsMap } from '@GDM/ValueWithUnit/config';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { ConfidenceValue, ConfidenceTuple } from '../ConfidenceValue';
import styles from './cards.module.scss';

export const ConfidenceInterval50YearCard = () => {
  const project = useProjectContext();

  const { q25_dev, q75_dev, q50_factor, national_q50_factor } = project?.full_merchant?.full_merchant_result || {};
  const { full_merchant_cannibalized_distribution_cat } = project?.full_merchant || {};
  const { t } = useTranslation();
  const year = full_merchant_cannibalized_distribution_cat?.[1];
  const variant =
    q50_factor && national_q50_factor ? (q50_factor >= national_q50_factor ? 'primary-1' : 'secondary') : null;

  const values: ConfidenceTuple = [
    { value: q25_dev || null, variant },
    { value: q75_dev || null, variant },
  ];

  return (
    <Card
      title={t('pricing.project.full_merchant_confidence_interval', { year })}
      className={styles.card}
      body={
        <div className={styles.value}>
          <ConfidenceValue values={values} append={specialUnitsMap.get('EUR/MWh')?.default} />
        </div>
      }
    />
  );
};
