import React from 'react';
import { Colon } from '@GDM/Colon';
import { DateCell } from '@GDM/Table';
import { AcceptableUnits, ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './widgets.module.scss';

export const LabeledValue = ({
  label,
  value,
  unit,
  date,
}: {
  label: string;
  value: number;
  unit: AcceptableUnits;
  date?: string | number | Date;
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {t(label)}
      <Colon />
      &nbsp;
      <ValueWithUnit value={value} unit={unit} />
      {date && (
        <span className={classNames(styles['hit-date'], 'ml-2', 'text-small')}>
          (<DateCell value={date} />)
        </span>
      )}
    </div>
  );
};
