import React from 'react';
import { Button } from '@GDM/Button';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { LabelInput } from './fields/LabelInput';
import { QuantityInput } from './fields/QuantityInput';
import { RevenueTypePicker } from './fields/RevenueTypePicker';
import { TotalInput } from './fields/TotalInput';
import { UnitPriceInput } from './fields/UnitPriceInput';
import styles from './update-modal.module.scss';

export const Revenue = ({
  index,
  currency,
  onDelete,
}: {
  index: number;
  currency: Currency;
  onDelete: (index: number) => void;
}) => {
  return (
    <div className={styles.revenues}>
      <LabelInput index={index} />
      <RevenueTypePicker index={index} />
      <QuantityInput index={index} />
      <UnitPriceInput index={index} currency={currency} />
      <TotalInput index={index} currency={currency} />

      <div className={classNames('d-flex', styles['delete-button'])}>
        <Button
          variant="secondary"
          floating
          onClick={() => onDelete(index)}
          icon="Trash2"
          size="xs"
          className="ml-auto"
        />
      </div>
    </div>
  );
};
