import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { FilterContainer, Filters } from '@GDM/Filters';
import { Input, RadioButtons } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { InvoicingType } from '@utils/types/meter-invoice';
import { MonitoringInvoicesFilters as MonitoringInvoicesFiltersType, TabId } from './invoices.types';

export const MonitoringInvoicesFilters = ({
  filters,
  setFilters,
  type,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: {
  filters: MonitoringInvoicesFiltersType;
  setFilters: (filters: MonitoringInvoicesFiltersType) => void;
  setStartDate: (date: Date | null) => void;
  startDate: Date | null;
  endDate?: Date | null;
  setEndDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  type: TabId;
}) => {
  const radioOptions: Option<InvoicingType>[] = [{ value: 'All', label: 'common.all' }];

  if (type === 'load_curve') {
    radioOptions.push(
      { value: 'Courbe de charge (0h-0h)', label: 'common.invoicing_type_0h' },
      { value: 'Courbe de charge (0h-0h) OA', label: 'common.invoicing_type_0h_feed_in_tariff' },
      { value: 'Courbe de charge (2h-2h)', label: 'common.invoicing_type_2h' },
    );
  } else {
    radioOptions.push(
      { value: 'Monthly Index', label: 'common.index_monthly' },
      { value: 'Half-Year Index', label: 'common.index_half_yearly' },
      { value: 'Annual Index', label: 'common.index_yearly' },
    );
  }

  return (
    <Filters>
      <FilterContainer size="datepicker">
        {type === 'load_curve' && <DatePicker selected={startDate} onChange={setStartDate} picks="months" />}
        {type === 'index' && (
          <DatePicker
            selectsRange
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(date) => {
              const [start, end] = date;

              setStartDate(start);
              setEndDate?.(end);
            }}
            picks="months"
          />
        )}
      </FilterContainer>

      <FilterContainer>
        <Input
          placeholder="common.filters.installation_name"
          onChange={(e) => setFilters({ ...filters, installationName: e.target.value })}
          value={filters.installationName}
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons
          name="invoicingTypeRadio"
          selected={filters.invoicingType}
          onChange={(invoicingType) => setFilters({ ...filters, invoicingType })}
          options={radioOptions}
        />
      </FilterContainer>
    </Filters>
  );
};
