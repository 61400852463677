import React from 'react';
import { useUser } from '@context/User.context';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { formatNumber } from '@utils/formatters';
import { useCurrency } from '@utils/string';
import { M0Aggregated, M0Row } from '@utils/types/m0';
import Highcharts from 'highcharts';

export const M0Plot = ({ m0Aggregated }: { m0Aggregated: M0Aggregated }) => {
  const { t } = useTranslation();
  const { locale } = useUser();
  const currency = useCurrency();

  const options: Highcharts.Options = {
    tooltip: {
      formatter: function () {
        return `<b>${this.x}</b><br /> ${formatNumber(this.y, locale)} ${currency}/MWh`;
      },
    },
    legend: { enabled: false },
    title: { text: '' },
    xAxis: {
      categories: Object.values(m0Aggregated)
        .filter((row) => row.date && row.date != 'TOTAL')
        .map((row) => row.date || ''),
    },
    yAxis: {
      title: { text: `${t('common.deviation')} (${currency}/MWh)` },
      opposite: true,
      plotLines: [
        {
          value: m0Aggregated['TOTAL']?.m0_deviation || undefined,
          width: 2.0,
          color: 'var(--chart-red)',
          zIndex: 3,
          dashStyle: 'ShortDash',
          label: { text: t('common.total') },
        },
      ],
    },
  };

  const series: Highcharts.Options['series'] = [
    {
      type: 'column',
      data: Object.values(m0Aggregated)
        .filter((row) => row.date != 'TOTAL')
        .map((row: M0Row) => ({ y: row.m0_deviation, color: 'var(--chart-aqua)' })),
      colorByPoint: true,
    },
  ];

  return (
    <div className="mt-3">
      <Chart chartType="column" options={options} series={series} />
    </div>
  );
};
