import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { getExpandedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { CountryCode } from '@utils/types/countries';
import { useRevenueContext } from '../hooks/useRevenueContext';
import styles from '../revenue.module.scss';
import { TableData } from '../revenue.types';
import { buildColumnList } from './build-column-list';
import { buildPeriodList } from './build-period-list';
import { parseToTableData } from './parse-to-table-data';

export const RevenueTable = () => {
  const { revenue, overview, resolution, groupBy, isLoading } = useRevenueContext();
  const { geolocation: userGeolocation } = useUser();

  const geolocation = userGeolocation[0] as CountryCode;
  const highlightedRows = overview?.map((_, i) => i);

  const periods: string[] = useMemo(() => {
    return buildPeriodList(revenue, overview);
  }, [overview, revenue]);

  const revenueData: TableData[] = useMemo(() => {
    return parseToTableData(revenue, overview);
  }, [overview, revenue]);

  const columns = useMemo<ColumnDef<TableData>[]>(() => {
    return buildColumnList({ geolocation, groupBy, isLoading, periods, resolution });
  }, [geolocation, groupBy, isLoading, periods, resolution]);

  const table = useReactTable({
    columns,
    data: revenueData,
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  if (isLoading && !revenue) return null;

  return (
    <>
      <Table className={styles['revenue-table']} stickyFirstColumn>
        <TableHead table={table} />
        <TableBody table={table} highlightedRows={highlightedRows} highlightColor="grey" />
      </Table>
    </>
  );
};
