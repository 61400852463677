import React, { useCallback, useMemo, useState } from 'react';
import { formatSystemDate } from '@utils/formatters';
import { isANumber } from '@utils/isANumber';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { SwapMeterModal } from './SwapMeterModal';
import { swapMeterModalContext } from './swapMeterModal.context';
import { SwapMeterModalContext } from './swapMeterModal.types';
import { useSwapMeterRequest } from './useSwapMeterRequest';

export const SwapMeterModalProvider = ({
  children,
  invoices,
}: React.PropsWithChildren<{ invoices: MeterInvoice[] }>) => {
  const [isOpen, setIsOpen] = useState<SwapMeterModalContext['isOpen']>(false);
  const [invoiceId, setInvoiceId] = useState<SwapMeterModalContext['invoiceId'] | null>(null);
  const invoice = useMemo(() => invoices.find((invoice) => invoice.uuid === invoiceId), [invoices, invoiceId]);
  const { loading, loaded, execute, error } = useSwapMeterRequest(invoiceId);

  const swapMeter = useCallback<SwapMeterModalContext['swapMeter']>((meterId) => {
    setInvoiceId(meterId);
    setIsOpen(true);
  }, []);

  const toggle = useCallback<SwapMeterModalContext['toggle']>(() => {
    setIsOpen((prevIsOpen) => {
      if (prevIsOpen) setInvoiceId(null);

      return !prevIsOpen;
    });
  }, []);

  const handleSubmit = useCallback<SwapMeterModalContext['handleSubmit']>(
    (formData) => {
      execute({
        change_date: formatSystemDate(formData.change_date),
        first_production_index: isANumber(formData.first_production_index) ? +formData.first_production_index : 0,
        last_production_index: isANumber(formData.last_production_index) ? +formData.last_production_index : 0,
        first_consumption_index: isANumber(formData.first_consumption_index) ? +formData.first_consumption_index : 0,
        last_consumption_index: isANumber(formData.last_consumption_index) ? +formData.last_consumption_index : 0,
      });
    },
    [execute],
  );

  const contextValue = useMemo<SwapMeterModalContext>(() => {
    return { isOpen, swapMeter, invoiceId: invoiceId, handleSubmit, toggle, loading, loaded, error };
  }, [isOpen, swapMeter, invoiceId, handleSubmit, toggle, loading, loaded, error]);

  return (
    <swapMeterModalContext.Provider value={contextValue}>
      <SwapMeterModal key={invoice?.uuid} isLoading={loading} invoice={invoice} />

      {children}
    </swapMeterModalContext.Provider>
  );
};
