import { InputHTMLAttributes } from 'react';
import { User } from '@utils/types/user';
import { formatNumber } from './formatNumber';

export const getInputValue = (
  value: InputHTMLAttributes<HTMLInputElement>['value'],
  type: InputHTMLAttributes<HTMLInputElement>['type'],
  readOnly?: boolean,
  user?: User,
) => {
  if (!value && value != 0) return '';

  if (readOnly && type === 'number' && value === '') return '';

  const displayValue = readOnly && type === 'number' ? formatNumber(Number(value), user?.locale) : value;

  return displayValue ?? '';
};
