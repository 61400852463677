import React from 'react';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { capitalizeFirstLetter } from '@utils/string';
import { Option } from '@utils/types/common-types';
import { Role } from '@utils/types/role';
import { Control, Controller } from 'react-hook-form';
import { FormRole, PermissionsForm } from './UserPermissions';

type PermissionSelectProps = {
  name: keyof PermissionsForm;
  label: string;
  roles: Role[];
  control: Control<PermissionsForm>;
};

const useOptions = (roles: Role[]): Option<FormRole>[] => {
  const { t } = useTranslation();

  return [
    { label: t('admin.sub_client.no_role'), value: '' },
    ...roles.map((e) => ({ label: capitalizeFirstLetter(e.split('_')[0]), value: e })),
  ];
};

const PermissionSelect = ({ control, name, roles, label }: PermissionSelectProps) => {
  const options = useOptions(roles);

  return (
    <div className="d-flex align-items-baseline mb-3">
      <label htmlFor={`${name}_input`} style={{ width: '100px' }}>
        {label}
      </label>
      <div className="flex-grow">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              id={`${name}_input`}
              options={options}
              selectedOption={field.value}
              onChange={(option) => field.onChange(option?.value)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default PermissionSelect;
