import React, { useMemo } from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Select } from '@GDM/forms';
import { useTabs } from '@GDM/Tabs';
import { Option } from '@utils/types/common-types';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Controller } from 'react-hook-form';
import { TabIds } from '../bsm.types';
import { useBSM } from '../context';
import styles from './filter.module.scss';

export const Filters = () => {
  const { activityTracks, subscriptions, control } = useBSM();
  const { currentTab } = useTabs<TabIds>();

  const emailList: Option[] = useMemo(() => {
    const resource = currentTab === 'subscriptions' ? subscriptions : activityTracks;
    if (!resource) return [];

    return uniqBy(
      resource.map((s) => ({ label: s.email, value: s.email })).filter((s) => !!s.value && !!s.label) as Option[],
      'value',
    );
  }, [activityTracks, currentTab, subscriptions]);

  return (
    <div className={classNames(styles.container, 'px-4 mt-4')}>
      {currentTab === 'activity_tracking' && (
        <Controller
          name="dates"
          control={control}
          render={({ field, fieldState }) => (
            <DatePicker
              className={styles.filter}
              onChange={field.onChange}
              startDate={field.value?.[0]}
              endDate={field.value?.[1]}
              hasError={!!fieldState.error}
              placeholderText="common.dates"
              picks="months"
              selectsRange
              isClearable
            />
          )}
        />
      )}
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <Select
            placeholder="common.email"
            className={styles.filter}
            options={emailList}
            selectedOption={field.value ?? ''}
            onChange={(option) => field.onChange(option?.value)}
            hasError={!!fieldState.error}
            isSearchable
            isClearable
          />
        )}
      />
    </div>
  );
};
