import React from 'react';
import { ValueCard } from '@GDM/Card';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './cards.module.scss';

export const LocalMarketCaptureFactorYear = () => {
  const project = useProjectContext();
  const { full_merchant_cannibalized_distribution_cat } = project?.full_merchant || {};
  const { t } = useTranslation();
  const year = full_merchant_cannibalized_distribution_cat?.[1];

  const { q50_factor, national_q50_factor } = project?.full_merchant?.full_merchant_result || {};

  const variant =
    q50_factor && national_q50_factor ? (q50_factor >= national_q50_factor ? 'primary-1' : 'secondary') : null;

  return (
    <ValueCard
      title={t('pricing.project.full_merchant_local_quality_factor_year', { year })}
      value={q50_factor || null}
      unit="percentage"
      className={styles.card}
      valueVariant={variant}
    />
  );
};
