import { SidebarMenuItemProps } from '@components/Sidebar/SidebarMenuItem/SidebarMenuItem';
import { IconNames } from '@GDM/Icon';
import {
  doc_index_path,
  monitoring_v2_events_path,
  sales_v2_events_path,
  v2_contracts_path,
  v2_meter_invoicing_index_path,
  v2_national_prices_path,
  v2_negative_prices_path,
  v2_portfolio_index_path,
  v2_ppa_simulations_path,
  v2_pricing_maps_path,
  v2_purchase_invoices_path,
  v2_unavailabilities_path,
  v2_trades_path,
} from '@utils/routes';
import { Permission } from '@utils/types/permission';
import { User } from '@utils/types/user';
import compact from 'lodash/compact';
import useTranslation from './useTranslation';

type UseMenuDataReturn = {
  id: string;
  label: string;
  permissions: Permission[];
  icon: IconNames;
  items: SidebarMenuItemProps[];
}[];

const useMenuData = (user: User): UseMenuDataReturn => {
  const { t } = useTranslation(user.locale);
  // TODO: HACK FOR SPANISH ACCOUNT
  const isSpanishUser = user.geolocation?.[0] === 'ES';

  return [
    {
      id: 'general',
      label: 'sidebar.general.title',
      icon: 'Home',
      permissions: [],
      items: [
        {
          link: '/v2/dashboardviewall',
          label: t('portfolio'),
          disabled: !user.monitoring,
          permissions: [],
        },
        {
          link: '/v2/export/show',
          label: t('sidebar.admin_settings.data_export'),
          disabled: !user.monitoring,
          hidden: user.limited,
          permissions: [],
        },
      ],
    },

    {
      id: 'monitoring',
      label: 'Monitoring',
      icon: 'Activity',
      permissions: ['display:view_monitoring'],
      items: [
        {
          link: monitoring_v2_events_path(),
          label: t('common.alerts'),
          disabled: !user.monitoring,
          permissions: ['display:view_monitoring_alerts_page'],
        },
        {
          link: v2_meter_invoicing_index_path(),
          label: t('common.invoicing_volume'),
          disabled: !user.monitoring,
          hidden: !user.billing || user.sales_management || user.limited,
          permissions: ['display:view_monitoring_index_page'],
        },
        {
          link: '/v2/comparaison_solaire/show',
          label: t('sidebar.admin_settings.comparison'),
          disabled: !user.monitoring,
          permissions: ['display:view_monitoring_comparison_page'],
        },
        {
          link: v2_unavailabilities_path(),
          label: t('sidebar.unavailabilities'),
          disabled: !user.permissions.includes('display:sidebar_unavailability'),
          hidden: user.limited,
        },
      ],
    },
    {
      id: 'sales_focus',
      label: user.permissions.includes('display:etrm') ? 'Portfolio Management' : 'Salesfocus',
      icon: 'ShoppingCart',
      permissions: [],
      items: [
        {
          link: v2_contracts_path(),
          label: t('common.contracts'),
          disabled: !user.permissions.includes('display:external_salesfocus'),
          hidden: user.limited,
        },
        {
          link: v2_trades_path(),
          label: t('common.trades'),
          disabled: !user.permissions.includes('display:etrm'),
          hidden: !user.permissions.includes('display:etrm'),
        },
        {
          link: '/v2/internal_risk_analysis?#valuation',
          label: t('common.risk_and_valuation'),
          disabled: !user.permissions.includes('display:etrm'),
          hidden: !user.permissions.includes('display:etrm'),
        },
        {
          link: v2_meter_invoicing_index_path(),
          label: t('common.invoices'),
          disabled: !user.permissions.includes('display:external_salesfocus'),
          hidden: user.limited,
        },
        {
          link: v2_purchase_invoices_path(),
          label: t('common.purchase_invoices'),
          disabled: !user.permissions.includes('display:external_salesfocus'),
          hidden: user.limited,
          permissions: ['display:purchase_invoices'],
        },
        {
          link: sales_v2_events_path(),
          label: t('common.alerts'),
          disabled: !user.permissions.includes('display:external_salesfocus'),
        },
        {
          link: v2_national_prices_path(),
          label: t('common.national_prices'),
          disabled: !user.permissions.includes('display:national_prices'),
          hidden: user.limited || isSpanishUser,
        },
        {
          link: v2_negative_prices_path(),
          label: t('common.negative_prices'),
          disabled: !user.permissions.includes('display:view_salesfocus'),
          hidden: user.limited,
        },
      ],
    },
    {
      id: 'reporting',
      label: 'sidebar.reporting',
      icon: 'PieChart',
      permissions: [],
      items: [
        {
          link: v2_portfolio_index_path(),
          label: t('common.overview'),
          disabled: !user.permissions.includes('display:external_salesfocus'),
        },
        {
          link: '/v2/custom_reporting',
          label: t('common.custom'),
          permissions: ['display:custom_reporting'],
          disabled: !user.permissions.includes('display:external_salesfocus'),
        },
        {
          link: '/v2/risk_analysis?#valuation',
          label: t('common.risk_analysis'),
          permissions: ['display:green_star_risk'],
          disabled: !user.permissions.includes('display:external_salesfocus'),
        },
      ],
    },
    {
      id: 'pricing',
      label: 'Pricing',
      icon: 'DollarSign',
      permissions: [],
      items: compact([
        {
          link: v2_ppa_simulations_path(),
          label: t('portfolio'),
          disabled: !user.permissions.includes('display:view_pricing'),
        },
        {
          link: v2_pricing_maps_path(),
          label: t('common.national_maps'),
          disabled: !user.permissions.includes('display:view_pricing'),
        },
        {
          link: v2_national_prices_path(),
          label: t('common.national_prices'),
          disabled: !user.permissions.includes('display:view_pricing'),
          hidden: user.sales_management,
        },
      ]),
    },
    {
      id: 'admin',
      icon: 'Settings',
      label: t('sidebar.admin_settings.title'),
      permissions: ['display:edit_monitoring', 'display:edit_salesfocus', 'display:edit_pricing', 'display:view_api'],
      items: [
        {
          link: '/v2/installations',
          label: t('sidebar.admin_settings.installations'),
          hidden: user.limited,
          permissions: ['display:edit_monitoring', 'display:edit_salesfocus', 'display:edit_pricing'],
        },
        {
          link: '/v2/books',
          label: t('sidebar.admin_settings.books'),
          hidden: user.limited,
          permissions: ['display:edit_monitoring', 'display:edit_salesfocus', 'display:edit_pricing'],
        },
        {
          link: '/v2/alerts',
          label: t('sidebar.admin_settings.alerts'),
          hidden: user.limited,
          permissions: ['index:user_notification_settings'],
        },
        {
          link: '/v2/sub_client_admin',
          label: t('sidebar.admin_settings.users'),
          hidden: user.limited,
          permissions: ['display:admin_users'],
        },
        {
          link: doc_index_path(),
          label: t('sidebar.admin_settings.api_doc'),
          hidden: user.limited,
          permissions: ['display:view_api'],
        },
      ],
    },
  ];
};

export default useMenuData;
