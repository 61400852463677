export const VAT_RATES = {
  HU: 0.27,
  DK: 0.25,
  NO: 0.25,
  SE: 0.25,
  GR: 0.24,
  IS: 0.24,
  FI: 0.24,
  PL: 0.23,
  PT: 0.23,
  IT: 0.22,
  UY: 0.22,
  BE: 0.21,
  NL: 0.21,
  ES: 0.21,
  ME: 0.21,
  CZ: 0.21,
  AT: 0.2,
  FR: 0.2,
  UK: 0.2,
  GB: 0.2,
  BG: 0.2,
  TR: 0.2,
  DE: 0.19,
  CL: 0.19,
  RO: 0.19,
  BF: 0.18,
  DO: 0.18,
  IN: 0.18,
  BR: 0.17,
  IL: 0.17,
  ZA: 0.15,
  NZ: 0.15,
  HR: 0.13,
  CN: 0.13,
  KZ: 0.12,
  PH: 0.12,
  ID: 0.11,
  AU: 0.1,
  JP: 0.1,
  KR: 0.1,
  MY: 0.1,
  SG: 0.09,
  CH: 0.077,
  TH: 0.07,
  CA: 0.05,
  US: 0,
  HK: 0,
  MX: 0.16,
};

export const VAT_EXEMPTION_MESSAGES: Record<string, string> = {
  AT: 'VAT exempt',
  BE: 'Exonération de TVA',
  BF: 'Exonéré de TVA',
  CH: 'TVA autoliquidée (Art. 283 du CGI)',
  CL: 'Exento de IVA',
  DE: 'USt. befreit',
  DO: 'RNC exento',
  ES: 'Factura exenta de IVA',
  FR: 'TVA autoliquidée (Art. 283 du CGI)',
  FI: 'VAT exempt',
  HR: 'PDV oslobođen',
  ME: 'VAT exempt',
  MX: 'VAT exempt',
  IT: 'Factura exenta de IVA',
  KZ: 'Exclusive of any applicable taxes',
  NL: 'BTW niet van toepassing',
  PL: 'Exclusive of any applicable taxes',
  PT: 'Exclusive of any applicable taxes',
  UK: 'VAT exempt',
  US: 'VAT exempt',
  UY: 'Exento de IVA',
  ZA: 'VAT exempt',
};
