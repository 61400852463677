import React from 'react';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { CodeInput } from '../../fields/CodeInput';
import { IpInput } from '../../fields/IpInput';
import { KeyInput } from '../../fields/KeyInput';
import { MeterPassAddressInput } from '../../fields/MeterPassAddressInput';
import { NumberInput } from '../../fields/NumberInput';
import { TcpPortInput } from '../../fields/TcpPortInput';

export const BaseFields = () => {
  const { watch } = useInstallationForm();
  const boitierIp = watch('boitier_ip');
  const country = watch('country');
  const isEs = country === 'ES';

  return (
    <>
      <div className="d-flex gap-3 mt-3">
        {isEs && <MeterPassAddressInput />}
        {isEs && <CodeInput />}
      </div>
      <div className="d-flex gap-3 mt-3">
        <KeyInput />

        {!boitierIp && <NumberInput />}

        {boitierIp && (
          <>
            <IpInput />
            <TcpPortInput />
          </>
        )}
      </div>
    </>
  );
};
