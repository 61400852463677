import React, { ReactNode, useMemo } from 'react';
import { Button } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { modalContext } from './modal.context';
import styles from './modal.module.scss';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalWrapper } from './ModalWrapper';

type ModalProps = {
  children?: ReactNode;
  className?: string;
  disableKeyboardClose?: boolean;
  error?: string;
  footer?: ReactNode;
  info?: string;
  header?: string | React.ReactNode;
  hideCancelButton?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  saveButtonText?: string;
  cancelButtonText?: string;
  scrollable?: boolean;
  'data-cy'?: string;
  submitAction?: () => void;
  submitDisabled?: boolean;
  toggle?: () => void;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  onClosed?: () => void;
};

export const Modal = ({
  cancelButtonText,
  children,
  className,
  'data-cy': dataCy,
  disableKeyboardClose,
  error,
  footer,
  header,
  hideCancelButton,
  info,
  isLoading,
  isOpen,
  onClosed,
  saveButtonText,
  scrollable,
  size,
  submitAction,
  submitDisabled,
  toggle,
}: ModalProps) => {
  const { t } = useTranslation();

  const contextValue = useMemo(() => ({ toggle }), [toggle]);

  return (
    <modalContext.Provider value={contextValue}>
      <ModalWrapper
        className={className}
        toggle={disableKeyboardClose ? undefined : toggle}
        isOpen={isOpen}
        size={size || 'md'}
        onClosed={onClosed}
        data-cy={dataCy}
        scrollable={scrollable}
      >
        {header && <ModalHeader toggle={toggle}>{typeof header === 'string' ? t(header) : header}</ModalHeader>}

        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <div className="d-flex flex-column flex-1">
            {Boolean(footer) && footer}
            {Boolean(info) && <Text text={info} size="sm" icon="AlertCircle" multiline />}
            {Boolean(error) && <Text type="danger" text={error} size="sm" icon="AlertCircle" multiline />}
          </div>
          {!hideCancelButton && (
            <Button
              size="sm"
              type="button"
              variant="link"
              onClick={toggle}
              className={styles['cancel-button']}
              text={t(cancelButtonText || 'common.cancel')}
            />
          )}

          {submitAction && (
            <Button
              size="sm"
              variant="primary-2"
              type="submit"
              disabled={submitDisabled}
              onClick={submitAction}
              className={styles['submit-button']}
            >
              {t(saveButtonText || 'common.save')}
              {!!isLoading && <Spinner size="sm" className="ml-1" />}
            </Button>
          )}
        </ModalFooter>
      </ModalWrapper>
    </modalContext.Provider>
  );
};
