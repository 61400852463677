import { useState } from 'react';
import { Option } from '@utils/types/common-types';
import isEqual from 'lodash/isEqual';
import { useShouldUseCache } from './useShouldUseCache';

type OptionsGetter<DataType, OptionType> = (data: DataType[]) => OptionType[];

const useSyncOptionsGetter = <DataType, OptionType>(
  optionsGetter: OptionsGetter<DataType, OptionType>,
  cb: (optionsGetter: OptionsGetter<DataType, OptionType>) => void,
) => {
  const [previousOptionsGetter, setPreviousOptionsGetter] = useState<OptionsGetter<DataType, OptionType>>(
    () => optionsGetter,
  );

  if (previousOptionsGetter !== optionsGetter) {
    setPreviousOptionsGetter(() => optionsGetter);
    cb(optionsGetter);
  }

  return previousOptionsGetter;
};

/**
 * This hook is used to get a list of options based on which filters are selected
 * If user is selecting a filter, this filter will use cached options
 * If user is not selecting a filter, this filter will use dynamic options
 * It allows for a nice UX and making other than selected filters dynamic
 */
export const useDynamicOptions = <
  FilterType extends Record<string, unknown>,
  DataType extends Record<string, unknown>,
  OptionType extends Option<string | null>,
>(
  optionsGetter: OptionsGetter<DataType, OptionType>,
  memoKey: keyof FilterType,
  data: DataType[],
  allData: DataType[],
) => {
  const shouldUseCache = useShouldUseCache(memoKey);
  const allDataIds = allData.map((c) => c.id);
  const [previousAllData, setPreviousAllData] = useState(allData.map((c) => c.id));
  const [previousOptions, setPreviousOptions] = useState<OptionType[]>(optionsGetter(allData));

  const syncedOptionsGetter = useSyncOptionsGetter(optionsGetter, (callback) => setPreviousOptions(callback(data)));

  // It's faster to compare arrays of ids than arrays of objects, as data could be a large array
  // We need this because allData should be used for the first render, but allData is set to []/null on the first render
  // Therefore, we need to wait for allData to have a value before setting options
  if (!isEqual(previousAllData, allDataIds)) {
    setPreviousAllData(allDataIds);
    setPreviousOptions(syncedOptionsGetter(allData));
  }

  if (!shouldUseCache) {
    const newOptions = syncedOptionsGetter(data);

    if (!isEqual(previousOptions, newOptions)) {
      setPreviousOptions(newOptions);
    }

    return newOptions;
  }

  return previousOptions;
};
