import React from 'react';
import { Select } from '@GDM/forms';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { InstallationWithMeterInfo } from '@utils/types/installation';
import { ExportFormData } from 'pages/Export/export.types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

export const MeterNamesSelect = ({ installations }: { installations: InstallationWithMeterInfo[] }) => {
  const { control } = useFormContext<ExportFormData>();
  const books = useWatch({ control, name: 'books' });
  const options = installations
    .map((installation) => ({ label: installation.name, value: installation.name, type: 'installation' }))
    .sort(sortOptionsByLabelAsc);

  return (
    <Controller
      name="meterNames"
      control={control}
      rules={{ required: books.length === 0, minLength: books.length ? 0 : 1 }}
      render={({ field }) => (
        <Select
          label="common.installations"
          id="meterNames"
          options={options}
          isMulti
          hasSelectAll
          selectedOptions={field.value}
          onChange={(options) => field.onChange(options?.map((option) => option.value))}
          classNamePrefix="export-meter-names"
        />
      )}
    />
  );
};
