import { useFormContext } from 'react-hook-form';
import { DEFAULT_INSTRUCTION, WINTER_INSTRUCTION, SUMMER_INSTRUCTION } from './default-instructions';
import { ReactiveForm } from './types';

export function useReactiveForm() {
  const context = useFormContext<ReactiveForm>();

  const resetInstructions = (): void => {
    const [periodicity, type] = context.getValues(['periodicity', 'type']);

    if (periodicity === 'constant') {
      return context.resetField('instructions', { defaultValue: [{ ...DEFAULT_INSTRUCTION, reactive_type: type }] });
    }

    if (periodicity === 'seasonal') {
      return context.resetField('instructions', {
        defaultValue: [
          { ...WINTER_INSTRUCTION, reactive_type: type },
          { ...SUMMER_INSTRUCTION, reactive_type: type },
        ],
      });
    }
  };

  return {
    ...context,
    resetInstructions,
  };
}
