import { useEffect, useState } from 'react';
import axiosInstance from '@utils/axiosInstance';
import axios from 'axios';
import parseGeoraster from 'georaster';
import { GeoRasterData } from './pricing-maps.types';

export const useGeoRaster = (url: string) => {
  const [request, setRequest] = useState<{ data: GeoRasterData | null; loading: boolean; error: string | null }>({
    data: null,
    loading: false,
    error: null,
  });

  useEffect(() => {
    const fetchRaster = async () => {
      setRequest((req) => ({ ...req, loading: true, error: null }));

      try {
        const { data } = await axiosInstance(false).get<Blob>(url, { responseType: 'blob' });
        const buffer = await data.arrayBuffer();
        const fetchedRaster = await parseGeoraster(buffer);

        setRequest({ data: fetchedRaster, loading: false, error: null });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setRequest({ data: null, loading: false, error: error.message });
        } else {
          setRequest({ data: null, loading: false, error: 'errors.unknown_error' });
        }
      }
    };

    if (url) fetchRaster();
  }, [url]);

  return request;
};
