import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useDynamicOptions } from '@GDM/Filters';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import uniqBy from 'lodash/uniqBy';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

const getOptions = (invoices: MeterInvoice[]): Option<MeterInvoice['contract_buyer_short_name']>[] => {
  const buyers = invoices.map((invoice) => invoice.contract_buyer_short_name);

  return uniqBy(
    buyers
      .filter((buyer) => buyer)
      .map((buyer) => ({ value: buyer, label: buyer }))
      .sort(sortOptionsByLabelAsc),
    'value',
  );
};

export const BuyerFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'buyer_name', filteredInvoices, allInvoices);

  return (
    <ControlledSelect
      control={form.control}
      name="buyer_name"
      classNamePrefix="filter-buyer_name"
      placeholder="common.offtaker"
      isClearable
      options={options}
      isMulti
      inline
    />
  );
};
