import React from 'react';
import { Button } from '@GDM/Button';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useSwapMeterModal } from 'pages/sales-management/invoicing/SwapMeterModal/swapMeterModal.context';

export const SwapMeterButton = ({ className, invoice }: { className?: string; invoice: MeterInvoice }) => {
  const { swapMeter } = useSwapMeterModal();

  return (
    <Button
      className={className}
      floating
      icon="RefreshCw"
      data-cy="swap-meter-button"
      tooltip="sales_management.swap_meter_modal.meter_switch_label"
      onClick={() => swapMeter(invoice.uuid)}
    />
  );
};
