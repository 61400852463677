import React, { useEffect } from 'react';
import { createUserNotificationsSettings } from '@api/userNotificationsSetings';
import ControlledCheckbox from '@components/FormInputs/ControlledCheckbox';
import { Restricted } from '@components/Restricted';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { type UserNotificationsSettings } from '@utils/types/userNotificationsSettings';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { useUserNotificationsSettingsCreateMutation } from '../hooks/useUserNotificationsSettingsCreateMutation';
import { useUserNotificationsSettingsUpdateMutation } from '../hooks/useUserNotificationsSettingsUpdateMutation';
import { EventTypesSelect } from './EventTypesSelect';
import { EnergyOption, InstallationsBooksSelect } from './InstallationsBooksSelect';
import { NotificationEmailsInput } from './NotificationEmailsInput';

export type NotificationFormData = {
  internal_name: string;
  all_installations_selected: boolean;
  installation_name: string;
  book_uuid: string;
  event_types: UserNotificationsSettings['event_types'];
  emails: { email: string }[];
  id?: number;
};

export const NotificationFormModal = ({
  isOpen,
  toggle,
  onSubmission,
  selectedNotification,
}: {
  isOpen: boolean;
  toggle: () => void;
  onSubmission: (
    oldSettings: UserNotificationsSettings | undefined | null,
    newSettings: UserNotificationsSettings | undefined | null,
  ) => void;
  selectedNotification?: UserNotificationsSettings | null;
}) => {
  const isEditing = typeof selectedNotification?.id === 'number';
  const { control, setValue, reset, handleSubmit, watch } = useForm<NotificationFormData>({
    defaultValues: getFormDefaultValues(selectedNotification),
  });

  const createMutation = useUserNotificationsSettingsCreateMutation();
  const updateMutation = useUserNotificationsSettingsUpdateMutation();
  const mutation = isEditing ? updateMutation : createMutation;

  const onSubmit = async (formData: NotificationFormData) => {
    const data = formatBody(formData);

    const settings: UserNotificationsSettings = isEditing
      ? await updateMutation.mutateAsync({ id: selectedNotification.id, data })
      : await createMutation.mutateAsync(data);

    onSubmission(selectedNotification, settings);

    toggle();
  };

  const onInstallationBookChange = (
    field: ControllerRenderProps<NotificationFormData, 'internal_name'>,
    option: EnergyOption | null,
  ) => {
    field.onChange(option?.value || null);

    const isBook = option?.energy === 'book';
    const value = option?.value || '';

    setValue('installation_name', !isBook ? value : '');
    setValue('book_uuid', isBook ? value : '');
  };

  // Form reset when modal closes
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const allInstatllationsAreSelected = watch('all_installations_selected');

  return (
    <Restricted permissions={['create:user_notification_settings', 'update:user_notification_settings']}>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        header={isEditing ? 'admin.alerts.edit_alert' : 'admin.alerts.create_alert'}
        submitAction={handleSubmit(onSubmit)}
        isLoading={mutation.isPending}
        size="lg"
      >
        <div className="d-flex flex-column gap-3">
          <NotificationEmailsInput control={control} />
          <InstallationsBooksSelect
            control={control}
            onChange={onInstallationBookChange}
            disabled={allInstatllationsAreSelected}
          />
          <ControlledCheckbox name="all_installations_selected" control={control} label="admin.installations.all" />
          <EventTypesSelect control={control} isEditing={isEditing} />
          {mutation.error && <Text type="danger" text={mutation.error.message || 'errors.unknown_error'} />}
        </div>
      </Modal>
    </Restricted>
  );
};

const formatBody = (data: NotificationFormData): Parameters<typeof createUserNotificationsSettings>[0] => {
  const baseBody = {
    emails: data.emails.map(({ email }) => email).filter((email) => email),
    event_types: data.event_types,
  };

  if (data.all_installations_selected) return baseBody;

  if (data.book_uuid) {
    return { ...baseBody, book_uuids: [data.book_uuid] };
  }

  return { ...baseBody, installation_names: [data.installation_name] };
};

const getFormDefaultValues = (selectedNotification?: UserNotificationsSettings | null): NotificationFormData => {
  if (!selectedNotification) {
    return {
      internal_name: '',
      all_installations_selected: false,
      installation_name: '',
      book_uuid: '',
      event_types: [],
      emails: [{ email: '' }],
    };
  }

  const installation_name =
    selectedNotification.eventable_type === 'Installation' ? selectedNotification.eventable?.name || '' : '';
  const book_uuid = selectedNotification.eventable_type === 'Book' ? selectedNotification.eventable?.uuid || '' : '';
  const internal_name = installation_name || book_uuid;

  return {
    all_installations_selected: !internal_name,
    installation_name,
    book_uuid,
    internal_name,
    event_types: selectedNotification.event_types,
    emails: selectedNotification.emails.map((email) => ({ email })),
    id: selectedNotification.id,
  };
};
