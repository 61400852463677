export const parseTypecompteur = (type: string) => {
  if (!type) return '';

  switch (type) {
    case 'PMEI':
      return 'PMEPMI';
    case 'LINKY':
      return 'LINKY_STEP30';
    default:
      return type;
  }
};
