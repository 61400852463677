import React from 'react';
import { useUser } from '@context/User.context';
import { Icon } from '@GDM/Icon';
import styles from './user-menu-infos.module.scss';

export const UserMenuInfosTitle = () => {
  const user = useUser();
  const name = user.name || user.email;

  return (
    <div className={styles['title']}>
      <div className={styles['avatar']} title={user.name && user.email ? `${user.name} (${user.email})` : name}>
        {name.charAt(0).toLocaleUpperCase()}
      </div>
      <div className={styles['name-wrapper']}>
        <div className={styles['name']} data-cy="user-name">
          {user.name || user.email}
        </div>
        {user.name && user.email && (
          <div className={styles['email']} data-cy="user-email">
            {user.email}
          </div>
        )}
      </div>
      <Icon name="ChevronRight" size={16} />
    </div>
  );
};
