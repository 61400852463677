import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import dayjs from 'dayjs';
import range from 'lodash/range';
import { Control } from 'react-hook-form';
import { FormType } from './BusinessPlanForm';

type Props = {
  control: Control<FormType>;
  isDisabled: boolean;
};

const currentYear = dayjs().year();
const minYear = currentYear - 5;
const maxYear = currentYear + 15;

export const defaultYearRange = range(minYear, maxYear);

export const YearFormField = ({ control, isDisabled }: Props) => {
  const options = defaultYearRange.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  return (
    <ControlledSelect
      placeholder="common.year"
      name="year"
      control={control}
      options={options}
      isDisabled={isDisabled}
    />
  );
};
