import React from 'react';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import { AxiosResponseLoadingState } from '@utils/types/useAxios';

type SaveMandateBtnProps = {
  onSave: () => void;
  loading: AxiosResponseLoadingState;
  disabled: boolean;
  requiredFields: boolean;
  allowEdit: boolean;
  setAllowEdit: (allowEdit: boolean) => void;
  valuesHasChanged: boolean;
  toRenew: boolean;
};

const SaveMandateBtn = ({
  onSave,
  loading,
  disabled,
  requiredFields,
  allowEdit,
  setAllowEdit,
  valuesHasChanged,
  toRenew,
}: SaveMandateBtnProps) => {
  const { t } = useTranslation();

  /**
   * if the mandate is to renew, we only want to check if the required fields are present
   * otherwise we want to also check if the values have changed
   */
  const saveBtnIsDisabled = toRenew ? requiredFields : requiredFields || !valuesHasChanged;

  if (loading === 'LOADING') {
    return <Button variant="primary-2" size="xs" disabled isLoading text="common.save_in_progress" />;
  }

  if (disabled && !toRenew) {
    return (
      <Button onClick={() => setAllowEdit(!allowEdit)} variant="primary-2" size="xs" icon="Edit2" text="common.edit" />
    );
  }

  return (
    <Button
      onClick={onSave}
      variant="primary-2"
      size="xs"
      disabled={saveBtnIsDisabled}
      text={toRenew ? t('common.renew') : t('common.save')}
      data-cy="save-mandate-btn"
    />
  );
};

export default SaveMandateBtn;
