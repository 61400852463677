import React from 'react';
import { Tab, Tabs } from '@GDM/Tabs';
import { tabsContext } from '@GDM/Tabs/tabs.context';
import Page from '@pages/Page/Page';
import { getTypeIconName } from '@utils/getTypeMeterIcons';
import { User } from '@utils/types/user';
import SimulationStatusCell from 'pages/Pricing/Simulations/table/cell/SimulationStatusCell';
import { ProjectProvider } from './ProjectProvider';
import styles from './simulation.module.scss';
import { SimulationActions } from './SimulationActions/SimulationActions';
import { Characteristics } from './tabs/Characteristics';
import { Realised } from './tabs/Realised';
import { Results } from './tabs/Results';
import { projectContext } from './utils/project.context';

export const Simulation = ({ user }: { user: User }) => {
  const tabs: Tab<'results' | 'realised' | 'characteristics'>[] = [
    { id: 'results', name: 'pricing.project.results' },
    { id: 'realised', name: 'pricing.project.realised', condition: false },
    { id: 'characteristics', name: 'pricing.project.characteristics' },
  ];

  return (
    <ProjectProvider>
      <projectContext.Consumer>
        {(project) => (
          <Page
            title={project.installation?.name ?? project.book?.name ?? 'pricing.portfolio.simulation_name'}
            user={user}
            titleIcon={getTypeIconName(project.installation?.energy ?? 'book')}
            header={!project.isBook && <SimulationActions />}
            subtitle={<SimulationStatusCell value={project.simulation?.status} />}
          >
            <Tabs tabs={tabs.map((tab, index) => (index !== 1 ? tab : { ...tab, condition: !project?.isBook }))}>
              <tabsContext.Consumer>
                {({ currentTab }) => (
                  <div className={styles['tab-container']}>
                    {currentTab === 'results' && <Results />}
                    {currentTab === 'realised' && <Realised />}
                    {currentTab === 'characteristics' && <Characteristics />}
                  </div>
                )}
              </tabsContext.Consumer>
            </Tabs>
          </Page>
        )}
      </projectContext.Consumer>
    </ProjectProvider>
  );
};
