import React, { useEffect, useMemo } from 'react';
import { useUser } from '@context/User.context';
import chroma from 'chroma-js';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { useMap } from 'react-leaflet';
import { getPricingMapSettings } from '../utils/getPricingMapSettings';
import { GeoRasterData, PricingMapM0s } from '../utils/pricing-maps.types';
import { usePricingMapsFiltersContext } from '../utils/pricingMapsFilters.context';
import { LegendContent } from './LegendContent';

export const PricingMapLegend = ({ geoRaster, m0s }: { geoRaster: GeoRasterData; m0s: PricingMapM0s }) => {
  const { locale } = useUser();
  const map = useMap();
  const { filters } = usePricingMapsFiltersContext();
  const isDeviation = filters.result === 'deviation';

  const { minValue, rangeOfValues, baselineValue, maxAbsValue } = useMemo(
    () => getPricingMapSettings(isDeviation, geoRaster, m0s.m0_prices, m0s.base_m0_prices),
    [isDeviation, geoRaster, m0s.m0_prices, m0s.base_m0_prices],
  );
  const scale = useMemo(() => {
    return chroma
      .scale(['#ad002e', '#ff1654', '#fdfec9', '#054652', '#03252b'])
      .domain([baselineValue - maxAbsValue, baselineValue + maxAbsValue]);
  }, [baselineValue, maxAbsValue]);

  useEffect(() => {
    const legend = new L.Control({ position: 'topright' });

    legend.onAdd = function () {
      const self = this as L.Control & { _div: HTMLDivElement };
      self._div = L.DomUtil.create('div');
      self._div.innerHTML = renderToString(
        <LegendContent
          locale={locale}
          isDeviation={isDeviation}
          scale={scale}
          minValue={minValue}
          rangeOfValues={rangeOfValues}
        />,
      );

      return self._div;
    };
    // Add this legend to the map
    legend.addTo(map);

    return () => {
      legend.remove();
    };
  }, [map, minValue, rangeOfValues, scale, isDeviation, locale]);

  return null;
};
