import { computePriceTooltip } from '@components/invoices/Table/Cells/UnitPriceCell';
import { tString } from '@hooks/useTranslation';
import { HeadCsv } from '@utils/json-to-csv';
import { Locale } from '@utils/types/common-types';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice } from '@utils/types/meter-invoice';
import compact from 'lodash/compact';
import round from 'lodash/round';
import getInvoiceType from '../getInvoiceType';

/**
 * This function ensure that the same fields are in the same order, and formatted the same way in all CSV exports
 */
const getCommonFields = (locale: Locale, direction: Contract['direction'] | 'all'): HeadCsv<MeterInvoice>[] => {
  const t = tString(locale);

  return compact([
    { label: t('common.installation'), key: 'installation_name' },
    { label: t('common.client_id'), key: 'installation_client_id' },
    { label: t('common.type'), getValue: (invoice) => getInvoiceType(invoice, locale).invoiceType || '' },
    direction !== 'buy' ? { label: t('invoice.number'), key: 'invoice_nb_expression' } : null,
    direction !== 'buy' ? { label: t('monitoring.invoicing.invoicing_date'), key: 'issued_at' } : null,
    { label: t('common.start_date'), key: 'start_date' },
    { label: t('common.end_date'), key: 'end_date' },
    { label: t('sales_management.contract_nb'), key: 'contract_nb' },
    {
      label: t('monitoring.invoicing.source'),
      key: 'volume_source',
      transform: (source) => (source === 'load_curve' ? 'points 10mn' : 'index'),
    },
    direction !== 'buy' ? { label: t('invoice.volume'), key: 'volume_production' } : null,
    direction === 'buy' ? { label: t('invoice.volume'), key: 'volume_consumption' } : null,
    direction !== 'buy' ? { label: t('sales_management.prod_validated'), key: 'volume_status' } : null,
    { label: t('contracts.status'), key: 'status' },
    { label: t('common.contract_type'), key: 'contract_type' },
    {
      label: t('common.price_type'),
      getValue: (filteredInvoices: MeterInvoice) => computePriceTooltip(filteredInvoices, t) ?? '',
    },
    { label: t('sales_management.price_label'), key: 'unit_price', transform: (v) => round(Number(v), 2) },
    { label: t('sales_management.total_amount'), key: 'total_amount', transform: (v) => round(Number(v), 2) },
    direction !== 'buy' ? { label: t('sales_management.card_i'), key: 'contract_card_i' } : null,
    direction !== 'buy' ? { label: t('monitoring.invoicing.consum'), key: 'volume_consumption' } : null,
    { label: t('sales_management.erp_code'), key: 'contract_erp_code' },
    {
      label: t(direction === 'buy' ? 'sales_management.seller' : 'sales_management.owner'),
      key: 'contract_seller_short_name',
    },
    // add these columns after populating the columns, it will be done through the invoice admin create_update_in_batch
    // NOTE NOTE: Please make sure the output of these values are numbers and not strings in the csv file
    // { label: t('sales_management.index_ref_prod'), key: 'contract_producer_price_insee_index_ref' },
    { label: t('sales_management.index_prod'), key: 'producer_price_insee_index' },
    // { label: t('sales_management.index_ref_work'), key: 'contract_labour_cost_insee_index_ref' },
    { label: t('sales_management.index_work'), key: 'labour_cost_insee_index' },
    { label: t('monitoring.invoicing.l_coef'), key: 'l_coef', transform: (v) => round(Number(v), 5) },
    direction !== 'buy'
      ? {
          label: t('sales_management.financement_participatif'),
          key: 'contract_financement_participatif',
          transform: (v) => (v ? t('common.yes') : t('common.no')),
        }
      : null,
    direction !== 'buy'
      ? {
          label: t('sales_management.investissement_participatif'),
          key: 'contract_investissement_participatif',
          transform: (v) => (v ? t('common.yes') : t('common.no')),
        }
      : null,
    { label: t('common.updated_at'), key: 'updated_at' },
    { label: 'id', key: 'uuid' },
    {
      label: t('common.data_source'),
      key: 'data_source',
      transform: (v) => (v === 'other' ? t('common.other') : 'Streem'),
    },
  ]);
};

export default getCommonFields;
