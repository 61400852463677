import type { Event, EventType } from '@utils/types/event';

export const dangerLevels: Partial<Record<EventType, number>> = {
  HighUnderPerformance: 1,
  OutOfRangeReactive: 1,
  ProductionStop: 1,
  UnderPerformance: 2,
  CommunicationFailure: 3,
};

export const getHighestDangerLevel = (events: Event[]): number => {
  const levels: number[] = events.map((event) => dangerLevels[event.type] || 0);
  if (levels && levels.length > 0) return Math.min(...levels);

  return 5;
};
