import React from 'react';
import { Button } from '@GDM/Button/Button';
import { sectionStyles } from '@GDM/forms';
import inputStyles from '@GDM/forms/Input/input.module.scss';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { Fee } from '@utils/types/contract/sub-period';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { FieldPath, FieldValues, UseFieldArrayProps, UseFormReturn, useFieldArray } from 'react-hook-form';
import styles from '../../../../sub-period.module.scss';
import aggregFeeStyles from './aggreg-fees.module.scss';

export const AggregatorFees = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  name,
  form,
  disabled,
  currency,
  readOnly,
  isMulti,
  label,
  labelStyle,
  tooltip,
}: {
  name: FieldPath<TFieldValues>;
  currency: Currency;
  form: UseFormReturn<TFieldValues, TFieldName>;
  disabled?: boolean;
  readOnly: boolean;
  isMulti?: boolean;
  label?: string;
  labelStyle?: React.CSSProperties;
  tooltip?: string | null;
}) => {
  const { control } = form;

  const aggregFees = useFieldArray({
    control,
    name,
    keyName: 'aggreg_fees_id',
    // Type safety with React Hook Form is hard to achieve when building generic components
  } as unknown as UseFieldArrayProps<{ fees: Fee[] }, 'fees', 'aggreg_fees_id'>);

  const feeTypeIsDisabled = disabled || aggregFees.fields.length === 2 || readOnly;

  const addAggregFee = () => {
    const type = aggregFees.fields[0]?.type;
    aggregFees.append({ value: null, type: type === 'fixed' || !type ? 'percentage' : 'fixed' });
  };

  return (
    <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
      <div className={aggregFeeStyles.wrapper}>
        {aggregFees.fields.map((fee, i) => (
          <div className={classNames(aggregFeeStyles['fee-wrapper'])} key={fee.aggreg_fees_id}>
            <AggregatorFee
              name={`${name}.${i}` as TFieldName}
              form={form}
              currency={currency}
              disabled={disabled}
              feeTypeIsDisabled={feeTypeIsDisabled}
              label={label}
              labelStyle={labelStyle}
              tooltip={tooltip}
            />
            {aggregFees.fields.length > 1 && !readOnly && (
              <Button
                icon="Trash2"
                variant="secondary"
                tooltip="common.delete"
                onClick={() => aggregFees.remove(i)}
                disabled={disabled}
                className="ml-1"
                size="xs"
                floating
              />
            )}
          </div>
        ))}

        {aggregFees.fields.length <= 1 && isMulti && !readOnly && (
          <Button
            className={aggregFeeStyles['plus-button']}
            icon="Plus"
            text="sales_management.add_fee"
            onClick={addAggregFee}
            variant="link"
            disabled={disabled}
            size="sm"
            data-cy="add-aggreg-fee"
          />
        )}
      </div>
    </div>
  );
};

export const AggregatorFee = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  currency,
  form: { control, watch },
  name,
  disabled,
  feeTypeIsDisabled,
  label = 'sales_management.aggreg_fee',
  labelStyle,
  tooltip,
}: {
  name: FieldPath<TFieldValues>;
  currency: Currency;
  form: UseFormReturn<TFieldValues, TFieldName>;
  disabled?: boolean;
  feeTypeIsDisabled?: boolean;
  label?: string;
  labelStyle?: React.CSSProperties;
  tooltip?: string | null;
}) => {
  const feeTypeFieldName = `${name}.type` as TFieldName;
  const feeValueFieldName = `${name}.value` as TFieldName;

  const options = [
    { label: `${getSymbol(currency)}/MWh`, value: 'fixed' },
    { label: '%', value: 'percentage' },
  ];

  const feeType = watch(feeTypeFieldName);

  const suffix = (
    <ListInput
      id="AggregFeeType"
      options={options}
      control={control}
      name={feeTypeFieldName}
      className={inputStyles.select}
      size="lg"
      isDisabled={feeTypeIsDisabled}
      isSearchable={false}
      tooltip={tooltip}
    />
  );

  return (
    <NumericInput
      id="AggregFee"
      containerClassName={inputStyles['has-select']}
      control={control}
      label={label}
      labelStyle={labelStyle}
      name={feeValueFieldName}
      data-cy={feeValueFieldName}
      suffix={suffix}
      disabled={disabled}
      tooltip={tooltip}
      rules={{
        min:
          feeType === 'percentage'
            ? { value: -100, message: 'sales_management.sub_period_errors.percentage_out_of_bounds' }
            : undefined,
        max:
          feeType === 'percentage'
            ? { value: 100, message: 'sales_management.sub_period_errors.percentage_out_of_bounds' }
            : undefined,
        validate: (value: number | null) => {
          if (value !== undefined) return !isNaN(Number(value)) || 'sales_management.sub_period_errors.value_is_nan';
        },
      }}
    />
  );
};
