import React, { useContext } from 'react';
import { Restricted } from '@components/Restricted';
import { useConsumeContracts } from '@context/contracts.context';
import { useUser } from '@context/User.context';
import { Button, DropDownButton } from '@GDM/Button';
import { SelectAllCheckBox } from '@GDM/forms/Checkbox';
import useTranslation from '@hooks/useTranslation';
import { filterRemitContracts } from '@utils/contract/filterRemit';
import fileDownload from 'js-file-download';
import { selectedContractsContext } from '../context';
import styles from '../contracts.module.scss';
import { csvExport } from '../utils/csvExport';
import { remitCsvExport } from '../utils/remitCsvExport';
import { TableSettings } from './TableSettings';

export const Actions = ({ openMassEditModal }: { openMassEditModal?: () => void }) => {
  const { t } = useTranslation();
  const user = useUser();
  const { selectedContracts, selectAllContracts, unSelectAllContracts } = useContext(selectedContractsContext);
  const { contracts } = useConsumeContracts();

  const handleExportRemitClick = () => {
    const fileName = 'Remit_Table_Contrats.csv'; // Typo is on purpose (French name)
    const filteredContracts = contracts.filter(filterRemitContracts);
    fileDownload(remitCsvExport(filteredContracts), fileName);
  };

  const handleCsvExportClick = () => {
    const fileName = 'contracts.csv';

    fileDownload(csvExport(contracts, user), fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <div className={styles.actions}>
      <div className={styles['table-actions']}>
        <SelectAllCheckBox
          name="select_all_invoices"
          onChange={(e) => (e.currentTarget.checked ? selectAllContracts() : unSelectAllContracts())}
          selectedLength={selectedContracts.length}
          total={contracts.length}
        />
        {openMassEditModal && (
          <Restricted permissions={['display:edit_salesfocus']}>
            <Button
              variant="primary-2"
              tooltip={t('sales_management.mass_update_title', { contract_nb: selectedContracts.length })}
              disabled={selectedContracts.length < 1}
              onClick={openMassEditModal}
              icon="Edit"
              size="xxs"
            />
          </Restricted>
        )}
      </div>
      <div className="d-flex">
        {!user?.limited && (
          <DropDownButton icon="Download" label="common.download" variant="primary-2" size="xxs">
            <Button variant="sub-button" text="common.csv" onClick={handleCsvExportClick} />
            <Button variant="sub-button" text="common.remit" onClick={handleExportRemitClick} />
          </DropDownButton>
        )}

        <Restricted permissions={['display:edit_salesfocus']}>
          <Button
            className="ml-2"
            variant="primary-2"
            data-cy="add-new-contract"
            href="/v2/contracts/new"
            size="xxs"
            icon="Plus"
            text="common.add_new"
          />
        </Restricted>

        <DropDownButton
          className="ml-2"
          icon="Settings"
          variant="primary-2"
          size="xxs"
          position="right"
          tooltip="contracts.table.show_or_hide_columns"
          noChevron
        >
          <div className="p-2">
            <TableSettings />
          </div>
        </DropDownButton>
      </div>
    </div>
  );
};
