import { useUser } from '@context/User.context';
import { getTimezone, getUserTimezone } from '@utils/timezones';
import type { CountryCode } from '@utils/types/countries';

export const useTimezone = (countryCode?: CountryCode) => {
  const user = useUser();

  if (countryCode) return getTimezone(countryCode);

  return getUserTimezone(user);
};
