import { createContext } from 'react';
import { RevenueContextType } from '../revenue.types';

export const revenueContext = createContext<RevenueContextType>({
  startDate: null,
  endDate: null,
  isLoading: false,
  groupByMonth: false,
  setGroupByMonth: () => {},
});
