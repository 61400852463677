import { Row, SortingFn } from '@tanstack/react-table';
import { SubUserWithAdditionalInfo } from '@utils/types/user';

export const sortUsersByStatus: SortingFn<SubUserWithAdditionalInfo> = (
  rowA: Row<SubUserWithAdditionalInfo>,
  rowB: Row<SubUserWithAdditionalInfo>,
) => {
  const rowAIsSuperAdmin = !rowA.original.init_password;
  const rowBIsSuperAdmin = !rowB.original.init_password;

  const rowAIsAdmin = rowA.original.roles?.includes('admin');
  const rowBIsAdmin = rowB.original.roles?.includes('admin');

  // Both are super admin
  if (rowAIsSuperAdmin && rowBIsSuperAdmin) {
    return rowA.original.name.localeCompare(rowB.original.name, undefined, { caseFirst: 'false' });
  }

  // One is super admin
  if (rowAIsSuperAdmin && rowAIsAdmin) return -1;
  if (rowBIsSuperAdmin && rowBIsAdmin) return 1;

  // Both are admin but not super admin
  if (rowAIsAdmin && rowBIsAdmin) {
    return rowA.original.name.localeCompare(rowB.original.name, undefined, { caseFirst: 'false' });
  }

  if (rowAIsAdmin) return -1;
  if (rowBIsAdmin) return 1;

  return rowA.original.name.localeCompare(rowB.original.name, undefined, { caseFirst: 'false' });
};

export const sortUsersByNbOfInstallations: SortingFn<SubUserWithAdditionalInfo> = (
  rowA: Row<SubUserWithAdditionalInfo>,
  rowB: Row<SubUserWithAdditionalInfo>,
) => {
  const rowAHasAllInstallations = rowA.original.access_admin_installations || rowA.original.roles?.includes('admin');
  const rowBHasAllInstallations = rowB.original.access_admin_installations || rowB.original.roles?.includes('admin');

  // Both have all installations
  if (rowAHasAllInstallations && rowBHasAllInstallations) {
    return rowA.original.name.localeCompare(rowB.original.name, undefined, { caseFirst: 'false' });
  }

  // One has all installations
  if (rowAHasAllInstallations) return 1;
  if (rowBHasAllInstallations) return -1;

  return rowA.original.installations.length - rowB.original.installations.length;
};
