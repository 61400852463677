import React from 'react';
import { Section } from '@GDM/forms';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { BooleanInput } from '../../components/Inputs/Boolean';
import { SectionHeader } from '../../components/SectionHeader';

export const Remit = ({ title, readOnly, form: { control } }: ContractFormSectionProps) => {
  return (
    <FormSectionLayout
      title={title}
      headerActions={<SectionHeader />}
      body={
        <Section className="py-4 px-3">
          <BooleanInput
            name="sent_to_remit"
            id="SentToRemit"
            control={control}
            readOnly={readOnly}
            label="sales_management.sent_to_remit"
            tooltip="sales_management.remit_tooltip"
          />
        </Section>
      }
    />
  );
};
