import dayjs from 'dayjs';
import { RevenueResolution } from '../revenue.types';

export const getColumnHeader = (period: string, interval?: RevenueResolution, index?: number): string | null => {
  const dayJSDate = dayjs(period);
  const isStartOfDay = dayJSDate.isSame(dayJSDate.startOf('day'));
  const isStartOfMonth = dayJSDate.isSame(dayJSDate.startOf('month'));

  if (interval === 'daily') return dayJSDate.format(isStartOfMonth || index === 0 ? '(YYYY-MM) ddd DD' : 'ddd DD');
  if (interval === 'hourly') return dayJSDate.format(isStartOfDay || index === 0 ? '(ddd DD) HH:ss' : 'HH:ss');
  if (interval === 'monthly') return dayJSDate.format('YYYY-MM');

  return null;
};
