import React, { ChangeEventHandler, useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Input } from '@GDM/forms';
import { patch, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { getErrorMessage } from '@utils/parsers';
import { v2_installation_path } from '@utils/routes';
import { Installation } from '@utils/types/installation';
import { validateSiret } from '@utils/validators/siret';
import { useInstallationInfo } from '../useInstallationInfo';

export const SiretModal = ({ installation }: { installation: Installation }) => {
  const { siret, setSiret } = useInstallationInfo();
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState<string | undefined>(siret ?? undefined);
  const [inputError, setInputError] = useState<string | undefined>();

  const {
    loading: isLoading,
    error,
    execute,
  } = useRequest(
    v2_installation_path(installation.name),
    ({ url }) =>
      patch<
        Installation,
        {
          installation: Partial<Installation>;
        }
      >({ url, body: { installation: { siret: localValue } } }),
    true,
  );

  const isValid = (value?: HTMLInputElement['value']): true | string => {
    return validateSiret(value, installation.owner_company_number);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLocalValue(e.target.value);

    const runValidation = isValid(e.target.value);
    if (typeof runValidation === 'string') {
      return setInputError(runValidation);
    }

    setInputError(undefined);
  };

  const onSave = async (): Promise<void | string> => {
    if (typeof isValid(localValue) === 'string') return;

    try {
      const response = await execute?.();

      if (!response) setSiret?.(localValue ?? null);

      return response;
    } catch (e) {
      const message = getErrorMessage(e);
      setInputError(message);

      return message;
    }
  };

  return (
    <>
      <DefinitionModalBody>
        <Input
          id="update-siret"
          data-cy="siret"
          hasError={!!inputError}
          value={localValue || ''}
          onChange={onChange}
          full
        />
        {installation.owner_company_number && (
          <div className="mt-2 text-small">
            {t('sales_management.owner_siren', { siren: installation.owner_company_number })}
          </div>
        )}
      </DefinitionModalBody>
      <DefinitionModalFooter
        save={() => onSave()}
        isDisabled={!!inputError}
        isLoading={isLoading}
        error={error?.message || inputError}
      />
    </>
  );
};
