import React from 'react';
import { BookNameCell } from '@components/ui/table/cell/BookNameCell';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import useTranslation from '@hooks/useTranslation';
import type { CellContext } from '@tanstack/react-table';
import { NotificationsTableRow } from '../NotificationsTable';

export const EventableCell = (props: CellContext<NotificationsTableRow, unknown>) => {
  const { t } = useTranslation();

  if (props.row.original.eventable?.book_type) {
    return <BookNameCell name={props.row.original.eventable?.name ?? ''} />;
  }

  return (
    <MeterNameCell
      installation_name={props.row.original.eventable?.name ?? t('admin.installations.all')}
      installation_energy={props.row.original.eventable?.energy ?? 'other'}
    />
  );
};
