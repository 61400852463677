import { toDate } from '@utils/formatters';
import { isValidDate } from '@utils/isValidDate';
import { Contract, ContractForm, ContractType } from '@utils/types/contract';
import { LIGHT_CONTRACT_TYPES } from '@utils/types/contract/constants';
import { parseHedgeBlocks } from './parseHedgeBlocks';
import { parseSubPeriods } from './parseSubPeriods';

export function parseContract(contract: Contract): ContractForm {
  const {
    id,
    installation,
    buyer,
    seller,
    contract_sub_periods,
    hedge_contract,
    balancing_responsible_party,
    additional_buyer,
    payment_delay,
  } = contract;

  // Set local 'light' type if installation is GLSF
  if (installation.has_meter === false && LIGHT_CONTRACT_TYPES.includes(contract.type))
    contract.type = `${contract.type}-LIGHT` as ContractType;

  const setDate = (d: string | Date): Date => (isValidDate(d) ? toDate(d) : new Date());
  const start_date = setDate(contract.start_date);
  const end_date = setDate(contract.end_date);
  const power_augmentation_start_date = contract.power_augmentation_start_date
    ? toDate(contract.power_augmentation_start_date)
    : null;
  const birthday_date = contract.birthday_date ? setDate(contract.birthday_date) : null;
  let financing: ContractForm['financing'] = 'none';

  if (contract.financement_participatif) financing = 'financement_participatif';
  if (contract.investissement_participatif) financing = 'investissement_participatif';

  const hedgeBlocks = parseHedgeBlocks(hedge_contract?.hedge_sub_periods);

  return {
    ...contract,
    id,
    birthday_date,
    end_date,
    financing,
    installation_uuid: installation.uuid,
    buyer_id: buyer?.id,
    seller_id: seller?.id,
    additional_buyer_id: additional_buyer?.id,
    balancing_responsible_party_id: balancing_responsible_party?.id,
    start_date,
    contract_sub_periods_attributes: parseSubPeriods(contract_sub_periods),
    ...(hedgeBlocks ? { hedge_blocks_attributes: hedgeBlocks } : null),
    power_augmentation:
      Boolean(contract.power_augmentation_percentage) && Boolean(contract.power_augmentation_start_date),
    power_augmentation_start_date,
    energy: contract.installation?.energy,
    payment_delay_attributes: payment_delay,
    installation_attributes: {
      grid_connection_type: installation?.grid_connection_type,
      grid_access_type: installation?.grid_access_type,
    },
  };
}
