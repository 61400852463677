import React from 'react';
import { Col, Row } from '@GDM/layout';
import type { EnergyType } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries/country-code';
import { DataPeriod } from '@utils/types/dataPeriod';
import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import { TimeSeries } from '@utils/types/timeSeries';
import { useActiveEnergy } from './hooks/useActiveEnergy';
import { useProductionBenchmark } from './hooks/useProductionBenchmark';
import { useReactiveEnergy } from './hooks/useReactiveEnergy';
import { useTensionAggregation } from './hooks/useTensionAggregation';
import { DateRange, ProductionTimeSeriesRecord } from './production.types';
import { AutoConsumptionWidget } from './Widgets/AutoConsumptionWidget';
import { EnergyProductionBenchmarkWidget } from './Widgets/BenchmarkWidget';
import { EnergyProductionWidget } from './Widgets/EnergyProductionWidget';
import { PowerWidget } from './Widgets/PowerWidget';
import { Pp2Widget } from './Widgets/Pp2Widget';
import { ReactivePowerMetrics } from './Widgets/ReactivePowerMetrics';
import { TensionWidget } from './Widgets/TensionWidget';

export type WidgetsProps = {
  country?: CountryCode;
  isBook: boolean;
  dataPeriod: DataPeriod;
  energyType?: EnergyType;
  autoConsoAppears: boolean;
  chartExtremes: [number, number];
  isLoading?: boolean;
  selectedDateRange: DateRange;
  timeSeries: Partial<ProductionTimeSeriesRecord>;
  inverterAll?: TimeSeries;
  constraint?: ReactiveConstraint;
  identifier: string;
  showProductionMinMaxAverage: boolean;
  qFuPenalty: number | null;
  maxPower?: number;
  reactivePenaltyPrice?: number | null;
};

export const Widgets = ({
  timeSeries: {
    potentialActiveEnergyProduction,
    rawTensionProduction,
    rawActiveEnergyProduction,
    rawActiveEnergyConsumption,
    rawReactivePositiveEnergyProduction,
    rawReactiveNegativeEnergyProduction,
  },
  isBook,
  dataPeriod,
  energyType,
  inverterAll,
  autoConsoAppears,
  chartExtremes,
  selectedDateRange,
  constraint,
  identifier,
  showProductionMinMaxAverage,
  qFuPenalty,
  maxPower,
  reactivePenaltyPrice,
  country,
}: WidgetsProps) => {
  const tension = useTensionAggregation(rawTensionProduction, chartExtremes);

  const {
    aggregations: { injectedActive, withdrawnActive },
    autoConso,
    autoProd,
  } = useActiveEnergy({
    timeSeries: { rawActiveEnergyProduction, rawActiveEnergyConsumption },
    inverterAll,
    dataPeriod,
    chartExtremes,
  });

  const { totalProdEnergyPlanned, totalPerformance, totalPerformanceLoss, showPotentialPower } = useProductionBenchmark(
    {
      potentialActiveEnergyProduction,
      dataPeriod,
      chartExtremes,
      energyType,
      totalProdEnergy: injectedActive?.energy,
      country,
    },
  );

  const {
    aggregations: { injectedReactive, withdrawnReactive },
    reactiveEnergyBalance,
    tanPhi,
    areReactiveConstraintsRespected,
    tanPhiPenalty,
  } = useReactiveEnergy({
    timeSeries: { rawReactivePositiveEnergyProduction, rawReactiveNegativeEnergyProduction },
    dataPeriod,
    chartExtremes,
    injectedActiveEnergy: injectedActive?.energy,
    constraint,
    reactivePenaltyPrice,
  });

  const reactiveExist = Boolean(injectedReactive || withdrawnReactive);

  const reactivePenalties: Record<string, number | null | undefined> = {
    qfu: qFuPenalty,
    tan_phi: tanPhiPenalty,
  };

  // At the completion of the EPIC "Engie v2.0 - Reactive penalties" (link to the epic below)
  // Only French installation have reactive penalties
  // https://www.notion.so/streemeu/Engie-v2-0-Reactive-penalties-b82085d1a675489494c7e04c1aed032f
  const reactivePenalty = country === 'FR' ? reactivePenalties[constraint?.reactive_type || ''] : undefined;

  return (
    <>
      <Row>
        <Col className="d-flex">
          <EnergyProductionWidget
            total={injectedActive?.energy}
            consumption={withdrawnActive?.energy}
            average={injectedActive?.average}
          />
        </Col>

        {reactiveExist && (
          <Col className="d-flex">
            <ReactivePowerMetrics
              reactiveEnergyBalance={reactiveEnergyBalance}
              tanPhi={tanPhi}
              areReactiveConstraintsRespected={areReactiveConstraintsRespected}
              maxPower={maxPower}
              reactivePenalty={reactivePenalty}
              reactiveType={constraint?.reactive_type}
              injectionConstraint={constraint?.injection}
              tanphiMin={constraint?.tanphi_min}
              tanphiMax={constraint?.tanphi_max}
              qMin={constraint?.q_min}
              qMax={constraint?.q_max}
            />
          </Col>
        )}

        {showPotentialPower && (
          <Col className="d-flex">
            <EnergyProductionBenchmarkWidget
              energyType={energyType}
              totalProdEnergyPlanned={totalProdEnergyPlanned}
              totalPerformance={totalPerformance}
              totalPerformanceLoss={totalPerformanceLoss}
              dataPeriod={dataPeriod}
              isBook={isBook}
            />
          </Col>
        )}

        {autoConsoAppears && (
          <Col className="d-flex">
            <AutoConsumptionWidget autoConso={autoConso} autoProd={autoProd} />
          </Col>
        )}

        <Pp2Widget selectedDateRange={selectedDateRange} className="d-flex" identifier={identifier} isBook={isBook} />
      </Row>
      {showProductionMinMaxAverage && (
        <div className="mt-4">
          <Row>
            {(injectedActive || withdrawnActive) && (
              <Col>
                <PowerWidget
                  title="monitoring.export.active_power"
                  injection={injectedActive}
                  withdrawal={withdrawnActive}
                />
              </Col>
            )}
            {(injectedReactive || withdrawnReactive) && (
              <Col>
                <PowerWidget
                  title="monitoring.export.reactive_power"
                  injection={injectedReactive}
                  withdrawal={withdrawnReactive}
                  unit="kVAR"
                />
              </Col>
            )}
            {tension && (
              <Col>
                <TensionWidget {...tension} />
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
