import React, { CSSProperties } from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './help-box.module.scss';

export type HelpBoxProps = {
  children?: React.ReactNode;
  className?: string;
  dismissible?: boolean;
  icon?: IconNames;
  iconColor?: 'primary-1' | 'primary-2' | 'secondary' | 'text-color';
  onDismiss?: () => void;
  style?: CSSProperties;
  text?: string;
  title?: string;
  variant?: 'light' | 'dark';
};

export const HelpBox = ({
  children,
  className,
  dismissible,
  iconColor,
  onDismiss,
  text,
  title,
  icon,
  style,
  variant,
}: HelpBoxProps) => {
  const { t } = useTranslation();

  if (!children && !text) return null;

  const iconStyle = { color: `var(--${iconColor || 'primary-1'})` };
  const iconName: IconNames = icon || 'HelpCircle';

  return (
    <aside
      className={classNames(styles['help-box'], styles[variant || 'dark'], !title && styles['no-title'], className)}
      style={style}
    >
      <div className={styles.header}>
        <Icon name={iconName} size={14} className={classNames('mr-2', styles.icon)} style={iconStyle} />
        {title && (
          <div className={styles.title} title={t(title)}>
            {t(title)}
          </div>
        )}
      </div>
      <div className={styles.content} dangerouslySetInnerHTML={text ? { __html: t(text) } : undefined}>
        {children}
      </div>
      {dismissible && <Icon className={styles['dismiss-button']} size={20} name="X" tabIndex={0} onClick={onDismiss} />}
    </aside>
  );
};
