import React from 'react';
import { NoWrapHeadCell, NumberCell } from '@GDM/Table';
import { DateCell } from '@GDM/Table/Cells';
import { ColumnDef } from '@tanstack/react-table';
import { Simulation } from '@utils/types/simulation';
import ConfidenceCell from '../table/cell/ConfidenceCell';
import { MarketDeviationFactorCell } from '../table/cell/MarketDeviationFactorCell';
import SelectRowCell from '../table/cell/SelectRowCell';
import { SimulationInstallationNameCell } from '../table/cell/SimulationInstallationNameCell';
import { SimulationNameCell } from '../table/cell/SimulationNameCell';
import SimulationStatusCell from '../table/cell/SimulationStatusCell';

const getColumns = (simulations: Simulation[], fullMerchant?: boolean, isBook?: boolean): ColumnDef<Simulation>[] => {
  const columns: ColumnDef<Simulation>[] = [
    { header: 'common.project', accessorKey: 'installation_name', cell: SimulationInstallationNameCell },
    { header: 'Simulation', accessorKey: 'name', cell: SimulationNameCell },
    {
      header: () => <NoWrapHeadCell tooltip="kW" label="common.power" />,
      accessorKey: 'maximum_power',
      cell: ({ row: { original } }) => <NumberCell value={original.maximum_power} />,
    },
    {
      header: fullMerchant ? 'pricing.local_capture_price' : 'pricing.project.market_value_deviation',
      id: 'market_deviation_factor',
      accessorFn: (originalRow: Simulation) =>
        fullMerchant ? originalRow?.results?.local_capture_factors?.q50 : originalRow?.results?.deviation_factors?.q50,
      cell: (props) => <MarketDeviationFactorCell simulation={props.row.original} fullMerchant={fullMerchant} />,
    },
    {
      id: 'confidence',
      header: () => <NoWrapHeadCell label="pricing.portfolio.confidence_interval_50" />,
      accessorFn: (originalRow: Simulation) => originalRow?.results?.deviation?.q75,
      cell: ({ row }) => <ConfidenceCell row={row} simulations={simulations} isFullMerchant={fullMerchant} />,
      enableSorting: false,
    },
    {
      header: 'common.status_label',
      accessorKey: 'status',
      cell: ({ row: { original } }) => <SimulationStatusCell value={original.status} />,
    },
    { header: 'common.date', accessorKey: 'created_at', cell: DateCell },
  ];

  if (!isBook) {
    columns.unshift({ header: '', id: 'actions', cell: SelectRowCell /*disableSortBy: true*/ });
  }

  return columns;
};

export default getColumns;
