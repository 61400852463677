import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ContractForm } from '@utils/types/contract';
import { Control } from 'react-hook-form';
import { EnumInput } from '../../../components/Inputs/Enum';

export const ParticipatoryFinancing = ({
  control,
  readOnly,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
}) => {
  return (
    <div className={sectionStyles.row}>
      <EnumInput
        id="ParticipativeFinancing"
        name="financing"
        label="sales_management.participatory_financing"
        data-cy="financing-investissement_participatif"
        control={control}
        options={[
          { value: 'financement_participatif', label: 'sales_management.financement_participatif' },
          { value: 'investissement_participatif', label: 'sales_management.investissement_participatif' },
          { value: 'none', label: 'sales_management.no_participatif' },
        ]}
        readOnly={readOnly}
      />
    </div>
  );
};
