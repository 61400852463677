import { Locale } from '@utils/types/common-types';

export const LOCALES: { [key in Locale]?: Highcharts.LangOptions } = {
  fr: {
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    shortMonths: ['Janv.', 'Fév.', 'Mars', 'Avr.', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
    decimalPoint: ',',
    thousandsSep: ' ',
    invalidDate: 'Format de date invalide',
    loading: 'Chargement',
    noData: 'Aucune donnée disponible',
    resetZoom: 'Réinitialiser zoom',
  },
  en: {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    decimalPoint: '.',
    thousandsSep: ',',
    invalidDate: 'Invalid date format',
    loading: 'Loading',
    noData: 'No data available',
    resetZoom: 'Reset zoom',
  },
  es: {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    decimalPoint: ',',
    thousandsSep: '.',
    invalidDate: 'Formato de fecha inválido',
    loading: 'Cargando',
    noData: 'No hay datos disponibles',
    resetZoom: 'Restablecer zoom',
  },
  it: {
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    shortMonths: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
    decimalPoint: ',',
    thousandsSep: '.',
    invalidDate: 'Formato data non valido',
    loading: 'Caricamento',
    noData: 'Nessun dato disponibile',
    resetZoom: 'Reimposta zoom',
  },
};
