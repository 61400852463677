import { updateUserNotificationsSettings } from '@api/userNotificationsSetings';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { UserNotificationsSettings } from '@utils/types/userNotificationsSettings';

export const useUserNotificationsSettingsUpdateMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['updateNotification'],
    mutationFn: updateUserNotificationsSettings,
    onSuccess: (data) => {
      queryClient.setQueryData(['notifications'], (previousNotifications: UserNotificationsSettings[]) => {
        return previousNotifications.map((notification) => (notification.id === data.id ? data : notification));
      });
    },
  });

  return mutation;
};
