import React from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import { Control, useFieldArray } from 'react-hook-form';
import type { NotificationFormData } from './NotificationFormModal';

export const NotificationEmailsInput = ({ control }: { control: Control<NotificationFormData> }) => {
  const arrayField = useFieldArray({ control, name: 'emails', keyName: 'emailId' });

  return (
    <div>
      <div className="d-flex flex-column gap-3">
        {arrayField.fields.map((field, index) => (
          <ControlledInput
            control={control}
            name={`emails.${index}.email`}
            key={field.emailId}
            label={index === 0 ? 'common.email' : undefined}
            full
            type="email"
            size="lg"
            rules={{
              required: true,
              validate: (value) => {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (typeof value !== 'string' || !emailRegex.test(value)) {
                  return 'common.invalid_email';
                }

                return true;
              },
            }}
          />
        ))}
      </div>
    </div>
  );
};
