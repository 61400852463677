import React, { useMemo } from 'react';
import { CurrencyCell, HeadCell, Table, TableBody, TableHead } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { Currency } from '@utils/types/currency';
import { MonthlyMarketResultAggregator } from '@utils/types/m0';
import dayjs from 'dayjs';
import { useFormContext } from 'react-hook-form';

type Props = {
  aggregatorsMarketResults: MonthlyMarketResultAggregator[] | null;
  solar: boolean;
  currency: Currency;
};

export const M0AggregHistory = ({ aggregatorsMarketResults, solar, currency }: Props) => {
  const { watch } = useFormContext();
  const { t } = useTranslation();
  const aggregatorId = watch('aggregator_id');
  const marketResults = useMemo(
    () =>
      aggregatorsMarketResults
        ?.filter((marketResult) => marketResult?.market_player?.id === aggregatorId)
        .filter((marketResult) =>
          solar ? marketResult?.price_type === 'M0_SOLAR' : marketResult?.price_type === 'M0_WIND',
        )
        .sort((a, b) => (a.start_date > b.start_date ? 1 : -1)) || [],
    [aggregatorId, aggregatorsMarketResults, solar],
  );

  const columns = useMemo<ColumnDef<MonthlyMarketResultAggregator>[]>(
    () => [
      {
        header: () => <HeadCell label="common.date" />,
        accessorKey: 'start_date',
        cell: (info) => <>{dayjs(info.getValue<MonthlyMarketResultAggregator['start_date']>()).format('YYYY-MM')}</>,
      },
      {
        header: () => <div className="ta:right">{t('pricing.market_value')}</div>,
        accessorKey: 'value',
        cell: (info) => (
          <CurrencyCell value={info.getValue<MonthlyMarketResultAggregator['value']>()} showUnit currency={currency} />
        ),
      },
    ],
    [currency, t],
  );

  const table = useReactTable({
    columns,
    data: marketResults,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!aggregatorId || !marketResults || marketResults.length < 1) return null;

  return (
    <div>
      <div className="fw:700 mt-3">{t('common.history')}</div>
      <Table className="mt-2">
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>
    </div>
  );
};
