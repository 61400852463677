import dayjs, { type Dayjs } from 'dayjs';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const formatDate = (date: Date | Dayjs | string, format: string = DEFAULT_DATE_FORMAT): string | null => {
  if (dayjs(date).isValid()) return dayjs(date).format(format);

  return null;
};

export const parseDate = (dateString: string, format: string = DEFAULT_DATE_FORMAT): Date | null => {
  if (dayjs(dateString, format).isValid()) return dayjs(dateString, format).toDate();

  return null;
};
