import React from 'react';
import { Badge } from '@GDM/Badge';
import { NumberCell } from '@GDM/Table';
import { ColumnDef } from '@tanstack/react-table';
import { Subscription } from '@utils/types/subscription';
import { FREQUENCY_VARIANT_MAPPING, MODULE_VARIANT_MAPPING, getPriceUnit } from '../helpers';
import { ActionsCell } from './ActionsCell';

export const SubscriptionColumns = () => {
  const columns: ColumnDef<Subscription>[] = [
    {
      header: 'common.email',
      accessorKey: 'email',
    },
    {
      header: 'subscriptions.module_name',
      accessorKey: 'product',
      cell: ({ getValue }) => (
        <Badge
          label={getValue<Subscription['product']>()}
          variant={MODULE_VARIANT_MAPPING[getValue<Subscription['product']>()]}
          className="tt:capitalize"
        />
      ),
    },
    {
      header: 'common.start_date',
      accessorKey: 'start_date',
    },
    {
      header: 'subscriptions.frequency',
      accessorKey: 'time_period',
      cell: ({ getValue }) => (
        <Badge
          label={getValue<Subscription['time_period']>()}
          variant={FREQUENCY_VARIANT_MAPPING[getValue<Subscription['time_period']>()]}
          className="tt:capitalize"
        />
      ),
    },
    {
      header: 'subscriptions.max_nb_meters',
      accessorKey: 'max_nb_meters',
      cell: ({ getValue }) => <NumberCell value={getValue<Subscription['max_nb_meters']>()} />,
    },
    {
      header: 'subscriptions.price_fixed',
      accessorKey: 'price_fixed',
      cell: ({ row: { original } }) => (
        <NumberCell value={original.price_fixed} unit={getPriceUnit(original)} fractionDigits={2} />
      ),
    },
    {
      header: 'subscriptions.price_variable',
      accessorKey: 'price_variable',
      cell: ({ row: { original } }) => (
        <NumberCell value={original.price_variable} unit={getPriceUnit(original)} fractionDigits={2} />
      ),
    },
    {
      header: 'subscriptions.price_variable_2',
      accessorKey: 'price_variable_2',
      cell: ({ row: { original } }) => (
        <NumberCell value={original.price_variable_2} unit={getPriceUnit(original)} fractionDigits={2} />
      ),
    },
    {
      header: 'common.billing_entity_name',
      accessorFn: (row) =>
        row.company_billing_detail?.payer?.long_name || row.company_billing_detail?.payer?.short_name,
    },
    {
      id: 'actions',
      cell: ActionsCell,
    },
  ];

  return columns;
};
