import { Option } from '@utils/types/common-types';

/**
 `normalize` splits the base character and its composing accent and `replace` removes the accent part of the string;
 */
export function filterOption<T extends string>(option: Option<T>, inputValue: string): boolean {
  return `${option.label}`
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLocaleLowerCase()
    .includes(
      inputValue
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase(),
    );
}
