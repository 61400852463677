import React from 'react';
import styles from './page.module.scss';

export const PageWrapper = ({
  children,
  fixedHeightContainer,
}: React.PropsWithChildren<{ fixedHeightContainer: boolean }>) => {
  if (fixedHeightContainer)
    return <div className={fixedHeightContainer && styles['fixed-height-container']}>{children}</div>;

  return <>{children}</>;
};
