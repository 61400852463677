import React, { useMemo } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button/Button';
import { styles } from '@GDM/Filters';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import { useQuery } from '@tanstack/react-query';
import { AccessorKeyColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import fetchWrapper from '@utils/fetch';
import { v2_green_star_counter_parts_path } from '@utils/routes';
import { UseFormReturn } from 'react-hook-form';
import { useGreenStarGroups } from './queries';
import { RiskAnalysisCounterpart, RiskAnalysisCounterpartsFilter } from './types';

export const Counterparts = ({ form: { control, watch } }: { form: UseFormReturn<RiskAnalysisCounterpartsFilter> }) => {
  const groupsQuery = useGreenStarGroups();

  const grappeOptions =
    groupsQuery.data?.grappes.map((grappe) => ({
      value: grappe,
      label: grappe,
    })) || [];

  const holdingOptions =
    groupsQuery.data?.holdings.map((holding) => ({
      value: holding,
      label: holding,
    })) || [];

  const spvOptions =
    groupsQuery.data?.spvs.map((spv) => ({
      value: spv,
      label: spv,
    })) || [];

  const { spv, grappe, holding, apply_ownership_percentage } = watch();

  const counterpartsQuery = useQuery({
    queryKey: ['greenstar_countereparts', spv?.join(), grappe?.join(), holding?.join(), apply_ownership_percentage],
    queryFn: async (): Promise<RiskAnalysisCounterpart[]> => {
      const options = { spv, grappe, holding, apply_ownership_percentage };
      const response = await fetchWrapper(v2_green_star_counter_parts_path(options));

      return response.json();
    },
  });

  return (
    <div className="p-4">
      <form className="d-flex justify-content-between mb-4" /*onSubmit={handleSubmit(onSubmit)}*/>
        <div className="d-flex gap-4">
          <ControlledSelect
            isMulti
            name="spv"
            control={control}
            options={spvOptions}
            placeholder="sales_management.owner"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="grappe"
            control={control}
            options={grappeOptions}
            placeholder="risk_analysis.grappe"
            className={styles.select}
          />
          <ControlledSelect
            isMulti
            name="holding"
            control={control}
            options={holdingOptions}
            placeholder="risk_analysis.holding"
            className={styles.select}
          />
          <ControlledSelect
            name="apply_ownership_percentage"
            control={control}
            options={[
              { label: '100 %', value: false },
              { label: 'risk_analysis.consolidated', value: true },
            ]}
            placeholder="risk_analysis.vision"
            className={styles.select}
            defaultValue={false}
          />
        </div>
        <div className="d-flex">
          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
          />
        </div>
      </form>
      <CounterPartsTable rows={counterpartsQuery.data} isLoading={counterpartsQuery.isPending} />
    </div>
  );
};

const CounterPartsTable = ({ rows = [], isLoading }: { rows?: RiskAnalysisCounterpart[]; isLoading: boolean }) => {
  const columns = useMemo<AccessorKeyColumnDef<RiskAnalysisCounterpart>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'risk_analysis.counterpart',
        sortDescFirst: true,
      },
      { accessorKey: 'debt', header: 'risk_analysis.unpaid_to_date', sortDescFirst: true },
      { accessorKey: 'termination_period_in_month', header: 'risk_analysis.termination', sortDescFirst: true },
      { accessorKey: 'exposure', header: 'risk_analysis.exposure', sortDescFirst: true },
      { accessorKey: 'collateral', header: 'risk_analysis.collateral', sortDescFirst: true },
      { accessorKey: 'guarantee', header: 'risk_analysis.guarantee', sortDescFirst: true },
      { accessorKey: 'exposure_without_debt', header: 'risk_analysis.residual_exposing', sortDescFirst: true },
      { accessorKey: 'rating', header: 'risk_analysis.rating', sortDescFirst: true },
    ],
    [],
  );

  const table = useReactTable({
    columns,
    data: rows,
    // https://github.com/TanStack/table/issues/4289#issuecomment-2052735066
    sortDescFirst: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHead table={table} />
      <TableBody table={table} isLoading={isLoading} />
    </Table>
  );
};
