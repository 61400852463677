import React from 'react';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './table-label.module.scss';

export const TableLabel = ({
  renderInput,
  label,
  name,
}: {
  renderInput: (className: string) => React.ReactNode;
  label: string;
  name: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.prefix, 'fw:700')}>
        <label className="font-bold" htmlFor={name} dangerouslySetInnerHTML={{ __html: t(label) }} />
      </div>
      {renderInput(styles.input)}
    </div>
  );
};
