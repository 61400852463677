import React from 'react';
import { Checkbox, CheckboxProps } from '@GDM/forms';
import { FieldValues, Path, Control, type PathValue, useController } from 'react-hook-form';

export default function ControlledCheckbox<T extends FieldValues>({
  name,
  control,
  defaultValue,
  shouldUnregister,
  ...checkboxProps
}: Omit<CheckboxProps, 'onChange' | 'checked' | 'defaultValue'> & {
  name: Path<T>;
  control: Control<T>;
  defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  shouldUnregister?: boolean;
}) {
  const { field } = useController({ name, control, shouldUnregister, defaultValue, disabled: checkboxProps.disabled });

  return <Checkbox {...checkboxProps} onChange={field.onChange} checked={field.value} />;
}
