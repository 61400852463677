import React from 'react';
import { Definition, DefinitionList } from '@GDM/DefinitionList';
import { Col, Row } from '@GDM/layout';
import { Map } from '@GDM/Map';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { COUNTRIES } from '@utils/constants/countries';
import { Installation } from '@utils/types/installation';
import { BookMap } from 'pages/ui/shared/maps';
import styles from './installation-informations.module.scss';
import { InstallationChangeModal } from './InstallationChangeModal';

export const InstallationInformations = () => {
  const { installation } = useProjectContext();

  if (!installation) {
    return null;
  }

  const hasNoCoordinates = !installation.latitude && !installation.longitude;
  const country = installation.country || 'FR';
  const { lat, lng } = COUNTRIES[country].coordinates;

  return (
    <Row>
      {!hasNoCoordinates && (
        <Col md={6} className={styles['map-container']}>
          <Map center={lat && lng ? [lat, lng] : [0, 0]}>
            <BookMap installations={[installation as Installation]} />
          </Map>
        </Col>
      )}
      <Col md={6} className={styles['installation-list']}>
        <DefinitionList>
          <Definition label="common.energy" value={`energy.${installation.energy}`} />
          <Definition
            label="common.latitude"
            value={<ValueWithUnit unstyled value={installation.latitude} unit="degree" />}
          />
          <Definition
            label="common.longitude"
            value={<ValueWithUnit unstyled value={installation.longitude} unit="degree" />}
          />
          <Definition
            label="pricing.project.monitored_installation"
            value={installation?.name || ''}
            modal={{
              title: 'pricing.project.change_monitored_installation',
              node: <InstallationChangeModal installationId={installation?.uuid} />,
              permission: 'display:edit_pricing',
            }}
          />
        </DefinitionList>
      </Col>
    </Row>
  );
};
