import React from 'react';
import { Input } from '@GDM/forms';
import classnames from 'classnames';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { InputType } from 'reactstrap/types/lib/Input';
import { UserFormType } from './types';

type Props = {
  name: keyof UserFormType;
  disabled?: boolean;
  rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  type?: InputType;
  'data-cy'?: string;
};

const ControlledInput = ({ name, disabled, rules, type, 'data-cy': dataCy }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const className = classnames({ 'is-invalid': !!errors?.[name]?.type, 'react-check': type === 'checkbox' });

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value as string}
          defaultChecked={type === 'checkbox' ? (field.value as boolean) : false}
          type={type}
          disabled={disabled}
          id={name}
          className={className}
          data-cy={dataCy}
        />
      )}
    />
  );
};

ControlledInput.defaultProps = {
  type: 'text',
};

export default ControlledInput;
