import React from 'react';
import { Progress } from '@GDM/Progress';
import { CellContext } from '@tanstack/react-table';
import { DashboardViewAllData } from '../../../portfolio.definitions';

export const PerformanceCell = ({ row }: React.PropsWithChildren<CellContext<DashboardViewAllData, unknown>>) => {
  const value = row.original.performance;
  if (!value) return null;

  return <Progress value={value} dangerThreshold={85} />;
};
