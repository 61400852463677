import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import styles from './page-actions.module.scss';

export const PageActions = ({
  submitDraft,
  readOnly,
  conractUuid,
  showSaveDraft = false,
}: {
  submitDraft: () => void;
  readOnly: boolean;
  conractUuid: string | undefined;
  showSaveDraft?: boolean;
}) => {
  return (
    <div className={styles.wrapper}>
      <Button
        text="common.back"
        variant="link"
        size="sm"
        href="/v2/contracts"
        className="mr-3"
        data-cy="back-to-contracts"
      />
      {!readOnly && (
        <>
          {showSaveDraft && (
            <Button
              text="common.save_draft"
              variant="outline"
              size="sm"
              data-cy="save-draft-contract"
              onClick={() => {
                submitDraft();
              }}
            />
          )}
          <Button text="common.save" variant="primary-2" size="sm" data-cy="submit-contract" type="submit" />
        </>
      )}

      {readOnly && (
        <Restricted permissions={['display:edit_salesfocus']}>
          <Button text="common.edit" variant="primary-2" size="sm" href={`/v2/contracts/${conractUuid}/edit`} />
        </Restricted>
      )}
    </div>
  );
};
