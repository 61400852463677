import React, { useMemo, useState, useCallback } from 'react';
import { useSubUsersContext } from '@context/admin/users/subusers.context';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Modal } from '@GDM/Modal';
import { Table, TableBody, TableHead } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import axiosInstance from '@utils/axiosInstance';
import { SubUser, SubUserWithAdditionalInfo } from '@utils/types/user';
import { PasswordCell } from './PasswordCell';
import { sortUsersByNbOfInstallations, sortUsersByStatus } from './utils';

type Props = { onModalOpen: (subUser: SubUser) => void; isLoading?: boolean };

export const UsersTable = ({ onModalOpen, isLoading }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [selectedSubUser, setSelectedSubUser] = useState<SubUser | null>(null);
  const { subUsersWithAdditionalInfo, deleteSubUser, isLoading: isSubUsersLoading } = useSubUsersContext();
  const toggle = useCallback(() => setVisible(!visible), [visible]);

  const { columns, columnVisibility } = useColumns(toggle, setSelectedSubUser, onModalOpen);

  const table = useReactTable({
    columns,
    data: subUsersWithAdditionalInfo,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    initialState: { sorting: [{ id: 'name', desc: false }] },
    state: { columnVisibility },
  });
  const onDeleteUser = useCallback(() => {
    if (selectedSubUser?.uuid) {
      axiosInstance().delete(`sub_client_admin/${selectedSubUser.uuid}`);
      deleteSubUser(selectedSubUser.uuid);
      setSelectedSubUser(null);
      toggle();
    }
  }, [toggle, selectedSubUser, deleteSubUser]);

  return (
    <div>
      <Modal
        isOpen={visible}
        toggle={toggle}
        size="sm"
        submitAction={onDeleteUser}
        saveButtonText="common.delete"
        data-cy="delete-user-modal"
      >
        {t('admin.sub_client.delete_sub_account')} <b style={{ wordBreak: 'break-all' }}>{selectedSubUser?.email}</b> ?
      </Modal>

      <Table className="mt-3" hasActions>
        <TableHead table={table} />
        <TableBody table={table} data-cy="users-table" isLoading={isLoading || isSubUsersLoading} />
      </Table>
    </div>
  );
};

const useColumns = (
  toggle: () => void,
  setSelectedSubUser: (subUser: SubUser) => void,
  onModalOpen: (subUser: SubUser) => void,
) => {
  const user = useUser();
  const { t } = useTranslation();

  const openDeleteModal = useCallback(
    (subUser: SubUser) => {
      setSelectedSubUser(subUser);
      toggle();
    },
    [toggle, setSelectedSubUser],
  );

  const columnVisibility = useMemo(() => {
    return { init_password: !user.isAuthorized(['display:hide_password']) };
  }, [user]);

  const columns = useMemo<ColumnDef<SubUserWithAdditionalInfo>[]>(() => {
    const cols = [
      {
        header: 'common.name',
        accessorKey: 'name',
        cell: (props) => {
          const { name, roles, limited } = props.row.original;

          let displayName = name;

          if (roles?.includes('admin')) displayName = `${name} (admin)`;
          if (limited) displayName = `${name} (${t('common.limited_access')})`;

          return <>{displayName}</>;
        },
        sortingFn: sortUsersByStatus,
      },
      { header: 'Email', accessorKey: 'email' },
      {
        header: 'admin.books.n_installations',
        accessorKey: 'installations',
        cell: ({ row: { original } }) => {
          const numberOfInstallations = original.installations.length > 0 ? original.installations.length : '-';

          return (
            <>
              {original.access_admin_installations || original.roles?.includes('admin')
                ? t('admin.installations.all')
                : numberOfInstallations}
            </>
          );
        },
        sortingFn: sortUsersByNbOfInstallations,
      },
      {
        header: 'common.password',
        accessorKey: 'init_password',
        enableSorting: false,
        cell: (info) => <PasswordCell value={info.getValue<string>()} />,
      },
      {
        id: 'actions',
        cell: (props) => (
          <>
            {!props.row.original.roles?.includes('admin') && (
              <>
                <Button
                  floating
                  variant="primary-2"
                  onClick={() => onModalOpen(props.row.original as SubUser)}
                  data-cy={`edit-user ${props.cell.row.original.email} ${props.row.original.limited ? 'limited' : ''}`}
                  icon="Edit3"
                />
                <Button
                  floating
                  variant="secondary"
                  onClick={() => openDeleteModal(props.row.original as SubUser)}
                  color="danger"
                  data-cy={`delete-user ${props.cell.row.original.email}`}
                  icon="Trash2"
                />
              </>
            )}
          </>
        ),
      },
    ] as const satisfies ColumnDef<SubUserWithAdditionalInfo>[];

    return cols;
  }, [onModalOpen, t, openDeleteModal]);

  return { columns, columnVisibility };
};
