import { EnergyType } from '@utils/types/common-types';
import Installation from '@utils/types/installation';
import { UseFormReturn } from 'react-hook-form';
import { getAllFields } from './getAllFields';
import { getSections } from './getSections';
import { isFieldRequired } from './isFieldRequired';
import { SimulationFormField, SimulationForm } from './simulations.types';

export const updateSessionsProgress = (
  energy: EnergyType,
  installation: Installation,
  getValues: UseFormReturn<SimulationForm>['getValues'],
  updateProgress: (stepId: string, percentage: number) => void,
  markAsValid: (stepId: string, valid: boolean) => void,
) => {
  const sectionsFields = getSections(energy);
  const sections = new Set(Array.from(sectionsFields.values()));
  const allFields = getAllFields(energy);

  sections.forEach((section) => {
    const sectionFields = Array.from(sectionsFields.entries()).filter(([, sectionName]) => sectionName === section);

    if (!sectionFields.length) return;

    const requiredFields = sectionFields
      .map(([fieldName]) => {
        const field = allFields.find((field) => field.name === fieldName);

        if (!field || !isFieldRequired(field, getValues, installation)) return;

        return field;
      })
      .filter((field): field is SimulationFormField => !!field);

    let requiredFieldsName = requiredFields.map((field) => field.name);

    /**
     * If the section is constraints, it's an array, therefore we need to check that for each element of the array
     * all the required fields are filled in.
     * This part will append all the required fields to the requiredFieldsName array.
     */
    if (section === 'constraints') {
      // append all the wind bounds fields to the requiredFieldsName
      const ppaWindBounds = getValues('ppa_wind_bounds_attributes');

      if (ppaWindBounds.length > 0) {
        const windBoundsFields = ppaWindBounds.flatMap((_, index) => {
          if (index === 0) return; // index 0 is already included in the requiredFieldsName

          const ppaWindBoundsKeys = Object.keys(ppaWindBounds[index]) as Array<keyof typeof ppaWindBounds[0]>;

          return ppaWindBoundsKeys.map((key) => {
            const windBoundField = allFields.find((field) => field.name === `ppa_wind_bounds_attributes.0.${key}`);

            if (!windBoundField || !isFieldRequired(windBoundField, getValues, installation)) return;

            return `ppa_wind_bounds_attributes.${index}.${key}` as SimulationFormField['name'];
          });
        });

        const windBoundsRequiredFields = windBoundsFields.filter(
          (field): field is SimulationFormField['name'] => !!field,
        );

        requiredFieldsName.push(...windBoundsRequiredFields);
      } else {
        requiredFieldsName = requiredFieldsName.filter((field) => !field.includes('ppa_wind_bounds_attributes'));
      }
    }

    const values = getValues(requiredFieldsName);

    const validValues = values.filter((value) => {
      if (value == null || value == undefined) return false;

      if (typeof value === 'object') return Object.values(value).every(Boolean);

      return !!value || value === 0;
    });

    const percentage = requiredFieldsName.length > 0 ? (validValues.length / requiredFieldsName.length) * 100 : 100;

    updateProgress(section, percentage);
    markAsValid(section, percentage === 100);
  });
};
