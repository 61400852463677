import type { OwnerFormField, StepKey } from '../ownerForm.types';

export const FIELD_SECTIONS = new Map<OwnerFormField['name'], StepKey>([
  ['country', 'owner'],
  ['company_number', 'owner'],
  ['short_name', 'owner'],
  ['company_number', 'owner'],
  ['short_name', 'owner'],
  ['erp_code', 'owner'],
  ['bank', 'owner'],
  ['iban', 'owner'],
  ['swift_bic', 'owner'],
  ['invoicing_emails', 'owner'],
  ['city', 'owner'],
]);
