import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BuyersContext, buyersContext } from '@context/buyers.context';
import contractsContext, { ContractsContextType } from '@context/contracts.context';
import { installationsContext } from '@context/installations.context';
import useContracts from '@hooks/requests/contracts/useContracts';
import { useBuyers } from '@hooks/requests/useBuyers';
import useIndexFormulas from '@hooks/requests/useIndexFormulas';
import { useInstallations } from '@hooks/requests/useInstallations';
import { Contract } from '@utils/types/contract';
import { User } from '@utils/types/user';
import { ContractTableFilters } from '../contracts.types';
import { DEFAULT_FILTERS, localStorageHelpers } from '../utils/tableFilterHelpers';
import { contractsPageContext, contractsPageContextProps } from './contractsPage.context';
import { IndexFormulaContext, indexFormulaContext } from './indexFormula.context';
import { selectedContractsContext } from './selectedContracts.context';

export const ContractsProvider = (
  props: React.PropsWithChildren<{
    user: User;
    contracts?: Contract[];
    bookId?: string;
    installationId?: string;
    meterId?: string;
    marketPlayerId?: string;
    tradeCapture?: boolean;
  }>,
) => {
  const contractsParams: Record<string, string> = {};
  if (props?.meterId) contractsParams.meter_id = props.meterId;
  if (props?.bookId) contractsParams.book_id = props.bookId;
  if (props?.installationId) contractsParams.installation_id = props.installationId;
  if (props?.marketPlayerId) contractsParams.market_player_id = props.marketPlayerId;

  const {
    data: contracts,
    allContracts,
    loading,
    loaded,
    error: contractsError,
    filterContracts,
    addContract,
    removeContract,
    updateContract,
    replaceContracts,
    deleteError,
    isDeleting,
    resetDeleteError,
  } = useContracts(contractsParams, props?.tradeCapture);

  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);
  const [selectedContracts, setSelectedContracts] = useState<Contract[]>([]);
  const [massEditModalOpen, setMassEditModalOpen] = useState<boolean | undefined>(false);
  const [selectedToDeleteId, setSelectedToDeleteId] = useState<string | null>(null);
  const installations = useInstallations({ type: 'operational' });

  const [infoFilters, setInfoFilters] = useState<ContractTableFilters>(
    localStorageHelpers.getTableColumnFilters() || DEFAULT_FILTERS,
  );

  const updateInfoFilters = (items: Partial<ContractTableFilters>) => {
    setInfoFilters((prev) => {
      const updated = { ...prev, ...items };

      localStorageHelpers.setTableColumnFilters(updated);

      return updated;
    });
  };

  const buyersRequest = useBuyers();
  const { data: indexData, loading: indexLoading } = useIndexFormulas();

  const isLoading = loading || indexLoading || installations.loading || buyersRequest.loading;
  const loadingError = installations.error || buyersRequest.error || contractsError;

  useEffect(() => {
    const hash = window.location.hash;
    if (loaded && hash.length) {
      const contractId = hash.replace('#', '');
      const contract = contracts.find((c) => c.id === contractId);

      if (contract) setSelectedContract(contract);
    }
  }, [contracts, loaded]);

  const selectContract = useCallback((contract: Contract) => {
    setSelectedContracts((contracts) => {
      if (contracts.includes(contract)) {
        return contracts.filter((c) => c.id !== contract.id);
      } else {
        return [...contracts, contract];
      }
    });
  }, []);

  const selectAllContracts = useCallback(() => {
    setSelectedContracts(contracts);
  }, [contracts]);

  const unSelectAllContracts = useCallback(() => {
    setSelectedContracts([]);
  }, []);

  const contractsContextValue = useMemo<ContractsContextType>(
    () => ({
      isLoading: props.contracts ? false : isLoading,
      error: loadingError,
      contracts: props.contracts || contracts,
      allContracts,
      addContract,
      removeContract,
      filterContracts,
      updateContract,
      selectedContract,
      setSelectedContract,
      replaceContracts,
      setSelectedToDeleteId,
      selectedToDeleteId,
      deleteError,
      isDeleting,
      resetDeleteError,
      infoFilters,
      setInfoFilters: updateInfoFilters,
    }),
    [
      addContract,
      allContracts,
      contracts,
      deleteError,
      filterContracts,
      infoFilters,
      isDeleting,
      isLoading,
      loadingError,
      props.contracts,
      removeContract,
      replaceContracts,
      resetDeleteError,
      selectedContract,
      selectedToDeleteId,
      updateContract,
    ],
  );
  const contractsPageContextValue = useMemo<contractsPageContextProps>(
    () => ({ massEditModalOpen, setMassEditModalOpen }),
    [massEditModalOpen],
  );
  const buyersContextValue = useMemo<BuyersContext>(() => ({ buyers: buyersRequest.data ?? [] }), [buyersRequest.data]);
  const installationsContextValue = useMemo(() => ({ installations: installations.data }), [installations.data]);
  const indexFormulasContextValue = useMemo<IndexFormulaContext>(
    () => ({ formulas: indexData?.formulas ?? [], edfFormulas: indexData?.edfFormulas ?? [] }),
    [indexData?.edfFormulas, indexData?.formulas],
  );
  const selectedContractsContextValue = useMemo(
    () => ({ selectedContracts, selectContract, unSelectAllContracts, selectAllContracts }),
    [selectAllContracts, selectContract, selectedContracts, unSelectAllContracts],
  );

  return (
    <contractsContext.Provider value={contractsContextValue}>
      <contractsPageContext.Provider value={contractsPageContextValue}>
        <buyersContext.Provider value={buyersContextValue}>
          <installationsContext.Provider value={installationsContextValue}>
            <indexFormulaContext.Provider value={indexFormulasContextValue}>
              <selectedContractsContext.Provider value={selectedContractsContextValue}>
                {props.children}
              </selectedContractsContext.Provider>
            </indexFormulaContext.Provider>
          </installationsContext.Provider>
        </buyersContext.Provider>
      </contractsPageContext.Provider>
    </contractsContext.Provider>
  );
};
