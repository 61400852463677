import { MeterInvoice } from '@utils/types/meter-invoice';

type ForecastTypeObject = {
  showRegul: boolean;
};

/**
 * This one is specific to MeterInvoice
 */
export default function isRegul(meterInvoice: MeterInvoice, filters: ForecastTypeObject): boolean {
  const isRegul = meterInvoice.internal_type === 'regul';

  // we exclude regul if filters is set to false
  return filters.showRegul || !isRegul;
}
