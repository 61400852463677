import React, { useContext } from 'react';
import { Restricted } from '@components/Restricted';
import { Checkbox } from '@GDM/forms';
import { Simulation } from '@utils/types/simulation';
import CreateBookButton from '../../CreateBookButton';
import { NewSimulationButton } from '../../NewSimulationButton';
import SelectedRowsContext from '../../state/selectedRows';
import styles from './simulations-actions.module.scss';

export const SimulationsActions = ({ simulations, toggle }: { simulations: Simulation[]; toggle: () => void }) => {
  const { selectedRows, selectAllRows, unselectAllRows } = useContext(SelectedRowsContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      selectAllRows(simulations);
    } else {
      unselectAllRows();
    }
  };

  const checked = simulations.length > 0 && selectedRows.length === simulations.length;

  return (
    <div className={styles['simulations-actions']}>
      <Checkbox checked={checked} onChange={handleChange} label="common.select_all" className="mr-4" />

      <CreateBookButton toggle={toggle} />

      <Restricted permissions={['display:edit_pricing']}>
        <div className="ml-auto">
          <NewSimulationButton />
        </div>
      </Restricted>
    </div>
  );
};
