import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ContractForm } from '@utils/types/contract';
import { Control } from 'react-hook-form';
import { ListInput } from '../../../components/Inputs/List';

export const CrInvoicingType = ({ control, readOnly }: { control: Control<ContractForm>; readOnly: boolean }) => {
  return (
    <div className={sectionStyles.row}>
      <ListInput
        id="CrInvoicingTypePicker"
        name="cr_facturation_type"
        label="sales_management.cr_invoice_type"
        control={control}
        options={[
          {
            value: 'initial_estimated_regulation_monthly_consolidated_yearly_definitive',
            label:
              'sales_management.cr_invoice_type_initial_estimated_regulation_monthly_consolidated_yearly_definitive',
          },
          {
            value: 'initial_estimated_regulation_yearly_definitive',
            label: 'sales_management.cr_invoice_type_initial_estimated_regulation_yearly_definitive',
          },
          {
            value: 'initial_consolidated_regulation_definitive',
            label: 'sales_management.cr_invoice_type_initial_consolidated_regulation_definitive',
          },
          {
            value: 'initial_spot_regulation_yearly_definitive',
            label: 'sales_management.cr_invoice_type_initial_spot_regulation_yearly_definitive',
          },
        ]}
        classNamePrefix="select-invoicing-type"
        isSearchable
        readOnly={readOnly}
      />
    </div>
  );
};
