import fetchWrapper from '@utils/fetch';
import { v2_index_formulas_path } from '@utils/routes';
import IndexFormula from '@utils/types/IndexFormula';

export const getIndexFormulas = async (): Promise<{
  formulas: IndexFormula[];
  edfFormulas: IndexFormula[];
}> => {
  const response = await fetchWrapper(v2_index_formulas_path());

  const data: IndexFormula[] = await response.json();

  return {
    formulas: data.filter((i) => !i.edf),
    edfFormulas: data.filter((i) => i.edf),
  };
};
