import React from 'react';
import { Tab, Tabs } from '@GDM/Tabs';
import { tabsContext } from '@GDM/Tabs/tabs.context';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { TabId } from './invoices.types';
import { TabContent } from './TabContent';

export const Invoices = ({ user }: { user: User }) => {
  const tabs: Tab<TabId>[] = [
    { id: 'load_curve', name: 'monitoring.invoicing.tab_loadcurve' },
    { id: 'index', name: 'monitoring.invoicing.tab_index' },
  ];

  return (
    <Page user={user} title="common.invoices">
      <Tabs tabs={tabs}>
        <tabsContext.Consumer>
          {({ currentTab }) => (
            <>
              {currentTab === 'load_curve' && <TabContent type="load_curve" />}
              {currentTab === 'index' && <TabContent type="index" />}
            </>
          )}
        </tabsContext.Consumer>
      </Tabs>
    </Page>
  );
};
