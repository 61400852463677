import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Instruction } from '../Instruction';
import { useReactiveForm } from '../useReactiveForm';

export const Instructions = () => {
  const { control } = useReactiveForm();

  const instructions = useFieldArray({
    control,
    name: 'instructions',
    keyName: 'instruction_id',
    rules: { required: true },
  });

  return (
    <div>
      {instructions?.fields?.map((instruction, i) => (
        <Instruction index={i} key={instruction.instruction_id} />
      ))}
    </div>
  );
};
