import dayjs from 'dayjs';
import _groupBy from 'lodash/groupBy';
import { granularityFormat, type Granularity } from '../constants/granularities';
import type { NegativePrice } from '../negative-prices.types';

/**
 * This function calculates negative price hours and related metrics for a set of prices grouped by a specified time interval (granularity).
 */
export const getFormattedNegativeHours = (
  prices: NegativePrice[],
  granularity: Granularity,
  groupBy: GroupBy,
): NegativeHour[] => {
  if (!prices.length) {
    return [];
  }

  const pricesByDate = _groupBy(prices, (price) => groupMap[groupBy](price, granularityFormat[granularity]));

  return Object.entries(pricesByDate).map(([date, prices]) => ({
    startDate: date,
    endDate: date,
    installation: prices[0].installation,
    ...calculateNegativeHours(prices),
  }));
};

const calculateNegativeHours = (prices: NegativePrice[]) => {
  return prices.reduce(
    (
      acc,
      {
        estimated_cost,
        nb_negative_price_hours,
        nb_production_hours_on_negative_prices,
        nb_interruption_hours_on_negative_prices,
        production,
        productible,
      },
    ) => {
      acc.negativePriceHours += nb_negative_price_hours;
      acc.productionHours += nb_production_hours_on_negative_prices;
      acc.interruptionHours += nb_interruption_hours_on_negative_prices;
      acc.productionOnNegativeHours += production;
      acc.productibleOnNegativeHours += productible;
      if (estimated_cost) acc.estimatedCostOnNegativeHours += estimated_cost;

      return acc;
    },
    {
      negativePriceHours: 0,
      productionHours: 0,
      interruptionHours: 0,
      productionOnNegativeHours: 0,
      productibleOnNegativeHours: 0,
      estimatedCostOnNegativeHours: 0,
    },
  );
};

const groupMap: Record<GroupBy, (price: NegativePrice, format?: string) => string> = {
  date: (price: NegativePrice, format?: string) => dayjs(price.start_time).format(format),
  installation: (price: NegativePrice) => price.installation.uuid,
};

export type NegativeHour = {
  startDate: string;
  endDate: string;
  negativePriceHours: number;
  productionHours: number;
  interruptionHours: number;
  productionOnNegativeHours: number;
  productibleOnNegativeHours: number;
  estimatedCostOnNegativeHours: number;
  installation: NegativePrice['installation'];
};

export type GroupBy = 'date' | 'installation';
