import { CountryCode } from '@utils/types/countries';
import { FieldValues } from 'react-hook-form';
import { FormField } from '../form.types';

export const isFieldAvalaibleForCountry = (field: FormField<FieldValues>, country?: CountryCode | null) => {
  if (
    (field.onlyForCountries && !country) ||
    (field.onlyForCountries && country && !field.onlyForCountries.includes(country))
  ) {
    return false;
  }

  return true;
};
