import { useContext } from 'react';
import { Subscription } from '@utils/types/subscription';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { SubscriptionForm } from '../bsm.types';
import { formContext } from './SubscriptionFormProvider';

export const useSubscriptionForm = (): UseFormReturn<Subscription> & { readonly: boolean; loading: boolean } => {
  const { readonly, loading } = useContext(formContext);
  const context = useFormContext<SubscriptionForm>();

  return { ...context, readonly, loading };
};
