import React from 'react';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import classNames from 'classnames';
import styles from './expander-cell.module.scss';

export function ExpanderCell<T extends object>({
  row,
  className,
  label,
}: CellContext<T, unknown> & { className: string; label: string }): JSX.Element {
  const { t } = useTranslation();
  const translatedLabel = t(
    label ||
      ('label' in row.original && row.original.label && typeof row.original.label === 'string'
        ? row.original.label
        : ''),
  );

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      // {...row.getToggleRowExpandedProps()}
      className={classNames(styles.container, className, styles[`indented-${row.depth}`])}
    >
      {row.getCanExpand() && <Icon size={12} name={row.getIsExpanded() ? 'Minus' : 'Plus'} />}
      <span className={classNames(styles.label, row.getCanExpand() && styles.interactive)}>{translatedLabel}</span>
    </div>
  );
}
