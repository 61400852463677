import React from 'react';
import { EventIcon } from '@utils/getTypeMeterIcons';
import { EventType } from '@utils/types/event';

type Props = {
  eventType: EventType;
  title: string;
  description: string;
};

export const EventRow = ({ eventType, title, description }: Props) => {
  return (
    <div className="mt-4 d-flex">
      <EventIcon className="mr-3 mt-1" type={eventType} hideTooltip />

      <div className="flex-grow">
        <div className="font-weight-bold mb-0">{title.toUpperCase()}</div>
        <p className="text-muted">{description}</p>
      </div>
    </div>
  );
};
