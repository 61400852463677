import { RequestError, useRequest } from '@hooks/useRequest';
import { metrics_v2_meter_invoicing_index_path } from '@utils/routes';
import { ContractType } from '@utils/types/contract';
import { MeterInvoiceFinalStatus } from '@utils/types/meter-invoice';

export type MetricsResponse = {
  categories: string[];
} & {
  [status in MeterInvoiceFinalStatus]: number[];
};

export type UseMetricsParams = {
  start_date: string;
  end_date: string;
  contract_types: ContractType[];
};

const useMetrics = (
  params: UseMetricsParams | null,
): { data: MetricsResponse | null; loading: boolean; error: RequestError | null } => {
  const { data, loading, error } = useRequest<MetricsResponse>(
    params ? metrics_v2_meter_invoicing_index_path(params) : null,
    'GET',
  );

  return { data, loading, error };
};
export default useMetrics;
