import React from 'react';
import { useUser } from '@context/User.context';
import { NumberCell } from '@GDM/Table';
import useTranslation, { TranslateFn } from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { formatCurrency } from '@utils/formatters';
import { MeterInvoice } from '@utils/types/meter-invoice';
import styles from './unit-price-cell.module.scss';

export const computePriceTooltip = (invoice: MeterInvoice, t: TranslateFn): string | null => {
  const { multi_period, contract_sub_period, contract_type } = invoice;
  if (multi_period) return t('sales_management.multi_period');

  if (!contract_sub_period) return null;

  let prefix = '';
  let suffix = '';
  if (contract_type === 'ContractCr' && contract_sub_period?.phase_type) {
    prefix = t(`sales_management.phases_tooltip.${contract_sub_period.phase_type}`);
  }
  if (contract_sub_period?.price_rule?.name) {
    suffix = contract_sub_period.price_rule.name;
  } else if (contract_sub_period?.price_type) {
    suffix = t(`sales_management.price.${contract_sub_period.price_type}`);
  }

  return `${prefix}${prefix && suffix ? ' - ' : ''}${suffix}`;
};

export const UnitPriceCell = ({ getValue, row }: CellContext<MeterInvoice, unknown>) => {
  const { locale } = useUser();
  const { t } = useTranslation();
  const currency = row.original.currency;

  if (!row?.original) return null;

  const { m0_price, contract_type, l_coef } = row.original;
  const m0Price = m0_price
    ? formatCurrency(m0_price, locale, 'standard', {
        maximumFractionDigits: 8,
        currency,
      })
    : null;
  const lCoef = !!l_coef && new Intl.NumberFormat(locale, { maximumFractionDigits: 5 }).format(l_coef);

  const priceTooltip = computePriceTooltip(row.original, t);

  return (
    <div className={styles['unit-price-cell']}>
      <NumberCell
        value={getValue()}
        numberOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
        tooltip={priceTooltip}
        unit={`${currency}/MWh`}
      />
      {(contract_type === 'ContractCrEdf' || contract_type === 'ContractOa') && lCoef && (
        <div className="mt-2 text-grey fw:400">L = {lCoef}</div>
      )}
      {(contract_type === 'ContractCrEdf' || contract_type === 'ContractCr') && m0Price && (
        <div className="mt-2 text-grey fw:400">M0 = {m0Price}</div>
      )}
    </div>
  );
};
