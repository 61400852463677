import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';

// eslint-disable-next-line
export const isEditable = (invoice: MeterInvoice, invoiceType: MeterInvoiceSource): boolean => {
  return invoice.status === 'created' || invoice.status === 'rejected';

  /**
   * We've made all the unapproved invoices editable for now, otherwise GLSF invoices would never be editable
   * I let this commented line as it might be changed soon
   */

  // if (invoiceType === 'index') {
  //   return isEditableIndex(invoice);
  // }

  // return isEditableLoadCurve(invoice);
};

const isComputed = (invoice: MeterInvoice) => {
  const created = invoice.status === 'created';
  const preliminary = invoice.preliminary;
  const price = invoice.unit_price || invoice.unit_price === 0;
  const production = invoice.volume_production || invoice.volume_production === 0;
  const fiveDaysAfterInvoicingPeriod = dayjs(invoice.end_date).add(5, 'day').toDate();

  return !!(created && (!preliminary || new Date() > fiveDaysAfterInvoicingPeriod) && price && production);
};

export const isEditableIndex = (invoice: MeterInvoice) => {
  const main = invoice.internal_type === 'main';
  const firstIndex = invoice.volume_first_index || invoice.volume_first_index === 0;

  return !!(main && firstIndex && isComputed(invoice));
};

export const isEditableLoadCurve = (invoice: MeterInvoice) => {
  return isComputed(invoice);
};
