import React from 'react';
import { Textarea } from '@GDM/forms/Textarea';
import { Text } from '@GDM/Text';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';

export const InvoicingEmailsInput = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('invoicing_emails');

  return (
    <Controller
      control={form.control}
      name="invoicing_emails"
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          <Textarea
            {...field}
            value={field.value ? field.value.join(', ') : ''}
            onChange={(e) => field.onChange(e.target.value.split(/[\n,]/).map((email) => email.trim()))}
            label="owner.invoicing_emails"
            size="lg"
            full
            disabled={disabled}
            data-cy="invoicing_emails"
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message}
          />
          <Text text="common.separate_comma" />
        </>
      )}
    />
  );
};
