import React from 'react';
import { useUser } from '@context/User.context';
import { DatePicker, DatePickerProps } from '@GDM/DatePicker';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';
import dayjs from 'dayjs';
import { useRevenueContext } from '../hooks/useRevenueContext';

export const DateSelector = () => {
  const { meter } = useDashboardMeter();
  const { startDate, setStartDate, endDate, setEndDate, resolution } = useRevenueContext();
  const { isAuthorized } = useUser();
  const isAllowedForecastedRevenues = isAuthorized(['display:forecasted_revenues']) && meter?.has_bp;

  const handleDatesChange = (dateRange: [Date | null, Date | null]): void => {
    if (Array.isArray(dateRange)) {
      let [startDate, endDate] = dateRange;

      const UTCStartDate = dayjs(startDate || undefined).set('h', 12);

      const UTCEndDate = dayjs(
        // Make sure the user is allowed to select a future date
        !isAllowedForecastedRevenues && endDate && endDate > new Date() ? undefined : endDate,
      ).set('h', 12);

      if (startDate) startDate = UTCStartDate.toDate();
      if (endDate) endDate = UTCEndDate.toDate();

      if (resolution === 'monthly') {
        startDate = startDate ? UTCStartDate.startOf('month').set('h', 12).toDate() : null;
        endDate = endDate ? UTCEndDate.startOf('month').set('h', 12).toDate() : null;
      }

      // If end date - start date is more than 3 weeks
      if (resolution === 'daily' && startDate && endDate && endDate.valueOf() - startDate?.valueOf() > 1.814e9) {
        endDate = UTCStartDate.add(3, 'week').endOf('day').toDate();
      }

      // If end date - start date is more than 24 hours
      if (resolution === 'hourly' && startDate && endDate && endDate.valueOf() - startDate.valueOf() > 8.64e7) {
        endDate = UTCStartDate.add(1, 'day').toDate();
      }

      setStartDate?.(startDate);
      setEndDate?.(endDate);
    }
  };

  let minDate: DatePickerProps['minDate'] = null;
  let maxDate: DatePickerProps['maxDate'] = new Date();
  let picks: DatePickerProps['picks'] = 'months';
  const twoMonthsAgo = dayjs().subtract(2, 'month').startOf('month').toDate();

  if (resolution === 'daily') {
    picks = 'default';
    // Daily revenue can be for max 1 week
    minDate = twoMonthsAgo;
    maxDate = endDate ? new Date() : dayjs(startDate).add(3, 'week').add(1, 'day').startOf('day').toDate();
  }

  if (resolution === 'hourly') {
    picks = 'default';
    // Daily revenue can be for max 1 day
    minDate = twoMonthsAgo;
    maxDate = endDate ? new Date() : dayjs(startDate).add(1, 'day').startOf('day').toDate();
  }

  return (
    <div>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={isAllowedForecastedRevenues ? null : maxDate}
        picks={picks}
        onChange={handleDatesChange}
        data-cy="revenue-dates"
        selectsRange={true}
      />
    </div>
  );
};
