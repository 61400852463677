import React from 'react';
import { AbsoluteValueWithPercentageCell } from '@GDM/Table/Cells/AbsoluteValueWithPercentageCell';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';

export const CapacityValueCell = ({
  row: { original },
}: React.PropsWithChildren<CellContext<Unavailability, unknown>>) => {
  return (
    <AbsoluteValueWithPercentageCell
      value={original.real_capacity_value}
      percentage={original.capacity_ratio ? original.capacity_ratio * 100 : null}
      numberOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
    />
  );
};
