import React from 'react';
import { useUser } from '@context/User.context';
import { Toggle } from '@GDM/Toggle';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import type { InstallationForm } from '@pages/Installations/Installation/installation.types';
import { Controller, ControllerRenderProps } from 'react-hook-form';

export const ProductionCheckbox = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('producteur');
  const { account_type } = useUser();

  const label = account_type === 'consumer' ? 'common.production' : 'common.consumption';

  const handleChange = (field: ControllerRenderProps<InstallationForm, 'producteur'>) => (toggled?: boolean) => {
    const value = account_type === 'consumer' ? !!toggled : !toggled;

    form.setValue('producteur', value);
    field.onChange(value);
  };

  const getValue = (field: ControllerRenderProps<InstallationForm, 'producteur'>) => {
    if (account_type === 'consumer') {
      return field.value;
    }

    return !field.value;
  };

  return (
    <Controller
      name="producteur"
      control={form.control}
      rules={rules}
      render={({ field }) => (
        <Toggle
          value={getValue(field)}
          onChange={handleChange(field)}
          data-cy="meter-production-checkbox"
          disabled={disabled}
          label={label}
        />
      )}
    />
  );
};
