import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button } from '@GDM/Button';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './upload-input.module.scss';

export const UploadInput = forwardRef(
  (
    {
      fileTypes,
      error,
      onClear,
      ...inputProps
    }: {
      fileTypes?: Array<`.${string}`>;
      error?: string;
      onClear?: () => void;
    } & React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { onChange, className, placeholder } = inputProps;
    const innerRef = useRef<React.ElementRef<'input'>>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const { t } = useTranslation();

    const isError = Boolean(error);
    const isValid = !isError && Boolean(fileName);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => innerRef.current);

    const handleBoxClick = () => {
      innerRef.current?.click();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      setFileName(file?.name || null);
      onChange?.(event);
    };

    const handleClear = (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      setFileName(null);
      onClear?.();

      if (innerRef.current) {
        innerRef.current.value = '';
        innerRef.current.files = null;
        innerRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setIsDragOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setIsDragOver(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setIsDragOver(false);

      if (event.dataTransfer.files?.[0] && innerRef.current) {
        innerRef.current.files = event.dataTransfer.files;
        innerRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      }
    };

    return (
      <div
        className={classNames(
          styles['upload-input'],
          isValid && styles.valid,
          isError && styles.error,
          isDragOver && styles.dragover,
          inputProps.disabled && styles.disabled,
          className,
        )}
        onClick={handleBoxClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnd={handleDragLeave}
      >
        {fileName && (
          <Text
            multiline
            text={fileName}
            className="font-bolder mb-3"
            icon={isError ? 'AlertCircle' : 'CheckCircle'}
            iconProps={{ size: 16, className: isError ? 'text-red' : 'text-blue' }}
          />
        )}

        <Text
          multiline
          text={placeholder ?? 'common.click_or_grab'}
          className={classNames('font-bolder', fileName && 'unavailable')}
        />

        {fileTypes && (
          <Text
            text={`${t('common.allowed_file_types')} ${fileTypes.join(', ')}`}
            className={classNames(fileName && 'unavailable')}
          />
        )}

        {error && <Text text={error} type="danger" multiline className="mt-3" />}

        {fileName && (
          <Button
            type="button"
            variant="link-secondary"
            text="common.cancel"
            icon="XSquare"
            onClick={handleClear}
            size="sm"
            className="mt-3"
          />
        )}

        <input
          ref={innerRef}
          type="file"
          hidden
          accept={fileTypes?.join(',') || undefined}
          {...inputProps}
          onChange={handleChange}
        />
      </div>
    );
  },
);
