import React from 'react';
import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import { isExpired, isNotStarted, isOngoing } from '@utils/filters/isDateStatus';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import compact from 'lodash/compact';
import { Controller, useFormContext } from 'react-hook-form';

const getOptions = (contracts: Contract[]) => {
  const hasCurrentContracts = contracts.some((contract) => isOngoing(contract));
  const hasExpiredContracts = contracts.some((contract) => isExpired(contract));
  const hasNotStartedContracts = contracts.some((contract) => isNotStarted(contract));

  const options: Option<ContractsFiltersType['date_status']>[] = compact([
    hasCurrentContracts && { value: 'ongoing', label: 'contracts.ongoing' },
    hasExpiredContracts && { value: 'expired', label: 'contracts.expired' },
    hasNotStartedContracts && { value: 'not_started', label: 'contracts.not_started' },
  ]);

  return options;
};

export const DateStatusFilter = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();

  const options = useDynamicOptions(getOptions, 'date_status', contracts, allContracts);

  return (
    <Controller
      control={form.control}
      name="date_status"
      render={({ field }) => (
        <Select
          options={options}
          selectedOption={field.value}
          placeholder="contracts.date_status"
          classNamePrefix="contracts-date-status-filter"
          onChange={(option) => {
            const date_status = option?.value ?? null;
            field.onChange(date_status);
            filterContracts({ ...form.getValues(), date_status, last_selected_filter: 'date_status' });
            form.setValue('last_selected_filter', date_status ? 'date_status' : null);
          }}
          isClearable
        />
      )}
    />
  );
};
