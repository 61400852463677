const ACCENT_DICTIONARY = {
  À: 'A',
  Á: 'A',
  Â: 'A',
  Ã: 'A',
  Ä: 'A',
  Å: 'A',
  à: 'a',
  á: 'a',
  â: 'a',
  ã: 'a',
  ä: 'a',
  å: 'a',
  Ç: 'C',
  ç: 'c',
  È: 'E',
  É: 'E',
  Ê: 'E',
  Ë: 'E',
  è: 'e',
  é: 'e',
  ê: 'e',
  ë: 'e',
  Ì: 'I',
  Í: 'I',
  Î: 'I',
  Ï: 'I',
  ì: 'i',
  í: 'i',
  î: 'i',
  ï: 'i',
  Ñ: 'N',
  ñ: 'n',
  Ò: 'O',
  Ó: 'O',
  Ô: 'O',
  Õ: 'O',
  Ö: 'O',
  Ø: 'O',
  ò: 'o',
  ó: 'o',
  ô: 'o',
  õ: 'o',
  ö: 'o',
  ø: 'o',
  Ù: 'U',
  Ú: 'U',
  Û: 'U',
  Ü: 'U',
  ù: 'u',
  ú: 'u',
  û: 'u',
  ü: 'u',
  Ý: 'Y',
  ý: 'y',
  ÿ: 'y',
  Æ: 'Ae',
  æ: 'ae',
  ß: 'ss',
};

const replaceAccents = (str?: string): string =>
  str ? str.replace(/[^\w ]/g, (char) => ACCENT_DICTIONARY[char as keyof typeof ACCENT_DICTIONARY] || char) : '';

export default replaceAccents;
