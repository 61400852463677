import fetchWrapper from '@utils/fetch';
import { formatSystemDate } from '@utils/formatters/formatSystemDate';
import { v2_market_futures_path } from '@utils/routes';
import { MarketFuture } from '@utils/types/market_future';

export const getMarketFutures = async (startDate?: Date, endDate?: Date): Promise<MarketFuture[]> => {
  const start_date = startDate ? formatSystemDate(startDate) : undefined;
  const end_date = endDate ? formatSystemDate(endDate) : undefined;

  const response = await fetchWrapper(v2_market_futures_path({ params: { start_date, end_date } }));

  return response.json();
};
