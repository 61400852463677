import React from 'react';
import { Button } from '@GDM/Button';

export const NewSimulationButton = () => {
  return (
    <Button
      variant="primary-2"
      size="xxs"
      icon="Plus"
      text="pricing.portfolio.new_simulation"
      href="/v2/ppa_simulations/new"
      data-cy="new-simulation-button"
    />
  );
};
