import fetchWrapper from '@utils/fetch';
import { intermediaries_v2_market_players_path, v2_buyers_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

export const getMarketPlayers = async () => {
  const response = await fetch(v2_buyers_path());
  const marketPlayers: MarketPlayer[] = await response.json();

  return marketPlayers;
};

export const getIntermediaries = async (): Promise<MarketPlayer[]> => {
  const response = await fetchWrapper(intermediaries_v2_market_players_path());

  return response.json();
};
