import { fetchDataProviders } from '@api/dataProviders';
import { useQuery } from '@tanstack/react-query';
import type { CountryCode } from '@utils/types/countries';

export const useDataProviders = (country: CountryCode) => {
  return useQuery({
    queryKey: ['data_providers', country],
    queryFn: () => fetchDataProviders(country),
  });
};
