import { useEffect } from 'react';
import { useProgressStepper, useStep } from '@GDM/forms';
import { useFormContext } from 'react-hook-form';
import { useSelectedInstallation } from '../context/selectedInstallation.context';
import { SimulationForm } from '../utils/simulations.types';
import { updateSessionsProgress } from '../utils/updateSessionsProgress';

export const useFormProgress = (): void => {
  const { markAsValid, updateProgress } = useProgressStepper();
  const { watch, getValues } = useFormContext<SimulationForm>();
  const { stepId } = useStep();
  const { selectedInstallation } = useSelectedInstallation();

  useEffect(() => {
    const { unsubscribe } = watch(({ installation_attributes }) => {
      if (!selectedInstallation || !installation_attributes || !installation_attributes.energy) return;

      const energy = installation_attributes.energy;

      updateSessionsProgress(energy, selectedInstallation, getValues, updateProgress, markAsValid);
    });

    return () => unsubscribe();
  }, [getValues, watch, markAsValid, stepId, updateProgress, selectedInstallation]);
};
