import React from 'react';
import { useUser } from '@context/User.context';
import { Sparkline } from '@pages/Reporting/components/charts/Sparkline';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import { getLocalizedMonthList } from '@utils/getLocalizedMonthList';

type Props = {
  data: {
    production: number;
    date: string;
  }[];
};

export const ReportSparkline = ({ data }: Props) => {
  const { selectedDate } = useRevenueReportingContext();
  const { locale } = useUser();
  const Months = getLocalizedMonthList(locale);
  const formattedData = selectedDate
    ? data
        .map((d) => ({ y: d.production, name: Months[new Date(d.date).getMonth()] }))
        .slice(
          0,
          new Date(selectedDate).getFullYear() === new Date().getFullYear()
            ? new Date(selectedDate).getMonth() + 1
            : 12,
        )
    : [];

  const options: Highcharts.Options = {
    chart: {
      backgroundColor: 'transparent',
    },
    xAxis: {
      categories: Months,
      labels: { enabled: false },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      pointFormatter() {
        return `<b>${
          this.y ? new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(this.y) : '--'
        }</b> kWh`;
      },
    },
    series: [{ data: formattedData, color: '#01d483', type: 'area' }],
  };

  return <Sparkline options={options} />;
};
