import React, { useMemo } from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { FilterContainer, Filters } from '@GDM/Filters';
import { FormattedOptionEnergy } from '@GDM/forms/Select/CustomComponents/OptionEnergy/FormattedOptionEnergy';
import OptionEnergy from '@GDM/forms/Select/CustomComponents/OptionEnergy/OptionEnergy';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { UserNotificationsSettings } from '@utils/types/userNotificationsSettings';
import { Control } from 'react-hook-form';
import type { EnergyOption } from './NotificationForm/InstallationsBooksSelect';
import { NotificationsFormFilters } from './types/notificationsFilters';

export const NotificationsFilters = ({
  control,
  data,
}: {
  control: Control<NotificationsFormFilters>;
  data: UserNotificationsSettings[];
}) => {
  const emailsOptions = useMemo(() => {
    const allEmails = data.flatMap((notification) => notification.emails);

    return Array.from(new Set(allEmails)).map((email) => ({ value: email, label: email }));
  }, [data]);

  const typesOptions = useMemo(() => {
    const allTypes = data.flatMap((notification) => notification.event_types);

    return Array.from(new Set(allTypes)).map((type) => ({ value: type, label: `admin.alerts.events.${type}` }));
  }, [data]);

  const [installationOptions, bookOptions] = useMemo(() => {
    const installationOptions: Map<string, EnergyOption> = new Map();
    const bookOptions: Map<string, EnergyOption> = new Map();

    const [installations, books] = data.reduce<[Map<string, EnergyOption>, Map<string, EnergyOption>]>(
      ([previousInstallations, previousBooks], notification) => {
        const isBook = notification.eventable_type === 'Book';
        const option = notification.eventable
          ? {
              label: notification.eventable.name,
              value: notification.eventable.name,
              energy: isBook ? 'book' : notification.eventable.energy,
            }
          : { label: 'admin.installations.all', value: '' };

        const books = isBook ? previousBooks.set(option.value, option) : previousBooks;
        const installations = isBook ? previousInstallations : previousInstallations.set(option.value, option);

        return [installations, books];
      },
      [installationOptions, bookOptions],
    );

    return [
      [...installations.values()].sort((a, b) => sortOptionsByLabelAsc(a, b, true)),
      [...books.values()].sort((a, b) => sortOptionsByLabelAsc(a, b, true)),
    ];
  }, [data]);

  return (
    <Filters>
      <FilterContainer size="select">
        <ControlledSelect
          name="email"
          control={control}
          inline
          options={emailsOptions}
          placeholder="common.emails"
          isMulti
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          name="event_type"
          control={control}
          inline
          options={typesOptions}
          isMulti
          placeholder="admin.alerts.alert_type"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          name="installation"
          control={control}
          inline
          options={installationOptions}
          placeholder="common.installations"
          components={{ Option: OptionEnergy }}
          formatOptionLabel={FormattedOptionEnergy}
          isMulti
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          name="book"
          control={control}
          inline
          options={bookOptions}
          placeholder="common.books"
          components={{ Option: OptionEnergy }}
          formatOptionLabel={FormattedOptionEnergy}
          isMulti
        />
      </FilterContainer>
    </Filters>
  );
};
