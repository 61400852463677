import React from 'react';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useCurrency } from '@utils/string';
import { CannibalizationChart } from './CannibalizationChart';

export const CannibalizationImpactDeviationChart = ({ percentage }: { percentage?: boolean }) => {
  const project = useProjectContext();
  const { cannibalized_distribution, cannibalized_distribution_factor, cannibalized_distribution_cat } =
    project?.feed_in_deviation || {};
  const currency = useCurrency();

  if (!cannibalized_distribution || !cannibalized_distribution_factor || !cannibalized_distribution_cat) return null;

  const data = percentage ? cannibalized_distribution_factor : cannibalized_distribution;

  const title = percentage
    ? 'pricing.project.market_value_deviation_percent'
    : 'pricing.project.market_value_deviation_eur_mwh';

  const categories = cannibalized_distribution_cat.map((cat) => cat?.toString() || '');

  return (
    <CannibalizationChart
      q50={data.q50}
      q1090={data.q1090}
      q2575={data.q2575}
      title={title}
      categories={categories}
      valueSuffix={percentage ? '%' : ` ${currency}/MWh`}
    />
  );
};
