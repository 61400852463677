import React from 'react';
import { ControlledToggle, ControlledToggleProps } from '@components/FormInputs/ControlledToggle';
import { HelpBoxProps } from '@GDM/HelpBox/HelpBox';
import { useHelpBoxContext } from '@pages/Contracts/Contract/contexts/helpBoxContext';
import { useFieldRegistrationTracker } from '@pages/Contracts/Contract/contexts/registeredFields';
import { FieldPath, FieldValues } from 'react-hook-form';

export const BooleanInput = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  helpBox,
  ...props
}: Omit<ControlledToggleProps<TFieldValues, TFieldName>, 'shouldUnregister'> & {
  helpBox?: Pick<HelpBoxProps, 'text' | 'title'>;
}) => {
  useFieldRegistrationTracker(props);
  const { handleFocus, handleBlur } = useHelpBoxContext();

  return <ControlledToggle {...props} shouldUnregister onFocus={helpBox && handleFocus(helpBox)} onBlur={handleBlur} />;
};
