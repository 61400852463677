import React from 'react';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import type { EventType } from '@utils/types/event';
import classNames from 'classnames';
import { eventConfig } from '../eventConfig';

export const EventDescription = ({ eventType }: { eventType: EventType }) => {
  const { t } = useTranslation();
  const event = eventConfig[eventType];

  if (!event) return null;

  return (
    <div className="d-flex">
      <Icon name={event.icon} size={14} className={classNames('mt-1 mr-3', event.colorClass)} />

      <div className="flex-grow">
        <div className="font-weight-bold mb-0">{t(event.title).toUpperCase()}</div>
        <p className="text-muted">{t(event.text)}</p>
      </div>
    </div>
  );
};
