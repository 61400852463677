import React from 'react';
import { Alert } from '@GDM/Alert';
import { Spinner } from '@GDM/Spinner';
import { CountryDefinitions } from '@utils/types/countries';
import styles from './pricing-maps.module.scss';
import { PricingChart } from './PricingChart/PricingChart';
import { PricingMap } from './PricingMap/PricingMap';
import { useSelectedPricingMapContext } from './utils/selectedPricingMap.context';
import { useGeoRaster } from './utils/useGeoRaster';
import { useMask } from './utils/useMask';
import { usePricingMapM0s } from './utils/usePricingMapM0s';

export const PricingMapContainer = () => {
  const { selectedPricingMap } = useSelectedPricingMapContext();
  const {
    data: geoRaster,
    loading: geoRasterLoading,
    error: geoRasterError,
  } = useGeoRaster(selectedPricingMap?.image_url || '');
  const { data: mask, loading: maskLoading } = useMask(selectedPricingMap);
  const { data: m0s, loading: m0sLoading } = usePricingMapM0s(selectedPricingMap?.id || null);
  const [latLng, setLatLng] = React.useState<CountryDefinitions['coordinates']>({ lat: null, lng: null });

  const loading = geoRasterLoading || maskLoading || m0sLoading;

  return (
    <>
      <div className={styles['pricing-maps-container']}>
        {geoRasterError && <Alert className={styles.alert} variant="danger" label="pricing.maps.errors.georaster" />}

        {(!geoRaster || !mask || !m0s) && <Alert label="pricing.maps.errors.no_data_available" />}

        {loading && (
          <div className={styles['loader']}>
            <Spinner />
          </div>
        )}

        {geoRaster && mask && m0s && (
          <>
            <PricingMap geoRaster={geoRaster} mask={mask} m0s={m0s} setLatLng={setLatLng} latLng={latLng} />
            <PricingChart
              latLng={latLng}
              geoRaster={geoRaster}
              baseLoadPrices={m0s.base_m0_prices}
              m0Prices={m0s.m0_prices}
            />
          </>
        )}
      </div>
    </>
  );
};
