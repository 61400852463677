import React, { useMemo, useState } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { usePortfolio } from '@pages/Portfolio/usePortfolio';
import {
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
  SortingState,
} from '@tanstack/react-table';
import { useColumns } from './useColumns';

export const PortfolioTable = () => {
  const { portfolio: data, loading } = usePortfolio();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 15 });
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'coverage', desc: true },
    { id: 'ref_centrale', desc: false },
  ]);

  const columns = useColumns();
  const portfolioData = useMemo(() => data || [], [data]);

  const table = useReactTable({
    columns,
    data: portfolioData,
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    state: { sorting, pagination },
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table data-cy="portfolio-table">
        <TableHead table={table} />
        <TableBody table={table} isLoading={loading} />
      </Table>
      {portfolioData.length > pageSize && (
        <TableActions>
          <TablePageSizeSelect
            pageSize={pageSize}
            setPageSize={table.setPageSize}
            totalEntries={portfolioData.length}
          />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </>
  );
};
