import { useMemo } from 'react';
import type { RequestErrorBody } from '@hooks/useRequest';
import type { Locale } from '@utils/types/common-types';
import type { AxiosError } from 'axios';
import type { FieldValues } from 'react-hook-form';
import { getErrorsFromRequest } from '../utils/getErrorsFromRequest';

export const useErrorsFromRequest = <T extends FieldValues>(
  requestsErrors: Array<AxiosError<RequestErrorBody> | null>,
  locale: Locale,
  errorsMap?: Record<string, string>,
) => {
  const errors = useMemo(
    () => getErrorsFromRequest<T>(requestsErrors, locale, errorsMap),
    [requestsErrors, locale, errorsMap],
  );

  return errors;
};
