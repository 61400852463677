import { useRequest } from '@hooks/useRequest';
import { history_merged_with_volumes_v2_invoice_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const useInvoiceHistoryMergedWithVolumes = (invoice: MeterInvoice | null) => {
  return useRequest<MeterInvoice[]>(
    invoice?.uuid ? history_merged_with_volumes_v2_invoice_path(invoice.uuid) : null,
    'GET',
  );
};
