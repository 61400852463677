import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useSelectedYear } from '@pages/Simulations/Simulation/utils/selectedYear.context';

export const NationalQualityFactorDefinition = () => {
  const project = useProjectContext();
  const { selectedYear } = useSelectedYear();
  const { forward_prices } = project?.full_merchant || {};
  const { full_merchant_cannibalized_distribution_cat, national_vs_baseload } = project?.full_merchant || {};

  if (!selectedYear) return null;

  const yearIndex = full_merchant_cannibalized_distribution_cat?.[selectedYear as unknown as number];

  if (!yearIndex) return null;

  const forwardPrice = forward_prices?.[yearIndex as unknown as number] || 0;
  const nationalVsBaseloadYear = national_vs_baseload?.[yearIndex as unknown as number];
  const capturePrice = forwardPrice + (nationalVsBaseloadYear?.q50 || 0);
  const nationalQualityFactor = (capturePrice / forwardPrice) * 100;

  return (
    <Definition
      label="pricing.project.full_merchant_national_quality_factor"
      value={<ValueWithUnit value={nationalQualityFactor} unit="percentage" />}
    />
  );
};
