import React, { useMemo } from 'react';
import { installationsContext } from '@context/installations.context';
import { useInstallations, Options } from '@hooks/requests/useInstallations';

/**
 * Component that provides the installations context, to avoid re-fetching the installations on every page/tab.
 */
export const InstallationProvider = ({
  children,
  type,
  includeInactive = false,
  withMeterInfo = false,
}: React.PropsWithChildren<Options>) => {
  const { data: installations, loading: isLoading } = useInstallations({
    type,
    includeInactive,
    withMeterInfo,
  });

  const contextValue = useMemo(() => ({ installations, isLoading }), [installations, isLoading]);

  return <installationsContext.Provider value={contextValue}>{children}</installationsContext.Provider>;
};
