import React from 'react';
import useTranslation from '@hooks/useTranslation';

/**
 * This component is designed to wrap any element and pass a tooltip to it.
 */
export const Tooltip = ({ children, tooltip }: { children: React.ReactNode; tooltip?: string | null }) => {
  const { t } = useTranslation();
  const id = 'default-tooltip';
  const value = tooltip ? t(tooltip) : undefined;

  return React.Children.map(children, (child) => {
    const props = value ? { 'data-tooltip-id': id, 'data-tooltip-html': value } : {};

    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }

    return child;
  });
};
