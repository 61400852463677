import { createContext, useContext } from 'react';
import { SwapMeterModalContext } from './swapMeterModal.types';

export const swapMeterModalContext = createContext<SwapMeterModalContext>({
  isOpen: false,
  swapMeter: () => {
    throw new Error('swapMeterModalContext: swapMeter method must be overridden');
  },
  handleSubmit: () => {
    throw new Error('swapMeterModalContext: handleSubmit method must be overridden');
  },
  toggle: () => {
    throw new Error('swapMeterModalContext: toggle method must be overridden');
  },
  invoiceId: '',
  loaded: false,
  loading: false,
  error: null,
});

export const useSwapMeterModal = () => useContext(swapMeterModalContext);
