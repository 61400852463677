import React from 'react';
import { useUser } from '@context/User.context';
import { Definition, DefinitionList } from '@GDM/DefinitionList';
import { formatNumber } from '@utils/formatters';
import { getLocalizedMonthList } from '@utils/getLocalizedMonthList';
import { BusinessPlan } from '@utils/types/business-plan';
import dayjs from 'dayjs';

type Props = { year: string; businessPlans: BusinessPlan[] };

export const BusinessPlanTable = ({ businessPlans, year }: Props) => {
  const { locale } = useUser();

  return (
    <DefinitionList>
      {getLocalizedMonthList(locale).map((m, i) => (
        <Definition
          key={`business-plans-row-${year}-${m}`}
          label={m}
          value={
            <>
              <code>
                {formatNumber(
                  businessPlans.find((businessPlan) => dayjs(businessPlan.start_date).month() === i)?.production,
                  locale,
                )}
              </code>
              &nbsp;kWh
            </>
          }
        />
      ))}
    </DefinitionList>
  );
};
