import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MarketPlayer from '@utils/types/market_player';
import { User } from '@utils/types/user';
import { ContractPage } from './ContractPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Contract = (props: { user: User; contractUuid?: string; offtaker?: MarketPlayer; readonly?: boolean }) => (
  <QueryClientProvider client={queryClient}>
    <ContractPage {...props} />
  </QueryClientProvider>
);
export default Contract;
