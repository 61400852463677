import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import Installation from '@utils/types/installation';
import { useInstallationInfo } from '../useInstallationInfo';
import { SiretModal } from './SiretModal';

export const SiretDefinition = ({ installation }: { installation: Installation }) => {
  const { siret } = useInstallationInfo();

  return (
    <Definition
      label="common.siret"
      data-cy="siret-definition"
      value={siret || 'common.none'}
      modal={{
        node: <SiretModal installation={installation} />,
        title: 'common.siret',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
