import React, { useState } from 'react';
import { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import { Event } from '@utils/types/event';
import classNames from 'classnames';
import getColumns from './getColumns';

type Props = {
  data: Event[];
  isLoading: boolean;
  isPortfolio?: boolean;
  light?: boolean;
};

export const EventsTable = ({ data, isLoading, isPortfolio, light }: Props) => {
  const { locale } = useUser();
  const columns: ColumnDef<Event>[] = useMemo(() => getColumns({ locale, isPortfolio }), [locale, isPortfolio]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const table = useReactTable({
    columns,
    data,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    state: { pagination },
    onPaginationChange: setPagination,
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table data-cy="alerts-table">
        <TableHead table={table} />
        <TableBody table={table} isLoading={isLoading} data-cy="event-table" />
      </Table>

      {data.length > pageSize && (
        <TableActions className={classNames(light && 'p-0')}>
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </>
  );
};
