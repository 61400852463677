import { useMemo } from 'react';
import { TimeSeries } from '@utils/types/timeSeries';
import { ProductionTimeSeriesRecord } from '../../production.types';
import { useSeriesList } from './useSeriesList';

/**
 * Fetch the series that will be displayed on the chart based on the availability
 * of the data. The show/hide logic is based on the filters and is handled in the
 * `useSeriesList` hook directly on the definitions.
 */
export const useDataSeries = (input: {
  availability?: TimeSeries;
  data?: Partial<ProductionTimeSeriesRecord>;
  definitions: ReturnType<typeof useSeriesList>['definitions'];
  inverterAll?: TimeSeries;
  hasPerformanceRatios?: boolean;
  marketPricesCurve: TimeSeries;
}): { series: Highcharts.SeriesOptionsType[] } => {
  const { availability, data, definitions, hasPerformanceRatios, inverterAll, marketPricesCurve } = input;

  const series = useMemo<Highcharts.SeriesOptionsType[]>(() => {
    if (!data) return [];

    const hasMarketPrices = marketPricesCurve.length > 0;

    const list = [
      ...(data?.invoicedActiveEnergyProduction?.values.length ? [definitions.BILLABLE_PRODUCTION] : []),
      ...(data?.rawActiveEnergyProduction?.values.length ? [definitions.PRODUCTION] : []),
      ...(data?.rawReactivePositiveEnergyProduction?.values.length ? [definitions.REACTIVE_PLUS] : []),
      ...(data?.rawReactiveNegativeEnergyProduction?.values.length ? [definitions.REACTIVE_MINUS] : []),
      ...(data?.potentialActiveEnergyProduction?.values.length ? [definitions.POTENTIAL_POWER] : []),
      ...(data?.businessPlanActiveEnergyProduction?.values.length ? [definitions.BUSINESS_PLAN] : []),
      ...(data?.rawActiveEnergySurplus?.values.length ? [definitions.SURPLUS] : []),
      ...(data?.rawActiveEnergySelfConsumption?.values.length ? [definitions.SELF_CONSUMPTION] : []),
      ...(hasPerformanceRatios ? [definitions.PERFORMANCE_RATIO] : []),
      ...(availability?.length ? [definitions.AVAILABILITY] : []),
      ...(data?.rawTensionProduction?.values?.length ? [definitions.TENSION] : []),
      ...(data?.correctedActiveEnergyProduction?.values.length ? [definitions.CORRECTED_PRODUCTION] : []),
      ...(data?.corrected5YearsAverageActiveEnergyProduction?.values.length ? [definitions.CORRECTED_HISTORY] : []),
      ...(data?.extrapolatedActiveEnergyProduction?.values.length ? [definitions.EXTRAPOLATED_PRODUCTION] : []),
      ...(data?.forecastedActiveEnergyProduction?.values.length ? [definitions.FORECAST] : []),
      ...(data?.dispatchedActiveEnergyProduction?.values.length ? [definitions.DISPATCHED_PRODUCTION] : []),
      ...(inverterAll?.length ? [definitions.SCADA] : []),
      ...(data?.rawActiveEnergyConsumption?.values.length ? [definitions.CONSUMPTION] : []),
      ...(hasMarketPrices ? [definitions.MARKET_PRICES] : []),
    ];

    return list.filter(Boolean);
  }, [availability?.length, data, definitions, hasPerformanceRatios, inverterAll?.length, marketPricesCurve?.length]);

  return { series };
};
