import React from 'react';
import { AbsoluteValueWithPercentageCell } from '@GDM/Table/Cells/AbsoluteValueWithPercentageCell';
import { CellContext } from '@tanstack/react-table';
import { computeBillablePowerAsPercentage } from '@utils/invoices/computeBillablePowerAsPercentage';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const BillableProdCell = ({ row: { original }, getValue }: CellContext<MeterInvoice, unknown>) => {
  return (
    <AbsoluteValueWithPercentageCell
      value={getValue()}
      percentage={original.contract_direction === 'sell' ? computeBillablePowerAsPercentage(original) : null}
      percentageTooltip="monitoring.invoicing.billable_percentage_tooltip"
    />
  );
};
