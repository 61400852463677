import React, { useState } from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { post, useRequest } from '@hooks/useRequest';
import { formatSystemDate } from '@utils/formatters';
import { change_commissioning_date_v2_dashboard_meter_index_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';

export const CommissioningDateModal = ({ meter }: { meter: Meter }) => {
  const { commissioning_date, setCommissioningDate } = useMeterInfo();
  const [localCommissioningDate, setLocalCommissioningDate] = useState<Date | null>(
    new Date(commissioning_date || new Date()),
  );

  const { loading: isLoading, execute } = useRequest(
    change_commissioning_date_v2_dashboard_meter_index_path(),
    ({ url }) =>
      post({
        url,
        body: {
          meter_name: meter.name,
          date: formatSystemDate(localCommissioningDate),
        },
      }),
    true,
  );

  const saveUpdate = () => {
    setCommissioningDate?.(formatSystemDate(localCommissioningDate));
    execute?.();
  };

  return (
    <>
      <DefinitionModalBody>
        <DatePicker
          minDate={new Date('1980-01-01')}
          maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
          onChange={setLocalCommissioningDate}
          selected={new Date(localCommissioningDate || new Date())}
        />
      </DefinitionModalBody>
      <DefinitionModalFooter save={saveUpdate} isLoading={isLoading} />
    </>
  );
};
