import { isValidDate } from '@utils/isValidDate';
import dayjs from 'dayjs';
import { toDate } from './toDate';

/**
 * Formats DD MMM YY eg. 29 Oct. 22
 */
export const formatHumanDate = (input?: string | number | Date | null): string => {
  const date = input && isValidDate(input) ? toDate(input) : new Date();
  const format = 'DD MMM YY';

  return dayjs(date).format(format);
};
