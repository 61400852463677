import axiosInstance from '@utils/axiosInstance';
import { v2_reactive_constraint_path, v2_reactive_constraints_path } from '@utils/routes';
import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import { getRequestStacks } from './get-request-stacks';
import { ReactiveInstruction } from './types';

const requestHeaders = {
  headers: { accept: 'application/json' },
};

export async function handleRequestStack(
  requestStacks: {
    new: ReactiveInstruction[];
    old: Partial<ReactiveConstraint>[];
  },
  meter_name?: string,
): Promise<Partial<ReactiveConstraint>[]> {
  const { instructionStack, requestStack } = getRequestStacks(requestStacks);

  if (instructionStack.delete?.length) {
    requestStack.delete = instructionStack.delete.map((i) => {
      if (!i.uuid) return null;

      return axiosInstance(false).delete<ReactiveInstruction>(v2_reactive_constraint_path(i.uuid), requestHeaders);
    });
  }

  // Needs to wait for deletes to complete so we don't have overlapping instructions
  await Promise.all(requestStack.delete);

  if (instructionStack.update?.length) {
    requestStack.update = instructionStack.update.map((i) => {
      if (!i.uuid) return null;

      return axiosInstance(false).put<ReactiveInstruction>(v2_reactive_constraint_path(i.uuid), i, requestHeaders);
    });
  }

  // Needs to wait for updates to complete so we don't have overlapping instructions
  await Promise.all(requestStack.update);

  if (instructionStack.create?.length) {
    requestStack.create = instructionStack.create.map((i) => {
      const requestBody = { ...i, meter_name };

      return axiosInstance(false).post<ReactiveInstruction>(
        v2_reactive_constraints_path(),
        requestBody,
        requestHeaders,
      );
    });
  }

  requestStack.update.forEach((req, i) =>
    req?.then(({ data }) => (instructionStack.update[i] = { ...instructionStack.update[i], ...data })),
  );

  await Promise.all(
    requestStack.create.map((req, i) =>
      req?.then(({ data }) => (instructionStack.create[i] = { ...instructionStack.create[i], ...data })),
    ),
  );

  return [...instructionStack.update, ...instructionStack.create];
}
