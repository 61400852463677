import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import useTranslation from '@hooks/useTranslation';
import { useInstallationInfo } from '../useInstallationInfo';
import { ClientIdModal } from './ClientIdModal';

export const ClientIdDefinition = ({ name }: { name: string }) => {
  const { client_id } = useInstallationInfo();
  const { t } = useTranslation();

  return (
    <Definition
      label="common.client_id"
      value={client_id || t('common.none')}
      modal={{
        node: <ClientIdModal name={name} />,
        title: 'monitoring.installation.external_ref_modify',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
