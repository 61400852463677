import React from 'react';
import { FilterContainer } from '@GDM/Filters';
import { Input } from '@GDM/forms';
import { useFilters } from './useFilters';

export const BookFilters = () => {
  const { bookName, setBookName } = useFilters();

  return (
    <FilterContainer size="fit">
      <Input
        autoComplete="off"
        icon="Search"
        placeholder="common.filters.book_name"
        value={bookName}
        onChange={(e) => setBookName?.(e.target.value)}
      />
    </FilterContainer>
  );
};
