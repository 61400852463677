import React, { useDeferredValue, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button/Button';
import { Chart } from '@GDM/Chart/Chart';
import { Table } from '@GDM/Table/Table';
import { TableBody } from '@GDM/Table/TableBody/TableBody';
import { TableHead } from '@GDM/Table/TableHead/TableHead';
import { useQuery } from '@tanstack/react-query';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { v2_green_star_risks_path, v2_green_star_valuations_path } from '@utils/routes';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Control, UseFormReturn } from 'react-hook-form';
import {
  FOCUS_OPTIONS,
  VALUATION_METRICS_PER_TYPE,
  SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS,
  VALUATION_METRICS_FORM_NAMES_TO_LABELS,
  SENSITIVITY_METRICS_PER_TYPE,
} from '../constants';
import styles from '../styles.module.scss';
import { RiskAnalysisReportType, GreenstarReportData, RiskAnalysisFormFilters } from '../types';
import { useRiskAnalysisTable } from './hooks/table';
import { useRiskAnalysisCharts } from './hooks/useCharts';
import { useFormatReportData } from './hooks/useFormatReportData';

export const RiskAnalysisReport = ({
  type,
  areFiltersReady,
  form: { setValue, watch, control },
}: {
  areFiltersReady: boolean;
  type: RiskAnalysisReportType;
  form: UseFormReturn<RiskAnalysisFormFilters>;
}) => {
  const display = watch('display');
  const splitBy = watch('splitBy');
  const splitByMetric = splitBy !== 'split_by_focus';
  const metricGroup = watch('metricGroup');
  const focus = watch(['primaryFocus', 'secondaryFocus']);

  const defferedeMetricGroup = useDeferredValue(metricGroup);

  useEffect(() => {
    if (metricGroup === defferedeMetricGroup) return;

    if (['price', 'mtom'].includes(metricGroup) && splitBy === 'split_by_focus') {
      setValue('splitBy', 'split_by_metric');
    }

    if (metricGroup === 'all') {
      setValue('display', 'table');
    } else setValue('display', 'chart');

    if (type === 'sensitivity' && display === 'chart') {
      setValue('splitBy', 'split_by_metric');
    }

    if (type === 'sensitivity' && display === 'table') {
      setValue('splitBy', 'split_by_focus');
    }
  }, [metricGroup, defferedeMetricGroup, splitBy, display, setValue, type]);

  const isReportNested = Boolean(watch('secondaryFocus')) || type === 'sensitivity';

  const {
    primaryFocus,
    secondaryFocus,
    grappe,
    holding,
    spv,
    installations,
    energy,
    contract_type,
    apply_ownership_percentage,
    version,
    period,
  } = watch();

  const latestStatuses = ['latest', 'latest_vs_last_month', 'latest_vs_last_year'];

  const status = latestStatuses.includes(version) ? 'latest' : version;
  let compare: string | undefined;

  if (version === 'latest_vs_last_month') {
    compare = 'last_month';
  } else if (version === 'latest_vs_last_year') {
    compare = 'last_year';
  }

  const generatedRoute = type === 'valuation' ? v2_green_star_valuations_path : v2_green_star_risks_path;

  const options = {
    primaryFocus,
    secondaryFocus,
    grappe,
    holding,
    spv,
    energy,
    contract_type,
    apply_ownership_percentage,
    start_date: period[0],
    end_date: period[1],
    site_name: installations,
    status,
    compare,
  };

  const url = generatedRoute(options);
  const exportUrl = generatedRoute({ ...options, format: 'xls' });

  const reportQuery = useQuery({
    queryKey: [url],
    enabled: areFiltersReady,
    queryFn: async (): Promise<GreenstarReportData> => {
      const response = await fetch(url);

      return response.json();
    },
  });

  const [lastValidData, setLastValidData] = useState(reportQuery.data);

  if (reportQuery.data && reportQuery.data !== lastValidData) {
    setLastValidData(reportQuery.data);
  }

  const data = areFiltersReady ? reportQuery.data : lastValidData;

  return (
    <div>
      {type === 'valuation' && (
        <RiskAnalysisValuationReportHeader
          control={control}
          exportUrl={exportUrl}
          focus={focus}
          metricGroup={metricGroup}
        />
      )}
      {type === 'sensitivity' && <RiskAnalysisSensitivityReportHeader control={control} exportUrl={exportUrl} />}
      {data && (
        <RiskAnalysisReportVisualisation
          data={data}
          isReportNested={isReportNested}
          isSplitByMetric={splitByMetric}
          type={type}
          metricGroup={metricGroup}
          display={display}
        />
      )}
    </div>
  );
};

const RiskAnalysisReportVisualisation = ({
  data: rawData,
  isReportNested,
  isSplitByMetric,
  type,
  metricGroup,
  display,
}: {
  data: GreenstarReportData;
  isReportNested: boolean;
  isSplitByMetric: boolean;
  type: RiskAnalysisReportType;
  metricGroup: RiskAnalysisFormFilters['metricGroup'];
  display: RiskAnalysisFormFilters['display'];
}) => {
  const [expanded, setExpanded] = useState({});
  const data = useFormatReportData(rawData, isReportNested, isSplitByMetric);
  const { columns, rows } = useRiskAnalysisTable(data, isReportNested);

  const metricsToLabel =
    type === 'valuation' ? VALUATION_METRICS_FORM_NAMES_TO_LABELS : SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS;

  const metricsPerType = type === 'valuation' ? VALUATION_METRICS_PER_TYPE : SENSITIVITY_METRICS_PER_TYPE;

  const columnFilters = useMemo<[{ id: string; value: string[] }] | []>(() => {
    if (metricGroup === 'all') {
      return [];
    } else {
      const blackList = Object.entries(metricsPerType).reduce<string[]>(
        (list, [metricType, metrics]) => (metricType === metricGroup ? list : [...list, ...metrics]),
        [],
      );

      return [
        {
          id: 'name',
          value: blackList.map((metric) => {
            return metricsToLabel[metric as keyof typeof metricsToLabel];
          }),
        },
      ];
    }
  }, [metricGroup, metricsPerType, metricsToLabel]);

  const table = useReactTable({
    columns,
    data: rows,
    initialState: {
      expanded: true,
    },
    state: {
      expanded,
      columnFilters,
    },
    enableExpanding: true,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useLayoutEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  const charts = useRiskAnalysisCharts({
    data,
    isReportNested,
    splitByMetric: isSplitByMetric,
    metricsBlackList: columnFilters?.[0]?.value || [],
  });

  return (
    <div className="p-4 pt-3">
      {display === 'table' && (
        <Table>
          <TableHead table={table} />
          <TableBody table={table} />
        </Table>
      )}
      {display === 'chart' && (
        <div className={styles['chart-grid']}>
          {charts.map((chart) => (
            <Chart {...chart} key={chart.key} />
          ))}
        </div>
      )}
    </div>
  );
};

const RiskAnalysisValuationReportHeader = ({
  exportUrl,
  control,
  focus: [primaryFocus, secondaryFocus],
  metricGroup,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisFormFilters>;
  focus: [string, string | undefined];
  metricGroup: RiskAnalysisFormFilters['metricGroup'];
}) => {
  const [isNestedMode, setIsNestedMode] = useState(false);

  return (
    <div className={classNames(styles['display-filters'], 'pt-3')}>
      <div className="d-flex gap-2">
        <ControlledSelect
          name="primaryFocus"
          control={control}
          options={FOCUS_OPTIONS.filter(({ value }) => value !== secondaryFocus)}
          placeholder="common.focus"
          className={styles.select}
        />
        {isNestedMode ? (
          <>
            <ControlledSelect
              name="secondaryFocus"
              control={control}
              options={FOCUS_OPTIONS.filter(({ value }) => value !== primaryFocus)}
              placeholder="common.focus"
              isClearable
              className={styles.select}
              shouldUnregister
            />
            <Button
              icon="Trash2"
              variant="secondary"
              tooltip="common.delete"
              onClick={() => setIsNestedMode(false)}
              className="ml-1"
              size="xs"
              floating
            />
          </>
        ) : (
          <Button
            icon="Plus"
            text="risk_analysis.add_focus"
            onClick={() => setIsNestedMode(true)}
            variant="link"
            size="sm"
          />
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex gap-4">
          <ControlledRadioButtons
            name="metricGroup"
            control={control}
            options={[
              { label: 'risk_analysis.all', value: 'all' },
              { label: 'risk_analysis.volume', value: 'volume' },
              { label: 'risk_analysis.price', value: 'price' },
              { label: 'risk_analysis.m_to_m', value: 'mtom' },
            ]}
          />
          <ControlledRadioButtons
            name="splitBy"
            control={control}
            options={[
              { label: 'risk_analysis.split_by_focus', value: 'split_by_focus' },
              { label: 'risk_analysis.split_by_metric', value: 'split_by_metric' },
            ]}
            disabled={['mtom', 'price'].includes(metricGroup)}
          />
        </div>
        <div className="d-flex">
          <ControlledRadioButtons
            name="display"
            control={control}
            options={[
              { label: 'common.table', value: 'table' },
              { label: 'common.chart', value: 'chart' },
            ]}
          />
          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
            onClick={debounce(() => {
              window.location.href = exportUrl;
            })}
          />
        </div>
      </div>
    </div>
  );
};

const RiskAnalysisSensitivityReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisFormFilters>;
}) => {
  return (
    <div className={classNames(styles['display-filters'], 'pt-3')}>
      <div>
        <ControlledSelect
          name="primaryFocus"
          control={control}
          options={FOCUS_OPTIONS}
          placeholder="common.focus"
          className={styles.select}
        />
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex">
          <ControlledRadioButtons
            name="metricGroup"
            control={control}
            options={[
              { label: 'risk_analysis.all', value: 'all' },
              { label: 'Price Outright', value: 'price_outright' },
              { label: 'Profile Risk', value: 'profile_risk' },
              { label: 'Balancing Risk', value: 'balancing_risk' },
            ]}
          />
        </div>
        <div className="d-flex">
          <ControlledRadioButtons
            name="display"
            control={control}
            options={[
              { label: 'common.table', value: 'table' },
              { label: 'common.chart', value: 'chart' },
            ]}
          />

          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
            onClick={debounce(() => {
              window.location.href = exportUrl;
            })}
          />
        </div>
      </div>
    </div>
  );
};
