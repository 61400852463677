import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

export type FieldDefinition = { required?: boolean };
export type RegisteredSectionFields = Map<string, FieldDefinition>;
type RegisteredSectionFieldsContext = {
  fields: RegisteredSectionFields;
  setFields: Dispatch<SetStateAction<Map<string, FieldDefinition>>>;
};
const registeredFields = createContext<RegisteredSectionFieldsContext>({
  fields: new Map<string, FieldDefinition>(),
  setFields: () => {},
});

/** Returns all the registered fields tracked by a `RegisteredFieldsTracker`.
 *
 * Depends on having `RegisteredFieldsTracker` higher in the component hierarchy*/
export const useFieldRegistrationTrackerListener = () => {
  const { fields } = useContext(registeredFields);

  return fields;
};

/** Activates the field registration tracking.
 *
 * Depends on having `RegisteredFieldsTracker` higher in the component hierarchy*/
export const useFieldRegistrationTracker = <
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>({
  name,
  rules,
}: {
  name: string;
  rules?: UseControllerProps<TFieldValues, TFieldName>['rules'];
}) => {
  const { setFields } = useContext(registeredFields);
  const required = Boolean(rules?.required);

  useEffect(() => {
    setFields((prev) => {
      const next = prev.set(name, { required });

      return new Map(next);
    });

    return () => {
      setFields((prev) => {
        const next = new Map(prev);
        next.delete(name);

        return next;
      });
    };
  }, [name, required, setFields]);
};

/** Boundary below which every form field register will be tracked */
export const RegisteredFieldsTracker = ({
  children,
  onChange,
}: {
  children: ReactNode;
  onChange?: (fields: Map<string, FieldDefinition>) => void;
}) => {
  const [fields, setFields] = useState(() => new Map<string, FieldDefinition>());
  const state = useMemo(() => ({ fields, setFields }), [fields]);

  useEffect(() => {
    onChange?.(fields);
  }, [fields, onChange]);

  return <registeredFields.Provider value={state}>{children}</registeredFields.Provider>;
};
