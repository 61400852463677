import React, { useMemo } from 'react';
import ControlledCheckbox from '@components/FormInputs/ControlledCheckbox';
import { useUser } from '@context/User.context';
import { Chart } from '@GDM/Chart';
import { ExtendableBox } from '@GDM/ExtendableBox';
import useTranslation from '@hooks/useTranslation';
import { LOCALIZED_SHORT_MONTHS } from '@utils/constants/localizedShortMonths';
import { getSymbol } from '@utils/currency/getSymbol';
import { isANumber } from '@utils/isANumber';
import { appendColon } from '@utils/string';
import dayjs from 'dayjs';
import Highcharts, { type SeriesColumnOptions } from 'highcharts/highstock';
import { useForm } from 'react-hook-form';
import { mapToDataPoint } from '../helpers/mapToDataPoint';
import { isGroupedData } from '../helpers/type-helpers';
import { useRevenueContext } from '../hooks/useRevenueContext';
import type { Point } from '../revenue.types';
import { CURRENCY_FORMATTING_OPTIONS } from './chart-config-enums';

export const RevenueChartByMonth = () => {
  const { t } = useTranslation();
  const { revenue, isLoading, selectedCurrency } = useRevenueContext();
  const { locale } = useUser();
  const showColumnForm = useForm<ShowColumnForm>();
  const showColumn = showColumnForm.watch();

  const currencyOptions = CURRENCY_FORMATTING_OPTIONS;

  currencyOptions.currency = selectedCurrency || 'EUR';

  const nonGroupedRevenueData = !isGroupedData(revenue) ? revenue?.map(mapToDataPoint) || [] : [];

  const series = useSeries(nonGroupedRevenueData, showColumn);

  const options = {
    chart: { type: 'column' },
    tooltip: {
      shared: true,
      headerFormat: `${t('common.revenues')}<br/>`,
      pointFormatter() {
        const isForecast = new Date(this.name).valueOf() > Date.now();
        const name = `${this.series.name}${isForecast ? ` (${t('common.forecast')})` : ''}`;

        return `<span style="color: ${this.color}">●</span> ${appendColon(name, locale)} <b>${
          isANumber(this.y) ? new Intl.NumberFormat(locale, currencyOptions).format(this.y) : '--'
        } ${this.series.options.tooltip?.valueSuffix || ''}</b><br/>`;
      },
    },
    xAxis: {
      labels: {
        formatter: function () {
          const value = Number(this.value);

          return t(LOCALIZED_SHORT_MONTHS[value]);
        },
      },
    },
    yAxis: { title: { text: `${t('common.revenues')} (${getSymbol(selectedCurrency || 'EUR')})` }, opposite: true },
  } satisfies Highcharts.Options;

  return (
    <div>
      <ExtendableBox>
        <div className="d-flex align-items-center gap-3">
          {series.map((serie, index) => {
            const colorIndex = index % colors.length;

            if (!serie.name) return null;

            return (
              <ControlledCheckbox
                defaultValue
                control={showColumnForm.control}
                name={serie.name}
                key={serie.name}
                label={serie.name}
                color={colors[colorIndex]}
              />
            );
          })}
        </div>
      </ExtendableBox>
      <Chart options={options} series={series} isLoading={isLoading} hideLegend />
    </div>
  );
};

const colors = [
  'var(--chart-blue)',
  'var(--chart-yellow)',
  'var(--chart-revenue-alpha)',
  'var(--chart-aqua)',
  'var(--chart-purple)',
  'var(--chart-orange)',
];

type ShowColumnForm = { [year: string]: boolean };

export const groupRevenuesByYear = (data: Point[]) => {
  return data
    .toSorted((a, b) => new Date(a.x).valueOf() - new Date(b.x).valueOf())
    .reduce<{ [year: string]: Point[] }>((revenuesByYear, item) => {
      const year = new Date(item.x).getFullYear();

      if (!revenuesByYear[year]) revenuesByYear[year] = [];

      revenuesByYear[year] = [...revenuesByYear[year], item];

      return revenuesByYear;
    }, {});
};

export const groupByYearSeries = (data: Point[], showColumn: ShowColumnForm) => {
  const dataByYear = groupRevenuesByYear(data);

  const series: SeriesColumnOptions[] = Object.keys(dataByYear).map((year, index) => {
    const points: Point[] = dataByYear[year].map((point) => {
      const seriesOptionsType: Point = { y: point.y, x: dayjs(point.x).month() };

      return seriesOptionsType;
    });

    return {
      name: year,
      type: 'column',
      data: points,
      visible: showColumn[year],
      color: colors[index % colors.length],
    };
  });

  return series;
};

const useSeries = (data: Point[], showColumn: ShowColumnForm) => {
  return useMemo(() => groupByYearSeries(data, showColumn), [data, showColumn]);
};
