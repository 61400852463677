import { getBooks } from '@api/books';
import { getBuyersWithContract } from '@api/buyers';
import { getContracts } from '@api/contracts';
import { useUser } from '@context/User.context';
import { useQuery } from '@tanstack/react-query';
import getContractTypeLabel from '@utils/contract/getLabel';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract, ContractInstallation, ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';

type ContractBasedOptions = {
  contracts: Option<string>[];
  installations: Option<string>[];
  countries: Option<CountryCode>[];
};

type ContractBasedStores = {
  contractTypes: Set<ContractType>;
  installations: Map<string, ContractInstallation>;
  countries: Set<CountryCode>;
};

export function useContractsBasedStores() {
  return useQuery<ContractBasedStores>({
    queryKey: ['contracts_list'],
    queryFn: async () => {
      const contractsList = await getContracts();

      if (!contractsList) {
        return {
          installations: new Map<string, ContractInstallation>(),
          contractTypes: new Set<ContractType>(),
          countries: new Set<CountryCode>(),
        };
      }

      const stores = createContractsBasedStores(contractsList);

      return stores;
    },
  });
}

export function useContractsBasedOptions(stores: ContractBasedStores | undefined): ContractBasedOptions {
  const user = useUser();

  if (!stores) {
    return { contracts: [], installations: [], countries: [] };
  }

  const contracts = [...stores.contractTypes]
    .map((value) => ({
      value,
      label: getContractTypeLabel(value, user.main_country || 'FR'),
    }))
    .sort((a, b) => sortOptionsByLabelAsc(a, b, true));

  const installations = [...stores.installations.values()]
    .map((installation) => ({
      label: installation.name,
      value: installation.uuid,
    }))
    .sort((a, b) => sortOptionsByLabelAsc(a, b, true));

  const countries = [...stores.countries].map((country) => ({
    label: `countries.${country}`,
    value: country,
  }));

  return { contracts, installations, countries };
}

export function useBookOptions() {
  return useQuery<Option<string>[]>({
    queryKey: ['book_options'],
    queryFn: async () => {
      const books = await getBooks();

      return books
        .map(({ name, uuid }) => ({
          label: name,
          value: uuid,
        }))
        .sort((a, b) => sortOptionsByLabelAsc(a, b, true));
    },
  });
}

export function useBuyersOptions() {
  return useQuery<Option<string>[]>({
    queryKey: ['buyers_options'],
    queryFn: async () => {
      const buyers = await getBuyersWithContract();

      return buyers
        .map(({ short_name, long_name }) => ({
          label: short_name,
          value: long_name,
        }))
        .sort((a, b) => sortOptionsByLabelAsc(a, b, true));
    },
  });
}

function createContractsBasedStores(contracts: Contract[]) {
  return contracts.reduce(
    ({ installations, contractTypes, countries }, contract) => {
      return {
        installations: installations.set(contract.installation.uuid, contract.installation),
        contractTypes: contractTypes.add(contract.type),
        countries: contract.installation.country ? countries.add(contract.installation.country) : countries,
      };
    },
    {
      installations: new Map<string, ContractInstallation>(),
      contractTypes: new Set<ContractType>(),
      countries: new Set<CountryCode>(),
    },
  );
}
