import React from 'react';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './simulation-settings.module.scss';
import { SimulationSettingsBook } from './SimulationSettingsBook';
import { SimulationSettingsInstallation } from './SimulationSettingsInstallation';

export const SimulationSettings = () => {
  const project = useProjectContext();

  return (
    <div className={styles['settings-container']}>
      {project.isBook && <SimulationSettingsBook />}
      {!project.isBook && <SimulationSettingsInstallation />}
    </div>
  );
};
