import React, { useContext, useEffect, useState } from 'react';
import { Restricted } from '@components/Restricted';
import contractsContext from '@context/contracts.context';
import { Alert } from '@GDM/Alert';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { SlideInPanel } from '@GDM/SlideInPanel';
import { contractTypeList, iconDictionary } from '@utils/constants';
import { v2_contract_path } from '@utils/routes';
import { Contract } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { SlideInForm } from './SlideInForm';

export const ContractSlideInPanel = () => {
  const [contract, setContract] = useState<Contract | null | undefined>(null);
  const { selectedContract, setSelectedContract } = useContext(contractsContext);

  // This value has to be transitioned 500ms after the selected contract changes because
  // there's a slide-in transition that's 500ms long and when closing, all the content will
  // disappear before the panel has slid back in.
  useEffect(() => {
    const timeout = selectedContract ? 0 : 500;
    // Spread the contract object to avoid mutating the original object
    const transition = setTimeout(() => setContract(selectedContract ? { ...selectedContract } : null), timeout);

    return () => clearTimeout(transition);
    // Because the reference to the selectedContract can change but not the content,
    // we only want to update when the id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract?.id]);

  const contractTypeDefinitions = contractTypeList[contract?.installation?.country as CountryCode]?.find(
    (contractType) => contractType.value === contract?.type,
  );

  const contractTypeName = contractTypeDefinitions?.label;

  const titleNode = (
    <>
      <Icon name={contract?.installation?.country || 'XX'} size={12} className="mr-2" />
      <Icon name={iconDictionary[contract?.installation?.energy || 'other']} size={14} className="mr-2" />
      <span>{contract?.installation.name}</span>
      {contract?.status === 'draft' && <Badge variant="danger" label="common.draft" className="ml-2" />}
      <Badge label={contractTypeName} className="ml-2" />
    </>
  );

  return (
    <SlideInPanel
      data-cy="contract-slide-in"
      actions={
        <Restricted permissions={['display:edit_salesfocus']}>
          <Button
            icon="Edit3"
            tooltip="common.edit"
            data-place="bottom"
            href={contract ? `${v2_contract_path(contract?.id)}/edit` : undefined}
            floating
          />
        </Restricted>
      }
      isOpen={!!selectedContract}
      title={titleNode}
      toggle={() => {
        setSelectedContract?.(null);
        window.location.hash = '';
      }}
    >
      {selectedContract && <SlideInForm contract={contract} />}

      {!contract && <Alert variant="danger" label="errors.unknown_error" />}
    </SlideInPanel>
  );
};
