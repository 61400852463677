import React from 'react';
import { ExpanderCell } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { useRevenueContext } from '../../hooks/useRevenueContext';
import { TableData } from '../../revenue.types';
import { getFormattedUnit } from './unit-mapping';

export const FirstColumn = ({
  row,
  isLoading,
  label,
}: {
  row: CellContext<TableData, unknown>;
  isLoading: boolean;
  label: string;
}) => {
  const { t } = useTranslation();
  const { resolution, sourceType, selectedCurrency } = useRevenueContext();

  if (isLoading) return <>--</>;

  const _unit = selectedCurrency || row.row.original?.unit || null;
  let unit = _unit ? getFormattedUnit(_unit) || '€' : '€';

  if ((resolution === 'monthly' || sourceType === 'book') && row.row.original.unit === 'kWh') {
    unit = 'MWh';
  }

  const cellText = row.row.original.isOverviewRow ? `${t(label)} (${unit})` : t(label);

  return <ExpanderCell className="fit" label={cellText} {...row} />;
};
