import React from 'react';
import { NumberCell } from '@components/ui/table/cell/NumberCell';
import { useUser } from '@context/User.context';
import { Colon } from '@GDM/Colon';
import { AbsoluteValueWithPercentageCell } from '@GDM/Table/Cells/AbsoluteValueWithPercentageCell';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import getNumberColorLegacy from '@utils/getNumberColorLegacy';
import { MeterInvoice } from '@utils/types/meter-invoice';

type Props = CellContext<MeterInvoice, unknown> & {
  type: 'raw' | 'corrected';
};

export const LoadcurveCell = ({ type, getValue, row: { original } }: Props) => {
  const { t } = useTranslation();
  const { locale } = useUser();
  const fetchedValue = getValue();
  const value = typeof fetchedValue === 'number' ? fetchedValue : null;
  const numberFormat = new Intl.NumberFormat(locale);
  const productionLoadCurve = (original?.volume_production_load_curve || 0) - (value || 0);

  const tooltip = `${t(`monitoring.invoicing.prod_from_${type}_load_curve`)}:<br /> ${numberFormat.format(
    productionLoadCurve,
  )} kWh`;

  const billableProd = original.volume_production;
  const percentage = value && billableProd ? (value / billableProd) * 100 : null;

  return (
    <div>
      <AbsoluteValueWithPercentageCell
        value={value}
        percentage={percentage}
        colorNumber
        valueTooltip={tooltip}
        percentageTooltip="monitoring.invoicing.delta_percentage_tooltip"
      />
      {original.volume_produced_at_neg_prices && original.volume_produced_at_neg_prices !== 0 ? (
        <div className="d-flex">
          <span style={{ fontWeight: 400 }} className="ml-auto mr-1">
            {t('monitoring.invoicing.negative_price')} :
          </span>
          <NumberCell
            color={getNumberColorLegacy(original.volume_produced_at_neg_prices)}
            value={original.volume_produced_at_neg_prices}
          />
        </div>
      ) : null}

      {original.volume_clipped && original?.volume_clipped !== 0 ? (
        <div className="ta:right ellipsis d-flex">
          <span style={{ fontWeight: 400 }}>
            {t('monitoring.invoicing.clipping')}
            <Colon />
            &nbsp;
          </span>
          <NumberCell color={getNumberColorLegacy(original.volume_clipped)} value={original.volume_clipped} />
        </div>
      ) : null}
    </div>
  );
};
