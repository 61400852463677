import React, { useState } from 'react';
import Page from '@pages/Page/Page';
import { User } from '@utils/types/user';
import CustomReportingFilters from './Filters/Filters';
import Report, { CustomReportProps } from './Report/CustomReport';

const CustomReporting = ({ user }: { user: User }) => {
  const [reportProps, setReportProps] = useState<CustomReportProps>();

  return (
    <Page title="reporting.custom_reporting" user={user} layout="no-background" mainClassName="classic">
      <CustomReportingFilters onReportGenerate={setReportProps} />
      {reportProps && <Report {...reportProps} />}
    </Page>
  );
};

export default CustomReporting;
