import { CommonRequestHookResponse, post, useRequest } from '@hooks/useRequest';
import { swap_meter_v2_invoice_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { SwapMeterBody } from './swapMeterModal.types';

export const useSwapMeterRequest = (meterId: MeterInvoice['uuid'] | null) => {
  const req = useRequest<CommonRequestHookResponse<{ message: 'ok' }>>(
    swap_meter_v2_invoice_path(meterId ?? 'none'),
    post,
    true,
  );

  const execute = (body: SwapMeterBody) => {
    req.execute?.(body);
  };

  return { ...req, execute };
};
