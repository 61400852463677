import React from 'react';
import { Modal } from '@GDM/Modal';
import { useInvoiceHistoryMergedWithVolumes } from '@hooks/requests/meter-invoices/useInvoiceHistoryMergedWithVolumes';
import useTranslation from '@hooks/useTranslation';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { TabId } from './invoices.types';

type Props = {
  invoicing: MeterInvoice | null;
  isOpen: boolean;
  toggle: () => void;
  Table: (props: {
    invoicings: MeterInvoice[] | null;
    loading: boolean;
    onOpenModal?: (invoicing: MeterInvoice) => void;
    inModal?: boolean;
    type?: TabId;
  }) => JSX.Element;
};

export const HistoryModal = ({ isOpen, toggle, Table, invoicing }: Props) => {
  const { data: invoiceHistory, loading } = useInvoiceHistoryMergedWithVolumes(invoicing);
  const { t } = useTranslation();

  if (!invoicing) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      header={`${invoicing.installation_name} ${t('sales_management.invoice_history')}`}
      scrollable
    >
      <Table invoicings={isOpen ? invoiceHistory : []} loading={loading} inModal />
    </Modal>
  );
};
