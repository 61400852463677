import React from 'react';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './nowrap-head-cell.module.scss';

export const NoWrapHeadCell = ({
  tooltip,
  translationKey,
  className,
}: {
  tooltip?: string;
  translationKey: string;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip tooltip={tooltip}>
      <div className={classNames(styles['nowrap-head-cell'], className)}>{t(translationKey)}</div>
    </Tooltip>
  );
};
