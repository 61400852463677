import React, { useMemo } from 'react';
import { ValueCard } from '@GDM/Card';
import { Colon } from '@GDM/Colon';
import { Col } from '@GDM/layout';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { exists } from '@utils/filters/exists';
import { formatSystemDate } from '@utils/formatters';
import { isANumber } from '@utils/isANumber';
import { v2_book_peak_period_metrics_path, v2_installation_peak_period_metrics_path } from '@utils/routes';
import { PeakPeriodMetric } from '@utils/types/peakPeriodMetric';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import { DateRange } from '../production.types';

const getUrl = (selectedDateRange: DateRange, identifier: string | undefined, isBook: boolean) => {
  if (!identifier) return null;

  const params = {
    start_date: formatSystemDate(dayjs(selectedDateRange?.[0]).startOf('d').toDate()),
    end_date: formatSystemDate(dayjs(selectedDateRange?.[1]).add(1, 'd').startOf('d').toDate()),
  };

  if (isBook) {
    return v2_book_peak_period_metrics_path(identifier, { params });
  }

  return v2_installation_peak_period_metrics_path(identifier, { params });
};

const getCleanedData = (
  data: PeakPeriodMetric[],
  key: keyof Pick<PeakPeriodMetric, 'capacity_price' | 'ncc' | 'realised_power'>,
) => {
  return data
    .filter((item) => isANumber(item[key]))
    .map((item) => item[key])
    .filter(exists);
};

const computeAverage = (data: number[]) => {
  if (data.length === 0) return null;

  return data.reduce((acc, item) => acc + item, 0) / data.length;
};

export const Pp2Widget = ({
  className,
  selectedDateRange,
  identifier,
  isBook,
}: {
  className: string;
  selectedDateRange: DateRange;
  identifier?: string;
  isBook: boolean;
}) => {
  const { t } = useTranslation();
  const { data } = useRequest<PeakPeriodMetric[]>(getUrl(selectedDateRange, identifier, isBook), 'GET');

  const numberOfDays = useMemo(() => Object.keys(groupBy(data, 'date')).length, [data]);

  if (!data) {
    return null;
  }

  const averagePp2 = computeAverage(getCleanedData(data, 'realised_power'));
  const averageNcc = computeAverage(getCleanedData(data, 'ncc'));
  const averageCapacityPrice = computeAverage(getCleanedData(data, 'capacity_price'));

  return (
    <Col className={className}>
      <ValueCard
        title="monitoring.installation.pp2"
        tooltip="monitoring.installation.pp2_card_info"
        value={isANumber(averagePp2) ? averagePp2 * 1000 : null}
        unit="kW"
      >
        <div>
          <b>{t('sales_management.ncc')}</b>
          <Colon />
          &nbsp;
          <ValueWithUnit value={isANumber(averageNcc) ? averageNcc * 1000 : null} unit="kW" />
        </div>
        <div>
          <b>{t('common.price')}</b>
          <Colon />
          &nbsp;
          <ValueWithUnit value={averageCapacityPrice} unit="EUR/MW" />
        </div>
        <div>
          <b>{t('monitoring.installation.pp2')}</b>
          <Colon />
          &nbsp;
          <span className="fw:600">{numberOfDays || 0}</span>{' '}
          <span className="fw:300">{t(numberOfDays > 1 ? 'common.days' : 'common.day')}</span>
        </div>
      </ValueCard>
    </Col>
  );
};
