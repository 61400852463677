import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';

const NotificationsActions = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Restricted permissions={['create:user_notification_settings']}>
      <div className="d-flex px-3">
        <Button
          size="xxs"
          variant="primary-2"
          className="ml-auto"
          data-cy="create-notification-button"
          icon="Plus"
          text="common.add_new"
          onClick={onClick}
        />
      </div>
    </Restricted>
  );
};

export default NotificationsActions;
