import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { Installation } from '@utils/types/installation';
import { Unavailability } from '@utils/types/unavailability';
import { UseFormReturn } from 'react-hook-form';
import { UnavailabilitiesFiltersType } from './type/filters';

type UnavailabilityFiltersContext = {
  form: UseFormReturn<UnavailabilitiesFiltersType> | null;
  filteredUnavailabilities: Unavailability[];
  filteredInstallations: Installation[]; // to compute total pmax
  chartVisible?: boolean;
  toggleChart?: Dispatch<SetStateAction<boolean>>;
};

export const unavailabilityFiltersContext = createContext<UnavailabilityFiltersContext>({
  form: null,
  filteredUnavailabilities: [],
  filteredInstallations: [],
  chartVisible: false,
});

export const useUnavailabilityFiltersContext = (): UnavailabilityFiltersContext =>
  useContext(unavailabilityFiltersContext);
