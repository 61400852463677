import React from 'react';
import { Select } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { Option } from '@utils/types/common-types';
import { EventType } from '@utils/types/unavailability';
import { useFormContext, Controller } from 'react-hook-form';
import { CreateModalForm } from '../../CreateModal/types';

export const EventTypeInput = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<CreateModalForm>();

  const options: Option<EventType>[] = [
    { label: t('monitoring.unavailabilities.maintenance'), value: 'maintenance' },
    { label: t('monitoring.unavailabilities.maintenance_producer'), value: 'maintenance_producer' },
    { label: t('monitoring.unavailabilities.maintenance_offtaker'), value: 'maintenance_offtaker' },
    { label: t('monitoring.unavailabilities.grid'), value: 'grid' },
    { label: t('monitoring.unavailabilities.failure'), value: 'failure' },
    { label: t('monitoring.unavailabilities.failure_producer'), value: 'failure_producer' },
    { label: t('monitoring.unavailabilities.failure_offtaker'), value: 'failure_offtaker' },
    { label: t('monitoring.unavailabilities.other'), value: 'other' },
    { label: t('monitoring.unavailabilities.major_force'), value: 'major_force' },
  ];

  return (
    <Controller
      control={control}
      name="event_type"
      render={({ field: { value, onChange } }) => (
        <Select
          onChange={(option) => onChange(option?.value)}
          selectedOption={value}
          label="monitoring.unavailabilities.event_type"
          options={options}
        />
      )}
    />
  );
};
