import React, { useEffect } from 'react';
import { BookNamesSelect } from '@components/Export/BookNamesSelect';
import { ExportDatesInput } from '@components/Export/ExportDatesInput';
import { MeasureTypesSelect } from '@components/Export/MeasureTypesSelect';
import { MeterNamesSelect } from '@components/Export/MeterNamesSelect';
import { PointsPerLineInput } from '@components/Export/PointsPerLineInput';
import { SampleSelect } from '@components/Export/SampleSelect';
import { Button } from '@GDM/Button';
import { Col, Row } from '@GDM/layout';
import { Text } from '@GDM/Text';
import useBooks from '@hooks/requests/useBooks';
import { useInstallations } from '@hooks/requests/useInstallations';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import { formatNumber } from '@utils/formatters';
import { User } from '@utils/types/user';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { ExportFormData } from './export.types';
import { getNumberOfPoints } from './getNumberOfPoints';
import { useExportDownload } from './useExportDowload';

const MAX_LIMIT = 10_000;
const numberOptions = { maximumFractionDigits: 0 };

const Export = ({ user }: { user: User }) => {
  const { locale } = user;
  const { data: books, isLoading: booksLoading } = useBooks();
  const { data: installations, loading: installationLoading } = useInstallations({
    type: 'operational',
    withMeterInfo: true,
  });
  const { t } = useTranslation(user.locale);
  const form = useForm<ExportFormData>({
    defaultValues: {
      dates: { startDate: dayjs().subtract(7, 'd').toDate(), endDate: dayjs().toDate() },
      meterNames: [],
      books: [],
      sample: '10mn',
      measureTypes: [],
      line_format: 1,
    },
    mode: 'onChange',
  });
  const formData = form.watch();
  const req = useExportDownload();
  const sample = formData.sample;
  const totalPoints = getNumberOfPoints(formData);
  const isDisabled = !form.formState.isDirty || !form.formState.isValid || totalPoints > MAX_LIMIT;

  const handleSubmit = (data: ExportFormData) => {
    req.execute?.(data);
  };

  // Side-effect, when request errors with fields, set the error in the form.
  useEffect(() => {
    if (req.loaded && req.error && req.error.field) {
      form.setError(req.error.field as keyof ExportFormData, { message: t(req.error.code) });
    }
  }, [req.error, t, form, req.loaded]);

  form.watch((data, { name }) => {
    if (
      (name === 'sample' && data.sample && ['hourly', 'daily', 'monthly', '1mn', '30mn'].includes(data.sample)) ||
      (name === 'measureTypes' && data.measureTypes && data.measureTypes.length > 1)
    ) {
      form.setValue('line_format', 1);
    }

    if (name === 'sample' && data.sample === '5mn' && data.line_format === 6) {
      form.setValue('line_format', 12);
    }

    if (name === 'sample' && data.sample === '10mn' && data.line_format === 12) {
      form.setValue('line_format', 6);
    }
  });

  const isLoading = installationLoading || booksLoading;

  return (
    <Page user={user} title="sidebar.admin_settings.data_export" isLoading={isLoading} useQueryClient={false}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className={classNames('p-3', { unavailable: isLoading })}>
          <Row>
            <Col md={4}>
              <ExportDatesInput />
            </Col>

            <Col md={4}>
              <MeterNamesSelect installations={installations || []} />
            </Col>

            <Col md={4}>
              <BookNamesSelect books={books || []} />
            </Col>

            <Col className="mt-3" md={6}>
              <SampleSelect />
            </Col>

            <Col className="mt-3" md={6}>
              <MeasureTypesSelect installations={installations || []} />
            </Col>

            {['1mn', '5mn', '10mn', '15mn'].includes(sample) && (
              <Col className="mt-3" md={12}>
                <PointsPerLineInput />
              </Col>
            )}

            <Col className="mt-3" md={12}>
              <Text
                type={totalPoints > MAX_LIMIT ? 'danger' : 'normal'}
                text={t(
                  totalPoints > MAX_LIMIT
                    ? 'monitoring.export.number_of_points_over'
                    : 'monitoring.export.number_of_points',
                  {
                    totalPoints: formatNumber(totalPoints, locale, numberOptions),
                    maxPoints: formatNumber(MAX_LIMIT, locale, numberOptions),
                  },
                )}
              />
              <Text size="sm" text="monitoring.export.number_of_points_explanation" />
            </Col>

            <Col className="mt-3 d-flex">
              {req.error && <Text type="danger" text={req.error.code || 'errors.unknown_error'} size="sm" />}

              <Button
                size="xs"
                variant="primary-2"
                type="submit"
                disabled={isDisabled}
                isLoading={req.loading}
                icon="Download"
                className="ml-auto"
                data-cy="export-submit-button"
              >
                {t('common.download')}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </Page>
  );
};

export default Export;
