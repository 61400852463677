import { Contract } from '@utils/types/contract';
import cloneDeep from 'lodash/cloneDeep';

export const findAndReplaceContracts =
  (contracts: Contract[]) =>
  (prevContracts: Contract[]): Contract[] => {
    if (prevContracts) {
      const clonedContracts = cloneDeep(prevContracts);

      contracts.forEach((contract) => {
        const index = clonedContracts.findIndex((c) => c.id === contract.id);

        if (index !== -1) {
          clonedContracts[index] = { ...contract };
        }
      });

      return clonedContracts;
    }

    return prevContracts;
  };
