import { useQuery } from '@tanstack/react-query';
import {
  v2_book_load_curves_path,
  v2_installation_load_curves_path,
  v2_market_player_load_curves_path,
} from '@utils/routes';
import type { DataPeriod } from '@utils/types/dataPeriod';
import { TimeSeries } from '@utils/types/timeSeries';
import {
  Corrected5YearsAverageActiveEnergyProduction,
  ProductionTimeSeries,
  ProductionTimeSeriesRecord,
  type ProductionData,
} from '../production.types';

// This hook gives (most of) the data needed for the production tab
// You do not need to put the result of this hook in a context
// If you REALLY need this data deep in the component tree, just reuse the hook
// And React query should be able to get the result from its in memory cache
export const useProductionData = ({
  identifier,
  start,
  stop,
  dataPeriod,
  type,
}: {
  identifier: string;
  start: Date | null;
  stop: Date | null;
  dataPeriod: DataPeriod;
  type: ProductionType;
}) => {
  const getUrl = productionURLs[type];

  return useQuery({
    queryKey: ['production_data', type, identifier, start, stop, dataPeriod],
    queryFn: async () => {
      const group_by = dataPeriod === 'minutes' ? undefined : dataPeriod;
      const url = getUrl(identifier, { start, stop, group_by });
      const response = await fetch(url);
      const data: ProductionTimeSeries[] | { error: string } = await response.json();

      const production = isValidData(data) ? mapProductionTimeSeriesListToProductionTimeSeriesRecord(data) : {};
      const marketPrices = isValidData(data) ? getMarketPriceCurves(data) : {};

      return { production, marketPrices };
    },
    enabled: Boolean(start && stop),
  });
};

export type ProductionType = 'installation' | 'market_player' | 'book';

const productionURLs = {
  installation: v2_installation_load_curves_path,
  market_player: v2_market_player_load_curves_path,
  book: v2_book_load_curves_path,
};

// Transforms the list of timeseries into an object (record) with keys name matching the timeseries type.
const mapProductionTimeSeriesListToProductionTimeSeriesRecord = (
  curves: ProductionTimeSeries[],
): Partial<ProductionTimeSeriesRecord> => {
  return curves.reduce<Partial<ProductionTimeSeriesRecord>>((obj, curve) => {
    const values: TimeSeries = curve.values.map(({ time, value }) => [Date.parse(time), Number(value)]);

    let key: keyof ProductionTimeSeriesRecord | undefined;
    if (isCorrected5YearsAverageActiveEnergyProduction(curve)) {
      key = 'corrected5YearsAverageActiveEnergyProduction';
    } else {
      const { field, source, type } = curve;

      key = PRODUCTION_TIMESERIES_DEFINITION_TREE[type]?.[field]?.[source];
    }

    if (!key || values.length === 0) {
      return obj;
    }

    return { ...obj, [key]: { ...curve, values } };
  }, {});
};

// Type guard to distinguish regular ProductionTimeSeries & historical corrected average production
const isCorrected5YearsAverageActiveEnergyProduction = (
  curve: ProductionTimeSeries,
): curve is Corrected5YearsAverageActiveEnergyProduction =>
  (curve as Corrected5YearsAverageActiveEnergyProduction).custom_name === 'prod_corrected_avg_5y';

// A tree that maps ProductionTimeSeries variants from their type, field and source to camelCased key names
// used in the ProductionTimeSeriesRecord returned by useProduction data
// A path in the tree is indexed in the following order: type, field, source
const PRODUCTION_TIMESERIES_DEFINITION_TREE: {
  [key in ProductionTimeSeries['type']]?: Partial<
    Record<
      ProductionTimeSeries['field'],
      Partial<Record<ProductionTimeSeries['source'], keyof ProductionTimeSeriesRecord>>
    >
  >;
} = {
  active_energy: {
    production: {
      raw: 'rawActiveEnergyProduction',
      corrected: 'correctedActiveEnergyProduction',
      extrapolated: 'extrapolatedActiveEnergyProduction',
      forecast: 'forecastedActiveEnergyProduction',
      dispatch: 'dispatchedActiveEnergyProduction',
      invoice: 'invoicedActiveEnergyProduction',
      published: 'publishedActiveEnergyProduction',
      potential: 'potentialActiveEnergyProduction',
      business_plan: 'businessPlanActiveEnergyProduction',
    },
    consumption: {
      raw: 'rawActiveEnergyConsumption',
    },
    surplus: {
      raw: 'rawActiveEnergySurplus',
    },
    self_consumption: {
      raw: 'rawActiveEnergySelfConsumption',
    },
  },
  reactive_negative_energy: { production: { raw: 'rawReactiveNegativeEnergyProduction' } },
  reactive_positive_energy: { production: { raw: 'rawReactivePositiveEnergyProduction' } },
  tension: { production: { raw: 'rawTensionProduction' } },
  billable_energy: { production: { invoice: 'invoicedActiveEnergyProduction' } },
};

function isValidData(data: ProductionTimeSeries[] | { error: string }): data is ProductionTimeSeries[] {
  return !('error' in data);
}

const getMarketPriceCurves = (data: ProductionTimeSeries[]) => {
  const marketPriceCurves = data.filter((curve) => curve.type === 'energy_price');

  return marketPriceCurves.reduce((obj, curve) => {
    return { ...obj, [`${curve.country}_${curve.source}`]: fillMissingMarketPricesValues(curve.values) };
  }, {} as Record<string, TimeSeries>);
};

/**
 * This method will fill "missing" data points in the market prices time series to have at least one point every 10 minutes
 * It is based on the legacy code in useProductionLegacy hook
 */
const fillMissingMarketPricesValues = (values: ProductionData['values']): TimeSeries => {
  if (!values) return values;

  const timeSerie: TimeSeries = [];

  values.forEach(({ time, value }) => {
    for (let k = 0; k < 6; k++) {
      const date = Date.parse(time);

      timeSerie.push([date + (k * 1000 * 3600) / 6, value]);
    }
  });

  return timeSerie;
};
