import { isValidDate } from '@utils/isValidDate';
import dayjs from 'dayjs';

/** Converts anything to a date, the same way `new Date()` would, but taking the timezone into account. */
export function toDate(input: string | number | Date | null): Date {
  if (!input || !isValidDate(input)) {
    // eslint-disable-next-line no-console
    console.error('You tried to convert an invalid value to a Date object. Falling back to today.');

    return new Date();
  }

  return dayjs(input).set('h', 12).tz('UTC').toDate();
}
