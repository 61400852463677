import { ReportMetric } from '../types';

export const FRACTION_DIGITS_PER_METRIC: Record<ReportMetric, number> = {
  revenue: 2,
  production: 1,
  contract_nb: 0,
  unit_price: 2,
  business_plan: 1,
  power: 2,
};
