import { useCallback } from 'react';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { Mandate } from '@utils/types/mandate';
import cloneDeep from 'lodash/cloneDeep';
import { MandateFiltersType } from './MandatesFilter';

export const useMandateFilters = (allMandates: Mandate[] | null) => {
  const filterMandates = useCallback(
    (params: MandateFiltersType) => {
      if (Object.values(params).every((value) => value === '')) {
        return allMandates || [];
      }

      let filteredMandates = cloneDeep(allMandates || []);

      if (params.representative) {
        filteredMandates = matchSorterInstanceStrict(filteredMandates, params.representative, {
          keys: ['representative'],
        });
      }

      if (params.status) {
        filteredMandates = matchSorterInstanceStrict(filteredMandates, params.status, {
          keys: ['status'],
        });
      }

      return filteredMandates;
    },
    [allMandates],
  );

  return filterMandates;
};
