import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Colon } from '@GDM/Colon';
import useTranslation from '@hooks/useTranslation';
import { usePortfolio } from '@pages/Portfolio/usePortfolio';
import { InputRange } from '@ui/InputRange';
import { FiltersContext } from './filters.context';
import styles from './filters.module.scss';
import { useFilters } from './useFilters';

const PortfolioFilters = () => {
  const { t } = useTranslation();
  const { data, onlyConsumers } = usePortfolio();
  const { filters, setFilters } = useFilters();

  const puissanceCreteMax = useMemo(
    () => (data && data?.length > 0 ? Math.ceil(Math.max(...data.map((d) => d.puissancecrete || 0))) : 0),
    [data],
  );
  const energyMax = useMemo(
    () => (data && data?.length > 0 ? Math.ceil(Math.max(...data.map((d) => Number(d.sumdata) || 0))) : 0),
    [data],
  );

  const [localFilters, setLocalFilters] = useState({
    puiCrete: [0, puissanceCreteMax],
    energy: [0, energyMax],
    capacityFactor: [0, 100],
    coverage: [0, 100],
  });

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!data || !data.length) return;

    if (isFirstRun.current) {
      isFirstRun.current = false;

      const updatedFilters: FiltersContext['filters'] = {
        puiCrete: [0, Math.ceil(Math.max(...data.map((d) => d.puissancecrete || 0)))],
        energy: [0, Math.ceil(Math.max(...data.map((d) => Number(d.sumdata) || 0)))],
        capacityFactor: [0, Math.ceil(Math.max(...data.map((d) => d.capacity_factor || 0)))],
        coverage: [0, 100],
      };

      setFilters?.(updatedFilters);
      setLocalFilters(updatedFilters);

      return;
    }
  }, [data, setFilters]);

  if (!filters) return null;

  return (
    <div className={styles['portfolio-filters-container']}>
      {!onlyConsumers ? (
        <div className="mb-3">
          <div className="mb-2">
            {t('common.power_peak')}
            <Colon />
          </div>
          <InputRange
            max={puissanceCreteMax}
            values={localFilters.puiCrete}
            onFinalChange={(values) =>
              setFilters?.((prev) => ({ ...prev, puiCrete: values } as FiltersContext['filters']))
            }
            onChange={(values) => setLocalFilters?.((prev) => ({ ...prev, puiCrete: values as [number, number] }))}
          />
        </div>
      ) : null}

      <div className="mb-3">
        <div className="mb-2">
          {t('common.energy')}
          <Colon />
        </div>
        <InputRange
          max={energyMax}
          values={localFilters.energy}
          onFinalChange={(values) => setFilters?.((prev) => ({ ...prev, energy: values } as FiltersContext['filters']))}
          onChange={(values) => setLocalFilters?.((prev) => ({ ...prev, energy: values as [number, number] }))}
        />
      </div>

      {!onlyConsumers ? (
        <div className="mb-3">
          <div className="mb-2">
            {t('monitoring.portfolio.table.capacity_factor')}
            <Colon />
          </div>
          <InputRange
            max={100}
            values={localFilters.capacityFactor}
            onFinalChange={(values) =>
              setFilters?.((prev) => ({ ...prev, capacityFactor: values } as FiltersContext['filters']))
            }
            onChange={(values) =>
              setLocalFilters?.((prev) => ({ ...prev, capacityFactor: values as [number, number] }))
            }
          />
        </div>
      ) : null}

      <div className="mb-3">
        <div className="mb-2">
          {t('common.coverage')}
          <Colon />
        </div>
        <InputRange
          values={localFilters.coverage}
          onFinalChange={(values) =>
            setFilters?.((prev) => ({ ...prev, coverage: values } as FiltersContext['filters']))
          }
          onChange={(values) => setLocalFilters?.((prev) => ({ ...prev, coverage: values as [number, number] }))}
        />
      </div>
    </div>
  );
};

export default PortfolioFilters;
