import React from 'react';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

export const RegulToggleFilter = () => {
  const form = useInvoicesFilters();

  return (
    <ControlledToggle
      control={form.control}
      name="showRegul"
      label="common.filters.show_regul"
      data-cy="invoice-filters-regul-toggle"
      variant="primary-2"
    />
  );
};
