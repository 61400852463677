import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { v2_installation_events_path, v2_book_events_path, v2_events_path } from '@utils/routes';
import { Event } from '@utils/types/event';

export type Props = {
  eventTypes: Event['type'][] | null;
  eventableId: string | null | undefined; // name in case of installation eventable
  eventableType: Event['eventable_type'] | 'Book' | null | undefined;
  start: Date | null;
  stop: Date | null;
};

type Data = Event[];

export const useEvents = ({ eventTypes, eventableId, eventableType, start, stop }: Props): UseQueryResult<Data> => {
  return useQuery({
    queryKey: ['getEvents', eventableId, eventableType, start, stop, eventTypes],
    queryFn: async () => {
      const params = {
        event_types: eventTypes,
        start,
        stop,
      };

      const url =
        eventableType == 'Installation' && eventableId
          ? v2_installation_events_path(eventableId, params)
          : eventableType == 'Book' && eventableId
          ? v2_book_events_path(eventableId, params)
          : v2_events_path(params);
      const response = await fetch(url);
      const data: Data = await response.json();

      return data;
    },
    enabled: Boolean(start && stop && eventableId !== undefined),
  });
};
