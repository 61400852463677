import { formatSystemDate } from '@utils/formatters';
import { HedgeSubPeriodForm, HedgeSubPeriodFormBody } from '@utils/types/contract';

export function serializeHedgeBlock(
  hedgeBlock: HedgeSubPeriodForm,
  props?: Partial<HedgeSubPeriodFormBody>,
): HedgeSubPeriodFormBody {
  const block: HedgeSubPeriodForm = {
    ...hedgeBlock,
    ...props,
  };

  block.start_date = formatSystemDate(hedgeBlock.dates?.start_date || hedgeBlock.start_date);
  block.end_date = formatSystemDate(hedgeBlock.dates?.end_date || hedgeBlock.end_date);

  // These props aren't needed by the backend.
  delete block.dates;

  return block;
}
