import { Option } from '@utils/types/common-types';
import { GroupBase, StylesConfig } from 'react-select';

const SearchbarInputStyles: Partial<StylesConfig<Option<string>, boolean, GroupBase<Option<string>>>> = {
  control: (controlStyles) => ({
    ...controlStyles,
    border: 'none',
    height: '3.5rem',
    borderRadius: 0,
    boxShadow: 'none',
  }),
  option: (optionStyles) => ({
    ...optionStyles,
    padding: '0.25rem 1rem',
    cursor: 'pointer',
  }),
  menu: (menuStyles) => ({
    ...menuStyles,
    marginTop: '2px',
    border: 'none',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
    borderRadius: '0 0 3px 3px',
    padding: '0.5rem 0',
    zIndex: 101,
  }),
  menuList: (menuListStyles) => ({
    ...menuListStyles,
    maxHeight: '600px',
  }),
  group: (groupStyles) => ({
    ...groupStyles,
    padding: 0,
  }),
};

export default SearchbarInputStyles;
