import React from 'react';
import { useUser } from '@context/User.context';
import { Text } from '@GDM/Text';
import { formatNumber } from '@utils/formatters';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import styles from './update-modal.module.scss';

export const RevenueTotal = ({ totalRevenue, currency }: { totalRevenue: number; currency: Currency }) => {
  const { locale } = useUser();

  return (
    <div className={classNames(styles['revenues'], styles['revenues-total'], 'mt-3')}>
      <Text text="invoice.including_vat" className={styles['revenues-type-label']} />
      <div>
        {formatNumber(totalRevenue, locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          currency,
          style: 'currency',
        })}
      </div>
    </div>
  );
};
