import { dafr_v2_installation_path } from '@utils/routes';
import { CommonRequestHookResponse, useRequest } from '..';

export type Dafr = { year: number; production: number };

const useDafr = (name?: string): CommonRequestHookResponse<Dafr[]> => {
  return useRequest<Dafr[]>(dafr_v2_installation_path(name ?? ''), 'GET');
};

export default useDafr;
