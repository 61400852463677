import React from 'react';
import type { Granularity } from './constants/granularities';
import { GroupedByDateNegativePricesTable } from './GroupedByDateNegativePricesTable';
import type { GroupBy, NegativeHour } from './helpers/getFormattedNegativeHours';
import { HourlyNegativePricesTable } from './HourlyNegativePricesTable';
import type { NegativePrice } from './negative-prices.types';

export const NegativePricesTableTab = ({
  isLoading,
  negativeHours,
  prices,
  granularity,
  showInstallation,
  groupBy,
}: {
  isLoading: boolean;
  negativeHours: NegativeHour[];
  prices: NegativePrice[];
  granularity: Granularity;
  showInstallation: boolean;
  groupBy: GroupBy;
}) => {
  return (
    <div>
      {granularity === 'hourly' && (
        <HourlyNegativePricesTable prices={prices} isLoading={isLoading} showInstallation={showInstallation} />
      )}
      {['daily', 'monthly'].includes(granularity) && (
        <GroupedByDateNegativePricesTable
          negativeHours={negativeHours}
          isLoading={isLoading}
          granularity={granularity}
          groupBy={groupBy}
        />
      )}
    </div>
  );
};
