import {
  v2_book_invoice_revenues_path,
  v2_installation_invoice_revenues_path,
  v2_market_player_invoice_revenues_path,
} from '@utils/routes';
import { InvoiceRevenueRequestParameters, RevenueContextType } from '../revenue.types';

export const REVENUE_URLS: Record<
  NonNullable<RevenueContextType['sourceType']>,
  (string: string, options?: InvoiceRevenueRequestParameters) => string
> = {
  book: v2_book_invoice_revenues_path,
  installation: v2_installation_invoice_revenues_path,
  market_player: v2_market_player_invoice_revenues_path,
};
