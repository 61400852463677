import type { Granularity } from '@pages/DashboardMeter/Tabs/NegativePrices/constants/granularities';
import type { NegativePrice } from '@pages/DashboardMeter/Tabs/NegativePrices/negative-prices.types';
import fetchWrapper from '@utils/fetch';
import { formatSystemDate } from '@utils/formatters';
import {
  v2_book_installation_negative_prices_path,
  v2_installation_installation_negative_prices_path,
  v2_installation_negative_prices_path,
} from '@utils/routes';
import dayjs from 'dayjs';

export const fetchNegativePrices = async (
  startDate: Date | null,
  endDate: Date | null,
  granularity: Granularity,
  name?: string,
  isBook?: boolean,
  excludeNightHours?: boolean,
): Promise<Array<NegativePrice>> => {
  const url = getUrl(
    { params: { ...formatDateParams(startDate, endDate, granularity).params, exclude_night_hours: excludeNightHours } },
    isBook,
    name,
  );

  const req = await fetchWrapper(url);

  if (!req.ok) {
    throw new Error('Failed to fetch negative prices');
  }

  const data = await req.json();

  return data;
};

const getEndDate = (endDate: Date | null, granularity: Granularity) => {
  if (granularity === 'monthly') {
    return dayjs(endDate).add(1, 'month').startOf('month').toDate();
  }

  return dayjs(endDate).add(1, 'day').startOf('day').toDate();
};

const formatDateParams = (startDate: Date | null, endDate: Date | null, granularity: Granularity) => ({
  params: {
    start_time: formatSystemDate(startDate),
    end_time: formatSystemDate(getEndDate(endDate, granularity)),
    granularity,
  },
});

const getUrl = (
  params: { params: { start_time: string; end_time: string; granularity: Granularity; exclude_night_hours?: boolean } },
  isBook?: boolean,
  name?: string,
) => {
  if (!name) {
    return v2_installation_negative_prices_path(params);
  }

  if (isBook) {
    return v2_book_installation_negative_prices_path(name, params);
  }

  return v2_installation_installation_negative_prices_path(name, params);
};
