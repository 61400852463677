import get from 'lodash/get';
import { FieldValues } from 'react-hook-form';
import { FormField } from '../form.types';

export const isFieldRequired = <T extends FieldValues>(field: FormField<T>, values: Partial<T>): boolean => {
  let required = field.required || field.rules?.required;

  if (field.requiredForFieldValues) {
    const fieldsToCheck = Object.entries(field.requiredForFieldValues) as [
      FormField<FieldValues>['name'],
      typeof field.requiredForFieldValues,
    ][];

    required = fieldsToCheck.every(([fieldName, value]) => {
      const valueToCheck = get(values, fieldName);

      return valueToCheck === value || (Array.isArray(value) && value.includes(valueToCheck));
    });
  }

  return !!required;
};

export const getRequiredFields = <T extends FormField<FieldValues>>(fields: T[], values: Partial<FieldValues>): T[] => {
  return fields.filter((field) => isFieldRequired(field, values));
};
