import React, { useState } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getPaginationRowModel, getSortedRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { ContractConfirmDeleteModal } from '../ContractConfirmDeleteModal';
import styles from '../contracts.module.scss';
import { useColumns } from './useColumns';

export const ContractsTable = () => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const {
    removeContract,
    selectedToDeleteId,
    setSelectedToDeleteId,
    contracts,
    deleteError,
    isDeleting,
    resetDeleteError,
  } = useConsumeContracts();

  const { columns } = useColumns();

  const table = useReactTable({
    columns,
    data: contracts,
    sortDescFirst: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { sorting: [{ id: 'installation.name', desc: false }] },
  });

  const pageSize = pagination.pageSize;
  const pageCount = table.getPageCount();

  const closeDeleteModal = () => {
    setSelectedToDeleteId(null);
    resetDeleteError();
  };

  const handleDelete = async () => {
    const contract = contracts.find((c) => c.id === selectedToDeleteId);
    if (contract && removeContract) {
      const deleteHasError = await removeContract(contract);
      if (!deleteHasError) closeDeleteModal();
    }
  };

  return (
    <div className={styles['table-container']}>
      {selectedToDeleteId && (
        <ContractConfirmDeleteModal
          isOpen={selectedToDeleteId !== null}
          toggle={closeDeleteModal}
          handleDelete={handleDelete}
          error={deleteError ?? undefined}
          id={selectedToDeleteId}
          isLoading={isDeleting}
        />
      )}

      <Table data-cy="contracts-table" hasActions>
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      {contracts.length > pageSize && (
        <TableActions className="p-0">
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={contracts.length} />
          <TablePagination pageCount={pageCount} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </div>
  );
};
