import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, useFormContext } from 'react-hook-form';
import { UnavailabilitiesFiltersType } from './type/filters';

export const InstallationFilter = () => {
  const form = useFormContext<UnavailabilitiesFiltersType>();

  return (
    <Controller
      name="installationName"
      control={form.control}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value ?? ''}
          placeholder="common.filters.installation_name"
          icon="Search"
          full
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            field.onChange(e.currentTarget.value);
          }}
        />
      )}
    />
  );
};
