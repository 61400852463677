import axiosInstance from '@utils/axiosInstance';
import { getErrorList } from '@utils/parsers/get-error-list';
import { v2_installation_path } from '@utils/routes';
import { ContractForm, ContractType } from '@utils/types/contract';
import Installation from '@utils/types/installation';
import isEmpty from 'lodash/isEmpty';
import { ContractFormField } from '../../contracts.types';

export type ContractRequestError = { resource: ContractType; field: ContractFormField['name']; code: string };

/**
 * Side effects to the contract submission.
 *
 * This is run when there are requests that need to be made that are not part of the `Contract` model.
 *
 * > _e.g_: updating an installation siret on contract submission (the siret is saved on the `Installation`,
 * > not the `Contract`.)
 *
 * If all requests are ok, then this should return
 * en empty promise. Otherwise, it should return errors, which will block the rest of the saving process.
 *
 * **Note for posterity**: preferably, this kind of logic should be done in the backend.
 */
export async function handleSubmitSideEffects(
  data: ContractForm,
  installations?: Installation[] | null,
): Promise<void | ContractRequestError[]> {
  if (!data._non_contract_props || isEmpty(data._non_contract_props)) {
    // eslint-disable-next-line no-console
    console.error(
      `Dear developer, looks like you're trying to run some side-effects
       but didn't set them in the _non_contract_props.
       Please make sure all models that need to be updated
       are listed in the _non_contract_props object.`,
    );

    return;
  }

  // If there are updates to installations, make sure they are triggered here.
  if (data._non_contract_props?.installation?.siret && data.installation_uuid) {
    const installationName = installations?.find(({ uuid }) => uuid === data.installation_uuid)?.name;

    if (!installationName) return;

    try {
      // axiosInstance because we're not in a hook and can't use useRequest.
      await axiosInstance(false).patch<Partial<Installation>>(
        v2_installation_path(installationName),
        { installation: data._non_contract_props.installation },
        { headers: { accept: 'application/json' } },
      );
    } catch (e) {
      const errors = getErrorList<ContractRequestError>(e);

      if (errors && Array.isArray(errors)) return errors;
    }
  }
}
