import React from 'react';
import { NumberCell } from '@GDM/Table';
import { TranslateFn } from '@hooks/useTranslation';
import {
  ReportMetricData,
  ReportMetric,
  CustomReportingGlobalQuery,
  TimeBasedCustomReportingGlobalQuery,
  FocusType,
} from '@pages/CustomReporting/types';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { UserGeolocation } from '@utils/types/user';
import classNames from 'classnames';
import { FRACTION_DIGITS_PER_METRIC } from '../constants';
import styles from '../custom-reporting-report.module.scss';
import { formatReportDate, formatReportValue, turnReportItemNameIntoLabel } from '../utils';
import {
  TimeBasedReportTableData,
  FullPeriodReportTableData,
  NestedTimeBasedReportTableRow,
  ReportTableRow,
  TimeBasedReportTableRow,
  NestedReportTableRow,
} from './types';

export const createTimeBasedTableColumns = (
  data: ReportMetricData[],
  metrics: Record<ReportMetric, boolean>,
  query: TimeBasedCustomReportingGlobalQuery,
  aggregations: Record<string, number>,
  t: TranslateFn,
): AccessorKeyColumnDef<TimeBasedReportTableRow>[] => {
  const selectedData = data.find(({ metric }) => metrics[metric]);

  return [
    {
      id: query.focus,
      header: '',
      sortingFn: 'text',
      cell: ({ row, getValue }) => (
        <div className={classNames(row.depth && styles['nested-row'])}>{getValue<string>()}</div>
      ),
      accessorKey: 'name',
      footer: () => t('common.total'),
    },
    ...(selectedData?.items[0].data.map<AccessorKeyColumnDef<TimeBasedReportTableRow>>(({ date }) => {
      const dateKey = String(date || '');

      return {
        id: dateKey,
        header: () => formatReportDate(dateKey, query.date_split),
        cell: ({ getValue }) => (
          <NumberCell
            value={formatReportValue(getValue<number>(), selectedData.metric)}
            fractionDigits={FRACTION_DIGITS_PER_METRIC[selectedData.metric]}
          />
        ),
        accessorKey: `values.${dateKey}`,
        footer: () => (
          <NumberCell
            value={formatReportValue(aggregations[dateKey], selectedData.metric)}
            fractionDigits={FRACTION_DIGITS_PER_METRIC[selectedData.metric]}
          />
        ),
      };
    }) || []),
  ];
};

export const createFullPeriodTableColumns = (
  metrics: Record<ReportMetric, boolean>,
  focus: FocusType,
  rows: ReportTableRow[],
  aggregations: Record<string, number>,
  t: TranslateFn,
): AccessorKeyColumnDef<ReportTableRow>[] => {
  const cols: AccessorKeyColumnDef<ReportTableRow>[] = [
    {
      id: 'revenue',
      header: () => (
        <>
          <span>{t('common.revenue')} </span>
          <span className="normal-case">(k€)</span>
        </>
      ),
      cell: ({ getValue }) => (
        <NumberCell
          value={formatReportValue(getValue<number>(), 'revenue')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.revenue}
        />
      ),
      accessorKey: 'revenue',
      footer: () => (
        <NumberCell
          value={formatReportValue(aggregations.revenue, 'revenue')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.revenue}
        />
      ),
    },
    {
      id: 'production',
      header: () => (
        <>
          <span>{t('common.production')} </span>
          <span className="normal-case">(GWh)</span>
        </>
      ),
      cell: ({ getValue }) => (
        <NumberCell
          value={formatReportValue(getValue<number>(), 'production')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.production}
        />
      ),
      accessorKey: 'production',
      footer: () => (
        <NumberCell
          value={formatReportValue(aggregations.production, 'production')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.production}
        />
      ),
    },
    {
      id: 'contract_nb',
      header: 'common.contract_nb',
      accessorKey: 'contract_nb',
      cell: NumberCell,
      footer: () => <NumberCell value={aggregations.contract_nb} />,
    },
    {
      id: 'unit_price',
      header: () => (
        <>
          <span>{t('common.price')} </span>
          <span className="normal-case">(€/MWh)</span>
        </>
      ),
      cell: ({ getValue }) => (
        <NumberCell
          value={formatReportValue(getValue<number>(), 'unit_price')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.unit_price}
        />
      ),
      accessorKey: 'unit_price',
      footer: () => (
        <NumberCell
          value={formatReportValue(aggregations.unit_price, 'unit_price')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.unit_price}
        />
      ),
    },
    {
      id: 'business_plan',
      header: () => (
        <>
          <span>{t('monitoring.installation.revenue.business_plan')} </span>
          <span className="normal-case">(MWh)</span>
        </>
      ),
      cell: ({ getValue }) => (
        <NumberCell
          value={formatReportValue(getValue<number>(), 'business_plan')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.unit_price}
        />
      ),
      accessorKey: 'business_plan',
      footer: () => (
        <NumberCell
          value={formatReportValue(aggregations.business_plan, 'business_plan')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.unit_price}
        />
      ),
    },
    {
      id: 'power',
      header: () => (
        <>
          <span>{t('common.power')} </span>
          <span className="normal-case">(MW)</span>
        </>
      ),
      cell: ({ getValue }) => (
        <NumberCell
          value={formatReportValue(getValue<number>(), 'business_plan')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.unit_price}
        />
      ),
      footer: () => (
        <NumberCell
          value={formatReportValue(aggregations.power, 'power')}
          fractionDigits={FRACTION_DIGITS_PER_METRIC.unit_price}
        />
      ),
      accessorKey: 'power',
    },
  ];

  return [
    {
      id: focus,
      sortingFn: 'text',
      header: '',
      cell: ({ row, getValue }) => (
        <div className={classNames(row.depth && styles['nested-row'])}>{getValue<string>()}</div>
      ),
      accessorKey: 'name',
      footer: () => t('common.total'),
    },
    ...cols.filter(({ id }) => metrics[id as ReportMetric]),
  ];
};

/** Converts data from the back end into rows usable by react table */
export const createFullPeriodReportTableRows = (
  data: ReportMetricData[],
  { focus, split_by }: CustomReportingGlobalQuery,
  country: UserGeolocation | undefined,
  t: TranslateFn,
): ReportTableRow[] => {
  if (!data.length) return [];

  const [first, ...rest] = data;
  const initialRows: ReportTableRow[] = [];

  // We traverse the data of the first metric and at every step we traverse
  // all the other metrics by looking up the values that are at the same index
  return first.items.reduce((rows, { name, data, nested_data }, index) => {
    // We already have the data of the first metric in the reducer current value,
    // we have to get the data of the remaining metrics
    const initialRemainingMetricsValues: Partial<ReportTableRow> = {};
    // We reduce the data of all metrics into an object that will be the basis of a new row
    const remainingMetricsValues = rest.reduce((obj, { metric, items }) => {
      const currentValue = items[index].data[0].value;

      return {
        ...obj,
        [metric]: typeof currentValue === 'string' ? Number(currentValue) : currentValue,
      };
    }, initialRemainingMetricsValues);

    // The same idea is applied with nested data
    // We traverse current metric nested data and other metrics' nested data that have the same
    // nested index
    const initialNestedRows: NestedReportTableRow[] = [];
    const newNestedData = nested_data?.reduce((nestedRows, { name, data }, nestedIndex) => {
      const initialRemainingMetricsValues: Partial<NestedReportTableRow> = {};
      const remainingMetricsValues = rest.reduce((obj, { metric, items }) => {
        const currentValue = items[index].nested_data?.[nestedIndex].data[0].value;

        return { ...obj, [metric]: typeof currentValue === 'string' ? Number(currentValue) : currentValue };
      }, initialRemainingMetricsValues);

      return [
        ...nestedRows,
        // The new nested row
        {
          // We merge the values of all the remaing metrics with current metric
          ...remainingMetricsValues,
          // Some name (like contract types and energy types) must be associateed with the corresponding translation label
          name: t(turnReportItemNameIntoLabel(name, split_by, country)),
          // Current metric value
          [first.metric]: typeof data[0].value === 'string' ? Number(data[0].value) : data[0].value,
        } as NestedReportTableRow,
      ];
    }, initialNestedRows);

    return [
      ...rows,
      // The new row
      {
        // We merge the values of all the remaing metrics with current metric
        ...remainingMetricsValues,
        // Some name (like contract types and energy types) must be associateed with the corresponding translation label
        name: t(turnReportItemNameIntoLabel(name, focus, country)),
        // Current metric value
        [first.metric]: typeof data[0].value === 'string' ? Number(data[0].value) : data[0].value,
        // Nested rows computed just above
        nested_data: newNestedData,
      } as ReportTableRow,
    ];
  }, initialRows);
};

/** Converts data from the back end into a set of rows lists usable by react table */
export const createTimeBasedTableRows = (
  data: ReportMetricData[],
  { focus, split_by }: CustomReportingGlobalQuery,
  country: UserGeolocation | undefined,
  t: TranslateFn,
): Record<ReportMetric, TimeBasedReportTableRow[]> => {
  // In a time based table, just a single metric is selected.
  // All metrics must be available for display on the change of a select
  // So we have a set of list of rows to filter on rather than a single list rows, hence the choice of a record
  const initialResult = {} as Record<ReportMetric, TimeBasedReportTableRow[]>;

  // The goal here is to create for each metric a list of rows where the values of the metric
  // are indexed per date so that they can easily be accessed by react table with "accessorKey: `values.${date}`"
  return data.reduce((obj, { metric, items }) => {
    const initialRows: TimeBasedReportTableRow[] = [];

    return {
      ...obj,
      [metric]: items.reduce((rows, { name, data, nested_data }) => {
        const initialValuesMappedToDates = {};
        const values = data.reduce(
          (obj, { date, value }) => ({ ...obj, [date || '']: typeof value === 'string' ? Number(value) : value }),
          initialValuesMappedToDates,
        );

        const initialNestedTimeBasedReportTableRow: NestedTimeBasedReportTableRow[] = [];
        const nested = nested_data?.reduce((nestedRows, { name, data }) => {
          const initialValuesMappedToDates = {};
          const values = data.reduce(
            (obj, { date, value }) => ({ ...obj, [date || '']: typeof value === 'string' ? Number(value) : value }),
            initialValuesMappedToDates,
          );

          const row: NestedTimeBasedReportTableRow = {
            name: t(turnReportItemNameIntoLabel(name, split_by, country)),
            values,
          };

          return [...nestedRows, row];
        }, initialNestedTimeBasedReportTableRow);

        return [...rows, { name: t(turnReportItemNameIntoLabel(name, focus, country)), values, nested_data: nested }];
      }, initialRows),
    };
  }, initialResult);
};

export const areColumnsAndRowsTimeBased = (
  tableData: TimeBasedReportTableData | FullPeriodReportTableData,
): tableData is TimeBasedReportTableData => {
  return tableData.isTimeBased;
};

export const computeFullPeriodAggregations = (rows: ReportTableRow[]): Record<ReportMetric, number> => {
  const sums = rows.reduce(
    (aggregation, currentRow) => ({
      contract_nb: aggregation.contract_nb + (currentRow.contract_nb || 0),
      production: aggregation.production + (currentRow.production || 0),
      power: aggregation.power + (currentRow.power || 0),
      revenue: aggregation.revenue + (currentRow.revenue || 0),
      business_plan: aggregation.business_plan + (currentRow.business_plan || 0),
      unit_price:
        typeof currentRow.unit_price === 'number'
          ? aggregation.unit_price + currentRow.unit_price * (currentRow.production || 0)
          : aggregation.unit_price,
      pricedProduction:
        typeof currentRow.unit_price === 'number'
          ? aggregation.pricedProduction + (currentRow.production || 0)
          : aggregation.pricedProduction,
    }),
    {
      contract_nb: 0,
      production: 0,
      power: 0,
      revenue: 0,
      business_plan: 0,
      unit_price: 0,
      pricedProduction: 0,
    },
  );

  return {
    ...sums,
    unit_price: sums.unit_price / sums.pricedProduction,
  };
};

export const computeTimeBasedAggregations = (
  rowsRecord: Record<ReportMetric, TimeBasedReportTableRow[]>,
): Record<ReportMetric, Record<string, number>> => {
  const sum =
    (skipPredicate?: (rowIndex: number, date: string) => boolean) =>
    (obj: Record<string, number>, row: TimeBasedReportTableRow, rowIndex: number) => {
      const newValues = Object.entries(row.values).reduce((previous, [date, value]) => {
        if (skipPredicate?.(rowIndex, date)) return previous;

        return {
          ...previous,
          [date]: Number(previous[date] || 0) + Number(value || 0),
        };
      }, obj);

      return newValues;
    };

  const weightedSum =
    (weights: TimeBasedReportTableRow[]) =>
    (obj: Record<string, number>, row: TimeBasedReportTableRow, index: number) => ({
      ...obj,
      ...Object.entries(row.values).reduce(
        (previous, [date, value]) => ({
          ...previous,
          [date]: Number(previous[date] || 0) + Number(value || 0) * Number(weights[index].values[date] || 0),
        }),
        obj,
      ),
    });

  const pricedProduction = rowsRecord.production.reduce(
    sum((rowIndex, date) => {
      const skip = typeof rowsRecord.unit_price[rowIndex]?.values[date] !== 'number';

      return skip;
    }),
    {},
  );

  return {
    contract_nb: rowsRecord.contract_nb.reduce(sum(), {}),
    production: rowsRecord.production.reduce(sum(), {}),
    power: rowsRecord.power.reduce(sum(), {}),
    revenue: rowsRecord.revenue.reduce(sum(), {}),
    business_plan: rowsRecord.business_plan.reduce(sum(), {}),
    unit_price: Object.entries(rowsRecord.unit_price.reduce(weightedSum(rowsRecord.production), {})).reduce(
      (obj, [date, value]) => ({ ...obj, [date]: value / pricedProduction[date] }),
      {} as Record<string, number>,
    ),
  };
};
