import React from 'react';
import { HelpBox } from '@GDM/HelpBox';
import useTranslation from '@hooks/useTranslation';
import { REVENUE_SHOW_HELP_LOCAL_STORAGE_KEY } from '../../context';
import { useRevenueContext } from '../../hooks/useRevenueContext';
import styles from '../header.module.scss';
import { HelpInfo, INFO_BOX_CONTENT } from './info-box-content';

export const InfoBox = ({ className }: { className?: string }) => {
  const { resolution, groupBy, displayMode, endDate, setShowHelp } = useRevenueContext();
  const { t } = useTranslation();

  const hideHelp = () => {
    setShowHelp?.(false);
    localStorage.setItem(REVENUE_SHOW_HELP_LOCAL_STORAGE_KEY, 'off');
  };

  const hasForecastCondition = endDate && endDate > new Date() ? 'forecast' : null;

  const conditions = [resolution, groupBy, displayMode, hasForecastCondition].filter(Boolean);
  const filterConditions = (i: HelpInfo): boolean => i.conditions.every((c) => conditions.includes(c));

  const relevantSideNotes: string[] = Array.from(
    new Set(INFO_BOX_CONTENT.filter(filterConditions).map((i) => i.translationString)),
  );

  if (!relevantSideNotes || relevantSideNotes.length === 0) return null;

  return (
    <div className={className}>
      <HelpBox onDismiss={hideHelp} dismissible>
        {relevantSideNotes.length === 1 && <p>{t(relevantSideNotes[0])}</p>}
        {relevantSideNotes.length > 1 && (
          <ul className={styles['help-list']}>
            {relevantSideNotes.map((i) => (
              <li key={i} dangerouslySetInnerHTML={{ __html: t(i) }} />
            ))}
          </ul>
        )}
      </HelpBox>
    </div>
  );
};
