import React from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import classNames from 'classnames';
import { iconDictionary } from './constants';
import { EnergyType } from './types/common-types';
import { EventType } from './types/event';

export const getTypeIconName = (type: EnergyType | 'player'): IconNames => iconDictionary[type] || 'MapPin';

type TypeIconProps = {
  type: EnergyType | 'player' | null;
  size?: number;
  color?: string;
};

export const TypeIcon = ({ type, size, color }: TypeIconProps) => {
  return <Icon name={getTypeIconName(type || 'other')} size={size || 14} className="mr-2" color={color} />;
};

type EventIconProps = { type: EventType; className?: string; hideTooltip?: boolean; dataFor?: string };

export const EventIcon = ({ className, hideTooltip, type }: EventIconProps) => {
  const iconDictionary: { [key in EventType]?: { title: string; icon: IconNames; colorClass?: string } } = {
    CommunicationFailure: { title: 'monitoring.alerts.modal.communication_failure', icon: 'PhoneMissed' },
    ProductionStop: { title: 'monitoring.alerts.modal.production_stop', icon: 'XOctagon', colorClass: 'text-red' },
    UnderPerformance: { title: 'monitoring.alerts.modal.under_performance', icon: 'AlertCircle' },
    HighUnderPerformance: {
      title: 'monitoring.alerts.modal.high_under_performance',
      icon: 'AlertTriangle',
      colorClass: 'text-red',
    },
    OutOfRangeReactive: {
      title: 'monitoring.alerts.modal.out_of_range_reactive',
      icon: 'Crosshair',
      colorClass: 'text-red',
    },
    AnnualProductionExceedsCap: {
      title: 'monitoring.alerts.modal.prod_annual_exceeds_cap',
      icon: 'ChevronsUp',
      colorClass: 'text-red',
    },
    AnnualProductionExceedsDafr: {
      title: 'monitoring.alerts.modal.prod_annual_exceeds_dafr',
      icon: 'ChevronsUp',
      colorClass: 'text-red',
    },
    ProductionOnNegativePrice: {
      title: 'monitoring.alerts.modal.prod_on_negative_price',
      icon: 'MinusCircle',
      colorClass: 'text-red',
    },
    InvoiceMissingData: {
      title: 'monitoring.alerts.modal.invoice_missing_data',
      icon: 'HelpCircle',
    },
    HighDiffPublishedProduction: {
      title: 'monitoring.alerts.modal.high_diff_published_production',
      icon: 'HelpCircle',
    },
  };

  const icon = iconDictionary[type];

  if (!icon || !type) return null;

  return (
    <Icon
      name={icon.icon}
      size={14}
      className={classNames(className, icon.colorClass)}
      title={!hideTooltip ? icon.title : undefined}
    />
  );
};
