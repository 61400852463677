import { UserInternal } from '@utils/types/user';

export const MASQUERADES_LOG_KEY = 'user_masquerades';

export const goToUser = (value?: string | number): void => {
  if (!value) return;

  const localStorageItem = localStorage.getItem(MASQUERADES_LOG_KEY);

  const masquerades: Record<string, number> = localStorageItem ? JSON.parse(localStorageItem) : {};

  if (!masquerades[value]) masquerades[value] = 1;
  else masquerades[value] += 1;

  localStorage.setItem(MASQUERADES_LOG_KEY, JSON.stringify(masquerades));

  window.location.href = `/users/masquerade/${value}`;
};

export const makeEmailIdDictionary = (userList: string[], users: UserInternal[] | null): Record<string, number> =>
  users?.reduce<Record<string, number>>((acc, { id, email }) => {
    if (userList.includes(email)) acc[email] = id;

    return acc;
  }, {}) ?? {};
