import React, { useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import dayjs from 'dayjs';
import InvoicePageWrapper from '../InvoicePageWrapper';

const IndexInvoices = () => {
  const [startDate, setStartDate] = useState<Date>(dayjs().startOf('month').subtract(1, 'month').toDate());
  const [endDate, setEndDate] = useState<Date | null>(dayjs().startOf('month').toDate());
  const req = useInvoicesByType('index', 'all', startDate, endDate);

  const handleDateChange = (startDate: Date | null, endDate?: Date | null) => {
    const endOfMonth = endDate ? dayjs(endDate).endOf('month').add(1, 'day').toDate() : null;

    setStartDate(startDate ?? dayjs().startOf('month').subtract(1, 'month').toDate());
    setEndDate(endOfMonth);
  };

  return (
    <InvoicePageWrapper
      invoices={req.data ?? []}
      invoiceType="index"
      isLoading={req.isPending}
      startDate={startDate}
      endDate={endDate}
      onDateChange={handleDateChange}
      isDateRange
      direction="all"
    />
  );
};

export default IndexInvoices;
