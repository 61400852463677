import React from 'react';
import ControlledInput from '@components/FormInputs/ControlledInput';
import { ControlledTextarea } from '@components/FormInputs/ControlledTextarea';
import { Text } from '@GDM/Text';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';

export const MeterRequest = () => {
  const { control } = useInstallationForm();
  const { rules: nameRules, disabled: nameDisabled } = useInstallationField('meter_request_name');
  const { rules: emailRules, disabled: emailDisabled } = useInstallationField('meter_request_email');
  const { rules: commentRules, disabled: commentDisabled } = useInstallationField('meter_request_comment');

  return (
    <div className="p-3 d-flex flex-column gap-3 mt-3">
      <Text text="admin.installations.meter_request_info" multiline />

      <div className="d-flex gap-3 mt-3">
        <ControlledInput
          control={control}
          rules={emailRules}
          disabled={emailDisabled}
          label="common.email"
          name="meter_request_name"
          type="text"
          size="lg"
          flexFull
        />
        <ControlledInput
          control={control}
          rules={nameRules}
          disabled={nameDisabled}
          label="common.name"
          name="meter_request_email"
          type="text"
          size="lg"
          flexFull
        />
      </div>

      <ControlledTextarea
        control={control}
        rules={commentRules}
        disabled={commentDisabled}
        name="meter_request_comment"
        rows={3}
        label="admin.installations.additional_info"
        size="lg"
        full
      />
    </div>
  );
};
