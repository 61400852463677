import React, { useState } from 'react';
import { FilterContainer, Filters } from '@GDM/Filters';
import { Input, RadioButtons } from '@GDM/forms';
import { energyOptions } from '@utils/constants/energyOptions';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { EnergyType } from '@utils/types/common-types';
import { Simulation } from '@utils/types/simulation';
import { BookFilter } from './BookFilter';

type SimulationFilters = {
  search: string;
  installationsName: string[];
  type: EnergyType | 'all';
};

const SimulationsFilters = ({
  allSimulations,
  setSimulations,
}: {
  setSimulations: React.Dispatch<React.SetStateAction<Simulation[]>>;
  allSimulations: Simulation[];
}) => {
  const [filters, setFilters] = useState<SimulationFilters>({
    search: '',
    installationsName: [],
    type: 'all',
  });

  const setFilter = (key: keyof SimulationFilters, value: string | string[]) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [key]: value };

      handleFiltering(newFilters);

      return newFilters;
    });
  };

  const handleFiltering = (filters: SimulationFilters) => {
    let filteredData = [...allSimulations];

    if (filters.search !== '') {
      filteredData = matchSorterInstanceStrict(filteredData, filters.search, { keys: ['name', 'installation_name'] });
    }

    if (filters.installationsName.length > 0) {
      filteredData = filteredData.filter((d) => filters.installationsName.includes(d.installation_name));
    }

    filteredData = filteredData.filter((d) => filters.type === 'all' || filters.type === d.energy);

    setSimulations(filteredData);
  };

  return (
    <Filters>
      <FilterContainer>
        <Input
          value={filters.search}
          onChange={(e) => setFilter('search', e.currentTarget.value)}
          placeholder="pricing.portfolio.simulation_name"
          data-cy="simulation-filter"
        />
      </FilterContainer>

      <FilterContainer>
        <BookFilter onChange={(names) => setFilter('installationsName', names)} />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons
          options={energyOptions}
          onChange={(type) => setFilter('type', type)}
          selected={filters.type === 'all' ? 'all' : filters.type}
        />
      </FilterContainer>
    </Filters>
  );
};

export default SimulationsFilters;
