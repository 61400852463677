import { useMemo } from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { MeterInvoiceFinalStatus } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';

export const usePermittedStatus = (
  status: MeterInvoiceFinalStatus,
): {
  disabled: boolean;
} => {
  const { filteredInvoices } = useInvoicesContext();
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const selectedInvoices = useMemo(
    () => filteredInvoices.filter((i) => selectedInvoicesUuids?.includes(i.uuid)),
    [filteredInvoices, selectedInvoicesUuids],
  );

  const disabled = useMemo(() => {
    if (selectedInvoices.length === 0) {
      return true;
    }

    return selectedInvoices.some((i) => !i.permitted_final_status?.includes(status));
  }, [selectedInvoices, status]);

  return { disabled };
};
