import Installation from '@utils/types/installation';
import { UseFormReturn } from 'react-hook-form';
import { SIMULATION_FORM_DEFAULT_VALUES } from '../config/default-values';
import { SimulationForm } from './simulations.types';

export const setInstallationValues = (
  reset: UseFormReturn<SimulationForm, unknown>['reset'],
  getValues: UseFormReturn<SimulationForm, unknown>['getValues'],
  installation: Installation | null,
): void => {
  if (installation) {
    reset({
      ...SIMULATION_FORM_DEFAULT_VALUES,
      installation_attributes: {
        id: getValues('installation_attributes.id'),
        name: installation.name,
        latitude: installation.latitude,
        longitude: installation.longitude,
        energy: installation.energy,
      },
    });
  } else {
    reset({
      ...SIMULATION_FORM_DEFAULT_VALUES,
      installation_attributes: {
        ...SIMULATION_FORM_DEFAULT_VALUES.installation_attributes,
        id: getValues('installation_attributes.id'),
      },
    });
  }
};
