import React from 'react';
import { Icon } from '@GDM/Icon';
import { isCountryCode } from '@utils/filters/isCountryCode';
import type { Option } from '@utils/types/common-types';
import type { CountryCode } from '@utils/types/countries';

export const getFormatOptionLabel = (isCountry?: boolean) => {
  if (!isCountry) {
    return undefined;
  }

  return (
    data: Option<CountryCode | string | null> & {
      country?: CountryCode | string | null;
    },
  ) => {
    const countryFromOption = data?.country || data.value;

    return (
      <div className="d-flex align-items-center">
        <Icon name={isCountryCode(countryFromOption) ? countryFromOption : 'XX'} size={12} className="mr-1" />
        <span className="ml-2">{data.label}</span>
      </div>
    );
  };
};
