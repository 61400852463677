import React from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';

export const RadioButtonLabel = ({
  icon,
  htmlFor,
  label,
  'data-cy': dataCy,
}: {
  icon?: IconNames;
  htmlFor: string;
  label: string;
  'data-cy'?: string;
}) => {
  const { t } = useTranslation();

  return (
    <label htmlFor={htmlFor} title={t(label)} data-cy={dataCy}>
      {!!icon && <Icon name={icon} size={14} className="mr-2" />}
      {t(label)}
    </label>
  );
};
