import React, { useContext } from 'react';
import localeContext from '@context/locale.context';
import { Button } from '@GDM/Button';
import fileDownload from 'js-file-download';
import { useInstallationsPageContext } from '../context/installations-page.context';
import { loadInstallationCsvExport } from './InstallationCsvExport';

export const InstallationCsvButton = () => {
  const locale = useContext(localeContext);
  const { filteredInstallations } = useInstallationsPageContext();

  const handleDownloadCsvInstallationClick = () => {
    if (!filteredInstallations) return;
    const csvContent = loadInstallationCsvExport(filteredInstallations, locale);

    fileDownload(csvContent, 'streem_filteredInstallations.csv', 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <Button
      text="common.download"
      icon="Download"
      size="xxs"
      variant="primary-2"
      data-cy="installation-download"
      onClick={handleDownloadCsvInstallationClick}
    />
  );
};
