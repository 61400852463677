import { AcceptableUnits } from '@GDM/ValueWithUnit';
import { Subscription } from '@utils/types/subscription';

export function getPriceUnit(subscription: Subscription): AcceptableUnits {
  let unit: AcceptableUnits = 'EUR';
  if (subscription.subscription_type === 'usage_price') {
    unit = 'EUR/MW';

    if (subscription.product === 'monitoring') unit = 'EUR/Meter';
  }

  return unit;
}
