import { Locale } from '@utils/types/common-types';
import { specialOptionsMap, specialUnitsMap } from '../config';
import { AcceptableUnits, SpecialNumberPart } from '../value-with-unit';
import { getUnit } from './getUnit';

const NUMERIC_PARTS: Intl.NumberFormatPart['type'][] = ['integer', 'decimal', 'fraction', 'group'];

export function getSpecialNumberParts({
  value,
  unit = 'numeric',
  locale = 'en',
}: {
  value?: number | null;
  unit?: AcceptableUnits;
  locale?: Locale;
}): SpecialNumberPart[] {
  const localizationOptions: Intl.NumberFormatOptions = specialOptionsMap.get(unit) || {
    maximumFractionDigits: 2,
    notation: 'compact',
  };

  if (!value) return [];

  const localeParts = new Intl.NumberFormat(locale, localizationOptions).formatToParts(value);

  // Get the numeric value that will be displayed e.g 2.43 | 1,54 ...
  const numericToDisplay = localeParts
    .filter(({ type }) => NUMERIC_PARTS.includes(type))
    .map(({ value }) => value)
    .join('');

  // Get the unit (either it's handled by Intl.NumberFormat like 'hour', or it's custom defined like 'kWh')
  const unitToDisplay = localeParts.find(({ type }) => type === 'unit')?.value || getUnit(unit, value) || '';

  // Take the list of parts and replace the numeric values by a single { type: number, value: ... } part,
  // and replace the unit part with the special unit if there is one.
  const specialNumberParts: SpecialNumberPart[] = localeParts.reduce<SpecialNumberPart[]>((acc, curr) => {
    const alreadyHasNumberPart = !!acc.find(({ type }) => type === 'number');

    // If it's numeric and we've already pushed the number part, ignore the current value.
    if (NUMERIC_PARTS.includes(curr.type) && alreadyHasNumberPart) return acc;

    const partToInclude: SpecialNumberPart = NUMERIC_PARTS.includes(curr.type)
      ? { type: 'number', value: numericToDisplay }
      : curr;

    if (partToInclude.type === 'unit') partToInclude.value = unitToDisplay;

    return [...acc, ...[partToInclude]];
  }, []);

  // Add the unit if need be
  if (
    unit !== 'numeric' &&
    !specialNumberParts.find(({ type }) => type === 'unit') &&
    !specialNumberParts.find(({ type }) => type === 'currency')
  )
    specialNumberParts.push({ type: 'unit', value: unitToDisplay });

  // Remove 'compact' value for special units like kWh, MWh etc
  const filteredSpecialNumberParts = specialUnitsMap.get(unit)?.K
    ? specialNumberParts.filter(({ type }) => type !== 'compact')
    : specialNumberParts;

  return filteredSpecialNumberParts;
}
