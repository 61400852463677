import { Locale } from '@utils/types/common-types';
import { MarketFuture } from '@utils/types/market_future';

/**
 * If the market future is monthly, with a name like 'JUN 23', then this will localize that
 * to something like 'JUIN 23'.
 */
export const formatMarketFutureDate = (marketFuture?: MarketFuture, locale?: Locale): string => {
  if (!marketFuture || !marketFuture.start_date) return '';

  if (marketFuture.time_type !== 'month') return marketFuture.name;

  const date = new Date(marketFuture.start_date);
  const label = new Intl.DateTimeFormat(locale || 'en', { month: 'short', year: '2-digit' }).format(date);

  return label.toLocaleUpperCase();
};
