import React from 'react';
import { Restricted } from '@components/Restricted';
import { Icon } from '@GDM/Icon';
import { iconDictionary } from '@utils/constants';
import { EventIcon } from '@utils/getTypeMeterIcons';
import { Event } from '@utils/types/event';
import { Permission } from '@utils/types/permission';
import styles from './notifications.module.scss';

type Props = { event: Event };

export const Notification = ({ event }: Props) => {
  const url = `/v2/dashboard_meter/?meter=${encodeURIComponent(event.installation.name || '')}`;
  const permissions: Permission[] =
    event.type === 'OutOfRangeReactive' ? ['display:view_monitoring_dashboard_meter_reactive'] : [];

  const iconName = event.installation.energy ? iconDictionary[event.installation.energy] : iconDictionary.other;

  return (
    <Restricted permissions={permissions}>
      <a href={url} className={styles['meter-name']}>
        <Icon name={iconName} size={14} className="mr-2" />
        <span>{event.installation.name}</span>
        <EventIcon className="ml-2" type={event.type} />
      </a>
    </Restricted>
  );
};
