import React, { useCallback, useMemo, useState } from 'react';
import { fetchUserNotificationsSettings } from '@api/userNotificationsSetings';
import { Modal } from '@GDM/Modal/Modal';
import useTranslation from '@hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';
import type { UserNotificationsSettings } from '@utils/types/userNotificationsSettings';
import { useForm } from 'react-hook-form';
import { useNotificationTableColumns } from './hooks/useNotificationTableColumns';
import { useUserNotificationsSettingsDeleteMutation } from './hooks/useUserNotificationsSettingsDeleteMutation';
import { NotificationFormModal } from './NotificationForm';
import NotificationsActions from './NotificationsActions';
import { NotificationsFilters } from './NotificationsFilters';
import { NotificationsTable, NotificationsTableRow } from './NotificationsTable';
import { NotificationsFormFilters } from './types/notificationsFilters';

export const NotificationsSettings = () => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<number | null>(null);
  const { watch, control, setValue } = useForm<NotificationsFormFilters>({
    defaultValues: {
      email: [],
      event_type: [],
      installation: [],
      book: [],
    },
  });

  const { book, installation, ...formFilters } = watch();
  const tableFilters = { ...formFilters, eventable: [...book, ...installation] };

  const onSubmission = (
    oldSettings: UserNotificationsSettings | undefined | null,
    newSettings: UserNotificationsSettings | undefined | null,
  ) => {
    const selectedEmailsWithoutTheDeletedOnes = formFilters.email.filter(
      (email) => !oldSettings?.emails.includes(email) || newSettings?.emails.includes(email),
    );

    const selectedEventsWithoutTheDeletedOnes = formFilters.event_type.filter(
      (eventType) => !oldSettings?.event_types.includes(eventType) || newSettings?.event_types.includes(eventType),
    );

    const selectedInstallationWithoutTheDeletedOnes = installation.filter(
      (installationId) =>
        !oldSettings?.eventable?.name.includes(installationId) || newSettings?.eventable?.name.includes(installationId),
    );

    const selectedBookWithoutTheDeletedOnes = book.filter(
      (bookId) => !oldSettings?.eventable?.name.includes(bookId) || newSettings?.eventable?.name.includes(bookId),
    );

    setValue('email', selectedEmailsWithoutTheDeletedOnes);
    setValue('event_type', selectedEventsWithoutTheDeletedOnes);
    setValue('installation', selectedInstallationWithoutTheDeletedOnes);
    setValue('book', selectedBookWithoutTheDeletedOnes);
  };

  const { data, isLoading } = useQuery<UserNotificationsSettings[]>({
    queryKey: ['notifications'],
    queryFn: async () => {
      const userNotificationsSettings = await fetchUserNotificationsSettings();

      return userNotificationsSettings.filter(({ emails }) => emails.length > 0);
    },
  });

  const rows = useMemo<NotificationsTableRow[]>(
    () =>
      // Unpack the data and create a row for each email
      data?.reduce<NotificationsTableRow[]>((previousUnpackedRows, originalSettings) => {
        const { id, emails, event_types, eventable } = originalSettings;

        return [
          ...previousUnpackedRows,
          ...emails.map((email) => ({ id, email, event_type: event_types, eventable, originalSettings })),
        ];
      }, []) || [],
    [data],
  );

  const selectedNotification = useMemo(() => {
    return data?.find((notification) => notification.id === selectedNotificationId);
  }, [data, selectedNotificationId]);

  const openEditModal = useCallback((id: number | null) => {
    setSelectedNotificationId(id);
    setIsFormModalOpen(true);
  }, []);

  const openDeleteConfirmationModal = useCallback((id: number | null) => {
    setSelectedNotificationId(id);
    setIsDeleteModalOpen(true);
  }, []);

  const toggleFormModal = useCallback(() => {
    setIsFormModalOpen(!isFormModalOpen);
    setSelectedNotificationId(null);
  }, [isFormModalOpen]);

  const toggleDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    setSelectedNotificationId(null);
  }, [isDeleteModalOpen]);

  const columns = useNotificationTableColumns({ openEditModal, openDeleteConfirmationModal });

  const { t } = useTranslation();

  const deleteMutation = useUserNotificationsSettingsDeleteMutation();

  return (
    <div className="mt-3 d-flex flex-column">
      <NotificationFormModal
        isOpen={isFormModalOpen}
        toggle={toggleFormModal}
        onSubmission={onSubmission}
        selectedNotification={selectedNotification}
        key={selectedNotification?.id}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        size="sm"
        toggle={toggleDeleteModal}
        submitAction={async () => {
          if (!selectedNotificationId) return;
          await deleteMutation.mutateAsync(selectedNotificationId);
          toggleDeleteModal();
        }}
        saveButtonText={t('common.delete')}
        data-cy="meter-delete-modal"
      >
        {t('monitoring.alerts.are_you_sure_delete')}
      </Modal>

      <NotificationsFilters control={control} data={data || []} />
      <NotificationsActions onClick={toggleFormModal} />
      <NotificationsTable rows={rows} columns={columns} isLoading={isLoading} filters={tableFilters} />
    </div>
  );
};
