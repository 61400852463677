import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import { ReactiveInstruction } from './types';

export function convertQfuQMins(instructions?: Partial<ReactiveConstraint>[]): ReactiveInstruction[] {
  return (
    instructions?.map((i) => {
      if (i.reactive_type === 'tan_phi') return i;

      return {
        ...i,
        q_min: (i?.q_min || 0) * -1,
      };
    }) || []
  );
}
