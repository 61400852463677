import { createContext, useContext } from 'react';
import { CountryCode } from '@utils/types/countries';
import { Timezone } from '@utils/types/countries';
import { Currency } from '@utils/types/currency';
import { NationalPrices } from '../national-prices.types';

export type NationalPricesContext = {
  timezone: Timezone;
  startDate: string | null;
  endDate: string | null;
  selectedCountry: CountryCode | null;
  m0Data: NationalPrices | null;
  currency: Currency;
};

export const nationalPriceContext = createContext<NationalPricesContext>({
  timezone: 'Europe/Paris',
  startDate: null,
  endDate: null,
  selectedCountry: null,
  m0Data: null,
  currency: 'EUR',
});

export const useNationalPricesContext = (): NationalPricesContext => useContext(nationalPriceContext);
