import { useEffect, useState } from 'react';
import { PricingMap } from './pricing-maps.types';

export const useMask = (pricingMap: PricingMap | null) => {
  const { country } = pricingMap || {};
  const [request, setRequest] = useState<{ data: GeoJSON.Feature | null; loading: boolean }>({
    data: null,
    loading: false,
  });

  useEffect(() => {
    const fetchMask = async () => {
      setRequest((req) => ({ ...req, loading: true }));

      const url = `/masks/${country?.toLowerCase()}.geo.json`;

      if (url) {
        const response = await fetch(url);
        const data = await response.json();

        setRequest({ data, loading: false });
      }
    };

    if (country) {
      fetchMask();
    }
  }, [country]);

  return request;
};
