import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

export const BuySellFilter = () => {
  const { filterContracts } = useConsumeContracts();
  const form = useFormContext<ContractsFiltersType>();

  const options: Option<Contract['direction']>[] = [
    { value: 'buy', label: 'sales_management.contract_buy' },
    { value: 'sell', label: 'sales_management.contract_sell' },
  ];

  return (
    <Controller
      name="direction"
      control={form.control}
      render={({ field }) => (
        <Select
          placeholder="sales_management.contract_direction"
          isClearable
          selectedOption={field.value}
          isSearchable
          options={options}
          onChange={(option: Option<Contract['direction']> | null) => {
            filterContracts({
              ...form.getValues(),
              direction: option?.value || null,
              last_selected_filter: 'direction',
            });
            field.onChange(option?.value);
            form.setValue('last_selected_filter', option?.value ? 'direction' : null);
          }}
        />
      )}
    />
  );
};
