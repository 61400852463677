import dayjs from 'dayjs';

/**
 * Formats datetimes to be used by the system and not the end user.
 * It will take any `DateTime` or datetime-like string and format it to ISO86
 * Not providing an argument will use now as a datetime.
 */

export const formatSystemDateTime = (input?: string | number | Date | null): string => {
  const date = input ? new Date(input) : new Date();

  return dayjs(date).toISOString();
};
