import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import { v2_installation_all_players_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

export const useInstallationPlayers = (installationName: string | null): CommonRequestHookResponse<MarketPlayer[]> => {
  const url = installationName ? v2_installation_all_players_path(installationName) : '';
  const req = useRequest<MarketPlayer[]>(url, 'GET');

  return req;
};
