import React, { useMemo, useState } from 'react';
import { getExchangeRates, type ExchangeRate } from '@api/currency';
import { DatePicker } from '@GDM/DatePicker';
import { Icon } from '@GDM/Icon';
import { Modal } from '@GDM/Modal';
import {
  DateCell,
  NumberCell,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TablePageSizeSelect,
  TablePagination,
} from '@GDM/Table';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  type ColumnDef,
  type CellContext,
} from '@tanstack/react-table';
import type { Currency } from '@utils/types/currency';
import dayjs from 'dayjs';

export const CurrencyModal = ({
  isOpen,
  toggle,
  startDate,
  endDate,
  currency,
}: {
  isOpen: boolean;
  toggle: () => void;
  startDate: Date;
  endDate: Date;
  currency: Currency;
}) => {
  const {
    isLoading,
    isError,
    error,
    data: exchangeRates,
  } = useQuery({
    queryKey: ['currency', 'exchangeRates'],
    queryFn: () => getExchangeRates(startDate, endDate, currency),
    enabled: isOpen,
  });
  const [selectedDate, setSelectedDate] = useState<[Date | null, Date | null]>([startDate, endDate]);
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([{ id: 'start_date', desc: false }]);

  const tableState = useMemo(() => ({ pagination: { pageIndex, pageSize }, sorting }), [pageIndex, pageSize, sorting]);

  const data: ExchangeRate[] = useMemo(() => {
    if (!exchangeRates) return [];

    return exchangeRates.filter(({ rate, start_date, source_currency, destination_currency }) => {
      const noRate = rate === null;
      const currenciesAreTheSame = source_currency === destination_currency;
      if (noRate || currenciesAreTheSame) return false;

      const isBetween = dayjs(start_date).isBetween(dayjs(selectedDate[0]), dayjs(selectedDate[1]), 'month', '[]');

      return isBetween;
    });
  }, [exchangeRates, selectedDate]);

  const columns = useColumns();

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: tableState,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  return (
    <Modal
      header="common.exchange_rates"
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      cancelButtonText="common.close"
    >
      <div className="d-flex flex-column gap-4">
        <DatePicker
          startDate={selectedDate[0]}
          endDate={selectedDate[1]}
          onChange={setSelectedDate}
          label="common.month"
          selectsRange
          picks="months"
          minDate={startDate}
          maxDate={endDate}
          isClearable
        />

        <Table>
          <TableHead table={table} />
          <TableBody table={table} isLoading={isLoading} />
        </Table>
        {data.length > pageSize && (
          <TableActions>
            <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
            <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
          </TableActions>
        )}

        {isError && <Text text={error?.message} />}
      </div>
    </Modal>
  );
};

const CurrencyCell = (props: CellContext<ExchangeRate, ExchangeRate['source_currency']>) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      {props.getValue()}
      <Icon name="ArrowRight" size={12} />
      {props.row.original.destination_currency}
    </div>
  );
};

const useColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<ExchangeRate>[]>(() => {
    const columns: ColumnDef<ExchangeRate>[] = [
      {
        accessorKey: 'start_date',
        header: 'common.month',
        cell: ({ getValue }) => <DateCell getValue={getValue} format="YYYY-MM" />,
      },
      {
        accessorKey: 'source_currency',
        header: 'common.currency',
        cell: CurrencyCell,
      },
      {
        accessorKey: 'rate',
        header: 'common.rate',
        cell: ({ getValue, row }) => (
          <NumberCell
            getValue={getValue}
            fractionDigits={4}
            tooltip={t('common.exchange_rate_source', {
              source: t(`common.exchange_rate_sources.${row.original.source}`),
            })}
          />
        ),
      },
    ];

    return columns;
  }, [t]);

  return columns;
};
