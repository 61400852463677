import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import { v2_book_all_players_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

export const useBookPlayers = (bookUuid: string | null): CommonRequestHookResponse<MarketPlayer[]> => {
  const url = bookUuid ? v2_book_all_players_path(bookUuid) : '';
  const req = useRequest<MarketPlayer[]>(url, 'GET');

  return req;
};
