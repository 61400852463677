export function getErrorList<T extends Record<string, string | number | boolean>>(e: unknown): T | T[] | null {
  let errors = null;

  if (e && typeof e === 'object') {
    if (
      'response' in e &&
      typeof e.response === 'object' &&
      e.response &&
      'data' in e.response &&
      typeof e.response.data === 'object' &&
      e.response.data &&
      'errors' in e.response.data
    ) {
      errors = e.response.data.errors as T | T[];
    }
  }

  return errors;
}
