import React from 'react';
import { Restricted } from '@components/Restricted';
import { UserMenuLanguagePicker } from '@components/Sidebar';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { GenerateToken } from 'pages/ui/shared/user-menu/GenerateToken';
import { useSidebarContext } from '../context';
import styles from './user-menu-infos.module.scss';

export const UserMenuInfosPanel = () => {
  const { t } = useTranslation();
  const { masquerading } = useSidebarContext();

  return (
    <ul className={classNames(styles['panel'])}>
      <li className={classNames(styles['panel-element'])}>
        <UserMenuLanguagePicker />
      </li>

      <Restricted permissions={['display:api_token']}>
        <li className={classNames(styles['panel-element'])}>
          <GenerateToken />
        </li>
      </Restricted>

      {masquerading && (
        <>
          <li className={styles['panel-element']} data-cy="masquerade-option">
            <a href="/users/masquerade/back">
              <Icon name="Users" size={16} className={styles.icon} />
              {t('use_sub_client.title')}
            </a>
          </li>
        </>
      )}

      <li className={classNames(styles['panel-element'], styles['panel-element-signout'])}>
        <a href="/users/sign_out">
          <Icon name="Power" size={18} className={styles.icon} />
          <span>{t('log_out')}</span>
        </a>
      </li>
    </ul>
  );
};
