import React, { useCallback, useEffect } from 'react';
import { useUnavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { Col, Row } from '@GDM/layout';
import { Modal } from '@GDM/Modal';
import { post as genericPost, put as genericPut, useRequest } from '@hooks/useRequest';
import { v2_unavailabilities_path, v2_unavailability_path } from '@utils/routes';
import { CapacityUnit, Unavailability } from '@utils/types/unavailability';
import { useFormContext } from 'react-hook-form';
import { CapacityInput } from '../Form/inputs/CapacityInput';
import { CommentInput } from '../Form/inputs/CommentInput';
import { EndDateInput } from '../Form/inputs/EndDateInput';
import { EventTypeInput } from '../Form/inputs/EventTypeInput';
import { InstallationInput } from '../Form/inputs/InstallationInput';
import { PmaxInput } from '../Form/inputs/PmaxInput';
import { StartDateInput } from '../Form/inputs/StartDateInput';
import { useCreateModalContext } from './context';
import { convertFormToBody } from './converters';
import { CreateModalForm, SubmitBody } from './types';

const capacityExceedsPmaxCheck = (
  capacity_value: number | null,
  capacity_unit: CapacityUnit,
  p_max: number | null,
): boolean => !!capacity_value && !!p_max && capacity_unit !== 'percentage' && capacity_value > p_max;

export const CreateModal = () => {
  const { addUnavailability, updateUnavailability, selectedUnavailability } = useUnavailabilitiesContext();
  const form = useFormContext<CreateModalForm>();
  const { reset, watch } = form;
  const { toggle, isOpen } = useCreateModalContext();

  const post = useCallback(
    ({ url }: { url: string }) => {
      const formBody = watch();
      const body = convertFormToBody(formBody);

      return genericPost<Unavailability, SubmitBody>({ url, body });
    },
    [watch],
  );

  const put = useCallback(
    ({ url }: { url: string }) => {
      const formBody = watch();
      const body = convertFormToBody(formBody);

      return genericPut<Unavailability, SubmitBody>({ url, body });
    },
    [watch],
  );

  const {
    data: newUnavailability,
    execute: onCreate,
    loading: createLoading,
    error: createError,
  } = useRequest(v2_unavailabilities_path(), post, true);

  const {
    data: updatedUnavailability,
    execute: onUpdate,
    loading: updateLoading,
    error: updateError,
  } = useRequest(selectedUnavailability ? v2_unavailability_path(selectedUnavailability.uuid) : null, put, true);

  useEffect(() => {
    if (!createLoading && !createError && !!newUnavailability && !!reset && !!toggle) {
      addUnavailability(newUnavailability);
      toggle();
      reset();
    }
  }, [newUnavailability, createLoading, createError, addUnavailability, reset, toggle]);

  useEffect(() => {
    if (!updateLoading && !updateError && !!updatedUnavailability && !!reset && !!toggle) {
      updateUnavailability(updatedUnavailability);
      toggle();
      reset();
    }
  }, [updatedUnavailability, updateLoading, updateError, updateUnavailability, reset, toggle]);

  const formIsValid = form ? Object.keys(form.formState.errors).length === 0 : true;

  const capacityExceedsPmax = capacityExceedsPmaxCheck(
    form.watch('capacity')?.value,
    form.watch('capacity')?.unit,
    form.watch('p_max'),
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      header={selectedUnavailability ? 'monitoring.unavailabilities.edit' : 'monitoring.unavailabilities.create'}
      submitAction={selectedUnavailability ? onUpdate : onCreate}
      submitDisabled={!formIsValid || capacityExceedsPmax}
      isLoading={updateLoading || createLoading}
      error={
        (capacityExceedsPmax ? 'monitoring.unavailabilities.errors.capacity_exceeds_pmax' : null) ||
        createError?.message ||
        updateError?.message
      }
    >
      <Row className="mb-4">
        <Col sm={6}>
          <InstallationInput />
        </Col>
        <Col sm={6}>
          <EventTypeInput />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={6}>
          <StartDateInput />
        </Col>
        <Col sm={6}>
          <EndDateInput />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={6}>
          <CapacityInput />
        </Col>
        <Col sm={6}>
          <PmaxInput />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={12}>
          <CommentInput />
        </Col>
      </Row>
    </Modal>
  );
};
