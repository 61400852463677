import fetchWrapper, { type ReqError } from '@utils/fetch';
import { all_v2_books_path } from '@utils/routes';
import { sortByNameAsc } from '@utils/sorters';
import Book, { type BookType } from '@utils/types/book';

export const getBooks = async (bookType?: BookType): Promise<Book[]> => {
  const response = await fetchWrapper(all_v2_books_path({ bookType }));
  const data = await response.json();

  if (!response.ok) {
    throw {
      code: data.error.code,
      message: data.error.message,
      errors: data.error.errors,
    } satisfies ReqError;
  }

  return data.toSorted(sortByNameAsc);
};
