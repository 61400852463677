import React from 'react';
import { useUser } from '@context/User.context';
import { Map } from '@GDM/Map';
import { useRequest } from '@hooks/useRequest';
import { COUNTRIES } from '@utils/constants/countries';
import { simulation_installations_v2_ppa_simulations_path } from '@utils/routes';
import { Installation } from '@utils/types/installation';
import styles from './simulations.module.scss';
import { SimulationsMapFeature } from './SimulationsMapFetureGroup';

export const SimulationsMap = () => {
  const { data: installations } = useRequest<Installation[]>(simulation_installations_v2_ppa_simulations_path(), 'GET');
  const user = useUser();
  const countryCenter = user.geolocation[0];

  const lat = COUNTRIES[countryCenter].coordinates.lat;
  const lng = COUNTRIES[countryCenter].coordinates.lng;
  const center: [number, number] | undefined = lat !== null && lng !== null ? [lat, lng] : undefined;

  return (
    <div className={styles['map-container']}>
      {installations && (
        <Map center={center} zoom={5}>
          <SimulationsMapFeature installations={installations} />
        </Map>
      )}
    </div>
  );
};
