export const LOCALIZED_SHORT_MONTHS = [
  'common.months.january.short',
  'common.months.february.short',
  'common.months.march.short',
  'common.months.april.short',
  'common.months.may.short',
  'common.months.june.short',
  'common.months.july.short',
  'common.months.august.short',
  'common.months.september.short',
  'common.months.october.short',
  'common.months.november.short',
  'common.months.december.short',
];
