import { useUser } from '@context/User.context';
import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import { formatMarketFutureDate } from '@utils/date';
import { formatSystemDate } from '@utils/formatters';
import { v2_market_futures_path } from '@utils/routes';
import { Locale } from '@utils/types/common-types';
import { MarketFuture } from '@utils/types/market_future';

export const futureMarketSorter = (a: MarketFuture, b: MarketFuture): number => {
  if (a.time_type === b.time_type) {
    return a.start_date.localeCompare(b.start_date);
  }

  return a.time_type.localeCompare(b.time_type);
};

function localizeNames(marketFuture: MarketFuture, locale?: Locale): MarketFuture {
  return {
    ...marketFuture,
    name: formatMarketFutureDate(marketFuture, locale),
  };
}

const useMarketFutures = (startDate?: Date, endDate?: Date): CommonRequestHookResponse<MarketFuture[]> => {
  const start_date = startDate ? formatSystemDate(startDate) : undefined;
  const end_date = endDate ? formatSystemDate(endDate) : undefined;
  const { data, loading, error, loaded, status } = useRequest<MarketFuture[]>(
    v2_market_futures_path({ params: { start_date, end_date } }),
    'GET',
  );
  const user = useUser();

  return {
    data:
      data && Array.isArray(data)
        ? data.sort(futureMarketSorter).map((marketFuture: MarketFuture) => localizeNames(marketFuture, user?.locale))
        : [],
    loading,
    error,
    loaded,
    status,
  };
};

export default useMarketFutures;
