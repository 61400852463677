import { Dispatch, SetStateAction, createContext } from 'react';
import { Subscription } from '@utils/types/subscription';

export type SubscriptionsContextType = {
  selectedSubscription: Subscription | null;
  setSelectedSubscription?: Dispatch<SetStateAction<SubscriptionsContextType['selectedSubscription']>>;
};

export const subscriptionsContext = createContext<SubscriptionsContextType>({
  selectedSubscription: null,
});
