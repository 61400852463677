import React from 'react';
import { useProjectContext } from '../../utils/project.context';
import { Cards } from './Cards';
import { CardsFullMerchant } from './Cards';
import { Charts } from './Charts/Charts';
import { ChartsFullMerchant } from './Charts/ChartsFullMerchant';
import styles from './results.module.scss';
import { SimulationSettings } from './SimulationSettings';

export const Results = () => {
  const project = useProjectContext();
  const { cannibalized_distribution, cannibalized_distribution_factor, cannibalized_distribution_cat } =
    project?.feed_in_deviation || {};
  const {
    full_merchant_cannibalized_distribution_cat,
    full_merchant_cannibalized_distribution,
    full_merchant_abs_price_cannibalized_distribution,
  } = project?.full_merchant || {};

  const isFullMerchantWithData =
    project?.simulation?.full_merchant &&
    full_merchant_cannibalized_distribution &&
    full_merchant_abs_price_cannibalized_distribution &&
    full_merchant_cannibalized_distribution_cat;

  const isDeviationWithData =
    !project?.simulation?.full_merchant &&
    cannibalized_distribution &&
    cannibalized_distribution_factor &&
    cannibalized_distribution_cat;

  return (
    <div>
      <div className={styles['results-header']}>
        <SimulationSettings />

        {isFullMerchantWithData && <CardsFullMerchant />}
        {isDeviationWithData && <Cards />}
      </div>

      {isFullMerchantWithData && <ChartsFullMerchant />}
      {isDeviationWithData && <Charts />}
    </div>
  );
};
