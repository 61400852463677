import React, { useMemo } from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { ControlledToggle } from '@components/FormInputs/ControlledToggle';
import { type DatePickerPicks, type DateTransform } from '@GDM/DatePicker';
import { FilterContainer, Filters as FilterLayout } from '@GDM/Filters';
import useBooks from '@hooks/requests/useBooks';
import { energyOptions as AllEnergyOptions } from '@utils/constants/energyOptions';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type Book from '@utils/types/book';
import type { EnergyType, Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries/country-code';
import dayjs from 'dayjs';
import { type UseFormReturn } from 'react-hook-form';
import { defaultDates } from './constants/default-values';
import { granularityOptions, type Granularity } from './constants/granularities';
import { chartTableOptions } from './constants/options';
import type { NegativePricesFilters, NegativePrice } from './negative-prices.types';

export const getMaxEndDate = (startDate: Date, granularity: Granularity): Date => {
  let maxEndDate = null;
  if (granularity === 'monthly') {
    maxEndDate = dayjs(startDate).add(1, 'year').toDate();
  }
  if (granularity === 'daily') {
    maxEndDate = dayjs(startDate).add(1, 'month').toDate();
  }
  if (granularity === 'hourly') {
    maxEndDate = dayjs(startDate).add(1, 'week').toDate();
  }
  const now = new Date();

  return !maxEndDate || maxEndDate > now ? now : maxEndDate;
};

const granularityPicks: Record<Granularity, DatePickerPicks> = {
  hourly: 'default',
  daily: 'default',
  monthly: 'months',
};

export const Header = ({
  form,
  installations,
  isLoading,
  showBookFilter,
  showEnergyFilter,
  showOaFilter,
  isSolar,
}: {
  form: UseFormReturn<NegativePricesFilters>;
  installations: NegativePrice['installation'][];
  isLoading: boolean;
  showBookFilter: boolean;
  showEnergyFilter: boolean;
  showOaFilter: boolean;
  isSolar?: boolean;
}) => {
  const displayMode = form.watch('displayMode');
  const granularity = form.watch('granularity');
  const [startDate, endDate] = form.watch('dates');

  const bookQuery = useBooks();
  const { countryOptions, energyOptions, installationOptions } = useOptionsFromInstallations(installations);

  const bookOptions = useBookOptions(bookQuery.data);

  const showHideNightHoursFilter = isSolar ?? energyOptions.some((e) => e.value === 'solar');

  return (
    <FilterLayout className="p-0" form={form}>
      <FilterContainer size="datepicker" data-cy="negative-prices-filters">
        <ControlledDatePicker
          control={form.control}
          name="dates"
          picks={granularityPicks[granularity]}
          selectsRange
          transformDate={granularity === 'monthly' ? setEndDateToEndOfMonth : undefined}
          maxDate={startDate && !endDate ? getMaxEndDate(startDate, granularity) : new Date()}
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <ControlledRadioButtons control={form.control} name="displayMode" options={chartTableOptions} />
      </FilterContainer>

      <FilterContainer size="fit">
        <ControlledRadioButtons
          control={form.control}
          name="granularity"
          options={granularityOptions[displayMode]}
          afterChange={(granularity) => {
            form.setValue('dates', [
              defaultDates[granularity as Granularity].startDate,
              defaultDates[granularity as Granularity].endDate,
            ]);
          }}
        />
      </FilterContainer>

      {/* energyOptions.length > 2 because there should always be the `all` option */}
      {showEnergyFilter && energyOptions.length > 2 && (
        <FilterContainer size="fit">
          <ControlledRadioButtons control={form.control} name="energy" options={energyOptions} />
        </FilterContainer>
      )}

      <FilterContainer size="fit">
        <ControlledSelect
          control={form.control}
          name="countries"
          options={countryOptions}
          placeholder="common.country"
          inline
          isMulti
          isClearable
          isCountry
          isLoading={isLoading}
          isDisabled={countryOptions.length === 1}
        />
      </FilterContainer>

      {installationOptions.length > 1 && (
        <FilterContainer size="select">
          <ControlledSelect
            control={form.control}
            name="installations"
            options={installationOptions}
            placeholder="common.installations"
            inline
            isMulti
            isClearable
            isInstallationOrBook
            isLoading={isLoading}
          />
        </FilterContainer>
      )}

      {showBookFilter && (
        <FilterContainer size="select">
          <ControlledSelect
            control={form.control}
            name="books"
            options={bookOptions}
            placeholder="common.books"
            inline
            isMulti
            isClearable
            isInstallationOrBook
            isLoading={bookQuery.isLoading || isLoading}
          />
        </FilterContainer>
      )}

      {installations.length > 0 && displayMode === 'table' && ['daily', 'monthly'].includes(granularity) && (
        <FilterContainer size="fit">
          <ControlledToggle control={form.control} name="splitByInstallation" label="common.split_by_installation" />
        </FilterContainer>
      )}

      {displayMode === 'table' && (
        <FilterContainer size="fit">
          <ControlledToggle control={form.control} name="hideZeroProduction" label="common.hide_zero_production" />
        </FilterContainer>
      )}

      {showHideNightHoursFilter && (
        <FilterContainer size="fit">
          <ControlledToggle
            control={form.control}
            name="excludeNightHours"
            label="common.exclude_night_hours"
            tooltip="common.exclude_night_hours_tooltip"
          />
        </FilterContainer>
      )}

      {showOaFilter && ['daily', 'monthly'].includes(granularity) && (
        <FilterContainer size="fit">
          <ControlledToggle control={form.control} name="onlyNonOa" label="common.only_non_oa" />
        </FilterContainer>
      )}
    </FilterLayout>
  );
};

const useOptionsFromInstallations = (installations: NegativePrice['installation'][]) => {
  return useMemo(() => {
    const installationOptions: { [name: string]: Option<string> & { energy: EnergyType } } = {};
    const uniqueEnergies = new Set(['all']);
    const countryOptions: { [name: string]: Option<CountryCode> } = {};

    installations.forEach((installation) => {
      if (!installation) return;

      const country = installation.country;
      const energy = installation.energy;

      if (country && !countryOptions[country]) countryOptions[country] = { value: country, label: country };
      if (energy) uniqueEnergies.add(energy);

      if (!installationOptions[installation.name])
        installationOptions[installation.name] = {
          value: installation.name,
          label: installation.name,
          energy: installation.energy,
        };
    });

    const energyOptions = AllEnergyOptions.filter((option) => uniqueEnergies.has(option.value));

    return {
      energyOptions: energyOptions,
      countryOptions: Object.values(countryOptions).toSorted(sortOptionsByLabelAsc),
      installationOptions: Object.values(installationOptions).toSorted(sortOptionsByLabelAsc),
    };
  }, [installations]);
};

const useBookOptions = (books?: Book[]): Option<string>[] => {
  return useMemo(() => {
    if (!books) return [];

    const options = books.map((book) => ({ value: book.name, label: book.name, energy: 'book' }));

    return options.toSorted(sortOptionsByLabelAsc);
  }, [books]);
};

const setEndDateToEndOfMonth: DateTransform = (dates) => {
  if (Array.isArray(dates) && dates[1]) {
    return [dates[0], dayjs(dates[1]).endOf('month').toDate()];
  }

  return dates;
};
