import React from 'react';
import { useUser } from '@context/User.context';
import { NumberCell } from '@GDM/Table/Cells/NumberCell';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import { AcceptableUnits } from '@GDM/ValueWithUnit';
import classNames from 'classnames';
import styles from './absolute-value-percentage.module.scss';

export const AbsoluteValueWithPercentageCell = ({
  percentage,
  value,
  unit,
  colorNumber,
  percentageTooltip,
  valueTooltip,
  numberOptions,
}: {
  value: unknown;
  percentage: number | null;
  unit?: AcceptableUnits;
  colorNumber?: boolean;
  percentageTooltip?: string;
  valueTooltip?: string;
  numberOptions?: Intl.NumberFormatOptions;
}) => {
  const { locale } = useUser();

  const color = value === 0 ? 'green' : 'red';

  return (
    <div className={styles['absolute-value-percentage']}>
      <NumberCell value={value} unit={unit} numberOptions={numberOptions} tooltip={valueTooltip} />
      <Tooltip tooltip={percentageTooltip}>
        <div className={classNames(styles.percentage, colorNumber && `text-${color}`)}>
          {(percentage || percentage === 0) && (
            <>
              {new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(percentage)}
              %
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
