import React, { useEffect, useRef } from 'react';
import { Marker } from '@GDM/Map';
import useIsOnScreen from '@hooks/useIsOnScreen';
import { LatLng } from 'leaflet';
import { Popup, useMap } from 'react-leaflet';
import styles from './maps.module.scss';

export const InstallationMap = ({ name, lat, lng }: { name: string; lat: number; lng: number }) => {
  const ref = useRef(null);
  const map = useMap();
  const isVisible = useIsOnScreen(ref);

  const position = new LatLng(Number(lat), Number(lng));

  const recenterMap = (position: LatLng): void => {
    map.setView(position, 18);
  };

  useEffect(() => {
    const resizeMap = () => map.invalidateSize();
    resizeMap();
    window.addEventListener('resize', resizeMap);

    return window.removeEventListener('resize', resizeMap);
  }, [isVisible, map]);

  useEffect(() => {
    recenterMap(position);
  }, [position]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref}>
      <Marker position={position}>
        <Popup className={styles.popup}>
          <div className="fw:700">{name}</div>
        </Popup>
      </Marker>
    </div>
  );
};
