import React, { useEffect, useRef, useState } from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { useSelectedPricingMapContext } from '../../utils/selectedPricingMap.context';
import styles from './period-select.module.scss';

export const PeriodSelect = ({
  onChange,
  selectedOption,
}: {
  onChange: (option: Option<string> | null) => void;
  selectedOption: string | null;
}) => {
  const user = useUser();
  const { selectedPricingMap, allPeriods } = useSelectedPricingMapContext();

  const [isPlaying, setIsPlaying] = useState(false);
  const intervalId = useRef<number | null>(null);

  const handlePlay = () => {
    if (isPlaying) clearInterval(intervalId.current || undefined);
    else {
      let startPeriod = 0;
      const playNextFrame = () => {
        if (startPeriod === allPeriods.length - 1) {
          clearInterval(intervalId.current || undefined);
          setIsPlaying(false);

          return;
        }

        onChange(allPeriods[startPeriod]);
        startPeriod = startPeriod + 1;
      };
      playNextFrame();
      const id = Number(setInterval(playNextFrame, 1500));
      intervalId.current = id;
    }
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current || undefined);
    };
  }, []);

  if (!selectedPricingMap) return null;

  return (
    <div className={styles['period-select']}>
      <Select options={allPeriods} onChange={onChange} selectedOption={selectedOption} isDisabled={isPlaying} />

      {['streemdemo@streem.eu', 'german_demo@streem.eu'].includes(user.email) && (
        <Button
          icon={isPlaying ? 'Pause' : 'Play'}
          size="xs"
          variant="primary-2"
          className="ml-2"
          onClick={handlePlay}
        />
      )}
    </div>
  );
};
