import React from 'react';
import { ProgressCard } from '@GDM/Card';
import { Colon } from '@GDM/Colon';
import useTranslation from '@hooks/useTranslation';

type AutoConsumptionWidgetProps = { autoConso?: number; autoProd?: number };

export function AutoConsumptionWidget({ autoConso, autoProd }: AutoConsumptionWidgetProps) {
  const { t } = useTranslation();

  return (
    <ProgressCard value={autoConso ?? 0} title="common.autoconso">
      <div className="mb-2 tt:capitalize">
        {t('monitoring.installation.injection')}
        <Colon />
        &nbsp;{autoProd ? `${autoProd}%` : '--'}
      </div>
    </ProgressCard>
  );
}
