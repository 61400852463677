import { OptionCountry } from './CustomComponents/OptionCountry';
import OptionEnergy from './CustomComponents/OptionEnergy/OptionEnergy';
import { DefaultOption } from './DefaultOption';

export const getOptionComponent = (isCountry?: boolean, isInstallationOrBook?: boolean) => {
  if (isCountry) {
    return OptionCountry;
  }

  if (isInstallationOrBook) {
    return OptionEnergy;
  }

  return DefaultOption;
};
