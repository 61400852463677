import React from 'react';
import { Select } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import type { InstallationForm } from '@pages/Installations/Installation/installation.types';
import { Option } from '@utils/types/common-types';
import { meterModels } from '@utils/types/meter_models';
import { Controller } from 'react-hook-form';

export const ModelSelect = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('model');

  const options: Option<InstallationForm['model']>[] = meterModels.map((model) => ({ value: model, label: model }));

  return (
    <Controller
      name="model"
      rules={{ ...rules, required: true }}
      control={form.control}
      render={({ field, fieldState }) => (
        <Select
          classNamePrefix="meter-model"
          label="admin.installations.meter_model"
          size="lg"
          full
          isSearchable={true}
          options={options}
          selectedOption={field.value}
          onChange={(option) => field.onChange(option?.value)}
          isDisabled={disabled}
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};
