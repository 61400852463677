import { createContext, useContext } from 'react';
import { PricingMapsFiltersContext } from './pricing-maps.types';

export const pricingMapsFiltersContext = createContext<PricingMapsFiltersContext>({
  filters: {
    country: null,
    result: 'deviation',
    run: '',
    periodId: null,
  },
  setFilters: () => {},
});

export const usePricingMapsFiltersContext = () => useContext(pricingMapsFiltersContext);
