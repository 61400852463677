import { useCallback, type Dispatch, type SetStateAction } from 'react';
import type { Step } from '@GDM/forms';
import { v2_installations_path } from '@utils/routes';
import type { UseFormClearErrors } from 'react-hook-form';
import type { InstallationForm, StepKey } from '../installation.types';

export const useStepsNavigation = (
  setSelectedStep: Dispatch<SetStateAction<StepKey>>,
  selectedStep: StepKey,
  steps: { [key in StepKey]?: Step | undefined },
  clearErrors: UseFormClearErrors<InstallationForm>,
) => {
  const stepKeys = Object.keys(steps) as StepKey[];

  const handleNextStep = useCallback<NextStepHandler>(
    (redirectDirectly) => {
      clearErrors();

      const isLastStep = stepKeys.indexOf(selectedStep) === stepKeys.length - 1;

      if (isLastStep || redirectDirectly) {
        window.location.href = v2_installations_path();

        return;
      }

      setSelectedStep((currentStep) => {
        const currentIndex = stepKeys.indexOf(currentStep);

        if (currentIndex === stepKeys.length - 1) {
          return currentStep;
        }

        const newSelectedStep = stepKeys[currentIndex + 1];

        return newSelectedStep;
      });
    },
    [stepKeys, setSelectedStep, clearErrors, selectedStep],
  );

  const handlePreviousStep = useCallback(() => {
    clearErrors();
    const currentIndex = stepKeys.indexOf(selectedStep);

    if (currentIndex === 0) return;

    const newSelectedStep = stepKeys[currentIndex - 1];

    setSelectedStep(newSelectedStep);
  }, [selectedStep, stepKeys, setSelectedStep, clearErrors]);

  return { handleNextStep, handlePreviousStep };
};

export type NextStepHandler = (redirectDirectly?: boolean) => void;
