import { UserCtxType } from '@context/User.context';
import { tString } from '@hooks/useTranslation';

/**
 * Return the correct translated string based on the user `account_type`
 */
export const getSmartTranslation = (user: UserCtxType, key: string): string => {
  const suffix = user.account_type === 'aggregator' ? '_aggregator' : '';

  return tString(user.locale)(key + suffix);
};
