import React from 'react';
import type { Option } from '@utils/types/common-types';
import type { GroupBase, OptionProps } from 'react-select';
import { components } from 'react-select';
import { FormattedOptionEnergy } from './FormattedOptionEnergy';

const OptionEnergy = <T extends string>(props: OptionProps<Option<T>, boolean, GroupBase<Option<T>>>) => {
  return (
    <components.Option {...props}>
      <FormattedOptionEnergy {...props.data} />
    </components.Option>
  );
};

export default OptionEnergy;
