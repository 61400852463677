import { ERRORS_TO_REMAP } from '@GDM/FormErrors/errors-to-remap';
import type { RequestError, RequestErrorBody } from '@hooks/useRequest';
import { tString } from '@hooks/useTranslation';
import type { Locale } from '@utils/types/common-types';
import { isAxiosError, type AxiosError } from 'axios';
import type { ErrorOption, FieldValues, Path } from 'react-hook-form';

export const getErrorsFromRequest = <T extends FieldValues>(
  requestsErrors: Array<AxiosError<RequestErrorBody> | null>,
  locale: Locale,
  errorsMap = ERRORS_TO_REMAP,
): {
  formErrors: FormError<T>[];
  globalError: string | null;
} => {
  const t = tString(locale);
  let globalError: string | null = null;
  const errors = requestsErrors
    .flatMap((requestError) => {
      if (requestError && isAxiosError<RequestErrorBody>(requestError) && requestError.response?.data?.errors) {
        const requestErrors = requestError.response.data.errors;

        return requestErrors;
      }

      if (requestError && isAxiosError<RequestErrorBody>(requestError) && !requestError.response?.data?.errors) {
        globalError = requestError.response?.data?.code || 'errors.unknown_error';
      }
    })
    .filter((error): error is RequestError => Boolean(error));

  const formErrors = errors
    .map((error) => {
      if (!error) return;

      const field = (error.field === 'ref' ? 'prm' : error.field) as Path<T>;

      if (field === 'status') {
        globalError = error.code;

        return;
      }

      const message = errorsMap[error.code ?? '']
        ? t(errorsMap[error.code ?? ''], { prop: `<code>${field}</code>`, date: error?.date, count: error?.count })
        : error.code || '';

      const formError: FormError<T> = [field, { message }];

      return formError;
    })
    .filter((error): error is FormError<T> => Boolean(error));

  return { formErrors, globalError };
};

type FormError<T> = [Path<T>, ErrorOption];
