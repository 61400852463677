import { FieldSection, InstallationFormField } from '../installation.types';

export const FIELD_SECTIONS = new Map<InstallationFormField['name'], FieldSection>([
  ['energy', 'installation'],
  ['country', 'installation'],
  ['name', 'installation'],

  ['company_number', 'owner'],
  ['short_name', 'owner'],
  ['company_number', 'owner'],
  ['short_name', 'owner'],
  ['erp_code', 'owner'],
  ['bank', 'owner'],
  ['iban', 'owner'],
  ['swift_bic', 'owner'],
  ['invoicing_emails', 'owner'],
  ['owner_id', 'owner'],

  ['grid_id', 'meter'],
  ['data_provider_id', 'meter'],
  ['mandate_uuid', 'meter'],
  ['prm', 'meter'],
  ['numero', 'meter'],
  ['key', 'meter'],
  ['aiguillage', 'meter'],
  ['equipment_direction', 'meter'],
  ['ip', 'meter'],
  ['tcp_port', 'meter'],
  ['boitier_ip', 'meter'],
  ['serial_number', 'meter'],
  ['model', 'meter'],
  ['typecompteur', 'meter'],
  ['meter_pass_address', 'meter'],
  ['producteur', 'meter'],
  ['via_data_provider', 'meter'],
  ['via_direct_connection', 'meter'],
  ['meter_request_email', 'meter'],
  ['meter_request_name', 'meter'],
  ['meter_request_comment', 'meter'],
  ['data_provider_number', 'meter'],
]);
