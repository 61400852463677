import { createContext } from 'react';
import { Contract } from '@utils/types/contract';

export const selectedContractsContext = createContext<{
  selectedContracts: Contract[];
  selectContract: (contract: Contract) => void;
  selectAllContracts: () => void;
  unSelectAllContracts: () => void;
}>({
  selectedContracts: [],
  selectContract: () => {},
  selectAllContracts: () => {},
  unSelectAllContracts: () => {},
});
