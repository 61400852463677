import { createContext, useContext } from 'react';
import Installation from '@utils/types/installation';

type SelectedInstallationContext = {
  selectedInstallation: Installation | null;
  setSelectedInstallation?: (installation: Installation | null) => void;
};

export const selectedInstallationContext = createContext<SelectedInstallationContext>({
  selectedInstallation: null,
});

export const useSelectedInstallation = (): SelectedInstallationContext => useContext(selectedInstallationContext);
