import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InstallationSelect } from '../fields/General/InstallationSelect';
import { EnergyInput } from '../fields/Installation/EnergyInput';
import { LatitudeInput } from '../fields/Installation/LatitudeInput';
import { LongitudeInput } from '../fields/Installation/LongitudeInput';
import { NameInput } from '../fields/Installation/NameInput';
import { useFormProgress } from '../hooks/useFormProgress';
import styles from '../new-simulation.module.scss';
import { SimulationForm } from '../utils/simulations.types';

export const ProjectSection = () => {
  const { watch } = useFormContext<SimulationForm>();
  const installationId = watch('installation_attributes.id');

  const disabled = installationId === null || installationId !== '';

  useFormProgress();

  return (
    <div className="p-3">
      <div className={styles['sub-form']}>
        <div className={styles['row']}>
          <InstallationSelect />
        </div>

        <div className={styles['row']}>
          <NameInput disabled={disabled} />
          <EnergyInput disabled={disabled} />
        </div>

        <div className={styles['row']}>
          <LatitudeInput disabled={disabled} />
          <LongitudeInput disabled={disabled} />
        </div>
      </div>
    </div>
  );
};
