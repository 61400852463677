import React, { useMemo } from 'react';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { CONTRACT_TYPES_WITH_EDF_FORMULAS } from '@pages/Contracts/Contract/Form/constants/contract-types-with-edf-formulas';
import { ContractFormQueries } from '@pages/Contracts/Contract/hooks/useContractForm/useContractFormQueries';
import { ContractForm, ContractType } from '@utils/types/contract';
import { UseFormReturn } from 'react-hook-form';
import styles from '../../../sub-period.module.scss';

export const IndexationSettings = ({
  form: { control, watch },
  indexFormulas,
  contractType,
  readOnly,
}: {
  form: UseFormReturn<ContractForm>;
  indexFormulas: ContractFormQueries['indexFormulas'];
  contractType: ContractType;
  readOnly: boolean;
}) => {
  const rateChange = watch('rate_change');

  const indexFormulaOptions = useMemo(() => {
    if (!indexFormulas.data) return [];
    const { edfFormulas, formulas } = indexFormulas.data;
    const filteredFormulaList = CONTRACT_TYPES_WITH_EDF_FORMULAS.includes(contractType) ? edfFormulas : formulas;

    return [
      ...filteredFormulaList?.map(({ formula }) => ({
        label: formula,
        value: formula,
      })),
    ];
  }, [contractType, indexFormulas.data]);

  return (
    <div className={styles['row-3']}>
      <ListInput
        id="IndexFormulaPicker"
        control={control}
        name="index_formula"
        label="sales_management.index_formula"
        options={indexFormulaOptions}
        readOnly={readOnly}
        menuPlacement="top"
        isLoading={indexFormulas.isPending}
      />
      <ListInput
        id="RateChangePicker"
        control={control}
        name="rate_change"
        label="sales_management.rate_change"
        options={[
          { value: 'commissioning_date', label: 'sales_management.commissioning_date' },
          { value: '1st_nov', label: 'sales_management.1st_nov' },
          { value: '1st_jan', label: 'sales_management.1st_jan' },
        ]}
        readOnly={readOnly}
        menuPlacement="top"
      />
      {rateChange === 'commissioning_date' && (
        <DateInput
          id="BirthdayDatePicker"
          name="birthday_date"
          control={control}
          rules={{ required: true }}
          label="sales_management.commissioning_date"
        />
      )}
    </div>
  );
};
