import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { useFormContext } from 'react-hook-form';
import { UnavailabilitiesFiltersType } from './type/filters';

export const DatesFilter = () => {
  const form = useFormContext<UnavailabilitiesFiltersType>();
  const handleDatesChange = ([newStartDate, newEndDate]: [Date | null, Date | null]) => {
    form.setValue('startDate', newStartDate);
    form.setValue('endDate', newEndDate);
  };

  return (
    <DatePicker
      onChange={handleDatesChange}
      startDate={form.watch('startDate')}
      endDate={form.watch('endDate')}
      selectsRange={true}
    />
  );
};
