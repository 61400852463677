import { ReactiveInstruction } from './types';

export const DEFAULT_INSTRUCTION: ReactiveInstruction = {
  start_month_day: '01-01',
  end_month_day: '01-01',
};

export const WINTER_INSTRUCTION: ReactiveInstruction = {
  start_month_day: '11-01',
  end_month_day: '04-01',
};

export const SUMMER_INSTRUCTION: ReactiveInstruction = {
  start_month_day: '04-01',
  end_month_day: '11-01',
};
