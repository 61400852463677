import React from 'react';
import { ValueCard } from '@GDM/Card';
import { Colon } from '@GDM/Colon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { useCurrency } from '@utils/string/useCurrency';
import { ReactiveType } from '@utils/types/reactive_constraint';
import classNames from 'classnames';

export type ReactivePowerMetricsProps = {
  reactiveEnergyBalance: number | undefined;
  tanPhi: number | undefined;
  areReactiveConstraintsRespected: boolean | undefined;
  reactiveType: ReactiveType | undefined;
  injectionConstraint: boolean | undefined;
  tanphiMin: number | undefined;
  tanphiMax: number | undefined;
  qMin: number | undefined;
  qMax: number | undefined;
  maxPower: number | undefined;
  reactivePenalty: number | null | undefined;
};

export const ReactivePowerMetrics = ({
  reactiveEnergyBalance,
  tanPhi,
  areReactiveConstraintsRespected,
  reactiveType,
  injectionConstraint,
  tanphiMin,
  tanphiMax,
  qMin,
  qMax,
  maxPower,
  reactivePenalty,
}: ReactivePowerMetricsProps) => {
  const { t } = useTranslation();
  const currency = useCurrency();

  return (
    <ValueCard title="common.reactive" value={reactiveEnergyBalance ?? 0} unit="kVARh">
      <div>
        <b>{t('common.tg_phi')}</b>
        <Colon />
        &nbsp;
        <span
          className={classNames({
            'text-green': reactiveType === 'tan_phi' && areReactiveConstraintsRespected,
            'text-red': reactiveType === 'tan_phi' && !areReactiveConstraintsRespected,
          })}
        >
          {`${tanPhi ? Math.abs(tanPhi).toFixed(3) : ''} `}
          {`(${
            tanPhi && tanPhi < 0 ? t('monitoring.installation.injection') : t('monitoring.installation.withdrawal')
          })`}
        </span>
        <br />
        <b>{t('monitoring.installation.order')}</b>
        <Colon />
        &nbsp;
        {reactiveType === 'tan_phi' && (
          <>
            {injectionConstraint ? 'I' : 'S'} {t('common.between')} {tanphiMin} {t('common.and')} {tanphiMax}
          </>
        )}
        {reactiveType === 'qfu' && (
          <>
            Q=f(U)
            <br />
            Qmax = {qMax} x {maxPower} kVA / Qmin ={qMin} x {maxPower} kVA
          </>
        )}
        {!reactiveType && <em>{t('monitoring.installation.no_order').toLocaleLowerCase()}</em>}
        {typeof reactivePenalty === 'number' && (
          <>
            <br />
            <Tooltip tooltip="monitoring.installation.reactive_energy_component">
              <b>{t('monitoring.installation.rec_penalties')}</b>
            </Tooltip>
            <Colon />
            &nbsp;
            {`${reactivePenalty?.toFixed(2)} ${currency}`}
          </>
        )}
      </div>
    </ValueCard>
  );
};
