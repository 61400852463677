import React, { useMemo, useState } from 'react';
import { Input, Select } from '@GDM/forms';
import { Text } from '@GDM/Text';
import Installation from '@utils/types/installation';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { BookFormContext, InstallationOption } from './book-form.types';
import { installationToOption } from './installationToOption';
import { SelectedBooksTable } from './SelectedBooksTable';

export const BookForm = ({ installations }: { installations: Installation[] }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<BookFormContext>();

  const selectedInstallations = watch('installations');
  const selectedOptions = installations.filter((i) => selectedInstallations.includes(i.name));

  const [selectedType, setSelectedType] = useState<Installation['status'] | 'none'>(
    // Default is meter if no installations are selected
    selectedOptions.length ? selectedOptions[0].status : 'none',
  );

  const options = useMemo<InstallationOption[]>(
    () =>
      installations
        .filter((i) => selectedType === 'none' || selectedType === getType(i.status))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(installationToOption),
    [installations, selectedType],
  );

  const handleSelectChange =
    (field: ControllerRenderProps<BookFormContext, 'installations'>) => (options: InstallationOption[] | null) => {
      field.onChange(options?.map((opt) => opt.value));

      setSelectedType(getType(options?.[0]?.status));
    };

  return (
    <>
      <Controller
        name="name"
        rules={{ required: true, minLength: 1 }}
        control={control}
        render={({ field, fieldState }) => (
          <Input {...field} full id="book-name" data-cy="book-name" hasError={!!fieldState.error} label="common.name" />
        )}
      />
      {errors?.name?.type === 'required' && (
        <Text size="sm" className="mt-1" type="danger" text="admin.books.name_error" />
      )}

      <div className="mt-3">
        <Controller
          name="installations"
          rules={{ validate: (list) => list?.length > 0 }}
          control={control}
          render={({ field }) => (
            <Select
              label="common.installations"
              tooltip="monitoring.books.errors.mixed_types"
              isMulti
              options={options}
              selectedOptions={field.value}
              classNamePrefix="select-installations"
              hasSelectAll
              onChange={handleSelectChange(field)}
              isInstallationOrBook
            />
          )}
        />
      </div>

      {!!selectedOptions.length && (
        <div className="mt-3">
          <SelectedBooksTable installations={selectedOptions} />
        </div>
      )}
    </>
  );
};

/**
 * This is to group draft and operational installations together as they are both considered operational for the user
 */
const getType = (status?: Installation['status']): Installation['status'] | 'none' => {
  if (!status) return 'none';

  switch (status) {
    case 'operational':
    case 'draft':
      return 'operational';
    default:
      return status;
  }
};
