import React from 'react';
import contractsContext from '@context/contracts.context';
import { useUser } from '@context/User.context';
import { Spinner } from '@GDM/Spinner';
import { ContractsProvider } from '@pages/Contracts/Contracts/context';
import { TableWrapper } from '@pages/Contracts/Contracts/Table';
import { Contract } from '@utils/types/contract';

export const ContractsTab = ({
  contracts,
  bookId,
  installationId,
  meterId,
  marketPlayerId,
}: {
  contracts?: Contract[];
  meterId?: string;
  installationId?: string;
  bookId?: string;
  marketPlayerId?: string;
}) => {
  const user = useUser();

  return (
    <ContractsProvider
      user={user}
      contracts={contracts}
      installationId={installationId}
      meterId={meterId}
      bookId={bookId}
      marketPlayerId={marketPlayerId}
    >
      <contractsContext.Consumer>
        {(context) => (
          <>
            {context.isLoading && (
              <div className="ta:center">
                <Spinner size="sm" />
              </div>
            )}
            {!context.isLoading && <TableWrapper />}
          </>
        )}
      </contractsContext.Consumer>
    </ContractsProvider>
  );
};
