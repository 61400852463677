import { createOwner, getOwners } from '@api/owners';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation, useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query';
import Installation, { InstallationWithMeterInfo } from '@utils/types/installation';
import type MarketPlayer from '@utils/types/market_player';
import type { AxiosError } from 'axios';
import type { UseFormSetValue } from 'react-hook-form';
import { formatOwnerBody } from '../Owner/OwnerForm/utils/formatOwnerBody';
import type { NextStepHandler } from './hooks/useStepsNavigation';
import type { InstallationForm } from './installation.types';

const onSuccess = (
  data: MarketPlayer,
  queryClient: QueryClient,
  goToNextStep?: NextStepHandler,
  setFormValue?: UseFormSetValue<InstallationForm>,
) => {
  queryClient.setQueryData<MarketPlayer[]>(['owners'], (prevOwners) => {
    return prevOwners ? [...prevOwners, data] : [data];
  });
  queryClient.setQueryData<InstallationWithMeterInfo>(['installation-name'], (prevInstallation) => {
    if (prevInstallation) return { ...prevInstallation, owner_uuid: data.id };
  });

  const installation = queryClient.getQueryData<Installation>(['installation-name']);
  const redirectDirectly = installation?.status === 'draft';

  setFormValue?.('owner_id', data.id);

  goToNextStep?.(redirectDirectly);
};

export const useOwnerFormQuery = (props?: {
  goToNextStep?: () => void;
  setFormValue?: UseFormSetValue<InstallationForm>;
}) => {
  const { goToNextStep, setFormValue } = props || {};
  const queryClient = useQueryClient();
  const query = useQuery<MarketPlayer[], AxiosError<RequestErrorBody>>({
    queryKey: ['owners'],
    queryFn: () => getOwners({ withMandatesOwners: true }),
  });

  const createMutation = useMutation<MarketPlayer, AxiosError<RequestErrorBody>, InstallationForm>({
    mutationFn: (body) => createOwner(formatOwnerBody(body)),
    onSuccess: (data) => onSuccess(data, queryClient, goToNextStep, setFormValue),
  });

  return { query, createMutation };
};
