import React from 'react';
import { Restricted } from '@components/Restricted';
import { useTabs } from '@GDM/Tabs';
import MarketPlayer from '@utils/types/market_player';
import { ContractsTab } from 'pages/ui/ContractsTab';
import { M0Deviation } from 'pages/ui/M0Deviation/M0Deviation';
import { InvoicesTab } from './InvoicesTab';
import { TabIds } from './market-players.types';
import { ProductionTab } from './ProductionTab';
import { Revenues } from './Revenues';

export const MarketPlayersTabContent = ({ marketPlayer }: { marketPlayer: MarketPlayer }) => {
  const { currentTab } = useTabs<TabIds>();

  return (
    <div className="p-3">
      <Restricted permissions={['display:view_monitoring']}>
        {currentTab === 'production' && <ProductionTab marketPlayer={marketPlayer} />}
        {currentTab === 'revenue' && <Revenues identifier={marketPlayer.id} marketPlayer={marketPlayer} />}
        {currentTab === 'contracts' && <ContractsTab marketPlayerId={marketPlayer.id} />}
        {currentTab === 'invoices' && <InvoicesTab marketPlayer={marketPlayer} />}
        {currentTab === 'm0_deviation' && <M0Deviation marketPlayer={marketPlayer} />}
      </Restricted>
    </div>
  );
};
