import React, { useCallback, useEffect, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import useBooks from '@hooks/requests/useBooks';
import { useInstallations } from '@hooks/requests/useInstallations';
import { destroy, useRequest } from '@hooks/useRequest';
import Page from '@pages/Page';
import { v2_book_path } from '@utils/routes';
import Book from '@utils/types/book';
import { User } from '@utils/types/user';
import { BookFormModal } from './BookForm';
import { BooksFilters } from './BooksFilters';
import { BooksPageProvider } from './BooksPageProvider';
import { BooksTable } from './BooksTable';
import { DeleteBookModal } from './DeleteBookModal';

const BooksPage = ({ user }: { user: User }) => {
  const { data: allBooks, error: booksError, isLoading: booksLoading } = useBooks();
  const {
    data: installations,
    error: installationsError,
    loading: installationsLoading,
  } = useInstallations({
    type: 'all',
  });
  const [books, setBooks] = useState<Book[]>(allBooks || []);
  const [modalVisible, setModalVisible] = useState(false);
  const [bookToDelete, setBookToDelete] = useState<Book | null>(null);
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const deleteRequest = useRequest(v2_book_path(bookToDelete?.uuid || 'none'), destroy, true);

  const toggle = useCallback(() => {
    setModalVisible((prevModalVisible) => {
      if (prevModalVisible) {
        setSelectedBook(null);
      }

      return !prevModalVisible;
    });
  }, []);

  useEffect(() => {
    setBooks(allBooks || []);
  }, [allBooks]);

  const addBook = useCallback(
    (book: Book) => {
      setBooks((prevBooks) => {
        const clonedBooks = [...prevBooks];
        const bookIndex = clonedBooks.findIndex((bookSearch) => bookSearch.uuid === book.uuid);

        if (bookIndex > -1) {
          clonedBooks[bookIndex] = book;

          return clonedBooks;
        } else {
          return [...prevBooks, book];
        }
      });

      toggle();
    },
    [toggle],
  );

  const handleSelectBook = useCallback(
    (uuid: string) => {
      const book = books.find((book) => book.uuid === uuid);

      if (book) {
        setSelectedBook(book);
        toggle();
      }
    },
    [books, toggle],
  );

  const handleDelete = useCallback(
    (uuid: string) => {
      const book = books.find((book) => book.uuid === uuid);

      if (book) {
        setBookToDelete(book);
      }
    },
    [books],
  );

  const confirmDelete = useCallback(() => {
    const bookIndex = books.findIndex((book) => book.uuid === bookToDelete?.uuid);

    deleteRequest.execute?.();

    if (bookIndex > -1) {
      const clonedBooks = [...books];
      clonedBooks.splice(bookIndex, 1);

      setBooks(clonedBooks);
      setBookToDelete(null);
    }
  }, [books, bookToDelete, deleteRequest]);

  return (
    <Page
      title="common.books"
      user={user}
      useQueryClient={false}
      isLoading={booksLoading || installationsLoading}
      error={booksError?.code || installationsError?.code ? booksError?.code || installationsError?.code : null}
      header={
        <BooksPageProvider onDelete={handleDelete} onSelect={handleSelectBook}>
          <BooksFilters allBooks={allBooks || []} setBooks={setBooks} />

          <Restricted permissions={['create:book']}>
            <div className="d-flex">
              <Button
                onClick={toggle}
                color="primary"
                className="ml-auto"
                icon="Plus"
                size="xxs"
                text="common.add_new"
                data-cy="add-book"
                variant="primary-2"
              />
            </div>
          </Restricted>

          <BookFormModal
            isOpen={modalVisible}
            toggle={toggle}
            installations={installations || []}
            addBook={addBook}
            selectedBook={selectedBook}
            key={selectedBook?.uuid}
          />

          <DeleteBookModal book={bookToDelete} onCancel={() => setBookToDelete(null)} onConfirm={confirmDelete} />

          <BooksTable books={books} isLoading={booksLoading} className="mt-3" />
        </BooksPageProvider>
      }
    />
  );
};

export default BooksPage;
