import safeSumReducer from '@utils/safeSumReducer';
import { Installation, InstallationWithMeterInfo } from '@utils/types/installation';
import { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';

export const computeAvailabilities = (
  unavailabilities: Unavailability[],
  total_p_max: number,
  start_date: Date | null,
  end_date: Date | null,
): [number, number][] => {
  const availabilities: [number, number][] = [];
  let date: Date = dayjs(start_date).startOf('day').toDate();

  while (end_date && date <= end_date) {
    const total_real_capacity = unavailabilities
      .filter(
        (unavailabilities) =>
          date >= dayjs(unavailabilities.start_date).toDate() && date <= dayjs(unavailabilities.end_date).toDate(),
      )
      .map((unavailability) => unavailability.real_capacity_value || 0)
      .reduce(safeSumReducer, 0);

    availabilities.push([date.getTime(), total_p_max - total_real_capacity]);
    date = dayjs(date).add(1, 'hour').toDate();
  }

  return availabilities;
};

export const computeTotalPmax = (installations: Array<Installation | InstallationWithMeterInfo>): number =>
  installations
    ? installations
        .map((installation) => ('p_max' in installation ? installation.p_max || 0 : 0))
        .reduce(safeSumReducer, 0) / 1000 // to convert to MW
    : 0;
