import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { type Contract, type DeliveryProfile } from '@utils/types/contract';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const getOptions = (contracts: Contract[]) => {
  const contractDeliveryProfiles = Array.from(
    new Set(contracts?.map((c) => c.delivery_profile).filter((c): c is DeliveryProfile => !!c) || []),
  );

  const options: Option<DeliveryProfile>[] = contractDeliveryProfiles
    .map((profile) => ({ label: `sales_management.${profile}`, value: profile }))
    .sort(sortOptionsByLabelAsc);

  return options;
};

export const DeliveryProfileFilter = () => {
  const { filterContracts, contracts, allContracts } = useConsumeContracts();
  const form = useFormContext<ContractsFiltersType>();
  const options = useDynamicOptions(getOptions, 'delivery_profile', contracts, allContracts);

  return (
    <Controller
      name="delivery_profile"
      control={form.control}
      render={({ field }) => (
        <Select
          placeholder="sales_management.delivery_profile"
          isClearable
          selectedOption={field.value}
          isSearchable
          options={options}
          onChange={(option: Option<DeliveryProfile> | null) => {
            filterContracts({
              ...form.getValues(),
              delivery_profile: option?.value || null,
              last_selected_filter: 'delivery_profile',
            });
            field.onChange(option?.value);
            form.setValue('last_selected_filter', option?.value ? 'delivery_profile' : null);
          }}
        />
      )}
    />
  );
};
