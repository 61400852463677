import fetchWrapper, { type ReqError } from '@utils/fetch';
import { v2_grids_path } from '@utils/routes';
import type { CountryCode } from '@utils/types/countries';

export type Grid = {
  id: number;
  name: string;
  data_providers: {
    id: number;
    name: string;
  }[];
};

export const fetchGrids = async (country?: CountryCode | null): Promise<Grid[]> => {
  const req = await fetchWrapper(v2_grids_path({ params: { country } }));
  const data = await req.json();

  if (!req.ok) {
    throw { code: data.code, message: data.message, errors: data.errors } satisfies ReqError;
  }

  return data;
};
