import React, { useContext } from 'react';
import { Checkbox } from '@GDM/forms';
import { CellContext } from '@tanstack/react-table';
import { DashboardViewAllData } from '../../../portfolio.definitions';
import launchTrCtx from '../../launchTr.context';

export const CheckboxCell = (props: React.PropsWithChildren<CellContext<DashboardViewAllData, unknown>>) => {
  const { addMeter, removeMeter, disabledMeters, selectedMeters } = useContext(launchTrCtx);
  const name = props.row.original?.ref_centrale;

  const isDisabled = disabledMeters?.includes(name);
  const isSelected = selectedMeters?.includes(name);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked ? addMeter?.(name) : removeMeter?.(name);
  };

  return <Checkbox disabled={isDisabled} checked={isSelected} onChange={handleOnChange} />;
};
