import React from 'react';
import { DatePickerProps } from '@GDM/DatePicker';
import { DatePicker } from '@GDM/DatePicker/DatePicker';
import { FieldValues, Control, FieldPath, ControllerProps, PathValue, useController } from 'react-hook-form';

export type ControlledDatePickerProps<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
> = Omit<DatePickerProps, 'startDate' | 'endDate' | 'onChange'> &
  Omit<ControllerProps<TFieldValues, TFieldName>, 'render'> & {
    name: TFieldName;
    control: Control<TFieldValues, TFieldName>;
  } & (
    | {
        selectsRange: true;
        startKey: keyof PathValue<TFieldValues, TFieldName>;
        endKey: keyof PathValue<TFieldValues, TFieldName>;
      }
    | {
        selectsRange: true;
        startKey?: never;
        endKey?: never;
      }
    | {
        selectsRange?: false;
        startKey?: never;
        endKey?: never;
      }
  );

export default function ControlledDatePicker<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  rules,
  startKey,
  endKey,
  defaultValue,
  shouldUnregister,
  ...datePickerProps
}: ControlledDatePickerProps<TFieldValues, TFieldName>) {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
    disabled: datePickerProps.disabled,
  });

  return datePickerProps.selectsRange ? (
    <DatePicker
      {...datePickerProps}
      selectsRange
      startDate={field.value?.[startKey ?? 0]}
      endDate={field.value?.[endKey ?? 1]}
      hasError={Boolean(fieldState.error)}
      errorMessage={fieldState.error?.message}
      onChange={(dates) => {
        const value = startKey || endKey ? { [startKey ?? 0]: dates?.[0], [endKey ?? 1]: dates?.[1] } : dates;

        return field.onChange(value);
      }}
    />
  ) : (
    <DatePicker {...datePickerProps} selectsRange={false} selected={field.value} onChange={field.onChange} />
  );
}
