import { formatNumber } from '@utils/formatters/formatNumber';
import jsonToCsv, { HeadCsv } from '@utils/json-to-csv';
import { MeterInvoice } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';

const remitCsvExport = (invoices: MeterInvoice[]): string => {
  // since french number formats include comma we need to use another delimiter
  const options = { delimiter: ';' };

  const remitFormatNumber = (value: number, options?: Intl.NumberFormatOptions): string => {
    return formatNumber(value, undefined, options)?.replace(/\s+/g, ' ') || '';
  };

  const getUnitPrice = (invoice: MeterInvoice): string => {
    // if volume_production is 0, we can't divide by it, so return null
    if (!invoice.total_amount || !invoice.volume_production) return '';

    return remitFormatNumber((invoice.total_amount * 1000) / invoice.volume_production, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const getUnitNotionalAmount = (invoice: MeterInvoice): string => {
    if (invoice.total_amount === null) return '';

    return remitFormatNumber(invoice.total_amount, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const getTransactionDate = (invoice: MeterInvoice): string => {
    // use last_status_at instead of issued_at because some client only approve invoices
    if (invoice.last_status_at === null) return '';

    return dateFormat(invoice.last_status_at);
  };

  const dateFormat = (d: string): string => dayjs.utc(d, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const AddAcerIdFirstHeader = (headers: HeadCsv<MeterInvoice>[], delimiter: string): string => {
    const columnToAcerId = {
      'Linked Transaction Id': 32,
      'Market participant': 1,
      Counterparty: 4,
      'Buy/Sell': 11,
      'Start Date': 49,
      'End Date': 50,
      'Fixed Price': 35,
      'Price Unit': 37,
      'Notional Amount': 38,
      'Notional Price Unit': 39,
      'Notional Quantity': 41,
      'Notional Quantity Unit': 42,
      'Transaction Date': 30,
      Duration: 51,
      'Load type': 52,
      Quantity: 40,
      'Quantity Unit': 42,
      'Terminaison Date': 43,
      Action: 58,
      'Load delivery profile': 54,
      'Contract Name': 22,
      'Contract Type': 23,
      Commodity: 24,
      'Indexes Names': 25,
      'Settlement Type': 26,
      'Network Point EIC Code': 48,
      Trader: 3,
      'Unique Transaction Id': 31,
      'Organised market place': 27,
      'Contract ID': 21,
      'Trading capicty of market participant': 10,
    };

    let line = '';

    let count = 0;
    headers.forEach((item, index) => {
      if (count === 0) line += 'id champ ACER';
      else {
        const acer_id = columnToAcerId[item.label as keyof typeof columnToAcerId];
        if (acer_id) line += `${acer_id}`;
      }

      if (index === headers.length - 1) line += '\n';
      else line += delimiter;

      count++;
    });

    return line;
  };

  const headers: HeadCsv<MeterInvoice>[] = [
    { label: 'Nom du champ', key: 'installation_name' },
    { label: 'Correlation Trade Id', getValue: (invoice) => dateFormat(invoice.start_date) },
    { label: 'Linked Transaction Id', key: 'contract_nb' },
    { label: 'Market Participant', key: 'contract_seller_acer_code' },
    { label: 'Counterparty', key: 'contract_buyer_acer_code' },
    { label: 'Buy/Sell', value: 'Sell' },
    { label: 'Start Date', getValue: (invoice) => dateFormat(invoice.start_date) + ' 00:00:00' },
    { label: 'End Date', getValue: (invoice) => dateFormat(invoice.client_end_date) + ' 00:00:00' },
    { label: 'Fixed Price', getValue: getUnitPrice },
    { label: 'Price Unit', getValue: (invoice) => invoice.currency },
    { label: 'Notional Amount', getValue: getUnitNotionalAmount },
    { label: 'Notional Price Unit', getValue: (invoice) => invoice.currency },
    {
      label: 'Notional Quantity',
      getValue: (invoice) =>
        remitFormatNumber(invoice.volume_production / 1000, {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        }),
    },
    { label: 'Notional Quantity Unit', value: 'MWh' },
    { label: 'Transaction Date', getValue: getTransactionDate },
    { label: 'Duration', value: '' },
    { label: 'Load Type', value: 'Shaped' },
    { label: 'Quantity', value: '' },
    { label: 'Quantity Unit', value: 'MW' },
    { label: 'Terminaison Date', value: '' },
    { label: 'Action', value: '' },
    { label: 'Load delivery profile', value: '00:00 / 24:00' },
    { label: 'Contract Name', value: 'EXECUTION' },
    { label: 'Contract Type', value: 'ForwardStyleContract' },
    { label: 'Commodity', value: 'Power' },
    { label: 'Indexes Names', value: '' },
    { label: 'Settlement Type', value: 'Physical' },
    { label: 'Network Point EIC Code', value: '10YFR-RTE------C' },
    { label: 'Trader', value: 'NA' },
    { label: 'Unique Transaction Id', value: 'CALCULATED' },
    { label: 'Organised market place', value: 'XBIL' },
    { label: 'Contract ID', value: 'NA' },
    { label: 'Trading capicty of market participant', value: 'P' },
    { label: 'Card-I', key: 'contract_card_i' },
  ];

  const csvData = jsonToCsv(invoices, headers, options);
  const acerIdHeader = AddAcerIdFirstHeader(headers, options.delimiter);

  return acerIdHeader + csvData;
};

export default remitCsvExport;
