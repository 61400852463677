import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { CountrySelect } from '@GDM/forms';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const getOptions = (contracts: Contract[]) => {
  const countries = Array.from(new Set(contracts.map((contract) => contract.installation.country)));

  const options: Option<string>[] = countries
    .map((country) => ({ label: `countries.${country}`, value: country as string }))
    .sort(sortOptionsByLabelAsc);

  return options;
};

export const CountryFilter = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();

  const options: Option<string>[] = useDynamicOptions(getOptions, 'installation_country', contracts, allContracts);

  return (
    <Controller
      name="installation_country"
      control={form.control}
      render={({ field }) => (
        <CountrySelect
          options={options}
          onChange={(option) => {
            filterContracts({
              ...form.getValues(),
              installation_country: option?.value as CountryCode | null,
              last_selected_filter: 'installation_country',
            });
            field.onChange(option?.value);
            form.setValue('last_selected_filter', option?.value ? 'installation_country' : null);
          }}
          selectedOption={field.value}
          placeholder="common.country"
          classNamePrefix="country-picker"
          neverDisable
          isClearable
        />
      )}
    />
  );
};
