import { EnergyType } from '@utils/types/common-types';
import { DEFAULT_SECTIONS, SOLAR_SECTIONS, WIND_SECTIONS } from '../config/field-sections';

export const getSections = (energy: EnergyType | null): Map<string, string> => {
  if (energy === 'solar') {
    return SOLAR_SECTIONS;
  }

  if (energy === 'wind') {
    return WIND_SECTIONS;
  }

  return DEFAULT_SECTIONS;
};
