import React from 'react';
import classNames from 'classnames';
import { ProgressStepperContext } from './context';
import { Step } from './progress-stepper';
import { ProgressStepperContent } from './ProgressStepperContent';

/**
 * @param containerMaxWidth is in rem
 */
export const ProgressStepper = ({
  steps,
  className,
  containerMaxWidth,
  infoBoxDefinitions,
  children,
  isLoading,
  singleStep,
  showNav = true,
}: React.PropsWithChildren<{
  className?: string;
  containerMaxWidth?: number;
  infoBoxDefinitions?: Record<string, { title?: string; text: string }>;
  isLoading?: boolean;
  showNav?: boolean;
  steps?: Record<string, Step>;
  singleStep?: string;
}>) => {
  return (
    <ProgressStepperContext
      initialSteps={steps}
      containerMaxWidth={containerMaxWidth}
      showNav={showNav}
      infoBoxDefinitions={infoBoxDefinitions}
      singleStep={singleStep}
    >
      <ProgressStepperContent className={classNames(className, isLoading && 'unavailable')} />
      {children}
    </ProgressStepperContext>
  );
};
