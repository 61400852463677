import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Alert } from '@GDM/Alert';
import { FormErrors } from '@GDM/FormErrors';
import { patch, post, useRequest } from '@hooks/useRequest';
import { internal_subscription_path, internal_subscriptions_path } from '@utils/routes';
import { useCurrency } from '@utils/string';
import { Subscription } from '@utils/types/subscription';
import { FormProvider, useForm } from 'react-hook-form';
import { SubscriptionForm } from '../bsm.types';
import { serializeSubscriptionForm } from './actions/serializeSubscriptionForm';

type FormContext = { readonly: boolean; loading: boolean };
export const formContext = createContext<FormContext>({ readonly: false, loading: false });

export const SubscriptionFormProvider = ({
  children,
  defaultValues,
  readonly = false,
}: React.PropsWithChildren<{ defaultValues?: Subscription | null; readonly?: boolean }>) => {
  const isEdit = !!defaultValues?.uuid;
  const url = isEdit ? internal_subscription_path(defaultValues?.uuid) : internal_subscriptions_path();
  const { execute, loading, loaded, errors } = useRequest(url, isEdit ? patch : post, true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const currency = useCurrency('code');

  const form = useForm<SubscriptionForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      company_billing_detail: {
        currency,
        time_zone: 'Europe/Paris',
      },
    },
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
    if (defaultValues?.company_billing_detail?.user?.id)
      form.setValue('company_billing_detail.user_id', defaultValues.company_billing_detail.user.id);

    // Show all the fields that are required
    form.trigger();
  }, [defaultValues, form]);

  const onSubmit = async (data: SubscriptionForm) => {
    if (readonly) return;
    setIsSuccessful(false);

    const response = await execute?.(serializeSubscriptionForm(data));

    if (!response) setIsSuccessful(true);
  };

  const contextValue = useMemo<FormContext>(() => ({ readonly, loading }), [readonly, loading]);

  return (
    <formContext.Provider value={contextValue}>
      <FormProvider {...form}>
        {loaded && !loading && isSuccessful && (
          <Alert variant="success" dismissible label="subscriptions.form.is_successful" className="mb-4" />
        )}
        {!loading && !!errors && (
          <div className="mb-4">
            <FormErrors errors={errors} title="subscriptions.form.has_errors" />
          </div>
        )}
        <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </formContext.Provider>
  );
};
