import React, { useRef } from 'react';
import { Card } from '@GDM/Card';
import { Chart } from '@GDM/Chart';
import { ChartRefContent } from '@GDM/Chart/chart.types';
import useTranslation from '@hooks/useTranslation';
import { isANumber } from '@utils/isANumber';
import fileDownload from 'js-file-download';
import round from 'lodash/round';
import { PriceTuple } from './national-prices.types';

export const CapturePriceChart = ({
  data,
  captureFactor,
  type,
}: {
  data: PriceTuple[];
  captureFactor: PriceTuple[];
  type: 'wind' | 'solar';
}) => {
  const { tLocalized } = useTranslation();
  const ref = useRef<ChartRefContent>(null);
  const currentIndex = data.length >= 2 ? data.length - 2 : null;
  const options: Highcharts.Options = {
    chart: { type: 'spline', height: 300 },
    xAxis: { gridLineWidth: 1, crosshair: true },
    yAxis: {
      title: { text: tLocalized(`sales_management.national_prices.chart.capture_price_factor_percentage_${type}`) },
      gridLineWidth: 1,
    },
    exporting: {
      filename: `capture_prices_${type}`,
    },
  };

  const series: Highcharts.Options['series'] = [
    {
      type: 'spline',
      cropThreshold: 59999,
      name: tLocalized(`sales_management.national_prices.chart.capture_price_factor_${type}`),
      data: captureFactor,
      zoneAxis: 'x',
      zones:
        data && currentIndex && data[currentIndex]?.[0]
          ? [{ value: data[currentIndex][0] }, { dashStyle: 'Dot' }]
          : undefined,
      color: type === 'solar' ? 'var(--chart-solar)' : 'var(--chart-wind)',
      tooltip: { valueSuffix: '%' },
    },
  ];

  const handleDownload = () => {
    if (!ref.current) return;

    /**
     * we want to download the data with the capture factor in percentage but with a value between 0 and 1
     */
    const data = ref.current.chart.getDataRows().map((el, index) => {
      if (index === 0) return el;

      const value = typeof el[1] === 'string' ? parseFloat(el[1]) : el[1];
      const newValue = isANumber(value) ? round(value / 100, 4) : null;

      return [el[0], newValue];
    });

    const csv = data.map((el) => el.join(',')).join('\n');

    fileDownload(csv, `capture_prices_${type}.csv`);
  };

  return (
    <Card className="mt-3" title={`sales_management.national_prices.chart.capture_price_factor_avg_${type}`}>
      <div className="w-100">
        <Chart
          options={options}
          series={series}
          hideLegend
          data-cy="capture-price-chart"
          ref={ref}
          handleDownloadCsv={handleDownload}
        />
      </div>
    </Card>
  );
};
