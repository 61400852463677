import React, { useMemo } from 'react';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { stepContext } from '../context/step/step.context';
import { Step as StepType } from '../progress-stepper';
import { card } from '../progress-stepper.module.scss';
import styles from './step.module.scss';

export const Step = ({ step, stepId }: { step: StepType; stepId: string }) => {
  const { t } = useTranslation();

  const contextValue = useMemo(() => ({ stepId, step }), [step, stepId]);

  return (
    <stepContext.Provider value={contextValue}>
      <div className={classNames(card, styles.step)}>
        <div className={styles.title}>
          <span>{t(step?.title)}</span>
          {step.headerActions && <div className={styles.actions}>{step.headerActions}</div>}
        </div>
        {step?.component}
      </div>
    </stepContext.Provider>
  );
};
