import { CommonRequestHookResponse, post, useRequest } from '@hooks/useRequest';
import { payment_v2_meter_invoicing_index_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const useInvoicePayment = (
  ids: string[],
): CommonRequestHookResponse<MeterInvoice[]> & { markAsAccepted: () => void; markAsPaid: () => void } => {
  const request = useRequest<MeterInvoice[]>(payment_v2_meter_invoicing_index_path(), post, true);

  const markAsAccepted = () => {
    request.execute?.({ invoices: { ids }, paid: false });
  };

  const markAsPaid = () => {
    request.execute?.({ invoices: { ids }, paid: true });
  };

  return { markAsAccepted, markAsPaid, ...request };
};
