import { RefObject, useState, CSSProperties, useCallback, UIEventHandler, FocusEvent } from 'react';
import { HelpBoxProps } from '@GDM/HelpBox';

// Scroll is buggy, must be fixed
export const useHelpBox = (wrapperRef: RefObject<HTMLDivElement>) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [infoBox, setInfoBox] = useState<
    { text?: string; title?: string; offsetTop: number; initialScrollTop: number } | undefined
  >(undefined);

  const offsetTopWrapper = (wrapperRef.current?.getBoundingClientRect().top ?? 0) + window.scrollY;
  const cappedOffsetTop = Math.max(0, (infoBox?.offsetTop ?? 0) - offsetTopWrapper);

  const [helpBoxStyle, setHelpBoxStyle] = useState<CSSProperties | undefined>(
    cappedOffsetTop ? { transform: `translateY(calc(${cappedOffsetTop}px - 1em))` } : undefined,
  );

  /** Display help box if there is one. */
  const handleFocus = useCallback(
    (props: HelpBoxProps) => (e: FocusEvent) => {
      const offsetTop = e.target.getBoundingClientRect().top + window.scrollY;

      setInfoBox({ ...props, offsetTop, initialScrollTop: scrollPosition });
    },
    [scrollPosition, setInfoBox],
  );

  /** Hide the box if there's no help info to display. */
  const handleBlur = useCallback(() => {
    setInfoBox(undefined);
  }, [setInfoBox]);

  /** Reposition info box on scroll if need be. */
  const handleScroll = useCallback<UIEventHandler<HTMLDivElement>>(
    (e) => {
      const scrollTop = e.currentTarget.scrollTop;
      const offsetTopWrapper = (wrapperRef.current?.getBoundingClientRect().top ?? 0) + window.scrollY;
      const newOffsetTop = (infoBox?.offsetTop ?? 0) - (scrollTop - (infoBox?.initialScrollTop ?? 0));
      const cappedOffsetTop = Math.max(0, (newOffsetTop ?? 0) - offsetTopWrapper);
      setScrollPosition(scrollTop);

      if (!infoBox) return;

      requestAnimationFrame(() => {
        if (!wrapperRef.current) return;

        const helpBox = wrapperRef.current.children[0]?.getBoundingClientRect();
        const containerHeight = wrapperRef.current?.getBoundingClientRect().height;

        if (!helpBox || !containerHeight) return;

        if (helpBox.height > containerHeight - cappedOffsetTop) {
          setHelpBoxStyle({ bottom: 0 });
        } else {
          setHelpBoxStyle(cappedOffsetTop ? { transform: `translateY(calc(${cappedOffsetTop}px - 1em))` } : undefined);
        }
      });
    },
    [infoBox, wrapperRef],
  );

  return { handleFocus, handleBlur, handleScroll, helpBoxStyle, infoBox };
};
