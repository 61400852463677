import React from 'react';
import { sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import { SPOT_SOURCES } from '@utils/types/contract/constants/spot-sources';
import { ContractForm } from '@utils/types/contract/form';
import { PriceType } from '@utils/types/contract/unions';
import { CountryCode } from '@utils/types/countries/country-code';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { DateValidators } from '../../../hooks/useDateValidation';
import { NegPriceRule } from '../constants/negPriceRules';
import { PRICE_TYPE_OPTIONS_MAP } from '../constants/priceTypes';
import styles from '../sub-period.module.scss';
import { VolumeRatio } from './fields/VolumeRatio';

export function SwapSubPeriod({
  index,
  onDatePeriodClick,
  preSelectedPeriod,
  customPeriods,
  form: { control, watch },
  datesValidation,
  readOnly,
  currency,
  priceTypeOptions,
  country,
  negativePriceRulesOptions,
}: {
  index: number;
  readOnly: boolean;
  form: UseFormReturn<ContractForm>;
  onDatePeriodClick: (period: string, index: number) => void;
  preSelectedPeriod: string | undefined;
  customPeriods:
    | {
        name: string;
        dates: [Date, Date];
      }[]
    | undefined;
  currency: Currency;
  datesValidation: DateValidators;
  priceTypeOptions: Option<PriceType | null>[];
  country?: CountryCode | null;
  negativePriceRulesOptions: Option<NegPriceRule>[];
}) {
  const { t } = useTranslation();
  const deliveryProfile = watch('delivery_profile');

  const sourceOptions =
    SPOT_SOURCES[country as keyof typeof SPOT_SOURCES]?.map((source) => ({
      label: source,
      value: source,
    })) || [];

  return (
    <>
      <div className={styles.section}>
        <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
          <DateInput
            id="SubPeriodDatesPicker"
            name={`contract_sub_periods_attributes.${index}.dates`}
            control={control}
            rules={{ validate: datesValidation }}
            data-cy={`dates-picker-${index}`}
            selectedPeriod={preSelectedPeriod}
            selectsRange
            showPeriodBadge
            customPeriods={customPeriods}
            onClickPeriod={(period) => onDatePeriodClick(period, index)}
            readOnly={readOnly}
            label="sales_management.sub_period.start_end_dates"
          />
        </div>

        <div className="mb-3">
          <div className={styles['subtitle']}>+ Leg A</div>
          <div className={sectionStyles.row}>
            <NumericInput
              id="SwapFixedPrice"
              control={control}
              rules={{ required: true, max: 10000 }}
              name={`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`}
              data-cy={`swap-fixed-price-input-${index}`}
              label="sales_management.swap_fixed_price"
              suffix={`${getSymbol(currency)}/MWh`}
              max={10000}
              step={1}
              required
              readOnly={readOnly}
            />
            <ListInput
              id="PriceType"
              control={control}
              options={priceTypeOptions}
              name={`contract_sub_periods_attributes.${index}.price_type`}
              label="common.price_type"
              rules={{ required: true }}
              menuPlacement="top"
              readOnly={readOnly}
              classNamePrefix={`select-price-type-${index}`}
            />
          </div>
        </div>

        <div className="mb-3">
          <div className={styles['subtitle']}>- Leg B</div>
          <div className={sectionStyles.row}>
            <NumericInput
              id="SwapScaleFactor"
              control={control}
              label="sales_management.swap_scale_factor"
              data-cy={`swap-scale-factor-${index}`}
              readOnly={readOnly}
              rules={{
                min: { value: -1, message: t('errors.out_of_bounds', { min: -1, max: 1 }) },
                max: { value: 1, message: t('errors.out_of_bounds', { min: -1, max: 1 }) },
                required: true,
              }}
              min={-1}
              max={1}
              step={0.01}
              name={`contract_sub_periods_attributes.${index}.extra_params.swap_scale_factor`}
            />
            <ListInput
              id="SwapPriceTypePicker"
              name={`contract_sub_periods_attributes.${index}.extra_params.swap_price_type`}
              control={control}
              rules={{ required: true }}
              classNamePrefix={`select-swap-price-type-${index}`}
              label="sales_management.swap_price_type"
              readOnly={readOnly}
              menuPlacement="top"
              options={[PRICE_TYPE_OPTIONS_MAP.spot, PRICE_TYPE_OPTIONS_MAP.m0]}
            />
            {sourceOptions.length > 1 && (
              <ListInput
                id="SourcePicker"
                name={`contract_sub_periods_attributes.${index}.price_model_attributes.source`}
                control={control}
                rules={{ required: true }}
                classNamePrefix={`select-source-${index}`}
                label="sales_management.price_model.source"
                readOnly={readOnly}
                menuPlacement="top"
                options={sourceOptions}
              />
            )}
          </div>
        </div>

        <div className={classNames(sectionStyles.row, styles['sub-period-container'], 'mt-4')}>
          <div className="wider">
            <ListInput
              id="NegPriceRulePicker"
              control={control}
              name={`contract_sub_periods_attributes.${index}.neg_price_rule`}
              label="sales_management.neg_price_rule"
              options={negativePriceRulesOptions}
              readOnly={readOnly}
              menuPlacement="top"
              classNamePrefix="select-neg-price-rule"
            />
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
          {deliveryProfile === 'pay_as_percentage_based_profile' && country === 'FR' && (
            <VolumeRatio index={index} control={control} />
          )}
        </div>
      </div>
    </>
  );
}
