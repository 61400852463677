import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import useTranslation from '@hooks/useTranslation';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { ClientIdModal } from './ClientIdModal';

export const ClientIdDefinition = ({ meter }: { meter: Meter }) => {
  const { client_id } = useMeterInfo();
  const { t } = useTranslation();

  return (
    <Definition
      label="common.client_id"
      value={client_id || t('common.none')}
      modal={{
        node: <ClientIdModal meter={meter} />,
        title: 'monitoring.installation.external_ref_modify',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
