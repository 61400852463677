import dayjs from 'dayjs';
import { RevenueContextType } from '../revenue.types';

export function getDefaultDateValues(hasForecast?: boolean): {
  DEFAULT_START_DATE: RevenueContextType['startDate'];
  DEFAULT_END_DATE: RevenueContextType['endDate'];
} {
  const DEFAULT_START_DATE: RevenueContextType['startDate'] = dayjs().subtract(1, 'year').startOf('month').toDate();

  const DEFAULT_END_DATE: RevenueContextType['endDate'] = dayjs()
    .add(hasForecast ? 1 : 0, 'year')
    .startOf('month')
    .toDate();

  return { DEFAULT_START_DATE, DEFAULT_END_DATE };
}
