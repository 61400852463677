import { tString } from '@hooks/useTranslation';
import { Locale } from '@utils/types/common-types';

export const parseInvoiceFormat = (format: string, locale: Locale): string | null => {
  const keys = [
    '%{credit_type}',
    '%{credit_type_fr}',
    '%{end_date}',
    '%{installation_code}',
    '%{issued_date}',
    '%<invoice_nb>',
  ];
  const regex = new RegExp(keys.join('|'), 'g');
  const t = tString(locale);

  const formatted = format.replace(regex, (match) => {
    switch (match) {
      case '%{credit_type}':
        return t('owner.format.credit_type');
      case '%{credit_type_fr}':
        return t('owner.format.credit_type_fr');
      case '%{end_date}':
        return t('owner.format.end_date');
      case '%{installation_code}':
        return t('owner.format.installation_code');
      case '%{issued_date}':
        return t('owner.format.issued_date');
      case '%<invoice_nb>':
        return t('owner.format.invoice_nb');
      default:
        return match;
    }
  });

  return formatted.replace(/(\.\d+d)/g, '');
};
