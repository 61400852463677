import { TranslateFn } from '@hooks/useTranslation';
import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import dayjs from 'dayjs';

const getTanPhiSentence = (reactiveConstraint: Partial<ReactiveConstraint>, t: TranslateFn): string => {
  return reactiveConstraint.injection
    ? t('monitoring.installation.reactive_power_instructions.tan_phi.inject', {
        from: reactiveConstraint.tanphi_min,
        to: reactiveConstraint.tanphi_max,
      })
    : t('monitoring.installation.reactive_power_instructions.tan_phi.withdraw', {
        from: reactiveConstraint.tanphi_min,
        to: reactiveConstraint.tanphi_max,
      });
};

export const getReactiveInstructions = (
  reactiveConstraints?: Partial<ReactiveConstraint>[],
  maxValue?: number | null,
  t?: TranslateFn,
): { name: string; sentence?: string } | void => {
  const currentYear = new Date().getFullYear();
  const reactiveConstraint =
    reactiveConstraints?.length === 1
      ? reactiveConstraints[0]
      : reactiveConstraints?.find((c) => {
          let start = `${currentYear}-${c.start_month_day}`;
          let end = `${currentYear}-${c.end_month_day}`;

          // The BE only sends the end/start month days, so we need to make sure we have the right year
          if (dayjs(start).isAfter(end)) {
            if (new Date(start).getMonth() < new Date().getMonth()) {
              end = `${currentYear + 1}-${c.end_month_day}`; // e.g period is 31/10 -> 01/04, day is 01/12
            } else {
              start = `${currentYear - 1}-${c.start_month_day}`; // e.g period is 31/10 -> 01/04, day is 01/01
            }
          }

          return (
            (dayjs().isSame(end, 'day') || dayjs().isBefore(end)) &&
            (dayjs().isSame(start, 'day') || dayjs().isAfter(start))
          );
        });

  if (!reactiveConstraint) return;

  const reactivePowerInstructionSentence =
    reactiveConstraint.reactive_type === 'tan_phi'
      ? t && getTanPhiSentence(reactiveConstraint, t)
      : `Qmax (${reactiveConstraint.q_max} x ${maxValue || '{max value}'}) / ` +
        `Qmin (${reactiveConstraint.q_min} x ${maxValue || '{max value}'}) kVA`;

  let reactivePowerInstructionName = '';
  if (reactiveConstraint.reactive_type === 'tan_phi') reactivePowerInstructionName = t ? t('common.tg_phi') : 'Tan Φ';
  if (reactiveConstraint.reactive_type === 'qfu') reactivePowerInstructionName = 'Q=f(U)';

  return {
    name: reactivePowerInstructionName,
    sentence: reactivePowerInstructionSentence,
  };
};
