import { tString } from '@hooks/useTranslation';
import { Locale } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';

const getInvoiceDeferredStatus = (invoice: MeterInvoice, locale: Locale) => {
  const t = tString(locale);
  const deferred_status = invoice.deferred_status;
  if (deferred_status == 'deferred') return t('sales_management.deferred');

  return null;
};

export default getInvoiceDeferredStatus;
