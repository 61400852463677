import React from 'react';
import { RadioButtons } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const NegHoursLimitRadio = () => {
  const form = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('neg_hours_limit');

  const options: Option[] = [
    { label: 'pricing.project.neg_hours_limit_0', value: '0' },
    { label: '1h', value: '1' },
    { label: '4h', value: '4' },
    { label: '6h', value: '6' },
  ];

  return (
    <Controller
      name="neg_hours_limit"
      control={form.control}
      rules={rules}
      render={({ field }) => (
        <div>
          <RadioButtons
            size="lg"
            options={options}
            label="pricing.project.neg_hours_limit"
            selected={field.value}
            onChange={(value) => field.onChange(value)}
          />
        </div>
      )}
    />
  );
};
