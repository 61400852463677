import React from 'react';
import { NoWrapHeadCell, NumberCell } from '@GDM/Table';
import { ColumnDef } from '@tanstack/react-table';
import { DataList, IndexData } from '@utils/DashboardMeter/useIndexInvoicingData';
import { getDataKeys } from '../../helpers';
import { TypeCell } from './TypeCell';

export const indexInvoicingColumns = (title: string, invoicingData?: DataList): ColumnDef<IndexData>[] => {
  if (!invoicingData) return [];

  const columnsKeys = getDataKeys(invoicingData);

  const columns: ColumnDef<IndexData>[] = [
    {
      header: title,
      accessorKey: 'type',
      cell: TypeCell,
    },

    ...columnsKeys.map(
      (key) =>
        ({
          header: () => <NoWrapHeadCell label={key} tooltip="kWh" align="right" />,
          accessorKey: key,
          cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
        } satisfies ColumnDef<IndexData>),
    ),
  ];

  return columns;
};
