import React from 'react';
import { Input } from '@GDM/forms';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { UserFormType } from '../../types';

export const CompanyEmailInput = ({ disabled }: { disabled: boolean }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UserFormType>();
  const className = classnames({ 'is-invalid': !!errors?.email?.type });

  return (
    <Controller
      name="email"
      control={control}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <>
          <Input
            {...field}
            disabled={disabled}
            className={className}
            data-cy="add-user-email"
            label="common.company_email"
            hasError={!!fieldState.error}
            full
          />
        </>
      )}
    />
  );
};
