import { getSymbol } from '@utils/currency/getSymbol';
import { formatNumber } from '@utils/formatters/formatNumber';
import jsonToCsv, { HeadCsv } from '@utils/json-to-csv';
import { Contract } from '@utils/types/contract';

export const remitCsvExport = (contracts: Contract[]): string => {
  // since french number formats include comma we need to use another delimiter
  const options = { delimiter: ';' };

  const remitFormatNumber = (value: number, options?: Intl.NumberFormatOptions): string => {
    return formatNumber(value, undefined, options)?.replace(/\s+/g, ' ') ?? '--';
  };

  const addAcerIdFirstHeader = (head: HeadCsv<Contract>[], delimiter: string): string => {
    const columnToAcerId = {
      'Market Participant name': 1,
      'Counterparty name': 3,
      'Buy/Sell': 10,
      'Contract ID': 11,
      'Contract date': 12,
      'Price or price formula': 15,
      'Volume optionality capacity': 19,
      'Volume optionality capacity unit': 20,
      'Delivery start date': 42,
      'Delivery end date': 43,
      'Load type': 44,
      'Action type': 45,
      'Delivery point or zone': 41,
      'Contrat type': 13,
      'Energy commodity': 14,
      'Volume optionality': 21,
      'Volume optionality frequency': 22,
      'Type of index price': 24,
      'Settlement method': 31,
      'Notional Currency': 17,
    };

    let line = '';

    let count = 0;
    head.forEach((item, index) => {
      if (count === 0) {
        line += 'id champ ACER';
      } else {
        const acer_id = columnToAcerId[item.label as keyof typeof columnToAcerId];
        if (acer_id) {
          line += `${acer_id}`;
        }
      }

      if (index === head.length - 1) {
        line += '\n';
      } else {
        line += delimiter;
      }

      count++;
    });

    return line;
  };

  const headers: HeadCsv<Contract>[] = [
    { label: 'Nom du champ', key: 'installation.name' },
    { label: 'Client ID', key: 'installation.external_ref' },
    { label: 'Market Participant', key: 'seller.acer_code' },
    { label: 'Counterparty name', key: 'buyer.acer_code' },
    { label: 'Buy/Sell', value: 'Sell' },
    { label: 'Contract ID', key: 'contract_nb' },
    { label: 'Contract date', key: 'start_date' },
    { label: 'Contract Status', key: 'status' },
    {
      label: 'Price or price formula',
      getValue: (contract) => {
        const ref_price =
          contract.contract_sub_periods.length === 1
            ? formatNumber(contract.contract_sub_periods[0].display_price)
            : null;
        const price_unit =
          contract.contract_sub_periods.length === 1 ? contract.contract_sub_periods[0].price_unit : null;

        if (contract.type === 'ContractOa' || contract.type === 'ContractCrEdf') {
          if (price_unit?.endsWith('/MWh')) {
            const ref_price = contract.contract_sub_periods[0].display_price;

            return ref_price
              ? `${remitFormatNumber(ref_price / 10)} c${getSymbol(
                  contract.currency,
                )}/kWh hors TVA (indexation annuelle)`
              : '';
          }

          return '';
        } else if (contract.type === 'ContractCr') {
          return 'Prix MWh = Mo Cre ou estimé - FEE (fixe ou en %) ou fee(%)*E*PSh selon phase';
        } else if (
          contract.type === 'ContractSoa' ||
          contract.type === 'ContractCppa' ||
          contract.type === 'ContractCapaProxy'
        ) {
          const price_types = new Set(contract.contract_sub_periods.map((period) => period.price_type));

          if (price_types.has('fixed') && price_types.has('spot')) return 'Prix fixe ou prix spot';
          else if (price_types.has('fixed')) return 'Prix fixe';
          else if (price_types.has('spot')) return 'Prix spot';
          else return '';
        }

        return ref_price ? `${ref_price} ${price_unit}` : '';
      },
    },
    {
      label: 'Volume optionality capacity',
      getValue: (contract) => {
        const p_max = contract.p_max;

        if (p_max !== null) {
          return remitFormatNumber(p_max / 1000);
        }

        return '';
      },
    },
    { label: 'Volume optionality capacity unit', value: 'MW' },
    { label: 'Delivery start date', key: 'start_date' },
    { label: 'Delivery end date', key: 'client_end_date' },
    { label: 'Load type', value: 'SH' },
    { label: 'Action type', value: '' },
    { label: 'Delivery point or zone', value: '10YFR-RTE------C' },
    { label: 'Contract Type', value: 'FW' },
    { label: 'Energy commodity', value: 'Power' },
    { label: 'Volume optionality', value: 'M' },
    { label: 'Volume optionality frequency', value: 'H' },
    { label: 'Type of index price', value: '' },
    { label: 'Settlement method', value: 'P' },
    { label: 'Notional Currency', value: 'EUR' },
  ];

  const csvData = jsonToCsv(contracts, headers, options);

  const acerIdHeader = addAcerIdFirstHeader(headers, options.delimiter);

  return acerIdHeader + csvData;
};
