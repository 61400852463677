import { Tab } from '@GDM/Tabs';
import { TabIds } from './market-players.types';

export const TABS: Tab<TabIds>[] = [
  { id: 'production', name: 'common.production' },
  { id: 'revenue', name: 'common.revenues' },
  { id: 'contracts', name: 'common.contracts', permissions: ['display:view_monitoring'] },
  { id: 'invoices', name: 'common.invoices', permissions: ['display:view_monitoring'] },
  { id: 'm0_deviation', name: 'common.market_perf', permissions: ['display:view_monitoring_dashboard_meter_m0_tab'] },
];
