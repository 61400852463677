import React, { useMemo, useState } from 'react';
import { Simulation } from '@utils/types/simulation';
import { User } from '@utils/types/user';
import { SimulationsProvider } from './context/SimulationsProvider';
import SimulationsList from './SimulationsList';
import SelectedRowsContext from './state/selectedRows';

export const Simulations = ({ user }: { user: User }) => {
  const [selectedRows, setSelectedRows] = useState<Simulation[]>([]);

  /**
   * function to select one row of the given table (simulations, full_merchant_simulations or book_simulations)
   */
  const selectRow = (row: Simulation): void => setSelectedRows((rows) => [...rows, row]);

  /**
   * function to unselect one row of the given table (simulations, full_merchant_simulations or book_simulations)
   */
  const unselectRow = (row: Simulation): void => {
    setSelectedRows((rows) => {
      const index = rows.findIndex((s) => s.id === row.id);

      if (index > -1) {
        const rowsClone = rows.filter((p, currentIndex) => currentIndex !== index);

        return rowsClone;
      }

      return rows;
    });
  };

  /**
   * function to select all rows of the given table (simulations, full_merchant_simulations or book_simulations)
   */
  const selectAllRows = (rows: Simulation[]): void => setSelectedRows(rows);

  /**
   * function to unselect all rows of the given table (simulations, full_merchant_simulations or book_simulations)
   */
  const unselectAllRows = (): void => setSelectedRows([]);

  const selectedRowContextValue = useMemo(
    () => ({ selectedRows, selectRow, unselectRow, selectAllRows, unselectAllRows }),
    [selectedRows],
  );

  return (
    <SelectedRowsContext.Provider value={selectedRowContextValue}>
      <SimulationsProvider>
        <SimulationsList user={user} />
      </SimulationsProvider>
    </SelectedRowsContext.Provider>
  );
};
