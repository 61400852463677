import { tString } from '@hooks/useTranslation';
import { Locale } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';

const getInvoiceType = (
  invoice: MeterInvoice,
  locale: Locale,
): { invoiceType: string | null; tooltip: string | null } => {
  const t = tString(locale);
  const internal_type = invoice.internal_type;
  const preliminary = invoice.preliminary;

  if (invoice.cancelled)
    return {
      invoiceType: t('common.cancelled'),
      tooltip: null,
    };
  if (internal_type == 'regul')
    return {
      invoiceType: t('sales_management.type_regul'),
      tooltip: null,
    };
  if (preliminary)
    return {
      invoiceType: t('common.preliminary'),
      tooltip: invoice.forecast_status ? t(`invoice.forecast_status.${invoice.forecast_status}`) : null,
    };
  if (internal_type == 'main')
    return {
      invoiceType: t('sales_management.type_main'),
      tooltip: null,
    };

  return {
    invoiceType: t('common.unknown'),
    tooltip: null,
  };
};

export default getInvoiceType;
