/**
 * Takes a number and returns a color hex code by shifting the number by 8 bits
 * and taking the last 8 three times to produce a #000000-like code.
 * @returns a color hex code string
 */
export function hashToHexCode(input: number): string {
  let hex = '#';

  for (let i = 0; i < 3; i++) {
    const value = (input >> (i * 8)) & 0xff;
    hex += value.toString(16);
  }

  return hex;
}
