import { createContext, useContext } from 'react';
import { RequestError } from '@hooks/useRequest';
import MarketPlayer from '@utils/types/market_player';

export type BuyersContext = {
  buyers: MarketPlayer[];
  isLoading?: boolean;
  error?: RequestError | null;
};

export const buyersContext = createContext<BuyersContext>({ buyers: [], isLoading: false });

export const useBuyersContext = (): BuyersContext => useContext(buyersContext);
