import { useQuery } from '@tanstack/react-query';
import { get_production_data_v2_dashboard_meter_index_path } from '@utils/routes';
import { DataPeriod } from '@utils/types/dataPeriod';
import { TimeSeries } from '@utils/types/timeSeries';
import dayjs from 'dayjs';

type ProductionDataRequest = {
  data_dispo: [];
};

export const useLegacyProductionData = (
  refcompteur: string | null,
  start: Date | null,
  end: Date | null,
  isBook = false,
  dataPeriod?: DataPeriod,
) => {
  return useQuery({
    queryKey: ['legacy_production_data', isBook, refcompteur, start, end, dataPeriod],
    queryFn: async () => {
      const parsedStartDate = dayjs(start).format('YYYY-MM-DD HH:mm:00');
      const parsedEndDate = dayjs(end).format('YYYY-MM-DD HH:mm:00');

      const params = {
        refcompteur,
        is_book: isBook,
        delta: 0,
        firstdate: parsedStartDate,
        lastdate: parsedEndDate,
        dataperiod: dataPeriod === 'minutes' ? '10mn' : dataPeriod,
      };

      const url = get_production_data_v2_dashboard_meter_index_path(params);
      const response = await fetch(url);
      const data: ProductionDataRequest = await response.json();

      return {
        availability: data.data_dispo && scaleTimeTransformer(data.data_dispo, start, end),
      };
    },
    enabled: Boolean(start && end && refcompteur),
  });
};

const scaleTimeTransformer = (data: TimeSeries, startDate: Date | null, endDate: Date | null): [number, number][] => {
  return data
    .map((d) => [d[0] * 1000, Number(d[1]?.toFixed(2))] as [number, number]) // scale time and set float number of data value
    .filter((d) => startDate && endDate && d[0] >= startDate.getTime() && d[0] <= endDate.getTime());
};
