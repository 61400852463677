import { getSymbol } from '@utils/currency/getSymbol';
import { Currency } from '@utils/types/currency';
import { TableData } from '../../revenue.types';

export const getFormattedUnit = (unit: NonNullable<TableData['unit']>) => {
  if (unit === 'kWh') return 'kWh';

  if (unit?.endsWith('/MWh')) {
    const currency = unit.split('/')[0] as Currency;

    return `${getSymbol(currency)}/MWh`;
  }

  return getSymbol(unit as Currency);
};
