import React, { useMemo, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { AltContent } from '@GDM/AltContent';
import { Col, Row } from '@GDM/layout';
import { Map } from '@GDM/Map';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';
import { Meter } from '@utils/types/meter';
import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import { InstallationMap } from 'pages/ui/shared/maps';
import styles from '../characteristics.module.scss';
import { Panels } from '../shared';
import { Books } from './Books';
import { BusinessPlan } from './BusinessPlan/BusinessPlan';
import Inverters from './Inverters';
import MeterInfo from './MeterInfo';
import { MeterInfoContextType, meterInfoContext } from './MeterInfo/meter-info.context';

export const MeterCharacteristics = ({ meter }: { meter: Meter }) => {
  const { book } = useDashboardMeter();
  const [lat, setLat] = useState(meter.coordinates?.lat);
  const [lng, setLng] = useState(meter.coordinates?.lng);
  const [nominal_power, setNominalPower] = useState(meter.nominal_power);
  const [pr_expected, setPrExpected] = useState(meter.prexpected);
  const [reactiveConstraints, setReactiveConstraints] = useState<Partial<ReactiveConstraint>[] | undefined>(
    meter.reactive_constraints,
  );
  const [client_id, setClientId] = useState(meter.client_id);
  const [facturation_type, setInvoicingTypeRawString] = useState(meter.facturation_type);
  const [grid_name, setGridName] = useState(meter.grid_name);
  const [commissioning_date, setCommissioningDate] = useState(meter.commissioning_date);
  const [maxValue, setMaxValue] = useState<number | undefined>(meter.maxvalue);
  const [erpCode, setErpCode] = useState(meter.installation?.erp_code);
  const [siret, setSiret] = useState(meter.installation?.siret);

  const contextValue = useMemo<MeterInfoContextType>(
    () => ({
      nominal_power,
      setNominalPower,
      lat,
      setLat,
      lng,
      setLng,
      pr_expected,
      setPrExpected,
      reactiveConstraints,
      setReactiveConstraints,
      client_id,
      setClientId,
      facturation_type,
      setInvoicingTypeRawString,
      grid_name,
      setGridName,
      commissioning_date,
      setCommissioningDate,
      maxValue,
      setMaxValue,
      erpCode,
      setErpCode,
      siret,
      setSiret,
    }),
    [
      nominal_power,
      lat,
      lng,
      pr_expected,
      reactiveConstraints,
      client_id,
      facturation_type,
      grid_name,
      commissioning_date,
      maxValue,
      erpCode,
      siret,
    ],
  );

  if (!meter) return <AltContent />;

  return (
    <meterInfoContext.Provider value={contextValue}>
      <Row>
        {!!lat && !!lng && (
          <Col xl={4} className={styles['map-container']}>
            <Map center={[Number(lat), Number(lng)]}>
              <InstallationMap name={meter.name} lat={Number(lat)} lng={Number(lng)} />
            </Map>
          </Col>
        )}
        <Col>
          <MeterInfo meter={meter} isDummy={!!meter.has_only_dummy_contracts || book?.has_only_dummy_contracts} />
        </Col>
      </Row>

      <div className={styles.configs}>
        <Restricted permissions={['display:view_monitoring_dashboard_meter_business_plan']}>
          <div className={styles.column}>
            <div className={styles.item}>
              <BusinessPlan installationName={meter.name} />
            </div>
          </div>
        </Restricted>

        <div className={styles.column}>
          {meter.installation?.energy === 'solar' && (
            <div className={styles.item}>
              <Panels meterName={meter.name} />
            </div>
          )}

          <Books meter={meter} />

          {!!meter.has_inverters && (
            <div className={styles.item}>
              <Inverters meterName={meter.name} />
            </div>
          )}
        </div>
      </div>
    </meterInfoContext.Provider>
  );
};
