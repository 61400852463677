import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import type { ContractForm } from '@utils/types/contract';
import type { Control } from 'react-hook-form';

export const VolumeRatio = ({ index, control }: { index: number; control: Control<ContractForm> }) => {
  const { t } = useTranslation();

  return (
    <NumericInput
      control={control}
      name={`contract_sub_periods_attributes.${index}.volume_ratio`}
      rules={{
        min: { value: 0, message: t('errors.out_of_bounds', { min: -1, max: 1 }) },
        max: { value: 100, message: t('errors.out_of_bounds', { min: -1, max: 1 }) },
      }}
      label="sales_management.volume_ratio"
      suffix="%"
      max={100}
      min={0}
    />
  );
};
