import React from 'react';
import { Spinner } from '@GDM/Spinner';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import InstallationBooks from '@ui/InstallationBooks';
import { v2_meter_books_path } from '@utils/routes';
import { Meter } from '@utils/types/meter';
import styles from '../../characteristics.module.scss';

export const Books = ({ meter }: { meter: Meter }) => {
  const { t } = useTranslation();

  const { data: books, loading } = useRequest<{ uuid: string; name: string }[]>(v2_meter_books_path(meter.name), 'GET');

  if (!books?.length) return null;

  const bookNames = books.map((book) => book.name);

  return (
    <div className={styles.item}>
      <div className="tt:uppercase fw:700 mb-4 d-flex">
        <span className="mr-2">{t('common.books').toLocaleUpperCase()}</span>
        {loading && <Spinner size="sm" />}
      </div>

      {!!books?.length && !loading && <InstallationBooks books={bookNames} />}
    </div>
  );
};
