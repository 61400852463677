import React from 'react';
import classNames from 'classnames';
import styles from './table.module.scss';

export const Table = ({
  children,
  className,
  hasActions,
  'data-cy': dataCy,
  stickyFirstColumn,
}: React.PropsWithChildren<{
  className?: string;
  hasActions?: boolean;
  stickyFirstColumn?: boolean;
  'data-cy'?: string;
}>) => {
  return (
    <div
      className={classNames(
        styles['table-container'],
        className,
        hasActions && 'has-actions',
        stickyFirstColumn && 'is-sticky-first-column',
      )}
    >
      <table className={styles.table} data-cy={dataCy}>
        {children}
      </table>
    </div>
  );
};
