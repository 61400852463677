import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { useInstallationInfo } from '../useInstallationInfo';
import { ErpCodeModal } from './ErpCodeModal';

export const ErpCodeDefinition = ({ name }: { name: string }) => {
  const { erpCode } = useInstallationInfo();

  return (
    <Definition
      label="sales_management.erp_code"
      value={erpCode || 'common.none'}
      modal={{
        node: <ErpCodeModal name={name} />,
        title: 'sales_management.erp_code',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
