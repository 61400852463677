import { deleteUserNotificationsSettings } from '@api/userNotificationsSetings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserNotificationsSettings } from '@utils/types/userNotificationsSettings';

export const useUserNotificationsSettingsDeleteMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['deleteNotification'],
    mutationFn: deleteUserNotificationsSettings,
    onSuccess: (_, id) => {
      queryClient.setQueryData(['notifications'], (previousNotifications: UserNotificationsSettings[]) => {
        return previousNotifications.filter((notification) => notification.id !== id);
      });
    },
  });

  return mutation;
};
