import React from 'react';
import { Checkbox } from '@GDM/forms';
import classnames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { UserFormType } from '../../types';

export const InternalCheckbox = ({ disabled }: { disabled: boolean }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<UserFormType>();
  const className = classnames({ 'is-invalid': !!errors?.internal?.type });

  return (
    <Controller
      name="internal"
      control={control}
      render={({ field }) => (
        <>
          <Checkbox
            {...field}
            value={field.value ? 'on' : 'off'}
            defaultChecked={field.value}
            onChange={(e) => field.onChange(Boolean(e.target.value))}
            disabled={disabled}
            className={className}
            label="admin.sub_client.internal_label"
            labelMultiLine
          />
        </>
      )}
    />
  );
};
