import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import { new_v2_installation_path } from '@utils/routes';
import { Permission } from '@utils/types/permission';
import { useInstallationsPageContext } from './context/installations-page.context';
import { InstallationCsvButton } from './InstallationButton/InstallationCsvButton';

type InstallationsActionsProps = {
  activeTab: string;
};

export const InstallationsActions = ({ activeTab }: InstallationsActionsProps) => {
  const { t } = useTranslation();
  const { toggleMandateModal, toggleDataLoggerModal } = useInstallationsPageContext();

  const buttons: { [tab: string]: { label: string; action?: () => void; href?: string; permissions: Permission[] } } = {
    installations: {
      label: t('admin.installations.add_meter'),
      href: new_v2_installation_path(),
      permissions: ['create:installation'],
    },
    mandates: {
      label: t('admin.installations.add_mandate'),
      action: toggleMandateModal,
      permissions: ['create:mandat'],
    },
    data_loggers: {
      label: t('admin.installations.add_data_logger'),
      action: () => toggleDataLoggerModal,
      permissions: ['display:edit_monitoring'],
    },
  };

  if (!buttons[activeTab]) return null;

  return (
    <div className="d-flex px-3">
      <Restricted permissions={buttons[activeTab].permissions}>
        <Button
          size="xxs"
          variant="primary-2"
          className="ml-auto"
          onClick={buttons[activeTab].action}
          href={buttons[activeTab].href}
          data-cy="create-meter-button"
          icon="Plus"
        >
          {buttons[activeTab].label}
        </Button>
      </Restricted>
      <div className="ml-2">{activeTab === 'installations' && <InstallationCsvButton />}</div>
    </div>
  );
};
