import { useCallback, useState } from 'react';
import axiosInstance from '@utils/axiosInstance';
import { send_to_remit_v2_contracts_path } from '@utils/routes';
import { Contract } from '@utils/types/contract';
import { AxiosError, AxiosResponse } from 'axios';

type SendToRemitBody = {
  contract_ids?: string[];
  contract_id?: string;
  send_to_remit: boolean;
};

const fetch = (body: SendToRemitBody) => {
  return axiosInstance(false)
    .post<SendToRemitBody, AxiosResponse<Contract[]>>(send_to_remit_v2_contracts_path(), body, {
      headers: { accept: 'application/json' },
    })
    .then((d) => d.data);
};

const useSendToRemit = () => {
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [contracts, setContracts] = useState<Contract[] | null>(null);

  const sendToRemit = useCallback((body: SendToRemitBody) => {
    setLoading(true);
    setError(null);
    setFinished(false);

    fetch(body)
      .then((d: Contract[]) => {
        setLoading(false);
        setFinished(true);
        setContracts(d);
      })
      .catch((e: AxiosError<{ message: string | null }>) => {
        setLoading(false);
        setError(e.response?.data?.message || e.message);
      });
  }, []);

  return { sendToRemit, loading, error, finished, contracts };
};

export default useSendToRemit;
