import { PreDefinedPeriods } from '../date-picker.types';

export const DEFAULT_PERIODS: PreDefinedPeriods[] = [
  PreDefinedPeriods.Today,
  PreDefinedPeriods.Yesterday,
  PreDefinedPeriods.CurrentWeek,
  PreDefinedPeriods.LastWeek,
  PreDefinedPeriods.CurrentMonth,
  PreDefinedPeriods.LastMonth,
];
