import { ThemeConfig } from 'react-select';

export const SelectTheme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#01d483',
    primary75: 'rgba(1,212,131,.5)',
    primary50: 'rgba(1,212,131,.25)',
    primary25: 'rgba(1,212,131,.15)',
  },
});
