import { TypeToInputValue } from '@utils/types/common-types';
import { ContractType } from '@utils/types/contract';

type BaseFilters = { contract_type: TypeToInputValue<ContractType | ContractType[]> };

export default function isContractType(contract_type: ContractType, filters: BaseFilters): boolean {
  if (typeof filters.contract_type === 'string') {
    return contract_type === filters.contract_type || !filters.contract_type;
  }

  if (filters.contract_type.length === 0) return true;

  return filters.contract_type.some(
    (filterContractType) => contract_type === filterContractType || !filters.contract_type,
  );
}
