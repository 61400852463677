import React from 'react';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { MandateStatus } from '@utils/types/mandate';
import { Controller, useFormContext } from 'react-hook-form';
import { MandateFiltersType } from './MandatesFilter';

export const MandatesStatusFilter = ({ onChange }: { onChange: (params: MandateFiltersType) => void }) => {
  const formContext = useFormContext<MandateFiltersType>();
  const MandatesStatusSelectOptions: Option<MandateStatus | string>[] = [
    { value: 'manual_checking', label: 'common.filters.mandate_status.manual_checking' },
    { value: 'no_signature', label: 'common.filters.mandate_status.not_signed' },
    { value: 'signed', label: 'common.filters.mandate_status.signed' },
    { value: 'expired', label: 'common.filters.mandate_status.expired' },
    { value: 'to_renew', label: 'common.filters.mandate_status.to_renew' },
  ];

  return (
    <Controller
      name="status"
      control={formContext.control}
      render={({ field }) => (
        <Select
          classNamePrefix=""
          selectedOption={field.value}
          options={MandatesStatusSelectOptions}
          placeholder="common.status_label"
          isClearable
          onChange={(e) => {
            onChange({ ...formContext.getValues(), status: e?.value || '' });
            field.onChange(e?.value || '');
          }}
        />
      )}
    />
  );
};
