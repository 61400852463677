import React from 'react';
import { useUser } from '@context/User.context';
import { Select, SingleSelectProps } from '@GDM/forms';
import { Icon } from '@GDM/Icon';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import classNames from 'classnames';
import styles from './country-select.module.scss';

type CountryOption = CountryCode | string | null;

export const CountrySelect = ({
  onChange,
  selectedOption,
  countryFullName = true,
  neverDisable = false,
  options,
  ...restProps
}: Omit<SingleSelectProps<CountryOption>, 'options'> & {
  options?: SingleSelectProps<CountryOption>['options'];
  onChange: (option: Option<CountryOption> | null) => void;
  selectedOption: CountryOption;
  countryFullName?: boolean;
  neverDisable?: boolean;
}) => {
  const user = useUser();

  const countryOptions: Option<CountryOption>[] = options
    ? options
    : user.geolocation
        .filter(Boolean)
        .map((country) => ({ label: countryFullName ? `countries.${country}` : country, value: country }));

  return (
    <Select
      {...restProps}
      options={countryOptions}
      selectedOption={selectedOption}
      isDisabled={!neverDisable && (countryOptions.length === 1 || restProps.isDisabled)}
      isCountry
      formatOptionLabel={(data) => (
        <div className={styles.container}>
          <Icon name={isCountryCode(data.value) ? data.value : 'XX'} size={12} className="mr-1" />
          <span className={classNames('ml-2', styles.label)}>{data.label}</span>
        </div>
      )}
      onChange={onChange}
    />
  );
};
