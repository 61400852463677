import { createUserNotificationsSettings } from '@api/userNotificationsSetings';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { UserNotificationsSettings } from '@utils/types/userNotificationsSettings';

export const useUserNotificationsSettingsCreateMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['createNotification'],
    mutationFn: createUserNotificationsSettings,
    onSuccess: (data) => {
      queryClient.setQueryData(['notifications'], (previousNotifications: UserNotificationsSettings[]) => {
        return [...previousNotifications, data];
      });
    },
  });

  return mutation;
};
