import React from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import { LOCALIZED_SHORT_MONTHS } from '@utils/constants/localizedShortMonths';
import { formatSystemDate } from '@utils/formatters';
import jsonToCsv, { type HeadCsv } from '@utils/json-to-csv';
import { CountryCode } from '@utils/types/countries';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import snakeCase from 'lodash/snakeCase';
import { formatGroupedDataForExport } from '../helpers/formatGroupedDataForExport';
import { getColumnsFromRevenue } from '../helpers/getColumnsFromRevenue';
import { getPeriodsFromRevenue } from '../helpers/getPeriodsFromRevenue';
import { mapToDataPoint } from '../helpers/mapToDataPoint';
import { isGroupedData } from '../helpers/type-helpers';
import { useRevenueContext } from '../hooks/useRevenueContext';
import { groupRevenuesByYear } from '../RevenueChart/RevenueChartByMonth';

export const ExportButton = () => {
  const {
    endDate,
    isLoading,
    overview,
    owners,
    players,
    resolution,
    revenue,
    selectedPlayers,
    sourceType,
    startDate,
    groupByMonth,
  } = useRevenueContext();
  const { t } = useTranslation();
  const { geolocation, locale } = useUser();
  const { selectedCurrency } = useRevenueContext();

  const defaultSelectedPlayer = sourceType === 'installation' && owners?.length === 1 ? owners[0] : null;
  let selectedPlayer = defaultSelectedPlayer;

  if (selectedPlayers && selectedPlayers?.length && players) {
    selectedPlayer = players.find((p) => p.id === selectedPlayers[0]) || defaultSelectedPlayer;
  }

  const selectedPlayerName = selectedPlayer?.short_name ? snakeCase(selectedPlayer.short_name) : '';

  const start = formatSystemDate(startDate);
  const end = formatSystemDate(endDate);

  const downloadCSV = () => {
    if (groupByMonth) {
      return downloadRevenueGroupedByMonthCSV();
    }

    return downloadRevenueCSV();
  };

  const downloadRevenueCSV = () => {
    const fileName = `invoice_revenue_${resolution}_${selectedPlayerName}_${start}_${end}.csv`;
    const periods = getPeriodsFromRevenue(revenue);
    const columns = getColumnsFromRevenue(
      revenue,
      overview,
      t,
      geolocation[0] as CountryCode,
      selectedCurrency || 'EUR',
    );

    const flatData = formatGroupedDataForExport(periods, columns, overview, isGroupedData(revenue) ? revenue : null);
    const csvData = jsonToCsv(flatData, columns, { locale });

    fileDownload(csvData, fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  const downloadRevenueGroupedByMonthCSV = () => {
    const fileName = `invoice_revenue_grouped_by_month_${resolution}_${selectedPlayerName}_${start}_${end}.csv`;
    const revenueData = !isGroupedData(revenue) ? revenue?.map(mapToDataPoint) || [] : [];
    const groupedByYear = groupRevenuesByYear(revenueData);

    const columns: HeadCsv<Record<string, string | number>>[] = [
      { label: '', key: 'month' },
      ...Object.keys(groupedByYear).map((year) => ({ label: `${year} (kWh)`, key: year })),
    ];

    const data: Record<string, string | number>[] = LOCALIZED_SHORT_MONTHS.map((month, monthIndex) => {
      return {
        month: t(month),
        ...Object.keys(groupedByYear).reduce<Record<string, string | number>>((acc, year) => {
          const monthData = groupedByYear[year].find((d) => dayjs(d.x).month() === monthIndex);

          if (monthData) {
            acc[year] = monthData.y || '';
          }

          return acc;
        }, {}),
      };
    });

    const csvData2 = jsonToCsv(data, columns, { locale });

    fileDownload(csvData2, fileName, 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <Button
      icon="Download"
      text="common.download_csv"
      variant="primary-2"
      size="xxs"
      onClick={() => downloadCSV()}
      disabled={isLoading}
    />
  );
};
