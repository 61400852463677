import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { MeterInvoice } from '@utils/types/meter-invoice';
import RecursiveKeyOf from '@utils/types/RecursiveKeyOf';
import { MonitoringInvoicesFilters } from '../invoices.types';

export const getFilteredInvoices = (filters: MonitoringInvoicesFilters, invoices: MeterInvoice[]): MeterInvoice[] => {
  let filteredInvoices = [...invoices];

  if (filters.installationName !== '') {
    const keys: Array<RecursiveKeyOf<MeterInvoice>> = ['installation_name'];

    filteredInvoices = matchSorterInstanceStrict(filteredInvoices, filters.installationName, { keys });
  }

  filteredInvoices = filteredInvoices.filter((invoice) => {
    return filters.invoicingType === 'All' ? true : invoice.invoicing_type === filters.invoicingType;
  });

  return filteredInvoices;
};
