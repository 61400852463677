import React from 'react';
import { Input } from '@GDM/forms';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { ReactiveForm } from '../types';
import { useReactiveForm } from '../useReactiveForm';

export const Voltage = () => {
  const { control, getValues, setValue } = useReactiveForm();

  const onChange = (field: ControllerRenderProps<ReactiveForm, 'voltage'>, value: string): void => {
    field.onChange(value);
    getValues('instructions')?.forEach((_, i) => {
      setValue(`instructions.${i}.voltage`, Number(value));
    });
  };

  return (
    <Controller
      name="voltage"
      rules={{ required: false }}
      control={control}
      render={({ field }) => (
        <Input
          value={field.value ?? ''}
          label="common.voltage"
          type="number"
          placeholder="20.0"
          suffix="kV"
          data-cy="voltage"
          onChange={(e) => onChange(field, e.target.value)}
          full
        />
      )}
    />
  );
};
