import React from 'react';
import { sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { FormSectionLayout, type ContractFormSectionProps } from '../components/FormSection/FormSectionLayout';
import { NumericInput } from '../components/Inputs/Numeric';

export const SubscriptionFixed = ({ title, readOnly, form: { control, watch } }: ContractFormSectionProps) => {
  const currency = watch('currency');
  const { t } = useTranslation();

  return (
    <FormSectionLayout
      title={title}
      body={
        <div className="p-3">
          <div className={sectionStyles.container}>
            <div className={sectionStyles.row}>
              <NumericInput
                control={control}
                name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.fixed_price"
                label="common.price"
                suffix={`${getSymbol(currency)}/${t('common.month')}`}
                rules={{ required: true }}
                readOnly={readOnly}
                helpBox={{
                  title: 'common.price',
                  text: 'sales_management.fixed_price_info',
                }}
              />
            </div>
          </div>
        </div>
      }
    />
  );
};
