import { contractTypeList } from '@utils/constants';
import { ContractType } from '@utils/types/contract';
import { UserGeolocation } from '@utils/types/user';

const getContractTypeLabel = (contractType: ContractType, country: UserGeolocation | null): string => {
  const countryCode = country || 'FR';

  const contractTypeDefinitions = contractTypeList[countryCode]?.find((contract) => contract.value === contractType);

  return contractTypeDefinitions?.label ?? '';
};

export default getContractTypeLabel;
