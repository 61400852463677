import { useCallback } from 'react';
import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { request_nomination_v2_meter_invoicing_index_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { AxiosResponse } from 'axios';

export type NominationInvoiceRequestBody = {
  invoices: Array<{ id: string; invoice_nb?: string }>;
};

const nominateInvoice =
  (body?: NominationInvoiceRequestBody) =>
  async ({ url }: { url: string }) => {
    const request = await axiosInstance(false).post<NominationInvoiceRequestBody, AxiosResponse<MeterInvoice[]>>(
      url,
      body,
      {
        /** this is specific for this endpoint, we cannot use our custom post for this */
        validateStatus: (status) => status < 206,
      },
    );

    return { data: request.data, status: request.status };
  };

export const useNominationRequest = (
  body?: NominationInvoiceRequestBody,
): CommonRequestHookResponse<MeterInvoice[]> => {
  const nominate = useCallback(({ url }: { url: string }) => nominateInvoice(body)({ url }), [body]);

  return useRequest(request_nomination_v2_meter_invoicing_index_path(), nominate, true);
};
