import React, { useCallback, useMemo, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import { Permission } from '@utils/types/permission';
import classNames from 'classnames';
import styles, { definition } from './definition-list.module.scss';
import { definitionContext } from './definition.context';
import { EditDefinitionModal } from './modal/EditDefinitionModal';

/** A definition item.
 * - Should be wrapped in a `<DefinitionList />`.
 * - The `modal` prop is a React component that holds what's inside the modal body.
 *
 * ```html
 *  <div>
 *    <dt>Definition label<dt>
 *    <dd>Definition value<dd>
 *  </div>
 * ```
 */
export const Definition = ({
  className,
  'data-cy': dataCy,
  label,
  modal,
  tooltip,
  value,
}: {
  className?: string;
  label: string;
  modal?: { node: React.ReactNode; permission?: Permission; title?: string; toggle?: () => void };
  tooltip?: string | null;
  value: React.ReactNode | string;
  'data-cy'?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const classList = classNames(definition, 'py-2 px-1', className);
  const toggle = useCallback(() => setIsOpen((v) => !v), []);

  const contextValue = useMemo(() => ({ toggle }), [toggle]);

  let definitionValue: typeof value = '--';
  if (typeof value === 'string' && value !== '') definitionValue = t(value);
  if (typeof value === 'number' && !isNaN(value)) definitionValue = value.toString();
  if (typeof value === 'object' && value !== null) definitionValue = value;

  const EditButton = <Button icon="Edit3" tooltip="common.edit" onClick={toggle} className="ml-2" floating />;

  return (
    <definitionContext.Provider value={contextValue}>
      <div className={classList} data-cy={dataCy}>
        <dt>
          <span>{t(label)}</span>
          {!!tooltip && <Icon name="Info" size={14} title={tooltip} className={`ml-1 ${styles['icon-help']}`} />}
        </dt>
        <dd>
          <span>{definitionValue}</span>
          {!!modal?.node && !!modal.permission && (
            <Restricted permissions={[modal.permission]}>{EditButton}</Restricted>
          )}
          {!!modal?.node && !modal?.permission && EditButton}
        </dd>

        {!!modal?.node && (
          <EditDefinitionModal title={modal?.title} isOpen={isOpen} toggle={toggle}>
            {modal?.node}
          </EditDefinitionModal>
        )}
      </div>
    </definitionContext.Provider>
  );
};
