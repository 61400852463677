import React from 'react';
import { LightDefinition } from '@GDM/LightDefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './simulation-settings.module.scss';

export const SimulationSettingsWind = () => {
  const project = useProjectContext();

  return (
    <>
      <LightDefinition
        className={styles.definition}
        label="common.power"
        value={<ValueWithUnit value={project.simulation?.maximum_power} unit="kW" unstyled />}
      />
      <LightDefinition
        className={styles.definition}
        label="pricing.project.n_turbine"
        value={project.simulation?.nb_turb}
      />
      <LightDefinition
        className={styles.definition}
        label="pricing.project.height"
        value={`${project.simulation?.height} m`}
      />
      <LightDefinition
        className={styles.definition}
        label="pricing.project.type_turbine"
        value={`${project.simulation?.turbine?.name} ${
          project.simulation?.turbine?.manufacturer ? `- ${project.simulation?.turbine?.manufacturer}` : ''
        }`.trim()}
      />
    </>
  );
};
