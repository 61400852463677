import { generateUuid } from '@utils/string';

const ID_MAP = new WeakMap();

/**
 * Used to generate a unique key when looping through an array of objects that don't have unique identifiers. _e.g_:
 * ```tsx
 * const { getKey } = useGenKey();
 * ...
 * return <MarketResultRow marketResult={marketResult} key={getKey(marketResult)} />
 * ```
 */
export function useGenKey(): { getKey: (object: Record<string, unknown>) => string } {
  const getKey = (object: Record<string, unknown>): string => {
    if (ID_MAP.has(object)) return ID_MAP.get(object);

    const uuid = generateUuid();
    ID_MAP.set(object, uuid);

    return uuid;
  };

  return { getKey };
}
