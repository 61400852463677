import { useMemo } from 'react';
import { EnergyType } from '@utils/types/common-types';
import type { CountryCode } from '@utils/types/countries';
import { DataPeriod } from '@utils/types/dataPeriod';
import { TimeSeries } from '@utils/types/timeSeries';
import { ProductionTimeSeriesRecord } from '../production.types';
import { computePowerMinMaxAverage } from '../utils/computePowerMinMaxAverage';

export function useProductionBenchmark({
  potentialActiveEnergyProduction,
  dataPeriod,
  chartExtremes,
  energyType,
  totalProdEnergy,
  country,
}: {
  potentialActiveEnergyProduction: ProductionTimeSeriesRecord['potentialActiveEnergyProduction'] | undefined;
  dataPeriod: DataPeriod;
  chartExtremes: [number, number];
  energyType: EnergyType | undefined;
  totalProdEnergy?: number;
  country?: CountryCode;
}) {
  // Forcast energy production (potentialPower)
  const { totalProdEnergyPlanned, potentialPowerIsDefinedEverywhere } = useMemo(() => {
    if (!potentialActiveEnergyProduction?.values.length || !dataPeriod) return {};
    let potentialPowerIsDefinedEverywhere = true;
    const checkThatPotentialPowerIsStillDefined = (point: TimeSeries[number]) => {
      potentialPowerIsDefinedEverywhere = potentialPowerIsDefinedEverywhere && Boolean(point[1]);
    };
    const timeSeriesAggregation = computePowerMinMaxAverage({
      series: potentialActiveEnergyProduction.values,
      dataPeriod,
      timeFilter: chartExtremes,
      onProcessing: checkThatPotentialPowerIsStillDefined,
    });

    return { totalProdEnergyPlanned: timeSeriesAggregation.energy, potentialPowerIsDefinedEverywhere };
  }, [chartExtremes, potentialActiveEnergyProduction, dataPeriod]);

  // Show hydro potential power widget only if all data points are non null
  const showPotentialPower = country !== 'UK' && (energyType !== 'hydro' || potentialPowerIsDefinedEverywhere);

  // Energy production performance metrics
  let totalPerformance;
  let totalPerformanceLoss;
  if (totalProdEnergyPlanned && totalProdEnergy) {
    let performanceRatio = totalProdEnergy / totalProdEnergyPlanned;
    performanceRatio = Math.round(performanceRatio * 1000) / 10;
    performanceRatio = Math.min(100, performanceRatio);
    totalPerformance = performanceRatio;

    totalPerformanceLoss = 0;
    if (totalProdEnergyPlanned > totalProdEnergy) {
      totalPerformanceLoss = Math.round(totalProdEnergyPlanned - totalProdEnergy);
    }
  }

  return { totalProdEnergyPlanned, totalPerformance, totalPerformanceLoss, showPotentialPower };
}
