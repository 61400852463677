import React from 'react';
import { Badge } from '@GDM/Badge';

export const ListCell = ({ list }: { list: string[] }) => {
  return (
    <div className="d-flex flex-columns gap-2">
      {list.map((element: string) => (
        <Badge key={element}>{element}</Badge>
      ))}
    </div>
  );
};
