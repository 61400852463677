import { CountryDictionary } from '@utils/types/countries';

/**
 * List of all the country definitions in the front-end. Any CountryCode or Timezone type should be derived
 * from this list. That means that adding a new country to this list will automatically update `CountryCode`.
 */
export const COUNTRIES = {
  AT: {
    coordinates: {
      lat: 47.516231,
      lng: 14.550072,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Vienna',
  },
  BE: {
    coordinates: {
      lat: 50.5039,
      lng: 4.4699,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Brussels',
  },
  BF: {
    coordinates: {
      lat: 12.238333,
      lng: -1.561593,
    },
    map: { defaultZoom: 7 },
    timezone: 'Africa/Ouagadougou',
  },
  BG: {
    coordinates: {
      lat: 42.43027,
      lng: 24.54376,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Sofia',
  },
  CH: {
    coordinates: {
      lat: 46.48,
      lng: 8.13,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Zurich',
  },
  CL: {
    coordinates: {
      lat: -35.675147,
      lng: -71.542969,
    },
    map: { defaultZoom: 7 },
    timezone: 'Pacific/Easter',
  },
  DE: {
    coordinates: {
      lat: 51.1657,
      lng: 10.4515,
    },
    map: { defaultZoom: 6 },
    timezone: 'Europe/Berlin',
  },
  ES: {
    coordinates: {
      lat: 40.4637,
      lng: -3.7492,
    },
    map: { defaultZoom: 6 },
    timezone: 'Europe/Madrid',
  },
  FI: {
    coordinates: {
      lat: 61.9,
      lng: 8.44,
    },
    map: { defaultZoom: 5.8 },
    timezone: 'Europe/Helsinki',
  },
  FR: {
    coordinates: {
      lat: 47.0273,
      lng: 2.2137,
    },
    map: { defaultZoom: 5.8 },
    timezone: 'Europe/Paris',
  },
  HR: {
    coordinates: {
      lat: 44.2914,
      lng: 16.2737,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Zagreb',
  },
  GR: {
    coordinates: {
      lat: 39.0742,
      lng: 21.8243,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Athens',
  },
  IE: {
    coordinates: {
      lat: 53.190275,
      lng: -7.753076,
    },
    map: { defaultZoom: 6 },
    timezone: 'Europe/Dublin',
  },
  IT: {
    coordinates: {
      lat: 41.8719,
      lng: 12.5674,
    },
    map: { defaultZoom: 6 },
    timezone: 'Europe/Rome',
  },
  KZ: {
    coordinates: {
      lat: 48.1211,
      lng: 66.8478,
    },
    map: { defaultZoom: 7 },
    timezone: 'Asia/Almaty',
  },
  ME: {
    coordinates: {
      lat: 42.4236,
      lng: 19.2436,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Podgorica',
  },
  MX: {
    coordinates: {
      lat: 25.1478,
      lng: -103.0028,
    },
    map: { defaultZoom: 7 },
    timezone: 'America/Mexico_City',
  },
  NL: {
    coordinates: {
      lat: 52.1326,
      lng: 5.2912,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Amsterdam',
  },
  PL: {
    coordinates: {
      lat: 51.9194,
      lng: 19.1451,
    },
    map: { defaultZoom: 6 },
    timezone: 'Europe/Warsaw',
  },
  PT: {
    coordinates: {
      lat: 39.3999,
      lng: -8.2245,
    },
    map: { defaultZoom: 7 },
    timezone: 'Europe/Lisbon',
  },
  UK: {
    coordinates: {
      lat: 54.9006,
      lng: -3.8185,
    },
    map: { defaultZoom: 5.7 },
    timezone: 'Europe/London',
  },
  US: {
    coordinates: {
      lat: 37.0902,
      lng: -95.7129,
    },
    map: { defaultZoom: 4 },
    timezone: 'America/New_York',
  },
  DO: {
    coordinates: {
      lat: 18.7357,
      lng: -70.1627,
    },
    map: { defaultZoom: 8 },
    timezone: 'America/Santo_Domingo',
  },
  UY: {
    coordinates: {
      lat: -32.5228,
      lng: -55.7658,
    },
    map: { defaultZoom: 7 },
    timezone: 'America/Montevideo',
  },
  ZA: {
    coordinates: {
      lat: -30.559482,
      lng: 22.937506,
    },
    map: { defaultZoom: 7 },
    timezone: 'Africa/Johannesburg',
  },
} as const satisfies CountryDictionary;

export const COUNTRY_CODES = Object.keys(COUNTRIES) as Array<keyof typeof COUNTRIES>;
