import React, { useContext, useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { Table, TableHead, TableBody, TableActions, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { unavailabilityFiltersContext } from './Filters/context';
import { ColumnWithClassName, getColumns } from './getColumns';
import styles from './unavailabilities.module.scss';

export const UnavailabilityTable = () => {
  const user = useUser();
  const { filteredUnavailabilities: data } = useContext(unavailabilityFiltersContext);
  const columns = useMemo<ColumnWithClassName[]>(() => getColumns(user), [user]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });

  const table = useReactTable({
    columns,
    data,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
    initialState: { sorting: [{ id: 'start_date', desc: false }] },
  });

  const pageSize = pagination.pageSize;

  return (
    <div className={styles['unavailability-table']}>
      <Table hasActions>
        <TableHead table={table} small />
        <TableBody table={table} data-cy="unavailabilities-table-body" />
      </Table>

      <TableActions>
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </div>
  );
};
