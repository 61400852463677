import { createContext, useContext } from 'react';

export const showMeterFormContext = createContext<{
  showMeterForm: boolean;
  setShowMeterForm: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  showMeterForm: false,
  setShowMeterForm: () => {},
});

export const useShowMeterForm = () => useContext(showMeterFormContext);
