import React from 'react';
import ControlledCheckbox from '@components/FormInputs/ControlledCheckbox';
import { Control } from 'react-hook-form';
import { FormType } from './BusinessPlanForm';

type Props = {
  control: Control<FormType>;
};

export const AllYearsFormField = ({ control }: Props) => {
  return <ControlledCheckbox label="monitoring.bp.apply_to_all_years" name="allYears" control={control} />;
};
