import React from 'react';
import { Step } from '@GDM/forms';
import { InstallationSettingsSection } from '../sections/InstallationSettingsSection';
import { ProjectSection } from '../sections/ProjectSection';
import { SimulationSection } from '../sections/SimulationSection';
import { WindConstraints } from '../sections/WindConstraints';

const PROJECT_STEP: Step = {
  title: 'common.project',
  progress: 0,
  component: <ProjectSection />,
};

const SIMULATION_STEP: Step = {
  title: 'pricing.simulation_settings',
  progress: 100,
  isValid: true,
  component: <SimulationSection />,
};

const WIND_CONSTRAINTS: Step = {
  title: 'pricing.project.constraints',
  progress: 0,
  component: <WindConstraints />,
};

const INSTALLATION_SETTINGS_STEP: Step = {
  title: 'pricing.installation_settings',
  progress: 0,
  component: <InstallationSettingsSection />,
};

export const DEFAULT_STEPS: Record<string, Step> = { project: PROJECT_STEP };

export const ALL_SOLAR_STEPS: Record<string, Step> = {
  ...DEFAULT_STEPS,
  installation: INSTALLATION_SETTINGS_STEP,
  simulation: SIMULATION_STEP,
};

export const ALL_WIND_STEPS: Record<string, Step> = {
  ...DEFAULT_STEPS,
  installation: INSTALLATION_SETTINGS_STEP,
  simulation: SIMULATION_STEP,
  constraints: WIND_CONSTRAINTS,
};
