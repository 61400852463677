import React from 'react';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './nowrap-head-cell.module.scss';

export const NoWrapHeadCell = ({
  align,
  tooltip,
  label,
}: {
  tooltip?: string;
  label: string;
  align?: 'left' | 'right' | 'center';
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip tooltip={tooltip}>
      <div className={classNames(styles['nowrap-head-cell'], align && `ta:${align}`)} data-tooltip-id="default-tooltip">
        {t(label)}
      </div>
    </Tooltip>
  );
};
