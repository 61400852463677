import React from 'react';
import { useFormContext } from 'react-hook-form';
import styles from '../new-simulation.module.scss';
import { SimulationForm } from '../utils/simulations.types';
import { SolarSimulation } from './SolarSection';
import { WindSection } from './WindSection';

export const InstallationSettingsSection = () => {
  const { watch } = useFormContext<SimulationForm>();
  const { energy } = watch('installation_attributes');

  return (
    <div className="p-3">
      <div className={styles['sub-form']}>
        {energy === 'solar' && <SolarSimulation />}
        {energy === 'wind' && <WindSection />}
      </div>
    </div>
  );
};
