import React from 'react';
import { ModalWrapper as Modal, ModalHeader } from '@GDM/Modal';
import useTranslation from '@hooks/useTranslation';

export const EditDefinitionModal = ({
  children,
  isOpen,
  title,
  toggle,
}: React.PropsWithChildren<{
  isOpen: boolean;
  title?: string;
  toggle: () => void;
}>) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{!!title && t(title)}</ModalHeader>
      {children}
    </Modal>
  );
};
