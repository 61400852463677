import React, { useMemo } from 'react';
import { NumberCell } from '@components/ui/table/cell/NumberCell';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { MetricsResponse } from './utils/hooks/useMetrics';

type InvoicesGeneratedTableProps = {
  metrics: MetricsResponse | null;
};

type TableType = {
  [key in keyof Omit<MetricsResponse, 'categories'>]: number | null;
} & { date: string };

/**
 * This function will transform our hashes of data into an array of objects
 * and sum the total of each invoices (sent, issued, approved, created)
 */
function invoicesToTableData(metrics: MetricsResponse | null): TableType[] {
  if (!metrics) return [];

  return metrics.categories.map((date, index) => {
    const approved = metrics.approved ? metrics.approved[index] : null;
    const created = metrics.created ? metrics.created[index] : null;
    const rejected = metrics.rejected ? metrics.rejected[index] : null;
    const requested_submission_external_service = metrics.requested_submission_external_service
      ? metrics.requested_submission_external_service[index]
      : null;
    const accepted_external_service = metrics.accepted_external_service
      ? metrics.accepted_external_service[index]
      : null;
    const generated = metrics.generated ? metrics.generated[index] : null;
    const accepted_offtaker = metrics.accepted_offtaker ? metrics.accepted_offtaker[index] : null;
    const payment_initiated = metrics.payment_initiated ? metrics.payment_initiated[index] : null;
    const paid = metrics.paid ? metrics.paid[index] : null;
    const ignored = metrics.ignored ? metrics.ignored[index] : null;
    const pending = metrics.pending ? metrics.pending[index] : null;
    const sent_to_erp = metrics.sent_to_erp ? metrics.sent_to_erp[index] : null;

    return {
      date,
      approved,
      created,
      rejected,
      requested_submission_external_service,
      accepted_external_service,
      generated,
      accepted_offtaker,
      payment_initiated,
      paid,
      ignored,
      pending,
      sent_to_erp,
    };
  });
}
const InvoicesGeneratedTable = ({ metrics }: InvoicesGeneratedTableProps) => {
  const data: TableType[] = useMemo(() => {
    return invoicesToTableData(metrics);
  }, [metrics]);

  const columns = useMemo<ColumnDef<TableType>[]>(() => {
    return [
      { header: 'common.date', accessorKey: 'date' },
      {
        header: 'common.filters.invoice_status.approved',
        accessorKey: 'approved',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.created',
        accessorKey: 'created',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.rejected',
        accessorKey: 'rejected',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.requested_submission_external_service',
        accessorKey: 'requested_submission_external_service',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.accepted_external_service',
        accessorKey: 'accepted_external_service',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.generated',
        accessorKey: 'generated',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.accepted_offtaker',
        accessorKey: 'accepted_offtaker',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.payment_initiated',
        accessorKey: 'payment_initiated',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.paid',
        accessorKey: 'paid',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.ignored',
        accessorKey: 'ignored',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.pending',
        accessorKey: 'pending',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
      {
        header: 'common.filters.invoice_status.sent_to_erp',
        accessorKey: 'sent_to_erp',
        cell: ({ getValue }) => <NumberCell value={getValue<number>()} />,
      },
    ] satisfies ColumnDef<TableType>[];
  }, []);

  const table = useReactTable({
    columns,
    data,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHead table={table} />
      <TableBody table={table} />
    </Table>
  );
};

export default InvoicesGeneratedTable;
