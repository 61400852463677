import React from 'react';
import { Modal } from '@GDM/Modal';
import { destroy, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { v2_meter_panel_path } from '@utils/routes';
import Panel from '@utils/types/panel';

export const PanelDeleteModal = ({
  visible,
  toggle,
  meterName,
  selectedPanel,
  deletePanel,
}: {
  visible: boolean;
  toggle: () => void;
  meterName: string;
  selectedPanel: Panel | null;
  deletePanel: (uuid: string) => void;
}) => {
  const deleteRequest = useRequest(v2_meter_panel_path(meterName, selectedPanel?.uuid || 'none'), destroy, true);
  const { t } = useTranslation();

  const handleDeleteClick = () => {
    if (selectedPanel?.uuid) {
      deleteRequest.execute?.();
      deletePanel(selectedPanel?.uuid);
    }
  };

  return (
    <Modal isOpen={visible} toggle={toggle} size="sm" submitAction={handleDeleteClick} saveButtonText="common.delete">
      <h5>{t('monitoring.panels.are_you_sure_delete')}</h5>
    </Modal>
  );
};
