import React from 'react';
import localeContext from '@context/locale.context';
import UserCtx, { useUserContext } from '@context/User.context';
import { User } from '@utils/types/user';

type UserProviderProps = {
  user: User;
};

export const UserProvider = ({ children, user }: React.PropsWithChildren<UserProviderProps>) => {
  const userContext = useUserContext(user);

  return (
    <UserCtx.Provider value={userContext}>
      <localeContext.Provider value={userContext.locale || 'fr'}>{children}</localeContext.Provider>
    </UserCtx.Provider>
  );
};
