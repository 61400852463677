import React from 'react';
import { useDynamicOptions } from '@GDM/Filters';
import { CountrySelect } from '@GDM/forms';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';
import { Controller } from 'react-hook-form';

const getOptions = (invoices: MeterInvoice[]): Option<MeterInvoice['installation_country']>[] => {
  const countries = Array.from(new Set(invoices.map((invoice) => invoice.installation_country)));
  const options = countries
    .map((country) => ({ label: `countries.${country}`, value: country }))
    .sort(sortOptionsByLabelAsc);

  return options;
};

export const CountryFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'installation_country', filteredInvoices, allInvoices);

  return (
    <Controller
      name="installation_country"
      control={form.control}
      render={({ field }) => (
        <CountrySelect
          options={options}
          onChange={(option) => field.onChange(option?.value)}
          selectedOption={field.value}
          placeholder="common.country"
          classNamePrefix="country-picker"
          neverDisable
          isClearable
        />
      )}
    />
  );
};
