import React, { useMemo, useState, useEffect } from 'react';
import { Alert } from '@GDM/Alert';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import { Event, EventType } from '@utils/types/event';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { filteredEventsContext, EventFilters, EventFiltersContext } from './filtered-events.context';

export const filterEvents = (filters: EventFilters, events: Event[]): Event[] => {
  let filteredEvents = events;

  if (filters.installationName !== '') {
    filteredEvents = matchSorterInstanceStrict(filteredEvents, filters.installationName, {
      keys: ['installation.name'],
    });
  }

  if (filters.installationCountry) {
    filteredEvents = matchSorterInstanceStrict(filteredEvents, filters.installationCountry, {
      keys: ['installation.country'],
    });
  }

  if (filters.installationEnergy !== 'all') {
    filteredEvents = filteredEvents.filter((d) => d.installation.energy === filters.installationEnergy);
  }

  if (filters.installationNames && filters.installationNames.length > 0) {
    filteredEvents = filteredEvents.filter(
      (d) => d.installation.name && filters.installationNames.includes(d.installation.name),
    );
  }

  if (filters.eventTypes.length > 0) {
    filteredEvents = filteredEvents.filter((d) => filters.eventTypes.includes(d.type));
  }

  return filteredEvents;
};

export const getDefaultFilters = (type?: 'monitoring' | 'sales'): EventFilters => {
  let eventTypes: EventType[] = [];
  if (type === 'monitoring')
    eventTypes = [
      'CommunicationFailure',
      'ProductionStop',
      'OutOfRangeReactive',
      'UnderPerformance',
      'HighUnderPerformance',
    ];
  if (type === 'sales')
    eventTypes = [
      'AnnualProductionExceedsCap',
      'AnnualProductionExceedsDafr',
      'ProductionOnNegativePrice',
      'InvoiceMissingData',
      'HighDiffPublishedProduction',
    ];

  return {
    installationNames: [],
    installationName: '',
    installationCountry: null,
    installationEnergy: 'all',
    eventTypes,
  };
};

type Props = {
  children: React.ReactNode;
  hasMonitoringPermission: boolean;
  hasSalesPermission: boolean;
  type?: 'monitoring' | 'sales';
};

export const FilteredEventsProvider = ({ type, hasMonitoringPermission, hasSalesPermission, children }: Props) => {
  const defaultFilters = getDefaultFilters(type);

  const [startDate, setStartDate] = useState<EventFiltersContext['startDate']>(dayjs().subtract(2, 'weeks').toDate());
  const [endDate, setEndDate] = useState<EventFiltersContext['endDate']>(dayjs().endOf('day').toDate());

  const [filters, setFilters] = useState<EventFilters>(defaultFilters);
  const [allEvents, setAllEvents] = useState<Event[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>(filterEvents(filters, []));

  if (!type && hasMonitoringPermission && !hasSalesPermission) {
    type = 'monitoring';
  }
  if (!type && !hasMonitoringPermission && hasSalesPermission) {
    type = 'sales';
  }

  const contextValue: EventFiltersContext = useMemo(
    () => ({
      allEvents,
      setAllEvents,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      filteredEvents,
      filters,
      type,
      setFilters: (filters: EventFilters) => {
        setFilters({ ...filters });
        setFilteredEvents(filterEvents(filters, allEvents));
      },
    }),
    [allEvents, setAllEvents, startDate, endDate, filteredEvents, filters, type],
  );

  useEffect(() => {
    const _filteredEvents = filterEvents(filters, allEvents);
    if (!isEqual(filteredEvents, _filteredEvents)) {
      setFilteredEvents(_filteredEvents);
    }
  }, [allEvents, filteredEvents, filters]);

  if (
    (!hasMonitoringPermission && !hasSalesPermission) ||
    (type === 'monitoring' && !hasMonitoringPermission) ||
    (type === 'sales' && !hasSalesPermission)
  ) {
    return <Alert label="errors.unauthorized" />;
  }

  return <filteredEventsContext.Provider value={contextValue}>{children}</filteredEventsContext.Provider>;
};
