import React from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { getSymbol } from '@utils/currency/getSymbol';
import { formatNumber, formatSystemDate } from '@utils/formatters';
import { ContractForm } from '@utils/types/contract/form';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import styles from './hedge-block-header.module.scss';

export const HedgeBlockHeader = ({
  title,
  badgeLabel,
  startDate,
  endDate,
  price,
  averagePower,
  isCollapsed,
  toggle,
  insertBefore,
  remove,
  error,
  readOnly,
  currency,
}: {
  title: string;
  badgeLabel?: string;
  startDate?: Date;
  endDate?: Date;
  price: number;
  averagePower: number;
  toggle: () => void;
  insertBefore: () => void;
  remove: () => void;
  isCollapsed: boolean;
  error?: FieldError;
  readOnly: boolean;
  currency: ContractForm['currency'];
}) => {
  const { locale } = useUser();

  const formattedDates = `${formatSystemDate(startDate)} / ${formatSystemDate(endDate)}`;
  const displayPrice = formatNumber(price, locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) ?? '-';
  const displayPower = formatNumber(averagePower, locale, { maximumFractionDigits: 2 }) ?? '-';

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <span onClick={toggle}>{title}</span>
        {isCollapsed && (
          <div className={classNames(styles['collapsed-dates'], error && styles['has-error'])}>
            <span className={styles['collapsed-prefix']}>&ndash;&nbsp;</span>
            <Badge variant={error ? 'danger' : 'primary-1'} label={badgeLabel} /> <span>{formattedDates}</span>
            <span className="pl-2">
              (<b>{displayPrice}</b> {getSymbol(currency)}/MWh / <b>{displayPower}</b> MW)
            </span>
            {error && (
              <Icon
                name="AlertCircle"
                title="common.contains_errors"
                size={14}
                className={styles['collapsed-warning']}
              />
            )}
          </div>
        )}
      </div>
      {!readOnly && (
        <>
          <Button icon="Plus" title="sales_management.add_block_before" onClick={insertBefore} floating />
          <Button icon="Trash2" variant="secondary" title="common.delete" onClick={remove} className="ml-1" floating />
        </>
      )}
    </div>
  );
};
