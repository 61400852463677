import chunk from 'lodash/chunk';

export function validateSiret(input: unknown, siren?: unknown): true | string {
  if (!input || (typeof input !== 'string' && typeof input !== 'number')) return 'errors.required';

  const siret = `${input}`;

  if (!/^[0-9]{14}$/.test(siret)) return 'sales_management.siret_wrong_size_error';

  if (siren && siret.slice(0, 9) !== `${siren}`.slice(0, 9)) return 'sales_management.siren_siret_mismatch_error';

  let sum = 0;

  chunk(siret, 2).forEach(([a, b]) => {
    const even = Number(a) * 2;
    sum += (even > 9 ? even - 9 : even) + Number(b);
  });

  return sum % 10 ? 'sales_management.siret_pattern_error' : true;
}
