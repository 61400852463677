import React from 'react';
import { Input } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';

export const BankInput = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('bank');

  return (
    <Controller
      control={form.control}
      name="bank"
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          type="text"
          label="owner.bank"
          size="lg"
          full
          disabled={disabled}
          data-cy="bank"
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};
