import React from 'react';
import { Alert } from '@GDM/Alert';
import { UseMutationResult } from '@tanstack/react-query';
import type { ReqError } from '@utils/fetch';
import { ContractFormBody, type Contract } from '@utils/types/contract';
import { ContractFormMode } from '../hooks/useContractForm/types';
import { FormErrors } from './FormErrors';

export const FormAlerts = ({
  mode,
  submitMutation,
}: {
  mode: ContractFormMode;
  submitMutation: UseMutationResult<Contract, ReqError, ContractFormBody>;
}) => {
  if (submitMutation.isSuccess)
    return (
      <Alert
        icon="Info"
        label={
          mode === 'edit'
            ? 'sales_management.info.contract_successfully_updated'
            : 'sales_management.info.contract_successfully_created'
        }
        dismissible
      />
    );

  if (submitMutation.error) {
    return <FormErrors error={submitMutation.error} title="sales_management.form_request_has_errors" />;
  }

  return null;
};
