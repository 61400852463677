import React from 'react';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';

export const HeadCell = ({
  align,
  tooltip,
  'data-cy': dataCy,
  label,
  className,
}: {
  tooltip?: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  'data-cy'?: string;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip tooltip={tooltip}>
      <div className={classNames(align && `ta:${align}`, className)} data-cy={dataCy}>
        {t(label)}
      </div>
    </Tooltip>
  );
};
