import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import { v2_buyers_path, with_contract_v2_buyers_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

export const useBuyers = (): CommonRequestHookResponse<MarketPlayer[]> => {
  return useRequest(v2_buyers_path(), 'GET', false);
};

export const useBuyersWithContract = (): CommonRequestHookResponse<MarketPlayer[]> => {
  return useRequest(with_contract_v2_buyers_path(), 'GET');
};
