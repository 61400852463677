import React from 'react';
import { SELECT_COMPONENTS } from '@GDM/forms';
import { Icon, OtherAvailableFlags } from '@GDM/Icon';
import { COUNTRY_CODES } from '@utils/constants/countries';
import { isCountryCode } from '@utils/filters/isCountryCode';
import { Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import classNames from 'classnames';
import { GroupBase, OptionProps } from 'react-select';
import styles from './option-country.module.scss';

export const CountryPickerOption = (
  data: Option<CountryCode | string | null> & {
    country?: CountryCode | string | null;
  },
): JSX.Element => {
  const countryFromOption = data?.country || data.value;
  const country = isCountryCode(countryFromOption) ? countryFromOption : 'XX';
  const validCountries = [...COUNTRY_CODES, 'XX'];
  const countryIcon = country && validCountries.includes(country) ? country : OtherAvailableFlags.XX;

  return (
    <div className={styles.container} title={data.label}>
      <Icon name={countryIcon} size={12} className={classNames(styles.flag, 'mr-1')} />
      <span className={classNames(styles.label, 'ml-1')}>{data.label}</span>
    </div>
  );
};

export const OptionCountry = <T extends string>(props: OptionProps<Option<T>, boolean, GroupBase<Option<T>>>) => {
  return (
    <SELECT_COMPONENTS.Option {...props}>
      <CountryPickerOption {...props.data} />
    </SELECT_COMPONENTS.Option>
  );
};
