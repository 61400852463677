import React from 'react';
import { SELECT_COMPONENTS } from '@GDM/forms';
import { Mandate } from '@utils/types/mandate';
import { GroupBase, OptionProps } from 'react-select';
import { MandateOption } from './mandate-select.types';
import { MandateStatusBadge } from './MandateStatusBadge';

export const MandateSelectBadge = (props: { status: Mandate['status']; label: string }) => (
  <div className="d-flex align-items-center" title={props.label}>
    <MandateStatusBadge status={props.status} />
    <span className="ml-2">{props.label}</span>
  </div>
);

export const MandateSelectOption: React.ComponentType<OptionProps<MandateOption, boolean, GroupBase<MandateOption>>> = (
  props,
) => {
  return (
    <SELECT_COMPONENTS.Option {...props}>
      <MandateSelectBadge status={props.data.status} label={props.data.label} />
    </SELECT_COMPONENTS.Option>
  );
};
