import React, { useEffect, useRef } from 'react';
import { Marker } from '@GDM/Map';
import useIsOnScreen from '@hooks/useIsOnScreen';
import { Installation } from '@utils/types/installation';
import { FeatureGroup as FeatureGroupType, LatLng } from 'leaflet';
import { Popup, useMap, FeatureGroup } from 'react-leaflet';
import styles from './maps.module.scss';

export const BookMap = ({
  installations,
}: {
  installations: Pick<Installation, 'latitude' | 'longitude' | 'name' | 'uuid'>[];
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const featureGroupRef = useRef<FeatureGroupType>(null);
  const map = useMap();
  const isVisible = useIsOnScreen(containerRef);

  useEffect(() => {
    const resizeMap = () => map.invalidateSize();
    resizeMap();
    window.addEventListener('resize', resizeMap);

    const bounds = featureGroupRef?.current?.getBounds();
    if (bounds?.isValid()) map.fitBounds(bounds);

    return window.removeEventListener('resize', resizeMap);
  }, [isVisible, map, featureGroupRef]);

  return (
    <div ref={containerRef}>
      <FeatureGroup ref={featureGroupRef}>
        {installations?.map(
          (installation) =>
            !!installation.latitude &&
            !!installation.longitude && (
              <Marker
                key={installation.uuid}
                position={new LatLng(Number(installation.latitude), Number(installation.longitude))}
              >
                <Popup className={styles.popup}>
                  <div className="fw:700">{installation.name}</div>
                </Popup>
              </Marker>
            ),
        )}
      </FeatureGroup>
    </div>
  );
};
