import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import type { Classification } from '@utils/types/contract';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

export const ClassificationFilter = () => {
  const { filterContracts } = useConsumeContracts();
  const form = useFormContext<ContractsFiltersType>();

  const options: Option<Classification>[] = [
    { value: 'physical', label: 'sales_management.physical' },
    { value: 'financial', label: 'sales_management.financial' },
  ];

  return (
    <Controller
      name="classification"
      control={form.control}
      render={({ field }) => (
        <Select
          placeholder="sales_management.classification"
          isClearable
          selectedOption={field.value}
          isSearchable
          options={options}
          onChange={(option: Option<Classification> | null) => {
            filterContracts({
              ...form.getValues(),
              classification: option?.value || null,
              last_selected_filter: 'classification',
            });
            field.onChange(option?.value);
            form.setValue('last_selected_filter', option?.value ? 'classification' : null);
          }}
        />
      )}
    />
  );
};
