import { useQuery } from '@tanstack/react-query';
import fetchWrapper from '@utils/fetch';
import { v2_green_star_counter_parts_path } from '@utils/routes';
import { EnergyType } from '@utils/types/common-types';

export type GreenStarInstallation = {
  id: number;
  darwin_code: string;
  energy: EnergyType;
  grappe: string;
  holding: string;
  name: string;
  spv: string;
  status: string;
  created_at: string;
  updated_at: string;
};

type GreenStarGroups = {
  grappes: string[];
  spvs: string[];
  holdings: string[];
  contract_types: string[];
};

export const useGreenStarInstallations = () =>
  useQuery({
    queryKey: ['greenstar_installations'],
    queryFn: async (): Promise<GreenStarInstallation[]> => {
      const data = await fetchWrapper('/v2/green_star_installations');
      const installations: GreenStarInstallation[] = await data.json();

      return installations.sort((a, b) => a.name.localeCompare(b.name));
    },
  });

export const useGreenStarGroups = () =>
  useQuery({
    queryKey: ['greenstar_installations_groups'],
    queryFn: async (): Promise<GreenStarGroups> => {
      const data = await fetchWrapper('/v2/green_star_installations/groups');

      return data.json();
    },
  });

export const useGreenStarCounterParts = () => {
  return useQuery({
    queryKey: ['greenstar_counterparts'],
    queryFn: async () => {
      const data = await fetchWrapper(v2_green_star_counter_parts_path());

      return data.json();
    },
  });
};
