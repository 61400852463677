import { MarketFuture } from '@utils/types/market_future';

export const findNextCalPeriods = (selected: MarketFuture, marketFutures: MarketFuture[]): MarketFuture[] | null => {
  const periodsSortedByDate = marketFutures.sort(
    (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime(),
  );

  const selectedPeriodIndex = periodsSortedByDate.findIndex((p) => p.id === selected.id);

  const relevantPeriods = periodsSortedByDate
    .slice(selectedPeriodIndex)
    .filter((p) => p.time_type === 'year' && p.id !== selected.id);

  return relevantPeriods.length ? relevantPeriods : null;
};
