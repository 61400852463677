import React from 'react';
import { Progress } from '@GDM/Progress';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { card } from '../card.module.scss';
import { CardTitle } from '../CardTitle';
import styles from './progress-card.module.scss';

export const ProgressCard = ({
  className,
  children,
  dangerThreshold = 80,
  isLoading,
  title,
  tooltip,
  text,
  value,
}: React.PropsWithChildren<{
  title?: string | JSX.Element;
  tooltip?: string;
  className?: string;
  value: number;
  isLoading?: boolean;
  dangerThreshold?: number;
  text?: string;
}>) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(card, styles['progress-card'], className)}>
      {title && <CardTitle title={title} tooltip={tooltip} />}
      <div className="mt-4">
        <Progress value={value} isLoading={isLoading} dangerThreshold={dangerThreshold} valueInside />
      </div>
      {(text || children) && <div className={classNames('mt-3', styles.text)}>{text ? t(text) : children}</div>}
    </div>
  );
};
