import React from 'react';
import { Select } from '@GDM/forms/Select/Select';
import useTranslation from '@hooks/useTranslation';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import Book from '@utils/types/book';

export const BookSelect = ({
  books,
  value,
  onChange,
}: {
  books: Book[];
  value: string | null;
  onChange: (id: string | null) => void;
}) => {
  const { t } = useTranslation();

  const options = books ? books.map((b) => ({ label: b.name, value: b.uuid })).sort(sortOptionsByLabelAsc) : [];

  return (
    <Select
      classNamePrefix="book_select"
      placeholder={t('admin.books.book_name')}
      options={options}
      selectedOption={value}
      onChange={(option) => {
        onChange(option?.value || null);
      }}
      id="book_select"
      isClearable
    />
  );
};
