import React from 'react';
import { ValueCard } from '@GDM/Card';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import type { NegativeHour } from './helpers/getFormattedNegativeHours';
import type { NegativePrice } from './negative-prices.types';

export const Widgets = ({
  negativeHours,
  negativePrices,
  showRealCovering,
}: {
  negativeHours: NegativeHour[];
  negativePrices: NegativePrice[];
  showRealCovering: boolean;
}) => {
  const { t } = useTranslation();
  const { totalNegativePriceHours, totalInterruptionHours, totalProdOnNegativeHours } = negativeHours.reduce(
    (acc, nh) => {
      acc.totalNegativePriceHours += nh.negativePriceHours;
      acc.totalInterruptionHours += nh.interruptionHours;
      acc.totalProdOnNegativeHours += nh.productionOnNegativeHours;

      return acc;
    },
    { totalNegativePriceHours: 0, totalInterruptionHours: 0, totalProdOnNegativeHours: 0 },
  );

  let nbInstallations = null;
  let realNegativesHours = null;
  if (showRealCovering) {
    nbInstallations = new Set(negativePrices.map((nh) => nh.installation?.uuid)).size;
    realNegativesHours =
      nbInstallations > 0 && totalNegativePriceHours ? Math.floor(totalNegativePriceHours / nbInstallations) : null;
  }

  return (
    <div className="d-flex gap-3 mt-3">
      <ValueCard
        title="common.n_negative_price_hours"
        value={totalNegativePriceHours}
        unit="h"
        tooltip={showRealCovering ? 'common.covering_real_tooltip' : undefined}
      >
        {showRealCovering && realNegativesHours && (
          <>
            {t('common.covering_real')}&nbsp;
            <ValueWithUnit value={realNegativesHours} unit="h" />
          </>
        )}
      </ValueCard>
      <ValueCard title="common.n_interruption_hours_on_negative_price" value={totalInterruptionHours} unit="h" />
      <ValueCard title="common.prod_during_negative_price" value={totalProdOnNegativeHours} unit="kWh" />
    </div>
  );
};
