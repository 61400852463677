import React from 'react';
import { Card } from '@GDM/Card';
import { specialUnitsMap } from '@GDM/ValueWithUnit/config';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import classNames from 'classnames';
import { ConfidenceValue, ConfidenceTuple } from '../ConfidenceValue';
import styles from './cards.module.scss';

export const ConfidenceIntervalCard = ({ confidence }: { confidence: '50' | '80' }) => {
  const project = useProjectContext();
  const {
    final_q10_ratio,
    final_q10,
    final_q25_ratio,
    final_q25,
    final_q75_ratio,
    final_q50_ratio,
    final_q75,
    final_q90_ratio,
    final_q90,
  } = project?.feed_in_deviation || {};

  const variant = final_q50_ratio ? (final_q50_ratio >= 0 ? 'primary-1' : 'secondary') : null;
  const unit = 'percentage';

  const ratios: ConfidenceTuple =
    confidence === '50'
      ? [
          { value: final_q25_ratio || null, unit, variant },
          { value: final_q75_ratio || null, unit, variant },
        ]
      : [
          { value: final_q10_ratio || null, unit, variant },
          { value: final_q90_ratio || null, unit, variant },
        ];
  const values: ConfidenceTuple =
    confidence === '50'
      ? [{ value: final_q25 || null }, { value: final_q75 || null }]
      : [{ value: final_q10 || null }, { value: final_q90 || null }];

  return (
    <Card
      title={`pricing.portfolio.confidence_interval_${confidence}`}
      className={classNames(styles.card)}
      body={
        <>
          <div className={styles.value}>
            <ConfidenceValue values={ratios} />
          </div>
          <div>
            <ConfidenceValue values={values} append={specialUnitsMap.get('EUR/MWh')?.default} />
          </div>
        </>
      }
    />
  );
};
