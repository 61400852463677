import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { v2_index_formulas_path } from '@utils/routes';
import IndexFormula from '@utils/types/IndexFormula';

const fetcher = ({ url }: { url: string }) =>
  axiosInstance(false)
    .get<IndexFormula[]>(url)
    .then((response) => ({
      data: {
        formulas: response.data.filter((i) => !i.edf),
        edfFormulas: response.data.filter((i) => i.edf),
      },
      status: response.status,
    }));

const useIndexFormulas = (): CommonRequestHookResponse<{
  formulas: IndexFormula[];
  edfFormulas: IndexFormula[];
}> => {
  const req = useRequest(v2_index_formulas_path(), fetcher);

  return req;
};

export default useIndexFormulas;
