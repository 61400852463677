import React from 'react';
import { Input } from '@GDM/forms';
import { Inverter } from '@utils/types/inverter';
import { Controller, Control, FieldValues, FieldErrors } from 'react-hook-form';

type FormFieldProps = {
  inverter?: Inverter;
  errors?: FieldErrors;
  control: Control<FieldValues>;
};

const FormField = ({ inverter, control }: FormFieldProps) => {
  if (!inverter) return null;

  return (
    <Controller
      name={inverter['ref']}
      control={control}
      render={({ field }) => <Input className="mb-3" label={inverter['ref']} {...field} />}
    />
  );
};

export default FormField;
