import React, { useEffect } from 'react';
import { Badge } from '@GDM/Badge';
import { Input } from '@GDM/forms';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { post, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { v2_books_path } from '@utils/routes';
import { Book } from '@utils/types/book';
import { Controller, useForm } from 'react-hook-form';

export const CreateBookModal = ({
  toggle,
  isOpen,
  selectedMeters,
}: {
  toggle: () => void;
  isOpen: boolean;
  selectedMeters: string[];
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { name: '' } });
  const { execute, loaded, loading, error } = useRequest<Book>(v2_books_path(), post, true);
  const installations = Array.from(new Set(selectedMeters));
  const { t } = useTranslation();

  const onSubmit = ({ name }: { name: string }) => {
    execute?.({ installations: installations, name });
  };

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen, reset]);

  useEffect(() => {
    if (loaded) {
      toggle();
    }
  }, [loaded, toggle]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      header="monitoring.books.create_book"
      submitDisabled={loading}
      submitAction={handleSubmit(onSubmit)}
      saveButtonText="monitoring.books.create_book"
      isLoading={loading}
      error={error?.code || error?.message}
    >
      <div>
        <Controller
          name="name"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="common.name"
              id="book-name"
              className={errors?.name?.type === 'required' ? 'is-invalid' : ''}
              full
            />
          )}
        />
        {errors?.name?.type === 'required' ? (
          <p className="text-red mt-3">{t('monitoring.books.errors.name_error')}</p>
        ) : null}
      </div>

      <Text text="monitoring.books.selected_installations" className="my-3 fw:600" />

      <div className="d-flex flex-wrap tight">
        {installations.map((el) => (
          <Badge className="mb-1" key={el}>
            {el}
          </Badge>
        ))}
      </div>
    </Modal>
  );
};
