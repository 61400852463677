import React, { useEffect, useMemo, useRef } from 'react';
import { Icon } from '@GDM/Icon';
import useIsOnScreen from '@hooks/useIsOnScreen';
import { FeatureGroup as FeatureGroupType, LatLng, Point, divIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { FeatureGroup, Marker, useMap } from 'react-leaflet';
import { DashboardViewAllData } from '../portfolio.definitions';
import { getColorClassNameForIcon } from './getColorClassNameForIcon';
import { MapPopup } from './MapPopup';

export const PortfolioMap = ({ data, defaultZoom }: { data?: DashboardViewAllData[]; defaultZoom: number }) => {
  const containerRef = useRef(null);
  const featureGroupRef = useRef<FeatureGroupType>(null);
  const map = useMap();
  const isVisible = useIsOnScreen(containerRef);

  const containsMarkers = useMemo(() => !!data?.some((meter) => !!meter.latitude && !!meter.longitude), [data]);

  useEffect(() => {
    if (!containsMarkers) {
      map?.setZoom(defaultZoom);

      return;
    }

    const resizeMap = () => map?.invalidateSize();
    resizeMap();
    window.addEventListener('resize', resizeMap);

    const bounds = featureGroupRef?.current?.getBounds();

    if (bounds?.isValid()) map.fitBounds(bounds);

    return window.removeEventListener('resize', resizeMap);
  }, [isVisible, map, featureGroupRef, data, containsMarkers, defaultZoom]);

  if (!containsMarkers) return null;

  return (
    <div ref={containerRef}>
      <FeatureGroup ref={featureGroupRef}>
        {data?.map(
          (meter) =>
            !!meter.latitude &&
            !!meter.longitude && (
              <Marker
                icon={divIcon({
                  className: '',
                  html: renderToString(
                    <Icon name="MapPin" size={18} className={getColorClassNameForIcon(meter.event_types)} />,
                  ),
                  popupAnchor: new Point(0, -20),
                  iconAnchor: new Point(12.5, 20),
                })}
                key={meter.ref_centrale}
                position={new LatLng(Number(meter.latitude), Number(meter.longitude))}
              >
                <MapPopup meter={meter} />
              </Marker>
            ),
        )}
      </FeatureGroup>
    </div>
  );
};
