import {
  Revenues,
  InvoiceRevenueDataPoint,
  InvoiceRevenueGroupedDataPoint,
  RevenueContextType,
} from '../revenue.types';

/** These type helpers allow typescript to know the format of the data coming in.
 *  The opposite works, so if it's not grouped, then TS can tell it's regular data.
 *  */

const isGroupedDataPoint = (
  dataPoint: InvoiceRevenueGroupedDataPoint | InvoiceRevenueDataPoint,
): dataPoint is InvoiceRevenueGroupedDataPoint => {
  if (!dataPoint) return false;

  return 'values' in dataPoint;
};

export const isGroupedData = (
  data: RevenueContextType['revenue'] | null,
): data is Revenues<InvoiceRevenueGroupedDataPoint> => {
  if (!data || data.length === 0) return false;

  return isGroupedDataPoint(data[0]);
};
