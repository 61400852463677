import { InvoiceRevenueGroupedDataPoint } from '../revenue.types';

/**
 * If we're splitting by installation, first calculate the total income for the period,
 * then sort by that figure, then splice the array if there's a limit, then sort alphabetically for display
 */
export function sortAndFilterGroupedData(
  groupedData?: InvoiceRevenueGroupedDataPoint[],
  limit?: number | null,
): InvoiceRevenueGroupedDataPoint[] | undefined {
  if (!groupedData?.every((group) => group.group === 'installation')) return groupedData;

  const installationsWithAccumulatedRevenue: Array<InvoiceRevenueGroupedDataPoint & { totalRevenue: number }> =
    groupedData
      .map((installation) => {
        const totalRevenue: number = installation.values.reduce((acc, curr) => acc + (curr.revenue || 0), 0);

        return { ...installation, totalRevenue };
      })
      .sort((a, b) => b.totalRevenue - a.totalRevenue)
      .slice(0, limit && limit > 0 ? limit : groupedData.length)
      .sort((a, b) => a.label?.localeCompare(b.label || '') || 0);

  return installationsWithAccumulatedRevenue;
}
