import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import styles from './table-head.module.scss';

export function TableHead<T extends Record<string, unknown>>({
  table,
  small,
}: {
  small?: boolean;
  table: Table<T>;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <thead className={styles['table-head']}>
      {table.getHeaderGroups().map((headerGroup) => {
        return (
          <tr className={classNames(styles.row)} key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const headerText =
                typeof header.column.columnDef.header === 'string'
                  ? t(header.column.columnDef.header)
                  : header.column.columnDef.header;

              const title: string | undefined = typeof headerText === 'string' ? headerText : undefined;

              const meta = header.column.columnDef.meta;
              let className = '';
              if (meta && 'className' in meta && typeof meta.className === 'string') className = meta.className;

              return (
                <th
                  title={title}
                  className={classNames(styles.cell, className, {
                    [styles['sorting']]: header.column.getCanSort(),
                    [styles['sorting-asc']]: header.column.getNextSortingOrder() === 'desc',
                    [styles['sorting-desc']]: !header.column.getNextSortingOrder(),
                    [styles.small]: small,
                    [styles['no-icons']]: !header.column.getCanSort(),
                  })}
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(headerText, header.getContext())}
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
}
