import { createContext } from 'react';

type LaunchTrCtx = {
  selectedMeters?: string[];
  disabledMeters?: string[];
  addMeter?: (meterName: string) => void;
  removeMeter?: (meterName: string) => void;
  selectAll?: () => void;
  unselectAll?: () => void;
};

const launchTrCtx = createContext<LaunchTrCtx>({});

export default launchTrCtx;
