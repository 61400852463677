import React from 'react';
import { CountrySelect } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { COUNTRIES } from '@utils/constants/countries';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Controller } from 'react-hook-form';

export const CountryPicker = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('country');
  const { t } = useTranslation();

  const countryKeys = Object.keys(COUNTRIES);
  const options = countryKeys
    .map((countryKey) => ({ label: t(`countries.${countryKey}`), value: countryKey }))
    .sort(sortOptionsByLabelAsc);

  return (
    <Controller
      control={form.control}
      name="country"
      rules={rules}
      render={({ field }) => (
        <CountrySelect
          options={options}
          onChange={(option) => field.onChange(option?.value)}
          selectedOption={field.value}
          isDisabled={disabled}
          size="lg"
          label="common.country"
          classNamePrefix="country-picker"
          full
        />
      )}
    />
  );
};
