import React, { useContext } from 'react';
import { BookSelect } from '@components/ui/BookSelect';
import useBooks from '@hooks/requests/useBooks';
import { filteredEventsContext } from './filtered-events.context';

export const BookFilter = ({ onChange }: { onChange: (installations: string[]) => void }) => {
  const { data: books } = useBooks({ bookType: 'monitoring' });
  const [selectedBook, setSelectedBook] = React.useState<string | null>(null);
  const { filteredEvents } = useContext(filteredEventsContext);

  const filteredBooks =
    books?.filter(
      (book) =>
        filteredEvents.filter(
          (event) => event.installation.name && book.installation_names.includes(event.installation.name),
        ).length > 0 || book.uuid === selectedBook,
    ) || [];

  if (!books) {
    return null;
  }

  return (
    <BookSelect
      books={filteredBooks}
      value={selectedBook}
      onChange={(bookId) => {
        const book = books.find((b) => b.uuid === bookId);

        setSelectedBook(bookId);
        onChange(book?.installation_names ?? []);
      }}
    />
  );
};
