import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { useResizeObserver } from '@hooks/useResizeObserver';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FiltersUiStateContext } from './filters.context';
import styles from './filters.module.scss';

export function Filters<T extends Record<string, unknown>>({
  children,
  form,
  className,
  onClear,
  disableClearButton,
}: PropsWithChildren<{
  form?: UseFormReturn<T>;
  className?: string;
  disableClearButton?: boolean;
  onClear?: () => void;
}>): JSX.Element {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [childrenAllFit, setChildrenAllFit] = useState(true);
  const [showAllFilters, setShowAllFilters] = useState(false);

  /**
   * Check if all children can fit in container width without overflow or new line
   */
  const checkChildrenFit = useCallback(() => {
    const { current } = ref;

    if (!current) {
      return false;
    }

    const children = Array.from(current.children);
    const parentWidth = current.getBoundingClientRect().width;

    const totalChildrenWidth = children.reduce((acc, child) => {
      const bounds = child.getBoundingClientRect();
      const style = getComputedStyle(child);
      const margin = parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
      const totalWidth = bounds.width + margin;

      // remove hide class if children is hidden and fit in container
      if (totalWidth + acc <= parentWidth || showAllFilters) {
        child.classList.remove(styles.hide);
      } else if (!child.classList.contains(styles.hide) && !showAllFilters) {
        child.classList.add(styles.hide);
      }

      return acc + totalWidth;
    }, 0);

    return totalChildrenWidth <= parentWidth;
  }, [ref, showAllFilters]);

  useEffect(() => {
    setChildrenAllFit(checkChildrenFit());
  }, [checkChildrenFit]);

  useResizeObserver(() => {
    setChildrenAllFit(checkChildrenFit());
  }, ref);

  const contextValue = useMemo(
    () => ({ showAllFilters, onChildrenResize: () => setChildrenAllFit(checkChildrenFit()) }),
    [showAllFilters, checkChildrenFit],
  );

  const content = (
    <>
      <div ref={ref} className={classNames(styles['filters-list'], showAllFilters && styles['show-all'])}>
        <FiltersUiStateContext.Provider value={contextValue}>{children}</FiltersUiStateContext.Provider>
      </div>

      <div className="d-flex">
        {onClear && (
          <Button
            disabled={disableClearButton}
            variant="primary-2"
            size="xs"
            text="common.reset"
            className="position-relative"
            onClick={onClear}
          />
        )}
        <Button
          className={classNames(styles['show-more-btn'], 'mb-3', showAllFilters && styles['shown'])}
          onClick={() => setShowAllFilters((prev) => !prev)}
          variant="primary-2"
          data-cy="show-more-filters"
          noClip
        >
          {t('common.more_filters')}
          <Icon size={16} name="ChevronsDown" />
        </Button>
      </div>
    </>
  );

  return (
    <div className={classNames(styles['filters'], !childrenAllFit && styles['overflow'], className)}>
      {form && <FormProvider {...form}>{content}</FormProvider>}
      {!form && content}
    </div>
  );
}
