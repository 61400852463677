import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { v2_installation_asset_bps_path } from '@utils/routes';
import { BusinessPlan } from '@utils/types/business-plan';

type Data = BusinessPlan[];

export const useBusinessPlans = (installationName: string | null): UseQueryResult<Data> => {
  return useQuery({
    queryKey: ['getBusinessPlans', installationName],
    queryFn: async () => {
      const url = installationName ? v2_installation_asset_bps_path(installationName) : null;
      if (url) {
        const response = await fetch(url);

        return response.json();
      }
    },
    enabled: Boolean(installationName),
  });
};
