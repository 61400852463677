import { RequestError, useRequest } from '@hooks/useRequest';
import { solar_v2_national_prices_path, wind_v2_national_prices_path } from '@utils/routes';
import { CountryCode } from '@utils/types/countries';
import { MonthlyMarketResult } from '@utils/types/m0';

type UseM0AggregReturn = {
  solar: MonthlyMarketResult[];
  wind: MonthlyMarketResult[];
  loadingWind: boolean;
  loadingSolar: boolean;
  error: RequestError | null;
};

const useM0ByType = (country: CountryCode | null): UseM0AggregReturn => {
  const {
    data: wind,
    loading: loadingWind,
    error: errorWind,
  } = useRequest<MonthlyMarketResult[]>(country ? wind_v2_national_prices_path({ country }) : null, 'GET');
  const {
    data: solar,
    loading: loadingSolar,
    error: errorSolar,
  } = useRequest<MonthlyMarketResult[]>(country ? solar_v2_national_prices_path({ country }) : null, 'GET');

  return { wind: wind || [], solar: solar || [], loadingWind, loadingSolar, error: errorSolar || errorWind };
};

export default useM0ByType;
