import React, { useContext } from 'react';
import localeContext from '@context/locale.context';
import { Button } from '@GDM/Button';
import fileDownload from 'js-file-download';
import { useOwnersContext } from '../owners.context';
import { loadOwnerCsvExport } from './ownerCsvExport';

export const OwnerCsvButton = () => {
  const locale = useContext(localeContext);
  const { owners } = useOwnersContext();

  const handleDownloadCsvClick = () => {
    if (!owners) return;
    const csvContent = loadOwnerCsvExport(owners, locale);

    fileDownload(csvContent, 'streem_owners.csv', 'text/csv;charset=utf-8', '\uFEFF');
  };

  return (
    <div className="ta:right pr-3">
      <Button
        text="common.download"
        icon="Download"
        size="xxs"
        variant="primary-2"
        data-cy="owner-download"
        onClick={handleDownloadCsvClick}
      />
    </div>
  );
};
