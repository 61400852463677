import { formatSystemDate } from '@utils/formatters';
import { ContractSubPeriod, ContractSubPeriodForm, ContractSubPeriodFormBody } from '@utils/types/contract';

/** Currently used only in the context of the serializeSubPeriods function. */
export function serializeSubPeriod(
  subPeriod: ContractSubPeriodForm | ContractSubPeriod,
  props: Partial<ContractSubPeriodForm> = {},
): ContractSubPeriodFormBody {
  const period: Omit<ContractSubPeriodForm, 'dates'> &
    ContractSubPeriodFormBody & { dates?: ContractSubPeriodForm['dates'] } = {
    ...subPeriod,
    id: subPeriod.id,
    ...props,
    start_date: formatSystemDate('dates' in subPeriod ? subPeriod.dates?.[0] : subPeriod.start_date),
    end_date: formatSystemDate('dates' in subPeriod ? subPeriod.dates?.[1] : subPeriod.end_date),
  };

  period.price_rule_id = subPeriod.price_rule?.id ?? null;

  // Coerce `display_price` to a Number
  if (period.display_price) period.display_price = Number(period.display_price);

  // Aggregator fees: reset and set to make sure there are no lingering values
  period.aggreg_fee_fixed = null;
  period.aggreg_fee_percentage = null;

  if ('aggreg_fees' in subPeriod)
    subPeriod.aggreg_fees?.forEach((fee) => {
      // Default fee type is 'fixed'
      if (fee.type === 'fixed' || !fee.type) period.aggreg_fee_fixed = fee.value ?? null;
      if (fee.type === 'percentage' && fee.value) period.aggreg_fee_percentage = fee.value / 100; // Backend stores a float between 0 and 1.
    });

  if ('volume_ratio' in subPeriod) period.volume_ratio = subPeriod.volume_ratio ? subPeriod.volume_ratio / 100 : null;

  // These props aren't needed by the backend.
  delete period.period_type;
  delete period.dates;
  delete period.aggreg_fees;
  delete period.volume_type;

  return period;
}
