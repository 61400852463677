import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from '@GDM/Alert';
import { AltContent } from '@GDM/AltContent';
import { Spinner } from '@GDM/Spinner';
import { useRequest } from '@hooks/useRequest';
import { useBSM } from '@pages/BSM/context';
import { internal_subscriptions_path } from '@utils/routes';
import { Subscription } from '@utils/types/subscription';
import { SubscriptionsContextType, subscriptionsContext } from './context';
import { SubscriptionSlideInPanel } from './SlideIn/SubscriptionSlideInPanel';
import { SubscriptionsTable } from './SubscriptionsTable';

export const Subscriptions = () => {
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);
  const { subscriptions, setSubscriptions } = useBSM();
  const { data, loading, loaded, error } = useRequest<Subscription[]>(internal_subscriptions_path(), 'GET');

  const subscriptionsContextValue = useMemo<SubscriptionsContextType>(
    () => ({ selectedSubscription, setSelectedSubscription }),
    [selectedSubscription],
  );

  useEffect(() => {
    const hash = window.location.hash;

    if (!loaded) return;

    if (hash.length) {
      const itemId = hash.replace('#', '');
      const subscription = data?.find((c) => c.uuid === itemId);

      if (subscription) setSelectedSubscription(subscription);
    }

    setSubscriptions?.(data);
  }, [loaded, data, setSubscriptions]);

  if (error) return <Alert variant="danger" label={error.code || 'Something went wrong with the request'} />;

  if (loading)
    return (
      <div className="ta:center">
        <Spinner />
      </div>
    );

  if (!subscriptions || !subscriptions.length) return <AltContent />;

  return (
    <subscriptionsContext.Provider value={subscriptionsContextValue}>
      <SubscriptionSlideInPanel />
      <SubscriptionsTable data={subscriptions} />
    </subscriptionsContext.Provider>
  );
};
