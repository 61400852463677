import { GroupedPoints } from '../revenue.types';
import { InvoiceRevenueGroupedDataPoint } from '../revenue.types';
import { mapToDataPoint } from './mapToDataPoint';

export function mapGroupedDataToSeries(rawData: InvoiceRevenueGroupedDataPoint): GroupedPoints {
  return {
    label: rawData.label || '--',
    type: rawData.group,
    values: rawData.values.map(mapToDataPoint),
    noData: rawData.values.every((v) => v.revenue === null || v.revenue === undefined),
  };
}
