import React from 'react';
import { UserProvider } from '@components/providers/UserProvider';
import localeContext from '@context/locale.context';
import { Locale } from '@utils/types/common-types';
import { User } from '@utils/types/user';
import classNames from 'classnames';

type PdfPageProps = {
  children?: React.ReactNode;
  locale: Locale;
  user: User;
  fixed?: boolean;
};

const PdfPage = ({ children, locale, user, fixed }: PdfPageProps) => {
  return (
    <div className={classNames('PdfPage', fixed && 'PdfPage--Fixed')} id="PdfPage">
      <localeContext.Provider value={locale || 'fr'}>
        <UserProvider user={user}>{children}</UserProvider>
      </localeContext.Provider>
    </div>
  );
};

export default PdfPage;
