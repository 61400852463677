import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { Contract } from '@utils/types/contract';
import dayjs from 'dayjs';

export const isExpired = (contract: Contract) => {
  const today = dayjs().startOf('day').toDate();

  return new Date(contract.end_date) < today;
};

export const isOngoing = (contract: Contract) => {
  const today = dayjs().startOf('day').toDate();
  const tomorrow = dayjs().add(1, 'day').startOf('day').toDate();

  return new Date(contract.start_date) < today && new Date(contract.end_date) > tomorrow;
};

export const isNotStarted = (contract: Contract) => {
  const tomorrow = dayjs().add(1, 'day').startOf('day').toDate();

  return new Date(contract.start_date) > tomorrow;
};

export function isDateStatus(contract: Contract, dateStatus: ContractsFiltersType['date_status']): boolean {
  const today = dayjs().startOf('day').toDate();
  const tomorrow = dayjs().add(1, 'day').startOf('day').toDate();

  if (dateStatus === 'expired') {
    return new Date(contract.end_date) < today;
  }

  if (dateStatus === 'ongoing') {
    return new Date(contract.start_date) < today && new Date(contract.end_date) > tomorrow;
  }

  if (dateStatus === 'not_started') {
    return new Date(contract.start_date) > tomorrow;
  }

  return true;
}
