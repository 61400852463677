import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { TableLabel } from '@GDM/InlineLabelnput/TableLabel';
import { TableLabelContainer } from '@GDM/InlineLabelnput/TableLabelContainer';
import { getSymbol } from '@utils/currency/getSymbol';
import type { ContractForm } from '@utils/types/contract';
import type { Currency } from '@utils/types/currency';
import type { Installation } from '@utils/types/installation';
import type { Control, FieldPath, UseFormWatch } from 'react-hook-form';
import { FormSectionLayout, type ContractFormSectionProps } from '../components/FormSection/FormSectionLayout';
import { ListInput } from '../components/Inputs/List';
import { NumericInput } from '../components/Inputs/Numeric';
import { PRICE_TYPE_OPTIONS_MAP } from '../sections/SubPeriods/components/SubPeriod/constants/priceTypes';

export const SubscriptionVariable = ({ title, readOnly, form: { control, watch } }: ContractFormSectionProps) => {
  const scheme = watch('scheme');

  return (
    <FormSectionLayout
      title={title}
      body={
        <div className="p-3 gap-3 d-flex flex-column">
          {scheme === 'supply_only' && (
            <SubscriptionVariableSupplyOnly watch={watch} control={control} readOnly={readOnly} />
          )}

          {scheme === 'unique_contract' && (
            <SubscriptionVariableUniqContract watch={watch} control={control} readOnly={readOnly} />
          )}
        </div>
      }
    />
  );
};

const SubscriptionVariableSupplyOnly = ({
  control,
  readOnly,
  watch,
}: {
  watch: UseFormWatch<ContractForm>;
  control: Control<ContractForm>;
  readOnly: boolean;
}) => {
  const gridConnectionType = watch('installation_attributes.grid_connection_type');
  const currency = watch('currency');
  const scheme = watch('scheme');

  return (
    <SubscriptionVariablePriceTable
      gridConnectionType={gridConnectionType}
      scheme={scheme}
      control={control}
      currency={currency}
      readOnly={readOnly}
    />
  );
};

const SubscriptionVariableUniqContract = ({
  watch,
  control,
  readOnly,
}: {
  watch: UseFormWatch<ContractForm>;
  control: Control<ContractForm>;
  readOnly: boolean;
}) => {
  const currency = watch('currency');
  const gridConnectionType = watch('installation_attributes.grid_connection_type');
  const priceType = watch('contract_sub_periods_attributes.0.price_type');
  const scheme = watch('scheme');

  return (
    <div className={sectionStyles.container}>
      <div className={sectionStyles.row}>
        <ListInput
          id="PriceType"
          control={control}
          options={[PRICE_TYPE_OPTIONS_MAP.spot, PRICE_TYPE_OPTIONS_MAP.indexed]}
          name="contract_sub_periods_attributes.0.price_type"
          label="common.price_type"
          rules={{ required: true }}
          menuPlacement="top"
          readOnly={readOnly}
          size="lg"
          helpBox={{
            title: 'common.price_type',
            text: 'sales_management.price_type_info',
          }}
          full
        />
      </div>

      {priceType === 'indexed' && (
        <div>
          <SubscriptionVariablePriceTable
            gridConnectionType={gridConnectionType}
            scheme={scheme}
            control={control}
            currency={currency}
            readOnly={readOnly}
          />
        </div>
      )}

      {priceType === 'spot' && (
        <>
          <div className={sectionStyles.row}>
            <NumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.market_access_commission"
              label="sales_management.variable.market_access_commission"
              suffix={`${getSymbol(currency)}/MWh`}
              readOnly={readOnly}
            />
            <NumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.capacity_mechanism"
              label="sales_management.variable.capacity_mechanism"
              suffix={`${getSymbol(currency)}/MWh`}
              readOnly={readOnly}
            />
          </div>
          <div className={sectionStyles.row}>
            <NumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.renewable"
              label="sales_management.variable.renewable"
              suffix={`${getSymbol(currency)}/MWh`}
              readOnly={readOnly}
            />
          </div>
        </>
      )}
    </div>
  );
};

const PeriodNumericInput = ({
  name,
  control,
  label,
  currency,
  readOnly,
  scheme,
}: {
  name: FieldPath<ContractForm>;
  control: Control<ContractForm>;
  label: string;
  currency: Currency;
  readOnly: boolean;
  scheme: ContractForm['scheme'];
}) => {
  return (
    <TableLabel
      label={label}
      name={name}
      renderInput={(className) => (
        <NumericInput
          id={name}
          control={control}
          name={name}
          suffix={`${getSymbol(currency)}/MWh`}
          readOnly={readOnly}
          inline
          full={false}
          className={className}
          helpBox={{
            title: 'sales_management.variable.prices',
            text: `sales_management.variable.prices_info_${scheme}`,
          }}
          rules={{ required: true }}
        />
      )}
    />
  );
};

const SubscriptionVariablePriceTable = ({
  gridConnectionType,
  scheme,
  control,
  currency,
  readOnly,
}: {
  gridConnectionType: Installation['grid_connection_type'];
  scheme: ContractForm['scheme'];
  control: Control<ContractForm>;
  currency: Currency;
  readOnly: boolean;
}) => (
  <div className="w-50">
    <TableLabelContainer label="sales_management.variable.prices">
      {gridConnectionType === 'high_voltage' && (
        <PeriodNumericInput
          control={control}
          name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.variable.P"
          label={
            scheme === 'unique_contract'
              ? 'sales_management.variable.peak'
              : 'sales_management.variable.peak_winter_red'
          }
          readOnly={readOnly}
          currency={currency}
          scheme={scheme}
        />
      )}
      <PeriodNumericInput
        control={control}
        name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.variable.HPH"
        label={
          scheme === 'unique_contract' ? 'sales_management.variable.hph' : 'sales_management.variable.hph_winter_orange'
        }
        readOnly={readOnly}
        currency={currency}
        scheme={scheme}
      />
      <PeriodNumericInput
        control={control}
        name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.variable.HCH"
        label={
          scheme === 'unique_contract' ? 'sales_management.variable.hch' : 'sales_management.variable.hch_winter_green'
        }
        readOnly={readOnly}
        currency={currency}
        scheme={scheme}
      />
      <PeriodNumericInput
        control={control}
        name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.variable.HPB"
        label={
          scheme === 'unique_contract' ? 'sales_management.variable.hpb' : 'sales_management.variable.hpb_summer_orange'
        }
        readOnly={readOnly}
        currency={currency}
        scheme={scheme}
      />
      <PeriodNumericInput
        control={control}
        name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.subscription.variable.HCB"
        label={
          scheme === 'unique_contract' ? 'sales_management.variable.hcb' : 'sales_management.variable.hch_summer_green'
        }
        readOnly={readOnly}
        currency={currency}
        scheme={scheme}
      />
    </TableLabelContainer>
  </div>
);
