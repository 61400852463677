import React, { useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { Col, Row } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';
import ModalForm from './ModalForm';

const Inverters = ({ meterName }: { meterName: string }) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const toggle = () => {
    setVisible((v) => !v);
  };

  return (
    <div>
      <ModalForm toggle={toggle} visible={visible} meterName={meterName} />
      <Row className="d-flex justify-content-center align-items-stretch">
        <Col md={9} className="d-flex align-items-center">
          <div className="tt:uppercase fw:700">{t('common.inverters')}</div>
        </Col>
        <Col md={3} className="d-flex justify-content-end">
          <Restricted permissions={['display:edit_monitoring']}>
            <Button className="mr-0" variant="primary-2" size="xs" onClick={toggle}>
              {t('common.edit')}
            </Button>
          </Restricted>
        </Col>
      </Row>
    </div>
  );
};

export default Inverters;
