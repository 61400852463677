import React from 'react';
import { flexRender, Table as TTable } from '@tanstack/react-table';
import classNames from 'classnames';
import { LoadingTBody } from '../LoadingTBody';
import { NoDataTBody } from './NoDataTBody';
import styles from './table-body.module.scss';

export function TableBody<T extends Record<string, unknown>>({
  'data-cy': dataCy,
  highlightColor,
  highlightedRows,
  isLoading,
  onMouseEnterRow,
  onMouseLeaveRow,
  table,
}: {
  'data-cy'?: string;
  highlightColor?: 'blue' | 'grey';
  highlightedRows?: number[];
  isLoading?: boolean;
  onMouseEnterRow?: (key: number) => void;
  onMouseLeaveRow?: (key: number) => void;
  table: TTable<T>;
}): JSX.Element {
  if (isLoading) return <LoadingTBody colSpan={table.getAllColumns().length} />;

  if (!table.getRowModel().rows || table.getRowModel().rows?.length === 0) {
    return <NoDataTBody colSpan={table.getAllColumns().length} data-cy={dataCy} />;
  }

  return (
    <tbody data-cy={dataCy} className={styles['table-body']}>
      {table.getRowModel().rows.map((row, i) => {
        const className = classNames(
          { [styles.highlighted]: highlightedRows?.includes(i) },
          highlightColor && styles[highlightColor],
        );

        return (
          <tr
            key={row.id}
            className={className}
            onMouseEnter={() => onMouseEnterRow?.(i)}
            onMouseLeave={() => onMouseLeaveRow?.(i)}
            data-highlighted={highlightedRows?.includes(i)}
            data-highlighted-last={highlightedRows?.includes(i) && i === highlightedRows.length - 1}
          >
            {row.getVisibleCells().map((cell) => {
              const meta = cell.column.columnDef.meta;
              let className = '';
              if (meta && 'className' in meta && typeof meta.className === 'string') className = meta.className;

              return (
                <td key={cell.id} className={classNames(styles.cell, className)}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
