import { GeoRasterData } from './pricing-maps.types';

export const getPricingMapSettings = (
  deviation: boolean,
  geoRaster: GeoRasterData,
  baseloadPrices: number[],
  m0Prices: number[],
) => {
  const baselineValue: number = deviation ? 0 : 100;
  let minValue: number;
  let maxValue: number;

  if (deviation) {
    minValue = 100 * Math.min(...geoRaster.mins.map((d, i) => d / baseloadPrices[i]).filter(Boolean));
    maxValue = 100 * Math.max(...geoRaster.maxs.map((d, i) => d / baseloadPrices[i]).filter(Boolean));
  } else {
    minValue = 100 * Math.min(...geoRaster.mins.map((d, i) => (d + m0Prices[i]) / baseloadPrices[i]).filter(Boolean));
    maxValue = 100 * Math.max(...geoRaster.maxs.map((d, i) => (d + m0Prices[i]) / baseloadPrices[i]).filter(Boolean));
  }

  const rangeOfValues = maxValue - minValue;

  const maxAbsValue = Math.max(
    Math.max(Math.abs(baselineValue - minValue), 0),
    Math.max(Math.abs(maxValue - baselineValue), 0),
  );

  return {
    baselineValue,
    maxAbsValue,
    minValue,
    maxValue,
    rangeOfValues,
  };
};
