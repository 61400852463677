import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { ContractForm } from '@utils/types/contract';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import styles from '../../../../sub-period.module.scss';
import { AggregatorFees } from '../AggregatorFees/AggregatorFees';
import { HedgeProfileCaps } from './HedgeProfileCaps/HedgeProfileCaps';

export const HedgeProfile = ({
  index,
  form,
  readOnly,
  currency,
  startDate,
  endDate,
}: {
  index: number;
  form: UseFormReturn<ContractForm>;
  readOnly: boolean;
  currency: Currency;
  startDate: Date | undefined;
  endDate: Date | undefined;
}) => {
  const { control } = form;

  return (
    <>
      <div className={styles.section}>
        <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
          <NumericInput
            id="HedgeProfileBelowPrice"
            control={control}
            name={`contract_sub_periods_attributes.${index}.display_price`}
            label="sales_management.hedge_profile_below_price"
            labelStyle={{ fontWeight: 'normal' }}
            autoComplete="off"
            readOnly={readOnly}
            full
            suffix={`${getSymbol(currency)}/MWh`}
            data-cy={`display-price-${index}`}
          />
          <ListInput
            id="PriceAboveCapType"
            control={control}
            name={`contract_sub_periods_attributes.${index}.profile_hedge_attributes.price_above_cap_type`}
            label="sales_management.hedge_profile_above_price"
            labelStyle={{ fontWeight: 'normal' }}
            size="lg"
            options={[
              { value: 'capture_price', label: 'pricing.maps.capture_price' },
              { value: 'baseload', label: 'pricing.baseload_price' },
              { value: 'positive_capture_price', label: 'pricing.maps.positive_capture_price' },
            ]}
            readOnly={readOnly}
          />
        </div>
        <AggregatorFees
          form={form}
          name={`contract_sub_periods_attributes.${index}.aggreg_fees`}
          currency={currency}
          readOnly={readOnly}
          isMulti
        />
      </div>
      <div className={styles.section}>
        <div className={styles['row-3']}>
          <ListInput
            id="HedgeProfilePeriod"
            label="sales_management.profile"
            size="lg"
            name={`contract_sub_periods_attributes.${index}.profile_hedge_attributes.period`}
            control={control}
            options={[
              { value: 'monthly', label: 'subscriptions.frequencies.monthly' },
              { value: 'yearly', label: 'subscriptions.frequencies.yearly' },
            ]}
            readOnly={readOnly}
            defaultValue="monthly"
            className={styles['columns-1-3']}
          />
          <HedgeProfileCaps
            className={styles['columns-3-3']}
            subPeriodIndex={index}
            startDate={startDate}
            endDate={endDate}
            readOnly={readOnly}
            form={form}
          />
        </div>
      </div>
    </>
  );
};
