import React from 'react';
import { SelectInvoicesProvider } from '@context/selectInvoices.context';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice } from '@utils/types/meter-invoice';
import InvoicePageWrapper from 'pages/sales-management/invoicing/InvoicePageWrapper';
import { InvoiceTableProps } from 'pages/sales-management/invoicing/InvoicesTable/InvoicesTable';
import { getInvoicesType } from '../../utils/getInvoicesType';

export const InvoiceTableDataWrapper = ({
  invoices,
  isLight,
  sortByDefault,
  onDateChange,
  startDate,
  endDate,
  isLoading,
  direction,
}: {
  invoices: MeterInvoice[];
  isLight?: boolean;
  onDateChange?: (startDate: Date | null, endDate?: Date | null) => void;
  startDate?: Date | null;
  endDate?: Date | null;
  isLoading?: boolean;
  direction: Contract['direction'] | 'all';
} & Pick<InvoiceTableProps, 'sortByDefault'>) => {
  const invoiceType = React.useMemo(() => getInvoicesType(invoices), [invoices]);

  return (
    <SelectInvoicesProvider>
      <InvoicePageWrapper
        invoices={invoices}
        invoiceType={invoiceType ?? 'load_curve'}
        isLoading={!!isLoading}
        isLight={isLight}
        sortByDefault={sortByDefault}
        isDateRange
        startDate={startDate}
        endDate={endDate}
        onDateChange={onDateChange}
        picks="months"
        hideBookSelect
        direction={direction}
      />
    </SelectInvoicesProvider>
  );
};
