import React from 'react';
import { useUser } from '@context/User.context';
import { Colon } from '@GDM/Colon';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { getSymbol } from '@utils/currency/getSymbol';
import { formatHumanDate } from '@utils/formatters';
import { isANumber } from '@utils/isANumber';
import { Contract } from '@utils/types/contract';
import dayjs from 'dayjs';
import round from 'lodash/round';
import styles from '../../../contracts.module.scss';
import { RefPriceTag } from './RefPriceTag';

export const PricingCell = ({ row: { original } }: CellContext<Contract, unknown>) => {
  const { t } = useTranslation();
  const { locale } = useUser();
  const currency = original.currency;

  const formatPrice = (value: number): string =>
    new Intl.NumberFormat(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2, notation: 'compact' }).format(
      value,
    );

  const { contract_sub_periods } = original;
  const normalSubPeriods = contract_sub_periods?.filter((csp) => csp.phase_type === null);

  if (normalSubPeriods && normalSubPeriods?.length < 1) {
    return <div>{t('sales_management.price.no_price')}</div>;
  }

  if (normalSubPeriods && normalSubPeriods?.length > 1) {
    return <div>{t('sales_management.price.multi')}</div>;
  }

  const subPeriod =
    original.type === 'ContractGo'
      ? normalSubPeriods?.find((p) => {
          const today = dayjs();
          const start = dayjs(p.start_date);
          const end = dayjs(p.end_date);

          return (
            (today.isAfter(start, 'day') || today.isSame(start, 'day')) &&
            (today.isBefore(end) || today.isSame(end, 'day'))
          );
        }) || normalSubPeriods?.[0]
      : normalSubPeriods?.[0];

  const aggreg_fee = subPeriod?.aggreg_fee;
  const aggreg_fee_unit = subPeriod?.aggreg_fee_unit;
  const price_unit = !subPeriod?.gc_price ? 'MWh' : 'MW';

  let latest_unit_price = '';

  if (original.latest_unit_price !== undefined && original.latest_unit_price !== null) {
    latest_unit_price =
      original.type != 'ContractCapa'
        ? (original.latest_unit_price * 1000).toString()
        : original.latest_unit_price.toFixed(0);
  }

  return (
    <div className="fw:400">
      {subPeriod && <RefPriceTag currency={original.currency} type={original.type} period={subPeriod} />}
      {original.type === 'ContractGo' && subPeriod && normalSubPeriods && normalSubPeriods.length > 1 && (
        <Tooltip tooltip="contracts.table.period_info">
          <div>
            {t('common.period')}
            <Colon />
            &nbsp;
            <span className="text-small">
              {formatHumanDate(subPeriod.start_date)} &gt; {formatHumanDate(subPeriod.end_date)}
            </span>
          </div>
        </Tooltip>
      )}

      {(isANumber(aggreg_fee) ||
        isANumber(subPeriod?.aggreg_fee_fixed) ||
        isANumber(subPeriod?.aggreg_fee_percentage)) && (
        <div className="mt-1">
          {t('common.fees_label')}{' '}
          {aggreg_fee && aggreg_fee_unit !== 'percentage' && (
            <span className={styles['price']}>
              <b>{new Intl.NumberFormat().format(+aggreg_fee)}</b>&nbsp;
              <span>
                {getSymbol(currency)}/{price_unit}
              </span>
            </span>
          )}
          {isANumber(aggreg_fee) && aggreg_fee > 0 && aggreg_fee_unit === 'percentage' && (
            <span className={styles['price']}>
              <b>{new Intl.NumberFormat().format(round(+aggreg_fee * 100, 2))}</b>&nbsp;<span>%</span>
            </span>
          )}
          {isANumber(subPeriod?.aggreg_fee_fixed) && subPeriod?.aggreg_fee_fixed > 0 && (
            <span className={styles['price']}>
              <b>
                {new Intl.NumberFormat(locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                  subPeriod.aggreg_fee_fixed,
                )}
              </b>
              &nbsp;
              <span>{getSymbol(currency)}/MWh</span>
            </span>
          )}
          {isANumber(subPeriod?.aggreg_fee_percentage) && subPeriod.aggreg_fee_percentage > 0 && (
            <span className={styles['price']}>
              <b>
                {new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(
                  subPeriod.aggreg_fee_percentage * 100,
                )}
              </b>
              &nbsp;
              <span>%</span>
            </span>
          )}
        </div>
      )}
      {isANumber(original.latest_unit_price) && original.latest_unit_price > 0 && (
        <div className="mt-1">
          {t('sales_management.previous_month')}&nbsp;
          <span className={styles['price']}>
            <b>{formatPrice(+latest_unit_price)}</b>{' '}
            <span>
              {getSymbol(currency)}/{price_unit}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};
