import { useMemo } from 'react';
import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import { formatSystemDate } from '@utils/formatters';
import { get_index_data_v2_dashboard_meter_index_path } from '@utils/routes';
import { IndexData, IndexType } from '@utils/types/installation_index';

type ResponseBody = {
  all_index_data: { data: IndexData; periodes: string[] };
  indexseries: IndexData;
  typeparam: string;
  name: string;
};

export const useIndexData = (
  startDate: Date | null,
  endDate: Date | null,
  typeParam?: IndexType,
): CommonRequestHookResponse<ResponseBody> => {
  const params = new URLSearchParams(window.location.search.substring(1));
  const meterName = params.has('meter') ? (params.get('meter') as string) : '';

  const body: Record<string, string> | null = useMemo(() => {
    if (!meterName || !startDate || !endDate || !typeParam) return null;

    const params = {
      refcompteur: meterName,
      typeparam: typeParam,
      firstdate: startDate ? formatSystemDate(startDate) : '',
      lastdate: endDate ? formatSystemDate(endDate) : '',
    };

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter((entry) => entry[1] !== null) as [string, string][],
    );

    return filteredParams;
  }, [meterName, typeParam, startDate, endDate]);

  const urlParams = body ? new URLSearchParams(body).toString() : null;
  const url = `${get_index_data_v2_dashboard_meter_index_path()}?${urlParams}`;

  return useRequest<ResponseBody>(url, 'GET', !body);
};
