import React, { useDeferredValue, useEffect } from 'react';
import { sectionStyles } from '@GDM/forms';
import { TableLabel } from '@GDM/InlineLabelnput/TableLabel';
import { TableLabelContainer } from '@GDM/InlineLabelnput/TableLabelContainer';
import type { ContractForm } from '@utils/types/contract';
import type Installation from '@utils/types/installation';
import type { Control, FieldPath, UseFormWatch } from 'react-hook-form';
import { FormSectionLayout, type ContractFormSectionProps } from '../../components/FormSection/FormSectionLayout';
import { ListInput } from '../../components/Inputs/List';
import { NumericInput } from '../../components/Inputs/Numeric';

export const Turpe = ({
  title,
  readOnly,
  form: { control, setValue, watch },
  globals: { installation },
}: ContractFormSectionProps) => {
  const gridConnectionTypeDefaultValue = installation?.grid_connection_type || null;
  const deferredInstallation = useDeferredValue(installation);
  const scheme = watch('scheme');

  useEffect(() => {
    if (installation && deferredInstallation && deferredInstallation !== installation) {
      const gridConnectionType = deferredInstallation?.grid_connection_type;
      const gridAccessType = deferredInstallation?.grid_access_type;

      setValue('installation_attributes.grid_connection_type', gridConnectionType || null);
      setValue('installation_attributes.grid_access_type', gridAccessType || null);
    }
  }, [installation, deferredInstallation, setValue]);

  return (
    <FormSectionLayout
      title={title}
      body={
        <div className="p-3">
          {scheme === 'supply_only' && (
            <TurpeSupplyOnly
              gridConnectionTypeDefaultValue={gridConnectionTypeDefaultValue}
              control={control}
              readOnly={readOnly}
            />
          )}

          {scheme === 'unique_contract' && (
            <TurpeUniqueContract
              watch={watch}
              gridConnectionTypeDefaultValue={gridConnectionTypeDefaultValue}
              control={control}
              readOnly={readOnly}
            />
          )}
        </div>
      }
    />
  );
};

const TurpeUniqueContract = ({
  control,
  readOnly,
  gridConnectionTypeDefaultValue,
  watch,
}: {
  gridConnectionTypeDefaultValue: Installation['grid_connection_type'] | null;
  control: Control<ContractForm>;
  readOnly: boolean;
  watch: UseFormWatch<ContractForm>;
}) => {
  const gridConnectionType = watch('installation_attributes.grid_connection_type', gridConnectionTypeDefaultValue);

  return (
    <div className={sectionStyles.container}>
      <div className={sectionStyles.row}>
        <ListInput
          control={control}
          name="installation_attributes.grid_connection_type"
          options={[
            { label: 'sales_management.hta_5', value: 'high_voltage' },
            { label: 'sales_management.low_voltage_above_36_4', value: 'low_voltage_above_36kVA' },
          ]}
          defaultValue={gridConnectionTypeDefaultValue}
          label="sales_management.injection_connection"
          size="lg"
          readOnly={readOnly}
        />
        <ListInput
          control={control}
          name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.grid_access_tariff.option"
          label="sales_management.enedis_tariff_option"
          disabled={!gridConnectionType}
          options={[
            {
              label: gridConnectionType === 'high_voltage' ? 'sales_management.cu_fixed' : 'sales_management.cu',
              value: gridConnectionType === 'high_voltage' ? 'cu_fixe' : 'cu_mobile',
            },
            {
              label: gridConnectionType === 'high_voltage' ? 'sales_management.lu_fixed' : 'sales_management.lu',
              value: gridConnectionType === 'high_voltage' ? 'lu_fixe' : 'lu_mobile',
            },
          ]}
          size="lg"
          readOnly={readOnly}
        />
      </div>
      <div className={sectionStyles.row}>
        <ListInput
          control={control}
          name="installation_attributes.grid_access_type"
          label="sales_management.grid_access_contract"
          options={[
            { label: 'sales_management.injection_withdrawal', value: 'injection_and_withdrawal' },
            { label: 'sales_management.withdrawal', value: 'withdrawal' },
          ]}
          size="lg"
          readOnly={readOnly}
        />
        <div>
          <TableLabelContainer label="sales_management.variable.subscribed_witdrawal_capacity">
            {gridConnectionType === 'high_voltage' && (
              <PeriodNumericInput
                control={control}
                name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.grid_access_tariff.P"
                label="sales_management.variable.peak"
                readOnly={readOnly}
              />
            )}
            <PeriodNumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.grid_access_tariff.HPH"
              label="sales_management.variable.hph"
              readOnly={readOnly}
            />
            <PeriodNumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.grid_access_tariff.HCH"
              label="sales_management.variable.hch"
              readOnly={readOnly}
            />
            <PeriodNumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.grid_access_tariff.HPB"
              label="sales_management.variable.hpb"
              readOnly={readOnly}
            />
            <PeriodNumericInput
              control={control}
              name="contract_sub_periods_attributes.0.extra_params.supply_auxiliaries.grid_access_tariff.HCB"
              label="sales_management.variable.hcb"
              readOnly={readOnly}
            />
          </TableLabelContainer>
        </div>
      </div>
    </div>
  );
};

const TurpeSupplyOnly = ({
  gridConnectionTypeDefaultValue,
  control,
  readOnly,
}: {
  gridConnectionTypeDefaultValue: Installation['grid_connection_type'] | null;
  control: Control<ContractForm>;
  readOnly: boolean;
}) => {
  return (
    <div className={sectionStyles.container}>
      <div className={sectionStyles.row}>
        <ListInput
          control={control}
          name="installation_attributes.grid_connection_type"
          options={[
            { label: 'sales_management.hta_5', value: 'high_voltage' },
            { label: 'sales_management.low_voltage_above_36_4', value: 'low_voltage_above_36kVA' },
          ]}
          defaultValue={gridConnectionTypeDefaultValue}
          label="sales_management.injection_connection"
          size="lg"
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

const PeriodNumericInput = ({
  name,
  control,
  label,
  readOnly,
}: {
  name: FieldPath<ContractForm>;
  control: Control<ContractForm>;
  label: string;
  readOnly: boolean;
}) => {
  return (
    <TableLabel
      label={label}
      name={name}
      renderInput={(className) => (
        <NumericInput
          id={name}
          control={control}
          name={name}
          suffix="kW"
          readOnly={readOnly}
          inline
          full={false}
          className={className}
          rules={{ required: true }}
        />
      )}
    />
  );
};
