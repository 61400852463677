import chunk from 'lodash/chunk';

/**
 * This function validates a SIREN number.
 * Spec: https://portal.hardis-group.com/doccenter/pages/viewpage.action?pageId=120357227
 * @param input SIREN number
 * @returns true if the SIREN is valid, otherwise an error message
 */
export function validateSiren(input: string | number): true | string {
  const siren = `${input}`;

  if (!/^[0-9]{9}$/.test(siren)) return 'sales_management.siren_wrong_size_error';

  let sum = 0;

  // Luhn algorithm
  chunk(siren, 2).forEach(([a, b], index, array) => {
    const even = Number(b) * 2;
    const odd = Number(a);

    const lastPair = index === array.length - 1;

    // last pair is only one digit, because siren is 9 digits long
    if (!lastPair) sum += (even > 9 ? even - 9 : even) + odd;
    else sum += odd;
  });

  return sum % 10 ? 'sales_management.siren_pattern_error' : true;
}
