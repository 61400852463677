import { getBooks } from '@api/books';
import { useQuery, QueryClient } from '@tanstack/react-query';
import type { ReqError } from '@utils/fetch';
import Book, { BookType } from '@utils/types/book';

const useBooks = (options?: { bookType?: BookType; queryClient?: QueryClient }) => {
  const bookType = options?.bookType;
  const query = useQuery<Book[], ReqError>(
    {
      queryKey: ['books'],
      queryFn: () => getBooks(bookType),
    },
    options?.queryClient,
  );

  return query;
};

export default useBooks;
