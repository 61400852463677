import { BadgeProps } from '@GDM/Badge';
import { Subscription } from '@utils/types/subscription';

export const FREQUENCY_VARIANT_MAPPING: Record<Subscription['time_period'], BadgeProps['variant']> = {
  monthly: 'primary-2',
  yearly: 'primary-1',
  one_time: 'default',
};

export const MODULE_VARIANT_MAPPING: Record<Subscription['product'], BadgeProps['variant']> = {
  monitoring: 'primary-2',
  monitoring_salesfocus: 'light-blue',
  salesfocus: 'primary-1',
  pricing: 'danger',
  load_forecasting: 'orange',
  generation_forecasting: 'light-blue',
  third_party_access: 'orange',
  other: 'default',
};
