import React, { useState, useMemo, useContext } from 'react';
import { CreateBookModal } from '@components/ui/books/CreateBookModal';
import { useTabs } from '@GDM/Tabs';
import { Simulation } from '@utils/types/simulation';
import BooksFilters from './Filters/BooksFilters';
import SimulationsFilters from './Filters/SimulationsFilters';
import SelectedRowsContext from './state/selectedRows';
import { SimulationsActions } from './table/SimulationsActions';
import SimulationsTable from './table/SimulationsTable';

const getSimulations = (
  activeTab: string | number | undefined,
  MarketSimulations: Simulation[],
  fullMerchantSimulations: Simulation[],
  bookSimulations: Simulation[],
) => {
  if (activeTab === 'simulations') {
    return MarketSimulations;
  }

  if (activeTab === 'full_merchant_simulations') {
    return fullMerchantSimulations;
  }

  if (activeTab === 'book_simulations') {
    return bookSimulations;
  }

  return [];
};

// sort simulations by created_at
const sortSimulations = (simulations: Simulation[]): Simulation[] => {
  return simulations.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
};

const SimulationsTabContent = ({
  marketSimulations,
  bookSimulations,
  fullMerchantSimulations,
}: {
  marketSimulations: Simulation[];
  bookSimulations: Simulation[];
  fullMerchantSimulations: Simulation[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentTab } = useTabs();
  const { selectedRows } = useContext(SelectedRowsContext);
  const [initialTab, setInitialTab] = useState(currentTab);

  const allSimulations = useMemo(
    () => sortSimulations(getSimulations(currentTab, marketSimulations, fullMerchantSimulations, bookSimulations)),
    [currentTab, marketSimulations, fullMerchantSimulations, bookSimulations],
  ); // we keep an array containing all simulations for filtering
  const [sims, setSims] = useState(allSimulations);

  const isBook = currentTab === 'book_simulations';
  const isFullMerchant = currentTab === 'full_merchant_simulations';

  const toggle = () => setIsOpen(!isOpen);

  if (initialTab !== currentTab) {
    setInitialTab(currentTab);
    setSims(allSimulations);
  }

  return (
    <>
      {!isBook && (
        <CreateBookModal
          toggle={toggle}
          isOpen={isOpen}
          selectedMeters={selectedRows.map((r) => r.installation_name)}
        />
      )}

      {isBook && <BooksFilters allSimulations={allSimulations} setSimulations={setSims} key={currentTab} />}
      {!isBook && <SimulationsFilters allSimulations={allSimulations} setSimulations={setSims} key={currentTab} />}

      {!isBook && <SimulationsActions simulations={allSimulations} toggle={toggle} />}

      <SimulationsTable simulations={sims} isBook={isBook} isFullMerchant={isFullMerchant} />
    </>
  );
};

export default SimulationsTabContent;
