import React, { useEffect, useMemo } from 'react';
import { sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import { ContractForm, PriceType } from '@utils/types/contract';
import { IT_PRICE_AREAS, SPOT_SOURCES } from '@utils/types/contract/constants/spot-sources';
import { CountryCode } from '@utils/types/countries';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { DateValidators } from '../../../hooks/useDateValidation';
import { PRICE_TYPE_OPTIONS_MAP } from '../constants/priceTypes';
import styles from '../sub-period.module.scss';

export function SwapItSubPeriod({
  index,
  onDatePeriodClick,
  preSelectedPeriod,
  customPeriods,
  form: { control, setValue },
  datesValidation,
  readOnly,
  currency,
  priceTypeOptions,
}: {
  index: number;
  readOnly: boolean;
  form: UseFormReturn<ContractForm>;
  onDatePeriodClick: (period: string, index: number) => void;
  preSelectedPeriod: string | undefined;
  customPeriods:
    | {
        name: string;
        dates: [Date, Date];
      }[]
    | undefined;
  currency: Currency;
  datesValidation: DateValidators;
  priceTypeOptions: Option<PriceType | null>[];
  country?: CountryCode | null;
}) {
  const { t } = useTranslation();

  const sourceOptions = useMemo(() => {
    return SPOT_SOURCES['IT']
      .map((source) => {
        let label: string = source;
        if (source === 'gme') label = t('monitoring.export.gme_regional');
        if (source === 'entsoe') label = t('monitoring.export.gme_regional');
        else label = label.toUpperCase();

        return {
          label,
          value: source,
        };
      })
      .filter(({ value }) => value === 'gme');
  }, [t]);

  const areaOptions = useMemo(() => {
    const areas = IT_PRICE_AREAS;

    return (
      areas.map((area) => ({
        label: area.replace('_', ' '),
        value: area,
      })) || []
    );
  }, []);

  useEffect(() => {
    setValue(`contract_sub_periods_attributes.${index}.extra_params.swap_scale_factor`, '1');
  }, [index, setValue]);

  return (
    <div className={styles.section}>
      <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
        <DateInput
          data-description="SubPeriodDatesPicker"
          name={`contract_sub_periods_attributes.${index}.dates`}
          control={control}
          rules={{ validate: datesValidation }}
          data-cy={`dates-picker-${index}`}
          selectedPeriod={preSelectedPeriod}
          selectsRange
          showPeriodBadge
          customPeriods={customPeriods}
          onClickPeriod={(period) => onDatePeriodClick(period, index)}
          readOnly={readOnly}
          label="sales_management.sub_period.start_end_dates"
        />
      </div>

      <div className="mb-3">
        <div className={styles['subtitle']}>Auction Price</div>
        <div className={sectionStyles.row}>
          <NumericInput
            data-description="SwapFixedPrice"
            control={control}
            rules={{ required: true, max: 10000 }}
            name={`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`}
            data-cy={`swap-fixed-price-input-${index}`}
            label="sales_management.swap_uk_fixed_price"
            suffix={`${getSymbol(currency)}/MWh`}
            max={10000}
            step={1}
            required
            readOnly={readOnly}
            helpBox={{ title: 'sales_management.swap_uk_fixed_price', text: 'sales_management.swap_uk_spot_info' }}
          />
          <ListInput
            data-description="PriceType"
            control={control}
            options={priceTypeOptions}
            name={`contract_sub_periods_attributes.${index}.price_type`}
            label="common.price_type"
            rules={{ required: true }}
            menuPlacement="top"
            readOnly={readOnly}
            classNamePrefix={`select-price-type-${index}`}
            defaultValue="indexed"
            isDisabled
          />
        </div>
      </div>

      <div className="mb-3">
        <div className={styles['subtitle']}>Market Reference</div>
        <div className={sectionStyles.row}>
          <ListInput
            data-description="SwapPriceTypePicker"
            name={`contract_sub_periods_attributes.${index}.extra_params.swap_price_type`}
            control={control}
            rules={{ required: true }}
            classNamePrefix={`select-swap-price-type-${index}`}
            label="sales_management.swap_price_type"
            readOnly={readOnly}
            menuPlacement="top"
            options={[PRICE_TYPE_OPTIONS_MAP.spot]}
            defaultValue="spot"
            isDisabled
          />
          <ListInput
            data-description="SourcePicker"
            name={`contract_sub_periods_attributes.${index}.price_model_attributes.source`}
            control={control}
            rules={{ required: true }}
            classNamePrefix={`select-source-${index}`}
            label="sales_management.price_model.source"
            readOnly={readOnly}
            menuPlacement="top"
            options={sourceOptions}
            defaultValue={sourceOptions[0].value}
            isDisabled
          />
          <ListInput
            data-description="PriceAreaPicker"
            control={control}
            readOnly={readOnly}
            options={areaOptions}
            rules={{ required: true }}
            defaultValue={areaOptions[0].value}
            label="sales_management.price_model.area"
            name={`contract_sub_periods_attributes.${index}.price_model_attributes.area`}
            classNamePrefix={`select-price-model-area-${index}`}
          />
        </div>
      </div>
    </div>
  );
}
