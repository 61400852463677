import React from 'react';
import { getContractIndices } from '@api/contracts';
import { styles } from '@GDM/Filters';
import { Table, TableHead, TableBody, NumberCell } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { useQuery } from '@tanstack/react-query';
import { useReactTable, getCoreRowModel, AccessorColumnDef } from '@tanstack/react-table';
import { getSymbol } from '@utils/currency/getSymbol';
import { IndexationPeriod } from '@utils/types/contract/indices';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';

export const AnnualIndexation = ({ currency, contractId }: { contractId: string; currency: Currency }) => {
  const { data } = useQuery({
    queryKey: ['contract annual indexation', contractId],
    queryFn: async () => {
      const indices = await getContractIndices(contractId);

      return indices.periods;
    },
  });

  const columns = useColumns(currency);

  if (!data) return null;

  return <AnnualIndexationTable rows={data} columns={columns} />;
};

const AnnualIndexationTable = ({
  columns,
  rows,
}: {
  rows: IndexationPeriod[];
  columns: AccessorColumnDef<IndexationPeriod>[];
}) => {
  const table = useReactTable({
    columns,
    data: rows,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className={classNames(styles.table, 'scroll-h')}>
      <TableHead table={table} />
      <TableBody table={table} />
    </Table>
  );
};

const useColumns = (currency: Currency): AccessorColumnDef<IndexationPeriod>[] => {
  const { t } = useTranslation();

  return [
    { header: 'common.start_date', accessorKey: 'start_date' },
    { header: 'common.end_date', accessorKey: 'end_date' },
    {
      header: () => (
        <>
          <span>{t('common.indexed_price')} </span>
          <span className="normal-case">{`(${getSymbol(currency)}/MWh)`}</span>
        </>
      ),
      accessorKey: 'price',
      cell: ({ getValue }) => <NumberCell getValue={getValue} fractionDigits={2} />,
    },
    {
      header: 'monitoring.invoicing.l_coef',
      accessorKey: 'l_coef',
      cell: ({ getValue }) => <NumberCell getValue={getValue} fractionDigits={5} />,
    },
    {
      header: 'contracts.index_work',
      cell: NumberCell,
      accessorFn: (row) => row.indices.find((index) => index.name === 'ICHTrevTS')?.value,
    },
    {
      header: 'contracts.index_prod',
      cell: NumberCell,
      accessorFn: (row) => row.indices.find((index) => index.name === 'FM0ABE0000')?.value,
    },
  ];
};
