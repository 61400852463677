import React from 'react';
import { Chart } from '@GDM/Chart';
import { Col } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';

type Props = {
  availabilities: [number, number][];
};

export const AvailabilityChart = ({ availabilities }: Props) => {
  const { t } = useTranslation();

  const series: Highcharts.Options['series'] = [
    {
      name: t('monitoring.unavailabilities.availability'),
      data: availabilities,
      type: 'line',
      color: 'var(--primary-2)',
      tooltip: {
        valueSuffix: ' MW',
      },
    },
  ];

  const options: Highcharts.Options = {
    time: {
      useUTC: false,
    },
    tooltip: {
      xDateFormat: '%Y-%m-%d %H:%M',
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: { title: { text: `${t('monitoring.unavailabilities.availability')} (MW)` }, opposite: true },
  };

  return (
    <Col>
      <Chart series={series} options={options} hideLegend />
    </Col>
  );
};
