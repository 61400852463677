import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { update_status_v2_meter_invoicing_index_path } from '@utils/routes';
import type { MeterInvoice, MeterInvoiceFinalStatus } from '@utils/types/meter-invoice';
import { InvoiceDestructiveActionButton } from './InvoiceDestructiveActionButton';

export const ReactivateInvoiceButton = ({ className, invoice }: { className?: string; invoice: MeterInvoice }) => {
  const { t } = useTranslation();

  const allowedStatuses: MeterInvoiceFinalStatus[] = ['pending', 'ignored'];

  if (!allowedStatuses.includes(invoice.status)) return null;

  return (
    <InvoiceDestructiveActionButton
      modalTitle="sales_management.reactivate_title"
      confirmationText={t('sales_management.confirm_reactivate', {
        newStatus: t('common.filters.invoice_status.created'),
      })}
      invoice={invoice}
      path={update_status_v2_meter_invoicing_index_path({
        params: { invoices: { ids: [invoice.uuid] }, event: 'reactivate' },
      })}
      method="patch"
      submitButtonInfo={t('sales_management.revert_to_created')}
      permissions={['display:edit_invoices']}
      data-cy="invoice-reactivate"
      className={className}
      icon="CornerUpLeft"
    />
  );
};
