import { createContext, useContext } from 'react';

export type SelectedYearContextType = {
  selectedYear: string | null;
  setSelectedYear: (year: string | null) => void;
};

export const selectedYearContext = createContext<SelectedYearContextType>({
  selectedYear: '',
  setSelectedYear: () => {},
});

export const useSelectedYear = () => useContext(selectedYearContext);
