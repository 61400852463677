import { fetchGrids } from '@api/grids';
import { useQuery } from '@tanstack/react-query';
import type { CountryCode } from '@utils/types/countries';

export const useGrids = (country?: CountryCode | null) => {
  return useQuery({
    queryKey: ['grids', country],
    queryFn: () => fetchGrids(country),
  });
};
