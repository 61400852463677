import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useDynamicOptions } from '@GDM/Filters';
import getContractTypeLabel from '@utils/contract/getLabel';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { Contract, ContractType } from '@utils/types/contract';
import { CountryCode } from '@utils/types/countries';
import { MeterInvoice } from '@utils/types/meter-invoice';
import uniqBy from 'lodash/uniqBy';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

const getOptions = (invoices: MeterInvoice[]) => {
  const contractTypes: { type: ContractType; country: CountryCode | null }[] = invoices
    .filter((invoice) => invoice.contract_type)
    .map((invoice) => ({ type: invoice.contract_type, country: invoice.installation_country }));

  const options: Option<Contract['type']>[] = [
    ...contractTypes
      .map((c) => ({
        label: getContractTypeLabel(c.type, c.country) || getContractTypeLabel(c.type, 'FR'),
        value: c.type,
      }))
      .sort((a, b) => sortOptionsByLabelAsc(a, b, true)),
  ];

  return uniqBy(options, 'value');
};

export const ContractTypeFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'contract_type', filteredInvoices, allInvoices);

  return (
    <ControlledSelect
      control={form.control}
      name="contract_type"
      isMulti
      options={options}
      placeholder="common.filters.contract_type"
      classNamePrefix="filter-installation-contract_type"
      isClearable
      inline
    />
  );
};
