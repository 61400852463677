import type { EnergyType } from '@utils/types/common-types';
import { TimeSeries } from '@utils/types/timeSeries';
import { ProductionTimeSeriesRecord } from '../../production.types';

/**
 * This hook calculates the performance ratio of solar installations.
 * The performance ratio is the ratio of the raw active energy production
 * to the potential energy.
 */
export const usePerformanceRatios = (data?: Partial<ProductionTimeSeriesRecord>, energy?: EnergyType) => {
  let performanceRatios = null;

  if (energy === 'solar') {
    performanceRatios =
      data?.potentialActiveEnergyProduction?.values.reduce<TimeSeries>((acc, [time, value], index) => {
        if (!data?.rawActiveEnergyProduction?.values?.[index]) return acc;

        return [...acc, [time, (100 * data?.rawActiveEnergyProduction.values[index][1]) / value]];
      }, []) ?? null;
  }

  return { performanceRatios };
};
