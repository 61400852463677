import axiosInstance from '@utils/axiosInstance';
import { v2_meter_path, v2_meters_path } from '@utils/routes';
import type { Meter } from '@utils/types/meter';

export type CreateUpdateMeterBody = Partial<Omit<Meter, 'country'>> & {
  installation_uuid?: string;
};

export const getMeters = async () => {
  return (await axiosInstance(false).get<Meter[]>(v2_meters_path())).data;
};

export const getMeterById = async (id?: Meter['id']) => {
  return (await axiosInstance(false).get<Meter>(v2_meter_path(id || ''))).data;
};

export const createMeter = async (body: CreateUpdateMeterBody) => {
  return (await axiosInstance(false).post<Meter>(v2_meters_path(), body)).data;
};

export const updateMeter = async (id: Meter['id'], body: CreateUpdateMeterBody) => {
  return (await axiosInstance(false).patch<Meter>(v2_meter_path(id), body)).data;
};

export const deleteMeter = async (id: Meter['id']) => {
  return axiosInstance(false).delete(v2_meter_path(id));
};
