import React, { useMemo } from 'react';
import { Tabs, type Tab } from '@GDM/Tabs';
import { tabsContext } from '@GDM/Tabs/tabs.context';
import Page from '@pages/Page';
import type { User } from '@utils/types/user';
import { NotificationsSettings } from './NotificationsSettings';

export const Alerts = ({ user }: { user: User }) => {
  const tabs = useMemo<Tab<'notifications' | 'emails'>[]>(() => {
    return [{ id: 'emails', name: 'admin.alerts.emails', permissions: ['index:user_notification_settings'] }];
  }, []);

  return (
    <Page title="common.alerts" user={user}>
      <Tabs tabs={tabs}>
        <tabsContext.Consumer>
          {({ currentTab }) => <>{currentTab === 'emails' && <NotificationsSettings />}</>}
        </tabsContext.Consumer>
      </Tabs>
    </Page>
  );
};
