import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Alert } from '@GDM/Alert';
import { NoWrapHeadCell, NumberCell, Table, TableBody, TableHead } from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { useCurrency } from '@utils/string/useCurrency';
import { ProcessedReactiveData } from '@utils/types/reactiveData';

export const ReactiveTable = ({ data }: { data: ProcessedReactiveData[] }) => {
  const { t } = useTranslation();
  const { locale } = useUser();
  const currency = useCurrency('code');

  const columns = useMemo<ColumnDef<ProcessedReactiveData>[]>(() => {
    const cols = [
      {
        header: () => <NoWrapHeadCell label="common.period" />,
        accessorKey: 'periodname',
        cell: ({ getValue }) => (
          <span className="tt:capitalize">
            {getValue() === 'last'
              ? t('common.current')
              : new Date(getValue<string>()).toLocaleString(locale, { month: 'long' })}
          </span>
        ),
      },
      {
        header: () => <NoWrapHeadCell tooltip="kWh" label="common.active" />,
        accessorKey: 'deltaidx',
        cell: ({ getValue }) => <NumberCell value={parseFloat(getValue<string>())} />,
      },
      {
        header: () => <NoWrapHeadCell tooltip="kVARh" label="monitoring.installation.reactive_plus" />,
        accessorKey: 'deltarp',
        cell: ({ getValue }) => <NumberCell value={parseFloat(getValue<string>())} />,
      },
      {
        header: () => <NoWrapHeadCell tooltip="kVARh" label="monitoring.installation.reactive_minus" />,
        accessorKey: 'deltarn',
        cell: ({ getValue }) => <NumberCell value={parseFloat(getValue<string>())} />,
      },
      {
        header: () => <NoWrapHeadCell label="common.tg_phi" />,
        id: 'tanphi',
        accessorKey: 'tanphi',
        cell: ({ getValue }) => <NumberCell value={Number(getValue<number>().toFixed(4)) ?? null} />,
      },
      {
        header: () => <NoWrapHeadCell tooltip="kVARh" label="common.in_opposition" />,
        accessorKey: 'opposition',
        cell: ({ getValue }) => <NumberCell value={getValue<ProcessedReactiveData['opposition']>()} />,
      },
      {
        header: () => <NoWrapHeadCell tooltip="kVARh" label="common.out_of_scope" />,
        id: 'hors_bandeau',
        accessorKey: 'horsbandeau',
        cell: ({ getValue }) => (
          <NumberCell value={Math.round(getValue<ProcessedReactiveData['horsbandeau']>()) ?? null} />
        ),
      },
      {
        header: () => <NoWrapHeadCell tooltip="kVARh" label="monitoring.installation.reactive_billable" />,
        id: 'energie_reactive_facturable',
        accessorKey: 'total_facture_kwh',
        cell: ({ getValue }) => (
          <NumberCell value={Math.round(getValue<ProcessedReactiveData['total_facture_kwh']>()) ?? null} />
        ),
      },
      {
        header: () => <NoWrapHeadCell tooltip={currency} label="monitoring.installation.rec_penalties" />,
        id: 'reactive_penalty',
        accessorKey: 'penalty',
        cell: ({ getValue }) => <NumberCell value={getValue<ProcessedReactiveData['penalty']>()?.toFixed(2) || null} />,
      },
    ] satisfies ColumnDef<ProcessedReactiveData>[];

    return cols;
  }, [t, locale, currency]);

  const table = useReactTable({
    columns,
    data,
    enableSorting: false,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const dataExists = Array.isArray(data) && data.length > 0;

  return (
    <div className="mt-3">
      {!dataExists && <Alert variant="warning" label="monitoring.installation.no_reactive_data" />}
      {dataExists && (
        <Table>
          <TableHead table={table} />
          <TableBody table={table} />
        </Table>
      )}
    </div>
  );
};
