import { useMemo } from 'react';
import {
  GreenstarReportData,
  GreenstarReportDataWithOneFocus,
  GreenstarFocusData,
  GreenstarReportMetricValues,
  GreenstarReportDataWithTwoFocus,
} from '@pages/RiskAnalysis/types';

export const useFormatReportData = (
  rawData: GreenstarReportData,
  isReportNested: boolean,
  isSplitByMetric: boolean,
) => {
  return useMemo(
    () => (isSplitByMetric ? createMetricBasedReportData(rawData, isReportNested) : rawData),
    [isReportNested, isSplitByMetric, rawData],
  );
};

const createMetricBasedReportData = (data: GreenstarReportData, isReportNested: boolean) => {
  if (!isReportNested) {
    const metricBasedReport: GreenstarReportDataWithOneFocus = {};
    Object.entries(data).forEach(([focus, metricValuesForEachDates]: [string, GreenstarFocusData]) => {
      Object.entries(metricValuesForEachDates).forEach(
        ([date, metricValues]: [string, GreenstarReportMetricValues]) => {
          Object.entries(metricValues).forEach(([metric, value]) => {
            if (!metricBasedReport[metric]) metricBasedReport[metric] = {};
            if (!metricBasedReport[metric][date]) metricBasedReport[metric][date] = {};
            metricBasedReport[metric][date][focus] = value;
          });
        },
      );
    });

    return metricBasedReport;
  }

  const nestedMetricBasedReport: GreenstarReportDataWithTwoFocus = {};
  Object.entries(data).forEach(([focus1, dataAlongSecondaryFocus]: [string, GreenstarReportDataWithOneFocus]) => {
    Object.entries(dataAlongSecondaryFocus).forEach(
      ([focus2, metricValuesForEachDates]: [string, GreenstarFocusData]) => {
        Object.entries(metricValuesForEachDates).forEach(
          ([date, metricValues]: [string, GreenstarReportMetricValues]) => {
            Object.entries(metricValues).forEach(([metric, value]) => {
              if (!nestedMetricBasedReport[metric]) nestedMetricBasedReport[metric] = {};
              if (!nestedMetricBasedReport[metric][focus1]) nestedMetricBasedReport[metric][focus1] = {};
              if (!nestedMetricBasedReport[metric][focus1][date]) nestedMetricBasedReport[metric][focus1][date] = {};

              nestedMetricBasedReport[metric][focus1][date][focus2] = value;
            });
          },
        );
      },
    );
  });

  return nestedMetricBasedReport;
};
