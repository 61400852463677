import { VisibilityState } from '@tanstack/react-table';

// TODO: if the column list is refactored into a readonly object,
// we could use it to pick out the keys and constrain this object better.
const COLUMNS_THAT_CAN_BE_TOGGLED = [
  'volume_source',
  'volume_first_index',
  'volume_last_index',
  'volume_first_conso_index',
  'volume_last_conso_index',
  'volume_delta_prod_vs_index',
  'volume_delta_prod_vs_load_curve',
  'volume_delta_prod_vs_device',
  'scada',
  'volume_coverage',
  'volume_consumption',
  'updated_at',
] as const;

export function getVisibilityState(state: boolean): VisibilityState {
  return COLUMNS_THAT_CAN_BE_TOGGLED.reduce((acc, key) => {
    acc[key] = state;

    return acc;
  }, {} as VisibilityState);
}
