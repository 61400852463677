import { ReportMetricDataItem, ReportMetric } from '@pages/CustomReporting/types';
import { CustomReportProps } from '../CustomReport';
import { MetricChartData, IndividualSeries } from './types';

export const prepareChartData = (
  items: ReportMetricDataItem[],
  metric: ReportMetric,
  query: CustomReportProps['query'],
): Pick<MetricChartData, 'categories' | 'series'> => {
  const isTimeBased = query.date_split !== 'full';

  if (isTimeBased) {
    const series = items.map<IndividualSeries>(({ name, data }) => ({
      type: metric === 'unit_price' ? 'line' : 'column',
      name,
      data: data.map(({ value }) => Number(value)),
    }));

    return {
      categories: items[0].data.map(({ date }) => date || ''),
      series,
    };
  }

  const initialChartData: { categories: string[]; series: Record<string, (number | null)[]> } = {
    categories: [],
    series: {},
  };

  const { categories, series } = items.reduce(({ categories, series }, { name: itemName, data, nested_data }) => {
    return {
      categories: [...categories, itemName],
      series: nested_data
        ? reduceNestedDataIntoSeriesObject(nested_data, series)
        : {
            ...series,
            [metric]: [...(series[metric] || []), ...data.map(({ value }) => Number(value))],
          },
    };
  }, initialChartData);

  return {
    categories,
    series: Object.entries(series).map(([name, data]) => ({
      type: 'column',
      name,
      data,
    })),
  };
};

type SeriesObject = Record<string, (number | null)[]>;

export const reduceNestedDataIntoSeriesObject = (
  nested_data: Required<ReportMetricDataItem>['nested_data'],
  series: SeriesObject,
): SeriesObject => {
  const setOfNestedKeys: Set<string> = new Set(nested_data.map(({ name }) => name));

  const obj = nested_data.reduce(
    (obj, { name: subItemName, data }) => ({
      ...obj,
      [subItemName]: [...(obj[subItemName] || []), ...data.map(({ value }) => Number(value))],
    }),
    series,
  );

  const res = Object.entries(obj).reduce(
    (obj, [currentName, currentData]) =>
      setOfNestedKeys.has(currentName) ? obj : { ...obj, [currentName]: [...currentData, null] },
    obj,
  );

  return res;
};
