import { useCallback, useState } from 'react';

export function useArray<T>(defaultArray: T[]): [T[], (args: T[] | T) => void] {
  const [array, setArray] = useState<T[]>(defaultArray || []);

  const push = useCallback(
    (args: T[] | T) => {
      const clonedArray = [...array];

      if (Array.isArray(args)) {
        clonedArray.push(...args);
      } else {
        clonedArray.push(args);
      }

      setArray(clonedArray);
    },
    [array],
  );

  return [array, push];
}
