import { createContext, Dispatch, SetStateAction } from 'react';

export type InstallationInfoContextType = {
  lat: number | null;
  setLat?: Dispatch<SetStateAction<InstallationInfoContextType['lat']>>;
  lng: number | null;
  setLng?: Dispatch<SetStateAction<InstallationInfoContextType['lng']>>;
  client_id?: string | null;
  setClientId?: Dispatch<SetStateAction<NonNullable<InstallationInfoContextType['client_id']> | null>>;
  erpCode?: string;
  setErpCode?: Dispatch<SetStateAction<InstallationInfoContextType['erpCode']>>;
  siret: string | null;
  setSiret?: Dispatch<SetStateAction<InstallationInfoContextType['siret']>>;
};

export const installationInfoContext = createContext<InstallationInfoContextType>({
  lat: null,
  lng: null,
  client_id: '',
  siret: null,
});
