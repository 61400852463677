import React from 'react';
import { Alert } from '@GDM/Alert';
import { AxiosResponseLoadingState } from '@utils/types/useAxios';

const RequestStatusMessage = ({ status, children }: React.PropsWithChildren<{ status: AxiosResponseLoadingState }>) => {
  if (status === 'LOADED') {
    return (
      <Alert size="sm" variant="success" className="mb-2" dismissible>
        {children}
      </Alert>
    );
  }

  return null;
};

export default RequestStatusMessage;
