import React from 'react';
import { ValueCard } from '@GDM/Card';
import { Colon } from '@GDM/Colon';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';

type EnergyProductionWidgetProps = {
  total: number | undefined;
  consumption: number | undefined;
  average: number | undefined;
};

export const EnergyProductionWidget = ({ total, consumption, average }: EnergyProductionWidgetProps) => {
  const { t } = useTranslation();

  return (
    <ValueCard title="common.energy" value={total} unit="kWh">
      <div>
        {t(consumption ? 'common.mean_production' : 'common.mean')}
        <Colon />
        &nbsp;
        <ValueWithUnit value={average} unit="kW" />
      </div>
      {!!consumption && (
        <div>
          {t('monitoring.installation.consumption')}
          <Colon />
          &nbsp;
          <ValueWithUnit value={consumption} unit="kWh" />
        </div>
      )}
    </ValueCard>
  );
};
