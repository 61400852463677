import React from 'react';
import { SourceCell, StatusCell, TypeCell } from '@components/invoices/Table/Cells';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { Button } from '@GDM/Button';
import { CoverageCell, DateCell, HeadCell, NoWrapHeadCell, NumberCell } from '@GDM/Table';
import { tString } from '@hooks/useTranslation';
import { ColumnDef } from '@tanstack/react-table';
import absoluteSortTable from '@utils/absoluteSortTable';
import { getNumberColor } from '@utils/getNumberColor';
import { Locale } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { TabId } from '../invoices.types';
import { LoadcurveCell } from './LoadcurveCell';

type GetColumnsOptions = {
  locale: Locale;
  inModal?: boolean;
  onOpenModal?: (props: MeterInvoice) => void;
};

export const getColumns = (type: TabId, options: GetColumnsOptions): ColumnDef<MeterInvoice>[] => {
  const t = tString(options.locale);
  const commonColumns: ColumnDef<MeterInvoice>[] = [
    {
      header: 'monitoring.invoicing.source',
      cell: SourceCell,
    },
    {
      accessorKey: 'volume_production',
      header: () => <HeadCell tooltip="kWh" label="monitoring.invoicing.prod_billable" />,
      cell: (props) => <NumberCell {...props} />,
    },
  ] satisfies ColumnDef<MeterInvoice>[];

  const STATUS_CELL: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_status',
    header: 'common.status_label',
    cell: StatusCell,
  };

  const FIRST_CONSO_INDEX_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'First conso index',
    accessorKey: 'volume_first_conso_index',
    cell: (props) => <NumberCell {...props} />,
  };
  const LAST_CONSO_INDEX_COLUMN: ColumnDef<MeterInvoice> = {
    header: 'Last conso index',
    accessorKey: 'volume_last_conso_index',
    cell: (props) => <NumberCell {...props} />,
  };

  const CONSUMPTION_COLUMN: ColumnDef<MeterInvoice> = {
    accessorKey: 'volume_consumption',
    header: () => <HeadCell tooltip="kWh" label="monitoring.invoicing.consum" />,
    cell: (props) => <NumberCell {...props} />,
  };

  const indexColumns: ColumnDef<MeterInvoice>[] = [
    ...commonColumns,
    {
      header: 'First index',
      accessorKey: 'volume_first_index',
      cell: (props) => <NumberCell {...props} />,
    },
    {
      header: 'Last index',
      accessorKey: 'volume_last_index',
      cell: (props) => <NumberCell {...props} />,
    },
    CONSUMPTION_COLUMN,
    FIRST_CONSO_INDEX_COLUMN,
    LAST_CONSO_INDEX_COLUMN,
    { header: 'Start Date', accessorKey: 'start_date' },
    { header: 'End Date', accessorKey: 'end_date' },
    STATUS_CELL,
  ];

  const loadcurveColumns = [
    ...commonColumns,
    {
      accessorKey: 'volume_delta_prod_vs_index',
      header: 'Δ Index',
      cell: ({ getValue, row }) => {
        const productionIndex = row?.original?.volume_production_index?.toLocaleString() || 0;
        const tooltip = `${t('monitoring.invoicing.prod_from_indexes')}:<br /> ${productionIndex} kWh`;
        const value = getValue<MeterInvoice['volume_delta_prod_vs_index']>();

        return <NumberCell color={getNumberColor(value)} value={value} tooltip={tooltip} />;
      },
      sortingFn: (rowA, rowB, columnId) => absoluteSortTable(rowA, rowB, columnId),
    },
    {
      accessorKey: 'volume_delta_prod_vs_load_curve',
      header: 'monitoring.invoicing.raw_curve',
      cell: LoadcurveCell,
      sortingFn: (rowA, rowB, columnId) => absoluteSortTable(rowA, rowB, columnId),
    },
    {
      accessorKey: 'volume_coverage',
      id: 'volume_coverage',
      cell: CoverageCell,
      header: () => <NoWrapHeadCell label="monitoring.invoicing.dispo_enedis" />,
    },
    {
      accessorKey: 'volume_raw_coverage',
      header: 'monitoring.invoicing.dispo_enedis_raw',
      cell: CoverageCell,
    },
    CONSUMPTION_COLUMN,
    STATUS_CELL,
    {
      accessorKey: 'invoicing_type',
      header: 'type',
      cell: TypeCell,
    },
    {
      accessorKey: 'volume_updated_at',
      header: 'monitoring.invoicing.update',
      cell: ({ row: { original } }) => (
        <DateCell
          value={original.volume_updated_at > original.updated_at ? original.volume_updated_at : original.updated_at}
        />
      ),
    },
  ] satisfies ColumnDef<MeterInvoice>[];

  let columns = [];

  if (type === 'load_curve') {
    columns = loadcurveColumns;
  } else {
    columns = indexColumns;
  }

  if (!options?.inModal) {
    columns.unshift({
      accessorKey: 'installation_name',
      header: 'common.installation',
      cell: ({ row: { original } }) => (
        <MeterNameCell
          installation_name={original.installation_name}
          installation_energy={original.installation_energy}
          client_id={original.installation_client_id}
        />
      ),
    } satisfies ColumnDef<MeterInvoice>);
  }

  if (!options?.inModal && options?.onOpenModal) {
    const ActionCol: ColumnDef<MeterInvoice> = {
      id: 'actions',
      cell: (props) => <Button floating onClick={() => options.onOpenModal?.(props.row.original)} icon="Eye" />,
    };

    columns.push(ActionCol);
  }

  return columns;
};
