import { useCallback } from 'react';
import { ContractForm, ContractSubPeriodForm } from '@utils/types/contract';
import dayjs from 'dayjs';
import { UseFormReturn, Validate } from 'react-hook-form';

export type DateValidators = Record<string, Validate<ContractSubPeriodForm['dates'], ContractForm>>;

export function useDateValidation(
  { getValues }: UseFormReturn<ContractForm>,
  contractStartDate: Date | null,
  contractEndDate: Date | null,
): (index: number) => DateValidators {
  const validateDates = useCallback(
    (index: number) => {
      return {
        noValues: (dates: ContractSubPeriodForm['dates']) => {
          const start_date = dates?.[0];
          const end_date = dates?.[1];

          return !!(start_date && end_date) || 'sales_management.sub_period_errors.no_values';
        },
        overlapping: (dates: ContractSubPeriodForm['dates']) => {
          const end_date = dates?.[1];
          const nextSubPeriod = getValues(`contract_sub_periods_attributes.${index + 1}`);
          if (!nextSubPeriod) return true;

          const endDate = dayjs(end_date).endOf('day').toDate().valueOf();
          const nextPeriodStartDate = dayjs(nextSubPeriod.dates?.[0]).startOf('day').toDate().valueOf();

          return endDate < nextPeriodStartDate || 'sales_management.sub_period_errors.ends_after_next_starts';
        },
        gapBetweenPeriods: (dates: ContractSubPeriodForm['dates']) => {
          const nextSubPeriod = getValues(`contract_sub_periods_attributes.${index + 1}`);
          if (!nextSubPeriod) return true;

          const expectedStartOfNextPeriod = dayjs(dates?.[1]).add(1, 'day').startOf('day').toDate().valueOf();
          const actualStartOfNextPeriod = dayjs(nextSubPeriod.dates?.[0]).startOf('day').toDate().valueOf();

          return (
            expectedStartOfNextPeriod === actualStartOfNextPeriod ||
            'sales_management.sub_period_errors.missing_time_between_this_period_and_next'
          );
        },
        beforeStartDate: (dates: ContractSubPeriodForm['dates']) => {
          // Make sure we have exact matches for the currentSubPeriod (could be same day but different hours)
          const startDay = dayjs(dates?.[0]).startOf('day').toDate().valueOf();
          const contractStartDay = dayjs(contractStartDate).startOf('day').toDate().valueOf();

          return startDay >= contractStartDay || 'sales_management.sub_period_errors.before_start_date';
        },
        afterEndDate: (dates: ContractSubPeriodForm['dates']) => {
          // Make sure we have exact matches for the currentSubPeriod (could be same day but different hours)
          const endDay = dayjs(dates?.[1]).endOf('day').toDate().valueOf();
          const contractEndDay = dayjs(contractEndDate).endOf('day').toDate().valueOf();

          return endDay <= contractEndDay || 'sales_management.sub_period_errors.after_end_date';
        },
        outOfBounds: (dates: ContractSubPeriodForm['dates']) => {
          const start_date = dates?.[0];
          const end_date = dates?.[1];

          // Make sure we have exact matches for the currentSubPeriod (could be same day but different hours)
          const startDate = dayjs(start_date).startOf('day').toDate().valueOf();
          const endDate = dayjs(end_date).endOf('day').toDate().valueOf();

          return startDate < endDate || 'sales_management.sub_period_errors.out_of_bounds';
        },
        timeMissingAtStart: (dates: ContractSubPeriodForm['dates']) => {
          if (index > 0) return true;

          const startDay = dayjs(dates?.[0]).startOf('day').toDate().valueOf();
          const contractStartDay = dayjs(contractStartDate).startOf('day').toDate().valueOf();

          return startDay === contractStartDay || 'sales_management.sub_period_errors.missing_time_at_beginning';
        },
        timeMissingAtEnd: (dates: ContractSubPeriodForm['dates']) => {
          const subPeriods = getValues('contract_sub_periods_attributes');
          if (subPeriods && index !== subPeriods.length - 1) return true;

          const endDay = dayjs(dates?.[1]).endOf('day').toDate().valueOf();
          const contractEndDay = dayjs(contractEndDate).endOf('day').toDate().valueOf();

          return endDay === contractEndDay || 'sales_management.sub_period_errors.missing_time_at_end';
        },
      };
    },
    [contractEndDate, contractStartDate, getValues],
  );

  return validateDates;
}
