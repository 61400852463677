import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import tableBodyStyles from '../TableBody/table-body.module.scss';
import styles from './table-footer.module.scss';

export function TableFooter<T extends Record<string, unknown>>({ table }: { table: Table<T> }): JSX.Element {
  const { t } = useTranslation();

  return (
    // The table footer will look better if its style is consistant with the table body
    <tfoot className={tableBodyStyles['table-body']}>
      {/* The empty row is only here to avoid having the first footer cell with no top border */}
      <tr />
      {table.getFooterGroups().map((footerGroup, index) => {
        return (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((footer) => {
              const footerText =
                typeof footer.column.columnDef.footer === 'string'
                  ? t(footer.column.columnDef.footer)
                  : footer.column.columnDef.footer;

              return (
                <td
                  role="cell"
                  key={footer.id}
                  className={classNames(tableBodyStyles.cell, index === 0 && styles['thick-border-top'])}
                >
                  {flexRender(footerText, footer.getContext())}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tfoot>
  );
}
