import React from 'react';
import { useUser } from '@context/User.context';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import { DataWidget } from '@ui/DataWidget';
import { getLocalizedMonthList } from '@utils/getLocalizedMonthList';
import { getSmartTranslation } from '@utils/getSmartTranslation';
import { isANumber } from '@utils/isANumber';
import { Card, Col, Row } from 'reactstrap';
import PieChart from '../charts/PieChart';
import { Results } from '../Results';

const YearlyResults = ({ printable }: { printable?: boolean }) => {
  const { locale } = useUser();
  const { t } = useTranslation();
  const user = useUser();
  const { ytd, selectedDate, totalProductionForSelectedYear, currency } = useRevenueReportingContext();

  if (!ytd) return <></>;

  const {
    aggregation_fee_unit_price,
    business_plan_delta_production,
    hedge_production,
    hedge_unit_price,
    market_revenue,
    negative_price_production,
    subsidy_revenue,
    total_production,
    total_revenue,
  } = ytd;

  const totalProduction = total_production?.value;
  const businessPlanDelta = business_plan_delta_production?.value;
  const averageUnitPrice =
    isANumber(total_revenue?.value) && isANumber(total_production?.value)
      ? (total_revenue?.value * 1000) / total_production?.value
      : null;
  const hedgeAmount =
    isANumber(hedge_production?.value) && selectedDate
      ? (hedge_production?.value / (selectedDate?.getMonth() + 1)) * 1000
      : null; // Average (number of months since Jan)
  const hedgePrice = hedge_unit_price?.value;
  const totalRevenue = total_revenue?.value;
  const marketRevenue = market_revenue?.value;
  const subsidyRevenue = subsidy_revenue?.value;
  const negativePriceProduction = negative_price_production?.value;
  const averageAggregatorFees = isANumber(aggregation_fee_unit_price?.value)
    ? aggregation_fee_unit_price?.value * -1000
    : null; // €c/MWh

  const monthNames = getLocalizedMonthList(locale);

  const startOfSelectedYear = monthNames[0];
  const endOfSelectedYearSoFarIndex =
    selectedDate?.getFullYear() !== new Date().getFullYear()
      ? 11
      : new Date().getMonth() > 1
      ? new Date().getMonth() - 1
      : 0;
  const endOfSelectedYearSoFar = monthNames[endOfSelectedYearSoFarIndex];

  if (printable) {
    return (
      <Row className="reports-container reports-container-yearly">
        <Col md={7} className="reports-values">
          <div className="d-flex flex-wrap justify-content-between h-100">
            <div className="DataWidgetContainerStylesTop">
              <DataWidget
                printable={printable}
                title="sales_management.portfolio.production_ytd"
                value={totalProduction}
                unit="kWh"
                subTitle={{
                  value: businessPlanDelta,
                  unit: 'kWh',
                  label: 'Δ BP',
                  valueColor: businessPlanDelta && businessPlanDelta > 0 ? 'green' : 'red',
                }}
              >
                {!!endOfSelectedYearSoFarIndex && (
                  <div className="text-small">
                    (
                    {t('sales_management.production_for_year', {
                      start: startOfSelectedYear,
                      end: endOfSelectedYearSoFar,
                    })}
                    &nbsp;
                    <ValueWithUnit value={totalProductionForSelectedYear} unit="kWh" />)
                  </div>
                )}
              </DataWidget>
            </div>

            <div className="DataWidgetContainerStylesTop">
              <DataWidget
                printable={printable}
                title="sales_management.prod_p0_ytd"
                value={negativePriceProduction}
                unit="kWh"
              />
            </div>

            <div className="DataWidgetContainerStylesBottom">
              <DataWidget
                printable={printable}
                title={getSmartTranslation(user, 'sales_management.average_sale_price')}
                value={averageUnitPrice}
                unit={`${currency}/MWh`}
              />
            </div>

            {hedgeAmount && (
              <div className="DataWidgetContainerStylesTop">
                <DataWidget printable={printable} title="sales_management.hedge_volume" value={hedgeAmount} unit="kW" />
              </div>
            )}

            {hedgePrice && (
              <div className="DataWidgetContainerStylesBottom">
                <DataWidget
                  printable={printable}
                  title="sales_management.hedge_average_price"
                  value={hedgePrice}
                  unit={`${currency}/MWh`}
                />
              </div>
            )}

            <div className="DataWidgetContainerStylesBottom">
              <DataWidget
                printable={printable}
                title="sales_management.average_aggreg_price"
                value={averageAggregatorFees}
                unit={`${currency}/MWh`}
              />
            </div>
          </div>
        </Col>

        <Col md={5} className="reports-chart">
          <Card className="cardStyles">
            <PieChart
              total_revenues={totalRevenue}
              market_revenues={marketRevenue}
              subsidies_revenues={subsidyRevenue}
              printable={printable}
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Results
      data-cy="yearly-results"
      prod={
        <DataWidget
          title="sales_management.portfolio.production_ytd"
          value={totalProduction}
          unit="kWh"
          subTitle={
            isANumber(businessPlanDelta)
              ? {
                  label: 'Δ BP',
                  value: businessPlanDelta,
                  unit: 'kWh',
                  valueColor: businessPlanDelta > 0 ? 'green' : 'red',
                  tooltip: 'sales_management.reports.bp_tooltip',
                }
              : undefined
          }
        >
          {!!endOfSelectedYearSoFarIndex && (
            <div className="text-small" data-cy="prod-so-far">
              (
              {t('sales_management.production_for_year', {
                start: startOfSelectedYear,
                end: endOfSelectedYearSoFar,
              })}
              &nbsp;
              <ValueWithUnit value={totalProductionForSelectedYear} unit="kWh" />)
            </div>
          )}
        </DataWidget>
      }
      prodP0={
        <DataWidget
          title={t('sales_management.prod_p0_ytd', {
            date: selectedDate?.getFullYear(),
          })}
          value={negativePriceProduction}
          unit="kWh"
        />
      }
      price={
        <DataWidget
          title={getSmartTranslation(user, 'sales_management.average_sale_price')}
          value={averageUnitPrice}
          unit={`${currency}/MWh`}
        />
      }
      hedge_quantity={
        hedgeAmount ? <DataWidget title="sales_management.hedge_volume" value={hedgeAmount} unit="kW" /> : null
      }
      hedge_price={
        hedgePrice ? (
          <DataWidget title="sales_management.hedge_average_price" value={hedgePrice} unit={`${currency}/MWh`} />
        ) : null
      }
      aggregPrice={
        <DataWidget
          title="sales_management.average_aggreg_price"
          value={averageAggregatorFees}
          unit={`${currency}/MWh`}
        />
      }
      chart={
        <PieChart total_revenues={totalRevenue} market_revenues={marketRevenue} subsidies_revenues={subsidyRevenue} />
      }
    />
  );
};

export default YearlyResults;
