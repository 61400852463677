import React from 'react';
import { Text } from '@GDM/Text';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import classNames from 'classnames';
import { CannibalizationImpactDeviationChart } from './CannibalizationImpactDeviationChart';
import styles from './charts.module.scss';
import { HourlyAnalysisChart } from './HourlyAnalysisChart';

export const Charts = () => {
  const project = useProjectContext();
  const { hourly_shape } = project?.result || {};

  return (
    <div className="mt-3">
      <Text text="pricing.project.cannibalization_analysis" className={classNames(styles['chart-title'], 'mb-1')} />
      <div className={styles['charts-container']}>
        <CannibalizationImpactDeviationChart percentage />
        <CannibalizationImpactDeviationChart />
      </div>

      {hourly_shape?.difference?.length && hourly_shape?.meter?.length && hourly_shape?.national?.length ? (
        <>
          <Text text="pricing.project.hourly_analysis" className={classNames(styles['chart-title'], 'mt-3 mb-1')} />
          <div className={styles['charts-container']}>
            <HourlyAnalysisChart />
          </div>
        </>
      ) : null}
    </div>
  );
};
