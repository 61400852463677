import React from 'react';
import { ValueCard } from '@GDM/Card';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './cards.module.scss';

export const NationalCapturePriceYearCard = () => {
  const project = useProjectContext();

  const { national_q50_factor } = project?.full_merchant?.full_merchant_result || {};
  const { t } = useTranslation();
  const year = project?.full_merchant?.full_merchant_cannibalized_distribution_cat[1];

  return (
    <ValueCard
      title={t('pricing.project.full_merchant_national_quality_factor_year', { year })}
      value={national_q50_factor ?? null}
      unit="percentage"
      className={styles.card}
    />
  );
};
