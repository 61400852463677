import React from 'react';
import { useState } from 'react';
import { EventsFilters } from '@components/Events/EventsFilters';
import { EventsPageWrapper } from '@components/Events/EventsPageWrapper';
import { FilteredEventsProvider } from '@components/Events/FilteredEventsProvider';
import { Button } from '@GDM/Button';
import Page from '@pages/Page';
import { User } from '@utils/types/user';

type Props = {
  user: User;
  type?: 'monitoring' | 'sales';
};

const Events = ({ user, type }: Props) => {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);
  const hasMonitoringPermission = user.permissions.includes('display:view_monitoring');
  const hasSalesPermission = user.permissions.includes('display:view_salesfocus');

  return (
    <FilteredEventsProvider
      hasMonitoringPermission={hasMonitoringPermission}
      hasSalesPermission={hasSalesPermission}
      type={type}
    >
      <Page
        user={user}
        title="common.alerts"
        fixedHeightContainer
        layout="no-background"
        header={
          <div className="d-flex flex-column mb-3">
            <EventsFilters />
            <Button
              className="ml-auto"
              onClick={() => setInfoModalIsOpen(true)}
              size="sm"
              variant="primary-2"
              icon="HelpCircle"
              data-cy="events-email-settings"
            />
          </div>
        }
      >
        <EventsPageWrapper
          isPortfolio
          eventableId={null}
          eventableType={null}
          infoModalIsOpen={infoModalIsOpen}
          setInfoModalIsOpen={setInfoModalIsOpen}
        />
      </Page>
    </FilteredEventsProvider>
  );
};

export default Events;
