import React from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { useProjectContext } from '../utils/project.context';

export const DownloadCsv = () => {
  const project = useProjectContext();

  if (!project.result?.scenarios_url) return null;

  return (
    <Restricted permissions={['display:view_pricing']}>
      <Button href={project.result?.scenarios_url} variant="sub-button" text="common.csv" />
    </Restricted>
  );
};
