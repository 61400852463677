import React from 'react';
import { Card } from '@GDM/Card/Card';
import { AcceptableUnits } from '@GDM/ValueWithUnit';
import { TimeSeriesMinMaxAverage } from '../production.types';
import { LabeledValue } from './LabeledValue';
import { SecondaryCardTitle } from './SecondaryCardTitle';

export const PowerWidget = ({
  title,
  injection,
  withdrawal,
  unit = 'kW',
}: {
  title: string;
  unit?: AcceptableUnits;
  injection?: TimeSeriesMinMaxAverage;
  withdrawal?: TimeSeriesMinMaxAverage;
}) => {
  return (
    <Card title={title}>
      <div className="w-100 mt-3">
        {injection && (
          <div className="mt-2">
            <SecondaryCardTitle title="monitoring.installation.injection" />

            <LabeledValue label="common.mean" value={injection.average} unit={unit} />
            {injection.max && (
              <LabeledValue label="common.max" value={injection.max.value} unit={unit} date={injection.max.date} />
            )}
          </div>
        )}
        {withdrawal && (
          <div className="mt-2">
            <SecondaryCardTitle title="monitoring.installation.withdrawal" />

            <LabeledValue label="common.mean" value={withdrawal.average} unit={unit} />
            {withdrawal.max && (
              <LabeledValue label="common.max" value={withdrawal.max.value} unit={unit} date={withdrawal.max.date} />
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
