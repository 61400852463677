import React from 'react';
import { Simulation } from '@utils/types/simulation';

type ContextProps = {
  selectedRows: Simulation[];
  selectRow: (row: Simulation) => void;
  unselectRow: (row: Simulation) => void;
  selectAllRows: (rows: Simulation[]) => void;
  unselectAllRows: () => void;
};

// Context to handle table selected rows
const SelectedRowsContext = React.createContext<ContextProps>({
  selectedRows: [],
  selectRow: () => {
    throw new Error('selectRow not implemented');
  },
  unselectRow: () => {
    throw new Error('unselectRow not implemented');
  },
  selectAllRows: () => {
    throw new Error('selectAllRows not implemented');
  },
  unselectAllRows: () => {
    throw new Error('unselectAllRows not implemented');
  },
});

export default SelectedRowsContext;
