import React from 'react';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { SeriesOptionsType } from 'highcharts';
import { useM0Deviation } from '../useM0Deviation';

export const M0Plot = () => {
  const { m0Aggregated, currency } = useM0Deviation();
  const currencySymbol = getSymbol(currency);
  const { t } = useTranslation();

  const totalDeviation = m0Aggregated['TOTAL']?.m0_deviation || null;
  const data = Object.keys(m0Aggregated)
    ?.filter((key) => key !== 'TOTAL')
    .map((key) => {
      const row = (m0Aggregated || {})[key];
      const date = row.date ? new Date(row.date) : null;

      return { y: row.m0_deviation, x: date?.valueOf() };
    });

  const series: SeriesOptionsType[] = [
    {
      name: t('pricing.market_value_deviation'),
      data,
      type: 'column',
      tooltip: { valueSuffix: ` ${currencySymbol}/MWh` },
    },
  ];

  const options: Highcharts.Options = {
    tooltip: {
      xDateFormat: '%Y-%m',
    },
    xAxis: { type: 'datetime' },
    yAxis: {
      title: { text: `${t('common.deviation')} (${currencySymbol}/MWh)` },
      opposite: true,
      labels: {
        format: '{value:.2f}',
      },
      plotLines: [
        {
          value: totalDeviation ?? undefined,
          width: 2.0,
          color: 'var(--chart-red)',
          zIndex: 3,
          dashStyle: 'ShortDash',
          label: { text: t('common.total') },
        },
      ],
    },
    plotOptions: {
      column: {
        maxPointWidth: 80,
      },
    },
  };

  return <Chart options={options} series={series} hideLegend />;
};
