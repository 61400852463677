import getMeterType from '@utils/getMeterType';
import dayjs from 'dayjs';
import { DashboardViewAllData, RawDashboardViewAllData } from './portfolio.definitions';

export const backendDataSerializer = (data: RawDashboardViewAllData[]): DashboardViewAllData[] => {
  const cleanedData = data?.map<DashboardViewAllData>((d) => ({
    ...d,
    event_types: d.event_types,
    avgdata: d.avgdata ? parseFloat(d.avgdata.toFixed(2)) : null,
    coverage: d.coverage && d.coverage > 100 ? 100 : d.coverage || null,
    capacity_factor: d.capacity_factor ? d.capacity_factor * 100 : null,
    hourprod: d.hourprod || null,
    maxdate: d.maxdate ? dayjs.unix(d.maxdate).format('YYYY-MM-DD HH:mm') : '',
    puissancecrete: Number(d.puissancecrete) || null,
    status: d.status || null,
    performance: parseInt(d.performance) || null,
    sumdata: d.sumdata || null,
    latitude: parseFloat(d.latitude) || null,
    longitude: parseFloat(d.longitude) || null,
    type: getMeterType(d),
    business_plan: d.business_plan || null,
  }));

  return cleanedData;
};
