import { createContext, useContext } from 'react';
import { SelectedPricingMapContext } from './pricing-maps.types';

export const selectedPricingMapContext = createContext<SelectedPricingMapContext>({
  selectedPricingMap: null,
  selectPricingMap: () => {},
  allPeriods: [],
});

export const useSelectedPricingMapContext = () => useContext(selectedPricingMapContext);
