import React, { useContext, useEffect, useMemo } from 'react';
import { selectInvoicesContext } from '@context/selectInvoices.context';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { useSendInvoicesEmail } from '@hooks/requests/useSendInvoicesEmail';
import useTranslation from '@hooks/useTranslation';
import { EmailInfoModal } from './EmailModal/EmailInfoModal';
import { useInvoicesContext } from './invoicesContext';

type SendInvoiceModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

export const SendInvoiceModal = ({ isOpen, toggle }: SendInvoiceModalProps) => {
  const { selectedInvoicesUuids } = useContext(selectInvoicesContext);
  const {
    execute: sendInvoices,
    loading: sendInvoicesLoading,
    loaded: sendInvoicesLoaded,
    error: sendInvoicesError,
    data: sendInvoicesData,
  } = useSendInvoicesEmail();
  const { allInvoices, updateInvoices } = useInvoicesContext();
  const { t } = useTranslation();

  const selectedInvoices = useMemo(
    () => allInvoices.filter((i) => selectedInvoicesUuids.includes(i.uuid)),
    [allInvoices, selectedInvoicesUuids],
  );

  const submitAction = () => {
    sendInvoices?.({
      invoices: selectedInvoices.map((invoice) => ({ id: invoice.uuid })),
    });
  };

  useEffect(() => {
    if (sendInvoicesLoaded) {
      toggle();
      updateInvoices(sendInvoicesData ?? []);
    }
  }, [sendInvoicesData, sendInvoicesLoaded, updateInvoices, toggle]);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      header={t('sales_management.send_invoices_by_email.title')}
      submitAction={submitAction}
      submitDisabled={sendInvoicesLoading}
      isLoading={sendInvoicesLoading}
      saveButtonText="common.send"
      data-cy="send-invoices-modal"
    >
      <EmailInfoModal invoices={selectedInvoices} />

      <Text text="sales_management.send_invoices_by_email.click_name" className="mt-3" />

      {sendInvoicesError && (
        <Text text="sales_management.send_invoices_by_email.error" type="danger" className="mt-3" />
      )}
    </Modal>
  );
};
