import { useMemo } from 'react';
import { RequestError, useRequest } from '@hooks/useRequest';
import { aggregators_market_results_v2_national_prices_path } from '@utils/routes';
import { CountryCode } from '@utils/types/countries';
import { MonthlyMarketResultAggregator } from '@utils/types/m0';
import groupBy from 'lodash/groupBy';

export type MonthlyMarketResultAggregatorPlayer = {
  [player_id: string]: {
    data: MonthlyMarketResultAggregator[];
    name: string;
  };
};

type UseM0AggregReturn = {
  rawM0s: MonthlyMarketResultAggregator[] | null;
  solarData: MonthlyMarketResultAggregatorPlayer | null;
  windData: MonthlyMarketResultAggregatorPlayer | null;
  loading: boolean;
  error: RequestError | null;
};

const useM0Aggreg = (country: CountryCode | null): UseM0AggregReturn => {
  const {
    data: m0s,
    loading,
    error,
  } = useRequest<MonthlyMarketResultAggregator[]>(
    country ? aggregators_market_results_v2_national_prices_path({ country }) : null,
    'GET',
  );

  const solarData = useMemo(() => {
    if (!m0s?.length) {
      return null;
    }

    const grouped = groupBy(m0s, 'market_player.id');

    const solarAggregated: MonthlyMarketResultAggregatorPlayer = {};

    Object.keys(grouped).forEach((key) => {
      const solar = grouped[key].filter((m0) => m0.price_type === 'M0_SOLAR');

      if (solar.length) {
        solarAggregated[key] = {
          data: solar,
          name: solar[0]?.market_player.long_name,
        };
      }
    });

    return solarAggregated;
  }, [m0s]);

  const windData = useMemo(() => {
    if (!m0s?.length) {
      return null;
    }

    const grouped = groupBy(m0s, 'market_player.id');

    const windAggregated: MonthlyMarketResultAggregatorPlayer = {};

    Object.keys(grouped).forEach((key) => {
      const wind = grouped[key].filter((m0) => m0.price_type === 'M0_WIND');

      if (wind.length) {
        windAggregated[key] = {
          data: wind,
          name: wind[0]?.market_player.long_name,
        };
      }
    });

    return windAggregated;
  }, [m0s]);

  return { rawM0s: m0s, solarData, windData, loading, error };
};

export default useM0Aggreg;
