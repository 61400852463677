import React from 'react';
import { RadioButtons } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const FullMerchantToggle = () => {
  const { control } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('full_merchant');

  const options: Option<boolean>[] = [
    { value: true, label: 'pricing.portfolio.full_merchant' },
    { value: false, label: 'pricing.portfolio.feed_in_premium' },
  ];

  return (
    <Controller
      control={control}
      rules={rules}
      name="full_merchant"
      render={({ field }) => (
        <RadioButtons
          size="lg"
          name="full-merchant"
          options={options}
          selected={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );
};
