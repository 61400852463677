import React from 'react';
import contractsContext from '@context/contracts.context';
import Page from '@pages/Page';
import { User } from '@utils/types/user';
import { ContractsProvider } from './context';
import { TableWrapper } from './Table';

export const Contracts = ({ user, tradeCapture }: { user: User; tradeCapture?: boolean }) => {
  return (
    <ContractsProvider user={user} tradeCapture={tradeCapture}>
      <contractsContext.Consumer>
        {(context) => (
          <Page
            title={tradeCapture ? 'sales_management.trade_capture' : 'sales_management.contracts'}
            error={context.error?.message}
            isLoading={context.isLoading}
            user={user}
            layout="no-background"
            fixedHeightContainer
          >
            <TableWrapper showBookFilter />
          </Page>
        )}
      </contractsContext.Consumer>
    </ContractsProvider>
  );
};
