import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { Contract } from '@utils/types/contract';

export default function isRemit(contract: Contract, filters: ContractsFiltersType): boolean {
  if (filters.sent_to_remit === 'sent' && contract.sent_to_remit !== true) {
    return false;
  }

  if (filters.sent_to_remit === 'not_sent' && !!contract.sent_to_remit) {
    return false;
  }

  return true;
}
