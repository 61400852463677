import { useMemo } from 'react';
import { Contract, ContractForm } from '@utils/types/contract';
import MarketPlayer from '@utils/types/market_player';
import { useForm } from 'react-hook-form';
import { parseContract } from '../../Form/helpers/parsers/parseContract';
import { SECTIONS_PER_CONTRACT_TYPES, SECTIONS_RECORD } from './constants';
import { ContractFormGlobals, ContractFormMode, ContractFormSection } from './types';
import { useContractFormQueries } from './useContractFormQueries';

export function useContractForm({
  contract,
  offtaker,
  mode = 'create',
}: {
  contract?: Contract | null;
  offtaker?: MarketPlayer;
  mode?: ContractFormMode;
}) {
  const values = contract ? parseContract(contract) : undefined;
  const form = useForm<ContractForm>({
    values,
    mode: 'onChange',
  });
  const { watch } = form;

  // Preparing globals: getting values shared among sections
  const [installation_uuid, buyer_id, startDate, endDate, contractType] = watch([
    'installation_uuid',
    'buyer_id',
    'start_date',
    'end_date',
    'type',
    'direction',
  ]);

  const sectionsNames = contractType
    ? SECTIONS_PER_CONTRACT_TYPES[contractType]
    : ['general-information', 'contract-type'];
  const sections: ContractFormSection[] = sectionsNames.map((name) => ({ ...SECTIONS_RECORD[name], name }));

  // TanStack Query Results of data fetches (mainly used for fetching the options lists of the form's select).
  // This is passed as a prop to each section
  const queries = useContractFormQueries(startDate, endDate);

  // Search for the current installation here and share it accross the entire form through globals
  const installation = useMemo(() => {
    return queries.installations.data?.find(({ uuid }) => uuid === installation_uuid) || null;
  }, [installation_uuid, queries.installations.data]);

  // Same for the buyer
  const buyer = useMemo(() => {
    return queries.marketPlayers.data?.find(({ id }) => id === buyer_id) || null;
  }, [buyer_id, queries.marketPlayers.data]);

  // Globals are passed as a prop to each section
  const globals: ContractFormGlobals = {
    formMode: mode,
    installation: installation || null,
    buyer: buyer,
    offtaker: offtaker,
  };

  return {
    sections,
    form,
    globals,
    queries,
  };
}
