import { createContext, useContext, Context, Dispatch, SetStateAction } from 'react';
import { Unavailability } from '@utils/types/unavailability';

type UnavailabilitiesContext = {
  loading?: boolean;
  unavailabilities: Unavailability[];
  selectedUnavailability: Unavailability | null;
  setUnavailabilities: Dispatch<SetStateAction<Unavailability[]>>; // we do not want to re-fetch all data if one record changes
  setSelectedUnavailability: Dispatch<SetStateAction<Unavailability | null>>; // we do not want to re-fetch all data if one record changes
  updateUnavailability: (unavailability: Unavailability) => void;
  addUnavailability: (unavailability: Unavailability) => void;
};

export const unavailabilitiesContext: Context<UnavailabilitiesContext> = createContext<UnavailabilitiesContext>({
  unavailabilities: [],
  selectedUnavailability: null,
  setUnavailabilities: () => {
    throw new Error('method setUnavailabilities not implemented');
  },
  setSelectedUnavailability: () => {
    throw new Error('method setSelectedUnavailability not implemented');
  },
  updateUnavailability: () => {
    throw new Error('method updateUnavailability not implemented');
  },
  addUnavailability: () => {
    throw new Error('method addUnavailability not implemented');
  },
});

export const useUnavailabilitiesContext = (): UnavailabilitiesContext => useContext(unavailabilitiesContext);
