import React from 'react';
import { FilterContainer } from '@GDM/Filters';
import { CountrySelect } from '@GDM/forms';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { CountryCode } from '@utils/types/countries';
import { usePortfolio } from '../usePortfolio';
import { useFilters } from './useFilters';

export const CountryFilters = () => {
  const { data } = usePortfolio();
  const { country, setCountry } = useFilters();

  const countries = [...new Set((data || []).map((dd) => dd.installation_country).filter((country) => country))];
  const options = countries
    .map((country) => ({ label: `countries.${country}`, value: country }))
    .sort(sortOptionsByLabelAsc);

  return (
    <FilterContainer size="fit">
      <CountrySelect
        options={options}
        onChange={(option) => setCountry?.(option?.value ? (option.value as CountryCode) : null)}
        selectedOption={country}
        placeholder="common.country"
        classNamePrefix="country-picker"
        isClearable
      />
    </FilterContainer>
  );
};
