import React from 'react';
import { createBatchCSV } from '@api/businessPlans';
import { Button } from '@GDM/Button';
import { HelpBox } from '@GDM/HelpBox';
import { ModalBody, ModalFooter, ModalHeader, ModalWrapper } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { UploadInput } from '@GDM/UploadInput/UploadInput';
import useTranslation from '@hooks/useTranslation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ReqError } from '@utils/fetch';
import { BusinessPlan } from '@utils/types/business-plan';
import { useForm, useFormState, type Path } from 'react-hook-form';

type BusinessPlanCsvFormData = {
  csv: FileList | null;
};

export const BusinessPlanUploadCsv = ({
  installationName,
  isOpen,
  toggle,
}: {
  installationName: string;
  isOpen: boolean;
  toggle: () => void;
}) => {
  const queryClient = useQueryClient();
  const form = useForm<BusinessPlanCsvFormData>();
  const { t } = useTranslation();

  const { errors } = useFormState(form);

  const bpMutation = useMutation<BusinessPlan[], ReqError, FormData>({
    mutationKey: ['bpUpload'],
    mutationFn: (body) => createBatchCSV(installationName, body),
    onSuccess: (businessPlans) => {
      queryClient.setQueryData<BusinessPlan[]>(['getBusinessPlans', installationName], businessPlans);

      toggle();
    },
    onError: (error) => {
      error.errors?.forEach((fieldError) => {
        if (fieldError.field)
          form.setError(fieldError.field as Path<BusinessPlanCsvFormData>, {
            message: fieldError.code || 'errors.unknown_error',
          });
      });
    },
  });

  const disabled = bpMutation.isPending;

  const handleSubmit = (formData: BusinessPlanCsvFormData) => {
    if (!formData.csv?.[0]) {
      return;
    }
    const file = formData.csv[0];
    const body = new FormData();

    body.append('csv', file);

    bpMutation.mutate(body);
  };

  return (
    <ModalWrapper isOpen={isOpen}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <ModalHeader toggle={toggle}>
          <Text text="monitoring.bp.upload_csv_title" className="font-bold" />
        </ModalHeader>

        <ModalBody>
          <HelpBox className="mb-3">
            <Text text="monitoring.bp.upload_csv_info" multiline />

            <code>
              {t('monitoring.bp.csv.month_row')}, {t('monitoring.bp.csv.bp_row')}&nbsp;
              <br />
              2024-01, 10000
              <br />
              2024-02, 10000
              <br />
              2024-03, 10000
              <br />
              2024-04, 10000
              <br />
              2024-05, 10000
              <br />
              2024-06, 10000
              <br />
              2024-07, 10000
              <br />
              2024-08, 10000
              <br />
              2024-09, 10000
              <br />
              2024-10, 10000
              <br />
              2024-11, 10000
              <br />
              2024-12, 10000
            </code>
          </HelpBox>

          <UploadInput
            {...form.register('csv', { required: true })}
            placeholder="monitoring.bp.upload_csv"
            fileTypes={['.csv']}
            error={errors.csv ? errors.csv.message || 'common.is_required_field' : undefined}
            disabled={disabled}
            onClear={() => form.resetField('csv')}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            size="sm"
            variant="primary-2"
            type="submit"
            disabled={disabled}
            isLoading={bpMutation.isPending}
            className="flex-shrink-0"
            text="common.save"
          />
        </ModalFooter>
      </form>
    </ModalWrapper>
  );
};
