import React, { memo } from 'react';
// Only exception to eslint rule as this file is a wrapper around react-tooltip
// eslint-disable-next-line no-restricted-imports
import { Tooltip as ReactTooltip } from 'react-tooltip';

export const TooltipRenderer = memo((props: TooltipProps) => {
  const defaultProps: TooltipProps = { float: true, className: 'z-100000' };

  const mergedProps = { ...defaultProps, ...props };

  return <ReactTooltip {...mergedProps} id="default-tooltip" />;
});

export type TooltipProps = React.ComponentProps<typeof ReactTooltip>;
