import { useCallback, useEffect, useRef, useState } from 'react';
import { useUser } from '@context/User.context';
import { updateCurrency } from '@utils/formatters';
import { AxiosRequestConfig } from 'axios';
import { RequestCache } from './requestCache';
import { fetch } from './requests.fetchers';
import { CommonRequestHookResponse, RequestError, RequestParams, Response as TResponse } from './requests.types';

const CACHE = new RequestCache();

/**
 * @deprecated Please use react query instead
 * @param url the request url
 * @param fetcher the http verb (only `'GET'` is currently supported) or a fetcher function
 * @param lazy should the request be lazy-loaded only
 * @param abortController a manual abort controller
 * @param cachedRequest if `false`, the request will never be cached on the client-side
 */
export function useRequest<T extends unknown[] | Record<string, unknown>>(
  url: string | null,
  fetcher: 'GET' | ((opts: RequestParams<T>) => TResponse<T>),
  lazy?: boolean,
  abortController?: AbortController | null,
  cachedRequest?: boolean,
): CommonRequestHookResponse<T> {
  const [request, setRequest] = useState<CommonRequestHookResponse<T>>({
    data: null,
    loading: !lazy,
    error: null,
    loaded: false,
    status: null,
  });
  const user = useUser();
  const IS_UK_USER = user.geolocation?.[0] === 'UK';

  // TODO: TEST FOR UPDATING CURRENCY
  const SHOULD_UPDATE_CURRENCY = IS_UK_USER;

  const isMountedRef = useRef(true);
  const defaultAbortController = useRef(new AbortController());
  const requestActor: (opts: RequestParams<T>) => TResponse<T> = fetcher === 'GET' ? fetch : fetcher;

  const fetchData = useCallback(
    async (options?: AxiosRequestConfig): Promise<void | string> => {
      if (!isMountedRef.current) return;

      const requestUrl = url || options?.url;

      setRequest((request) => ({ ...request, loading: true, error: null, loaded: false }));
      // this rule is used for conditional requests. It is similar to: https://swr.vercel.app/docs/conditional-fetching

      if (!requestUrl) return;

      try {
        setRequest((request) => ({ ...request, loading: true }));

        let data: T | null;
        let status: number | undefined;

        // Only for requests that use the `fetch` function provided in @hooks/useRequest (GET requests.)
        if (cachedRequest !== false && fetcher === 'GET' && CACHE.get({ url: requestUrl, params: options?.params })) {
          data = CACHE.get({ url: requestUrl, params: options?.params }) as T;
        } else {
          const request = await requestActor({
            url: requestUrl,
            body: options?.data || { ...options },
            options: {
              ...options,
              signal: abortController?.signal || defaultAbortController?.current.signal,
            },
          });

          data = request?.data || null;
          status = request?.status;
        }

        if (isMountedRef.current) {
          setRequest({ data, loading: false, error: null, loaded: true, status: status || request?.status });

          if (fetcher !== 'GET' || cachedRequest === false) return;

          CACHE.set({ url: requestUrl, params: options?.params, data });
        }
      } catch (error) {
        if (error && typeof error === 'object') {
          let errorCode = 'code' in error && typeof error.code === 'string' ? error.code : 'errors.unknown_error';
          let errorField = 'field' in error && typeof error.field === 'string' ? error.field : null;

          const errorMessage =
            'message' in error && typeof error.message === 'string' ? error.message : 'errors.unknown_error';

          if ('response' in error && error.response && typeof error.response === 'object') {
            const data =
              'data' in error.response && typeof error.response.data === 'object' ? error.response.data : null;

            errorCode =
              (data as { error: string })?.error ||
              (data as { error_code: string })?.error_code ||
              (data as { errors: { code: string }[] })?.errors?.[0]?.code ||
              (data as { code: string })?.code ||
              errorCode;

            if ('request' in error && (error.request as { responseType: unknown })?.responseType === 'blob') {
              try {
                const response = await (data as Response)?.text();
                const code = JSON.parse(response)?.code;
                errorField = JSON.parse(response)?.field;

                if (code) errorCode = code;
              } catch (e) {
                // eslint-disable-next-line no-console
                console.error(
                  "Failed to parse. It's probably not a JSON response. HTML maybe?\nℹ️",
                  (e as Error).message,
                );
                setRequest({
                  data: null,
                  loading: false,
                  error: {
                    field: null,
                    message: (e as Error).message,
                    code: (e as Error).message === 'Network Error' ? 'errors.network_error' : 'errors.unknown_error',
                  },
                  loaded: true,
                  status: 500,
                });

                return;
              }
            }

            const message = errorMessage || (data as { message: string })?.message;

            // 500 errors are displayed as 'unknown' because they shouldn't happen.
            if ('status' in error.response && error.response?.status === 500 && !errorCode)
              errorCode = 'errors.unknown_error';

            if (isMountedRef.current && 'message' in error && error.message !== 'canceled') {
              const status =
                'status' in error.response && typeof error.response.status === 'number' ? error.response.status : null;

              setRequest({
                data: null,
                loading: false,
                errors: Array.isArray((data as { errors: { field: string }[] })?.errors)
                  ? (data as { errors: RequestError[] })?.errors
                  : null,
                error: {
                  field: errorField || (data as { errors: { field: string }[] })?.errors?.[0]?.field,
                  message: errorCode || message,
                  code: errorCode,
                  ...(status ? { status } : {}),
                },
                loaded: true,
                status,
              });
            }
          } else {
            const status = 'status' in error && typeof error.status === 'number' ? error.status : null;

            setRequest({
              data: null,
              loading: false,
              error: {
                field: null,
                message: (error as Error).message,
                code: (error as Error).message === 'Network Error' ? 'errors.network_error' : 'errors.unknown_error',
              },
              loaded: true,
              status,
            });
          }

          return errorCode === 'Network Error' ? 'errors.network_error' : errorCode;
        }

        return error as string;
      } finally {
        // THIS IS A TEMPORARY SOLUTION TO DISPLAY UK CURRENCY FOR UK USERS.
        if (SHOULD_UPDATE_CURRENCY) updateCurrency();
      }
    },
    [SHOULD_UPDATE_CURRENCY, abortController?.signal, cachedRequest, fetcher, request?.status, requestActor, url],
  );

  useEffect(() => {
    if (!lazy) fetchData();
  }, [fetchData, lazy]);

  useEffect(() => {
    const defaultAbortControllerRef = defaultAbortController?.current;

    return () => {
      isMountedRef.current = false;

      if (abortController || defaultAbortControllerRef) {
        abortController?.abort();
        defaultAbortControllerRef?.abort();
      }
    };
  }, [abortController]);

  useEffect(() => {
    // In the case of a delete request for example, the url can change.
    // This means we have to reset all 'loaded' and 'error' values to avoid
    // showing outdated values.
    setRequest((request) => ({ ...request, error: null, loaded: false }));
  }, [url]);

  const execute = useCallback(
    async (options?: AxiosRequestConfig | Record<string, unknown>) => {
      if (!isMountedRef.current) return;

      return fetchData(options);
    },
    [fetchData],
  );

  const reset = () => {
    setRequest((request) => ({ ...request, error: null, errors: null, loaded: false }));
  };

  return { ...request, execute, reset };
}
