import { Dispatch, SetStateAction } from 'react';
import { Currency } from '@utils/types/currency';
import MarketPlayer from '@utils/types/market_player';
import { PointOptionsObject } from 'highcharts';

export type RevenueContextType = {
  displayMode?: 'chart' | 'table';
  endDate: Date | null;
  groupBy?: 'contract_type' | 'installation' | 'role' | 'market' | 'risk' | null;
  intermediaries?: MarketPlayer[];
  isLoading: boolean;
  marketPlayer?: MarketPlayer;
  owners?: MarketPlayer[];
  playerIds?: string[];
  players?: MarketPlayer[];
  resolution?: RevenueResolution;
  revenue?: Revenues<InvoiceRevenueDataPoint | InvoiceRevenueGroupedDataPoint>;
  overview?: Overview[];
  selectedPlayers?: string[];
  selectedSellerId?: string;
  setDisplayMode?: Dispatch<SetStateAction<RevenueContextType['displayMode']>>;
  setEndDate?: Dispatch<SetStateAction<RevenueContextType['endDate']>>;
  setGroupBy?: Dispatch<SetStateAction<RevenueContextType['groupBy']>>;
  setIsLoading?: Dispatch<SetStateAction<RevenueContextType['isLoading']>>;
  setResolution?: Dispatch<SetStateAction<RevenueContextType['resolution']>>;
  setSelectedPlayers?: Dispatch<SetStateAction<RevenueContextType['selectedPlayers']>>;
  setSelectedSellerId?: Dispatch<SetStateAction<RevenueContextType['selectedSellerId']>>;
  setShowHelp?: Dispatch<SetStateAction<RevenueContextType['showHelp']>>;
  setStartDate?: Dispatch<SetStateAction<RevenueContextType['startDate']>>;
  setTopInstallationsLimit?: Dispatch<SetStateAction<RevenueContextType['topInstallationsLimit']>>;
  showHelp?: boolean;
  sourceType?: 'installation' | 'book' | 'market_player';
  startDate: Date | null;
  topInstallationsLimit?: number | null;
  currencies?: Currency[];
  selectedCurrency?: Currency;
  setSelectedCurrency?: Dispatch<SetStateAction<RevenueContextType['selectedCurrency']>>;
  groupByMonth: boolean;
  setGroupByMonth: Dispatch<SetStateAction<RevenueContextType['groupByMonth']>>;
};

export type RevenueResolution = 'hourly' | 'monthly' | 'daily';
type RevenueDataUnits = 'kWh' | Currency | `${Currency}/MWh`;

export type InvoiceRevenueDataPoint = {
  period: string;
  revenue?: number | null;
  value?: string | null; // 'float' string
  incomplete?: boolean;
  unit?: RevenueDataUnits;
};

type SubGroup = {
  group: 'role';
  label: string;
  values: InvoiceRevenueDataPoint[];
};

export type InvoiceRevenueGroupedDataPoint = {
  group?: RevenueContextType['groupBy'];
  label?: string;
  values: InvoiceRevenueDataPoint[];
  sub_groups?: SubGroup[];
};

export type Revenues<T> = T extends InvoiceRevenueDataPoint
  ? InvoiceRevenueDataPoint[]
  : InvoiceRevenueGroupedDataPoint[];

export type TableData = {
  label?: string;
  values?: InvoiceRevenueDataPoint[];
  period?: string;
  revenue?: number;
  unit?: RevenueDataUnits;
  subRows?: SubGroup[];
  isOverviewRow?: boolean;
};

export enum OverviewFields {
  'billable_production',
  'business_plan',
  'hedge_production',
  'injected_production',
  'revenue',
  'spot_price',
}

type Overview = {
  field: keyof typeof OverviewFields;
  data: InvoiceRevenueDataPoint[];
  unit: RevenueDataUnits;
};

export type InvoiceRevenueRequestParameters = {
  resolution: RevenueContextType['resolution'];
  group: RevenueContextType['groupBy'];
  start: RevenueContextType['startDate'] | string;
  end: RevenueContextType['endDate'] | string;
  market_player_ids: string[];
  currency: Currency;
};

export interface Point extends PointOptionsObject {
  x: number;
  y: number | null;
  opacity?: string;
}

export type GroupedPoints = {
  label: string;
  type?: RevenueContextType['groupBy'];
  values: Point[];
  noData?: boolean;
};

export type CSVExportPeriod = {
  label: string;
  index: number;
};

export type CSVExportColumn = {
  label: string;
  key?: string;
};
