import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { Locale } from '@utils/types/common-types';
import { ContractType } from '@utils/types/contract';
import { ContextType, FormatOption, ViewOption } from '../../filtersContext';

const useFilters = (locale: Locale): ContextType<ViewOption, FormatOption> => {
  const [selectedView, setSelectedView] = React.useState<keyof ViewOption>('chart');
  const [selectedFormat, setSelectedFormat] = React.useState<keyof FormatOption>('monthly');
  const [selectedContractType, setSelectedContractType] = React.useState<ContractType | ''>('');
  const { t } = useTranslation(locale);

  const filtersContextValue: ContextType<ViewOption, FormatOption> = {
    contracts: { selected: selectedContractType, select: setSelectedContractType },
    view: {
      selected: selectedView,
      options: {
        chart: t('common.chart'),
        table: t('common.table'),
      },
      select: setSelectedView,
    },
    format: {
      selected: selectedFormat,
      options: { monthly: t('common.monthly'), cumulated: t('common.cumulative') },
      select: setSelectedFormat,
    },
  };

  return filtersContextValue;
};

export default useFilters;
