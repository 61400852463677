import { RadioOption } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { RevenueContextType, RevenueResolution } from '../../revenue.types';

export const resolutionOptions: RadioOption<RevenueResolution>[] = [
  { label: 'common.monthly', value: 'monthly' },
  { label: 'common.daily', value: 'daily' },
  { label: 'common.hourly', value: 'hourly' },
];

export const chartTableOptions: RadioOption<RevenueContextType['displayMode']>[] = [
  { label: 'common.chart', value: 'chart' },
  { label: 'common.table', value: 'table' },
];

export const groupByOptions: Option<RevenueContextType['groupBy']>[] = [
  { label: 'monitoring.installation.revenue.group_by.contract', value: 'contract_type' },
  { label: 'monitoring.installation.revenue.group_by.role', value: 'role' },
];

export const groupByInstallationOption: Option<RevenueContextType['groupBy']> = {
  label: 'monitoring.installation.revenue.group_by.installation',
  value: 'installation',
};

export const groupByRiskOption: Option<RevenueContextType['groupBy']> = {
  label: 'monitoring.installation.revenue.group_by.risk',
  value: 'risk',
};

export const groupByMarketOption: Option<RevenueContextType['groupBy']> = {
  label: 'monitoring.installation.revenue.group_by.market',
  value: 'market',
};

export const allOwnersOption: Option<string> = {
  label: 'monitoring.installation.revenue.all_owners',
  value: '',
};
