import React, { useCallback, useEffect, useMemo } from 'react';
import { Option } from '@utils/types/common-types';
import { User } from '@utils/types/user';
import dayjs from 'dayjs';
import { PricingMap, SelectedPricingMapContext } from './utils/pricing-maps.types';
import { usePricingMapsContext } from './utils/pricingMaps.context';
import { selectedPricingMapContext } from './utils/selectedPricingMap.context';

export const SelectedPricingMapProvider = ({ children, user }: React.PropsWithChildren<{ user: User }>) => {
  const [selectedPricingMap, setSelectedPricingMap] = React.useState<PricingMap | null>(null);
  const { pricingMaps } = usePricingMapsContext();
  const country = user.geolocation.filter(Boolean)[0];

  const allPeriods = useMemo<Option[]>(() => {
    if (!selectedPricingMap?.periods) return [];

    return selectedPricingMap?.periods.map((period, index) => ({
      label: dayjs(period).format('YYYY-MM'),
      value: index.toString(),
    }));
  }, [selectedPricingMap]);

  const selectPricingMap = useCallback(
    (id: number | null) => {
      const pricingMap = pricingMaps?.find((pricingMap) => pricingMap.id === id);

      setSelectedPricingMap(pricingMap || null);
    },
    [pricingMaps],
  );

  const value = useMemo<SelectedPricingMapContext>(() => {
    return { selectedPricingMap, selectPricingMap, allPeriods };
  }, [selectedPricingMap, selectPricingMap, allPeriods]);

  useEffect(() => {
    if (pricingMaps && pricingMaps.length > 0) {
      const highestId = pricingMaps
        .filter((pm) => pm.country === country.toLowerCase())
        .reduce((acc, pricingMap) => {
          return pricingMap.id > acc ? pricingMap.id : acc;
        }, 0);

      selectPricingMap(highestId);
    }
  }, [pricingMaps, selectPricingMap, country]);

  return <selectedPricingMapContext.Provider value={value}>{children}</selectedPricingMapContext.Provider>;
};
