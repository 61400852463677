import { DataPeriod } from '@utils/types/dataPeriod';
import { useLegacyProductionData } from './useLegacyProductionData';
import { ProductionType, useProductionData } from './useProductionData';

export function useAllProductionData({
  startDate,
  endDate,
  dataPeriod,
  identifier,
  legacyIdentifier,
  type,
}: {
  startDate: Date;
  endDate: Date;
  dataPeriod: DataPeriod;
  identifier: string;
  legacyIdentifier: string | undefined;
  type: ProductionType;
}) {
  // Data from the latest api (March 2024)
  const { data: productionData, isLoading: areProdCurvesLoading } = useProductionData({
    identifier,
    start: startDate,
    stop: endDate,
    type,
    dataPeriod,
  });

  // Some fields are not (yet?) fully replicated by the new api, we use the legacy api to handle them
  // market_prices: The new api seems to only give the prices for a single country
  // data_dispo: Not found on the new api, It seems not computable by the front
  const { data: legacyProdData, isLoading: isLegacyProductionDataLoading } = useLegacyProductionData(
    legacyIdentifier || null,
    startDate,
    endDate,
    type === 'book',
    dataPeriod,
  );

  const chartLoadingStatesPerDataPeriod: Record<DataPeriod, boolean> = {
    // Must wait the legacy api to display countries market prices
    minutes: areProdCurvesLoading || isLegacyProductionDataLoading,
    // Must wait the legacy api to display availability
    daily: areProdCurvesLoading || isLegacyProductionDataLoading,
    // Only data from the new api is required
    monthly: areProdCurvesLoading,
  };

  const isChartLoading = chartLoadingStatesPerDataPeriod[dataPeriod];

  return {
    productionData: productionData?.production,
    marketPrices: productionData?.marketPrices,
    legacyProdData,
    isChartLoading,
    areProdCurvesLoading,
  };
}
