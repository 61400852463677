import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import { AxiosResponse } from 'axios';
import { ReactiveInstruction } from './types';

type Methods = 'delete' | 'update' | 'create';

/**
 * Gathers all the instructions and splits them out into delete, update and create requests.
 */
export function getRequestStacks(instructions: { new: ReactiveInstruction[]; old: Partial<ReactiveConstraint>[] }): {
  instructionStack: Record<Methods, Partial<ReactiveConstraint>[]>;
  requestStack: Record<Methods, (Promise<AxiosResponse<ReactiveInstruction>> | null)[]>;
} {
  const newInstructionUuids = instructions.new?.map((i) => i.uuid);
  const oldInstructionUuids = instructions.old?.map((i) => i.uuid);

  const instructionStack = {
    delete: instructions.old?.filter((c) => !newInstructionUuids?.includes(c.uuid)),
    update: instructions.new?.filter((c) => oldInstructionUuids?.includes(c.uuid)),
    create: instructions.new?.filter((i) => !i.uuid),
  };

  const requestStack = {
    delete: [],
    update: [],
    create: [],
  };

  return { instructionStack, requestStack };
}
