import { post, useRequest } from '@hooks/useRequest';
import { v2_monthly_market_results_path } from '@utils/routes';
import { Status, PriceType, MonthlyMarketResultType } from '@utils/types/monthly_market_result';
import dayjs from 'dayjs';

type Params = {
  market_player_id: string | null;
  start_date: Date | null;
  value: number | null;
  status: Status;
  price_type: PriceType;
  country: string;
  type: MonthlyMarketResultType;
};

export const useCreateMonthlyMarketResults = () => {
  const { execute, ...request } = useRequest(v2_monthly_market_results_path(), post, true);

  const create = (params: Params) => {
    const formatted_start_date = dayjs(params.start_date).format('YYYY-MM-DD');

    execute?.({ ...params, start_date: formatted_start_date });
  };

  return {
    ...request,
    create,
  };
};
