import React, { useCallback, useState } from 'react';
import SmartTranslator from '@components/SmartTranslator';
import { AltContent } from '@GDM/AltContent';
import { Badge } from '@GDM/Badge';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useRevenueReportingContext } from '../utils/hooks/useRevenueReportingContext';
import ProductionChart from './charts/ProductionChart';
import MarketData from './MarketData';
import MonthlyResults from './MonthlyResults';
import YearlyResults from './YearlyResults';

const DEFAULT_HEIGHT = 400;

const Reports = React.forwardRef<HTMLDivElement>((_, ref) => {
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const { t } = useTranslation();
  const { selectedDate, monthly, currency } = useRevenueReportingContext();

  const isEstimated =
    selectedDate &&
    selectedDate?.getDate() >= 25 &&
    dayjs(selectedDate).format('YYYY-MM') === dayjs().format('YYYY-MM');

  const measuredRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      const _height = node.getBoundingClientRect().height;
      if (_height > 0) setHeight(_height);
      else setHeight(DEFAULT_HEIGHT);
    }
  }, []);

  if (!monthly) return <AltContent label="common.fetching_data" />;

  return (
    <div className="ReportPage" ref={ref}>
      <div className="ReportPageContainer">
        <h4 className="mb-3 d-flex align-items-center">
          {t('sales_management.monthly_results', {
            date: dayjs(selectedDate).format('MMMM YYYY'),
          })}
          {isEstimated && <Badge label="sales_management.estimated" className="ml-2" />}
        </h4>
        <MonthlyResults />
      </div>

      <div className={classNames('ReportPageContainer', 'mt-3')}>
        <h4 className="mb-3">
          {t('sales_management.annual_results', {
            start: dayjs(selectedDate).startOf('year').format('MMMM'),
            end: dayjs(selectedDate).format('MMMM YYYY'),
          })}
        </h4>
        <YearlyResults />
      </div>

      <div className={classNames('ReportPageContainer', 'mt-3')}>
        <h4 className="mb-3">{t('sales_management.market_price')}</h4>
        <MarketData ref={measuredRef} />
      </div>

      <div className={classNames('ReportPageContainer', 'ProductionChart', 'mt-3')}>
        <h4 className="mb-3">
          <SmartTranslator translationKey="sales_management.prod_vs_revenues" />
        </h4>

        <ProductionChart height={height} currency={currency} />
      </div>
    </div>
  );
});

export default Reports;
