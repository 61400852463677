import { constrainFractionDigits, toDate } from '@utils/formatters';
import { ContractForm, ContractSubPeriod, ContractSubPeriodForm, Fee } from '@utils/types/contract';

export function parseSubPeriods(subPeriods: ContractSubPeriod[]): ContractForm['contract_sub_periods_attributes'] {
  const parsedSubPeriods: ContractSubPeriodForm[] = subPeriods.map((period) => {
    // Parse Aggreg fees
    const aggreg_fees: ContractSubPeriodForm['aggreg_fees'] = [
      ...(period.aggreg_fee_fixed ? [{ value: period.aggreg_fee_fixed, type: 'fixed' } as Fee] : []),
      ...(period.aggreg_fee_percentage
        ? [{ value: constrainFractionDigits(period.aggreg_fee_percentage * 100), type: 'percentage' } as Fee]
        : []),
    ];

    const volume_type = period.profile_hedge_attributes ? 'hedge_profile' : 'full';

    return {
      ...period,
      volume_type,
      aggreg_fees: aggreg_fees.length ? aggreg_fees : [{ value: null, type: 'fixed' } as Fee],
      period_type: period.market_future_id ? 'market_future' : 'custom',
      price_model_attributes: period.price_model,
      price_rule_id: period.price_rule?.id,
      volume_ratio: period.volume_ratio ? period.volume_ratio * 100 : null,
      dates: [toDate(period.start_date), toDate(period.end_date)],
      profile_hedge_attributes: period.profile_hedge_attributes && {
        ...period.profile_hedge_attributes,
        profile_hedge_caps_attributes: period.profile_hedge_attributes.profile_hedge_caps_attributes.map(
          ({ cap, ...rest }) => ({
            ...rest,
            cap: cap && Number(cap),
          }),
        ),
      },
    };
  });

  return parsedSubPeriods || [];
}
