import { RadioOption } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { FocusType, SplitByType, DateSplitType } from '../types';

export const FOCUS_OPTIONS: Option<FocusType>[] = [
  { label: 'common.contract_type', value: 'contract_type' },
  { label: 'reporting.buyer_name', value: 'buyer_name' },
];

export const SPLIT_BY_OPTIONS: Option<SplitByType>[] = [
  {
    label: 'common.technology',
    value: 'energy',
  },
  { label: 'common.contract_type', value: 'contract_type' },
  { label: 'reporting.buyer_name', value: 'buyer_name' },
];

export const DATE_SPLIT_OPTIONS: RadioOption<DateSplitType>[] = [
  {
    label: 'common.full_period',
    value: 'full',
  },
  { label: 'subscriptions.frequencies.yearly', value: 'yearly' },
  { label: 'subscriptions.frequencies.monthly', value: 'monthly' },
];
