import React from 'react';
import ControlledDatePicker, { ControlledDatePickerProps } from '@components/FormInputs/ControlledDatePicker';
import { HelpBoxProps } from '@GDM/HelpBox/HelpBox';
import { useHelpBoxContext } from '@pages/Contracts/Contract/contexts/helpBoxContext';
import { useFieldRegistrationTracker } from '@pages/Contracts/Contract/contexts/registeredFields';
import { FieldPath, FieldValues } from 'react-hook-form';

export const DateInput = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  helpBox,
  ...props
}: ControlledDatePickerProps<TFieldValues, TFieldName> & {
  helpBox?: Pick<HelpBoxProps, 'text' | 'title'>;
}) => {
  useFieldRegistrationTracker(props);
  const { handleFocus, handleBlur } = useHelpBoxContext();

  return (
    <ControlledDatePicker
      size="lg"
      {...props}
      shouldUnregister
      onFocus={helpBox && handleFocus(helpBox)}
      onBlur={handleBlur}
    />
  );
};
