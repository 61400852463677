import React, { useMemo } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { DateCell, NumberCell, Table, TableBody, TableHead } from '@GDM/Table';
import { getSortedRowModel, useReactTable, getCoreRowModel, ColumnDef } from '@tanstack/react-table';
import { BookInstallation } from '../characteristics.types';

export const BookInstallations = ({ installations }: { installations: BookInstallation[] }) => {
  const columns = useMemo<ColumnDef<BookInstallation>[]>(
    () =>
      [
        {
          header: 'common.installation',
          accessorKey: 'name',
          cell: ({ row: { original } }) => (
            <MeterNameCell
              hasMeter={original.has_meter}
              installation_name={original.name}
              installation_energy={original.energy}
              hasLocation={!!original.latitude && !!original.longitude}
              isDraft={original.status === 'draft'}
            />
          ),
        },
        {
          header: 'common.power_rating',
          accessorKey: 'p_max',
          cell: ({ row: { original } }) => <NumberCell value={original.p_max} unit="kW" />,
        },
        {
          accessorKey: 'commissioning_date',
          header: 'monitoring.installation.commissioning_date_title',
          cell: ({ row: { original } }) => (
            <DateCell
              value={original.commissioning_date || ''}
              placeholder="common.none_specified"
              format="YYYY-MM-DD"
            />
          ),
        },
        {
          accessorKey: 'current_year_total_assets_bps',
          header: 'monitoring.bp.current_year_bp',
          cell: ({ row: { original } }) => (
            <NumberCell
              value={original.current_year_total_assets_bps && original.current_year_total_assets_bps / 1000}
              unit="MWh"
              fractionDigits={2}
            />
          ),
        },
      ] satisfies ColumnDef<BookInstallation>[],
    [],
  );

  const table = useReactTable({
    columns,
    data: installations,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHead table={table} />
      <TableBody table={table} />
    </Table>
  );
};
