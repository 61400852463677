import { createInstallation, getInstallationByName, updateInstallation } from '@api/installations';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation, useQuery, useQueryClient, type QueryClient } from '@tanstack/react-query';
import { edit_v2_installation_path } from '@utils/routes';
import type { InstallationWithMeterInfo } from '@utils/types/installation';
import type { AxiosError } from 'axios';
import type { NextStepHandler } from './hooks/useStepsNavigation';
import type { InstallationFormRequestBody, StepKey } from './installation.types';
import { formatInstallationBody } from './utils/formatInstallationBody';

type MutationContext = { step?: StepKey; status?: InstallationFormRequestBody['status'] };

export const useInstallationFormQuery = (props?: { name?: string; step: StepKey; goToNextStep: NextStepHandler }) => {
  const { name, goToNextStep, step } = props || {};
  const queryClient = useQueryClient();
  const query = useQuery<InstallationWithMeterInfo, AxiosError<RequestErrorBody>>({
    queryKey: ['installation-name'],
    queryFn: () => getInstallationByName(name),
    enabled: !!name,
  });
  const internalName = query.data?.name || name;

  const createMutation = useMutation<
    InstallationWithMeterInfo,
    AxiosError<RequestErrorBody>,
    InstallationFormRequestBody,
    MutationContext
  >({
    mutationFn: (body) => createInstallation(formatInstallationBody(body)),
    onSuccess: (data, _, context) => onSuccess(data, queryClient, context, goToNextStep),
    onMutate: (requestBody) => ({ step, status: requestBody.status }),
  });

  const updateMutation = useMutation<
    InstallationWithMeterInfo,
    AxiosError<RequestErrorBody>,
    InstallationFormRequestBody,
    MutationContext
  >({
    mutationFn: (body) => updateInstallation(internalName || '', formatInstallationBody(body)),
    onSuccess: (data, _, context) => onSuccess(data, queryClient, context, goToNextStep),
    onMutate: (requestBody) => ({ step, status: requestBody.status }),
  });

  return { query, createMutation, updateMutation };
};

const onSuccess = (
  data: InstallationWithMeterInfo,
  queryClient: QueryClient,
  context: MutationContext,
  goToNextStep?: NextStepHandler,
) => {
  queryClient.setQueryData(['installation-name'], data);
  history.pushState({}, '', edit_v2_installation_path(data.name));
  const redirectDirectly = context?.step === 'owner' && context?.status === 'draft';

  goToNextStep?.(redirectDirectly);
};
