import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InclinationInput } from '../fields/Solar/InclinationInput';
import { MaxAngleTrackerEveningInput } from '../fields/Solar/MaxAngleTrackerEveningInput';
import { MaxAngleTrackerMorningInput } from '../fields/Solar/MaxAngleTrackerMorningInput';
import { OrientationInput } from '../fields/Solar/OrientationInput';
import { TotalPowerSolarInput } from '../fields/Solar/TotalPowerSolarInput';
import { TrackerToggle } from '../fields/Solar/TrackerToggle';
import styles from '../new-simulation.module.scss';
import { SimulationForm } from '../utils/simulations.types';

export const SolarSimulation = () => {
  const { watch } = useFormContext<SimulationForm>();
  const tracker = watch('tracker');

  return (
    <>
      <div className={styles.row}>
        <TrackerToggle />
      </div>

      <div className={styles.row}>
        <TotalPowerSolarInput />
      </div>

      <div className={styles.row}>
        <OrientationInput />
        {!tracker && <InclinationInput />}
      </div>

      {tracker && (
        <div className={styles.row}>
          <MaxAngleTrackerMorningInput />
          <MaxAngleTrackerEveningInput />
        </div>
      )}
    </>
  );
};
