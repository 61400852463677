import React from 'react';
import ControlledDatePicker from '@components/FormInputs/ControlledDatePicker';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { FilterContainer } from '@GDM/Filters/FilterContainer';
import { Filters } from '@GDM/Filters/Filters';
import { EnergyType, Option } from '@utils/types/common-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { UseFormReturn } from 'react-hook-form';
import { useGreenStarGroups, useGreenStarInstallations } from './queries';
import styles from './styles.module.scss';
import { RiskAnalysisFormFilters } from './types';

const DEFAULT_PERIOD = [dayjs().subtract(1, 'year').toDate(), dayjs().add(5, 'year').toDate()];
const MAX_DATE = dayjs().add(15, 'years').toDate();

export const RiskAnalysisConfiguration = ({
  form: { control },
  setFilterReadiness,
}: {
  form: UseFormReturn<RiskAnalysisFormFilters>;
  setFilterReadiness: (isReady: boolean) => void;
}) => {
  const installationsQuery = useGreenStarInstallations();
  const groupsQuery = useGreenStarGroups();

  const installationsOptions =
    installationsQuery.data?.map((installation) => ({
      value: installation.name,
      label: installation.name,
    })) || [];

  const grappeOptions =
    groupsQuery.data?.grappes.map((grappe) => ({
      value: grappe,
      label: grappe,
    })) || [];

  const holdingOptions =
    groupsQuery.data?.holdings.map((holding) => ({
      value: holding,
      label: holding,
    })) || [];

  const spvOptions =
    groupsQuery.data?.spvs.map((spv) => ({
      value: spv,
      label: spv,
    })) || [];

  const energyOptions =
    installationsQuery.data?.reduce((acc, installation) => {
      if (!acc.find((energy) => energy.value === installation.energy)) {
        return [
          ...acc,
          {
            value: installation.energy,
            label: `energy.${installation.energy}`,
          },
        ];
      }

      return acc;
    }, [] as Option<EnergyType>[]) || [];

  const contractTypesOptions =
    groupsQuery.data?.contract_types.map((contractType) => ({
      value: contractType,
      label: contractType,
    })) || [];

  return (
    <Filters className={classNames('pt-4', styles['configuration-filters'])}>
      <FilterContainer size="select">
        <ControlledDatePicker
          name="period"
          control={control}
          picks="years"
          selectsRange
          defaultValue={DEFAULT_PERIOD}
          maxDate={MAX_DATE}
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          name="version"
          control={control}
          options={[
            { value: 'latest', label: 'risk_analysis.latest' },
            { value: 'last_month', label: 'risk_analysis.last_month' },
            { value: 'last_year', label: 'risk_analysis.last_year' },
            { value: 'latest_vs_last_month', label: 'risk_analysis.latest_vs_last_month' },
            { value: 'latest_vs_last_year', label: 'risk_analysis.latest_vs_last_year' },
          ]}
          className={styles.select}
          defaultValue="latest"
          placeholder="risk_analysis.version"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          name="apply_ownership_percentage"
          control={control}
          options={[
            { label: '100 %', value: false },
            { label: 'risk_analysis.consolidated', value: true },
          ]}
          className={styles.select}
          defaultValue={false}
          placeholder="risk_analysis.vision"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="grappe"
          control={control}
          options={grappeOptions}
          className={styles.select}
          placeholder="risk_analysis.grappe"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="holding"
          control={control}
          options={holdingOptions}
          className={styles.select}
          placeholder="risk_analysis.holding"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="spv"
          control={control}
          options={spvOptions}
          className={styles.select}
          placeholder="sales_management.owner"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="installations"
          control={control}
          options={installationsOptions}
          className={styles.select}
          placeholder="sales_management.installation"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="energy"
          control={control}
          options={energyOptions}
          className={styles.select}
          placeholder="common.technology"
        />
      </FilterContainer>
      <FilterContainer size="select">
        <ControlledSelect
          isMulti
          onMenuOpen={() => {
            setFilterReadiness(false);
          }}
          onMenuClose={() => {
            setFilterReadiness(true);
          }}
          name="contract_type"
          control={control}
          options={contractTypesOptions}
          placeholder="common.filters.contract_type"
          className={styles.select}
        />
      </FilterContainer>
    </Filters>
  );
};
