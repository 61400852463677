import React from 'react';
import { Icon } from '@GDM/Icon';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';
import styles from './status-cell.module.scss';

export const StatusCell = ({ getValue, row: { original } }: CellContext<MeterInvoice, unknown>) => {
  let estimatedTooltip = 'common.estimated';
  if (original.volume_prod_estimated_status)
    estimatedTooltip = `invoice.volume_status.${original.volume_prod_estimated_status}`;

  return (
    <div className={styles['status-cell']}>
      {getValue() === 'validated' && <Icon name="Check" size={16} title="common.pre_approved" />}
      {getValue() === 'estimated' && <Icon name="HelpCircle" size={16} title={estimatedTooltip} />}
    </div>
  );
};
