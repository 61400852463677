import React from 'react';
import { Progress } from '@GDM/Progress';
import { CellContext } from '@tanstack/react-table';
import { DashboardViewAllData } from '../../../portfolio.definitions';

export const CapacityFactorCell = ({ row }: React.PropsWithChildren<CellContext<DashboardViewAllData, unknown>>) => {
  const value = row.original.capacity_factor;
  if (!value) return null;

  let threshold = 85;
  if (row.original.meter_energy === 'solar') threshold = 14;
  if (row.original.meter_energy === 'wind') threshold = 25;

  return <Progress value={value} dangerThreshold={threshold} data-cy="capacity-factor" />;
};
