import { useContext, createContext } from 'react';
import { Book } from '@utils/types/book';
import { type LightWeightInstallation } from '@utils/types/installation';
import { SubUserWithAdditionalInfo } from '@utils/types/user';

type SubUsersContext = {
  subUsersWithAdditionalInfo: SubUserWithAdditionalInfo[];
  installations: LightWeightInstallation[];
  books: Book[];
  addSubUser: (subUser: SubUserWithAdditionalInfo) => void;
  updateSubUser: (subUser: SubUserWithAdditionalInfo) => void;
  deleteSubUser: (subUserId: string) => void;
  isLoading: boolean;
};

export const subUsersContext = createContext<SubUsersContext>({
  subUsersWithAdditionalInfo: [],
  installations: [],
  books: [],
  addSubUser: () => {},
  updateSubUser: () => {},
  deleteSubUser: () => {},
  isLoading: false,
});

export const useSubUsersContext = (): SubUsersContext => useContext(subUsersContext);
