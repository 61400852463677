import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useDynamicOptions } from '@GDM/Filters';
import { Option } from '@utils/types/common-types';
import type { LoadCurveSource, MeterInvoice } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

export const SourceFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'sources', filteredInvoices, allInvoices) as Option<LoadCurveSource>[];

  return (
    <ControlledSelect
      control={form.control}
      name="sources"
      placeholder="monitoring.invoicing.source"
      options={options}
      isClearable
      isMulti
      inline
    />
  );
};

const getOptions = (invoices: MeterInvoice[]) => {
  const sources = Array.from(new Set(invoices.map((invoice) => invoice.volume_production_load_curve_source))).filter(
    Boolean,
  );

  const hasIndex = invoices.some((invoice) => invoice.volume_source === 'index');

  return [
    ...(hasIndex ? [{ value: 'index', label: 'common.index' }] : []),
    ...sources.map((source) => ({ value: source, label: `sales_management.load_curve_source.option_${source}` })),
  ];
};
