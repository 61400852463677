import { Currency } from '@utils/types/currency';

export const CURRENCY_SYMBOL_MAP: {
  [key in Currency]?: string;
} = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  ARS: '$',
  UYU: '$',
  DOP: 'RD$',
  XOF: 'FCFA',
  XAF: 'FCFA',
  PLN: 'zł',
};

export const getSymbol = (currency: Currency) => {
  return CURRENCY_SYMBOL_MAP[currency] || currency;
};
