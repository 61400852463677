import { PortfolioTableFilters } from '../context/portfolio.context';

const TABLE_COLUMN_FILTER_KEY = 'portfolio.table-column-filter';

function getTableColumnFilters(): PortfolioTableFilters | null {
  const item = localStorage.getItem(TABLE_COLUMN_FILTER_KEY);

  if (!item) return null;
  const parsedItem = JSON.parse(item);

  return itemIsColumnFilters(parsedItem) ? parsedItem : null;
}

function setTableColumnFilters(filters: PortfolioTableFilters): void {
  localStorage.setItem(TABLE_COLUMN_FILTER_KEY, JSON.stringify(filters));
}

const itemIsColumnFilters = (item: unknown): item is PortfolioTableFilters => {
  return typeof item === 'object' && item !== null && 'clientId' in item && 'commissioningDate' in item;
};

export const localStorageHelpers = {
  getTableColumnFilters,
  setTableColumnFilters,
};
