import { useState } from 'react';

const getItem = (key: string) => {
  const item = localStorage.getItem(key);

  if (!item) return null;

  return JSON.parse(item);
};

export function useLocalStorage(key: string) {
  const [value, setValue] = useState<string | number | null | object>(getItem(key));

  const set = (value: string | number | null | object) => {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  };

  const clear = () => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { clear, set, value };
}
