import React from 'react';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import dayjs from 'dayjs';

const locales = navigator.languages as string[];

export const formatDate = (date: string) =>
  `${new Date(date).toLocaleString(locales, { month: 'long' })} ${dayjs(date).year()}`;

const ReportHeader = ({
  reportDate,
  bookName,
  marketPlayerName,
  title,
}: {
  title: string;
  reportDate: string;
  bookName: string | null;
  marketPlayerName: string | null;
}) => {
  const { selectedCountry } = useRevenueReportingContext();

  return (
    <header>
      <div className="infos">
        <div className="head">
          <img src="/img/streem_logo.png" className="brand-name" />
          <div className="report-name">
            <p className="name">
              <b>
                Reporting
                {bookName && `| ${bookName}`}
                {marketPlayerName && `| ${marketPlayerName}`}
                {selectedCountry && `| ${selectedCountry}`}
              </b>
            </p>
            <p className="date">{formatDate(reportDate)}</p>
          </div>
        </div>

        <div className="email-phone">
          <p>
            <a href="mailto:info@streem.eu">info@streem.eu</a>
          </p>
          <p>+33 1 86 95 76 54</p>
        </div>
      </div>

      <div className="label">
        <h4 className="text-green">{title}</h4>
        <h5>Résultats Portefeuille</h5>
      </div>
    </header>
  );
};

export default ReportHeader;
