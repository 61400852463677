import { CommonRequestHookResponse, useRequest } from '@hooks/useRequest';
import { v2_sub_client_admin_with_books_installations_path } from '@utils/routes';
import { Book } from '@utils/types/book';
import { type LightWeightInstallation } from '@utils/types/installation';
import { SubUserWithAdditionalInfo } from '@utils/types/user';

type Data = {
  sub_users: SubUserWithAdditionalInfo[];
  installations: LightWeightInstallation[];
  books: Book[];
};

export const useSubUsersWithAdditionalInfo = (): CommonRequestHookResponse<Data> => {
  const req = useRequest<Data>(v2_sub_client_admin_with_books_installations_path(), 'GET');

  return req;
};
