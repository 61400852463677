import React, { useState } from 'react';
import { DropDownButton } from '@GDM/Button';
import { FilterContainer, Filters } from '@GDM/Filters';
import { Input } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { InputRange } from '@ui/InputRange';
import { isANumber } from '@utils/isANumber';
import matchSorterInstanceStrict from '@utils/matchSorterInstanceStrict';
import Book from '@utils/types/book';

type BooksFilter = {
  search: string;
  maxPower: number[];
};

export const BooksFilters = ({
  allBooks,
  setBooks,
}: {
  allBooks: Book[];
  setBooks: React.Dispatch<React.SetStateAction<Book[]>>;
}) => {
  const newMaxPower =
    allBooks.length > 0
      ? Math.max(
          ...allBooks
            .map((book) => book.total_power)
            .filter((totalPower): totalPower is number => totalPower === 0 || !!totalPower),
        )
      : 100;
  const [maxPower, setMaxPower] = useState<number>(newMaxPower);
  const [filters, setFilters] = useState<BooksFilter>({ search: '', maxPower: [0, maxPower] });

  if (newMaxPower !== maxPower) {
    setFilters({ search: '', maxPower: [0, newMaxPower] });
    setMaxPower(newMaxPower);
  }

  const { t } = useTranslation();

  const handleFiltering = (filters: Partial<BooksFilter>) => {
    let filteredBooks = [...allBooks];

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, ...filters };

      filteredBooks = matchSorterInstanceStrict(filteredBooks, newFilters.search, { keys: ['name'] });
      filteredBooks = filteredBooks.filter(
        (book) =>
          isANumber(book.total_power) &&
          book.total_power >= newFilters.maxPower[0] &&
          book.total_power <= newFilters.maxPower[1],
      );

      return newFilters;
    });

    setBooks(filteredBooks);
  };

  return (
    <Filters className="p-0">
      <FilterContainer>
        <Input
          id="book-name"
          onChange={(e) => handleFiltering({ search: e.currentTarget.value })}
          value={filters.search}
          data-cy="book-name-filter"
          placeholder={t('admin.books.book_name')}
          icon="Search"
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <DropDownButton label="common.total_power" icon="Filter" size="xs" dropDownMenuClassName="p-3">
          <div style={{ minWidth: '10rem' }}>
            <div className="mb-3">{t('common.total_power')}</div>
            <InputRange
              max={maxPower}
              values={filters.maxPower}
              onChange={(values) => handleFiltering({ maxPower: values })}
            />
          </div>
        </DropDownButton>
      </FilterContainer>
    </Filters>
  );
};
