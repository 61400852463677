import { PriceTuple } from '@pages/NationalPrices/national-prices.types';
import { BasicM0 } from '@utils/types/m0';
import dayjs from 'dayjs';

export const parseM0Array = (arr?: PriceTuple): BasicM0 | null => {
  if (!arr) return null;

  const [date, m0, , status] = arr;

  return {
    date: dayjs(date).format('YYYY-MM'),
    m0: m0,
    estimated: status.includes('ESTIM'),
  };
};
