import React, { useMemo, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { useUser } from '@context/User.context';
import { Button, DropDownButton } from '@GDM/Button';
import { Checkbox } from '@GDM/forms';
import { Tab, Tabs } from '@GDM/Tabs';
import { useArray } from '@hooks/useArray';
import { CreateBookModal } from '@ui/books/CreateBookModal';
import axiosInstance from '@utils/axiosInstance';
import { api_v1_launchtr_addtroperation_website_path } from '@utils/routes';
import classNames from 'classnames';
import { DataType } from '../portfolio.definitions';
import { usePortfolio } from '../usePortfolio';
import launchTrCtx from './launchTr.context';
import { Settings } from './PortfolioTable/Actions/Settings';
import { PortfolioTable } from './PortfolioTable/PortfolioTable';

const tabs: Tab<DataType>[] = [
  { id: 'installations', name: 'common.installations' },
  { id: 'books', name: 'common.books' },
];

export const DashboardViewAll = () => {
  const { dataType, portfolio, loading, setDataType, setDisplayMode } = usePortfolio();
  const { launchtr, limited } = useUser();
  const [selectedMeters, setSelectedMeters] = useState<string[]>([]);
  const [disabledMeters, pushDisabledMeter] = useArray<string>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const selectAll = () => setSelectedMeters(portfolio?.map((el) => el.ref_centrale) || []);
  const unselectAll = () => setSelectedMeters([]);

  const launchTelereleve = () => {
    selectedMeters.forEach((meterName) => {
      axiosInstance(false).get(api_v1_launchtr_addtroperation_website_path({ meter: meterName }));
    });

    pushDisabledMeter(selectedMeters);
    setSelectedMeters([]);
  };

  const handleOnCheckSelectAll = (e: React.ChangeEvent<HTMLInputElement>) =>
    e.target.checked ? selectAll() : unselectAll();

  const launchTrContextValue = useMemo(
    () => ({
      addMeter: (meterName: string) => {
        const clonedMeters = [...selectedMeters, meterName];
        setSelectedMeters(clonedMeters);
      },
      disabledMeters,
      removeMeter: (meterName: string) => {
        const clonedMeters = [...selectedMeters].filter((m) => m !== meterName);
        setSelectedMeters(clonedMeters);
      },
      selectAll: () => setSelectedMeters(portfolio?.map((el) => el.ref_centrale) || []),
      selectedMeters,
      unselectAll,
    }),
    [disabledMeters, selectedMeters, portfolio],
  );

  return (
    <launchTrCtx.Provider value={launchTrContextValue}>
      <CreateBookModal isOpen={isOpen} toggle={toggle} selectedMeters={selectedMeters} key={selectedMeters.join(',')} />

      <Tabs
        tabs={tabs}
        noContainer
        className={classNames({ unavailable: loading })}
        onTabChange={(e) => {
          setDataType?.(e);
          if (e === 'books') setDisplayMode?.('table');
        }}
      >
        <div
          className={classNames('my-3', 'px-3', 'd-flex', 'align-items-end', 'justify-content-between', {
            unavailable: loading,
          })}
        >
          <div className={classNames('d-flex align-items-center', { 'not-visible': dataType !== 'installations' })}>
            <Checkbox label="common.select_all" className="mr-4" onChange={handleOnCheckSelectAll} />

            {!limited && launchtr && (
              <Button
                size="sm"
                variant="primary-2"
                onClick={launchTelereleve}
                tooltip="monitoring.portfolio.launch_tr"
                icon="PhoneCall"
                disabled={!selectedMeters.length || selectedMeters.length > 10}
              />
            )}

            <Restricted permissions={['display:edit_monitoring']}>
              <Button
                size="sm"
                variant="primary-2"
                className="ml-2"
                onClick={() => toggle()}
                tooltip="monitoring.portfolio.create_book"
                icon="Book"
                disabled={!selectedMeters.length}
              />
            </Restricted>
          </div>

          <DropDownButton
            className="ml-2"
            icon="Settings"
            variant="primary-2"
            size="sm"
            position="right"
            tooltip="contracts.table.show_or_hide_columns"
            data-cy="portfolio-table-settings-button"
            noChevron
          >
            <div className="p-2">
              <Settings />
            </div>
          </DropDownButton>
        </div>

        {portfolio && <PortfolioTable />}
      </Tabs>
    </launchTrCtx.Provider>
  );
};
