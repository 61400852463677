import React from 'react';
import SmartTranslator from '@components/SmartTranslator';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { CurrencyCell } from '@GDM/Table';
import { EnergyCell } from '@GDM/Table/Cells/EnergyCell';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { ColumnDef } from '@tanstack/react-table';
import { Locale } from '@utils/types/common-types';
import { Currency } from '@utils/types/currency';
import { ReportSparkline } from './ReportSparkline';
import { InstallationRevenue } from './ReportsTable';

const getReportsTableColumn = (locale: Locale, currency: Currency): ColumnDef<InstallationRevenue>[] => {
  const columns: ColumnDef<InstallationRevenue>[] = [
    {
      header: 'common.installation',
      accessorKey: 'name',
      cell: ({ row: { original } }) => (
        <MeterNameCell installation_name={original.name} installation_energy={original.energyType} />
      ),
    },
    {
      header: '',
      enableSorting: false,
      accessorKey: 'monthly_production',
      cell: ({ getValue }) => <ReportSparkline data={getValue<InstallationRevenue['monthly_production']>()} />,
    },
    {
      header: 'common.production',
      accessorKey: 'production',
      cell: ({ getValue }) => <EnergyCell value={getValue<InstallationRevenue['production']>()} />,
    },
    {
      header: 'common.ytd_production',
      accessorKey: 'total_production',
      cell: ({ getValue }) => <EnergyCell value={getValue<InstallationRevenue['total_production']>()} />,
    },
    {
      header: () => <SmartTranslator translationKey="sales_management.average_sale_price" />,
      accessorKey: 'unit_price',
      cell: ({ getValue }) => (
        <div className="ta:right">
          <ValueWithUnit
            value={getValue<InstallationRevenue['unit_price']>()}
            unit={`${currency}/MWh`}
            valueVariant={(getValue<InstallationRevenue['unit_price']>() || 0) < 0 ? 'secondary' : null}
          />
        </div>
      ),
    },
    {
      header: () => <SmartTranslator translationKey="sales_management.bp_budget" />,
      accessorKey: 'bp',
      cell: ({ getValue }) => (
        <CurrencyCell
          value={getValue<InstallationRevenue['bp']>()}
          color={(getValue<InstallationRevenue['bp']>() || 0) < 0 ? 'red' : 'default'}
          showUnit
          compact
          currency={currency}
        />
      ),
    },
    {
      header: () => <SmartTranslator translationKey="common.revenues" />,
      accessorKey: 'revenues',
      cell: ({ getValue }) => (
        <CurrencyCell
          value={getValue<InstallationRevenue['revenues']>()}
          color={(getValue<InstallationRevenue['revenues']>() || 0) < 0 ? 'red' : 'default'}
          showUnit
          compact
          currency={currency}
        />
      ),
    },
    {
      header: () => <SmartTranslator translationKey="common.revenues_ytd" />,
      accessorKey: 'ytd_revenues',
      cell: ({ getValue }) => (
        <CurrencyCell
          value={getValue<InstallationRevenue['ytd_revenues']>()}
          color={(getValue<InstallationRevenue['ytd_revenues']>() || 0) < 0 ? 'red' : 'default'}
          showUnit
          compact
          currency={currency}
        />
      ),
    },
  ];

  return columns;
};

export default getReportsTableColumn;
