import React, { useContext, useState } from 'react';
import { Tab, Tabs } from '@GDM/Tabs';
import { Toggle } from '@GDM/Toggle';
import Page from '@pages/Page/Page';
import { User } from '@utils/types/user';
import classNames from 'classnames';
import { useSimulations } from './context/useSimulations';
import MarketValueDeviationWidget from './MarketValueDeviationWidget';
import { SimulationsMap } from './SimulationsMap';
import SimulationsTabContent from './SimulationsTabContent';
import SelectedRowsContext from './state/selectedRows';

type SimulationTabId = 'simulations' | 'full_merchant_simulations' | 'book_simulations';

const SimulationsList = ({ user }: { user: User }) => {
  const { marketSimulations, fullMerchantSimulations, bookSimulations, loading, error } = useSimulations();
  const { selectedRows, unselectAllRows } = useContext(SelectedRowsContext);
  const [showMap, setShowMap] = useState(false);

  /**
   * conditionally get tabs base on whether the user is full_merchant or not
   */
  const tabs = React.useMemo<Tab<SimulationTabId>[]>(() => {
    const allTabs: Tab<SimulationTabId>[] = [
      { id: 'simulations', name: 'pricing.portfolio.feed_in_premium' },
      { id: 'full_merchant_simulations', name: 'pricing.portfolio.full_merchant' },
      { id: 'book_simulations', name: 'Books' },
    ];

    if (user.full_merchant) {
      return allTabs;
    }

    return allTabs.filter((tab) => tab.id !== 'full_merchant_simulations');
  }, [user]);

  return (
    <Page
      title="portfolio"
      user={user}
      error={error}
      isLoading={loading}
      header={
        !loading && (
          <div className="mb-3">
            <Toggle
              onChange={(toggled) => setShowMap(toggled || false)}
              value={showMap}
              label="common.map"
              icon="Map"
            />

            <div className={classNames('d-flex', (showMap || selectedRows.length > 0) && 'mt-3')}>
              {showMap && <SimulationsMap />}

              <MarketValueDeviationWidget mapVisible={showMap} />
            </div>
          </div>
        )
      }
    >
      {marketSimulations && fullMerchantSimulations && bookSimulations && (
        <Tabs tabs={tabs} className="mb-3" onTabChange={() => unselectAllRows()}>
          <SimulationsTabContent
            marketSimulations={marketSimulations}
            fullMerchantSimulations={fullMerchantSimulations}
            bookSimulations={bookSimulations}
          />
        </Tabs>
      )}
    </Page>
  );
};

export default SimulationsList;
