import React from 'react';

const ReportFooter = () => {
  return (
    <footer>
      <p>
        Powered by <b className="text-green">streem</b>
      </p>
    </footer>
  );
};

export default ReportFooter;
