import React, { useState } from 'react';
import { DefinitionModalBody, DefinitionModalFooter } from '@GDM/DefinitionList';
import { Select } from '@GDM/forms';
import { post, useRequest } from '@hooks/useRequest';
import { change_grid_v2_dashboard_meter_index_path, v2_grids_path } from '@utils/routes';
import { Grid } from '@utils/types/grid';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';

export const GridModal = ({ meter }: { meter?: Meter }) => {
  const { grid_name, setGridName } = useMeterInfo();
  const [localGridName, setLocalGridName] = useState(grid_name);

  const { data: grids } = useRequest<Grid[]>(v2_grids_path({ country: meter?.country }), 'GET');

  const { loading: isLoading, execute } = useRequest(
    change_grid_v2_dashboard_meter_index_path(),
    ({ url }) =>
      post<Record<string, never>, { meter?: string; grid?: string }>({
        url,
        body: {
          meter: meter?.name,
          grid: localGridName,
        },
      }),
    true,
  );

  const saveUpdate = () => {
    setGridName?.(localGridName);
    execute?.();
  };

  const options = grids?.map((grid) => ({ label: grid.name, value: grid.name })) || [];

  return (
    <>
      <DefinitionModalBody>
        {grids && (
          <Select
            options={options}
            selectedOption={localGridName}
            isSearchable
            onChange={(option) => setLocalGridName(option?.value)}
          />
        )}
      </DefinitionModalBody>
      <DefinitionModalFooter save={saveUpdate} isLoading={isLoading} />
    </>
  );
};
