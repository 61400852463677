import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { v2_compteurs_path } from '@utils/routes';
import { Locale } from '@utils/types/common-types';
import { Meter } from '@utils/types/meter';
import dayjs from 'dayjs';
import { DataType } from '../portfolio.definitions';

export function usePortfolioDefaultParams(
  locale: Locale,
  errorMessage?: string,
  setErrorFunction?: React.Dispatch<React.SetStateAction<string | undefined>>,
) {
  const { t } = useTranslation(locale);
  const { data: userMeters } = useRequest<Pick<Meter, 'is_it_production_meter' | 'name'>[]>(v2_compteurs_path(), 'GET');

  const urlParams = new URLSearchParams(window.location.search);
  const onlyConsumers = userMeters ? !userMeters?.find((m) => !!m.is_it_production_meter) : null;
  const showMap = urlParams.get('show_map') === 'true';

  let firstDate = onlyConsumers ? dayjs().subtract(7, 'day') : dayjs().subtract(1, 'day');
  if (urlParams.get('start_date')) firstDate = dayjs(urlParams.get('start_date'));

  let lastDate = dayjs().subtract(1, 'day');
  if (urlParams.get('end_date')) lastDate = dayjs(urlParams.get('end_date'));

  // Make sure the date is 60 days max. If not, adjust it and show an error banner.
  if (lastDate.diff(firstDate, 'day') > 60) {
    firstDate = lastDate.subtract(60, 'day');

    if (!errorMessage)
      setErrorFunction?.(
        t('monitoring.portfolio.errors.max_range_exceeded', {
          startDate: firstDate.format('YYYY-MM-DD'),
          endDate: lastDate.format('YYYY-MM-DD'),
        }),
      );
  }

  const defaultTab = (window.location.hash.replace('#', '') || 'installations') as DataType;

  return { firstDate, lastDate, defaultTab, showMap, onlyConsumers };
}
