import React, { useCallback, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { createModalContext } from './context';
import { CreateModalForm } from './types';

export const CreateModalProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultValues: CreateModalForm = {
    installation_uuid: null,
    dates: {
      start_date: null,
      end_date: null,
    },
    p_max: null,
    event_type: null,
    capacity: {
      value: null,
      unit: 'MW',
    },
    comment: null,
  };

  const form = useForm<CreateModalForm>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const toggle = useCallback(() => {
    setIsOpen((open) => !open);
  }, [setIsOpen]);

  const contextValue = useMemo(() => ({ isOpen, toggle }), [isOpen, toggle]);

  return (
    <FormProvider {...form}>
      <createModalContext.Provider value={contextValue}>{children}</createModalContext.Provider>
    </FormProvider>
  );
};
