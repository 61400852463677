import { useContext } from 'react';
import { UseFormReturn, useFormContext } from 'react-hook-form';
import { BSMContext, FilterForm } from '../bsm.types';
import { bsmContext } from './subscriptions.context';

export const useBSM = (): UseFormReturn<FilterForm> & BSMContext => {
  const context = useContext(bsmContext);
  const formContext = useFormContext<FilterForm>();

  return {
    ...formContext,
    ...context,
  };
};
