import React from 'react';
import { RadioButtons } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { energyOptions } from '@utils/constants/energyOptions';
import { Controller } from 'react-hook-form';

export const EnergyPicker = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('energy');

  return (
    <Controller
      control={form.control}
      name="energy"
      rules={rules}
      render={({ field }) => (
        <RadioButtons
          onChange={field.onChange}
          name={field.name}
          size="lg"
          label="common.type"
          selected={field.value}
          disabled={disabled}
          options={energyOptions.filter((option) => option.value !== 'all')}
        />
      )}
    />
  );
};
