import React from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import useTranslation from '@hooks/useTranslation';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import useInvoiceNumbering from 'pages/sales-management/invoicing/utils/useInvoiceNumbering';
import styles from '../actions.module.scss';

const cannotBeNominatedToEdfOa =
  (filteredInvoices: MeterInvoice[]) =>
  (uuid: string): boolean => {
    const invoice = filteredInvoices.find((m) => m.uuid === uuid);
    if (!invoice || invoice.status !== 'approved' || invoice.contract_invoicing_platform !== 'edf_oa') return true;

    return false;
  };

export const NominateEdfOaButton = ({ toggleNominateModal }: { toggleNominateModal?: () => void }) => {
  const { t } = useTranslation();
  const { filteredInvoices } = useInvoicesContext();
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { handleClick } = useInvoiceNumbering();
  const { disabled } = usePermittedStatus('requested_submission_external_service');
  const nonNominableInvoices = selectedInvoicesUuids.filter(cannotBeNominatedToEdfOa(filteredInvoices));

  return (
    <button
      onClick={toggleNominateModal ? handleClick(toggleNominateModal, 'nominate_to_edf_oa') : undefined}
      disabled={disabled || nonNominableInvoices.length > 0}
      className={styles['menu-element']}
      data-cy="submit-index-edf-oa-button"
    >
      {t('sales_management.nominate_to_edf_oa')}
    </button>
  );
};
