import axiosInstance from '@utils/axiosInstance';
import { formatSystemDate } from '@utils/formatters';
import { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';

export const fetchCsv = async ({ url, options }: { url: string; options?: AxiosRequestConfig }) => {
  const request = await axiosInstance(false).get(url, { responseType: 'blob', ...options });

  const data = request.data;
  const startDate = options?.params?.start_date;
  const endDate = options?.params?.end_date;

  fileDownload(
    data,
    `unavailabilities_${formatSystemDate(startDate)}_${formatSystemDate(endDate)}.csv`,
    'text/csv;charset=utf-8',
    '\uFEFF',
  );

  return data;
};
