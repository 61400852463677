import React from 'react';
import { Input } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { Controller } from 'react-hook-form';

export const IbanInput = () => {
  const form = useInstallationForm();
  const { rules, disabled } = useInstallationField('iban');

  return (
    <Controller
      control={form.control}
      name="iban"
      rules={rules}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          type="text"
          label="owner.iban"
          size="lg"
          full
          disabled={disabled}
          data-cy="iban"
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};
