import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { DateInput } from '@pages/Contracts/Contract/Form/components/Inputs/Date';
import { ListInput } from '@pages/Contracts/Contract/Form/components/Inputs/List';
import { NumericInput } from '@pages/Contracts/Contract/Form/components/Inputs/Numeric';
import { getSymbol } from '@utils/currency/getSymbol';
import { Option } from '@utils/types/common-types';
import { ContractForm, PriceType } from '@utils/types/contract';
import { Currency } from '@utils/types/currency';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { DateValidators } from '../../../hooks/useDateValidation';
import styles from '../sub-period.module.scss';

export const SwapPlSubPeriod = ({
  index,
  onDatePeriodClick,
  preSelectedPeriod,
  customPeriods,
  form: { control },
  datesValidation,
  readOnly,
  currency,
  priceTypeOptions,
}: {
  index: number;
  readOnly: boolean;
  form: UseFormReturn<ContractForm>;
  onDatePeriodClick: (period: string, index: number) => void;
  preSelectedPeriod: string | undefined;
  customPeriods:
    | {
        name: string;
        dates: [Date, Date];
      }[]
    | undefined;
  currency: Currency;
  datesValidation: DateValidators;
  priceTypeOptions: Option<PriceType | null>[];
}) => {
  return (
    <div className="p-3 pb-2">
      <div className={classNames(sectionStyles.row, styles['sub-period-container'])}>
        <DateInput
          id="SubPeriodDatesPicker"
          name={`contract_sub_periods_attributes.${index}.dates`}
          control={control}
          rules={{ validate: datesValidation }}
          data-cy={`dates-picker-${index}`}
          selectedPeriod={preSelectedPeriod}
          selectsRange
          showPeriodBadge
          customPeriods={customPeriods}
          onClickPeriod={(period) => onDatePeriodClick(period, index)}
          readOnly={readOnly}
          label="sales_management.sub_period.start_end_dates"
        />
      </div>
      <div>
        <div className="mb-3">
          <div className={styles['subtitle']}>Auction Price</div>
          <div className={sectionStyles.row}>
            <NumericInput
              id="SwapFixedPrice"
              control={control}
              rules={{ required: true, max: 10000 }}
              name={`contract_sub_periods_attributes.${index}.extra_params.swap_fixed_price`}
              data-cy={`swap-fixed-price-input-${index}`}
              label="sales_management.swap_fixed_price"
              suffix={`${getSymbol(currency)}/MWh`}
              max={10000}
              step={1}
              required
              readOnly={readOnly}
              helpBox={{ title: 'sales_management.swap_fixed_price', text: 'sales_management.swap_spot_info' }}
            />
            <ListInput
              id="PriceType"
              control={control}
              options={priceTypeOptions}
              name={`contract_sub_periods_attributes.${index}.price_type`}
              label="common.price_type"
              rules={{ required: true }}
              menuPlacement="top"
              readOnly={readOnly}
              classNamePrefix={`select-price-type-${index}`}
              defaultValue="indexed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
