import React from 'react';
import { FormErrorAlert, FormErrorContainer, FormTitle } from '@GDM/FormErrors';
import { ERRORS_TO_REMAP } from '@GDM/FormErrors/errors-to-remap';
import useTranslation from '@hooks/useTranslation';
import type { BaseError, ReqError } from '@utils/fetch';

export const FormErrors = ({ error, title }: { error: ReqError | null | undefined; title: string }) => {
  const { t } = useTranslation();
  const errors = error?.errors;

  const printError = (error?: Partial<BaseError>) => {
    if (!error) return t('errors.unknown_error');

    const { date, count, field, code } = error;

    const errorI18nString = code ? ERRORS_TO_REMAP[code] || code : 'errors.unknown_error';

    return t(errorI18nString, { prop: `<code>${field}</code>`, date, count });
  };

  return (
    <FormErrorAlert>
      <FormTitle>{t(title)}</FormTitle>
      <FormErrorContainer>
        {!!errors?.length &&
          errors.map((error) => (
            <li key={JSON.stringify(error)} dangerouslySetInnerHTML={{ __html: printError(error) }} />
          ))}
        {!errors?.length && <li>{t(error?.code || 'errors.unknown_error')}</li>}
      </FormErrorContainer>
    </FormErrorAlert>
  );
};
