import { createContext, useContext } from 'react';

export type BooksPageContext = {
  onDelete: (uuid: string) => void;
  onSelect: (uuid: string) => void;
};

export const booksPageContext = createContext<BooksPageContext>({
  onDelete: () => {},
  onSelect: () => {},
});

export const useBooksPageProvider = () => useContext(booksPageContext);
