import { formatSystemDate } from '@utils/formatters';
import dayjs from 'dayjs';
import { InvoiceRevenueRequestParameters } from '../revenue.types';
import { removeInvalidParams } from './removeInvalidParams';

/**
 * Updates the date format for certain requests and removes null values from the dictionary of parameters.
 */
export const serializeSearchParams = (input: InvoiceRevenueRequestParameters): InvoiceRevenueRequestParameters => {
  const params: InvoiceRevenueRequestParameters = {
    ...input,
    start: formatSystemDate(input.start),
    end:
      input.resolution === 'monthly'
        ? dayjs(input.end).add(1, 'month').format('YYYY-MM-DD')
        : dayjs(input.end).add(1, 'day').format('YYYY-MM-DD'),
  };

  return removeInvalidParams(params);
};
