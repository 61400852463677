import { User } from '@utils/types/user';
import { COUNTRIES } from './constants/countries/countries';
import { CountryCode, Timezone } from './types/countries';

/**
 * Get timezone from country code
 * @param country
 */
export const getTimezone = (country: CountryCode): Timezone => {
  return COUNTRIES[country].timezone;
};

/**
 * Get timezone of user
 * @param user
 */
export const getUserTimezone = (user: User): Timezone =>
  user.main_country ? getTimezone(user.main_country) : 'Europe/Paris';
