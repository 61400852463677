import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useSelectedYear } from '@pages/Simulations/Simulation/utils/selectedYear.context';
import { FullMerchant } from '@pages/Simulations/Simulation/utils/simulation.types';
import { ConfidenceValue } from '../ConfidenceValue';
import styles from './full-merchant-definition.module.scss';

export const LocalCapturePriceDefinition = () => {
  const project = useProjectContext();
  const { selectedYear } = useSelectedYear();
  const merchantDeviationsPerYear = project.full_merchant?.merchant_deviations_per_year || {};

  if (!selectedYear) return null;

  const currentYear: FullMerchant['merchant_deviations_per_year'][number] =
    Object.values(merchantDeviationsPerYear)[selectedYear as unknown as number];
  const forwardPrice = currentYear.forward_price;
  const localVsBaseloadYear = currentYear.loc_vs_base;
  const nationalVsBaseloadYear = currentYear.nat_vs_base;
  const localVsNationalYear = currentYear.loc_vs_nat;
  const localCapturePrice = forwardPrice + nationalVsBaseloadYear.q50 + localVsNationalYear.q50;
  const localVsBaseloadVariant = localVsBaseloadYear.q50 > 0 ? 'primary-1' : 'secondary';
  const localVsNatVariant = localVsBaseloadYear.q50 - nationalVsBaseloadYear.q50 > 0 ? 'primary-1' : 'secondary';

  return (
    <Definition
      label="pricing.project.local_capture_price"
      value={
        <div className={styles['multiline-definition-value']}>
          <ValueWithUnit value={localCapturePrice} unit="EUR/MWh" />

          <div className={styles['definition-range']}>
            <ValueWithUnit
              valueVariant={localVsNatVariant}
              value={localVsBaseloadYear.q50 - nationalVsBaseloadYear.q50}
              unit="EUR/MWh"
            />
            <ConfidenceValue
              className="ml-2"
              values={[
                { value: localVsBaseloadYear.q25 - nationalVsBaseloadYear.q25 },
                { value: localVsBaseloadYear.q75 - nationalVsBaseloadYear.q75 },
              ]}
              append="pricing.project.vs_national"
            />
          </div>

          <div className={styles['definition-range']}>
            <ValueWithUnit valueVariant={localVsBaseloadVariant} value={localVsBaseloadYear.q50} unit="EUR/MWh" />
            <ConfidenceValue
              className="ml-2"
              values={[{ value: localVsBaseloadYear.q25 }, { value: localVsBaseloadYear.q75 }]}
              append="pricing.project.vs_baseload"
            />
          </div>
        </div>
      }
    />
  );
};
