import { useUser } from '@context/User.context';
import useTranslation from '@hooks/useTranslation';
import { isQueryTimeBased } from '@pages/CustomReporting/utils';
import { ReportMetricData, ReportMetric, CustomReportingGlobalQuery } from '../../types';
import { FullPeriodReportTableData, TimeBasedReportTableData } from './types';
import {
  createTimeBasedTableRows,
  createTimeBasedTableColumns,
  createFullPeriodTableColumns,
  createFullPeriodReportTableRows,
  computeTimeBasedAggregations,
  computeFullPeriodAggregations,
} from './utils';

export function useReportTableColumnsAndRows(
  data: ReportMetricData[],
  query: CustomReportingGlobalQuery,
  metrics: Record<ReportMetric, boolean>,
): TimeBasedReportTableData | FullPeriodReportTableData {
  const { t } = useTranslation();
  const user = useUser();
  const isTimeBased = isQueryTimeBased(query);

  if (isTimeBased) {
    const rowsRecord = createTimeBasedTableRows(data, query, user.main_country, t);
    const displayedMetric = Object.keys(metrics).find(
      (metric) => metrics[metric as ReportMetric] && rowsRecord[metric as ReportMetric],
    ) as ReportMetric;

    const aggregations = computeTimeBasedAggregations(rowsRecord);
    const displayedMetricAggregations = aggregations[displayedMetric];

    return {
      isTimeBased: true,
      columns: createTimeBasedTableColumns(data, metrics, query, displayedMetricAggregations, t),
      aggregations: displayedMetricAggregations,
      rows: rowsRecord[displayedMetric],
    };
  }

  const rows = createFullPeriodReportTableRows(data, query, user.main_country, t);
  const aggregations = computeFullPeriodAggregations(rows);

  return {
    isTimeBased: false,
    columns: createFullPeriodTableColumns(metrics, query.focus, rows, aggregations, t),
    rows,
    aggregations,
  };
}
