import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { useSelectedYear } from '@pages/Simulations/Simulation/utils/selectedYear.context';

export const LocalQualityFactorDefinition = () => {
  const project = useProjectContext();
  const { selectedYear } = useSelectedYear();
  const { forward_prices } = project?.full_merchant || {};
  const { full_merchant_cannibalized_distribution_cat, local_vs_baseload } = project?.full_merchant || {};

  if (!selectedYear) return null;

  const yearIndex = full_merchant_cannibalized_distribution_cat?.[selectedYear as unknown as number];
  const forwardPrice = forward_prices?.[yearIndex as unknown as number] || 0;
  const localVsBaseloadYear = local_vs_baseload?.[yearIndex as unknown as number];
  const localCapturePrice = forwardPrice + (localVsBaseloadYear?.q50 || 0);
  const localQualityFactor = (localCapturePrice / forwardPrice) * 100;

  return (
    <Definition
      label="pricing.project.full_merchant_local_quality_factor"
      value={<ValueWithUnit value={localQualityFactor} unit="percentage" />}
    />
  );
};
