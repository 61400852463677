import React from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { tString } from '@hooks/useTranslation';
import { ColumnDef, Row } from '@tanstack/react-table';
import { Locale } from '@utils/types/common-types';
import { Event } from '@utils/types/event';
import { CommentCell } from './CommentCell';

type Props = {
  locale: Locale;
  isPortfolio?: boolean;
};

const getColumns = ({ locale, isPortfolio }: Props): ColumnDef<Event>[] => {
  const t = tString(locale);

  const METER: ColumnDef<Event> = {
    header: 'common.installation',
    accessorKey: 'meter_name',
    cell: ({ row: { original } }: { row: Row<Event> }) => {
      const installation = {
        name: original.installation.name,
        energy: original.installation.energy,
        average_q50: null,
        latitude: null,
        longitude: null,
        nb_simulations: null,
        owner_company_number: null,
        has_meter: null,
        max_value: null,
      };

      return (
        <MeterNameCell
          hasMeter={installation.has_meter}
          installation_name={installation.name || ''}
          installation_energy={installation.energy}
          muted={original.finished}
        />
      );
    },
  };

  const event_TYPE: ColumnDef<Event> = {
    header: 'common.type',
    id: 'event_type',
    accessorKey: 'type',
    cell: CommentCell,
  };

  const CREATED_AT: ColumnDef<Event> = {
    header: 'common.created_at',
    accessorKey: 'start_date',
  };

  const ENDED_AT: ColumnDef<Event> = {
    header: 'monitoring.alerts.end',
    accessorKey: 'end_date',
  };

  const OPEN: ColumnDef<Event> = {
    header: 'common.open',
    accessorKey: 'finished',
    cell: ({ row }) => {
      if (!row.original.finished) {
        return <div>{t('common.yes')}</div>;
      } else {
        return <div>{t('common.no')}</div>;
      }
    },
  };

  return [...(isPortfolio ? [METER] : []), event_TYPE, CREATED_AT, ENDED_AT, OPEN];
};

export default getColumns;
