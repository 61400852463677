import { MeterInvoice } from '@utils/types/meter-invoice';

/**
 * This one is specific to MeterInvoice
 */
export default function isForecastType(meterInvoice: MeterInvoice, filters: { showForecast: boolean }): boolean {
  const isForecast = meterInvoice.forecast || meterInvoice.volume_forecast;

  // we return everything if filters is set to forecast and only non forecast if filters is set to empty or null
  return filters.showForecast || (!filters.showForecast && !isForecast);
}
