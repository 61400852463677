import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './section.module.scss';

export const Section = ({
  children,
  className,
  'data-cy': dataCy,
}: {
  className?: string;
  'data-cy'?: string;
  children: ReactNode;
}) => {
  return (
    <div className={classNames(styles.container, className)} data-cy={dataCy}>
      {children}
    </div>
  );
};
