import { isGroupedData } from '../helpers/type-helpers';
import { InvoiceRevenueDataPoint, RevenueContextType } from '../revenue.types';

export function buildPeriodList(
  revenue: RevenueContextType['revenue'],
  overview: RevenueContextType['overview'],
): string[] {
  if (!revenue && !overview) return [];

  const concatenatedPeriods: string[] = [];

  const addDataPoint = (dataPoint: InvoiceRevenueDataPoint) => {
    const value = dataPoint?.period ?? dataPoint?.period;
    if (!concatenatedPeriods.includes(value)) concatenatedPeriods.push(value);
  };

  // Find all the periods in the revenue object
  if (isGroupedData(revenue)) {
    revenue.forEach((item) => item.values?.forEach(addDataPoint));
  } else {
    revenue?.forEach(addDataPoint);
  }

  // Find all the periods in the overview objects
  overview?.forEach((item) => item.data.forEach(addDataPoint));

  return concatenatedPeriods;
}
