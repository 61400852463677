import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useDynamicOptions } from '@GDM/Filters';
import { Option } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

const getOptions = (invoices: MeterInvoice[]): Option<MeterInvoice['status']>[] => {
  const invoicesStatusList = invoices.map((invoice) => invoice.status);

  const invoiceStatusSelectOptions: Option<MeterInvoice['status']>[] = [
    { value: 'created', label: 'common.filters.invoice_status.created' },
    { value: 'approved', label: 'common.filters.invoice_status.approved' },
    { value: 'generated', label: 'common.filters.invoice_status.generated' },
    {
      value: 'requested_submission_external_service',
      label: 'common.filters.invoice_status.requested_submission_external_service',
    },
    { value: 'accepted_external_service', label: 'common.filters.invoice_status.accepted_external_service' },
    { value: 'payment_initiated', label: 'common.filters.invoice_status.payment_initiated' },
    { value: 'paid', label: 'common.filters.invoice_status.paid' },
    { value: 'rejected', label: 'common.filters.invoice_status.rejected' },
    { value: 'accepted_offtaker', label: 'common.filters.invoice_status.accepted_offtaker' },
    { value: 'sent_to_erp', label: 'common.filters.invoice_status.sent_to_erp' },
    { value: 'ignored', label: 'common.filters.invoice_status.ignored' },
    { value: 'pending', label: 'common.filters.invoice_status.pending' },
  ];

  const allInvoiceStatusSelectOptions: Option<MeterInvoice['status']>[] = [
    ...invoiceStatusSelectOptions.filter((option) => invoicesStatusList.includes(option.value)),
  ];

  return allInvoiceStatusSelectOptions;
};

export const InvoiceStatusFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'invoice_status', filteredInvoices, allInvoices);

  return (
    <ControlledSelect
      control={form.control}
      name="invoice_status"
      classNamePrefix="filter-invoice-status"
      placeholder="common.status_label"
      isClearable
      isMulti
      options={options}
      inline
    />
  );
};
