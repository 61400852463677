import { DatePickerProps, PreDefinedPeriods } from '../date-picker.types';
import { DEFAULT_PERIODS } from './default-periods';

/**
 * Build a list of period names based on the `periods` and `customPeriods` props.
 */
export function getPeriods(params?: {
  periods?: DatePickerProps['periods'];
  customPeriods?: DatePickerProps['customPeriods'];
}): Array<string | PreDefinedPeriods> {
  if (!params?.periods && !params?.customPeriods) return DEFAULT_PERIODS;

  const periods = new Set<string | PreDefinedPeriods>([
    ...(params?.periods || []),
    ...(params?.customPeriods?.map((p) => p?.name) || []),
  ]);

  return Array.from(periods);
}
