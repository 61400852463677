import React from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const InstallationCell = ({ row: { original } }: CellContext<MeterInvoice, unknown>) => (
  <MeterNameCell
    installation_name={original.installation_name}
    installation_energy={original.installation_energy}
    client_id={original.installation_client_id}
  />
);
