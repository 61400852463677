import { SimulationFormField, FormSection } from '../utils/simulations.types';

export const DEFAULT_SECTIONS = new Map<SimulationFormField['name'], FormSection>([
  ['start_date', 'simulation'],
  ['end_date', 'simulation'],
  ['perturbate_prices', 'simulation'],
  ['fixed_stop_strike', 'simulation'],
  ['dynamic_stop_strike', 'simulation'],
  ['neg_hours_limit', 'simulation'],
  ['installation_attributes.name', 'project'],
  ['installation_attributes.longitude', 'project'],
  ['installation_attributes.latitude', 'project'],
  ['installation_attributes.energy', 'project'],
]);

export const WIND_SECTIONS = new Map<SimulationFormField['name'], FormSection>([
  ...DEFAULT_SECTIONS,
  ['height', 'installation'],
  ['nb_turb', 'installation'],
  ['total_power_wind', 'installation'],
  ['turbine_id', 'installation'],
  ['ppa_wind_bounds_attributes.0.debut_mois_contrainte', 'constraints'],
  ['ppa_wind_bounds_attributes.0.fin_mois_contrainte', 'constraints'],
  ['ppa_wind_bounds_attributes.0.start', 'constraints'],
  ['ppa_wind_bounds_attributes.0.finish', 'constraints'],
  ['ppa_wind_bounds_attributes.0.maximum_power', 'constraints'],
]);

export const SOLAR_SECTIONS = new Map<SimulationFormField['name'], FormSection>([
  ...DEFAULT_SECTIONS,
  ['tracker', 'installation'],
  ['orientation', 'installation'],
  ['inclination', 'installation'],
  ['total_power_solar', 'installation'],
  ['maxangle_tracker_morning', 'installation'],
  ['maxangle_tracker_evening', 'installation'],
]);
