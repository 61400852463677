import fetchWrapper, { type ReqError } from '@utils/fetch';
import { v2_data_providers_path } from '@utils/routes';
import type { CountryCode } from '@utils/types/countries';
import type { Grid } from './grids';

export type DataProvider = {
  id: number;
  name: string;
  grid?: Omit<Grid, 'data_providers'>;
};

export const fetchDataProviders = async (country: CountryCode): Promise<DataProvider[]> => {
  const req = await fetchWrapper(v2_data_providers_path({ params: { country } }));
  const data = await req.json();

  if (!req.ok) {
    throw { code: data.code, message: data.message, errors: data.errors } satisfies ReqError;
  }

  return data;
};
