import React from 'react';
import { ValueCard } from '@GDM/Card';
import useTranslation from '@hooks/useTranslation';
import { BasicM0 } from '@utils/types/m0';
import classNames from 'classnames';
import { useNationalPricesContext } from './context';
import styles from './national-prices.module.scss';

export const M0Card = ({
  m0,
  type,
  isLoading,
}: {
  m0: BasicM0 | null;
  type: 'wind' | 'solar' | 'm0_base' | 'base';
  isLoading?: boolean;
}) => {
  const { tLocalized } = useTranslation();
  const { currency } = useNationalPricesContext();
  const baseTitle = tLocalized(`sales_management.national_prices.title_${type}`, { date: m0?.date });
  const estimatedTitle = m0?.estimated ? `(${tLocalized('common.estimated')})` : '';
  const title = `${baseTitle} ${estimatedTitle}`;

  return (
    <ValueCard
      title={title}
      data-cy={`card-m0-${type}`}
      unit={`${currency}/MWh`}
      value={m0?.m0}
      className={classNames('mt-2 mb-0', styles['card-value'], isLoading && 'is-loading')}
    />
  );
};
