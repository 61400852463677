// transform hash into query params string
export function setQueryParams(params: Record<string, unknown>): void {
  const url = new URL(window.location.href);

  for (const key in params) {
    url.searchParams.set(key, `${params[key]}`);
  }

  window.history.pushState({}, '', url);
}

// parse string into query params hash
export function getQueryParams(): Record<string, string> {
  const url = new URL(window.location.href);
  const params: { [key: string]: string } = {};

  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}
