import React from 'react';
import { Alert } from '@GDM/Alert';
import { Spinner } from '@GDM/Spinner';
import type { ReactiveData } from '@utils/types/reactiveData';
import { ReactiveTable } from './ReactiveTable';

export const ReactiveConstraints = ({
  isLoading,
  data,
  error,
}: {
  isLoading: boolean;
  data: ReactiveData[] | null;
  error: string | null;
}) => {
  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-4">
          <Spinner size="sm" />
        </div>
      )}
      {!isLoading && data && (
        <div className="mt-4">
          <ReactiveTable data={data} />
        </div>
      )}
      {!isLoading && error && (
        <div className="d-flex justify-content-center mt-4">
          <Alert variant="warning" label={error} />
        </div>
      )}
    </>
  );
};
