import React, { CSSProperties, TextareaHTMLAttributes } from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import styles from './text-area.module.scss';

export type TextAreaProps = {
  className?: string;
  full?: boolean;
  hasError?: boolean;
  icon?: IconNames;
  tooltip?: string;
  inline?: boolean;
  label?: string;
  labelStyle?: CSSProperties;
  size?: 'lg';
  suffix?: string;
  iconOnClick?: () => void;
  'data-cy'?: string;
  errorMessage?: string | null;
};

export const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'> & TextAreaProps
>(
  (
    {
      autoComplete,
      className,
      disabled,
      full,
      hasError,
      id,
      tooltip,
      label,
      labelStyle,
      name,
      onClick,
      placeholder,
      suffix,
      value,
      errorMessage,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const htmlID = id || uniqueId();

    return (
      <div>
        {!!label && (
          <label className={styles.label} htmlFor={htmlID} style={labelStyle}>
            <span>{t(label)}</span>
            {tooltip && <Icon name="Info" size={14} title={tooltip} className="ml-1" />}
          </label>
        )}

        <textarea
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          ref={ref}
          id={htmlID}
          placeholder={placeholder && t(placeholder)}
          name={name}
          defaultValue={value}
          disabled={disabled}
          onClick={onClick}
          autoComplete={autoComplete}
          className={classNames(styles.textarea, hasError && styles.error, full && styles.full, className)}
          data-cy={props['data-cy']}
        />
        {!!suffix && <span className={styles.suffix}>{suffix}</span>}
        {!!errorMessage && <Text size="sm" className="mt-1" type="danger" text={errorMessage} />}
      </div>
    );
  },
);
