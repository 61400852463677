import { formatSystemDateTime } from '@utils/formatters/formatSystemDateTime';
import { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';
import { CreateModalForm, SubmitBody } from './types';

export const convertFormToBody = (form: CreateModalForm): SubmitBody => {
  const body: SubmitBody = {
    installation_uuid: form.installation_uuid,
    start_date: formatSystemDateTime(form.dates.start_date),
    end_date: formatSystemDateTime(form.dates.end_date),
    capacity_value: +Number(form.capacity.value).toFixed(3),
    capacity_unit: form.capacity.unit,
    p_max: form.p_max,
    comment: form.comment,
    event_type: form.event_type,
  };

  return body;
};

export const convertUnavailabilityToForm = (unavailability: Unavailability): CreateModalForm => {
  const body: CreateModalForm = {
    installation_uuid: unavailability.installation.uuid,
    dates: {
      start_date: dayjs(unavailability.start_date).toDate(),
      end_date: dayjs(unavailability.end_date).toDate(),
    },
    capacity: {
      unit: unavailability.capacity_unit,
      value: unavailability.capacity_value,
    },
    event_type: unavailability.event_type,
    p_max: unavailability.p_max,
    comment: unavailability.comment,
  };

  return body;
};
