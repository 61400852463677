import React from 'react';
import classNames from 'classnames';
import styles from './card.module.scss';

export const CardBody = ({
  children,
  className,
}: React.PropsWithChildren<{
  className?: string;
}>) => {
  return <div className={classNames(styles['card-body'], className)}>{children}</div>;
};
