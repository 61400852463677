import React, { useCallback } from 'react';
import { FilterContainer, Filters } from '@GDM/Filters';
import { Input, RadioButtons, Select, CountrySelect } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { TypeFilter } from '@pages/Portfolio/portfolio.definitions';
import { energyOptions } from '@utils/constants/energyOptions';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import type Installation from '@utils/types/installation';
import { useInstallationsPageContext } from './context/installations-page.context';

export type InstallationFiltersType = {
  name: string;
  type: TypeFilter;
  status: Installation['status'] | null;
  country: Installation['country'] | null;
};

export const InstallationsFilters = () => {
  const { t } = useTranslation();
  const { filters, setFilters, installations } = useInstallationsPageContext();

  const searchInstallations = useCallback(
    (name: string) => {
      setFilters((prevFilters) => ({ ...prevFilters, name }));
    },
    [setFilters],
  );

  const searchInType = useCallback(
    (energyType: TypeFilter) => {
      setFilters((prevFilters) => ({ ...prevFilters, type: energyType }));
    },
    [setFilters],
  );

  const searchInStatus = useCallback(
    (status: Installation['status'] | null) => {
      setFilters((prevFilters) => ({ ...prevFilters, status }));
    },
    [setFilters],
  );

  const searchInCountry = useCallback(
    (country: Installation['country'] | null) => {
      setFilters((prevFilters) => ({ ...prevFilters, country }));
    },
    [setFilters],
  );

  const statusOptions: Option<Installation['status']>[] = [
    { value: 'operational', label: 'common.active' },
    { value: 'draft', label: 'common.draft' },
  ];

  const countryOptions: Option<CountryCode | null>[] = Array.from(
    new Set(installations.map((installation) => installation.country)),
  )
    .map((country) => ({ label: `countries.${country}`, value: country }))
    .sort(sortOptionsByLabelAsc);

  return (
    <Filters>
      <FilterContainer>
        <Input
          type="text"
          id="installation_name"
          icon="Search"
          onChange={(e) => searchInstallations(e.currentTarget.value)}
          value={filters.name}
          placeholder={t('common.filters.installation_name')}
        />
      </FilterContainer>

      <FilterContainer>
        <CountrySelect
          options={countryOptions}
          onChange={(option) => searchInCountry(option?.value as CountryCode | null)}
          selectedOption={filters.country}
          placeholder="common.country"
          classNamePrefix="country-picker"
          neverDisable
          isClearable
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons
          options={energyOptions}
          selected={filters.type}
          onChange={(energy: TypeFilter) => searchInType(energy)}
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <Select
          placeholder="contracts.status"
          options={statusOptions}
          selectedOption={filters.status}
          onChange={(option) => searchInStatus(option?.value ?? null)}
          isClearable
        />
      </FilterContainer>
    </Filters>
  );
};
