import React from 'react';
import { UserMenuDropDown } from '../UserMenuDropDown';
import { UserMenuInfosPanel, UserMenuInfosTitle } from '../UserMenuInfos';
import styles from './user-menu.module.scss';

export const UserMenu = () => {
  return (
    <div className={styles['container']} data-cy="user-menu-trigger">
      <UserMenuDropDown title={<UserMenuInfosTitle />} panel={<UserMenuInfosPanel />} />
    </div>
  );
};
