import React, { useEffect } from 'react';
import { useSubUsersContext } from '@context/admin/users/subusers.context';
import { Button } from '@GDM/Button';
import { patch, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { v2_user_path } from '@utils/routes';
import { capitalizeFirstLetter } from '@utils/string';
import { StreemModule } from '@utils/types/common-types';
import { Role } from '@utils/types/role';
import { SubUser, SubUserWithAdditionalInfo } from '@utils/types/user';
import { useForm } from 'react-hook-form';
import { GiveablePermissions } from '../Users';
import PermissionSelect from './PermissionSelect';

const parseModuleFromRole = (role: Role): StreemModule => {
  return role.split('_')[1] as StreemModule;
};

const rolesToFormData = (roles: Role[], giveablePermissions: GiveablePermissions): PermissionsForm => {
  const response: PermissionsForm = {};

  Object.keys(giveablePermissions).forEach((module) => {
    response[module as keyof PermissionsForm] = '';
  });

  roles?.forEach((role) => {
    const module = parseModuleFromRole(role);

    if (giveablePermissions[module]) {
      response[module] = role;
    }
  });

  return response;
};

export type FormRole = Role | '';

export type PermissionsForm = {
  [key in StreemModule]?: FormRole;
};

const UserPermissions = ({
  selectedSubUser,
  giveablePermissions,
  onClose,
}: {
  selectedSubUser: SubUser;
  giveablePermissions: GiveablePermissions;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { updateSubUser } = useSubUsersContext();
  const form = useForm<PermissionsForm>({
    defaultValues: rolesToFormData(selectedSubUser.roles || [], giveablePermissions),
  });
  const updateRolesRequest = useRequest<SubUserWithAdditionalInfo>(v2_user_path(selectedSubUser.uuid), patch, true);

  const handleSubmit = (formData: PermissionsForm) => {
    updateRolesRequest.execute?.({
      roles: Object.keys(formData).map((key) =>
        formData[key as keyof PermissionsForm] === '' ? `destroy_${key}` : formData[key as keyof PermissionsForm],
      ),
    });
  };

  useEffect(() => {
    if (updateRolesRequest.loaded && updateRolesRequest.data) {
      updateSubUser(updateRolesRequest.data);
    }
  }, [updateRolesRequest, updateSubUser]);

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      {Object.keys(giveablePermissions).map((module) => {
        return (
          <PermissionSelect
            control={form.control}
            roles={giveablePermissions[module as keyof GiveablePermissions]}
            label={capitalizeFirstLetter(module)}
            name={module as StreemModule}
            key={module}
          />
        );
      })}

      {updateRolesRequest.error && <p className="text-red">{t('errors.unknown_error')}</p>}
      <div className="d-flex">
        {onClose && <Button variant="link" size="sm" text="common.cancel" onClick={onClose} className="ml-auto mr-2" />}
        <Button
          type="submit"
          variant="primary-2"
          size="sm"
          className="mr-0"
          disabled={updateRolesRequest.loading}
          isLoading={updateRolesRequest.loading}
          text="common.save"
        />
      </div>
    </form>
  );
};

export default UserPermissions;
