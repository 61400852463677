import { EnergyType } from './types/common-types';

const getMeterType = (input: unknown): EnergyType | 'book' | null => {
  if (!input || typeof input !== 'object') return null;

  if ('book' in input && input.book) return 'book';
  if ('meter_energy' in input && input.meter_energy) return input.meter_energy as EnergyType;

  return null;
};

export default getMeterType;
