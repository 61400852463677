export const chartColors = {
  '--chart-grey': '#ceced3',
  '--chart-purple': '#785589',
  '--chart-blue': '#01baef',
  '--chart-yellow': '#ffdf64',
  '--chart-orange': '#f26419',
  '--chart-red': '#f45b69',
  '--chart-aqua': '#028090',
  '--chart-deep-purple': '#4c1036',
};
