import { useCallback, useState } from 'react';
import { initEndDate, initStartDate } from '../constants';
import type { DateRange } from '../production.types';

export const useSelectedDateRange = (
  startDate: Date = initStartDate,
  endDate: Date = initEndDate,
): [DateRange, (dateRange: DateRange | null) => void] => {
  const [dateRange, setDateRange] = useState<DateRange>([startDate, endDate]);

  const updateDateRange = useCallback(
    (dateRange: DateRange | null) => {
      if (!dateRange) {
        setDateRange([startDate, endDate]);

        return;
      }

      const [selectedStart, selectedEnd] = dateRange;

      setDateRange([selectedStart, selectedEnd]);
    },
    [startDate, endDate],
  );

  return [dateRange, updateDateRange];
};
