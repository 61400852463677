import React from 'react';
import { AcceptableUnits, ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import { card } from '../card.module.scss';
import { CardTitle } from '../CardTitle';
import styles from './value-card.module.scss';

export const ValueCard = ({
  'data-cy': dataCy,
  className,
  children,
  title,
  tooltip,
  text,
  value,
  unit,
  valueVariant,
}: React.PropsWithChildren<{
  title?: string;
  tooltip?: string;
  className?: string;
  value?: number | null;
  unit?: AcceptableUnits;
  text?: string;
  valueVariant?: 'primary-1' | 'primary-2' | 'secondary' | null;
  'data-cy'?: string;
}>) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(card, styles['value-card'], className)}>
      {title && <CardTitle title={title} tooltip={tooltip} />}
      <div className={styles.value}>
        <ValueWithUnit value={value} unit={unit} valueVariant={valueVariant} data-cy={dataCy} />
      </div>
      {(text || children) && <div>{text ? t(text) : children}</div>}
    </div>
  );
};
