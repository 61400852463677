import { createContext } from 'react';
import { M0DeviationContextType } from './m0-deviation.types';

export const m0DeviationContext = createContext<M0DeviationContextType>({
  startDate: null,
  endDate: null,
  m0Aggregated: {},
  m0ByInstallation: {},
  tableData: [],
  csvData: [],
  energy: null,
  currency: 'EUR',
  source: null,
});
