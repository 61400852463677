import React from 'react';
import { Spinner } from '@GDM/Spinner';

export const LoadingTBody = ({ colSpan }: { colSpan?: number }) => (
  <tbody>
    <tr>
      <td colSpan={colSpan || 100} className="ta:center p-4">
        <Spinner />
      </td>
    </tr>
  </tbody>
);
