import React from 'react';
import useTranslation from '@hooks/useTranslation';
import styles from './table-page-size-select.module.scss';

export const TablePageSizeSelect = ({
  pageSize,
  setPageSize,
  totalEntries,
}: {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  totalEntries: number;
}) => {
  const { t } = useTranslation();

  return (
    <label className={styles['page-size-select']}>
      <select defaultValue={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
        <option value={10}>10 {t('common.filters.page_size.rows_per_pages')}</option>
        <option value={15}>15 {t('common.filters.page_size.rows_per_pages')}</option>
        <option value={25}>25 {t('common.filters.page_size.rows_per_pages')}</option>
        <option value={50}>50 {t('common.filters.page_size.rows_per_pages')}</option>
        <option value={totalEntries}>{t('common.filters.page_size.show_all')}</option>
      </select>
    </label>
  );
};
