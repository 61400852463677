import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { cancel_v2_invoice_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { InvoiceDestructiveActionButton } from './InvoiceDestructiveActionButton';

export const CancelInvoiceButton = ({ invoice, className }: { invoice: MeterInvoice; className?: string }) => {
  const { t } = useTranslation();
  const allowedStatuses: MeterInvoice['status'][] = ['generated', 'sent_to_erp', 'paid', 'accepted_offtaker'];

  if (!allowedStatuses.includes(invoice.status) || invoice.cancelled) return null;

  return (
    <InvoiceDestructiveActionButton
      modalTitle="sales_management.cancel_title"
      confirmationText={t('sales_management.confirm_cancel', {
        newType: t('common.cancelled'),
        newStatus: t('common.filters.invoice_status.approved'),
      })}
      warningText={t('sales_management.cancel_warning')}
      invoice={invoice}
      path={cancel_v2_invoice_path(invoice.uuid)}
      submitButtonInfo={t('sales_management.cancel_invoice')}
      permissions={['display:edit_invoices']}
      data-cy="cancel-invoice"
      className={className}
    />
  );
};
