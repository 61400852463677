import React from 'react';
import { Input } from '@GDM/forms';
import { Col } from '@GDM/layout';

const SignatoryNameInput = ({
  value,
  onChange,
  disabled,
}: {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}) => {
  return (
    <Col md={6}>
      <Input
        full
        id="mandate-name"
        value={value}
        onChange={onChange}
        disabled={disabled}
        label="admin.installations.signatory_name"
        data-cy="signatory-name-input"
      />
    </Col>
  );
};

export default SignatoryNameInput;
