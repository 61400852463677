import React from 'react';
import { useUser } from '@context/User.context';
import { getSmartTranslation } from '@utils/getSmartTranslation';

type SmartTranslatorProps = {
  translationKey: string;
};

/**
 * Wrapper of `getSmartTranslation` to use it in React components.
 * @param Props.translationKey The key to get the translated string
 */
const SmartTranslator = ({ translationKey }: SmartTranslatorProps) => {
  const user = useUser();

  return <>{getSmartTranslation(user, translationKey)}</>;
};

export default SmartTranslator;
