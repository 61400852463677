import axiosInstance from '@utils/axiosInstance';
import { v2_export_meter_invoicings_path, v2_export_meter_invoicing_path } from '@utils/routes';
import { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';
import { CommonRequestHookResponse, useRequest } from '..';

/**
 * Get correct url for download pdf invoices based on if it's capa or not.
 */
const getUrl = (selectedInvoicesUuids: string[], isCapa: boolean): string => {
  const singleton = selectedInvoicesUuids.length == 1;
  let url = singleton
    ? v2_export_meter_invoicing_path(selectedInvoicesUuids[0]) + '.pdf'
    : v2_export_meter_invoicings_path();

  url += isCapa ? '?only_capa=true' : '?only_capa=false';

  return url;
};

const fetchPdf = async ({ url, options }: { url: string; options?: AxiosRequestConfig }) => {
  const req = await axiosInstance(false).get(url, { ...options, responseType: 'blob' });

  if (req?.headers['content-disposition']) {
    const fileName = req.headers['content-disposition'].split('"')[1];

    fileDownload(req.data, fileName);
  }

  return req.data;
};

/**
 * Handle download pdf invoices.
 */
const useDownloadPdfInvoices = (
  selectedInvoicesUuids: string[],
  isCapa: boolean,
): CommonRequestHookResponse<unknown> & {
  handleDownloadPDF: () => Promise<void | string> | undefined;
} => {
  const req = useRequest(getUrl(selectedInvoicesUuids, isCapa), fetchPdf, true);

  const handleDownloadPDF = () => {
    if (selectedInvoicesUuids.length == 1) {
      return req.execute?.();
    }

    return req.execute?.({ params: { uuids: selectedInvoicesUuids } });
  };

  return {
    ...req,
    handleDownloadPDF,
  };
};

export default useDownloadPdfInvoices;
