import React from 'react';
import { Input } from '@GDM/forms';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FormType } from './BusinessPlanForm';

export type Month = Exclude<keyof FormType, 'year' | 'allYears'>;

type Props = {
  month: Month;
  label: string;
  errors: FieldErrors;
  control: Control<FormType>;
};

export const MonthFormField = ({ month, label, errors, control }: Props) => {
  return (
    <div className="my-2 d-flex justify-content-center">
      <Controller
        rules={{ required: true }}
        name={month}
        control={control}
        render={({ field }) => (
          <Input
            label={label}
            value={field.value}
            onChange={field.onChange}
            suffix="kWh"
            type="number"
            hasError={!!errors?.[month]}
            id={`input-${month}`}
            labelStyle={{ width: '3rem' }}
            className="justify-content-between w-100"
            inline
          />
        )}
      />
    </div>
  );
};
