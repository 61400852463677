import { SimulationFormField } from '../utils/simulations.types';

export const commonFields: SimulationFormField[] = [
  { name: 'installation_attributes.id' },
  { name: 'installation_attributes.name', rules: { required: true } },
  { name: 'installation_attributes.longitude', rules: { required: true } },
  { name: 'installation_attributes.latitude', rules: { required: true } },
  { name: 'installation_attributes.energy', rules: { required: true } },
  { name: 'start_date', rules: { required: true } },
  { name: 'end_date', rules: { required: true } },
  { name: 'neg_hours_limit', excludedForCountries: ['FR'] },
  { name: 'no_strike', excludedForCountries: ['FR'] },
  { name: 'fixed_stop_strike', excludedForCountries: ['FR'], requiredForFieldValues: { no_strike: 'fixed' } },
  {
    name: 'dynamic_stop_strike',
    excludedForCountries: ['FR'],
    requiredForFieldValues: { no_strike: 'dynamic' },
  },
  { name: 'full_merchant' },
  { name: 'perturbate_prices', excludedForCountries: ['DE'] },
];
