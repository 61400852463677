import { TranslateFn } from '@hooks/useTranslation';
import getContractTypeLabel from '@utils/contract/getLabel';
import jsonToCsv, { HeadCsv } from '@utils/json-to-csv';
import { ContractType } from '@utils/types/contract';
import { UserGeolocation } from '@utils/types/user';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';
import { CustomReportingGlobalQuery, DateSplitType, FocusType, ReportMetric, SplitByType } from '../types';
import { isQueryTimeBased } from '../utils';
import { FRACTION_DIGITS_PER_METRIC } from './constants';
import {
  FullPeriodReportTableData,
  NestedReportTableRow,
  NestedTimeBasedReportTableRow,
  TimeBasedReportTableData,
} from './Table/types';

export const turnReportItemNameIntoLabel = (
  name: string,
  focus: FocusType | SplitByType | undefined,
  country: UserGeolocation | undefined,
) => {
  if (focus === 'contract_type') return getContractTypeLabel(name as ContractType, country || null);
  if (focus === 'energy') return `energy.${name}`;

  return name;
};

export const formatReportValue = (value: number | string | null | undefined, metric: ReportMetric) => {
  if (value === null || value === undefined) return null;
  const val = Number(value);

  // From kWh to GWh
  if (metric === 'production') return val / 1_000_000;
  // From € to k€ (€ or any other currency)
  if (metric === 'revenue') return val / 1000;
  // From €/kWh to €/MWh
  if (metric === 'unit_price') return val * 1000;
  // From kWh to MWh
  if (metric === 'business_plan') return val / 1000;
  // From kW to MW
  if (metric === 'power') return val / 1000;

  return val;
};

export const formatReportDate = (date: string | number, dateSplit: DateSplitType) => {
  const d = String(date);

  if (dateSplit === 'monthly') return dayjs(d).format('YYYY-MM');
  if (dateSplit === 'yearly') return dayjs(d).format('YYYY');

  return d;
};

// CSV export
// Mixing full period and time based data in the same function makes the function harder to understand
// It would be better to split this function into two different functions
export const exportCustomRportToCsv = (
  tableData: TimeBasedReportTableData | FullPeriodReportTableData,
  query: CustomReportingGlobalQuery,
  uniqueMetric: ReportMetric,
  t: TranslateFn,
) => {
  const headerLabels: Record<string, string> = {
    focus: t('common.focus'),
    buyer_name: t('reporting.buyer_name'),
    contract_type: t('common.contract_type'),
    energy: t('common.technology'),
    revenue: `${t('common.revenue')} (k€)`,
    production: `${t('common.production')} (GWh)`,
    contract_nb: t('common.contract_nb'),
    unit_price: `${t('common.price')} (€/MWh)`,
    business_plan: t('monitoring.installation.revenue.business_plan'),
    power: t('common.power'),
  };

  const initialNestedRow: NestedReportTableRow[] | NestedTimeBasedReportTableRow[] = [];
  const isTimeBased = isQueryTimeBased(query);

  // CSV rows are not nested, so we have to unnest nested_data
  // we can ignore aggregated data, the use can aggregate data by themselve
  // in their favorite spreasheet software (most likely LibreOffice Calc)
  const splitBy = query.split_by;
  const splitByRows =
    splitBy &&
    tableData.rows.reduce((previousRows, { nested_data, name }) => {
      const newRows =
        nested_data?.map(({ name: splitByName, ...item }) => ({
          ...item,
          name,
          splitByName,
        })) || [];

      return [...previousRows, ...newRows] as NestedReportTableRow[] | NestedTimeBasedReportTableRow[];
    }, initialNestedRow);

  // tableData.rows is cloned to avoid mutating it when we sort it later
  // it is important to protect tableData since it is the actual data used by react table
  const originalRows = splitByRows || [...tableData.rows];

  const headers = tableData.columns.map(({ id, accessorKey }) => {
    const metric = isTimeBased ? uniqueMetric : id;
    const fractionDigits = FRACTION_DIGITS_PER_METRIC[metric as ReportMetric];

    return {
      key: accessorKey,
      transform: (value: number | string | null | undefined) => {
        // Hide absent values
        if (value === null || value === undefined) return '';
        // Hide NaN values
        if (typeof value === 'number' && isNaN(value)) return '';
        // Directly display string that are not numbers
        if (typeof value === 'string' && (isNaN(Number(value)) || !value)) return value;

        // Format numbers
        return formatReportValue(Number(value), metric as ReportMetric)?.toFixed(fractionDigits);
      },
      label: headerLabels[id || ''] || formatReportDate(id || '', query.date_split),
    };
  });

  // Since we cannot nest data in a csv file, if data is split by a dimension
  // We add this dimension as another column
  const finalHeaders = (
    splitBy ? [headers[0], { key: 'splitByName', label: headerLabels[splitBy] }, ...headers.slice(1)] : headers
  ) as HeadCsv<Record<string, unknown>>[];

  const sortedRows = originalRows.sort(({ name: nameA }, { name: nameB }) =>
    (nameA || '')?.localeCompare(nameB || ''),
  ) as Record<string, unknown>[];

  const fileName = 'report.csv';

  const aggregationRow = isTimeBased
    ? { values: tableData.aggregations, name: t('common.total') }
    : { ...tableData.aggregations, name: t('common.total') };

  return () => {
    fileDownload(
      jsonToCsv([...sortedRows, aggregationRow], finalHeaders, {
        delimiter: ';',
        keepAccents: true,
      }),
      fileName,
      'text/csv;charset=utf-8',
      '\uFEFF',
    );
  };
};
