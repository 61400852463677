import React from 'react';
import classNames from 'classnames';
import styles from './card.module.scss';
import { CardBody } from './CardBody';
import { CardTitle } from './CardTitle';

export const Card = ({
  children,
  className,
  'data-cy': dataCy,
  title,
  tooltip,
  textSize = 'md',
  body,
}: React.PropsWithChildren<{
  title?: string | JSX.Element;
  tooltip?: string;
  className?: string;
  textSize?: 'md' | 'lg';
  body?: string | JSX.Element;
  'data-cy'?: string;
}>) => {
  return (
    <div className={classNames(styles.card, className)} data-cy={`card-${dataCy?.toLocaleLowerCase()}`}>
      {title && <CardTitle title={title} tooltip={tooltip} />}

      {children && <CardBody className={classNames(styles[`size-${textSize}`])}>{children}</CardBody>}
      {!!body && body}
    </div>
  );
};
