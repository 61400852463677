import fetchWrapper from '@utils/fetch';
import { v2_user_notification_setting_path, v2_user_notification_settings_path } from '@utils/routes';
import type { EventType } from '@utils/types/event';
import type { UserNotificationsSettings } from '@utils/types/userNotificationsSettings';

export const fetchUserNotificationsSettings = async (): Promise<UserNotificationsSettings[]> => {
  const response = await fetchWrapper(v2_user_notification_settings_path());

  return response.json();
};

type MutationBody = {
  book_uuids?: string[];
  installation_names?: string[];
  emails?: string[];
  event_types: EventType[];
};

export type UserNotificationsSettingsUpdateMutationParameters = { id: number; data: MutationBody };

export const createUserNotificationsSettings = async (data: MutationBody): Promise<UserNotificationsSettings> => {
  const response = await fetchWrapper(v2_user_notification_settings_path(), {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData?.code);
  }

  return responseData;
};

export const updateUserNotificationsSettings = async ({
  id,
  data,
}: UserNotificationsSettingsUpdateMutationParameters): Promise<UserNotificationsSettings> => {
  const response = await fetchWrapper(v2_user_notification_setting_path(id), {
    method: 'PUT',
    body: JSON.stringify({ id, ...data }),
    headers: { 'Content-Type': 'application/json' },
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData?.code);
  }

  return responseData;
};

export const deleteUserNotificationsSettings = async (id: number): Promise<void> => {
  const response = await fetchWrapper(v2_user_notification_setting_path(id), {
    method: 'DELETE',
  });

  if (!response.ok) {
    throw new Error('common.error');
  }
};
