import { MeterInvoice } from './types/meter-invoice';

export const getInvoicesType = (invoices: MeterInvoice[]) => {
  if (!invoices || invoices.length === 0) return null;

  const invoiceTypeSet = new Set(invoices.map((i) => i.invoicing_type));
  const indexTypeSet = Array.from(invoiceTypeSet).filter((i) => i.indexOf('Index') > -1);

  if (indexTypeSet.length > 0) return 'index';

  const loadCurveTypeSet = Array.from(invoiceTypeSet).filter((i) => i.indexOf('Courbe de charge') > -1);
  if (loadCurveTypeSet.length > 0) return 'load_curve';

  return Array.from(new Set(invoices.map((i) => i.volume_source)))[0];
};
