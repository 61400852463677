import { useMemo } from 'react';
import { isANumber } from '@utils/isANumber';
import { DataPeriod } from '@utils/types/dataPeriod';
import { TimeSeries } from '@utils/types/timeSeries';
import { ProductionTimeSeriesRecord } from '../production.types';
import { computePowerMinMaxAverage } from '../utils/computePowerMinMaxAverage';

export const useActiveEnergy = ({
  timeSeries: { rawActiveEnergyProduction, rawActiveEnergyConsumption },
  inverterAll,
  dataPeriod,
  chartExtremes,
}: {
  dataPeriod: DataPeriod;
  chartExtremes: [number, number];
  timeSeries: Partial<Pick<ProductionTimeSeriesRecord, 'rawActiveEnergyProduction' | 'rawActiveEnergyConsumption'>>;
  inverterAll?: TimeSeries;
}) => {
  // Set main loadcurve if no meter data exists
  const mainLoadCurve = useMemo(() => {
    const rawData = rawActiveEnergyProduction?.values.length && rawActiveEnergyProduction?.values;
    const inverterData = inverterAll?.length && inverterAll;

    return rawData || inverterData || [];
  }, [rawActiveEnergyProduction, inverterAll]);

  // Active Power or Energy Produced (Injected)
  const { injectedActiveMinMaxAverage, activeEnergyMap } = useMemo(() => {
    if (!mainLoadCurve.length) return {};
    const activeEnergyMap: Record<number, number> = {};
    const populateMap = ([time, value]: TimeSeries[number]) => {
      activeEnergyMap[time] = value;
    };

    const injectedActiveMinMaxAverage = computePowerMinMaxAverage({
      series: mainLoadCurve,
      dataPeriod,
      timeFilter: chartExtremes,
      onProcessing: populateMap,
    });

    return { injectedActiveMinMaxAverage, activeEnergyMap };
  }, [mainLoadCurve, dataPeriod, chartExtremes]);

  const { withdrawnActiveMinMaxAverage, autoConso, autoProd } = useMemo(() => {
    let autoConso;
    let autoProd;
    if (!rawActiveEnergyConsumption?.values.length) return {};
    let consumptionProducedLocally = 0;
    let totalProdOnConsoIndex = 0;
    const incrementConsumptionAndProduction = ([time, consumption]: TimeSeries[number]) => {
      const production = activeEnergyMap?.[time];
      consumptionProducedLocally += Math.min(production || 0, consumption);
      if (isANumber(production)) totalProdOnConsoIndex += production;
    };
    const withdrawnActiveMinMaxAverage = computePowerMinMaxAverage({
      series: rawActiveEnergyConsumption.values,
      dataPeriod,
      timeFilter: chartExtremes,
      onProcessing: incrementConsumptionAndProduction,
    });

    const consumption = withdrawnActiveMinMaxAverage.energy;
    if (consumption > 0) {
      autoConso = Math.round((100 * consumptionProducedLocally) / consumption);
    }

    if (totalProdOnConsoIndex > 0) {
      autoProd = Math.round((100 * consumptionProducedLocally) / totalProdOnConsoIndex);
    }

    return { withdrawnActiveMinMaxAverage, autoConso, autoProd };
  }, [rawActiveEnergyConsumption, dataPeriod, chartExtremes, activeEnergyMap]);

  return {
    aggregations: { injectedActive: injectedActiveMinMaxAverage, withdrawnActive: withdrawnActiveMinMaxAverage },
    autoConso,
    autoProd,
  };
};
