import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { useNumbers } from '@hooks/useNumbers';
import { isANumber } from '@utils/isANumber';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { ExpectedPrModal } from './ExpectedPrModal';

export const ExpectedPrDefinition = ({ meter }: { meter: Meter }) => {
  const { pr_expected } = useMeterInfo();
  const { format } = useNumbers(1);

  return (
    <Definition
      label="common.pr_expected"
      data-cy="pr-expected-definition"
      value={isANumber(pr_expected) ? `${format(Number(pr_expected) * 100)} %` : '-'}
      modal={{
        node: <ExpectedPrModal meter={meter} />,
        title: 'monitoring.installation.pr_modify',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
