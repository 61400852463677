import React, { useContext, useMemo } from 'react';
import { CountryCode } from '@utils/types/countries';
import { FieldValues } from 'react-hook-form';
import { FormField } from '../form.types';
import { Step, useProgressStepper } from '../ProgressStepper';

type InternalFormContext = {
  country?: CountryCode | null;
  commonFields: FormField<FieldValues>[];
  fieldSections: Map<FormField<FieldValues>['name'], string>;
  steps: Record<string, Step>;
  progressCallback?: (
    markAsValid: ReturnType<typeof useProgressStepper>['markAsValid'],
    updateProgress: ReturnType<typeof useProgressStepper>['updateProgress'],
  ) => void;
  loading: boolean;
};

export const internalFormContext = React.createContext<InternalFormContext>({
  commonFields: [],
  fieldSections: new Map(),
  steps: {},
  country: null,
  loading: false,
});

export const useInternalFormContext = () => useContext(internalFormContext);

export const InternalFormProvider = ({
  children,
  country,
  commonFields,
  fieldSections,
  progressCallback,
  steps,
  loading,
}: React.PropsWithChildren<InternalFormContext>) => {
  const value = useMemo(
    () => ({ country, commonFields, fieldSections, progressCallback, steps, loading }),
    [country, commonFields, fieldSections, progressCallback, steps, loading],
  );

  return <internalFormContext.Provider value={value}>{children}</internalFormContext.Provider>;
};
