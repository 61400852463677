import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useDynamicOptions } from '@GDM/Filters';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import uniqBy from 'lodash/uniqBy';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

const getOptions = (invoices: MeterInvoice[]): Option<MeterInvoice['contract_seller_short_name']>[] => {
  const sellers = invoices.map((invoice) => invoice.contract_seller_short_name);

  return uniqBy(
    sellers
      .filter((seller) => seller)
      .map((seller) => ({ value: seller, label: seller }))
      .sort(sortOptionsByLabelAsc),
    'value',
  );
};

export const SellerFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'seller_name', filteredInvoices, allInvoices);

  return (
    <ControlledSelect
      control={form.control}
      name="seller_name"
      classNamePrefix="filter-seller_name"
      placeholder="common.supplier"
      isClearable
      options={options}
      isMulti
      inline
    />
  );
};
