import { SimulationFormField } from '../utils/simulations.types';
import { commonFields } from './common-fields';

export const windFields: SimulationFormField[] = [
  ...commonFields,
  {
    name: 'height',
    requiredForFieldValues: { 'installation_attributes.energy': 'wind' },
    rules: { min: 1 },
  },
  {
    name: 'nb_turb',
    requiredForFieldValues: { 'installation_attributes.energy': 'wind' },
    rules: { min: 1 },
  },
  { name: 'turbine_id', requiredForFieldValues: { 'installation_attributes.energy': 'wind' } },
  {
    name: 'total_power_wind',
    requiredForFieldValues: { 'installation_attributes.energy': 'wind' },
    rules: { min: 10 },
  },
  { name: 'ppa_wind_bounds_attributes.0.debut_mois_contrainte', rules: { required: true } },
  { name: 'ppa_wind_bounds_attributes.0.fin_mois_contrainte', rules: { required: true } },
  { name: 'ppa_wind_bounds_attributes.0.start', rules: { required: true } },
  { name: 'ppa_wind_bounds_attributes.0.finish', rules: { required: true } },
  { name: 'ppa_wind_bounds_attributes.0.maximum_power', rules: { min: 0, max: 100, required: true } },
];
