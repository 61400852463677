import React from 'react';
import { CurrencyModalButton } from '@components/Currency/CurrencyModal';
import { FilterContainer, Filters as FilterLayout } from '@GDM/Filters';
import { Icon } from '@GDM/Icon';
import { Spinner } from '@GDM/Spinner';
import classNames from 'classnames';
import { REVENUE_SHOW_HELP_LOCAL_STORAGE_KEY } from './context';
import { ExportButton, Filters, InfoBox } from './header';
import { useRevenueContext } from './hooks/useRevenueContext';
import styles from './revenue.module.scss';
import { RevenueChart } from './RevenueChart';
import { RevenueChartByMonth } from './RevenueChart/RevenueChartByMonth';
import { RevenueTable } from './RevenueTable';
import { RevenueWidgets } from './RevenueWidgets';

export const RevenueContent = () => {
  const { displayMode, isLoading, revenue, setShowHelp, showHelp, selectedCurrency, startDate, endDate, groupByMonth } =
    useRevenueContext();

  const showInfo = () => {
    setShowHelp?.(true);
    localStorage.setItem(REVENUE_SHOW_HELP_LOCAL_STORAGE_KEY, 'on');
  };

  return (
    <div className={classNames(styles['revenue-wrapper'], displayMode === 'table' && styles['is-table'])}>
      <FilterLayout className="p-0">
        <Filters />

        {isLoading && (
          <FilterContainer size="fit">
            <Spinner size="sm" />
          </FilterContainer>
        )}
      </FilterLayout>

      <div className={classNames(styles['action-bar'], 'mb-4')}>
        {!!revenue && (
          <div className={styles['actions']}>
            {!showHelp && (
              <Icon
                name="HelpCircle"
                title="common.show_help"
                size={16}
                className={styles['show-info-button']}
                onClick={showInfo}
              />
            )}
            <ExportButton />
            <CurrencyModalButton
              currency={selectedCurrency ?? 'EUR'}
              startDate={startDate || new Date()}
              endDate={endDate || new Date()}
            />
          </div>
        )}
      </div>

      {showHelp && <InfoBox className={classNames(styles['info-box'], 'mb-4')} />}

      {displayMode === 'chart' && !groupByMonth && <RevenueChart />}
      {displayMode === 'chart' && groupByMonth && <RevenueChartByMonth />}
      {displayMode === 'table' && <RevenueTable />}

      <RevenueWidgets />
    </div>
  );
};
