import React from 'react';
import { ReactNode } from 'react';
import useTranslation from '@hooks/useTranslation';
import { ContractFormGlobals } from '@pages/Contracts/Contract/hooks/useContractForm/types';
import { ContractFormQueries } from '@pages/Contracts/Contract/hooks/useContractForm/useContractFormQueries';
import { ContractForm } from '@utils/types/contract';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import contractSectionstyles from './contractSection.module.scss';

export type ContractFormSectionProps = {
  title: string;
  readOnly: boolean;
  /** Values from the form that must be shared among multiple sections */
  globals: ContractFormGlobals;
  /** TanStack queries object with async dataandstates useful for the contract form*/
  queries: ContractFormQueries;
  /** The actual form */
  form: UseFormReturn<ContractForm>;
};

export const FormSectionLayout = ({
  title,
  headerActions,
  body,
}: {
  title: string;
  headerActions?: ReactNode;
  body: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(contractSectionstyles.card, contractSectionstyles.step)}>
      <div className={contractSectionstyles.title}>
        <span>{t(title)}</span>
        {headerActions && <div>{headerActions}</div>}
      </div>
      {body}
    </div>
  );
};
