import { specialUnitsMap } from '../config';
import { AcceptableUnits } from '../value-with-unit';

export const getUnit = (unit: AcceptableUnits, value: number): string | null => {
  const specialUnit = specialUnitsMap.get(unit);

  if (!specialUnit) return null;

  const localeParts = new Intl.NumberFormat('en', { notation: 'compact' }).formatToParts(value); // Format to English to get predictable compact letters.
  const compactedValue = localeParts
    .find(({ type }) => type === 'compact')
    ?.['value'].toUpperCase() as keyof typeof specialUnit; // 'K', 'M', 'G'...

  return compactedValue ? specialUnit[compactedValue] || specialUnit.default : specialUnit.default;
};
