/**
 * THIS FILE SHOULD CONTAIN GENERIC SORTING FUNCTIONS
 */

/**
 * Sort an array of object (ArrayOfObject) by key in ascending order
 * @param a ArrayOfObject item 1
 * @param b ArrayOfObject item 2
 * @param key Key (in ArrayOfObject) to sort by
 */
const sortByKeyAsc = <ArrayOfObject extends Record<string, string>>(
  a: ArrayOfObject,
  b: ArrayOfObject,
  key: keyof ArrayOfObject,
  caseInsensitive?: boolean,
): number => {
  const firstValue = !caseInsensitive ? a[key] : `${a[key] || ''}`.toLocaleLowerCase();
  const secondValue = !caseInsensitive ? b[key] : `${b[key] || ''}`.toLocaleLowerCase();

  // if (firstValue < secondValue) return -1;
  // if (firstValue > secondValue) return 1;

  return firstValue.localeCompare(secondValue);
};

/**
 * Function to sort collection by name.
 * The type needs to have a name property.
 */
type RecordWithName = {
  name: string;
};

type ObjectWithLabel = {
  label: string;
};

export const sortByNameAsc = (a: RecordWithName, b: RecordWithName): number => sortByKeyAsc(a, b, 'name');
export const sortOptionsByLabelAsc = (a: ObjectWithLabel, b: ObjectWithLabel, caseInsensitive?: boolean): number =>
  sortByKeyAsc(a, b, 'label', caseInsensitive);
export const sortByDate = (a: Date | string, b: Date | string): number => new Date(b).getTime() - new Date(a).getTime();
