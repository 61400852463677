import React from 'react';
import useTranslation from '@hooks/useTranslation';
import type { Option } from '@utils/types/common-types';
import classNames from 'classnames';
import { components, type GroupBase, type OptionProps } from 'react-select';
import styles from './select.module.scss';

export const DefaultOption = <T extends string>(props: OptionProps<Option<T>, boolean, GroupBase<Option<T>>>) => {
  const { t } = useTranslation();

  return (
    <components.Option {...props} data-cy={`option-${props.data.value}`}>
      {props.data.value === null || props.data.value === '' ? (
        <div className={classNames(styles['option-default'])}>{t(props.data.label)}</div>
      ) : (
        t(props.data.label)
      )}
    </components.Option>
  );
};
