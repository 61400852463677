import React from 'react';
import { Card, CardBody, CardTitle } from '@GDM/Card';
import { Icon } from '@GDM/Icon';
import { AcceptableUnits, ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import { TextColor } from '@utils/types/colors';
import classNames from 'classnames';
import { Card as CardLegacy, CardBody as CardBodyLegacy, CardTitle as CardTitleLegacy } from 'reactstrap';
import styles from './data-widget.module.scss';

const getTextColorClassName = (valueColor?: TextColor) => `text-${valueColor || 'default'}`;

type WidgetSubtitle = {
  label: string;
  tooltip?: string;
  value: number | null;
  unit: AcceptableUnits;
  valueColor?: TextColor;
};

export const DataWidget = ({
  title,
  value,
  unit,
  subTitle,
  valueColor,
  printable,
  children,
}: React.PropsWithChildren<{
  title: string;
  value: number | null;
  unit: AcceptableUnits;
  valueColor?: TextColor;
  subTitle?: WidgetSubtitle | WidgetSubtitle[];
  printable?: boolean;
}>) => {
  const { t } = useTranslation();
  const tTitle = t(title);

  const subtitles = Array.isArray(subTitle) ? subTitle : subTitle ? [subTitle] : null;

  if (printable) {
    return (
      <CardLegacy className="data-widget-container">
        <CardBodyLegacy className="d-flex flex-column justify-content-center align-items-center">
          <CardTitleLegacy className="data-widget-title">{tTitle}</CardTitleLegacy>

          <h4 className="metrics mb-2 data-widget-center">
            <ValueWithUnit value={value} unit={unit} className={getTextColorClassName(valueColor)} />
          </h4>

          {subtitles?.map((subtitle) => (
            <h5 className="data-widget-center" key={subtitle.label}>
              {subtitle.label}{' '}
              <ValueWithUnit
                value={subtitle?.value}
                unit={subtitle?.unit}
                className={getTextColorClassName(subtitle?.valueColor)}
              />
            </h5>
          ))}

          {children}
        </CardBodyLegacy>
      </CardLegacy>
    );
  }

  const dataCy: string = title;

  return (
    <Card className={styles['data-widget']} data-cy={dataCy}>
      <CardBody>
        <CardTitle>{tTitle}</CardTitle>

        <span
          className={classNames('mb-1 mt-2', getTextColorClassName(valueColor), styles['widget-value-container'])}
          data-cy="value"
        >
          <ValueWithUnit value={value} unit={unit} />
        </span>

        {subtitles?.map((subtitle) => (
          <div className={styles['subtitle']} key={subtitle.label} data-cy="subtitle">
            {subtitle.label}{' '}
            <ValueWithUnit
              value={subtitle?.value}
              unit={subtitle?.unit}
              className={getTextColorClassName(subtitle?.valueColor)}
            />
            {subtitle.tooltip && <Icon name="Info" size={14} title={subtitle.tooltip} className={styles.tooltip} />}
          </div>
        ))}

        {children}
      </CardBody>
    </Card>
  );
};
