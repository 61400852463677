import React from 'react';
import ControlledSelect, { ControlledSelectProps } from '@components/FormInputs/ControlledSelect';
import { HelpBoxProps } from '@GDM/HelpBox';
import { useHelpBoxContext } from '@pages/Contracts/Contract/contexts/helpBoxContext';
import { useFieldRegistrationTracker } from '@pages/Contracts/Contract/contexts/registeredFields';
import { FieldPath, FieldValues } from 'react-hook-form';

/**
 * ListInput is a select with default suited for
 * dynamic forms. It wraps the ControlledSelect component.
 * Don't forget to provide a documentation name as a prop.
 * It will help other devs to understand the purpose of the select.
 */
export const ListInput = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  helpBox,
  ...props
}: Omit<
  ControlledSelectProps<TFieldValues, TFieldName>,
  'shouldUnregister' | 'onFocus' | 'onBlur' | 'blurInputOnSelect'
> & {
  helpBox?: Pick<HelpBoxProps, 'text' | 'title'>;
}) => {
  useFieldRegistrationTracker(props);
  const { handleFocus, handleBlur } = useHelpBoxContext();

  return (
    <ControlledSelect
      size="lg"
      {...props}
      shouldUnregister
      onFocus={helpBox && handleFocus(helpBox)}
      onBlur={handleBlur}
      blurInputOnSelect
    />
  );
};
