import React, { useMemo, useState } from 'react';
import { MeterNameCell } from '@components/ui/table/cell/MeterNameCell';
import { useUser } from '@context/User.context';
import {
  CurrencyCell,
  NumberCell,
  Table,
  TableActions,
  TableBody,
  TableHead,
  TablePageSizeSelect,
  TablePagination,
} from '@GDM/Table';
import useTranslation from '@hooks/useTranslation';
import {
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
} from '@tanstack/react-table';
import { getSymbol } from '@utils/currency/getSymbol';
import { M0Row } from '@utils/types/m0';
import { useM0Deviation } from '../useM0Deviation';

export const M0Table = () => {
  const user = useUser();
  const { t } = useTranslation();
  const { tableData: data, currency, source } = useM0Deviation();
  const isGermany = user.geolocation[0] === 'DE';
  const priceUnit = `(${getSymbol(currency)}/MWh)`;

  const columns: ColumnDef<M0Row>[] = useMemo(
    () => [
      {
        id: 'installation',
        header: 'Installation',
        cell: ({ row: { original } }) => (
          <MeterNameCell
            installation_name={original.installation_name || ''}
            installation_energy={original.installation_energy}
            client_id={original.installation_external_ref || ''}
          />
        ),
      },
      {
        id: 'installation_p_max',
        accessorKey: 'installation_p_max',
        header: `${t('common.power')} (kW)`,
        cell: (props) => <NumberCell {...props} />,
      },
      {
        id: 'date',
        header: `${t('common.date')}`,
        accessorKey: 'date',
      },
      {
        id: 'm0',
        header: `${t('pricing.market_value')} ${priceUnit}`,
        accessorKey: 'm0',
        cell: ({ getValue }) => <CurrencyCell currency={currency} value={getValue<M0Row['m0']>()} />,
      },
      {
        id: 'capture_price',
        header: `${t('monitoring.installation.capture_price')} ${priceUnit}`,
        accessorKey: 'capture_price',
        cell: ({ getValue }) => <CurrencyCell currency={currency} value={getValue<M0Row['capture_price']>()} />,
      },
      {
        id: 'm0_deviation',
        header: `${t(isGermany ? 'common.deviation_m0_german' : 'monitoring.installation.m0_deviation')} ${priceUnit}`,
        accessorKey: 'm0_deviation',
        cell: ({ getValue }) => <CurrencyCell currency={currency} value={getValue<M0Row['m0_deviation']>()} />,
      },
      {
        id: 'm0_deviation_no_prod_neg_price',
        header: `${t('monitoring.installation.m0_deviation_no_prod_neg_price')} ${priceUnit}`,
        accessorKey: 'm0_deviation_no_prod_neg_price',
        cell: ({ getValue }) => (
          <CurrencyCell currency={currency} value={getValue<M0Row['m0_deviation_no_prod_neg_price']>()} />
        ),
      },
    ],
    [t, priceUnit, currency, isGermany],
  );

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 12 });

  const table = useReactTable({
    columns,
    data,
    state: { pagination },
    sortDescFirst: false,
    initialState: {
      columnVisibility: {
        date: source == 'installation',
        installation: source == 'book' || source == 'market_player',
        installation_p_max: source == 'book' || source == 'market_player',
        m0_deviation_no_prod_neg_price: !isGermany,
        epex_gain: !isGermany,
      },
    },
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>

      {Array.isArray(data) && data.length > pageSize && (
        <TableActions>
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </>
  );
};
