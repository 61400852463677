import type { IconNames } from '@GDM/Icon';
import type { EventType } from '@utils/types/event';

export const eventConfig: Partial<{
  [key in EventType]: { title: string; text: string; icon: IconNames; colorClass?: string };
}> = {
  CommunicationFailure: {
    title: 'admin.alerts.events.CommunicationFailure',
    text: 'admin.alerts.events.CommunicationFailure_text',
    icon: 'PhoneMissed',
  },
  ProductionStop: {
    title: 'admin.alerts.events.ProductionStop',
    text: 'admin.alerts.events.ProductionStop_text',
    icon: 'XOctagon',
    colorClass: 'text-red',
  },
  UnderPerformance: {
    title: 'admin.alerts.events.UnderPerformance',
    text: 'admin.alerts.events.UnderPerformance_text',
    icon: 'AlertCircle',
  },
  HighUnderPerformance: {
    title: 'admin.alerts.events.HighUnderPerformance',
    text: 'admin.alerts.events.HighUnderPerformance_text',
    icon: 'AlertTriangle',
    colorClass: 'text-red',
  },
  OutOfRangeReactive: {
    title: 'admin.alerts.events.OutOfRangeReactive',
    text: 'admin.alerts.events.OutOfRangeReactive_text',
    icon: 'Crosshair',
    colorClass: 'text-red',
  },
  AnnualProductionExceedsCap: {
    title: 'admin.alerts.events.AnnualProductionExceedsCap',
    text: 'admin.alerts.events.AnnualProductionExceedsCap_text',
    icon: 'ChevronsUp',
    colorClass: 'text-red',
  },
  AnnualProductionExceedsDafr: {
    title: 'admin.alerts.events.AnnualProductionExceedsDafr',
    text: 'admin.alerts.events.AnnualProductionExceedsDafr_text',
    icon: 'ChevronsUp',
    colorClass: 'text-red',
  },
  ProductionOnNegativePrice: {
    title: 'admin.alerts.events.ProductionOnNegativePrice',
    text: 'admin.alerts.events.ProductionOnNegativePrice_text',
    icon: 'MinusCircle',
    colorClass: 'text-red',
  },
  DailyProductionOnNegativePrice: {
    title: 'admin.alerts.events.DailyProductionOnNegativePrice',
    text: 'admin.alerts.events.DailyProductionOnNegativePrice_text',
    icon: 'MinusCircle',
    colorClass: 'text-red',
  },
  InvoiceMissingData: {
    title: 'admin.alerts.events.InvoiceMissingData',
    text: 'admin.alerts.events.InvoiceMissingData_text',
    icon: 'HelpCircle',
  },
  HighDiffPublishedProduction: {
    title: 'admin.alerts.events.HighDiffPublishedProduction',
    text: 'admin.alerts.events.HighDiffPublishedProduction_text',
    icon: 'HelpCircle',
  },
  // InconsistentProduction: {
  //   title: 'admin.alerts.events.InconsistentProduction',
  //   text: 'admin.alerts.events.InconsistentProduction_text',
  //   icon: 'AlertCircle',
  // },
  // ProductionAnomaly: {
  //   title: 'admin.alerts.events.ProductionAnomaly',
  //   text: 'admin.alerts.events.ProductionAnomaly_text',
  //   icon: 'AlertCircle',
  // },
};
