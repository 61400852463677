import React from 'react';
import { useUser } from '@context/User.context';
import { Select } from '@GDM/forms';
import getContractTypeLabel from '@utils/contract/getLabel';
import type { Option } from '@utils/types/common-types';
import type { ContractType } from '@utils/types/contract';
import { usePortfolio } from '../usePortfolio';
import { useFilters } from './useFilters';

const ContractTypeFilter = () => {
  const { contractTypes, setContractTypes } = useFilters();
  const { data } = usePortfolio();
  const { main_country } = useUser();
  const country = main_country ?? 'FR';

  const dataWithContractType = data?.filter((d) => d.contract_type) ?? [];
  const allContractTypes = new Set(dataWithContractType.map(({ contract_type }) => contract_type));
  const options: Option<ContractType | null>[] = [
    { label: 'common.none', value: null },
    ...Array.from(allContractTypes).map((value) => ({ label: getContractTypeLabel(value, country), value })),
  ];

  return (
    <Select
      options={options}
      isMulti
      selectedOptions={contractTypes}
      onChange={(option) => setContractTypes?.(option?.map((opt) => opt.value) ?? [])}
      placeholder="common.filters.contract_type"
      isClearable
      inline
    />
  );
};

export default ContractTypeFilter;
