import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';
import dayjs from 'dayjs';

export const TimeCell = ({ getValue }: React.PropsWithChildren<CellContext<Unavailability, unknown>>) => {
  const value = getValue();
  if (!value || (typeof value !== 'string' && typeof value !== 'number')) return '--';

  const time = dayjs(value).format('YYYY-MM-DD HH:mm');

  return <>{time}</>;
};
