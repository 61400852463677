import { ContractForm, HedgeContractFormBody, HedgeSubPeriodFormBody } from '@utils/types/contract';
import { serializeHedgeBlock } from './serializeHedgeBlock';

export const serializeHedgeBlocks = (data?: ContractForm): HedgeContractFormBody | undefined => {
  if (!data) return;

  const preExistingHedgeContractId = data.hedge_contract?.id;
  const spotFee = data.hedge_contract?.spot_fee;

  if (!preExistingHedgeContractId && !data.hedge_blocks_attributes) return;

  // If there weren't any pre-existing hedge blocks
  if (!preExistingHedgeContractId && data.hedge_blocks_attributes) {
    return {
      id: null,
      spot_fee: data.hedge_contract?.spot_fee,
      hedge_sub_periods_attributes: data.hedge_blocks_attributes?.map((block) =>
        serializeHedgeBlock(block, { id: null }),
      ),
    };
  }

  const preExistingHedgeBlockIds = data.hedge_contract?.hedge_sub_periods?.map(({ id }) => id) || [];
  const formHedgeBlocks = data.hedge_blocks_attributes?.map(({ id }) => id) || [];

  // Explicitly destroy previous blocks that are now not present in the form
  const hedgeBlocksToDestroy: HedgeSubPeriodFormBody[] =
    data.hedge_contract?.hedge_sub_periods
      ?.filter(({ id }) => !formHedgeBlocks.includes(id))
      .map((block) => serializeHedgeBlock(block, { _destroy: '1' })) || [];

  const hedgeBlocksToCreate: HedgeSubPeriodFormBody[] =
    data.hedge_blocks_attributes
      ?.filter(({ id }) => !preExistingHedgeBlockIds.includes(id as string))
      .map((block) => serializeHedgeBlock(block, { id: null })) || [];

  const hedgeBlocksToUpdate: HedgeSubPeriodFormBody[] =
    data.hedge_blocks_attributes
      ?.filter(({ id }) => preExistingHedgeBlockIds.includes(id as string))
      .map((block) => serializeHedgeBlock(block)) || [];

  const hedge_sub_periods_attributes: HedgeSubPeriodFormBody[] = [
    ...hedgeBlocksToCreate,
    ...hedgeBlocksToUpdate,
    ...hedgeBlocksToDestroy,
  ];

  return { id: preExistingHedgeContractId, spot_fee: spotFee, hedge_sub_periods_attributes };
};
