import { useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { year_v2_export_revenue_reports_path } from '@utils/routes';
import { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';

const fetchCsv = async ({ url, options }: { url: string; options?: AxiosRequestConfig }) => {
  const res = await axiosInstance(false).get(url, { ...options, responseType: 'blob' });

  if (res.data && res.headers['content-disposition']) {
    const fileName = res.headers['content-disposition'].split('"')[1];
    fileDownload(res.data, fileName);
  }

  return res.data;
};

export const useDownloadAnnualReport = () => {
  return useRequest(year_v2_export_revenue_reports_path(), fetchCsv, true);
};
