import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DynamicStopStrikeInput } from './fields/General/DynamicStopStrikeInput';
import { FixedStopStrikeInput } from './fields/General/FixedStopStrikeInput';
import { NegHoursLimitRadio } from './fields/General/NegHoursLimitRadio';
import { NoStrikeToggle } from './fields/General/NoStrikeRadio';
import styles from './new-simulation.module.scss';
import { SimulationForm } from './utils/simulations.types';

export const GermanSimulation = () => {
  const form = useFormContext<SimulationForm>();
  const noStrike = form.watch('no_strike');
  const fullMerchant = form.watch('full_merchant');

  return (
    <>
      {!fullMerchant && <NegHoursLimitRadio />}

      <div className={styles['row']}>
        <NoStrikeToggle />

        {noStrike === 'fixed' && <FixedStopStrikeInput />}
        {noStrike === 'dynamic' && <DynamicStopStrikeInput />}
      </div>
    </>
  );
};
