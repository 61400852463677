import { DeepPartial, FieldValues } from 'react-hook-form';
import { FormField } from '../form.types';
import { Step, useProgressStepper } from '../ProgressStepper';
import { getSectionFields } from './getSectionFields';

const getPercentage = (values: DeepPartial<FieldValues>, requiredFields: FormField<FieldValues>['name'][]) => {
  const filledFields = requiredFields.filter((field) => values[field]);

  return (filledFields.length / requiredFields.length) * 100;
};

export const updateFormProgress =
  (
    steps: Record<string, Step>,
    requiredFields: FormField<FieldValues>[],
    fieldSections: Map<FormField<FieldValues>['name'], string>,
    updateProgress: ReturnType<typeof useProgressStepper>['updateProgress'],
    markAsValid: ReturnType<typeof useProgressStepper>['markAsValid'],
  ) =>
  (values: DeepPartial<FieldValues>) => {
    const sections = Object.keys(steps);

    sections.forEach((section) => {
      const sectionFields = getSectionFields(section, requiredFields, fieldSections);

      if (!sectionFields.length) return;

      const filledPercentage = getPercentage(
        values,
        sectionFields.map((field) => field.name),
      );

      updateProgress(section, filledPercentage);
      markAsValid(section, filledPercentage === 100);
    });
  };
