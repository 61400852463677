import { createContext, Dispatch, SetStateAction } from 'react';
import { ReactiveConstraint } from '@utils/types/reactive_constraint';

export type MeterInfoContextType = {
  nominal_power?: string;
  setNominalPower?: Dispatch<SetStateAction<MeterInfoContextType['nominal_power']>>;
  lat?: string;
  setLat?: Dispatch<SetStateAction<MeterInfoContextType['lat']>>;
  lng?: string;
  setLng?: Dispatch<SetStateAction<MeterInfoContextType['lng']>>;
  pr_expected?: string;
  setPrExpected?: Dispatch<SetStateAction<MeterInfoContextType['pr_expected']>>;
  client_id?: string | null;
  setClientId?: Dispatch<SetStateAction<NonNullable<MeterInfoContextType['client_id']> | null>>;
  facturation_type?: string;
  setInvoicingTypeRawString?: Dispatch<SetStateAction<MeterInfoContextType['facturation_type']>>;
  grid_name?: string;
  setGridName?: Dispatch<SetStateAction<MeterInfoContextType['grid_name']>>;
  commissioning_date?: string | null;
  setCommissioningDate?: Dispatch<SetStateAction<NonNullable<MeterInfoContextType['commissioning_date']> | null>>;
  maxValue?: number;
  setMaxValue?: Dispatch<SetStateAction<MeterInfoContextType['maxValue']>>;
  reactiveConstraints?: ReactiveConstraint[] | undefined;
  setReactiveConstraints?: Dispatch<SetStateAction<MeterInfoContextType['reactiveConstraints']>>;
  erpCode?: string;
  setErpCode?: Dispatch<SetStateAction<MeterInfoContextType['erpCode']>>;
  siret?: string;
  setSiret?: Dispatch<SetStateAction<MeterInfoContextType['siret']>>;
};

export const meterInfoContext = createContext<MeterInfoContextType>({
  nominal_power: '',
  lat: '',
  lng: '',
  pr_expected: '',
  client_id: '',
  facturation_type: '',
  grid_name: '',
  commissioning_date: '',
});
