/**
 * Not all backend errors are made equal. Sometimes the error is a plain string and not
 * a translation one. This remaps those cheeky words to their corresponding i18n string.
 */
export const ERRORS_TO_REMAP: Record<string, string> = {
  taken: 'sales_management.form_errors.already_taken',
  'must be blank': 'sales_management.form_errors.must_be_blank',
  cant_be_blank: 'sales_management.form_errors.cant_be_blank',
  blank: 'sales_management.form_errors.cant_be_blank',
  date_before: 'sales_management.form_errors.date_before',
  date_after: 'sales_management.form_errors.date_after',
  less_than_or_equal_to: 'sales_management.form_errors.less_than_or_equal_to',
  greater_than_or_equal_to: 'sales_management.form_errors.greater_than_or_equal_to',
  invalid: 'errors.field_invalid',
  inclusion: 'errors.field_invalid',
  present: 'errors.field_present',
};
