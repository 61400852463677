import React from 'react';
import { Option } from '@utils/types/common-types';
import { GroupBase, ValueContainerProps, components } from 'react-select';
import styles from '../select.module.scss';

export const ValueContainer = <T extends string>(
  valueContainerProps: ValueContainerProps<Option<T>, boolean, GroupBase<Option<T>>>,
) => {
  const values = valueContainerProps.getValue();
  const isCustomValue = values && values.length > 1;

  const { children, ...rest } = valueContainerProps;
  const childrenArray = React.Children.toArray(children);
  const selectedValues = childrenArray.slice(0, childrenArray.length - 1);
  const searchInput = childrenArray[childrenArray.length - 1];

  return (
    <components.ValueContainer {...rest}>
      {!isCustomValue && selectedValues}
      {isCustomValue && (
        <>
          {selectedValues[0]}
          <div className={styles['multi-value']}>+{selectedValues.length - 1}</div>
        </>
      )}
      {searchInput}
    </components.ValueContainer>
  );
};
