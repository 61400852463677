import React from 'react';
import { Restricted } from '@components/Restricted';
import { AltContent } from '@GDM/AltContent';
import { Card } from '@GDM/Card';
import { Spinner } from '@GDM/Spinner';
import { useRequest } from '@hooks/useRequest';
import { v2_market_prices_path } from '@utils/routes';
import { Currency } from '@utils/types/currency';
import { AggregatedPriceData } from '@utils/types/price';
import { useNationalPricesContext } from '../context';
import { ImbalancePositiveChart } from './ImbalancePositiveChart';

export const ImbalancePositive = () => {
  const { selectedCountry: country, startDate: start_date, endDate: end_date } = useNationalPricesContext();

  const { data: positiveImbalance, loading: positiveImbalanceLoading } = useRequest<{
    currency: Currency;
    values: AggregatedPriceData[];
  }>(
    country && start_date && end_date
      ? v2_market_prices_path({ country, type: 'positive_imbalance', start_date, end_date })
      : null,
    'GET',
  );
  const { data: spot, loading: spotLoading } = useRequest<{ currency: Currency; values: AggregatedPriceData[] }>(
    country && start_date && end_date ? v2_market_prices_path({ country, type: 'spot', start_date, end_date }) : null,
    'GET',
  );
  const loading = positiveImbalanceLoading || spotLoading;

  if (!country || !start_date || !end_date) return null;

  return (
    <Restricted permissions={['display:view_salesfocus']}>
      <Card className="mt-3" title="sales_management.imbalance">
        {loading && <Spinner />}
        {positiveImbalance && spot && !loading && (
          <div className="w-100">
            <ImbalancePositiveChart imbalance={positiveImbalance.values ?? []} spot={spot.values ?? []} />
          </div>
        )}
        {(!positiveImbalance || !spot) && !loading && <AltContent />}
      </Card>
    </Restricted>
  );
};
