import React from 'react';
import { CoverageCell, DateCell, NoWrapHeadCell } from '@GDM/Table';
import { DashboardViewAllData } from '@pages/Portfolio/portfolio.definitions';
import { ColumnDef } from '@tanstack/react-table';
import {
  CapacityFactorCell,
  CheckboxCell,
  ContractCell,
  NameCell,
  PerformanceCell,
  PortfolioNumberCell,
  PortfolioTranslatedHeader,
} from './cells';

export const COLUMNS = {
  BUSINESS_PLAN: {
    header: () => <PortfolioTranslatedHeader tooltip="kWh" name="monitoring.installation.revenue.bp" />,
    accessorKey: 'business_plan',
    cell: PortfolioNumberCell,
  },

  CHECKBOX: {
    id: 'checkbox',
    cell: CheckboxCell,
    enableSorting: false,
  },

  CAPACITY_FACTOR: {
    header: () => (
      <PortfolioTranslatedHeader
        tooltip="common.tooltips.capacity_factor"
        name="monitoring.portfolio.table.capacity_factor"
        data-cy="capacity-factor"
      />
    ),
    accessorKey: 'capacity_factor',
    cell: CapacityFactorCell,
  },

  COMMISSIONING_DATE: {
    header: () => <NoWrapHeadCell label="sales_management.commissioning_date" />,
    accessorKey: 'commissioning_date',
    cell: ({ getValue }) => (
      <DateCell value={getValue<DashboardViewAllData['commissioning_date']>()} format="YYYY-MM-DD" />
    ),
  },

  CONTRACT_TYPE: {
    header: () => <PortfolioTranslatedHeader name="common.contract" />,
    accessorKey: 'contract_type',
    cell: ({ getValue }) => <ContractCell value={getValue<DashboardViewAllData['contract_type']>()} />,
    sortUndefined: 'last',
  },

  COVERAGE: {
    header: () => <PortfolioTranslatedHeader tooltip="common.tooltips.coverage" name="common.coverage" />,
    accessorKey: 'coverage',
    cell: CoverageCell,
  },

  ENERGY: {
    header: () => <PortfolioTranslatedHeader tooltip="common.tooltips.sum_data" name="common.energy" />,
    accessorKey: 'sumdata',
    cell: PortfolioNumberCell,
  },

  LAST_READING: {
    header: () => (
      <PortfolioTranslatedHeader tooltip="common.tooltips.max_date" name="monitoring.portfolio.table.last_tr" />
    ),
    accessorKey: 'maxdate',
    cell: ({ getValue }) => <DateCell value={getValue<DashboardViewAllData['maxdate']>()} />,
  },

  MAX_POWER: {
    header: () => (
      <PortfolioTranslatedHeader tooltip="common.tooltips.max_data" name="monitoring.portfolio.table.max_power" />
    ),
    accessorKey: 'maxdata',
    cell: PortfolioNumberCell,
  },

  MEAN: {
    header: () => <PortfolioTranslatedHeader tooltip="common.tooltips.avg_data" name="common.mean" />,
    accessorKey: 'avgdata',
    cell: PortfolioNumberCell,
  },

  NAME: {
    header: () => <PortfolioTranslatedHeader name="name" />,
    accessorKey: 'ref_centrale',
    cell: NameCell,
  },

  PEAK_POWER: {
    header: () => (
      <PortfolioTranslatedHeader tooltip="common.tooltips.nominal_power" name="monitoring.portfolio.table.pui_crete" />
    ),
    accessorKey: 'puissancecrete',
    cell: PortfolioNumberCell,
  },

  PERFORMANCE: {
    header: () => <PortfolioTranslatedHeader tooltip="common.tooltips.performance" name="common.performance.light" />,
    accessorKey: 'performance',
    cell: PerformanceCell,
  },

  SPECIFIC_ENERGY_COLUMN: {
    header: () => (
      <PortfolioTranslatedHeader tooltip="common.tooltips.hour_prod" name="monitoring.portfolio.table.spec_e" />
    ),
    accessorKey: 'hourprod',
    cell: PortfolioNumberCell,
  },
} as const satisfies Record<string, ColumnDef<DashboardViewAllData>>;
