import React, { useEffect } from 'react';
import { CountryDefinitions } from '@utils/types/countries';
import { useMap } from 'react-leaflet';
import { GeoRasterData, PricingMapM0s } from '../utils/pricing-maps.types';
import { PricingMapLayers } from './PricingMapLayers';
import { PricingMapLegend } from './PricingMapLegend';

export const PricingMapContent = ({
  geoRaster,
  mask,
  m0s,
  setLatLng,
  latLng,
}: {
  geoRaster: GeoRasterData;
  mask: GeoJSON.Feature;
  m0s: PricingMapM0s;
  setLatLng: (latLng: CountryDefinitions['coordinates']) => void;
  latLng: CountryDefinitions['coordinates'];
}) => {
  const map = useMap();

  useEffect(() => {
    const resizeMap = () => map.invalidateSize();
    resizeMap();
    window.addEventListener('resize', resizeMap);

    return window.removeEventListener('resize', resizeMap);
  }, [map]);

  return (
    <>
      <PricingMapLayers geoRaster={geoRaster} mask={mask} m0s={m0s} setLatLng={setLatLng} latLng={latLng} />
      <PricingMapLegend geoRaster={geoRaster} m0s={m0s} />
    </>
  );
};
