import { UserCtxType } from '@context/User.context';
import { tString } from '@hooks/useTranslation';
import { Contract } from '@utils/types/contract';
import { FIXED_PRICE_CONTRACT_TYPES } from '../constants';

export const getRefPriceTagCsv = (contract: Contract, user: UserCtxType) => {
  const { locale } = user;
  const t = tString(locale);

  const { price_type } = contract.contract_sub_periods[0];
  const { type } = contract;

  if (type === 'ContractOa') {
    return 'FeedInTarif';
  } else if (type === 'ContractCr' || type === 'ContractCrEdf') {
    return 'M0';
  } else if (type && FIXED_PRICE_CONTRACT_TYPES.includes(type) && price_type === 'fixed') {
    return t('sales_management.price.fix');
  } else if ((type === 'ContractSoa' || type === 'ContractCppa') && price_type === 'spot') {
    return t('sales_management.price.spot');
  }

  return '';
};
