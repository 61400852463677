import React, { useCallback, useEffect, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { useUser } from '@context/User.context';
import { AltContent } from '@GDM/AltContent';
import { Button, DropDownButton } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Text } from '@GDM/Text';
import { useBusinessPlans } from '@hooks/requests/useBusinessPlans';
import useTranslation from '@hooks/useTranslation';
import { export_bp_data_v2_installation_asset_bps_path } from '@utils/routes';
import safeSumReducer from '@utils/safeSumReducer';
import { BusinessPlan as BusinessPlanType } from '@utils/types/business-plan';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import { BusinessPlanCollapsible } from './BusinessPlanCollapsible';
import { BusinessPlanForm } from './BusinessPlanForm';
import { BusinessPlanTable } from './BusinessPlanTable';
import { BusinessPlanUploadCsv } from './BusinessPlanUploadCsv';

type Props = {
  installationName: string;
};

export const BusinessPlan = ({ installationName }: Props) => {
  const { locale } = useUser();
  const { t } = useTranslation();

  const [businessPlans, setBusinessPlans] = useState<Record<string, BusinessPlanType[]>>({});
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const { data, isLoading } = useBusinessPlans(installationName);

  const [manualFormModalVisible, setManualFormModalVisible] = useState(false);
  const toggleManualFormModal = useCallback(() => setManualFormModalVisible((v) => !v), [setManualFormModalVisible]);

  const [importFormModalVisible, setImportFormModalVisible] = useState(false);
  const toggleImportFormModal = useCallback(() => setImportFormModalVisible((v) => !v), [setImportFormModalVisible]);
  const years = Object.keys(businessPlans);
  const dataExists = years.length > 0;

  const handleDownload = () => {
    window.location.href = export_bp_data_v2_installation_asset_bps_path(installationName);
  };

  useEffect(() => {
    const groupedDataByYear = data ? groupBy(data, (businessPlan) => dayjs(businessPlan.start_date).year()) : {};
    setBusinessPlans(groupedDataByYear);
  }, [data]);

  return (
    <>
      <div className="d-flex flex-row">
        <div className="fw:700 d-flex justify-content-center">
          <Text text={`${t('monitoring.bp.title').toUpperCase()} (kWh)`} className="font-bold" />
          {isLoading && <Spinner size="sm" className="ml-2" />}
        </div>

        <div className="ml-auto">
          <Button variant="primary-2" size="xs" icon="Download" text="common.download" onClick={handleDownload} />

          <Restricted permissions={['create:asset_bp']}>
            <DropDownButton
              variant="primary-2"
              size="xs"
              icon={dataExists ? 'Edit3' : 'Plus'}
              label={dataExists ? 'common.edit' : 'common.add_new'}
              disabled={isLoading}
              className="ml-3"
            >
              <Button variant="sub-button" onClick={() => toggleManualFormModal()} text="common.manually" />
              <Button variant="sub-button" onClick={() => toggleImportFormModal()} text="common.import_csv" />
            </DropDownButton>
          </Restricted>
        </div>
      </div>

      <div className="mt-3">
        {!isLoading && !dataExists && <AltContent label="monitoring.bp.none" />}
        {dataExists &&
          years.map((year) => {
            const _businessPlans = businessPlans[year] || [];
            const dataExists = _businessPlans.length > 0;
            const businessPlanTotalProd = dataExists
              ? _businessPlans
                  .map((_businessPlans) => _businessPlans.production)
                  .filter((production) => production)
                  .reduce(safeSumReducer, 0)
              : null;
            const secondaryTitle = businessPlanTotalProd
              ? `${new Intl.NumberFormat(locale).format(businessPlanTotalProd)} kWh`
              : null;

            return (
              <BusinessPlanCollapsible
                key={`business-plans-collapsible-${year}`}
                isOpen={selectedYear === year}
                title={year}
                secondaryTitle={secondaryTitle}
                toggle={() => setSelectedYear((s) => (s == year ? null : year))}
              >
                {dataExists ? (
                  <BusinessPlanTable year={year} businessPlans={_businessPlans} />
                ) : (
                  <AltContent label="monitoring.bp.none" />
                )}
              </BusinessPlanCollapsible>
            );
          })}
      </div>

      <BusinessPlanUploadCsv
        installationName={installationName}
        isOpen={importFormModalVisible}
        toggle={toggleImportFormModal}
      />

      <BusinessPlanForm
        installationName={installationName}
        modalVisible={manualFormModalVisible}
        toggleModal={toggleManualFormModal}
        businessPlans={businessPlans}
        setBusinessPlans={setBusinessPlans}
      />
    </>
  );
};
