import { createContext, useContext } from 'react';
import { Project } from './simulation.types';

export const projectContext = createContext<Project>({
  installation: null,
  book: null,
  feed_in_deviation: null,
  full_merchant: null,
  simulation: null,
  result: null,
  isBook: false,
  error: null,
  loading: true,
});

export const useProjectContext = () => useContext(projectContext);
