import React, { useState } from 'react';
import { Button } from '@GDM/Button';
import { Icon, IconNames } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './alert.module.scss';

export const Alert = ({
  children,
  className,
  dismissible,
  full,
  size,
  label,
  variant = 'info',
  icon,
}: React.PropsWithChildren<{
  className?: string;
  icon?: IconNames;
  label?: string | null;
  full?: boolean;
  size?: 'sm';
  dismissible?: boolean;
  variant?: 'info' | 'warning' | 'danger' | 'success';
}>) => {
  const { t } = useTranslation();
  const [isDismissed, setIsDimissed] = useState(false);

  if ((!children && !label) || isDismissed) return null;

  return (
    <div className={classNames(styles.alert, className, styles[variant], full && styles.full, size && styles[size])}>
      {icon && <Icon name={icon} size={14} className={classNames('mr-2', styles.icon)} />}
      <div className={styles.content}>{t(label) || children}</div>
      {dismissible && <Button icon="X" floating className={styles.close} onClick={() => setIsDimissed(true)} />}
    </div>
  );
};
