import { UserCtxType } from '@context/User.context';
import { tString } from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { isANumber } from '@utils/isANumber';
import { Contract } from '@utils/types/contract';
import round from 'lodash/round';

export const getAggregFeeCsv = (contract: Contract, user: UserCtxType): { value: number | string; unit: string } => {
  const { locale } = user;
  const t = tString(locale);
  if (contract.contract_sub_periods.length > 1)
    return {
      value: t('sales_management.price.multi'),
      unit: '',
    };
  if (contract.contract_sub_periods.length === 0)
    return {
      value: t('sales_management.price.no_price'),
      unit: '',
    };

  const { contract_sub_periods, currency } = contract;
  const subPeriod = contract_sub_periods[0];

  const { aggreg_fee, aggreg_fee_unit, aggreg_fee_fixed, aggreg_fee_percentage, price_unit } = subPeriod ?? {};

  if (isANumber(aggreg_fee) || isANumber(aggreg_fee_fixed) || isANumber(aggreg_fee_percentage)) {
    if (aggreg_fee && aggreg_fee_unit !== 'percentage') {
      return {
        value: +aggreg_fee,
        unit: price_unit || '',
      };
    }

    if (aggreg_fee && aggreg_fee_unit === 'percentage') {
      return {
        value: round(+aggreg_fee * 100, 2),
        unit: '%',
      };
    }

    if (aggreg_fee_fixed) {
      return {
        value: round(+aggreg_fee_fixed, 2),
        unit: `${getSymbol(currency)}/MWh`,
      };
    }

    if (aggreg_fee_percentage) {
      return {
        value: round(+aggreg_fee_percentage * 100, 2),
        unit: '%',
      };
    }
  }

  return {
    value: '',
    unit: '',
  };
};
