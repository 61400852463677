import type { RequestErrorBody } from '@hooks/useRequest';
import type { UseMutateFunction } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { InstallationFormRequestBody } from '../installation.types';

export type MutateFunction = UseMutateFunction<unknown, AxiosError<RequestErrorBody>, InstallationFormRequestBody>;

export const submitFormStep = (
  values: InstallationFormRequestBody,
  createMutation: MutateFunction,
  updateMutation: MutateFunction,
  isEdit: boolean,
) => {
  if (isEdit) {
    updateMutation(values);

    return;
  }

  createMutation(values);
};
