import { createMeter, getMeterById, updateMeter } from '@api/meters';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { Meter } from '@utils/types/meter';
import type { AxiosError } from 'axios';
import type { NextStepHandler } from './hooks/useStepsNavigation';
import type { InstallationForm } from './installation.types';
import { formatMeterBody } from './utils/formMeterBody';

export const useMeterFormQuery = ({ id, goToNextStep }: { id?: Meter['id']; goToNextStep: NextStepHandler }) => {
  const query = useQuery<Meter, AxiosError<RequestErrorBody>>({
    queryKey: ['meter', id],
    queryFn: () => getMeterById(id),
    enabled: !!id,
  });

  const createMutation = useMutation<Meter, AxiosError<RequestErrorBody>, InstallationForm>({
    mutationFn: (body) => createMeter(formatMeterBody(body)),
    onSuccess: () => goToNextStep(),
  });

  const updateMutation = useMutation<Meter, AxiosError<RequestErrorBody>, InstallationForm>({
    mutationFn: (body) => updateMeter(query.data?.id || id || 0, formatMeterBody(body)),
    onSuccess: () => goToNextStep(),
  });

  return { query, createMutation, updateMutation };
};
