import React from 'react';
import { Col } from '@GDM/layout/Col/Col';
import { Row } from '@GDM/layout/Row/Row';
import { TimeSeriesMinMaxAverage } from '../production.types';
import { PowerWidget } from './PowerWidget';
import { TensionWidget } from './TensionWidget';

export type MinMaxAverageWidgetsProps = {
  injectedActive?: TimeSeriesMinMaxAverage;
  withdrawnActive?: TimeSeriesMinMaxAverage;
  injectedReactive?: TimeSeriesMinMaxAverage;
  withdrawnReactive?: TimeSeriesMinMaxAverage;
  tension?: TimeSeriesMinMaxAverage;
};

export const MinMaxAverageWidgets = ({
  injectedActive,
  withdrawnActive,
  injectedReactive,
  withdrawnReactive,
  tension,
}: MinMaxAverageWidgetsProps) => {
  return (
    <>
      <Row>
        {/* active power widget */}
        {(injectedActive || withdrawnActive) && (
          <Col>
            <PowerWidget
              title="monitoring.export.active_power"
              injection={injectedActive}
              withdrawal={withdrawnActive}
            />
          </Col>
        )}
        {/* reactive power widget */}
        {(injectedReactive || withdrawnReactive) && (
          <Col>
            <PowerWidget
              title="monitoring.export.reactive_power"
              injection={injectedReactive}
              withdrawal={withdrawnReactive}
              unit="kVAR"
            />
          </Col>
        )}
        {/* tension widget */}
        {tension && (
          <Col>
            <TensionWidget {...tension} />
          </Col>
        )}
      </Row>
    </>
  );
};
