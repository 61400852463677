import { useEffect, useState } from 'react';
import { useRequest } from '@hooks/useRequest';
import { project_info_v2_ppa_project_path } from '@utils/routes';
import { Project, ProjectRequest } from './simulation.types';

export const useProject = (projectName?: string | null, simulationId?: string | null): Project => {
  const [projectInfo, setProjectInfo] = useState<Project>({
    installation: null,
    book: null,
    simulation: null,
    feed_in_deviation: null,
    full_merchant: null,
    result: null,
    isBook: false,
    error: null,
    loading: true,
  });
  const req = useRequest<ProjectRequest>(
    project_info_v2_ppa_project_path(projectName ?? '', {
      params: { ppa_simulation_id: simulationId },
    }),
    'GET',
  );

  useEffect(() => {
    if (req.error) {
      setProjectInfo({
        installation: null,
        book: null,
        simulation: null,
        feed_in_deviation: null,
        full_merchant: null,
        result: null,
        isBook: false,
        error: req.error.message,
        loading: false,
      });
    } else if (req.data) {
      setProjectInfo({
        installation: req.data.installation,
        book: req.data.book,
        simulation: req.data.simulation,
        feed_in_deviation: req.data.feed_in_deviation,
        full_merchant: req.data.full_merchant,
        result: req.data.result,
        isBook: req.data.book !== null,
        error: null,
        loading: false,
      });
    }
  }, [req.data, req.loading, req.error]);

  return projectInfo;
};
