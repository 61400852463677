import React from 'react';
import { useUser } from '@context/User.context';
import { useCurrency } from '@utils/string';
import { CountryDefinitions } from '@utils/types/countries';
import classNames from 'classnames';
import dayjs from 'dayjs';
import geoblaze from 'geoblaze';
import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import Ema from 'highcharts/indicators/ema';
import Indicators from 'highcharts/indicators/indicators';
import parentStyles from '../pricing-maps.module.scss';
import { GeoRasterData } from '../utils/pricing-maps.types';
import { usePricingMapsFiltersContext } from '../utils/pricingMapsFilters.context';
import { useSelectedPricingMapContext } from '../utils/selectedPricingMap.context';
import { getOptions } from './getOptions';
import styles from './pricing-chart.module.scss';

Indicators(HighStock);
Ema(HighStock);

export const PricingChart = ({
  latLng,
  geoRaster,
  baseLoadPrices,
  m0Prices,
}: {
  latLng: CountryDefinitions['coordinates'];
  geoRaster: GeoRasterData;
  baseLoadPrices: number[];
  m0Prices: number[];
}) => {
  const { selectedPricingMap } = useSelectedPricingMapContext();
  const periodData = selectedPricingMap?.periods.map((period) => 1000 * dayjs(period).unix()) ?? [];
  const { filters } = usePricingMapsFiltersContext();
  const isDeviation = filters.result === 'deviation';
  const { locale, timezone } = useUser();
  const currency = useCurrency();

  let selectedTimeSeries: number[] = geoblaze.identify(geoRaster, [latLng.lng, latLng.lat]);

  if (!isDeviation) {
    selectedTimeSeries = selectedTimeSeries?.map((d, i) => d + m0Prices[i]);
  }

  const selectedTimeSeriesRatio = selectedTimeSeries?.map((d, i) => (100 * d) / baseLoadPrices[i]);

  const options: Highcharts.Options = getOptions(
    locale,
    timezone,
    isDeviation,
    selectedTimeSeries,
    selectedTimeSeriesRatio,
    baseLoadPrices,
    m0Prices,
    periodData,
    latLng,
    currency,
  );

  return (
    <div className={classNames(parentStyles['content'])}>
      <div className={styles['chart']}>
        <HighchartsReact highcharts={HighStock} options={options} />
      </div>
    </div>
  );
};
