import React from 'react';
import { NumberCell, NoWrapHeadCell } from '@GDM/Table';
import { DateCell } from '@GDM/Table/Cells';
import { ColumnDef } from '@tanstack/react-table';
import compact from 'lodash/compact';
import { Line } from '../index-tab.definitions';

export const indexTableColumns = (periods: string[], showDate = true): ColumnDef<Line>[] => {
  const columns: ColumnDef<Line>[] = [
    ...(showDate
      ? [
          {
            header: () => <NoWrapHeadCell label="common.date" />,
            accessorKey: 'date',
            cell: ({ getValue }) => <DateCell value={getValue<Line['date']>()} />,
          } satisfies ColumnDef<Line>,
        ]
      : []),
    ...periods.map(
      (period) =>
        ({
          header: () => <NoWrapHeadCell label={period} tooltip="kWh" />,
          accessorKey: period,
          cell: NumberCell,
        } satisfies ColumnDef<Line>),
    ),
  ];

  return compact(columns);
};
