import React from 'react';
import { ValueCard } from '@GDM/Card';
import { TimeSeriesMinMaxAverage } from '../production.types';
import { LabeledValue } from './LabeledValue';

export const TensionWidget = ({ average, max, min }: TimeSeriesMinMaxAverage) => {
  return (
    <ValueCard className="h-100" title="monitoring.export.tension" value={average} unit="V">
      {max && <LabeledValue label="common.max" value={max.value} unit="V" date={max.date} />}
      {min && <LabeledValue label="common.min" value={min.value} unit="V" date={min.date} />}
    </ValueCard>
  );
};
