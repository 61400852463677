import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InstallationProvider } from '@components/Installations/InstallationProvider';
import { PlayersProvider } from '@components/providers';
import { Alert } from '@GDM/Alert';
import useBooks from '@hooks/requests/useBooks';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import Page from '@pages/Page';
import { QueryClient } from '@tanstack/react-query';
import { formatSystemDate, toDate } from '@utils/formatters';
import { v2_revenue_reports_path } from '@utils/routes';
import { Locale, Option } from '@utils/types/common-types';
import { CountryCode } from '@utils/types/countries';
import { Currency } from '@utils/types/currency';
import { User } from '@utils/types/user';
import dayjs from 'dayjs';
import Reports from './components/Reports';
import { SitesReports } from './components/SitesReports';
import { revenueReportingContext } from './context';
import { RevenueReportingContext, RevenueReportsResponse } from './reporting.types';
import { ReportsActions } from './ReportsActions';
import { useDownloadAnnualReport } from './utils/hooks/useDownloadAnnualReport';
import useDownloadPdf from './utils/hooks/useDownloadPdf';

const initialDate = dayjs();
const initialStartDate = initialDate.subtract(1, 'month').startOf('year').format('YYYY-MM-DD');
const initialEndDate = initialDate
  .subtract(initialDate.date() > 24 ? 0 : 1, 'month')
  .startOf('month')
  .format('YYYY-MM-DD');

const queryClient = new QueryClient();

const PortfolioPage = ({ user, locale }: { locale: Locale; user: User }) => {
  const { t } = useTranslation(locale);
  const tabs = { reports: 'portfolio', sites_by_sites: t('sales_management.site_by_site') };
  const { data: books } = useBooks({ bookType: 'monitoring', queryClient });
  const [activeTab, setActiveTab] = useState<keyof typeof tabs>('reports');
  const [selectedDate, setSelectedDate] = useState<Date>(toDate(initialEndDate));
  const [selectedBook, setSelectedBook] = useState<string | null>(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string | string[] | null | undefined>();
  const [selectedCountry, setSelectedCountry] = useState<CountryCode | null>(null);
  const [requestStartDate, setRequestStartDate] = useState<string>(initialStartDate);
  const [requestEndDate, setRequestEndDate] = useState<string>(initialEndDate);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(user.currencies?.[0] || 'EUR');

  const selectedYear = useRef<number>(selectedDate.getFullYear());
  if (selectedDate.getFullYear() !== selectedYear.current) selectedYear.current = selectedDate.getFullYear();

  const reportsRef = useRef<HTMLDivElement | null>(null);

  let market_player_ids = null;
  if (selectedPlayerId && Array.isArray(selectedPlayerId)) market_player_ids = selectedPlayerId;
  if (selectedPlayerId && !Array.isArray(selectedPlayerId)) market_player_ids = [selectedPlayerId];

  const {
    loading,
    error: downloadError,
    downloadPdf,
  } = useDownloadPdf({
    selectedDate,
    selectedBook,
    selectedCountry,
    selectedMarketPlayers: market_player_ids,
  });
  const {
    loading: loadingAnnualReport,
    execute: downloadAnnualReport,
    error: annualReportError,
  } = useDownloadAnnualReport();

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date === null) return;

      setSelectedDate(date);

      if (date.getFullYear() !== toDate(requestStartDate).getFullYear()) {
        setRequestStartDate(formatSystemDate(dayjs(date).startOf('year').toDate()));
        setRequestEndDate(formatSystemDate(dayjs(date).startOf('month').toDate()));
      }
    },
    [requestStartDate],
  );

  const handleExportAnnualRevenue = useCallback(
    ({ forecast }: { forecast: boolean }) => {
      return () => downloadAnnualReport?.({ params: { year: selectedDate.getFullYear(), forecast } });
    },
    [downloadAnnualReport, selectedDate],
  );

  const optionsBooks: Option[] = books?.map((b) => ({ label: b.name, value: b.uuid, energy: 'book' })) || [];

  const {
    data,
    error: requestError,
    loading: requestLoading,
  } = useRequest<RevenueReportsResponse & RevenueReportsResponse<'split'>>(
    v2_revenue_reports_path({
      start_date: requestStartDate,
      end_date: formatSystemDate(dayjs(requestEndDate).add(1, 'year').startOf('year').toDate()),
      book_uuid: selectedBook,
      market_player_ids,
      country: selectedCountry,
      view: activeTab === 'sites_by_sites' ? 'split' : 'overview',
      currency: selectedCurrency,
    }),
    'GET',
  );

  const currencies = user.currencies ?? ['EUR'];
  const cumulative = data?.[0]?.data?.cumulative;
  const monthly = data?.[0]?.data?.monthly;
  const currency = selectedCurrency ?? 'EUR';
  const splitData = Array.isArray(data?.[0]?.data) ? data?.[0].data : null;

  useEffect(() => {
    if (data?.[0]?.currency && !selectedCurrency) setSelectedCurrency(data[0].currency);
  }, [data, selectedCurrency]);

  const revenueReportingContextValue: RevenueReportingContext = useMemo(
    () => ({
      loading: requestLoading,
      error: !!requestError,
      selectedDate,
      cumulative,
      monthly,
      selectedBook,
      selectedPlayerId,
      selectedCountry,
      setSelectedBook,
      setSelectedDate,
      setSelectedPlayerId,
      setSelectedCountry,
      currency,
      splitData,
    }),
    [
      requestError,
      requestLoading,
      selectedBook,
      selectedDate,
      selectedPlayerId,
      selectedCountry,
      currency,
      cumulative,
      monthly,
      splitData,
    ],
  );

  return (
    <InstallationProvider type="operational">
      <PlayersProvider onlyRevenuePlayers>
        <revenueReportingContext.Provider value={revenueReportingContextValue}>
          <Page
            user={user}
            title="sales_management.reports_title"
            isLoading={requestLoading}
            layout="no-background"
            error={requestError?.message}
            header={
              <ReportsActions
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                books={books || []}
                optionsBooks={optionsBooks}
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                loadingAnnualReport={loadingAnnualReport}
                loading={loading}
                handleExport={downloadPdf}
                reportRequestLoading={loading}
                reportRequestError={annualReportError}
                handleExportAnnualRevenue={handleExportAnnualRevenue}
                currencies={currencies}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
            }
          >
            {downloadError && (
              <Alert
                icon="AlertCircle"
                variant="danger"
                label="sales_management.portfolio.download_error"
                className="mt-2"
                dismissible
              />
            )}
            {requestError && (
              <Alert icon="AlertCircle" variant="danger" label={requestError.message} className="mt-2" dismissible />
            )}
            <div className="mt-3">
              {!requestError && (
                <>
                  {activeTab === 'reports' && <Reports ref={reportsRef} />}
                  {activeTab === 'sites_by_sites' && <SitesReports />}
                </>
              )}
            </div>
          </Page>
        </revenueReportingContext.Provider>
      </PlayersProvider>
    </InstallationProvider>
  );
};

export default PortfolioPage;
