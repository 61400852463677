import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const getOptions = (contracts: Contract[]) => {
  const allCardI = contracts.map((contract) => contract.card_i).filter((card_i) => card_i);
  const uniqueCardI = [...new Set(allCardI)];
  const options: Option<string>[] = uniqueCardI.map((cardI) => ({ label: cardI, value: cardI }));

  return options;
};

export const CardIFilter = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();

  const options: Option<string>[] = useDynamicOptions(getOptions, 'card_i', contracts, allContracts);

  return (
    <Controller
      name="card_i"
      control={form.control}
      render={({ field }) => (
        <Select
          isMulti
          options={options}
          inline
          selectedOptions={field.value}
          onChange={(values) => {
            const card_i = values?.map((v) => v.value) ?? [];

            filterContracts({ ...form.getValues(), card_i, last_selected_filter: 'card_i' });
            field.onChange(card_i);
            form.setValue('last_selected_filter', card_i.length > 0 ? 'card_i' : null);
          }}
          classNamePrefix="contracts-filters-cardi"
          placeholder="sales_management.card_i"
        />
      )}
    />
  );
};
