import type { OwnerBody } from '@api/owners';
import pick from 'lodash/pick';
import type { OwnerForm } from '../ownerForm.types';

export const formatOwnerBody = (values: OwnerForm & { uuid?: string }) => {
  const clonedValues: Partial<OwnerForm> = { ...values };

  const owner: OwnerBody = {
    ...pick(clonedValues, [
      'short_name',
      'erp_code',
      'bank',
      'iban',
      'swift_bic',
      'company_number',
      'invoicing_emails',
      'street_address',
      'postcode',
      'vat_number',
      'city',
    ]),
    installation_uuid: values.uuid ?? undefined,
    country: values.country ?? undefined,
  };

  return owner;
};
