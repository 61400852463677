import React from 'react';
import { updateStatus } from '@api/invoices';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { Button } from '@GDM/Button';
import { usePermittedStatus } from '@hooks/requests/invoices/usePermittedStatus';
import { useMutation } from '@tanstack/react-query';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';

const IgnoreButton = () => {
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { updateInvoices } = useInvoicesContext();
  const { disabled } = usePermittedStatus('ignored');
  const mutation = useMutation({
    mutationFn: async () => updateStatus('ignore', selectedInvoicesUuids),
    onSuccess: (invoices) => {
      updateInvoices(invoices);
    },
  });

  return (
    <Button
      variant="sub-button"
      text="monitoring.invoicing.ignore"
      onClick={() => mutation.mutate()}
      isLoading={mutation.isPending}
      disabled={disabled}
    />
  );
};

export default IgnoreButton;
