import type { CreateUpdateMeterBody } from '@api/meters';
import pick from 'lodash/pick';
import { InstallationForm } from '../installation.types';

export const formatMeterBody = (values: InstallationForm) => {
  const clonedValues: Partial<InstallationForm> = { ...values };

  const meterBody: CreateUpdateMeterBody = {
    ...pick(clonedValues, [
      'prm',
      'numero',
      'key',
      'mandate_uuid',
      'aiguillage',
      'code',
      'ip',
      'tcp_port',
      'boitier_ip',
      'serial_number',
      'model',
      'typecompteur',
      'equipment_direction',
      'meter_pass_address',
      'producteur',
      'data_provider_id',
      'data_provider_number',
      'grid_id',
    ]),
    installation_uuid: clonedValues.uuid,
    meter_request: {
      name: clonedValues.meter_request_name || '',
      email: clonedValues.meter_request_email || '',
      comment: clonedValues.meter_request_comment || '',
    },
  };

  return meterBody;
};
