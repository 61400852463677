import { ContractFormGlobals } from '@pages/Contracts/Contract/hooks/useContractForm/types';
import { formatSystemDate } from '@utils/formatters';
import { isValidDate } from '@utils/isValidDate';
import { ContractForm, ContractFormBody, ContractType } from '@utils/types/contract';
import omit from 'lodash/omit';
import { serializeHedgeBlocks } from './serializeHedgeBlocks';
import { serializeSubPeriods } from './serializeSubPeriods';

/**
 * Cleans up the contract data before sending the data to the server.
 */
export function serializeContract(data: ContractForm, globals: ContractFormGlobals): ContractFormBody {
  const { start_date, end_date, birthday_date, type, financing, external_pdf_link } = data;

  const subPeriods = serializeSubPeriods(data);
  const hedgeBlocks = serializeHedgeBlocks(data);

  const body: ContractFormBody = {
    ...omit(data, 'energy'),
    type: (type?.replace('-LIGHT', '') as ContractType) ?? null,
    financement_participatif: financing === 'financement_participatif',
    investissement_participatif: financing === 'investissement_participatif',
    start_date: formatSystemDate(start_date),
    end_date: formatSystemDate(end_date),
    power_augmentation_start_date: isValidDate(data.power_augmentation_start_date)
      ? formatSystemDate(data.power_augmentation_start_date)
      : null,
    birthday_date: isValidDate(birthday_date) ? formatSystemDate(birthday_date) : null,
    contract_sub_periods_attributes: subPeriods,
    hedge_contract_attributes: hedgeBlocks,
    external_pdf_link: external_pdf_link || null,
    seller_id: data.seller_id || globals.installation?.owner_uuid || null,
    buyer_id: data.buyer_id || globals.offtaker?.id || null,
    invoicing_period: data.invoicing_period || 'monthly',
    delivery_profile: data.type === 'ContractSupplyAuxiliaries' ? 'pay_as_consumed' : data.delivery_profile,
  };

  // In the case of cPPA contracts, clear out the birthday_date and index_rate if it was set and no longer needed.
  if (type === 'ContractCppa' && subPeriods[0]?.price_type !== 'indexed') {
    body.index_formula = null;
    body.birthday_date = null;
  }

  // If there's no invoicing platform, makes sure the credential is removed in the db
  if (!body.invoicing_platform) body.credential_id = null;

  if (body._non_contract_props) delete body._non_contract_props;
  if (body.financing) delete body.financing;
  if (body.hedge_blocks_attributes) delete body.hedge_blocks_attributes;

  return body;
}
