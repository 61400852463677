import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { Contract } from '@utils/types/contract';
import dayjs from 'dayjs';

export const HorizonCell = ({ row: { original } }: CellContext<Contract, unknown>) => {
  const format = 'DD MMM YY';

  return (
    <div>
      {dayjs(original.start_date).format(format)} {'>'} {dayjs(original.end_date).format(format)}
    </div>
  );
};
