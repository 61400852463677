import React from 'react';
import { HeightInput } from '../fields/Wind/HeightInput';
import { NbTurbInput } from '../fields/Wind/NbTurbInput';
import { TotalPowerWindInput } from '../fields/Wind/TotalPowerWindInput';
import { TurbineSelect } from '../fields/Wind/TurbineSelect';
import styles from '../new-simulation.module.scss';

export const WindSection = () => {
  return (
    <>
      <div className={styles.row}>
        <HeightInput />
        <TotalPowerWindInput />
      </div>

      <div className={styles.row}>
        <NbTurbInput />
        <TurbineSelect />
      </div>
    </>
  );
};
