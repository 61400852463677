import { useEffect, useMemo, useState } from 'react';
import { Step } from '@GDM/forms';
import Installation from '@utils/types/installation';
import { UseFormReturn } from 'react-hook-form';
import { ALL_SOLAR_STEPS, ALL_WIND_STEPS, DEFAULT_STEPS } from '../config/steps';
import { setInstallationValues } from './setInstallationValues';
import { SimulationForm } from './simulations.types';

export const useBuilder = (
  form: UseFormReturn<SimulationForm, unknown>,
  installations: Installation[],
): {
  steps: Record<string, Step>;
  selectedInstallation: Installation | null;
} => {
  const [steps, setSteps] = useState<Record<string, Step>>(DEFAULT_STEPS);
  const { watch, reset, getValues, setValue } = form;
  const installationId = watch('installation_attributes.id');

  const selectedInstallation = useMemo(() => {
    const installation = installations?.find((installation) => installation.uuid === installationId) || null;

    return installation;
  }, [installationId, installations]);

  useEffect(() => {
    const { unsubscribe } = watch(({ installation_attributes, no_strike }, { name }) => {
      const energy = installation_attributes?.energy;

      // the !name condition is used to trigger the effect on reset
      if (name === 'installation_attributes.energy' || !name) {
        if (energy === 'solar') {
          setSteps(ALL_SOLAR_STEPS);
        } else if (energy === 'wind') {
          setSteps(ALL_WIND_STEPS);
        } else {
          setSteps(DEFAULT_STEPS);
        }
      }

      if (name === 'no_strike') {
        if (!no_strike) {
          setValue('fixed_stop_strike', null);
          setValue('dynamic_stop_strike', null);
        }
      }
    });

    return () => unsubscribe();
  }, [setValue, watch]);

  useEffect(() => {
    setInstallationValues(reset, getValues, selectedInstallation);
  }, [reset, getValues, selectedInstallation, installationId]);

  return { steps, selectedInstallation };
};
