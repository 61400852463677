import React, { createContext, useContext, useMemo } from 'react';
import { RequestError, useRequest } from '@hooks/useRequest';
import { revenuePlayers } from '@utils/filters/revenuePlayers';
import { all_players_v2_market_players_path } from '@utils/routes';
import MarketPlayer from '@utils/types/market_player';

type PlayersContext = {
  players: MarketPlayer[] | null;
  loading: boolean;
  loaded: boolean;
  error?: RequestError | null;
};

const playersContext = createContext<PlayersContext>({ players: null, loaded: false, loading: false });

export const usePlayersContext = (): PlayersContext => useContext(playersContext);

export const PlayersProvider = ({
  children,
  onlyRevenuePlayers,
}: React.PropsWithChildren<{ onlyRevenuePlayers?: boolean }>) => {
  const { data, loading, error, loaded } = useRequest<MarketPlayer[]>(all_players_v2_market_players_path(), 'GET');

  const players = onlyRevenuePlayers ? (data ?? []).filter(revenuePlayers) : data;

  const playersContextValue: PlayersContext = useMemo(
    () => ({
      players,
      loading,
      loaded,
      error,
    }),
    [error, loaded, loading, players],
  );

  return <playersContext.Provider value={playersContextValue}>{children}</playersContext.Provider>;
};
