import React from 'react';
import { ValueCard } from '@GDM/Card';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import styles from './cards.module.scss';

export const MarketDeviationFactorCard = () => {
  const project = useProjectContext();
  const { final_q50_ratio, final_q50 } = project?.feed_in_deviation || {};
  const variant = final_q50 ? (final_q50 >= 0 ? 'primary-1' : 'secondary') : null;

  return (
    <ValueCard
      title="pricing.project.market_value_deviation"
      value={final_q50_ratio || null}
      unit="percentage"
      className={styles.card}
      valueVariant={variant}
    >
      <ValueWithUnit value={final_q50} unit="EUR/MWh" />
    </ValueCard>
  );
};
