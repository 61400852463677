import React from 'react';
import { BookProvider } from '@components/Books/BookProvider';
import { Tabs } from '@GDM/Tabs';
import Page from '@pages/Page';
import { Locale } from '@utils/types/common-types';
import type { Contract } from '@utils/types/contract';
import { User } from '@utils/types/user';
import { TABS } from './invoices.constants';
import { InvoicesTabContents } from './InvoicesTabContents';

const Invoicing = ({ user, direction }: { locale: Locale; user: User; direction: Contract['direction'] }) => {
  return (
    <Page title={direction === 'buy' ? 'common.purchase_invoices' : 'common.invoices'} user={user} fixedHeightContainer>
      <BookProvider>
        <Tabs tabs={TABS[direction]} className="mb-3" noContainer>
          <InvoicesTabContents direction={direction} />
        </Tabs>
      </BookProvider>
    </Page>
  );
};

export default Invoicing;
