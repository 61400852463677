import React, { useMemo } from 'react';
import { Text } from '@GDM/Text';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { SelectedYearContextType, selectedYearContext } from '@pages/Simulations/Simulation/utils/selectedYear.context';
import classNames from 'classnames';
import { FullMerchantDefinition } from '../FullMerchantDefinition';
import { CannibalizationImpactDeviationFullMerchantChart } from './CannibalizationImpactDeviationFullMerchantChart';
import styles from './charts.module.scss';
import { YearlyAnalysisChart } from './YearlyAnalysisChart';
import { YearPicker } from './YearPicker';

export const ChartsFullMerchant = () => {
  const project = useProjectContext();
  const { full_merchant_cannibalized_distribution_cat } = project?.full_merchant || {};
  const [selectedYear, setSelectedYear] = React.useState<string | null>(null);

  const selectedYearContextValue = useMemo<SelectedYearContextType>(() => {
    return { selectedYear, setSelectedYear };
  }, [selectedYear]);

  if (full_merchant_cannibalized_distribution_cat && !selectedYear) {
    setSelectedYear('1');
  }

  return (
    <div className="mt-3">
      <div className={styles['charts-container']}>
        <CannibalizationImpactDeviationFullMerchantChart percentage />
        <CannibalizationImpactDeviationFullMerchantChart />
      </div>

      <selectedYearContext.Provider value={selectedYearContextValue}>
        <div className={classNames('mt-3', styles['title-select'])}>
          <Text text="pricing.project.analysis" className={classNames(styles['chart-title'], 'mb-1')} />
          <YearPicker />
        </div>

        <div className={styles['charts-container']}>
          <div className={styles['definition-container']}>
            <FullMerchantDefinition />
          </div>

          <YearlyAnalysisChart />
        </div>
      </selectedYearContext.Provider>
    </div>
  );
};
