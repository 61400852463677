import React from 'react';
import { ValueCard } from '@GDM/Card';
import { Progress } from '@GDM/Progress';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import { isANumber } from '@utils/isANumber';

export function EnergyProductionBenchmarkWidget({
  energyType,
  totalProdEnergyPlanned,
  totalPerformance,
  totalPerformanceLoss,
  dataPeriod,
  isBook,
}: {
  energyType?: string;
  totalProdEnergyPlanned?: number;
  totalPerformance?: number;
  totalPerformanceLoss?: number;
  dataPeriod: string;
  isBook: boolean;
}) {
  const { t } = useTranslation();
  const tooltipEnergyTypeMap: Record<string, string> = {
    solar: 'common.solar_potential_power_info',
    hydro: 'common.hydro_potential_power_info',
    wind: 'common.benchmark_info',
  };

  const isNotWindAndNotBook = energyType !== 'wind' && !isBook;
  const isDataPeriodMinutes = dataPeriod === 'minutes';
  const performance = isNotWindAndNotBook || isDataPeriodMinutes ? totalPerformance ?? 0 : 0;

  return (
    <ValueCard
      title={energyType == 'wind' ? 'common.performance.wind' : 'common.performance.main'}
      value={totalProdEnergyPlanned}
      tooltip={energyType && tooltipEnergyTypeMap[energyType]}
      unit="kWh"
    >
      <Progress value={performance} dangerThreshold={85} valueInside />
      {dataPeriod === 'minutes' &&
        energyType !== 'wind' &&
        energyType !== 'hydro' &&
        isANumber(totalPerformanceLoss) &&
        totalPerformanceLoss > 0 && (
          <div className="mt-3">
            {t('monitoring.portfolio.loss_of')}&nbsp;
            <ValueWithUnit value={totalPerformanceLoss} unit="kWh" />
          </div>
        )}
    </ValueCard>
  );
}
