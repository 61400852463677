import { isANumber } from '@utils/isANumber';
import { MeterInvoiceRevenue } from '@utils/types/meter-invoice';
import round from 'lodash/round';
import { UpdateInvoiceForm } from '../UpdateModal/update-modal.types';

export const parseRevenuesResponseBody = (revenues: MeterInvoiceRevenue[]): UpdateInvoiceForm['revenues'] => {
  return revenues.map((revenue) => ({
    uuid: revenue.uuid,
    quantity: isANumber(revenue.quantity) ? round(revenue.quantity, 8)?.toString() : '',
    label: revenue.label,
    // convert from {currency}/kWh to c{currency}/kWh
    unit_price: isANumber(revenue.unit_price) ? round(revenue.unit_price * 100, 8)?.toString() : '',
    revenue_type: revenue.revenue_type,
  }));
};
