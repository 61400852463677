import { createContext } from 'react';
import dayjs from 'dayjs';
import { RevenueReportingContext } from '../reporting.types';

export const revenueReportingContext = createContext<RevenueReportingContext>({
  loading: false,
  error: false,
  selectedBook: null,
  selectedCountry: null,
  selectedDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
  currency: 'EUR',
});
