import { createContext, useContext } from 'react';
import { OwnersContextType } from './owner.types';

export const ownersContext = createContext<OwnersContextType>({
  owners: [],
  updateOwner: () => {},
  setOwners: () => {},
  allOwners: [],
});

export const useOwnersContext = (): OwnersContextType => useContext(ownersContext);
