import { FINANCIAL_CONTRACTS } from '@utils/constants';
import type { Classification, ContractForm } from '@utils/types/contract';

export const getClassificationValue = (contractType: ContractForm['type']): Classification | null => {
  if (!contractType) {
    return null;
  }

  return FINANCIAL_CONTRACTS.has(contractType) ? 'financial' : 'physical';
};
