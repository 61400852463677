import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Input, Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Subscription } from '@utils/types/subscription';
import { Controller } from 'react-hook-form';
import styles from '../subscription-form.module.scss';
import { useSubscriptionForm } from '../useSubscriptionForm';

export const PricingParams = () => {
  const { control, readonly } = useSubscriptionForm();

  const frequencyOptions: Option<Subscription['time_period']>[] = [
    { value: 'monthly', label: 'subscriptions.frequencies.monthly' },
    { value: 'yearly', label: 'subscriptions.frequencies.yearly' },
    { value: 'one_time', label: 'subscriptions.frequencies.one_time' },
  ];

  const discountTypeOptions: Option<`${Subscription['discount_type']}`>[] = [
    { value: 'fixed', label: 'subscriptions.price_fixed' },
    { value: 'variable', label: 'subscriptions.price_variable' },
  ];

  return (
    <>
      <div className={styles.section}>
        <Controller
          control={control}
          name="start_date"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <DatePicker
              wrapperClassName={styles.input}
              label="common.start_date"
              selected={new Date(field.value)}
              onChange={field.onChange}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
            />
          )}
        />
        <Controller
          control={control}
          name="time_period"
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Select
              options={frequencyOptions}
              className={styles.input}
              selectedOption={field.value}
              label="subscriptions.frequency"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
      </div>
      <div className={styles.section}>
        <Controller
          control={control}
          name="price_fixed"
          rules={{ min: 0 }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="subscriptions.price_fixed"
              size="lg"
              hasError={!!fieldState.error}
              type="number"
              suffix="€"
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="price_variable"
          rules={{ min: 0 }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="subscriptions.price_variable"
              tooltip="subscriptions.info.price_variable"
              size="lg"
              hasError={!!fieldState.error}
              type="number"
              suffix="€/Meter/MW"
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="price_variable_2"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="subscriptions.price_variable_2"
              tooltip="subscriptions.info.price_variable_2"
              size="lg"
              hasError={!!fieldState.error}
              type="number"
              suffix="€/Meter/MW"
              readOnly={readonly}
              full
            />
          )}
        />
      </div>
      <div className={styles.section}>
        <Controller
          control={control}
          name="discount_amount"
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.input}
              value={field.value ?? ''}
              label="subscriptions.discount_amount"
              size="lg"
              hasError={!!fieldState.error}
              type="number"
              suffix="€/Meter/MW"
              readOnly={readonly}
              full
            />
          )}
        />
        <Controller
          control={control}
          name="discount_type"
          render={({ field, fieldState }) => (
            <Select
              options={discountTypeOptions}
              className={styles.input}
              selectedOption={field.value}
              label="subscriptions.discount_type"
              onChange={(option) => field.onChange(option?.value)}
              size="lg"
              hasError={!!fieldState.error}
              readOnly={readonly}
              full
            />
          )}
        />
      </div>
    </>
  );
};
