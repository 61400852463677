import React, { useEffect, useMemo } from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import axiosInstance from '@utils/axiosInstance';
import { get_details_v2_export_meter_invoicings_path } from '@utils/routes';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';

const fetchCsv = async ({ url, options }: { url: string; options?: AxiosRequestConfig }) => {
  const res = await axiosInstance(false).get(url, { ...options, responseType: 'blob' });

  if (res.data && res.headers['content-disposition']) {
    const fileName = res.headers['content-disposition'].split('"')[1];
    fileDownload(res.data, fileName);
  }

  return res.data;
};

export const DownloadAuditCsvButton = ({
  invoiceType,
  onError,
}: {
  invoiceType: MeterInvoiceSource;
  onError: (error: string | null) => void;
}) => {
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { filteredInvoices } = useInvoicesContext();

  const allInvoices = useMemo(
    () => filteredInvoices.filter((invoice) => selectedInvoicesUuids.includes(invoice.uuid)),
    [filteredInvoices, selectedInvoicesUuids],
  );

  const disabled = allInvoices.some((i) => i.contract_type === 'ContractOa');

  const { t } = useTranslation();

  const {
    execute: downloadAudit,
    error,
    loading,
    loaded,
  } = useRequest(get_details_v2_export_meter_invoicings_path(), fetchCsv, true);

  useEffect(() => {
    if (loaded && error) {
      onError(error.code);
    }
  }, [error, loaded, onError]);

  return (
    <Button
      variant="sub-button"
      onClick={() => downloadAudit?.({ params: { uuids: selectedInvoicesUuids, only_capa: invoiceType === 'capa' } })}
      disabled={disabled}
    >
      {loading ? <Icon name="Loader" size={14} className="rotate" /> : t('common.csv')}
    </Button>
  );
};
