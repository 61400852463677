import React, { useMemo, useContext } from 'react';
import { ValueCard } from '@GDM/Card';
import { Simulation } from '@utils/types/simulation';
import classNames from 'classnames';
import styles from './simulations.module.scss';
import SelectedRowsContext from './state/selectedRows';

const computeAverageDeviation = (simulations: Simulation[]): number => {
  if (simulations.length === 0) {
    return 0;
  }

  const totalPower = simulations.reduce((prev, curr) => prev + (curr.maximum_power || 0), 0);
  const totalWeightedAverage = simulations.reduce(
    (prev, curr) => prev + curr.maximum_power * (curr.results?.deviation?.q50 || 0),
    0,
  );

  return totalWeightedAverage / totalPower;
};

/**
 * Display the average market value deviation of the selected simulations
 */
const MarketValueDeviationWidget = ({ mapVisible }: { mapVisible: boolean }) => {
  const { selectedRows } = useContext(SelectedRowsContext);
  const avgDev = useMemo(() => computeAverageDeviation(selectedRows), [selectedRows]);

  if (selectedRows.length === 0) {
    return null;
  }

  return (
    <div className={classNames(styles['value-card-container'], { [styles['map-visible']]: mapVisible })}>
      <ValueCard title="pricing.project.price_gap" unit="EUR/MWh" value={avgDev} />
    </div>
  );
};

export default MarketValueDeviationWidget;
