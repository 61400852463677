import React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useMandates } from '@hooks/requests/useMandates';
import { CommonRequestHookResponse } from '@hooks/useRequest';
import { Mandate } from '@utils/types/mandate';

export type MandatesContext = CommonRequestHookResponse<Mandate[]>;

export const mandatesContext = createContext<MandatesContext>({
  data: [],
  loading: false,
  loaded: false,
  error: null,
  status: null,
});

export const MandatesProvider = ({ children }: React.PropsWithChildren) => {
  const req = useMandates();
  const value = useMemo<MandatesContext>(() => {
    return req;
  }, [req]);

  return <mandatesContext.Provider value={value}>{children}</mandatesContext.Provider>;
};

export const useMandatesContext = () => useContext(mandatesContext);
