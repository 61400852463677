import { useState } from 'react';

export const useConfigurableFields = <ConfigurableField extends string>(
  localStorageKey: string,
  defaultValue?: ConfigurableField[],
) => {
  const state = useState<Set<ConfigurableField>>(() => {
    const configurableFieldsFromLocalStorage = localStorage.getItem(localStorageKey);
    const parsedConfigurableFieldsFromLocalStorage = configurableFieldsFromLocalStorage?.split(',');

    return parsedConfigurableFieldsFromLocalStorage
      ? new Set(parsedConfigurableFieldsFromLocalStorage as ConfigurableField[])
      : new Set(defaultValue ? defaultValue : []);
  });

  return state;
};
