import React from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import styles from './text.module.scss';

/** Displays a line of text that can be small or of a specific colour. */
export const Text = ({
  children,
  className,
  'data-cy': dataCy,
  icon,
  tooltip,
  size,
  text,
  type,
  multiline,
  iconProps,
}: {
  className?: string;
  type?: 'normal' | 'danger' | 'good' | 'warning';
  size?: 'xs' | 'sm' | 'md';
  icon?: IconNames;
  tooltip?: string;
  text?: string | null;
  multiline?: boolean;
  'data-cy'?: string;
  children?: React.ReactNode;
  iconProps?: Partial<React.ComponentProps<typeof Icon>>;
}) => {
  const { t } = useTranslation();
  const classList = classNames(
    styles.text,
    size && styles[size],
    type && styles[type],
    !multiline && 'ellipsis',
    className,
  );

  return (
    <div className={classList} title={t(text)?.replace(/(<([^>]+)>)/gi, '')} data-cy={dataCy}>
      {icon && <Icon name={icon} size={14} {...iconProps} className={classNames(styles.icon, iconProps?.className)} />}
      {children || <span dangerouslySetInnerHTML={text ? { __html: t(text) } : undefined} />}
      {tooltip && (
        <Icon
          name="HelpCircle"
          size={13}
          title={tooltip}
          {...iconProps}
          className={classNames(styles.tooltip, iconProps?.className)}
        />
      )}
    </div>
  );
};
