import { createContext, useContext } from 'react';

type ModalContext = {
  isOpen: boolean;
  toggle?: () => void;
};

export const cancelModalContext = createContext<ModalContext>({
  isOpen: false,
});

export const useCancelModalContext = (): ModalContext => useContext(cancelModalContext);
