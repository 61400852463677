import React from 'react';
import { AltContent } from '@GDM/AltContent';
import { Col, Row } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import { DataWidget } from '@ui/DataWidget';
import MarketChart from './MarketChart';

const MarketData = React.forwardRef<HTMLDivElement, { printable?: boolean }>(({ printable }, ref) => {
  const { t } = useTranslation();
  const { current, selectedCountry, currency } = useRevenueReportingContext();

  if (!current) return null;

  const {
    market_value_base,
    market_value_solar,
    market_value_wind,
    local_value_wind,
    local_value_solar,
    total_unit_price_wind,
    total_unit_price_solar,
  } = current;

  const baseValue = (market_value_base?.value ?? 0) * 1000;
  const m0WindValue = (market_value_wind?.value ?? 0) * 1000;
  const m0SolarValue = (market_value_solar?.value ?? 0) * 1000;
  const m1WindValue = (local_value_wind?.value ?? 0) * 1000;
  const m1SolarValue = (local_value_solar?.value ?? 0) * 1000;

  const averageSalesWindValue = (total_unit_price_wind?.value ?? 0) * 1000;
  const averageSalesSolarValue = (total_unit_price_solar?.value ?? 0) * 1000;

  const countryHasM0 = selectedCountry === 'FR' || selectedCountry === 'DE';

  const hasSolarData = m1SolarValue !== 0 || averageSalesSolarValue !== 0;
  const hasWindData = m1WindValue !== 0 || averageSalesWindValue !== 0;

  let solarDiff = null;
  if (countryHasM0 && !!m0SolarValue && !!m1SolarValue) solarDiff = m1SolarValue - m0SolarValue;
  if (!countryHasM0 && !!baseValue && !!m1SolarValue) solarDiff = m1SolarValue - baseValue;

  let windDiff = null;
  if (countryHasM0 && !!m0WindValue && !!m1WindValue) windDiff = m1WindValue - m0WindValue;
  if (!countryHasM0 && !!baseValue && !!m1WindValue) windDiff = m1WindValue - baseValue;

  if (!hasSolarData && !hasWindData) return <AltContent label="sales_management.reports.no_market_data" />;

  return (
    <div ref={ref} className="MarketChart" data-cy="market-chart">
      <Row>
        {hasWindData && (
          <Col md={hasSolarData ? 6 : 12}>
            <div className="MarketChartContainer">
              <MarketChart
                printable={printable}
                title={t('common.wind.eolian')}
                isM0Estimated={
                  market_value_wind?.status === 'ESTIMATED' || market_value_wind?.status === 'LIGHT_ESTIMATION'
                }
                chartValues={{
                  m0: countryHasM0 ? m0WindValue : null,
                  m1: m1WindValue,
                  base: !countryHasM0 ? baseValue : null,
                  average_sales_price: averageSalesWindValue,
                }}
                currency={currency}
              />
            </div>

            {windDiff && (
              <div className="mt-3">
                <DataWidget
                  printable={printable}
                  title="sales_management.valo_spot_vs_m0_wind"
                  value={windDiff}
                  unit={`${currency}/MWh`}
                />
              </div>
            )}
          </Col>
        )}

        {hasSolarData && (
          <Col md={hasWindData ? 6 : 12}>
            <div className="MarketChartContainer">
              <MarketChart
                printable={printable}
                title={t('common.pv.solar')}
                isM0Estimated={
                  market_value_solar?.status === 'ESTIMATED' || market_value_solar?.status === 'LIGHT_ESTIMATION'
                }
                chartValues={{
                  m0: countryHasM0 ? m0SolarValue : null,
                  m1: m1SolarValue,
                  base: !countryHasM0 ? baseValue : null,
                  average_sales_price: averageSalesSolarValue,
                }}
                currency={currency}
              />
            </div>

            {solarDiff && (
              <div className="mt-3">
                <DataWidget
                  printable={printable}
                  title="sales_management.valo_spot_vs_m0_solar"
                  value={solarDiff}
                  unit={`${currency}/MWh`}
                />
              </div>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
});

export default MarketData;
