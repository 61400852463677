import React from 'react';
import { getDescription } from '@components/ui/table/cell/Event';
import { useUser } from '@context/User.context';
import { CellContext } from '@tanstack/react-table';
import { EventIcon } from '@utils/getTypeMeterIcons';
import { Event } from '@utils/types/event';
import styles from './comment-cell.module.scss';

export const CommentCell = ({ getValue }: CellContext<Event, unknown>) => {
  const { locale } = useUser();
  const value = getValue<Event['type']>();

  return (
    <div className={styles['comment-cell']}>
      <EventIcon className="mr-2" type={value} hideTooltip />
      {getDescription(value, locale)}
    </div>
  );
};
