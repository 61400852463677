import React, { useMemo, useState } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import {
  useReactTable,
  type ColumnDef,
  getSortedRowModel,
  getPaginationRowModel,
  getCoreRowModel,
  getFilteredRowModel,
  TableState,
} from '@tanstack/react-table';
import Book from '@utils/types/book';
import type { EventType } from '@utils/types/event';
import Installation from '@utils/types/installation';
import type { UserNotificationsSettings } from '@utils/types/userNotificationsSettings';
import type { NotificationsTableFilters } from './types/notificationsFilters';

export const NotificationsTable = ({
  rows,
  columns,
  isLoading,
  filters,
}: {
  columns: ColumnDef<NotificationsTableRow>[];
  rows: NotificationsTableRow[];
  isLoading: boolean;
  filters: NotificationsTableFilters;
}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const state = useMemo<Partial<TableState>>(() => {
    return {
      pagination: { pageIndex, pageSize },
      columnFilters: Object.entries(filters).map(([id, value]) => ({ id, value })),
    };
  }, [filters, pageIndex, pageSize]);

  const table = useReactTable({
    columns,
    data: rows,
    state,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const gotoPage = (pageIndex: number) => {
    setPagination((pagination) => ({ ...pagination, pageIndex }));
  };

  const setPageSize = (pageSize: number) => {
    setPagination((pagination) => ({ ...pagination, pageSize }));
  };

  return (
    <div className="p-3">
      <Table hasActions className="mt-3">
        <TableHead table={table} />
        <TableBody table={table} isLoading={isLoading} />
      </Table>
      {rows.length > 10 && (
        <TableActions>
          <TablePageSizeSelect pageSize={pageSize} setPageSize={setPageSize} totalEntries={rows.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={gotoPage} />
        </TableActions>
      )}
    </div>
  );
};

export type NotificationsTableRow = {
  id: number;
  email: string;
  event_type: EventType[];
  eventable?: Book & Installation;
  originalSettings: UserNotificationsSettings;
};
