import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from '@GDM/Modal';
import { useInvoiceHistoryMergedWithVolumes } from '@hooks/requests/meter-invoices/useInvoiceHistoryMergedWithVolumes';
import useTranslation from '@hooks/useTranslation';
import type { Contract } from '@utils/types/contract';
import { MeterInvoice, MeterInvoiceSource } from '@utils/types/meter-invoice';
import styles from './invoices.module.scss';
import { getVisibilityState } from './InvoicesTable';
import { InvoiceTableProps } from './InvoicesTable/InvoicesTable';

const HistoryModal = ({
  clickedInvoice,
  invoiceType,
  Table,
  isOpen,
  setIsOpen,
  direction,
}: {
  clickedInvoice: MeterInvoice | null;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  invoiceType: MeterInvoiceSource;
  Table: (props: InvoiceTableProps) => JSX.Element;
  direction: Contract['direction'] | 'all';
}) => {
  const { data: invoiceHistory, loading } = useInvoiceHistoryMergedWithVolumes(clickedInvoice);
  const toggle = () => setIsOpen((s) => !s);
  const { t } = useTranslation();

  if (!clickedInvoice) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      hideCancelButton
      header={`${clickedInvoice.installation_name} ${t('sales_management.invoice_history')}`}
      scrollable
    >
      <Table
        wrapperClassName={styles['history-modal']}
        invoices={invoiceHistory ?? []}
        loading={loading}
        invoiceType={invoiceType}
        columnVisibility={getVisibilityState(true)} // By default show all columns
        isModal
        direction={direction}
      />
    </Modal>
  );
};

export default HistoryModal;
