import React, { useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { useRevenueReportingContext } from '@pages/Reporting/utils/hooks/useRevenueReportingContext';
import { getPaginationRowModel, getSortedRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { isANumber } from '@utils/isANumber';
import { EnergyType } from '@utils/types/common-types';
import getReportsTableColumn from './getReportsTableColumn';

export type InstallationRevenue = {
  name: string;
  energyType?: EnergyType;
  production: number | null;
  monthly_production: Array<{ production: number; date: string }>;
  bp: number | null;
  total_production: number | null;
  revenues: number | null;
  unit_price: number | null;
  ytd_revenues: number | null;
};

const ReportsTable = ({
  disablePadding,
  printable,
  showAll,
}: {
  disablePadding?: boolean;
  printable?: boolean;
  showAll?: boolean;
}) => {
  const { split, loading, currency } = useRevenueReportingContext();
  const { locale } = useUser();
  const columns = useMemo(() => getReportsTableColumn(locale, currency), [locale, currency]);

  const data: InstallationRevenue[] = useMemo(
    () =>
      split
        ? split.map((s) => {
            return {
              name: s.installationName,
              energyType: s.installationEnergy,
              monthly_production: s.data.ytdProduction,
              production: s.data.total_production?.value,
              bp: s.data.business_plan_revenue?.value,
              total_production: s.data.total_cumulative_production?.value,
              revenues: s.data.total_revenue?.value,
              unit_price: isANumber(s.data.total_unit_price?.value) ? s.data.total_unit_price?.value * 1000 : null,
              ytd_revenues: s.data.total_cumulative_revenue?.value,
            };
          })
        : [],
    [split],
  );

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: showAll ? data.length : 50 });

  const table = useReactTable({
    columns,
    data,
    state: { pagination },
    sortDescFirst: false,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody table={table} isLoading={loading} data-cy="site-by-site-table" />
      </Table>

      {!disablePadding && !printable && data.length > pageSize && (
        <TableActions>
          <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={data.length} />
          <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
        </TableActions>
      )}
    </>
  );
};

export default ReportsTable;
