import React, { useContext, useEffect, useMemo } from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import { Button } from '@GDM/Button';
import { Icon } from '@GDM/Icon';
import useDownloadPdfInvoices from '@hooks/requests/useDownloadPdfInvoices';
import useTranslation from '@hooks/useTranslation';
import { MeterInvoiceSource } from '@utils/types/meter-invoice';
import classNames from 'classnames';
import invoicesContext from 'pages/sales-management/invoicing/invoicesContext';
import styles from '../actions.module.scss';

type DownloadPdfButtonProps = {
  invoiceType: MeterInvoiceSource;
  onError: (error: string | null) => void;
  textButton?: boolean;
};

const DownloadPdfButton = ({ invoiceType, onError, textButton }: DownloadPdfButtonProps) => {
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { filteredInvoices } = useContext(invoicesContext);
  const selectedInvoices = useMemo(
    () => filteredInvoices?.filter((invoice) => selectedInvoicesUuids.includes(invoice.uuid)),
    [filteredInvoices, selectedInvoicesUuids],
  );
  const { t } = useTranslation();
  const {
    handleDownloadPDF,
    loading: pdfLoading,
    error: pdfError,
    loaded: pdfLoaded,
  } = useDownloadPdfInvoices(selectedInvoicesUuids, invoiceType === 'capa');

  const contractAreDummies = selectedInvoices?.some((i) => i.contract_dummy);
  const disabled = selectedInvoicesUuids.length === 0 || pdfLoading || contractAreDummies;

  /**
   * This hook handle syncing loading and error states to parent.
   */
  useEffect(() => {
    if (pdfLoaded && !pdfError) {
      onError(null);
    } else if (pdfError) {
      onError(t(pdfError.code || 'sales_management.invoice_download_error'));
    }
  }, [pdfLoaded, pdfError, onError, t]);

  if (textButton) {
    return (
      <button
        className={styles['menu-element']}
        disabled={disabled}
        onClick={() => handleDownloadPDF()}
        data-cy="download-pdf-button"
      >
        {pdfLoading ? <Icon name="Loader" size={14} className="rotate" /> : 'PDF'}
      </button>
    );
  }

  return (
    <Button
      tooltip="sales_management.invoice_tooltip_download_invoice"
      disabled={disabled}
      onClick={() => handleDownloadPDF()}
      variant="primary-2"
      className={classNames(styles['button'], styles['button-icon'])}
      data-cy="download-pdf-button"
    >
      {pdfLoading ? <Icon name="Loader" size={14} className="rotate" /> : <Icon name="Download" size={14} />}
    </Button>
  );
};

export default DownloadPdfButton;
