import React from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { useDynamicOptions } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Contract } from '@utils/types/contract';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractsFiltersType } from './ContractFilters';

const getOptions = (contracts: Contract[]) => {
  const allContractNb = contracts.map((contract) => contract.contract_nb).filter((contract_nb) => contract_nb);
  const uniqueContractNb = [...new Set(allContractNb)];
  const options: Option<string>[] = uniqueContractNb.map((contractNb) => ({ label: contractNb, value: contractNb }));

  return options;
};

export const ContractNbFilter = () => {
  const form = useFormContext<ContractsFiltersType>();
  const { filterContracts, contracts, allContracts } = useConsumeContracts();

  const options = useDynamicOptions(getOptions, 'contract_nb', contracts, allContracts);

  return (
    <Controller
      name="contract_nb"
      control={form.control}
      render={({ field }) => (
        <Select
          isMulti
          inline
          placeholder="common.filters.contract_nb"
          classNamePrefix="contracts-filter-contract_nb"
          options={options}
          selectedOptions={field.value}
          onChange={(values) => {
            const contract_nb = values?.map((value) => value.value) ?? [];

            filterContracts({ ...form.getValues(), contract_nb, last_selected_filter: 'contract_nb' });
            field.onChange(contract_nb);
            form.setValue('last_selected_filter', contract_nb.length > 0 ? 'contract_nb' : null);
          }}
        />
      )}
    />
  );
};
