import { MeterInvoice } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';

export const computeBillablePowerAsPercentage = (invoice: MeterInvoice): number | null => {
  const {
    contract_p_max: nominalPower,
    start_date: startDate,
    end_date: endDate,
    volume_production: billablePower,
  } = invoice;

  const totalHours = Math.abs(
    dayjs(startDate).startOf('day').diff(dayjs(endDate).add(1, 'day').startOf('day'), 'hours'),
  );

  if (!nominalPower || !billablePower || !totalHours) return null;

  return (billablePower / (nominalPower * totalHours)) * 100;
};
