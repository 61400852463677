import { useState } from 'react';
import { ContractForm } from '@utils/types/contract';
import { UseFieldArrayReturn } from 'react-hook-form';

export const useSubPeriodsToggle = ({
  subPeriods,
  readOnly,
}: {
  readOnly: boolean;
  subPeriods: UseFieldArrayReturn<ContractForm, 'contract_sub_periods_attributes', 'field_internal_id'>;
}) => {
  const [collapsedIds, setCollapsedIds] = useState<Set<string>>(() => {
    if (subPeriods.fields.length > 5 || readOnly) return new Set(subPeriods.fields.map((p) => p.field_internal_id));

    return new Set();
  });

  const collapseAll = (): void => {
    setCollapsedIds(new Set(subPeriods.fields.map((p) => p.field_internal_id)));
  };

  const unCollapseAll = (): void => {
    setCollapsedIds(new Set());
  };

  const toggleSubPeriod = (id: string): void => {
    setCollapsedIds((currentSet) => {
      const isCollapsed = currentSet.has(id);
      if (isCollapsed) {
        currentSet.delete(id);

        return new Set(currentSet);
      }

      return new Set(currentSet.add(id));
    });
  };

  return { collapsedIds, collapseAll, toggleSubPeriod, unCollapseAll };
};
