import React, { useState } from 'react';
import { useUser } from '@context/User.context';
import { DatePicker } from '@GDM/DatePicker';
import { Spinner } from '@GDM/Spinner';
import { useM0Data } from '@hooks/requests/m0';
import dayjs from 'dayjs';
import { useProjectContext } from '../../utils/project.context';
import { CapturePriceCard } from './Cards/CapturePriceCard';
import { M0Card } from './Cards/M0Card';
import { CsvFileInput } from './CsvFileInput';
import { M0Plot } from './M0Plot';
import { M0Table } from './M0Table';
import styles from './realised.module.scss';

export const Realised = () => {
  const { geolocation } = useUser();
  const project = useProjectContext();
  const [startDate, setStartDate] = useState<Date | null>(dayjs().startOf('month').subtract(13, 'month').toDate());
  const [endDate, setEndDate] = useState<Date | null>(dayjs().startOf('month').subtract(1, 'month').toDate());
  const { isPending, data } = useM0Data({
    startDate,
    endDate,
    source: 'installation',
    identifier: project?.installation?.name || null,
    energy: null,
  });
  const m0Aggregated = data?.m0Aggregated || {};
  const totalRealisedM0 = m0Aggregated?.['TOTAL']?.m0_deviation;
  const localCapturePrice = m0Aggregated?.['TOTAL']?.m0;
  const nationalCapturePrice = m0Aggregated?.['TOTAL']?.capture_price;

  const showTablePlot = !!m0Aggregated;
  const isGermany = geolocation[0] === 'DE';
  const uuid = project?.simulation?.uuid ?? project?.book?.uuid ?? '';

  if (!project?.installation?.has_meter) {
    return (
      <div>
        <CsvFileInput simulationRef={uuid} />
      </div>
    );
  }

  return (
    <div>
      <DatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        picks="months"
        onChange={(dates) => {
          setStartDate(dates[0]);
          setEndDate(dates[1]);
        }}
      />

      {isPending && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}

      {!isPending && showTablePlot && (
        <>
          <div className={styles['cards-container']}>
            <M0Card totalRealisedM0={totalRealisedM0} totalSimulatedM0={project?.result?.deviation?.q50} />
            <CapturePriceCard localCapturePrice={localCapturePrice} nationalCapturePrice={nationalCapturePrice} />
          </div>
          <M0Plot m0Aggregated={m0Aggregated} />
          <M0Table m0Aggregated={m0Aggregated} isGermany={isGermany} />
        </>
      )}
    </div>
  );
};
