import React from 'react';
import { Badge } from '@GDM/Badge';
import { Icon } from '@GDM/Icon';
import { Contract } from '@utils/types/contract';
import dayjs from 'dayjs';
import styles from './page-subtitle.module.scss';

export const PageSubtitle = ({
  id,
  status,
  updated_at,
  contract_nb,
}: Partial<Pick<Contract, 'status' | 'updated_at' | 'contract_nb' | 'id'>>) => {
  if (!id) return null;

  return (
    <>
      {status === 'draft' && <Badge variant="danger" label="common.draft" className="mr-2" />}
      <span className={styles['contract-nb']}>{contract_nb}</span>
      {updated_at && (
        <span className={styles['updated-at']}>
          <Icon name="Edit2" size={12} title="common.last_modification_on" className={styles.icon} />
          {dayjs(updated_at).format('YYYY-MM-DD HH:mm')}
        </span>
      )}
    </>
  );
};
