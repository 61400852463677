import React, { useMemo, useState } from 'react';
import { useInvoicesByType } from '@hooks/requests/meter-invoices/useInvoicesByType';
import { MeterInvoice } from '@utils/types/meter-invoice';
import dayjs from 'dayjs';
import { Actions } from './Actions';
import { HistoryModal } from './HistoryModal';
import { TabId } from './invoices.types';
import { MonitoringInvoicesFilters } from './MonitoringInvoicesFilters';
import { MonitoringInvoicesTable } from './MonitoringInvoicesTable';
import { monitoringInvoicesFiltersContext } from './utils/monitoringInvoicesFiltersContext';
import { useFilteredInvoices } from './utils/useFilteredInvoices';

export const TabContent = ({ type }: { type: TabId }) => {
  const [startDate, setStartDate] = useState<Date>(dayjs().startOf('month').subtract(1, 'month').toDate());
  const [endDate, setEndDate] = useState<Date | null>(dayjs().startOf('month').toDate());
  const req = useInvoicesByType(type, 'all', startDate, endDate);
  const allInvoices = useMemo(() => req.data ?? [], [req.data]);
  const [selectedInvoice, setSelectedInvoice] = useState<MeterInvoice | null>(null);
  const { filters, setFilters, filteredInvoices, onFilterChange } = useFilteredInvoices(allInvoices);
  const [isOpen, setIsOpen] = useState(false);

  const monitoringInvoicesFiltersContextValue = useMemo(() => ({ filters, setFilters }), [filters, setFilters]);

  const toggle = () => setIsOpen(!isOpen);

  const handleOpenModal = (invoicing: MeterInvoice) => {
    setSelectedInvoice(invoicing);
    toggle();
  };

  return (
    <monitoringInvoicesFiltersContext.Provider value={monitoringInvoicesFiltersContextValue}>
      <HistoryModal invoicing={selectedInvoice} isOpen={isOpen} toggle={toggle} Table={MonitoringInvoicesTable} />

      <div className="my-3">
        <MonitoringInvoicesFilters
          filters={filters}
          setFilters={onFilterChange}
          startDate={startDate}
          setStartDate={(date) => {
            setStartDate(dayjs(date).toDate());
          }}
          endDate={type === 'index' ? endDate : null}
          setEndDate={type === 'index' ? setEndDate : undefined}
          type={type}
        />

        <Actions filteredInvoices={filteredInvoices} type={type} />
      </div>

      <MonitoringInvoicesTable
        invoicings={filteredInvoices}
        loading={req.isPending}
        type={type}
        onOpenModal={handleOpenModal}
      />
    </monitoringInvoicesFiltersContext.Provider>
  );
};
