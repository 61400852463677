import type { ReqError } from '@utils/fetch';
import fetchWrapper from '@utils/fetch';
import { create_batch_csv_v2_installation_asset_bps_path } from '@utils/routes';
import type { BusinessPlan } from '@utils/types/business-plan';

export const createBatchCSV = async (installationName: string, body: FormData): Promise<BusinessPlan[]> => {
  const url = create_batch_csv_v2_installation_asset_bps_path(installationName);
  const req = await fetchWrapper(url, { method: 'POST', body });

  const data = await req.json();

  if (!req.ok) {
    throw { code: data.code, message: data.message, errors: data.errors } satisfies ReqError;
  }

  return data;
};
