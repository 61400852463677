import React from 'react';
import classNames from 'classnames';
import styles from './spinner.module.scss';

export const Spinner = ({
  className,
  size = 'sm',
  type = 'border',
}: {
  className?: string;
  size?: 'sm' | 'lg';
  type?: 'border' | 'grow';
}) => {
  return (
    <div role="status" className={classNames(styles.spinner, styles[type], styles[`${type}-${size}`], className)}>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
