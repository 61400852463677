import React from 'react';
import { ValueCard } from '@GDM/Card';
import { Colon } from '@GDM/Colon';
import { Col, Row } from '@GDM/layout';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import { useM0Deviation } from '../useM0Deviation';

export const M0Card = () => {
  const { t } = useTranslation();
  const { m0Aggregated, currency } = useM0Deviation();

  const m0TotalRow = m0Aggregated['TOTAL'];
  const m0TotalCapturePrice = m0TotalRow ? m0TotalRow.capture_price : null;
  const m0TotalBaseloadPrice = m0TotalRow ? m0TotalRow.baseload_price : null;
  const capturePricePercent =
    m0TotalRow && m0TotalRow.baseload_price && m0TotalRow.capture_price
      ? (100 * m0TotalRow.capture_price) / m0TotalRow.baseload_price
      : null;

  const m0Total = m0TotalRow ? m0TotalRow.m0 : null;
  const m0TotalDeviation = m0TotalRow ? m0TotalRow.m0_deviation : null;
  const m0TotalDeviationNoProdNegPrice = m0TotalRow ? m0TotalRow.m0_deviation_no_prod_neg_price : null;

  return (
    <Row>
      <Col lg="4">
        <ValueCard
          value={m0TotalDeviation}
          unit={`${currency}/MWh`}
          title="monitoring.installation.m0_deviation"
          className="h-100"
          valueVariant={m0TotalDeviation && m0TotalDeviation < 0 ? 'secondary' : 'primary-1'}
        >
          <div className="mt-1">
            {t('pricing.market_value')}
            <Colon />
            &nbsp;
            <ValueWithUnit value={m0Total} unit={`${currency}/MWh`} />
          </div>
        </ValueCard>
      </Col>
      <Col lg="4">
        <ValueCard
          value={m0TotalDeviationNoProdNegPrice}
          unit={`${currency}/MWh`}
          title="monitoring.installation.m0_deviation_no_prod_neg_price"
          className="h-100"
          valueVariant={
            m0TotalDeviationNoProdNegPrice && m0TotalDeviationNoProdNegPrice < 0 ? 'secondary' : 'primary-1'
          }
        >
          <div className="mt-1">
            {t('pricing.market_value')}
            <Colon />
            &nbsp;
            <ValueWithUnit value={m0Total} unit={`${currency}/MWh`} />
          </div>
        </ValueCard>
      </Col>
      <Col lg="4">
        <ValueCard
          value={capturePricePercent}
          unit="percentage"
          title="monitoring.installation.capture_price_ratio"
          className="h-100"
          valueVariant={capturePricePercent ? (capturePricePercent < 100 ? 'secondary' : 'primary-1') : null}
        >
          <div className="mt-1">
            {t('monitoring.installation.capture_price')}
            <Colon />
            &nbsp;
            <ValueWithUnit value={m0TotalCapturePrice} unit={`${currency}/MWh`} />
          </div>
          <div>
            {t('monitoring.installation.mean_baseload_price')}
            <Colon />
            &nbsp;
            <ValueWithUnit value={m0TotalBaseloadPrice} unit={`${currency}/MWh`} />
          </div>
        </ValueCard>
      </Col>
    </Row>
  );
};
