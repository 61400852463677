import React from 'react';
import { Button } from '@GDM/Button';
import { Collapse } from '@GDM/Collapse';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  title: string;
  secondaryTitle?: string | null;
  toggle: () => void;
};

export const BusinessPlanCollapsible = ({ children, isOpen, title, secondaryTitle, toggle }: Props) => {
  return (
    <div className="my-3">
      <div className="d-flex flex-row justify-content-between">
        <Button variant="link" size="sm" onClick={toggle} icon={isOpen ? 'ChevronDown' : 'ChevronRight'}>
          {title}
        </Button>
        {secondaryTitle && <div>{secondaryTitle}</div>}
      </div>
      <Collapse className="my-1" isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};
