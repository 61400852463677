import React from 'react';
import type { DataProvider as TDataProvider } from '@api/dataProviders';
import ControlledInput from '@components/FormInputs/ControlledInput';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useInternalFormContext } from '@GDM/forms';
import { useGrids } from '@hooks/requests/useGrids';
import { useSelectedGrid } from '@pages/Installations/Installation/context/useSelectedGrid.context';
import { useFormDataProviders } from '@pages/Installations/Installation/hooks/useFormDataProviders';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import type { Option } from '@utils/types/common-types';
import { Controller } from 'react-hook-form';
import { DefaultSubSection } from './DefaultSubSection';

export const DataProvider = () => {
  const { watch, control, setValue } = useInstallationForm();
  const { country } = useInternalFormContext();
  const grids = useGrids(country);
  const { selectedGrid } = useSelectedGrid();

  const dataProviders = useFormDataProviders(watch);

  const options = [
    { value: null, label: 'common.other' },
    ...dataProviders.map(dataProviderToOption).sort(sortOptionsByLabelAsc),
  ];

  return (
    <Controller
      control={control}
      name="via_data_provider"
      render={({ field }) => (
        <DefaultSubSection
          title="admin.installations.via_data_provider"
          isOpen={field.value}
          name="data_provider"
          onChange={(isOpen) => {
            field.onChange(isOpen);
            if (isOpen) {
              if (dataProviders.length === 1) {
                setValue('data_provider_id', dataProviders[0].id);
              } else if (selectedGrid?.data_providers?.length === 1) {
                setValue('data_provider_id', selectedGrid.data_providers[0].id);
              } else {
                setValue('data_provider_id', null);
              }
            }
          }}
        >
          <div className="d-flex gap-3">
            <ControlledSelect
              control={control}
              name="data_provider_id"
              options={options}
              isLoading={grids.isLoading}
              label="admin.installations.data_provider"
              size="lg"
              classNamePrefix="data-provider-select"
              disabled={options.length < 2}
            />

            <ControlledInput
              rules={{ required: true }}
              control={control}
              name="data_provider_number"
              data-cy="data_provider_number"
              label="admin.installations.meter_number"
              size="lg"
              flexFull
            />
          </div>
        </DefaultSubSection>
      )}
    />
  );
};

const dataProviderToOption = (dataProvider: TDataProvider): Option<TDataProvider['id']> => ({
  value: dataProvider.id,
  label: dataProvider.name,
});
