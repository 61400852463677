import React, { useEffect, useMemo, useState } from 'react';
import { useRequest } from '@hooks/useRequest';
import { gather_meter_data_v2_dashboardviewall_index_path } from '@utils/routes';
import { Locale } from '@utils/types/common-types';
import { backendDataSerializer } from './backend-data.serializer';
import { usePortfolioDefaultParams } from './hooks/usePortfolioDefaultParams';
import { PortFolioContextType, portfolioContext } from './portfolio.context';
import { DashboardViewAllData, DataType, PortFolioDisplayType, RawDashboardViewAllData } from './portfolio.definitions';

export const PortfolioProvider = ({ locale, children }: React.PropsWithChildren<{ locale: Locale }>) => {
  const [errorBanner, setErrorBanner] = useState<string | undefined>();
  const { firstDate, lastDate, defaultTab, showMap, onlyConsumers } = usePortfolioDefaultParams(
    locale,
    errorBanner,
    setErrorBanner,
  );

  const [startDate, setStartDate] = useState<string | undefined>(firstDate.format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string | undefined>(lastDate.format('YYYY-MM-DD'));
  const [portfolio, setPortfolio] = useState<DashboardViewAllData[] | undefined>();
  const [dataType, setDataType] = useState<DataType>(defaultTab);
  const [displayMode, setDisplayMode] = useState<PortFolioDisplayType>(
    showMap && dataType !== 'books' ? 'map' : 'table',
  );

  const {
    data: meterData,
    loading,
    error,
  } = useRequest<{
    data: RawDashboardViewAllData[];
    books: RawDashboardViewAllData[];
    sum_realtime_portfolio: number;
    realtime_ratio: number;
  }>(
    gather_meter_data_v2_dashboardviewall_index_path({
      firstdate: startDate,
      lastdate: endDate,
    }),
    'GET',
  );

  const context = useMemo<PortFolioContextType>(
    () => ({
      data: [
        ...(meterData?.data ? backendDataSerializer(meterData?.data) : []),
        ...(meterData?.books ? backendDataSerializer(meterData?.books) : []),
      ],
      onlyConsumers,
      dataType,
      loading,
      error: error || undefined,
      errorBanner,
      sum_realtime_portfolio: meterData?.sum_realtime_portfolio,
      realtime_ratio: meterData?.realtime_ratio,
      setDataType,
      displayMode,
      setDisplayMode,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      portfolio,
      setPortfolio,
    }),
    [dataType, displayMode, endDate, error, errorBanner, loading, meterData, onlyConsumers, portfolio, startDate],
  );

  useEffect(() => {
    if (meterData && defaultTab) {
      setPortfolio(backendDataSerializer(defaultTab === 'books' ? meterData?.books : meterData?.data));
    }
  }, [defaultTab, meterData]);

  return <portfolioContext.Provider value={context}>{children}</portfolioContext.Provider>;
};
