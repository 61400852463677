import React, { useMemo } from 'react';
import { createOwner, getOwnerById, updateOwner } from '@api/owners';
import { Alert } from '@GDM/Alert';
import { Form } from '@GDM/forms';
import { getDefaultValues } from '@GDM/forms/utils/getDefaultValues';
import type { RequestErrorBody } from '@hooks/useRequest';
import { useErrorsFromRequest } from '@pages/Installations/Installation/hooks/useErrorsFromRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import { v2_installations_path } from '@utils/routes';
import type MarketPlayer from '@utils/types/market_player';
import type { User } from '@utils/types/user';
import type { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { COMMON_FIELDS } from './constants/common-fields';
import { FIELD_SECTIONS } from './constants/field-sections';
import { steps } from './constants/steps';
import type { OwnerForm as OwnerFormType } from './ownerForm.types';
import { formatOwnerBody } from './utils/formatOwnerBody';

const REDIRECT_TIMEOUT = 2000;

export const OwnerForm = ({ user, id }: { user: User; id?: string }) => {
  const defaultValues = getDefaultValues(COMMON_FIELDS);
  const form = useForm<OwnerFormType>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
  });

  const ownerQuery = useQuery<MarketPlayer, AxiosError<RequestErrorBody>>({
    queryKey: ['owners'],
    queryFn: () => getOwnerById(id),
    enabled: !!id,
  });

  const ownerCreateMutation = useMutation<MarketPlayer, AxiosError<RequestErrorBody>, OwnerFormType>({
    mutationFn: (body) => createOwner(formatOwnerBody(body)),
    onSuccess: () => {
      setTimeout(() => (window.location.href = v2_installations_path({ anchor: 'owners' })), REDIRECT_TIMEOUT);
    },
  });

  const ownerUpdateMutation = useMutation<MarketPlayer, AxiosError<RequestErrorBody>, OwnerFormType>({
    mutationFn: (body) => updateOwner(id || '', formatOwnerBody(body)),
    onSuccess: () => {
      setTimeout(() => (window.location.href = v2_installations_path({ anchor: 'owners' })), REDIRECT_TIMEOUT);
    },
  });

  const values = useMemo(() => {
    if (ownerQuery.data) {
      return {
        ...ownerQuery.data,
        country: ownerQuery.data.country,
      };
    }
  }, [ownerQuery.data]);

  const { formErrors, globalError } = useErrorsFromRequest<OwnerFormType>(
    [ownerQuery.error, ownerCreateMutation.error, ownerUpdateMutation.error],
    user.locale,
  );

  const handleSubmit = (data: OwnerFormType) => {
    if (id) {
      return ownerUpdateMutation.mutate(data);
    }

    return ownerCreateMutation.mutate(data);
  };

  const loading = ownerQuery.isLoading || ownerCreateMutation.isPending || ownerUpdateMutation.isPending;

  return (
    <Form
      user={user}
      isLoading={loading}
      errors={formErrors}
      pageProps={{
        title: 'common.owner',
        subtitle: ownerQuery.data?.short_name ?? '',
        titleIcon: ownerQuery.data?.country,
        user,
        layout: 'no-background',
        isLoading: loading,
        error: ownerQuery.error?.message ?? globalError,
      }}
      form={form}
      commonFields={COMMON_FIELDS}
      fieldSections={FIELD_SECTIONS}
      steps={steps}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      values={values}
      countryKey="country"
      backButtonHref={v2_installations_path({ anchor: 'owners' })}
    >
      {(ownerCreateMutation.isSuccess || ownerUpdateMutation.isSuccess) && (
        <div className="w-100 my-3">
          <Alert variant="success" label="owner.save_success" dismissible />
        </div>
      )}
    </Form>
  );
};
