import { useEffect } from 'react';
import { useSelectedInvoicesContext } from '@context/selectInvoices.context';
import useApproveVolumes, { RequestVolume } from '@hooks/requests/meter-invoice-volumes/useApproveVolumes';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { usePermittedStatus } from './usePermittedStatus';

export const useApproveInvoices = (setErrorMessage: (message: string) => void) => {
  const { approveVolumes, loaded, loading, data, error } = useApproveVolumes();
  const { filteredInvoices, updateInvoices } = useInvoicesContext();
  const { selectedInvoicesUuids } = useSelectedInvoicesContext();
  const { disabled } = usePermittedStatus('approved');

  const handleApproveInvoice = () => {
    const selectedVolumesIdLastIndex: RequestVolume[] = selectedInvoicesUuids.map((uuid) => {
      const last_index = filteredInvoices.find((m) => m.uuid === uuid)?.volume_last_index;

      return { last_index, invoice_uuid: uuid };
    });

    approveVolumes(selectedVolumesIdLastIndex);
  };

  const buttonDisabled = disabled || loading;

  useEffect(() => {
    if (loaded) {
      updateInvoices(data ?? []);
    }
  }, [loaded, data, updateInvoices]);

  useEffect(() => {
    if (error) {
      setErrorMessage('invoice.error.approve_fail');
    }
  }, [setErrorMessage, error]);

  return { buttonDisabled, handleApproveInvoice, loading };
};
