import React from 'react';
import { Modal } from '@GDM/Modal';
import useTranslation from '@hooks/useTranslation';
import Book from '@utils/types/book';

export const DeleteBookModal = ({
  book,
  onCancel,
  onConfirm,
}: {
  book: Book | null;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();

  if (!book) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      header={t('monitoring.books.delete_confirmation', { bookName: book.name })}
      submitAction={onConfirm}
      saveButtonText="common.delete"
      data-cy="delete-book-modal"
      toggle={onCancel}
    >
      <p>{t('monitoring.books.delete_warning')}</p>
    </Modal>
  );
};
