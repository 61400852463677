import React from 'react';
import { Select } from '@GDM/forms';
import { useTurbines } from '@hooks/useTurbines';
import { Option } from '@utils/types/common-types';
import { Controller, useFormContext } from 'react-hook-form';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const TurbineSelect = () => {
  const turbines = useTurbines();
  const { control } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('turbine_id');

  const options: Option[] =
    turbines.data?.map((turbine) => ({
      label: `${turbine.name} ${turbine.manufacturer ? `- ${turbine.manufacturer}` : ''}`.trim(),
      value: turbine.id.toString(),
    })) || [];

  return (
    <Controller
      control={control}
      name="turbine_id"
      rules={rules}
      render={({ field, fieldState }) => (
        <Select
          full
          hasError={!!fieldState.error}
          isLoading={turbines.loading}
          options={options}
          selectedOption={field.value?.toString()}
          onChange={(option) => field.onChange(option?.value)}
          size="lg"
          label="pricing.project.type_turbine"
          menuPlacement="top"
          classNamePrefix="turbine-select"
        />
      )}
    />
  );
};
