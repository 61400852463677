import React from 'react';
import { Input } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../update-modal.module.scss';
import type { UpdateInvoiceForm } from '../update-modal.types';

export const QuantityInput = ({ index }: { index: number }) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<UpdateInvoiceForm>();
  const { revenue_type: revenueType } = watch(`revenues.${index}`);

  return (
    <Controller
      control={control}
      name={`revenues.${index}.quantity`}
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <Input
          className={styles.input}
          suffix="kWh"
          errorMessage={fieldState.error && t('common.is_required_dynamic', { key: t('common.production') })}
          value={field.value ?? ''}
          onChange={(e) => {
            field.onChange(e.target.value);
          }}
          data-cy={`revenues.${revenueType}.quantity`}
        />
      )}
    />
  );
};
