import React from 'react';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import styles from './charts.module.scss';

export const CannibalizationChart = ({
  categories,
  title,
  q50,
  q1090,
  q2575,
  valueSuffix,
}: {
  categories: string[];
  q50: number[][];
  q2575: number[][];
  q1090: number[][];
  valueSuffix: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const chartColor = getComputedStyle(document.documentElement).getPropertyValue('--chart-grey');
  const lineColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-1');

  const options: Highcharts.Options = {
    chart: { zoomType: undefined },
    legend: { enabled: false },
    yAxis: { title: { text: t(title) } },
    xAxis: { categories, tickInterval: 1, crosshair: { width: 1 } },
  };

  const series: Highcharts.Options['series'] = [
    {
      name: t('pricing.project.average'),
      type: 'line',
      data: q50,
      color: lineColor,
      marker: {
        enabled: true,
        symbol: 'circle',
        fillColor: lineColor,
        radius: 4,
      },
      tooltip: { valueSuffix },
      zIndex: 1,
    },
    {
      name: t('pricing.project.q2575'),
      data: q2575,
      type: 'arearange',
      linkedTo: ':previous',
      color: chartColor,
      fillOpacity: 0.6,
      lineWidth: 0,
      states: { hover: { lineWidth: 0 } },
      tooltip: { valueSuffix },
      zIndex: 0,
    },
    {
      name: t('pricing.project.q1090'),
      data: q1090,
      type: 'arearange',
      linkedTo: ':previous',
      color: chartColor,
      fillOpacity: 0.3,
      lineWidth: 0,
      states: { hover: { lineWidth: 0 } },
      tooltip: { valueSuffix },
      zIndex: 0,
    },
  ];

  return (
    <div className={styles.chart}>
      <Chart chartType="scatter" series={series} options={options} />
    </div>
  );
};
