import { UserCtxType } from '@context/User.context';
import { tString } from '@hooks/useTranslation';
import { Contract } from '@utils/types/contract';

export const getLatestUnitPrice = (contract: Contract, user: UserCtxType): { value: number | string; unit: string } => {
  const { locale } = user;
  const t = tString(locale);

  if (contract.contract_sub_periods.length > 1)
    return {
      value: t('sales_management.price.multi'),
      unit: '',
    };
  if (contract.contract_sub_periods.length === 0)
    return {
      value: t('sales_management.price.no_price'),
      unit: '',
    };

  const { latest_unit_price, type } = contract;
  const subPeriod = contract.contract_sub_periods[0];
  const { price_unit } = subPeriod;

  if (!latest_unit_price)
    return {
      value: '',
      unit: '',
    };

  const latestUnitPrice =
    type != 'ContractCapa' ? +(latest_unit_price * 1000).toFixed(2) : +latest_unit_price.toFixed(0);

  return { value: latestUnitPrice, unit: price_unit || '' };
};
