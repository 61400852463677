import React, { ButtonHTMLAttributes } from 'react';
import { Icon, IconNames } from '@GDM/Icon';
import { Spinner } from '@GDM/Spinner';
import { Tooltip } from '@GDM/Tooltip/Tooltip';
import { type TooltipProps } from '@GDM/Tooltip/TooltipRenderer';
import useTranslation from '@hooks/useTranslation';
import classNames from 'classnames';
import compact from 'lodash/compact';
import styles, { btn } from './button.module.scss';

export type ButtonProps = {
  className?: string;
  floating?: boolean;
  full?: boolean;
  href?: string;
  icon?: IconNames;
  tooltip?: string;
  isLoading?: boolean;
  name?: string;
  size?: 'sm' | 'xs' | 'xxs';
  target?: HTMLAnchorElement['target'];
  text?: string | null;
  value?: string | number;
  variant?: 'link' | 'link-secondary' | 'sub-button' | 'primary-2' | 'primary-1' | 'secondary' | 'outline';
  'data-place'?: TooltipProps['place'];
  'data-cy'?: string;
  noClip?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLAnchorElement>;

export const Button = ({
  children,
  className,
  floating,
  full,
  href,
  icon,
  tooltip,
  isLoading,
  name,
  onClick,
  size,
  target,
  text,
  type,
  value,
  variant,
  noClip,
  ...props
}: ButtonProps) => {
  const { t } = useTranslation();
  const hasNoChildren = (!children && !text) || (Array.isArray(children) && compact(children).length === 0);
  const isFloating = floating && hasNoChildren;
  const styleSize = isFloating ? 'xs' : size;
  const classList = classNames(btn, className, styleSize && styles[styleSize], variant && styles[variant], {
    [styles.full]: full,
    [styles['is-floating']]: isFloating,
    [styles['icon-button']]: hasNoChildren,
    [styles['is-loading']]: isLoading,
  });
  const iconSize = isFloating ? 18 : size === 'xs' ? 12 : 14;

  if (floating && !hasNoChildren)
    console.warn('You added `floating` as prop to a non-icon button. This is incompatible.'); // eslint-disable-line no-console

  if (floating && size && size !== 'xs')
    console.warn(`Floating buttons can only be 'xs' and you passed '${size}'. The 'size' prop will be ignored.`); // eslint-disable-line no-console

  const updatedProps: ButtonHTMLAttributes<HTMLButtonElement> &
    ButtonHTMLAttributes<HTMLAnchorElement> & { ['data-icon-name']?: string } = {
    ...props,
    className: classList,
    name,
    type: type ?? 'button',
    role: 'button',
    title: props.title ? t(props.title) : undefined,
    value,
    'data-icon-name': icon,
    ...{ onClick: href ? undefined : onClick },
    ...{ href: href ?? undefined },
    ...{ target: href && target ? target : undefined },
  };

  const Element: 'a' | 'button' = href ? 'a' : 'button';

  return (
    <Tooltip tooltip={tooltip}>
      <Element {...updatedProps}>
        {icon && (
          <Icon
            name={icon}
            size={iconSize}
            className={classNames({ 'mr-2': !hasNoChildren, [styles['icon-only']]: hasNoChildren })}
          />
        )}
        <div className={classNames(styles.content, noClip && styles['no-clip'])} title={text ? t(text) : undefined}>
          {text ? t(text) : children}
        </div>
        {isLoading && <Spinner className={classNames(styles.spinner, 'ml-2')} />}
      </Element>
    </Tooltip>
  );
};
