import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateModalForm } from '../../CreateModal/types';

export const EndDateInput = () => {
  const { control, watch } = useFormContext<CreateModalForm>();
  const startDate = watch('dates.start_date');

  return (
    <Controller
      control={control}
      name="dates.end_date"
      rules={{ validate: (value) => Boolean(startDate && value && startDate?.valueOf() < value?.valueOf()) }}
      render={({ field: { onChange, value }, fieldState }) => (
        <DatePicker
          onChange={onChange}
          selected={value}
          hasError={!!fieldState.error}
          label="common.end"
          picks="datetime"
          minDate={startDate ?? undefined}
        />
      )}
    />
  );
};
