import React, { useEffect, useState } from 'react';
import { Textarea } from '@GDM/forms/Textarea';
import { Col } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';

type SiretsInputProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  disabled: boolean;
};

const SiretsInput = ({ onChange, value, disabled }: SiretsInputProps) => {
  const { t } = useTranslation();
  const [rows, setRow] = useState(1);
  const _value = value.split(',').join('\n');

  useEffect(() => {
    const numberLines = value.split(',').length;

    if (numberLines > 1) {
      setRow(Math.min(5, numberLines));
    }
  }, [value]);

  return (
    <Col md={12}>
      <div className="mt-3">
        <Textarea
          full
          label="SIRETS/SIRENS"
          id="mandate-sirets"
          value={_value}
          onChange={onChange}
          rows={rows}
          disabled={disabled}
          data-cy="sirets-input"
        />

        <em className="font-italic mb-3 text-small">{t('common.separate_comma')}</em>
      </div>
    </Col>
  );
};

export default SiretsInput;
