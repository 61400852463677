import React from 'react';
import { AltContent } from '@GDM/AltContent';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';
import { BookCharacteristics } from './BookCharacteristics';
import { InstallationCharacteristics } from './InstallationCharacteristics';
import { MeterCharacteristics } from './MeterCharacteristics';

export const Characteristics = () => {
  const { meter, book, installation } = useDashboardMeter();

  if (!meter && !book && !installation) return <AltContent />;

  return (
    <>
      {!!book && <BookCharacteristics book={book} />}
      {!!meter && <MeterCharacteristics meter={meter} />}
      {!!installation && <InstallationCharacteristics installation={installation} />}
    </>
  );
};
