import React, { useEffect, useRef, useState } from 'react';
import { DropDownMenu } from '@GDM/DropDownMenu';
import styles from './user-menu-dropdown.module.scss';

type UserMenuDropDownProps = {
  title: React.ReactNode;
  panel: React.ReactNode;
};

export const UserMenuDropDown = ({ title, panel }: UserMenuDropDownProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Close the panel when clicking outside
   */
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsPanelOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className={styles['item']}>
      <div className={styles['item-title']} onClick={() => setIsPanelOpen((prevOpen) => !prevOpen)}>
        {title}
      </div>

      <DropDownMenu isOpen={isPanelOpen} className={styles['item-panel']}>
        {panel}
      </DropDownMenu>
    </div>
  );
};
