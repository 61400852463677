import axiosInstance from '@utils/axiosInstance';
import fetchWrapper, { type ReqError } from '@utils/fetch';
import { formatSystemDate } from '@utils/formatters';
import {
  by_type_index_v2_invoices_path,
  by_type_loadcurve_v2_invoices_path,
  by_type_rec_v2_invoices_path,
  by_type_yearly_regul_v2_invoices_path,
  update_status_v2_meter_invoicing_index_path,
  v2_meter_invoicings_capa_index_path,
} from '@utils/routes';
import type { Contract } from '@utils/types/contract';
import type { MeterInvoice, MeterInvoiceEvent, MeterInvoiceSource } from '@utils/types/meter-invoice';

export const updateStatus = async (event: MeterInvoiceEvent, ids: MeterInvoice['uuid'][]) => {
  const body = { invoices: { ids }, event };

  return (await axiosInstance(false).patch<MeterInvoice[]>(update_status_v2_meter_invoicing_index_path(), body)).data;
};

export const fetchInvoicesByType = async (
  invoiceType: MeterInvoiceSource,
  direction: Contract['direction'] | 'all',
  startDate: Date,
  endDate?: Date | null,
): Promise<MeterInvoice[]> => {
  const getUrl = invoicesUrl[invoiceType];

  const req = await fetchWrapper(
    getUrl({
      params: {
        start_date: startDate ? formatSystemDate(startDate) : undefined,
        end_date: endDate ? formatSystemDate(endDate) : undefined,
        direction,
      },
    }),
  );

  const data = await req.json();

  if (!req.ok) {
    throw { code: data.code, message: data.message, errors: data.errors } satisfies ReqError;
  }

  return data;
};

const invoicesUrl: Record<MeterInvoiceSource, (opts?: { params?: Record<string, string | undefined> }) => string> = {
  capa: v2_meter_invoicings_capa_index_path,
  load_curve: by_type_loadcurve_v2_invoices_path,
  rec: by_type_rec_v2_invoices_path,
  yearly_regul: by_type_yearly_regul_v2_invoices_path,
  index: by_type_index_v2_invoices_path,
};
