import React, { useMemo } from 'react';
import { Chart } from '@GDM/Chart';
import { Dafr } from '@hooks/requests/useDafr';
import useTranslation from '@hooks/useTranslation';
import Highcharts from 'highcharts';
import round from 'lodash/round';

export const DafrChart = ({ dafr }: { dafr: Dafr[] }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return dafr ? dafr.map((d) => ({ y: d.production, name: d.year })) : [];
  }, [dafr]);

  const rollingAverage = useMemo(() => {
    if (!dafr) return [];

    return dafr.map((d, index) => {
      if (index === 0) {
        return d.production;
      }

      const dafrValue = (d.production + dafr[index - 1].production) / 2;

      return round(dafrValue, 2);
    });
  }, [dafr]);

  const options: Highcharts.Options = {
    xAxis: { categories: dafr.map((d) => d.year.toString()) },
    yAxis: {
      title: {
        text: t('common.hours'),
      },
      plotLines: [
        {
          color: 'var(--chart-red)',
          value: 3600,
          dashStyle: 'ShortDash',
          width: 2,
          zIndex: 5,
          label: { text: '3600', style: { color: 'var(--chart-red)' } },
        },
        {
          color: 'var(--chart-orange)',
          value: 2800,
          dashStyle: 'ShortDash',
          width: 2,
          zIndex: 5,
          label: { text: '2800', style: { color: 'var(--chart-orange)' } },
        },
        {
          color: 'var(--primary-1)',
          value: 2400,
          dashStyle: 'ShortDash',
          width: 2,
          zIndex: 5,
          label: { text: '2400', style: { color: 'var(--primary-1)' } },
        },
      ],
    },
  };

  const series: Highcharts.Options['series'] = [
    { type: 'column', name: 'Dafr', data: columns },
    { type: 'spline', name: t('common.rolling_average'), data: rollingAverage },
  ];

  return <Chart options={options} series={series} />;
};
