import React from 'react';
import { Icon } from '@GDM/Icon';
import { NumberCell } from '@GDM/Table';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';
import styles from './total-amount-cell.module.scss';

export const TotalAmountCell = ({ row: { original } }: CellContext<MeterInvoice, unknown>) => {
  return (
    <div className={styles['total-amount-cell']}>
      {original.data_source !== 'streem' && (
        <Icon
          name="Flag"
          size={16}
          className="mr-3"
          title="monitoring.invoicing.manually_updated_tooltip"
          data-cy="invoice-manually-changed"
        />
      )}
      <NumberCell
        value={original.total_amount}
        numberOptions={{ maximumFractionDigits: 2, minimumFractionDigits: 2 }}
        unit={original.currency}
      />
    </div>
  );
};
