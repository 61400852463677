import React from 'react';
import { Button } from '@GDM/Button';
import { useApproveInvoices } from '@hooks/requests/invoices/useApproveInvoices';

const ApproveInvoicesButton = ({ setErrorMessage }: { setErrorMessage: (message: string) => void }) => {
  const { buttonDisabled, handleApproveInvoice, loading } = useApproveInvoices(setErrorMessage);

  return (
    <Button
      data-cy="approve-billable-production"
      tooltip="monitoring.invoicing.approve"
      disabled={buttonDisabled}
      onClick={handleApproveInvoice}
      variant="primary-2"
      size="xxs"
      icon={loading ? 'Loader' : 'CheckCircle'}
    />
  );
};

export default ApproveInvoicesButton;
