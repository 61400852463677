import React from 'react';
import { RadioButtons, RadioButtonsProps, RadioOption } from '@GDM/forms';
import { FieldValues, Path, PathValue, useController, UseControllerProps } from 'react-hook-form';

export type ControlledRadioButtonsProps<T extends FieldValues> = Omit<
  RadioButtonsProps<PathValue<T, Path<T>>>,
  'onChange' | 'options' | 'selected'
> &
  UseControllerProps<T> & {
    options: RadioOption<PathValue<T, Path<T>>>[];
    placeholder?: string;
    afterChange?: (optionValue?: PathValue<T, Path<T>>) => void;
  };

export default function ControlledRadioButtons<T extends FieldValues>({
  name,
  control,
  options,
  rules,
  afterChange,
  shouldUnregister,
  defaultValue,
  ...radioButtonsProps
}: ControlledRadioButtonsProps<T>) {
  const { field, fieldState } = useController({
    name,
    control,
    shouldUnregister,
    rules,
    defaultValue,
    disabled: radioButtonsProps.disabled,
  });

  return (
    <RadioButtons
      {...radioButtonsProps}
      onChange={(e) => {
        field.onChange(e);
        if (afterChange) afterChange(e);
      }}
      options={options}
      selected={field.value}
      errorMessage={fieldState.error?.message}
    />
  );
}
