import React from 'react';
import { Input } from '@GDM/forms';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { getSymbol } from '@utils/currency/getSymbol';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useNationalPricesContext } from '../context';

export const M0AggregInput = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t, tLocalized } = useTranslation();
  const { currency } = useNationalPricesContext();
  const currencySymbol = getSymbol(currency);

  return (
    <Controller
      name="m0_aggreg"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <>
          <Input
            {...field}
            label={tLocalized('sales_management.national_prices.modal.m0_estimated_by_offtaker')}
            value={field.value?.toString() || ''}
            hasError={errors?.m0_aggreg?.type === 'required'}
            className="mt-3"
            type="number"
            data-cy="m0-value"
            suffix={`${currencySymbol}/MWh`}
          />

          {errors?.m0_aggreg?.type === 'required' && (
            <Text type="danger">
              {t('common.is_required_dynamic', {
                key: tLocalized('sales_management.national_prices.modal.m0_estimated_by_offtaker'),
              })}
            </Text>
          )}
        </>
      )}
    />
  );
};
