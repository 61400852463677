import get from 'lodash/get';
import { DeepPartial, FieldValues } from 'react-hook-form';
import { FormField } from '../form.types';

export const isFieldAvalaibleForFieldValues = (field: FormField<FieldValues>, values: DeepPartial<FieldValues>) => {
  if (field.onlyForFieldValues && field.onlyForFieldValues.length > 0) {
    return field.onlyForFieldValues.every((condition) => {
      const value = get(values, condition.field);

      return value === condition.value;
    });
  }

  return true;
};
