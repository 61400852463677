import { RadioOption } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { useCurrency } from '@utils/string/useCurrency';
import { ReportMetric } from '../types';

export const useUniqueMetricRadioOptions = (): RadioOption<ReportMetric>[] => {
  const { t } = useTranslation();
  const currency = useCurrency('symbol');

  return [
    {
      label: `${t('common.revenue')} (k${currency})`,
      value: 'revenue',
    },
    {
      label: `${t('common.production')} (GWh)`,
      value: 'production',
    },
    {
      label: 'common.contract_nb',
      value: 'contract_nb',
    },
    {
      label: `${t('common.price')} (${currency}/MWh)`,
      value: 'unit_price',
    },
    {
      label: `${t('monitoring.installation.revenue.business_plan')} (MWh)`,
      value: 'business_plan',
    },
    {
      label: `${t('common.power')} (MW)`,
      value: 'power',
    },
  ];
};
