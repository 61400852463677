import { isGroupedData } from '../helpers/type-helpers';
import { RevenueContextType, TableData } from '../revenue.types';

export const parseToTableData = (
  revenue: RevenueContextType['revenue'],
  overview: RevenueContextType['overview'],
): TableData[] => {
  let tableData: TableData[] = [];

  if (revenue && revenue.length && isGroupedData(revenue)) {
    tableData = revenue?.map((item) => ({ ...item, subRows: item.sub_groups }));
  }

  const overviewData: TableData[] =
    overview?.reduce((acc, item) => {
      return [...acc, { label: item.field, values: item.data, unit: item.unit, isOverviewRow: true }];
    }, [] as TableData[]) ?? [];

  return [...overviewData, ...tableData];
};
