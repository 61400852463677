import React from 'react';
import { DatePicker } from '@GDM/DatePicker';
import dayjs from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import styles from '../../new-simulation.module.scss';
import { useFieldRules } from '../../utils/getFieldRules';
import { SimulationForm } from '../../utils/simulations.types';

export const StartDateInput = () => {
  const { control, watch } = useFormContext<SimulationForm>();
  const { fieldRules } = useFieldRules();
  const rules = fieldRules('start_date');
  const endDate = watch('end_date');

  return (
    <Controller
      control={control}
      name="start_date"
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={styles['datepicker-container']}>
          <DatePicker
            errorMessage={fieldState?.error ? 'pricing.form.errors.start_date' : null}
            label="common.start_date"
            size="lg"
            selected={field.value}
            onChange={(startDate) => field.onChange(dayjs(startDate).startOf('d').toDate())}
            maxDate={endDate}
          />
        </div>
      )}
    />
  );
};
