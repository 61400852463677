import { SubUserWithAdditionalInfo, User } from '@utils/types/user';

const generateEmptyUser = (): User => ({
  account_type: 'producer',
  access_admin_installations: false,
  admin: false,
  asset_report: false,
  billing: false,
  email: '',
  full_merchant: false,
  geolocation: ['FR'],
  currencies: ['EUR'],
  has_access_to_reports: false,
  launchtr: true,
  limited: false,
  locale: 'fr',
  monitoring: false,
  name: '',
  permissions: [],
  pricing: false,
  roles: [],
  sales_management: false,
  status: '',
  uuid: '',
  societe: '',
  adresse: '',
  siren: '',
  invoice_manager_emails: [],
});

export const generateEmptySubUser = (): SubUserWithAdditionalInfo => ({
  ...generateEmptyUser(),
  init_password: '',
  installations: [],
  books: [],
});
