import React from 'react';
import { Definition } from '@GDM/DefinitionList';
import { Meter } from '@utils/types/meter';
import { useMeterInfo } from '../useMeterInfo';
import { SiretModal } from './SiretModal';

export const SiretDefinition = ({ meter }: { meter: Meter }) => {
  const { siret } = useMeterInfo();

  return (
    <Definition
      label="common.siret"
      data-cy="siret-definition"
      value={siret || 'common.none'}
      modal={{
        node: <SiretModal meter={meter} />,
        title: 'common.siret',
        permission: 'display:edit_monitoring',
      }}
    />
  );
};
