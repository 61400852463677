import { IconNames } from '@GDM/Icon';
import { EnergyType } from '@utils/types/common-types';

export const iconDictionary: Record<EnergyType | 'player', IconNames> = {
  book: 'Book',
  solar: 'Sun',
  wind: 'Wind',
  hydro: 'Droplet',
  other: 'MapPin',
  player: 'User',
};
