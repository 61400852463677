export const chartColors = {
  availabilityColor: 'var(--chart-aqua)',
  businessPlanColor: 'var(--chart-business-plan)',
  consoColor: 'var(--chart-blue)',
  correctedProductionColor: 'var(--primary-1-dark-alpha)',
  correctedProductionHistoryColor: 'var(--grey)',
  extrapolatedProductionColor: 'var(--chart-yellow)',
  forecastColor: 'var(--chart-orange)',
  dispatchColor: 'var(--chart-aqua)',
  marketPricesColor: 'var(--chart-yellow)',
  potentialPowerColor: 'var(--grey-light)',
  productionColor: 'var(--chart-production)',
  reactiveColor: 'var(--chart-deep-purple)',
  reactiveLightColor: 'var(--chart-red)',
  scadaColor: 'var(--chart-aqua)',
  selfConsumptionColor: 'var(--chart-self-consumption)',
  surplusColor: 'var(--chart-yellow)',
  tensionColor: 'var(--primary-2)',
};
