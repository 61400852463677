import React from 'react';
import { useUser } from '@context/User.context';
import { Button, DropDownButton } from '@GDM/Button';
import { useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { latest_v2_events_path, monitoring_v2_events_path } from '@utils/routes';
import { Event } from '@utils/types/event';
import classNames from 'classnames';
import { Notification } from './Notification';
import styles from './notifications.module.scss';

export const NotificationMenu = () => {
  const { locale } = useUser();
  const { t } = useTranslation(locale);
  const { data } = useRequest<Event[]>(latest_v2_events_path(), 'GET', false, null, false);

  if (!data?.length) return null;

  const eventTypesToDisplay: Event['type'][] = ['HighUnderPerformance', 'OutOfRangeReactive', 'ProductionStop'];

  const filteredNotifications = data.filter((n) => eventTypesToDisplay.includes(n.type));

  return (
    <DropDownButton icon="Bell" position="right" noChevron floating data-cy="notification-menu">
      <div className={classNames(styles['dropdown-header'], 'p-3')}>
        <b>{filteredNotifications.length}</b>&nbsp;
        {t(filteredNotifications.length === 1 ? 'common.notification' : 'common.notifications')}
      </div>

      {!!filteredNotifications.length && (
        <div className="px-3 py-2">
          {filteredNotifications.map((event) => (
            <div className={styles['notification']} key={event.id} data-cy="session-notification">
              <Notification event={event} />
            </div>
          ))}
        </div>
      )}

      <div className={classNames(styles['dropdown-footer'], 'p-3')}>
        <Button href={monitoring_v2_events_path()} variant="link" text="common.view_all" size="sm" />
      </div>
    </DropDownButton>
  );
};
