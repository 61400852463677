import { getDefaultOptions } from '@GDM/Chart/default-options';
import { tString } from '@hooks/useTranslation';
import { formatNumber } from '@utils/formatters';
import { appendColon } from '@utils/string';
import { Locale } from '@utils/types/common-types';
import { CountryDefinitions, Timezone } from '@utils/types/countries';
import Highcharts from 'highcharts/highstock';
import merge from 'lodash/merge';

export const getOptions = (
  locale: Locale,
  timezone: Timezone,
  isDeviation: boolean,
  selectedTimeSeries: number[],
  selectedTimeSeriesRatio: number[],
  baseLoadPrices: number[],
  m0Prices: number[],
  periodData: number[],
  coordinates: CountryDefinitions['coordinates'],
  currency: string,
) => {
  const t = tString(locale);
  const fieldName = isDeviation ? t('pricing.maps.deviation') : t('pricing.maps.capture_price');
  const fieldName12MonthAvg = isDeviation
    ? t('pricing.maps.deviation_avg_12_months')
    : t('pricing.maps.capture_price_avg_12_months');
  const fieldNameEurPerMWh = isDeviation
    ? t('pricing.maps.market_deviation_eur_per_mwh')
    : t('pricing.maps.capture_price_eur_per_mwh');
  const fieldRatioNamePercent = isDeviation
    ? t('pricing.maps.market_deviation_ratio_percentage')
    : t('pricing.maps.capture_price_ratio_percentage');
  const fieldRatioName = isDeviation ? t('pricing.maps.market_deviation_ratio') : t('pricing.maps.capture_price_ratio');
  const fieldNameRatio12NonthAvg = isDeviation
    ? t('pricing.maps.market_deviation_ratio_avg_12_months')
    : t('pricing.maps.capture_price_ratio_percentage');

  const tooltipPercent = { valueSuffix: '%', valueDecimals: 2 };
  const tooltipEuro = { valueSuffix: ` ${currency}/MWh`, valueDecimals: 2 };

  const defaultOptions: Highcharts.Options = getDefaultOptions(timezone, locale);

  // Deleting colors & tooltip.pointFormatter because it makes the chart crash
  delete defaultOptions.tooltip?.pointFormatter;
  delete defaultOptions.colors;

  const numberOptions = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
  const latitudeTitle = `<b>${appendColon('common.latitude', locale)}</b>&nbsp;${formatNumber(
    coordinates?.lat,
    locale,
    numberOptions,
  )}`;
  const longitudeTitle = `<b>${appendColon('common.longitude', locale)}</b>&nbsp;${formatNumber(
    coordinates?.lng,
    locale,
    numberOptions,
  )}`;
  const coordinatesSubTitle = coordinates.lat && coordinates.lng ? `${latitudeTitle}, ${longitudeTitle}` : null;
  const subtitle = coordinatesSubTitle ? { text: coordinatesSubTitle } : { text: t('pricing.maps.click_on_map') };

  const overriddenOptions: Highcharts.Options = {
    title: { text: isDeviation ? t('pricing.maps.deviation_over_time') : t('pricing.maps.capture_price_over_time') },
    subtitle,
    chart: {
      type: 'spline',
      height: 680,
      style: { fontFamily: "'Nunito sans', sans-serif'" },
    },
    legend: { enabled: false },
    xAxis: {
      gridLineWidth: 1,
      crosshair: true,
      dateTimeLabelFormats: {
        second: '%Y-%m-%d',
        minute: '%Y-%m-%d',
        hour: '%Y-%m-%d',
        day: '%d %b (%a)',
        month: '%Y-%m',
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
    },
    tooltip: {
      dateTimeLabelFormats: {
        second: '%Y-%m-%d',
        minute: '%Y-%m-%d',
        hour: '%Y-%m-%d',
        day: '%d %b (%a)',
        month: '%Y-%m',
      },
    },
    yAxis: [
      { title: { text: fieldNameEurPerMWh }, height: '45%', opposite: true, crosshair: true },
      {
        title: { text: fieldRatioNamePercent },
        top: '50%',
        height: '45%',
        offset: 0,
        opposite: true,
        crosshair: true,
      },
    ],
    series: [
      {
        name: fieldName,
        id: 'dev',
        data: selectedTimeSeries?.map((d, i) => [periodData[i], d]) || [],
        color: 'var(--chart-aqua)',
        negativeColor: 'var(--chart-red)',
        threshold: 0,
        type: 'spline',
        yAxis: 0,
        tooltip: tooltipEuro,
        opacity: 0.75,
      },
      {
        name: fieldName12MonthAvg,
        type: 'sma',
        linkedTo: 'dev',
        dashStyle: 'Dash',
        color: 'var(--chart-aqua)',
        negativeColor: 'var(--chart-red)',
        opacity: 0.65,
        lineWidth: 0.8,
        yAxis: 0,
        tooltip: tooltipEuro,
        params: { period: 12 },
      },
      {
        name: fieldRatioName,
        id: 'qf',
        data: selectedTimeSeriesRatio?.map((d, i) => [periodData[i], d]) || [],
        color: 'var(--chart-aqua)',
        negativeColor: 'var(--chart-red)',
        type: 'spline',
        tooltip: tooltipPercent,
        yAxis: 1,
      },
      {
        name: fieldNameRatio12NonthAvg,
        type: 'sma',
        linkedTo: 'qf',
        dashStyle: 'Dash',
        color: 'var(--chart-aqua)',
        negativeColor: 'var(--chart-red)',
        opacity: 0.65,
        lineWidth: 0.8,
        yAxis: 1,
        params: { period: 12 },
        tooltip: tooltipPercent,
      },
      {
        name: t('pricing.maps.baseload'),
        id: 'bl',
        data: baseLoadPrices?.map((d, i) => [periodData[i], d]) || [],
        color: 'var(--chart-purple)',
        type: 'spline',
        yAxis: 0,
        tooltip: tooltipEuro,
        zIndex: -10,
        opacity: 0.75,
        visible: !isDeviation,
      },
      {
        type: 'sma',
        name: t('pricing.maps.baseload_avg_12_months'),
        linkedTo: 'bl',
        color: 'var(--chart-purple)',
        opacity: 0.65,
        params: { period: 12 },
        dashStyle: 'Dash',
        tooltip: tooltipPercent,
        visible: !isDeviation,
        yAxis: 0,
      },
      {
        name: t('pricing.maps.national_capture_price_ratio'),
        id: 'nationalcp',
        data: baseLoadPrices.map((d, i) => [periodData[i], (100 * m0Prices[i]) / d]),
        color: 'var(--chart-purple)',
        opacity: 0.75,
        type: 'spline',
        yAxis: 1,
        tooltip: tooltipPercent,
        zIndex: -10,
        visible: !isDeviation,
      },
      {
        type: 'sma',
        name: t('pricing.maps.national_capture_price_ratio_avg_12_months'),
        linkedTo: 'nationalcp',
        color: 'var(--chart-purple)',
        opacity: 0.65,
        params: { period: 12 },
        dashStyle: 'Dash',
        tooltip: tooltipPercent,
        visible: !isDeviation,
        yAxis: 1,
      },
    ],
  };

  if (isDeviation && overriddenOptions.yAxis && Array.isArray(overriddenOptions.yAxis)) {
    overriddenOptions.yAxis[1].softMin = -10;
    overriddenOptions.yAxis[1].softMax = 10;
  }

  const options = merge(defaultOptions, overriddenOptions);

  return options;
};
