import React from 'react';
import { Select } from '@GDM/forms';
import { Col } from '@GDM/layout';
import { Text } from '@GDM/Text';

const SignInput = ({ value, onChange }: { value: string; onChange: (value?: string) => void }) => {
  const options = [
    { value: 'online', label: 'common.e_signature' },
    { value: 'offline', label: 'common.manuel' },
  ];

  return (
    <Col md={12} className="mb-3">
      <div className="mt-3">
        <Select
          label="common.signature"
          options={options}
          id="signtype"
          selectedOption={value}
          onChange={(opt) => onChange(opt?.value)}
          classNamePrefix="select-sign-type"
        />

        {value === 'online' && <Text text="common.sign_alert" className="text-small mt-2" />}
      </div>
    </Col>
  );
};

export default SignInput;
