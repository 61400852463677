import React from 'react';
import { Card } from '@GDM/Card';
import useTranslation from '@hooks/useTranslation';
import { useProjectContext } from '@pages/Simulations/Simulation/utils/project.context';
import { ConfidenceValue, ConfidenceTuple } from '../ConfidenceValue';
import styles from './cards.module.scss';

export const MarketCaptureConfidenceIntervalCard = () => {
  const project = useProjectContext();

  const { q25_factor, q75_factor, q50_factor, national_q50_factor } =
    project?.full_merchant?.full_merchant_result || {};

  const variant =
    q50_factor && national_q50_factor ? (q50_factor >= national_q50_factor ? 'primary-1' : 'secondary') : null;
  const { full_merchant_cannibalized_distribution_cat } = project?.full_merchant || {};
  const { t } = useTranslation();
  const year = full_merchant_cannibalized_distribution_cat?.[1];
  const unit = 'percentage';
  const values: ConfidenceTuple = [
    { value: q25_factor || null, unit, variant },
    { value: q75_factor || null, unit, variant },
  ];

  return (
    <Card
      title={t('pricing.project.full_merchant_quality_factory_confidence_range', { year })}
      className={styles.card}
      body={
        <>
          <div className={styles.value}>
            <ConfidenceValue values={values} />
          </div>
        </>
      }
    />
  );
};
