import { useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import { v2_export_invoice_reports_winshuttle_path } from '@utils/routes';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import fileDownload from 'js-file-download';

const fetchWinshuttleReport = async ({ url, options }: { url: string; options?: AxiosRequestConfig }) => {
  const response = await axiosInstance(false)({ ...options, url, method: 'POST', responseType: 'blob' });
  if (response.headers['content-disposition'] && response.data) {
    const fileName = response.headers['content-disposition'].split('"')[1];
    fileDownload(response.data, fileName);
  }

  return response.data;
};

export const useDownloadWinshuttleReport = ({
  startDate,
  endDate,
  invoice_uuids_to_filter,
}: {
  startDate?: Date | null;
  endDate?: Date | null;
  invoice_uuids_to_filter?: string[] | null;
}) => {
  const { execute, ...request } = useRequest(
    v2_export_invoice_reports_winshuttle_path({
      params: {
        start_date: dayjs(startDate).format('YYYY-MM'),
        end_date: dayjs(endDate).format('YYYY-MM'),
      },
    }),
    fetchWinshuttleReport,
    true,
  );

  const downloadWinshuttleReport = () => {
    execute?.({ data: { invoice_uuids_to_filter } });
  };

  return { ...request, downloadWinshuttleReport };
};
