import React from 'react';
import { Option } from '@utils/types/common-types';
import { GroupProps, GroupBase } from 'react-select';
import styles from './SearchbarCustomGroup.module.scss';

export const SearchbarCustomGroup: React.ComponentType<GroupProps<Option, true, GroupBase<Option>>> = (props) => (
  <div className={styles['custom-group']}>
    <div className={`${styles['custom-group--Label']} px-2`}>{props.label}</div>
    {props.children}
  </div>
);
