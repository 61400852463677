import React from 'react';
import { Text } from '@GDM/Text';
import styles from './table-label.module.scss';

export const TableLabelContainer = ({ children, label }: { children: React.ReactNode; label: string }) => {
  return (
    <div>
      <Text text={label} className={styles.label} />

      <div className={styles['container']}>{children}</div>
    </div>
  );
};
