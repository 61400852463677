import { useMemo } from 'react';
import { ProductionTimeSeriesRecord } from '../production.types';
import { computeTimeSeriesAggregation } from '../utils/computeTimeSeriesAggregation';

export const useTensionAggregation = (
  rawTensionProduction: ProductionTimeSeriesRecord['rawTensionProduction'] | undefined,
  chartExtremes: [number, number],
) => {
  return useMemo(() => {
    if (!rawTensionProduction?.values.length) return;

    const timeSeriesAggregation = computeTimeSeriesAggregation({
      series: rawTensionProduction.values,
      timeFilter: chartExtremes,
    });
    const average = timeSeriesAggregation.sum / timeSeriesAggregation.count;

    return { ...timeSeriesAggregation, average };
  }, [rawTensionProduction, chartExtremes]);
};
