import React from 'react';
import { NumberCell } from '@GDM/Table';
import { Row } from '@tanstack/react-table';
import { useRevenueContext } from '../../hooks/useRevenueContext';
import { TableData } from '../../revenue.types';

export const ValueCell = ({ row, isLoading, period }: { row: Row<TableData>; isLoading: boolean; period: string }) => {
  const { resolution, sourceType } = useRevenueContext();

  if (isLoading) return <>--</>;

  let value: number | null = null;
  let isIncomplete = false;

  if ('values' in row.original) {
    const dataPoint = row.original.values?.find((point) => point.period === period);
    value = dataPoint?.revenue ?? Number(dataPoint?.value) ?? null; // coerce to null if undefined
    isIncomplete = dataPoint?.incomplete || false;
  }

  if (
    (resolution === 'monthly' || sourceType === 'book') &&
    row.original.unit === 'kWh' &&
    value !== null &&
    value !== undefined
  ) {
    value = Number(value) / 1000;
  }

  const displayValue = value === null ? null : Number(value) ?? null;

  const numberOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  return (
    <NumberCell
      color={isIncomplete ? 'grey' : 'default'}
      tooltip={isIncomplete ? 'common.incomplete_data' : null}
      value={displayValue}
      numberOptions={numberOptions}
    />
  );
};
