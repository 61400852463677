import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useUser } from '@context/User.context';
import { Badge } from '@GDM/Badge';
import useTranslation from '@hooks/useTranslation';
import { updateCurrency } from '@utils/formatters';
import { Permission } from '@utils/types/permission';
import classNames from 'classnames';
import { TabsContextType, tabsContext } from './tabs.context';
import styles from './tabs.module.scss';

export type Tab<T extends number | string> = {
  id: T;
  name: string;
  badge?: string;
  permissions?: Permission[];
  condition?: boolean;
};

export function Tabs<T extends number | string>({
  children,
  tabs,
  onTabChange,
  className,
  defaultTab,
  noContainer = false,
}: PropsWithChildren<{
  tabs: readonly Tab<T>[];
  onTabChange?: (e: T) => void | React.Dispatch<React.SetStateAction<T>>;
  className?: string;
  defaultTab?: T;
  noContainer?: boolean;
}>): JSX.Element {
  const { t } = useTranslation();
  const { isAuthorized, geolocation } = useUser();
  const [activeTab, setActiveTab] = useState<T | undefined>(defaultTab);

  useEffect(() => {
    const hash = window.location.hash;
    const permittedTabs = tabs.filter(filterTabs);

    if (hash.length) {
      const tabId = hash.replace('#', '');
      const tabToSet = permittedTabs?.find((tab) => tab.id === tabId) ? (tabId as T) : permittedTabs[0].id;
      setActiveTab(tabToSet);
    } else {
      const hasDefaultTabAndTabIsPermitted = defaultTab && permittedTabs.find((t) => t.id === defaultTab);
      setActiveTab(hasDefaultTabAndTabIsPermitted ? defaultTab : permittedTabs?.[0]?.id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (tabId: Tab<T>['id']) => () => {
    setActiveTab(tabId);
    window.location.hash = tabId.toString();
    onTabChange?.(tabId);

    setTimeout(() => {
      const IS_UK_USER = geolocation?.[0] === 'UK';
      if (IS_UK_USER) updateCurrency();
    }, 500);
  };

  const filterTabs = ({ permissions, condition }: Tab<T>): boolean => {
    if (condition === false) return false;

    return permissions ? isAuthorized(permissions) : true;
  };

  const contextValue = useMemo(
    () => ({ currentTab: activeTab, setCurrentTab: setActiveTab } as TabsContextType<unknown>),
    [activeTab],
  );

  return (
    <tabsContext.Provider value={contextValue}>
      <ul className={classNames(styles.nav, className)} data-cy="tab-list">
        {tabs.filter(filterTabs).map((tab) => {
          return (
            <li
              key={tab.id}
              onClick={handleClick(tab.id)}
              className={classNames(styles.item, { [styles.active]: activeTab === tab.id })}
              data-cy={`header-tab-${tab.id}`}
            >
              <div className={classNames(styles.link, { [styles.active]: activeTab === tab.id })}>
                <span>{t(tab.name)}</span>
                {!!tab.badge && <Badge variant="danger" className={styles.badge} label={tab.badge} />}
              </div>
            </li>
          );
        })}
      </ul>

      {!noContainer && <div data-tab-container>{children}</div>}
      {noContainer && children}
    </tabsContext.Provider>
  );
}
