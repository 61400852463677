import React from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import jsonToCsv, { HeadCsv } from '@utils/json-to-csv';
import { MeterInvoice } from '@utils/types/meter-invoice';
import fileDownload from 'js-file-download';
import { TabId } from './invoices.types';

export const Actions = ({ filteredInvoices, type }: { filteredInvoices: MeterInvoice[]; type: TabId }) => {
  const user = useUser();
  const { t } = useTranslation();

  const handleExportCsvClick = () => {
    fileDownload(
      jsonToCsv(filteredInvoices, [
        { label: t('common.name'), key: 'installation_name' },
        { label: t('common.client_id'), key: 'installation_client_id' },
        {
          label: t('monitoring.invoicing.source'),
          key: 'volume_source',
          transform: (source) => (source === 'load_curve' ? 'points 10mn' : 'index'),
        },
        { label: t('monitoring.invoicing.prod_billable'), key: 'volume_production' },
        ...((type
          ? [
              { label: t('sales_management.first_index'), key: 'volume_first_index' },
              { label: t('sales_management.last_index'), key: 'volume_last_index' },
              { label: t('monitoring.invoicing.consum'), key: 'volume_consumption' },
              { label: t('sales_management.first_consumption_index'), key: 'volume_first_conso_index' },
              { label: t('sales_management.last_consumption_index'), key: 'volume_last_conso_index' },
              { label: t('common.start_date'), key: 'start_date' },
              { label: t('common.end_date'), key: 'end_date' },
            ]
          : [
              { label: 'Δ Index', key: 'volume_delta_prod_vs_index' },
              { label: t('monitoring.invoicing.raw_curve'), key: 'volume_delta_prod_vs_load_curve' },
              { label: t('monitoring.invoicing.clipping'), key: 'volume_clipped' },
              { label: t('monitoring.invoicing.negative_price'), key: 'volume_produced_at_neg_prices' },
              { label: t('monitoring.invoicing.dispo_enedis'), key: 'volume_coverage' },
              { label: t('monitoring.invoicing.consum'), key: 'volume_consumption' },
              { label: t('common.type'), key: 'invoicing_type' },
              { label: t('monitoring.invoicing.dispo_enedis_raw'), key: 'volume_raw_coverage' },
            ]) as HeadCsv<MeterInvoice>[]),
        { label: t('common.status_label'), key: 'volume_status' },
        { label: t('common.updated_at'), key: 'updated_at' },
      ]),
      'facturation.csv',
    );
  };

  if (user?.limited) return null;

  return (
    <div className="d-flex px-3">
      <Button variant="primary-2" size="sm" className="ml-auto" onClick={handleExportCsvClick} icon="Download" />
    </div>
  );
};
