import React from 'react';
import { useUser } from '@context/User.context';
import { CellContext } from '@tanstack/react-table';
import { Owner } from '@utils/types/market_player';
import chunk from 'lodash/chunk';

export const InvoicingEmailCell = ({ getValue }: CellContext<Owner, unknown>) => {
  const user = useUser();
  const value = getValue();

  if (!Array.isArray(value) || value.length === 0) return null;

  const emails = (value.length > 0 ? value : user.invoice_manager_emails).filter((email) => email);

  if (emails.length >= 3) {
    const chunks = chunk(emails, 3);

    return (
      <>
        {chunks.map((chunk) => (
          <div key={chunk.toString()}>{chunk.join(', ')}</div>
        ))}
      </>
    );
  }

  return (
    <>
      {emails.map((email) => (
        <div key={email}>{email}</div>
      ))}
    </>
  );
};
