import React from 'react';
import { Restricted } from '@components/Restricted';
import { ProgressCard, ValueCard } from '@GDM/Card';
import { ValueWithUnit } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import { isANumber } from '@utils/isANumber';
import classNames from 'classnames';
import styles from '../portfolio.module.scss';
import { usePortfolio } from '../usePortfolio';

export const PortfolioAggregate = () => {
  const { t } = useTranslation();
  const { portfolio, loading } = usePortfolio();

  const performance = portfolio?.map((el) => el.performance).filter((el) => el);

  const totalEnergyPerformance = parseFloat(
    portfolio
      ?.filter((el) => el.performance)
      .map((el) => Number(el.sumdata))
      .reduce((prev, curr) => (curr || 0) + (prev || 0), 0)
      .toFixed(2) || '',
  );

  const totalEnergy =
    portfolio && portfolio.length && !portfolio.every((i) => !isANumber(i.sumdata))
      ? parseFloat(portfolio?.reduce((prev, curr) => Number(curr.sumdata) + prev, 0).toFixed(2))
      : null;

  const productionHoursPower = portfolio?.reduce(
    (prev, curr) => (curr.puissancecrete || 0) * (curr.hourprod || 0) + prev,
    0,
  );

  const totalPowerCrete = portfolio?.reduce((prev, curr) => (curr.puissancecrete || 0) + prev, 0);

  const energySpe =
    isANumber(productionHoursPower) && isANumber(totalPowerCrete)
      ? parseFloat((productionHoursPower / totalPowerCrete).toFixed(2))
      : null;

  const totalPerf = performance?.reduce((prev, curr) => Number(curr) + Number(prev), 0);

  const avgPerf =
    isANumber(totalPerf) && isANumber(performance?.length)
      ? parseFloat((totalPerf / (performance?.length as number)).toFixed(2))
      : null;

  const energyLoss = isANumber(avgPerf)
    ? parseFloat((totalEnergyPerformance - totalEnergyPerformance * (avgPerf / 100)).toFixed(2))
    : null;

  const totalCover = portfolio?.reduce((prev, curr) => (curr.coverage || 0) + prev, 0);

  const avgCover =
    isANumber(totalCover) && isANumber(portfolio?.length)
      ? parseFloat((totalCover / (portfolio?.length as number)).toFixed(2))
      : null;

  return (
    <div className={styles['aggregate-row']}>
      <div className={classNames(styles['aggregate-card'])}>
        <ValueCard title="monitoring.portfolio.total_energy" value={totalEnergy} unit="kWh" data-cy="total-energy">
          {t('monitoring.portfolio.specific_energy')}&nbsp;
          <ValueWithUnit value={energySpe} unit="h" data-cy="specific-energy" />
        </ValueCard>
      </div>
      <Restricted permissions={['display:view_monitoring_dashboard_meter_performances']}>
        <div className={classNames(styles['aggregate-card'], styles['progress-bar'])}>
          <ProgressCard
            title="monitoring.portfolio.portfolio_performance"
            value={avgPerf ?? 0}
            dangerThreshold={85}
            isLoading={loading}
          >
            {!loading && (
              <div className="mt-2">
                {t('monitoring.portfolio.loss_of')} <ValueWithUnit value={energyLoss} unit="kWh" />
              </div>
            )}
          </ProgressCard>
        </div>
      </Restricted>
      <div className={classNames(styles['aggregate-card'], styles['progress-bar'])}>
        <ProgressCard
          title="monitoring.portfolio.data_coverage"
          value={avgCover ?? 0}
          dangerThreshold={0}
          isLoading={loading}
        />
      </div>
    </div>
  );
};
