import React, { useMemo, useState } from 'react';
import { Table, TableActions, TableBody, TableHead, TablePageSizeSelect, TablePagination } from '@GDM/Table';
import { getPaginationRowModel, getSortedRowModel, useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { Simulation } from '@utils/types/simulation';
import getColumns from '../state/tableColumns';

type SimulationsTableProps = {
  simulations: Simulation[];
  isFullMerchant?: boolean;
  isBook?: boolean;
};

const SimulationsTable = ({ simulations, isFullMerchant, isBook }: SimulationsTableProps) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

  const columns = useMemo(() => getColumns(simulations, isFullMerchant, isBook), [simulations, isFullMerchant, isBook]);

  const table = useReactTable({
    columns,
    data: simulations,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    state: { pagination },
  });

  const pageSize = pagination.pageSize;

  return (
    <>
      <Table>
        <TableHead table={table} />
        <TableBody data-cy="simulations-table" table={table} />
      </Table>

      <TableActions>
        <TablePageSizeSelect pageSize={pageSize} setPageSize={table.setPageSize} totalEntries={simulations.length} />
        <TablePagination pageCount={table.getPageCount()} gotoPage={table.setPageIndex} />
      </TableActions>
    </>
  );
};

export default SimulationsTable;
