import { CURRENCY_SYMBOL_MAP, getSymbol } from '@utils/currency/getSymbol';
import { Locale } from '@utils/types/common-types';

export const formatNumber = (
  value: number | null | undefined,
  locale?: Locale,
  options?: Intl.NumberFormatOptions,
): string | null => {
  if (typeof value !== 'number' || value === undefined || value === null || isNaN(value)) return null;

  const parts = new Intl.NumberFormat(locale, options).formatToParts(value);

  // If currency is provided, replace the currency symbol with our currency mapping.
  // This was necessary for XOF because we map it to "FCFA", but the Intl.NumberFormat API
  // returns "F CFA" as the currency symbol.
  const customFormatted =
    options?.currency && options.currency in CURRENCY_SYMBOL_MAP
      ? parts.reduce((acc, curr) => {
          return curr.type === 'currency'
            ? acc + getSymbol(options.currency as keyof typeof CURRENCY_SYMBOL_MAP)
            : acc + curr.value;
        }, '')
      : parts.map((v) => v.value).join('');

  return customFormatted;
};
