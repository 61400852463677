import { useCallback } from 'react';
import { put, useRequest } from '@hooks/useRequest';
import { approve_in_batch_v2_invoice_insights_path } from '@utils/routes';
import { MeterInvoice } from '@utils/types/meter-invoice';

export type RequestVolume = { last_index?: number; invoice_uuid: string };
type ApproveVolume = (volumes: RequestVolume[]) => void;

const useApproveVolumes = () => {
  const { execute, loaded, loading, data, error } = useRequest<MeterInvoice[]>(
    approve_in_batch_v2_invoice_insights_path(),
    put,
    true,
  );

  const approveVolumes = useCallback<ApproveVolume>(
    (volumes) => {
      if (!volumes || volumes.length === 0) return;
      execute?.({ volumes });
    },
    [execute],
  );

  return {
    approveVolumes,
    data,
    error,
    loading,
    loaded,
  };
};

export default useApproveVolumes;
