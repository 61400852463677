import chroma from 'chroma-js';

export function getColorFromValues(
  values: number[],
  deviation: boolean,
  rasterIndex: number,
  baseloadPrices: number[],
  m0Prices: number[],
  scale: chroma.Scale<chroma.Color>,
) {
  let value: number;

  if (deviation) value = (100 * values[rasterIndex]) / baseloadPrices[rasterIndex];
  else value = (100 * (values[rasterIndex] + m0Prices[rasterIndex])) / baseloadPrices[rasterIndex];

  if (isNaN(value)) return null;

  return scale(value).hex();
}
