import { RevenueContextType } from '../revenue.types';

export const CURRENCY_FORMATTING_OPTIONS: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'eur',
  notation: 'compact',
  minimumFractionDigits: 2,
};

export const DATE_FORMATS: Record<NonNullable<RevenueContextType['resolution']>, string> = {
  daily: '%d %b (%a)',
  hourly: '%H:%M',
  monthly: '%Y-%m',
};
