import React from 'react';
import { useInstallationFields } from '../../hooks/useInstallationFields';
import { CountryPicker } from './fields/CountryPicker';
import { EnergyPicker } from './fields/EnergyPicker';
import { InstallationNameInput } from './fields/InstallationNameInput';

export const InstallationSettingsSection = () => {
  const { hasField } = useInstallationFields();

  return (
    <div className="p-3 d-flex flex-column gap-3">
      {hasField('name') && (
        <div>
          <InstallationNameInput />
        </div>
      )}

      <div className="d-flex gap-3">
        {hasField('country') && <CountryPicker />}
        {hasField('energy') && <EnergyPicker />}
      </div>
    </div>
  );
};
