import { useMemo } from 'react';
import useTranslation from '@hooks/useTranslation';
import { isANumber } from '@utils/isANumber';
import { DataTypeFiltersForm as DataTypeFiltersForm } from '@utils/types/dataFilter';
import { DataPeriod } from '@utils/types/dataPeriod';
import { TimeSeries } from '@utils/types/timeSeries';
import { UseFormReturn } from 'react-hook-form';
import { ProductionTimeSeriesRecord } from '../../production.types';

export const useYAxes = ({
  data,
  dataPeriod,
  performanceRatios,
  nominalPower,
  form,
  marketPricesCurve,
}: {
  data?: Partial<ProductionTimeSeriesRecord>;
  dataPeriod: DataPeriod;
  performanceRatios: TimeSeries | null;
  nominalPower?: string;
  form: UseFormReturn<DataTypeFiltersForm>;
  marketPricesCurve: TimeSeries;
}) => {
  const { t } = useTranslation();
  const { watch: getFilterValues } = form;

  const maxPower = isANumber(nominalPower) ? nominalPower : undefined;

  const yAxes = useMemo(() => {
    const showPerformanceRateAxes = Boolean(
      getFilterValues('showPotentialPower') &&
        performanceRatios &&
        performanceRatios?.length > 0 &&
        dataPeriod === 'daily',
    );

    const isKw = dataPeriod !== 'daily' && dataPeriod !== 'monthly';

    const curtailmentLineConfig: Highcharts.YAxisPlotLinesOptions = {
      value: maxPower,
      dashStyle: 'ShortDash',
      width: 1,
      zIndex: 5,
      label: {
        text: t('monitoring.portfolio.table.max_power'),
        style: { color: 'var(--grey)' },
        align: 'right',
      },
    };

    const highestMarketPrice = Math.max(...marketPricesCurve.map((d) => d?.[1]));

    const axes = {
      PRODUCTION: {
        id: 'PRODUCTION',
        title: { text: isKw ? 'kW' : 'kWh' },
        height: showPerformanceRateAxes ? 230 : undefined,
        offset: showPerformanceRateAxes ? 0 : undefined,
        lineWidth: showPerformanceRateAxes ? 1.5 : undefined,
        opposite: true,
        min: 0,
        plotLines: isKw && getFilterValues('showCurtailmentLine') ? [curtailmentLineConfig] : undefined,
      },
      PERFORMANCE_RATE: {
        id: 'PERFORMANCE_RATE',
        title: { text: `${t('monitoring.installation.pr')} (%)` },
        max: 100,
        height: 80,
        top: 250,
        offset: 0,
        lineWidth: 2,
        tickInterval: 50,
        opposite: true,
        visible: showPerformanceRateAxes,
      },
      AVAILABILITY: {
        id: 'AVAILABILITY',
        title: { text: t('monitoring.installation.availability') },
        min: 0,
        max: 100,
        top: 340,
        height: 40,
        offset: 0,
        lineWidth: 2,
        tickInterval: 50,
        opposite: true,
        visible: showPerformanceRateAxes,
      },
      TENSION: {
        id: 'TENSION',
        title: { text: `${t('monitoring.installation.tension')} (V)` },
        lineWidth: 1.5,
        offset: 0,
        visible:
          getFilterValues('showTension') &&
          data?.rawTensionProduction?.values &&
          data?.rawTensionProduction?.values.length > 0,
      },
      MARKET_PRICES: {
        id: 'MARKET_PRICES',
        min: -30,
        max: Math.max(150, highestMarketPrice),
        visible: getFilterValues('showMarketPrices') && marketPricesCurve.length > 0,
        title: {
          text: t('monitoring.installation.market_prices'),
        },
      },
    } as const satisfies Record<string, Highcharts.YAxisOptions>;

    return Object.values(axes);
  }, [
    data?.rawTensionProduction?.values,
    marketPricesCurve,
    dataPeriod,
    getFilterValues,
    maxPower,
    performanceRatios,
    t,
  ]);

  return { yAxes };
};
