import { createContext, useContext } from 'react';
import Book from '@utils/types/book';

export type BooksContext = {
  isLoading: boolean;
  books: Book[];
};

const booksContext = createContext<BooksContext>({ books: [], isLoading: false });

export const useBooksContext = () => useContext(booksContext);

export default booksContext;
