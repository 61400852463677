import React, { useEffect } from 'react';
import { useUnavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { Modal } from '@GDM/Modal';
import { destroy, useRequest } from '@hooks/useRequest';
import useTranslation from '@hooks/useTranslation';
import { v2_unavailability_path } from '@utils/routes';
import { Unavailability } from '@utils/types/unavailability';
import { useCancelModalContext } from './context';

export const CancelModal = () => {
  const { t } = useTranslation();
  const { updateUnavailability, selectedUnavailability } = useUnavailabilitiesContext();
  const { toggle, isOpen } = useCancelModalContext();

  const {
    data: unavailability,
    execute: onCancel,
    loading,
    error,
  } = useRequest<Unavailability>(
    selectedUnavailability ? v2_unavailability_path(selectedUnavailability.uuid) : null,
    destroy,
    true,
  );

  useEffect(() => {
    if (!loading && !error && !!unavailability && !!toggle) {
      updateUnavailability(unavailability);
      toggle();
    }
  }, [unavailability, loading, error, updateUnavailability, toggle]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      header="monitoring.unavailabilities.cancel_modal.header"
      submitAction={selectedUnavailability ? onCancel : undefined}
      isLoading={loading}
      error={error?.message}
    >
      {t('monitoring.unavailabilities.cancel_modal.body')}
    </Modal>
  );
};
