import { createContext, useContext } from 'react';
import type { DataProvider } from '@api/dataProviders';
import type { Grid } from '@api/grids';

type SelectedGridContext = {
  selectedGrid: Grid | null;
  selectedDataProvider: DataProvider | null;
};

export const SelectedGridContext = createContext<SelectedGridContext>({
  selectedGrid: null,
  selectedDataProvider: null,
});

export const SelectedGridProvider = SelectedGridContext.Provider;

export const useSelectedGrid = () => useContext(SelectedGridContext);
