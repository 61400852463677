import React from 'react';
import { sectionStyles } from '@GDM/forms';
import { getSymbol } from '@utils/currency/getSymbol';
import { ContractForm } from '@utils/types/contract';
import { Currency } from '@utils/types/currency';
import { Control } from 'react-hook-form';
import { NumericInput } from '../../../components/Inputs/Numeric';

export const CapaPricing = ({
  control,
  readOnly,
  currency,
}: {
  control: Control<ContractForm>;
  readOnly: boolean;
  currency: Currency;
}) => (
  <div className={sectionStyles.row}>
    <NumericInput
      id="Ncc"
      name="ncc"
      control={control}
      rules={{ required: true }}
      required
      label="sales_management.ncc"
      suffix="MW"
      readOnly={readOnly}
    />
    <NumericInput
      id="AggregationFee"
      name="aggregation_fee"
      control={control}
      rules={{ required: true }}
      label="sales_management.aggreg_fee"
      suffix={`${getSymbol(currency)}/MW`}
      readOnly={readOnly}
    />
  </div>
);
