import React from 'react';
import { Icon } from '@GDM/Icon';
import { CellContext } from '@tanstack/react-table';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const TypeCell = ({ getValue }: CellContext<MeterInvoice, unknown>) => {
  switch (getValue()) {
    case 'Index':
      return (
        <span>
          <Icon name="List" size={16} title="sales_management.index" /> (2h-2h)
        </span>
      );
    case 'Courbe de charge (2h-2h)':
      return (
        <span>
          <Icon name="Activity" size={16} title="sales_management.ten_minutes_points" /> (2h-2h)
        </span>
      );
    case 'Courbe de charge (0h-0h)':
      return (
        <span>
          <Icon name="Activity" size={16} title="sales_management.ten_minutes_points" /> (0h-0h)
        </span>
      );
    case 'Courbe de charge (0h-0h) OA':
      return (
        <span>
          <Icon name="Activity" size={16} title="sales_management.ten_minutes_points" /> (0h-0h)
        </span>
      );

    default:
      return null;
  }
};
