import React from 'react';
import { Chart } from '@GDM/Chart';
import useTranslation from '@hooks/useTranslation';
import { QfuData } from '@utils/types/qfuData';
import dayjs from 'dayjs';
import Highcharts from 'highcharts/highstock';

export const QfuChart = ({ qfuData }: { qfuData: QfuData }) => {
  const { t } = useTranslation();

  // Take min and max from qfuData['tab_ref_low']
  const { max: refMax, min: refMin } = qfuData['tab_ref_low'].reduce(
    (acc, cur) => {
      if (+cur[0] > acc.max) acc.max = +cur[0];
      if (+cur[0] < acc.min) acc.min = +cur[0];

      return acc;
    },
    { max: qfuData['tab_ref_low'][0][0], min: qfuData['tab_ref_low'][0][0] },
  );

  const { max, min } = qfuData['tab_data'].reduce(
    (acc, { delta_u }) => {
      const deltaU = Number(delta_u);

      return { max: Math.max(acc.max, deltaU), min: Math.min(acc.min, deltaU) };
    },
    { max: -Infinity, min: Infinity },
  );

  const options: Highcharts.Options = {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      height: '450px',
    },
    xAxis: {
      title: {
        text: 'ΔU(%Un)',
      },
      type: 'linear',
      gridLineWidth: 1,
      min: Math.min(refMin, min),
      max: Math.max(refMax, max),
    },
    yAxis: [
      {
        title: {
          text: t('monitoring.installation.reactive_injected'),
          align: 'high',
          x: 0,
          y: 0,
        },
        gridLineWidth: 1,
        opposite: true,
      },
      {
        title: {
          text: 'Q/Pracc',
          align: 'middle',
          x: -30,
          y: 0,
        },
        gridLineWidth: 1,
        opposite: true,
      },
      {
        title: {
          text: t('monitoring.installation.reactive_withdrawn'),
          align: 'low',
          x: -60,
          y: 0,
        },
        gridLineWidth: 1,
        opposite: true,
      },
    ],
    tooltip: {
      formatter: function () {
        if (this.series) {
          return (
            `Date: <b>${this.point.name}</b><br />` +
            'ΔU: <b>' +
            this.x?.toFixed(2) +
            '</b>%<br />Q/Pracc: <b>' +
            this.y +
            '</b>'
          );
        } else {
          return (
            'ΔU: ' +
            this.x?.toFixed(2) +
            '%<br />Q/Pracc: ' +
            this.points?.[0]?.y +
            ' [' +
            this.points?.[2]?.y +
            ', ' +
            this.points?.[1]?.y +
            ']'
          );
        }
      },
      shared: true,
    },
    plotOptions: {
      scatter: {
        turboThreshold: 50 * 1000,
      },
    },
  };

  return (
    <div className="m-2">
      <Chart
        hideLegend
        options={options}
        series={[
          {
            name: 'mesure',
            type: 'scatter',
            data: qfuData['tab_data'].map(({ delta_u, value, time }) => ({
              x: Number(delta_u),
              y: value,
              name: dayjs(time * 1000).format('DD/MM HH:mm'),
            })),
            color: 'rgba(1, 212, 131,0.7)',
            marker: {
              radius: 1.5,
            },
          },
          {
            name: 'reference',
            data: qfuData['tab_ref'],
            type: 'line',
            color: '#000',
          },
          {
            name: 'reference max',
            data: qfuData['tab_ref_up'],
            type: 'line',
            color: 'rgba(0, 0, 0, 0.40)',
            dashStyle: 'Dot',
          },
          {
            name: 'reference min',
            data: qfuData['tab_ref_low'],
            type: 'line',
            color: 'rgba(0, 0, 0, 0.40)',
            dashStyle: 'Dot',
          },
        ]}
      />
    </div>
  );
};
