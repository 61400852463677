import { useFormContext } from 'react-hook-form';
import { useInternalFormContext } from '../Form';
import { isFieldAvalaibleForCountry, isFieldAvalaibleForFieldValues } from '../utils';
import { findCountryField } from './useField';

export const useFields = <T extends string>() => {
  const { commonFields, country } = useInternalFormContext();
  const { watch } = useFormContext();
  const values = watch();

  const hasField = (fieldName: T) => {
    const fields = commonFields.filter((f) => f.name === fieldName);
    const field = findCountryField(fields, country);

    if (!field) {
      return false;
    }

    const isAvailableForCountry = isFieldAvalaibleForCountry(field, country);
    const isAvailableForFieldValues = isFieldAvalaibleForFieldValues(field, values);

    return isAvailableForCountry && isAvailableForFieldValues;
  };

  return { hasField };
};
