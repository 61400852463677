import { Dispatch, SetStateAction, SyntheticEvent, FocusEventHandler } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

export type DatePickerPicks = 'default' | 'months' | 'years' | 'datetime';

export type DateTransform = (
  dates: [Date | null, Date | null] | Date | null,
) => [Date | null, Date | null] | Date | null;

export type BaseDatePickerProps = Omit<ReactDatePickerProps, 'selectsRange' | 'onChange' | 'value'> & {
  className?: string;
  customPeriods?: Array<{ name: string; dates: [Date, Date] }>;
  'data-cy'?: string;
  dateFormat?: string;
  disabled?: boolean;
  endDate?: Date | null;
  errorMessage?: string | null;
  hasError?: boolean;
  hasPreDefinedPeriods?: boolean;
  id?: string;
  tooltip?: string | null;
  label?: string;
  maxDate?: Date | null;
  minDate?: Date | null;
  name?: string;
  onCalendarClose?: () => void;
  onClickPeriod?: (period: string | PreDefinedPeriods) => void;
  periods?: PreDefinedPeriods[];
  picks?: DatePickerPicks;
  readOnly?: boolean;
  selected?: Date | null;
  selectedPeriod?: string | PreDefinedPeriods;
  size?: 'lg';
  showPeriodBadge?: boolean;
  startDate?: Date | null;
  showHeader?: boolean;
  transformDate?: DateTransform;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

export type DatePickerProps = BaseDatePickerProps &
  (
    | {
        selectsRange?: false;
        onChange?: (date: Date | null, event?: SyntheticEvent<unknown, Event>) => void;
      }
    | {
        selectsRange: true;
        onChange?: (date: [Date | null, Date | null], event?: SyntheticEvent<unknown, Event>) => void;
      }
  );

export enum PreDefinedPeriods {
  Today = 'common.today',
  Yesterday = 'common.yesterday',
  CurrentWeek = 'common.this_week',
  LastWeek = 'common.last_week',
  CurrentMonth = 'common.this_month',
  LastMonth = 'common.last_month',
  LastYear = 'common.last_year',
  Last7Days = 'common.last_7_days',
  Last30Days = 'common.last_30_days',
  Custom = 'common.custom',
}

export type DatePickerContext = Pick<
  DatePickerProps,
  | 'customPeriods'
  | 'hasError'
  | 'hasPreDefinedPeriods'
  | 'maxDate'
  | 'minDate'
  | 'onClickPeriod'
  | 'periods'
  | 'picks'
  | 'placeholderText'
  | 'selectedPeriod'
  | 'showPeriodBadge'
  | 'size'
> & {
  setSelectedPeriod?: Dispatch<SetStateAction<DatePickerContext['selectedPeriod'] | PreDefinedPeriods>>;
} & (
    | {
        selectsRange?: false;
        onChange?: (date: Date | null, event?: SyntheticEvent<unknown, Event>) => void;
      }
    | {
        selectsRange: true;
        onChange?: (date: [Date | null, Date | null], event?: SyntheticEvent<unknown, Event>) => void;
      }
  );
