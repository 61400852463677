import { CountryCode } from '@utils/types/countries';
import { FieldValues } from 'react-hook-form';
import { FormField } from '../form.types';
import { isFieldAvalaibleForCountry } from './isFieldAvalaibleForCountry';
import { isFieldAvalaibleForFieldValues } from './isFieldAvalaibleForFieldValues';

export const getAvailableFields = <T extends FormField<FieldValues>>(
  fields: T[],
  values: Partial<FieldValues>,
  country?: CountryCode | null,
): T[] => {
  return fields.filter(
    (field) => isFieldAvalaibleForCountry(field, country) && isFieldAvalaibleForFieldValues(field, values),
  );
};
