import React, { useMemo } from 'react';
import { RadioButtons } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import compact from 'lodash/compact';
import { ExportFormData } from 'pages/Export/export.types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

export const PointsPerLineInput = () => {
  const { control } = useFormContext<ExportFormData>();
  const { t } = useTranslation();
  const sample = useWatch<ExportFormData, 'sample'>({ control, name: 'sample' });
  const measureTypes = useWatch<ExportFormData, 'measureTypes'>({ control, name: 'measureTypes' });

  const options = useMemo(
    () =>
      compact([
        sample === '10mn' && { label: `6 ${t('monitoring.export.points_per_line')}`, value: 6 },
        sample === '5mn' && { label: `12 ${t('monitoring.export.points_per_line')}`, value: 12 },
        { label: `1 ${t('monitoring.export.point_per_line')}`, value: 1 },
      ]),
    [sample, t],
  );

  const disabled = useMemo(() => {
    const filteredMeasureTypes = measureTypes.filter(
      (type) => !['spot_epex', 'spot_imrp', 'spot_nordpool', 'positive_imbalance', 'negative_imbalance'].includes(type),
    );

    return filteredMeasureTypes.length > 1;
  }, [measureTypes]);

  if (options.length <= 1) return null;

  return (
    <Controller
      name="line_format"
      control={control}
      render={({ field }) => (
        <RadioButtons
          name="line_format"
          options={options}
          selected={field.value}
          onChange={(value) => field.onChange(value)}
          disabled={disabled}
        />
      )}
    />
  );
};
