import React, { useEffect, useState } from 'react';
import { Restricted } from '@components/Restricted';
import { Button } from '@GDM/Button';
import { IconNames } from '@GDM/Icon';
import { Modal } from '@GDM/Modal';
import { Text } from '@GDM/Text';
import { put, patch, useRequest } from '@hooks/useRequest';
import { MeterInvoice } from '@utils/types/meter-invoice';
import { Permission } from '@utils/types/permission';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { InvoiceInformationDefinitionList } from '../InvoiceInformationDefinitionList';

const methodMap = {
  put,
  patch,
};

export const InvoiceDestructiveActionButton = ({
  invoice,
  path,
  className,
  confirmationText,
  submitButtonInfo,
  warningText,
  permissions,
  modalTitle,
  'data-cy': dataCy,
  method = 'put',
  icon = 'XOctagon',
}: {
  invoice: MeterInvoice;
  path: string;
  confirmationText: string;
  modalTitle: string;
  warningText?: string;
  submitButtonInfo: string;
  className?: string;
  permissions: Permission[];
  'data-cy'?: string;
  method?: 'put' | 'patch';
  icon?: IconNames;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { updateInvoice, updateInvoices } = useInvoicesContext();
  const request = useRequest<MeterInvoice>(path, methodMap[method], true);

  const handleModalSubmit = () => {
    request.execute?.();
  };

  const toggle = () => setModalIsOpen((prevOpen) => !prevOpen);

  useEffect(() => {
    if (request.loaded && request.data) {
      if (Array.isArray(request.data)) {
        updateInvoices(request.data);
      } else {
        updateInvoice(request.data);
      }
      setModalIsOpen(false);
    }
  }, [request.loaded, request.data, updateInvoice, updateInvoices]);

  return (
    <Restricted permissions={permissions}>
      <Modal
        isOpen={modalIsOpen}
        toggle={toggle}
        header={modalTitle}
        saveButtonText="common.confirm"
        submitAction={handleModalSubmit}
        isLoading={request.loading}
        data-cy={`${dataCy}-modal`}
      >
        <Text text={confirmationText} className="text-wrap" />

        <InvoiceInformationDefinitionList className="my-3" invoice={invoice} />

        {warningText && <Text type="normal" text={warningText} className="text-wrap" />}

        {request.error && <Text type="danger" text={request?.error?.code || 'errors.unknown_error'} />}
      </Modal>

      <Button
        floating
        variant="secondary"
        icon={icon}
        onClick={toggle}
        className={className}
        tooltip={submitButtonInfo}
        data-cy={`${dataCy}-button-${invoice.uuid}`}
      />
    </Restricted>
  );
};
