import React from 'react';
import { Chart } from '@GDM/Chart/Chart';
import styles from '../custom-reporting-report.module.scss';
import { ChartProps } from './types';

const ReportCharts = ({ chartsProps }: { chartsProps: ChartProps[] }) => {
  return (
    <div className={styles['chart-grid']}>
      {chartsProps.map(({ metric, ...props }) => (
        <Chart {...props} key={metric} />
      ))}
    </div>
  );
};

export default ReportCharts;
