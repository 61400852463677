import { ValidFlags } from '../icon.types';
import At from './at.svg';
import Be from './be.svg';
import Bf from './bf.svg';
import Bg from './bg.svg';
import Bzh from './bzh.svg';
import Ch from './ch.svg';
import Cl from './cl.svg';
import De from './de.svg';
import Do from './do.svg';
import Es from './es.svg';
import Eu from './eu.svg';
import Fi from './fi.svg';
import Fr from './fr.svg';
import Gb from './gb.svg';
import Gr from './gr.svg';
import Hn from './hn.svg';
import Hr from './hr.svg';
import Ie from './ie.svg';
import It from './it.svg';
import Kz from './kz.svg';
import Me from './me.svg';
import Mx from './mx.svg';
import Nl from './nl.svg';
import Pl from './pl.svg';
import Pt from './pt.svg';
import Us from './us.svg';
import Uy from './uy.svg';
import Xx from './xx.svg';
import Za from './za.svg';

/**
 * Want to add a new flag?
 * Take it from here: https://github.com/hampusborgos/country-flags/tree/main/svg
 */
export const COUNTRIES: Record<ValidFlags, string> = {
  AT: At,
  BE: Be,
  BF: Bf,
  BG: Bg,
  BZH: Bzh,
  CH: Ch,
  CL: Cl,
  DE: De,
  DO: Do,
  ES: Es,
  EU: Eu,
  FI: Fi,
  FR: Fr,
  GR: Gr,
  HN: Hn,
  HR: Hr,
  IE: Ie,
  IT: It,
  KZ: Kz,
  ME: Me,
  MX: Mx,
  NL: Nl,
  PL: Pl,
  PT: Pt,
  UK: Gb,
  US: Us,
  UY: Uy,
  XX: Xx,
  ZA: Za,
};
