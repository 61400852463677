export const parseRevenuesNumberValues = (revenues: {
  quantity: string;
  unit_price: string;
}): {
  quantity: number | null;
  unit_price: number | null;
} => {
  const quantity = parseFloat(revenues.quantity.replace(/,/g, '.'));
  const unit_price = parseFloat(revenues.unit_price.replace(/,/g, '.'));

  return {
    quantity: (isNaN(quantity) ? null : quantity) ?? null,
    unit_price: (isNaN(unit_price) ? null : unit_price) ?? null,
  };
};
