import React from 'react';
import { Button } from '@GDM/Button';
import useTranslation from '@hooks/useTranslation';
import styles from '../new-simulation.module.scss';

export const WindConstraintHeader = ({
  index,
  handleRemove,
}: {
  index: number;
  handleRemove: (id: number) => () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['wind-constraint-header']}>
      <div className={styles['wind-constraint-title']}>{t('pricing.constraint', { index: index + 1 })}</div>
      <div className={styles['wind-constraint-actions']}>
        <Button
          icon="Trash2"
          variant="secondary"
          title="common.delete"
          onClick={handleRemove(index)}
          className="ml-1"
          floating
        />
      </div>
    </div>
  );
};
