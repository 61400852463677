import React from 'react';
import { useInstallationsContext } from '@context/installations.context';
import { useUnavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { Select } from '@GDM/forms';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { useFormContext, Controller } from 'react-hook-form';
import { CreateModalForm } from '../../CreateModal/types';

export const InstallationInput = () => {
  const { control, setValue } = useFormContext<CreateModalForm>();
  const { selectedUnavailability } = useUnavailabilitiesContext();
  const { installations } = useInstallationsContext();

  const options: Option[] =
    installations?.map((installation) => ({
      label: installation.name,
      value: installation.uuid,
    })) || [];

  return (
    <Controller
      control={control}
      name="installation_uuid"
      render={({ field: { value, onChange } }) => (
        <Select
          isDisabled={!!selectedUnavailability}
          label="common.installation"
          options={options.sort(sortOptionsByLabelAsc)}
          isMulti={false}
          onChange={(option) => {
            const installation_uuid = option?.value;
            onChange(installation_uuid);

            // fill p max input as installation p max
            const installation = installations?.find((installation) => installation.uuid == installation_uuid);
            if (!!installation && 'p_max' in installation)
              setValue(
                'p_max',
                installation['p_max']
                  ? (installation['p_max'] as number) / 1000 // to convert to MW
                  : null,
              );
          }}
          selectedOption={value}
        />
      )}
    />
  );
};
