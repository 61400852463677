import React from 'react';
import { RadioButtons } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { Controller, useFormContext } from 'react-hook-form';
import { SimulationForm } from '../../utils/simulations.types';

export const NoStrikeToggle = () => {
  const form = useFormContext<SimulationForm>();

  const options: Option<SimulationForm['no_strike']>[] = [
    { label: 'pricing.project.no_strike', value: 'no_strike' },
    { label: 'pricing.project.dynamic_stop_strike_tag', value: 'dynamic' },
    { label: 'pricing.project.fixed_stop_strike', value: 'fixed' },
  ];

  return (
    <Controller
      name="no_strike"
      control={form.control}
      render={({ field }) => (
        <div>
          <RadioButtons
            size="lg"
            label="pricing.project.interruption"
            options={options}
            selected={field.value}
            onChange={(value) => field.onChange(value)}
          />
        </div>
      )}
    />
  );
};
