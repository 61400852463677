import React from 'react';
import { BookProvider } from '@components/Books/BookProvider';
import { InstallationProvider } from '@components/Installations/InstallationProvider';
import { Restricted } from '@components/Restricted';
import LocaleContext from '@context/locale.context';
import { unavailabilitiesContext } from '@context/UnavailabilitiesContext';
import { Alert } from '@GDM/Alert';
import Page from '@pages/Page';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from '@utils/types/user';
import { CancelModal } from './CancelModal';
import { CancelModalProvider } from './CancelModal/CancelModalProvider';
import { CreateModal } from './CreateModal';
import { CreateModalProvider } from './CreateModal/CreateModalProvider';
import { UnavailabilityFiltersProvider } from './Filters/Provider';
import { Header } from './Header';
import { UnavailabilityProvider } from './UnavailabilityProvider';
import { UnavailabilityTable } from './UnavailabilityTable';

type UnavailabilitiesProps = {
  user: User;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Unavailabilities = (props: UnavailabilitiesProps) => {
  if (!props.user.permissions.includes('display:unavailability'))
    return (
      <LocaleContext.Provider value={props.user?.locale}>
        <div className="ta:center">
          <Alert label="monitoring.unavailabilities.warnings.page_is_not_accessible" variant="warning" />
        </div>
      </LocaleContext.Provider>
    );

  return (
    <QueryClientProvider client={queryClient}>
      <InstallationProvider type="operational" withMeterInfo>
        <BookProvider>
          <UnavailabilityProvider>
            <UnavailabilityFiltersProvider>
              <CreateModalProvider>
                <CancelModalProvider>
                  <unavailabilitiesContext.Consumer>
                    {(context) => (
                      <Page
                        user={props.user}
                        locale={props.user.locale}
                        title="common.unavailabilities"
                        header={<Header />}
                        isLoading={context.loading}
                        useQueryClient={false}
                      >
                        <Restricted permissions={['display:unavailability']}>
                          <CreateModal />
                          <CancelModal />
                          <UnavailabilityTable />
                        </Restricted>
                      </Page>
                    )}
                  </unavailabilitiesContext.Consumer>
                </CancelModalProvider>
              </CreateModalProvider>
            </UnavailabilityFiltersProvider>
          </UnavailabilityProvider>
        </BookProvider>
      </InstallationProvider>
    </QueryClientProvider>
  );
};
