import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { doPeriodsOverlap } from '@utils/date/doPeriodsOverlap';
import isBuyer from '@utils/filters/isBuyer';
import isBuySell from '@utils/filters/isBuySell';
import isContractSubType from '@utils/filters/isContractSubType';
import isContractType from '@utils/filters/isContractType';
import { isDateStatus } from '@utils/filters/isDateStatus';
import isMarketFuture from '@utils/filters/isMarketFuture';
import isRemit from '@utils/filters/isRemit';
import matchClassification from '@utils/filters/matchClassification';
import matchDeliveryProfile from '@utils/filters/matchDeliveryProfile';
import matchInstallationName from '@utils/filters/matchInstallationName';
import { Contract } from '@utils/types/contract';
import cloneDeep from 'lodash/cloneDeep';

export const filterContracts = (filters: ContractsFiltersType, contracts: Contract[]) => {
  let clonedContracts = cloneDeep(contracts);

  if (filters.installation_name && filters.installation_name.length > 0) {
    clonedContracts = clonedContracts.filter((contract) => {
      return filters.installation_name.includes(contract.installation.name);
    });
  }

  if (filters.installation_country) {
    clonedContracts = clonedContracts.filter(
      (contract) => filters.installation_country === contract.installation.country,
    );
  }

  if (filters.contract_nb && filters.contract_nb.length > 0) {
    clonedContracts = clonedContracts.filter((contract) => {
      return filters.contract_nb.includes(contract.contract_nb);
    });
  }

  if (filters.card_i && filters.card_i.length > 0) {
    clonedContracts = clonedContracts.filter((contract) => {
      return filters.card_i.includes(contract.card_i);
    });
  }

  clonedContracts = clonedContracts.filter((contract) => {
    const dateOverlap =
      (filters.dates[0] &&
        filters.dates[1] &&
        doPeriodsOverlap(
          [new Date(contract.start_date), new Date(contract.end_date)],
          [filters.dates[0], filters.dates[1]],
        )) ||
      (!filters.dates[0] && !filters.dates[1]);

    const isMarketFutureContract = isMarketFuture(contract, filters);
    const isContractTypeContract = isContractType(contract.type, filters);
    const isContractSubTypeContract = isContractSubType(contract, filters);
    const isRemitContract = isRemit(contract, filters);
    const isBuyerContract = isBuyer(contract, filters);
    const isDateStatusContract = isDateStatus(contract, filters.date_status);
    const booksMatch =
      filters.books.length === 0 ||
      matchInstallationName(
        filters.books.flatMap((book) => book.installation_names),
        contract,
      );
    const filterStatusMatch = filters.status ? contract.status === filters.status : true;
    const filtersDirectionMatch = isBuySell(contract, filters);
    const filtersClassificationMatch = matchClassification(contract, filters);
    const filtersDeliveryProfileMatch = matchDeliveryProfile(contract, filters);

    return (
      isMarketFutureContract &&
      isContractTypeContract &&
      isContractSubTypeContract &&
      isRemitContract &&
      isBuyerContract &&
      dateOverlap &&
      isDateStatusContract &&
      filterStatusMatch &&
      filtersDirectionMatch &&
      filtersClassificationMatch &&
      filtersDeliveryProfileMatch &&
      booksMatch
    );
  });

  return clonedContracts;
};
