import { RadioOption } from '@GDM/forms';
import { TypeFilter } from '@pages/Portfolio/portfolio.definitions';

export const energyOptions: RadioOption<TypeFilter>[] = [
  { label: 'common.all', value: 'all' },
  { label: 'common.wind.eolian', value: 'wind' },
  { label: 'common.pv.short', value: 'solar' },
  { label: 'common.hydro.short', value: 'hydro' },
  { label: 'common.other', value: 'other' },
];
