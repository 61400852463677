import React from 'react';
import type { Option } from '@utils/types/common-types';
import type { Contract } from '@utils/types/contract';
import { FormSectionLayout, type ContractFormSectionProps } from '../../components/FormSection/FormSectionLayout';
import { ListInput } from '../../components/Inputs/List';

export const Scheme = ({ title, readOnly, form: { control } }: ContractFormSectionProps) => {
  const options: Option<Contract['scheme']>[] = [
    { value: 'supply_only', label: 'sales_management.supply_only' },
    { value: 'unique_contract', label: 'sales_management.unique_contract' },
  ];

  return (
    <FormSectionLayout
      title={title}
      body={
        <div className="p-3">
          <ListInput
            control={control}
            name="scheme"
            label="sales_management.scheme"
            readOnly={readOnly}
            options={options}
            rules={{ required: true }}
            helpBox={{
              title: 'sales_management.scheme',
              text: 'sales_management.scheme_info',
            }}
          />
        </div>
      }
    />
  );
};
