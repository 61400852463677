import type { RadioOption } from '@GDM/forms';
import type dayjs from 'dayjs';
import type { NegativePricesFilters } from '../negative-prices.types';

export const GRANULARITIES = ['hourly', 'daily', 'monthly'] as const;
export type Granularity = typeof GRANULARITIES[number];

export const baseGranularityOptions: RadioOption<Granularity>[] = [
  { label: 'common.daily', value: 'daily' },
  { label: 'common.monthly', value: 'monthly' },
];

export const tableGranularityOptions: RadioOption<Granularity>[] = [
  { label: 'common.hourly', value: 'hourly' },
  ...baseGranularityOptions,
];

export const granularityOptions: Record<NegativePricesFilters['displayMode'], RadioOption<Granularity>[]> = {
  chart: baseGranularityOptions,
  table: tableGranularityOptions,
};

export const granularityFormat: Record<Granularity, string> = {
  hourly: 'YYYY-MM-DD HH:mm',
  daily: 'YYYY-MM-DD',
  monthly: 'YYYY-MM',
};

export const granularityChartFormat: Record<Granularity, string> = {
  hourly: granularityFormat.hourly,
  daily: granularityFormat.daily,
  monthly: 'MMM YYYY',
};

export const dayjsGranularityMap: Record<Granularity, dayjs.ManipulateType> = {
  hourly: 'hour',
  daily: 'day',
  monthly: 'month',
};
