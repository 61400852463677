import { FieldValues } from 'react-hook-form';
import { FormField } from '../form.types';

export const getSectionFields = <T extends FormField<FieldValues>>(
  section: string,
  fields: T[],
  sectionMap: Map<T['name'], string>,
): T[] => {
  const internalFields: T[] = [];

  for (const [fieldKey, fieldSection] of sectionMap.entries()) {
    if (fieldSection === section) {
      const field = fields.find((f) => f.name === fieldKey);

      if (field) {
        internalFields.push(field);
      }
    }
  }

  return internalFields;
};
