import { Contract, ContractType } from '@utils/types/contract';
import { MeterInvoice } from '@utils/types/meter-invoice';

const admissibleRemitContractTypes: ContractType[] = ['ContractOa', 'ContractCr', 'ContractSoa', 'ContractCppa'];

export const filterRemitContracts = (contract: Contract, _: number, contracts: Contract[]): boolean => {
  if (!admissibleRemitContractTypes.includes(contract.type)) return false;
  if (!contract?.seller?.acer_code) return false;
  if (contract?.p_max < 10_000) return false;

  if (contract?.end_date < new Date().toISOString().split('T')[0]) return false;

  const hasSimilarNextContract = contracts.some(
    (other_contract) =>
      other_contract.installation.uuid === contract.installation.uuid &&
      other_contract.type === contract.type &&
      other_contract.start_date > contract.start_date,
  );
  if (hasSimilarNextContract) return false;

  return true;
};

export const filterRemitInvoices = (invoice: MeterInvoice): boolean => {
  if (!admissibleRemitContractTypes.includes(invoice.contract_type)) return false;
  if (invoice.installation_p_max < 10_000) return false;

  return true;
};
