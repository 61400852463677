import React, { useMemo } from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button';
import { Spinner } from '@GDM/Spinner';
import { Locale } from '@utils/types/common-types';
import { Timezone } from '@utils/types/countries';
import classNames from 'classnames';
import dayjs from 'dayjs';
import HighchartsChart from 'highcharts-react-official';
import more from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highstock';
import brokenAxis from 'highcharts/modules/broken-axis';
import exportData from 'highcharts/modules/export-data';
import exporting from 'highcharts/modules/exporting';
import noData from 'highcharts/modules/no-data-to-display';
import merge from 'lodash/merge';
import styles from './chart.module.scss';
import { ChartRefContent } from './chart.types';
import { getDefaultOptions } from './default-options';
import { LOCALES } from './locales';

more(Highcharts);
brokenAxis(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
noData(Highcharts);

declare let window: Window & { moment: typeof dayjs };

window.moment = dayjs;

/**
 * @param chartType default is `column`;
 */
export const Chart = React.forwardRef<
  ChartRefContent,
  {
    className?: string;
    chartType?: 'line' | 'column' | 'area' | 'spline' | 'scatter' | 'bar' | 'waterfall';
    constructorType?: keyof typeof Highcharts;
    locale?: Locale;
    timezone?: Timezone;
    series?: Highcharts.Options['series'];
    options?: Highcharts.Options;
    hideLegend?: boolean;
    immutable?: boolean;
    oneToOne?: boolean;
    isLoading?: boolean;
    'data-cy'?: string;
    handleDownloadCsv?: () => void;
  }
>(
  (
    {
      chartType,
      className,
      hideLegend,
      isLoading,
      locale,
      timezone,
      options,
      series,
      constructorType,
      immutable,
      oneToOne,
      'data-cy': dataCy,
      handleDownloadCsv,
    },
    ref,
  ) => {
    const user = useUser();
    const lang = locale || user.locale;

    if (LOCALES[lang]) Highcharts.setOptions({ lang: LOCALES[lang] });

    const chartOptions = useMemo(
      () => ({
        ...merge(getDefaultOptions(timezone || user.timezone, user.locale, isLoading), options),
        series,
      }),
      [isLoading, options, series, timezone, user.locale, user.timezone],
    );

    if (chartType && chartOptions?.chart) chartOptions.chart.type = chartType;
    if (hideLegend && chartOptions?.legend) chartOptions.legend.enabled = false;

    return (
      <div
        className={classNames(styles.container, { [styles['is-loading']]: isLoading }, className)}
        style={{ height: handleDownloadCsv ? 'auto' : options?.chart?.height || 'auto' }}
        data-cy={dataCy}
      >
        {handleDownloadCsv && (
          <Button
            className={styles['download-button']}
            onClick={handleDownloadCsv}
            icon="Download"
            variant="primary-2"
            size="xxs"
            tooltip="common.download_csv"
          />
        )}
        <HighchartsChart
          ref={ref}
          highcharts={Highcharts}
          options={chartOptions}
          constructorType={constructorType}
          immutable={immutable}
          oneToOne={oneToOne}
        />
        {isLoading && (
          <div className={styles['loading-container']}>
            <Spinner />
          </div>
        )}
      </div>
    );
  },
);
