import React, { useState } from 'react';
import { DropDownButton } from '@GDM/Button';
import { FilterContainer, Filters } from '@GDM/Filters';
import { Select } from '@GDM/forms';
import { Option } from '@utils/types/common-types';
import { useProjectContext } from '../utils/project.context';
import { useSimulations } from '../utils/useSimulations';
import { DeleteSimulation } from './DeleteSimulation';
import { DownloadCsv } from './DownloadCsv';
import { DownloadPdf } from './DownloadPdf';
import styles from './simulation-actions.module.scss';

export const SimulationActions = () => {
  const project = useProjectContext();
  const name = window.location.pathname.split('/').filter(Boolean).pop() ?? '';
  const simulationRequest = useSimulations(name);
  const simulationId =
    new URLSearchParams(window.location.search).get('ppa_simulation_id') ?? project?.simulation?.id?.toString();
  const [selectedSimulation, setSelectedSimulation] = useState<string | null>(null);

  if (project.isBook) return null;

  if (!selectedSimulation && simulationId) {
    setSelectedSimulation(simulationId);
  }

  const handleChange = (option: Option | null) => {
    setSelectedSimulation(option?.value ?? null);

    if (option?.value) {
      window.location.href = `/v2/ppa_projects/${name}?ppa_simulation_id=${option?.value}`;
    }
  };

  const options: Option[] =
    simulationRequest.data?.map((simulation) => ({
      label: simulation.name,
      value: simulation.id.toString(),
    })) || [];

  return (
    <div className={styles['actions-container']}>
      <Filters className="px-0 mr-auto">
        <FilterContainer size="fit">
          <div className={styles['select-container']}>
            <Select
              full
              options={options}
              isLoading={simulationRequest.loading}
              selectedOption={selectedSimulation}
              onChange={handleChange}
            />
          </div>
        </FilterContainer>
      </Filters>

      <div className={styles.actions}>
        <DeleteSimulation />
        <DropDownButton icon="Download" label="common.download" variant="primary-2" size="xxs">
          <DownloadCsv />
          <DownloadPdf />
        </DropDownButton>
      </div>
    </div>
  );
};
