import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { MeterInvoice } from '@utils/types/meter-invoice';

export const InstallationInfoExternalService = ({
  invoice,
  source,
}: {
  source: MeterInvoice['external_service'];
  invoice: MeterInvoice;
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex pl-3">
      <div className="mr-2">
        <b>
          {source === 'cegedim'
            ? t('sales_management.cegedim_username')
            : source === 'edf_oa'
            ? t('sales_management.edf_oa_username')
            : null}
        </b>
      </div>
      <div>{invoice.contract_credential_username}</div>
    </div>
  );
};
