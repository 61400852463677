import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { Contract } from '@utils/types/contract';

export default function isMarketFuture(contract: Contract, filters: ContractsFiltersType): boolean {
  // Filter by spot
  if (filters.market_future_id && filters.market_future_id === 'SPOT') {
    // filter by spot here
    const normalSubPeriods = contract.contract_sub_periods.filter((csp) => !csp.phase_type);
    const spotSubPeriods = normalSubPeriods.filter((csp) => csp.price_type === 'spot');

    return spotSubPeriods.length > 0;
  }

  // Filter by given market future
  const subPeriodsMarketFutures = contract.contract_sub_periods.map((csp) => csp.market_future?.id).filter((id) => id);

  if (filters.market_future_id && !subPeriodsMarketFutures.includes(filters.market_future_id)) {
    return false;
  }

  return true;
}
