const getDeviationMarkerColor = (value: number): string => {
  if (value === 0) {
    return 'var(--grey-lightest)';
  }

  if (value > 0) {
    return 'var(--primary-1)';
  }

  if (value <= -1) {
    return 'var(--secondary)';
  }

  if (value < 0) {
    return 'var(--chart-orange)';
  }

  return 'var(--primary-2)';
};

export default getDeviationMarkerColor;
