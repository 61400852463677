import { isANumber } from '@utils/isANumber';
import { Locale } from '@utils/types/common-types';

export const formatCurrency = (
  value?: number | null,
  locale: Locale = 'en',
  notation: Intl.NumberFormatOptions['notation'] = 'compact',
  options?: Intl.NumberFormatOptions,
): string | null => {
  return isANumber(value)
    ? new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR',
        notation,
        ...options,
      }).format(value)
    : null;
};
