import { createContext } from 'react';
import IndexFormula from '@utils/types/IndexFormula';

export type IndexFormulaContext = {
  formulas: IndexFormula[];
  edfFormulas: IndexFormula[];
};

export const indexFormulaContext = createContext<IndexFormulaContext>({
  formulas: [],
  edfFormulas: [],
});
