import React, { useRef } from 'react';
import { MandateFormType } from '@context/AdMeterForm.context';
import { Button } from '@GDM/Button';
import { Input } from '@GDM/forms';
import { Col } from '@GDM/layout';
import useTranslation from '@hooks/useTranslation';
import { FormMandatesProps } from '../FormMandates';

const UploadButton = ({ disabled }: { disabled: boolean }) => {
  return (
    <Button
      variant="primary-2"
      size="xs"
      disabled={disabled}
      className="ml-2"
      icon="Upload"
      text="admin.installations.insert_mandate"
    />
  );
};

const UploadMandateInput = ({
  form,
  setFormData,
  disabled,
  setValuesHasChanged,
}: {
  form: FormMandatesProps['selectedMandate'];
  setFormData: (key: keyof MandateFormType, value: string | File | null) => void;
  disabled: boolean;
  setValuesHasChanged: (value: boolean) => void;
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const file = form?.mandateFile;
  const { t } = useTranslation();

  const handleUploadButtonClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target?.files?.[0]) {
      setFormData('mandateFile', e.target?.files?.[0]);
      setValuesHasChanged(true);
    }
  };

  return (
    <>
      <Col md={12}>
        <div className="d-flex my-3" onClick={handleUploadButtonClick}>
          <Input flexFull type="text" placeholder={file?.name ?? 'Inserer un mandat signé'} disabled={disabled} />

          <UploadButton disabled={disabled} />

          <input
            disabled={disabled}
            type="file"
            ref={fileRef}
            hidden
            accept=".pdf"
            onChange={handleFileUpload}
            data-cy="mandate-file-input"
          />
        </div>

        {form?.mandateFile ? (
          <p className="mt-1" dangerouslySetInnerHTML={{ __html: t('admin.installations.insert_mandate_warning') }} />
        ) : null}
      </Col>
    </>
  );
};

export default UploadMandateInput;
