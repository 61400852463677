import React, { useState } from 'react';
import { useConsumeContracts } from '@context/contracts.context';
import { Checkbox } from '@GDM/forms';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { ContractTableFilters } from '../contracts.types';

export const TableSettings = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { infoFilters, setInfoFilters } = useConsumeContracts();

  // Some accounts have a lot of contracts, so we use setTimeout to 'make' the request handle like an
  // async function, which allows us to set a loading state.
  const updateTableFilters = throttle((filter: keyof ContractTableFilters, value: boolean) => {
    setIsLoading(true);
    setTimeout(() => {
      setInfoFilters({ [filter]: value });
      setIsLoading(false);
    });
  }, 1000);

  return (
    <div className={classNames('d-flex flex-column gap-1', { unavailable: isLoading })}>
      <Checkbox
        label="common.country"
        checked={infoFilters.country}
        onChange={(v) => updateTableFilters('country', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="common.client_id"
        checked={infoFilters.clientId}
        onChange={(v) => updateTableFilters('clientId', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="sales_management.classification"
        checked={infoFilters.classification}
        onChange={(v) => updateTableFilters('classification', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="sales_management.commissioning_date"
        checked={infoFilters.commissioningDate}
        onChange={(v) => updateTableFilters('commissioningDate', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="sales_management.card_i"
        checked={infoFilters.cardI}
        onChange={(v) => updateTableFilters('cardI', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="common.capacity"
        checked={infoFilters.p_max}
        onChange={(v) => updateTableFilters('p_max', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="sales_management.seller"
        checked={infoFilters.seller}
        onChange={(v) => updateTableFilters('seller', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="sales_management.seller_siren"
        checked={infoFilters.sellerSiren}
        onChange={(v) => updateTableFilters('sellerSiren', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="sales_management.buyer"
        checked={infoFilters.buyer}
        onChange={(v) => updateTableFilters('buyer', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="common.horizon"
        checked={infoFilters.horizon}
        onChange={(v) => updateTableFilters('horizon', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="sales_management.volume"
        checked={infoFilters.volume}
        onChange={(v) => updateTableFilters('volume', v.currentTarget.checked)}
        disabled={isLoading}
      />
      <Checkbox
        label="common.pricing"
        checked={infoFilters.pricing}
        onChange={(v) => updateTableFilters('pricing', v.currentTarget.checked)}
        disabled={isLoading}
      />
    </div>
  );
};
