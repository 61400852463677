import { useQuery } from '@tanstack/react-query';
import { get_inverters_data_v2_dashboard_meter_index_path } from '@utils/routes';
import { DataPeriod } from '@utils/types/dataPeriod';
import { InverterData } from '@utils/types/inverterData';
import dayjs from 'dayjs';

export const useInverterData = (
  meterName: string,
  start: Date | null,
  end: Date | null,
  isBook = false,
  dataPeriod?: DataPeriod,
) => {
  return useQuery({
    queryKey: ['get_inverter_data', meterName, start, end, isBook, dataPeriod],
    queryFn: async () => {
      const parsedStartDate = dayjs(start).format('YYYY-MM-DD HH:mm:00');
      const parsedEndDate = dayjs(end).format('YYYY-MM-DD HH:mm:00');
      const params = {
        meter: meterName,
        firstdate: parsedStartDate,
        lastdate: parsedEndDate,
        period: dataPeriod === 'minutes' ? '10mn' : dataPeriod,
      };
      const url = get_inverters_data_v2_dashboard_meter_index_path(params);
      const response = await fetch(url);
      const inverterData: InverterData | undefined = await response.json();
      const inverterAll = inverterData?.invdata?.all
        ?.map((d) => [d[0] * 1000, Number(d[1]?.toFixed(2))] as [number, number])
        .filter((d) => start && end && d[0] >= start.getTime() && d[0] <= end.getTime());

      return { inverterData, inverterAll };
    },
    enabled: !isBook && Boolean(start && end),
  });
};
