import { DataTypeFiltersForm } from '@utils/types/dataFilter';
import { DataPeriod } from '@utils/types/dataPeriod';
import type { Meter } from '@utils/types/meter';
import { TimeSeries } from '@utils/types/timeSeries';
import { UseFormReturn } from 'react-hook-form';
import type { ProductionFilters } from '../../Production';
import { ProductionTimeSeriesRecord } from '../../production.types';
import { useDataSeries } from './useDataSeries';
import { usePerformanceRatios } from './usePerformanceRatios';
import { useSeriesList } from './useSeriesList';
import { useYAxes } from './useYAxes';

/**
 * This hook sequentially gathers all the required data to establish series' data and
 * the corresponding y axes.
 */
export function useChartData({
  availability,
  data,
  dataPeriod,
  inverterAll,
  meter,
  showForecast,
  form,
  selectedMarketPriceCurve,
  marketPricesCurve,
}: {
  availability?: TimeSeries;
  data?: Partial<ProductionTimeSeriesRecord>;
  dataPeriod: DataPeriod;
  inverterAll?: TimeSeries;
  meter?: Meter;
  showForecast: boolean;
  form: UseFormReturn<DataTypeFiltersForm>;
  selectedMarketPriceCurve: ProductionFilters['selectedMarketPriceCurve'];
  marketPricesCurve: TimeSeries;
}): { series: Highcharts.SeriesOptionsType[]; yAxes: Highcharts.YAxisOptions[] } {
  const energy = meter?.installation?.energy;
  const nominalPower = meter?.nominal_power;

  const { performanceRatios } = usePerformanceRatios(data, energy);
  const { definitions } = useSeriesList({
    data,
    dataPeriod,
    availability,
    inverterAll,
    performanceRatios,
    showForecast,
    form,
    selectedMarketPriceCurve,
    marketPricesCurve,
  });

  const { series } = useDataSeries({
    data,
    definitions,
    availability,
    inverterAll,
    hasPerformanceRatios: !!performanceRatios,
    marketPricesCurve,
  });
  const { yAxes } = useYAxes({ data, dataPeriod, performanceRatios, nominalPower, form, marketPricesCurve });

  return { series, yAxes };
}
