import React from 'react';
import { DataProvider } from './DataProvider';
import styles from './default.module.scss';
import { DirectConnection } from './DirectConnection';
import { MeterRequest } from './MeterRequest';

export const Default = () => {
  return (
    <div>
      <div className={styles.container}>
        <DataProvider />
        <DirectConnection />
      </div>

      <MeterRequest />
    </div>
  );
};
