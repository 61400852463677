import React from 'react';
import { NumberCell } from '@GDM/Table';
import { CellContext } from '@tanstack/react-table';
import { DashboardViewAllData } from '../../../portfolio.definitions';

export const PortfolioNumberCell = ({
  getValue,
}: React.PropsWithChildren<CellContext<DashboardViewAllData, unknown>>) => {
  return <NumberCell value={getValue()} fractionDigits={0} />;
};
