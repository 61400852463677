import { EnergyType } from '@utils/types/common-types';
import { commonFields } from '../config/common-fields';
import { solarFields } from '../config/solar-fields';
import { windFields } from '../config/wind-fields';
import { SimulationFormField } from './simulations.types';

export const getAllFields = (energy: EnergyType | null): Array<SimulationFormField> => {
  if (energy === 'solar') {
    return solarFields;
  }

  if (energy === 'wind') {
    return windFields;
  }

  return commonFields;
};
