import React from 'react';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { useDynamicOptions } from '@GDM/Filters';
import { sortOptionsByLabelAsc } from '@utils/sorters';
import { Option } from '@utils/types/common-types';
import { MeterInvoice } from '@utils/types/meter-invoice';
import uniqBy from 'lodash/uniqBy';
import { useInvoicesContext } from 'pages/sales-management/invoicing/invoicesContext';
import { useInvoicesFilters } from 'pages/sales-management/invoicing/invoicesFiltersContext';

const getOptions = (invoices: MeterInvoice[]): Option<MeterInvoice['contract_nb']>[] => {
  const contractNbs = invoices.map((invoice) => invoice.contract_nb);

  return uniqBy(
    contractNbs
      .filter((contractNb) => contractNb)
      .map((contractNb) => ({ value: contractNb, label: contractNb }))
      .sort(sortOptionsByLabelAsc),
    'value',
  );
};

export const ContractNbFilter = () => {
  const form = useInvoicesFilters();
  const { allInvoices, filteredInvoices } = useInvoicesContext();

  const options = useDynamicOptions(getOptions, 'contract_nb', filteredInvoices, allInvoices);

  return (
    <ControlledSelect
      control={form.control}
      name="contract_nb"
      classNamePrefix="filter-contract_nb"
      placeholder="common.filters.contract_nb"
      isClearable
      options={options}
      isMulti
      inline
    />
  );
};
