import React from 'react';
import { useFields } from '@GDM/forms';
import { Hr } from '@GDM/Hr';
import { Text } from '@GDM/Text';
import type { InstallationFormField } from '@pages/Installations/Installation/installation.types';
import type { OwnerFormField } from '../../ownerForm.types';
import { BankInput } from './fields/BankInput';
import { CityInput } from './fields/CityInput';
import { CompanyNumberInput } from './fields/CompanyNumberInput';
import { ErpCodeInput } from './fields/ErpCodeInput';
import { IbanInput } from './fields/IbanInput';
import { InvoicingEmailsInput } from './fields/InvoicingEmailsInput';
import { OwnerPicker } from './fields/OwnerPicker';
import { PostcodeInput } from './fields/PostcodeInput';
import { ShortNameInput } from './fields/ShortNameInput';
import { StreetAddressInput } from './fields/StreetAddressInput';
import { SwiftBicInput } from './fields/SwiftBicInput';
import { VatNumberInput } from './fields/VatNumberInput';

export const OwnerSection = ({ hasOwnerSelect }: { hasOwnerSelect: boolean }) => {
  const { hasField } = useFields<OwnerFormField['name'] | InstallationFormField['name']>();

  return (
    <div className="d-flex flex-column gap-3">
      {hasOwnerSelect && (
        <div className="p-3 d-flex flex-column gap-3 hide-empty">
          {hasField('owner_id') && (
            <div className="d-flex gap-3">
              <OwnerPicker />
            </div>
          )}
        </div>
      )}

      <div className="d-flex flex-column gap-3 hide-empty">
        {(hasField('street_address') ||
          hasField('postcode') ||
          hasField('vat_number') ||
          hasField('company_number') ||
          hasField('short_name') ||
          hasField('city') ||
          hasField('erp_code')) && (
          <>
            {hasField('owner_id') && <Hr />}

            <div className="p-3 pb-0 d-flex flex-column gap-3">
              <Text text="owner.company_info" className="fw:700" size="md" />

              <div className="d-flex gap-3 hide-empty">
                {hasField('company_number') && <CompanyNumberInput />}
                {hasField('short_name') && <ShortNameInput />}
                {hasField('erp_code') && <ErpCodeInput />}
              </div>

              <div className="d-flex gap-3 hide-empty">
                {hasField('street_address') && <StreetAddressInput />}
                {hasField('postcode') && <PostcodeInput />}
              </div>
              <div className="d-flex gap-3 hide-empty">
                {hasField('city') && <CityInput />}
                {hasField('vat_number') && <VatNumberInput />}
              </div>
            </div>
          </>
        )}

        {hasField('invoicing_emails') && (
          <div className="p-3 pt-0">
            <InvoicingEmailsInput />
          </div>
        )}
      </div>

      <div className="d-flex flex-column gap-3 hide-empty">
        {(hasField('bank') || hasField('swift_bic') || hasField('iban')) && (
          <>
            <Hr />

            <div className="p-3 pt-0 d-flex flex-column gap-3">
              <Text text="owner.bank_info" className="fw:700" size="md" />

              <div className="d-flex gap-3 hide-empty">
                {hasField('bank') && <BankInput />}
                {hasField('swift_bic') && <SwiftBicInput />}
                {hasField('iban') && <IbanInput />}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
