import { RevenueContextType } from '../../revenue.types';

type Condition =
  | RevenueContextType['resolution']
  | RevenueContextType['groupBy']
  | RevenueContextType['displayMode']
  | 'forecast';
export type HelpInfo = { conditions: Condition[]; translationString: string };

export const INFO_BOX_CONTENT: HelpInfo[] = [
  { conditions: ['forecast', 'table'], translationString: 'monitoring.installation.revenue.info.forecast' },
  { conditions: ['table'], translationString: 'monitoring.installation.revenue.info.missing_data' },
  { conditions: ['monthly'], translationString: 'monitoring.installation.revenue.info.monthly_estimation' },
  {
    conditions: ['monthly'],
    translationString: 'monitoring.installation.revenue.info.business_plan',
  },
  {
    conditions: ['monthly', 'market'],
    translationString: 'monitoring.installation.revenue.info.business_plan',
  },
  {
    conditions: ['monthly', 'market'],
    translationString: 'monitoring.installation.revenue.info.revenue_type',
  },
  {
    conditions: ['monthly', 'contract_type'],
    translationString: 'monitoring.installation.revenue.info.contract_type',
  },
  {
    conditions: ['daily', 'contract_type'],
    translationString: 'monitoring.installation.revenue.info.contract_type',
  },
  {
    conditions: ['hourly', 'contract_type'],
    translationString: 'monitoring.installation.revenue.info.contract_type',
  },
  {
    conditions: ['monthly', 'role'],
    translationString: 'monitoring.installation.revenue.info.direction',
  },
  {
    conditions: ['daily', 'role'],
    translationString: 'monitoring.installation.revenue.info.direction',
  },
  {
    conditions: ['hourly', 'role'],
    translationString: 'monitoring.installation.revenue.info.direction',
  },
  {
    conditions: ['daily'],
    translationString: 'monitoring.installation.revenue.info.no_capa',
  },
  {
    conditions: ['daily', 'risk'],
    translationString: 'monitoring.installation.revenue.info.risk',
  },
  {
    conditions: ['hourly'],
    translationString: 'monitoring.installation.revenue.info.no_capa',
  },
  {
    conditions: ['hourly', 'risk'],
    translationString: 'monitoring.installation.revenue.info.risk',
  },
];
