import React from 'react';
import { getContract } from '@api/contracts';
import { Form } from '@pages/Contracts/Contract/Form/Form';
import { useContractForm } from '@pages/Contracts/Contract/hooks/useContractForm/useContractForm';
import { useQuery } from '@tanstack/react-query';
import type { Contract } from '@utils/types/contract';

export const SlideInForm = ({ contract }: { contract: Contract | null | undefined }) => {
  const { data: contractData, isPending } = useQuery({
    queryKey: ['contract', contract?.id],
    queryFn: async () => {
      return contract?.id ? getContract(contract.id) : null;
    },
  });

  const { sections, form, globals, queries } = useContractForm({
    contract: contractData,
    mode: 'view',
  });

  return <Form isLoading={isPending} readOnly sections={sections} globals={globals} queries={queries} form={form} />;
};
