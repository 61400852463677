import { ContractForm, ContractType } from '@utils/types/contract';
import dayjs from 'dayjs';
import { UseFieldArrayReturn, UseFormTrigger } from 'react-hook-form';
import { makeSubPeriod } from '../helpers/makeSubPeriod';

export const useInsertRemovePeriod = ({
  startDate,
  endDate,
  subPeriods,
  trigger,
  contractType,
}: {
  startDate: Date | null;
  endDate: Date | null;
  subPeriods: UseFieldArrayReturn<ContractForm, 'contract_sub_periods_attributes', 'field_internal_id'>;
  trigger: UseFormTrigger<ContractForm>;
  contractType: ContractType | null;
}) => {
  const removePeriod = (index: number) => {
    subPeriods.remove(index);
    trigger('contract_sub_periods_attributes');
  };

  const insertPeriodBefore = async (index: number) => {
    const dates = { start_date: startDate, end_date: endDate };
    const previousPeriod = subPeriods.fields[index - 1];
    const currentPeriod = subPeriods.fields[index];
    const currentStartDate = dayjs(currentPeriod.dates?.[0]).startOf('day');

    if (index > 0) {
      const previousEndDate = dayjs(previousPeriod.dates?.[1]).startOf('day');

      // Check if there's space between this period and the previous one
      if (previousEndDate.add(1, 'day').valueOf() < currentStartDate.valueOf()) {
        dates.start_date = previousEndDate.add(1, 'day').startOf('day').toDate();
        dates.end_date = currentStartDate.subtract(1, 'day').endOf('day').toDate();
      } else {
        // Otherwise just give it a length of one day (but it will clash).
        dates.start_date = currentStartDate.subtract(2, 'day').endOf('day').toDate();
        dates.end_date = currentStartDate.subtract(1, 'day').endOf('day').toDate();
      }
    } else if (index === 0 && currentStartDate.valueOf() !== dayjs(startDate).startOf('day').valueOf()) {
      // If there's no previous period, and there's space between it and the start of the contract:
      dates.end_date = currentStartDate.subtract(1, 'day').endOf('day').toDate();
    }

    if (!contractType) return;

    const newPeriod = makeSubPeriod(contractType, {
      dates: [dates.start_date || undefined, dates.end_date || undefined],
      price_model_attributes: currentPeriod?.price_model_attributes ?? {},
    });

    subPeriods?.insert(index, newPeriod);
    if (await trigger('contract_sub_periods_attributes')) trigger('contract_sub_periods_attributes');
  };

  return { removePeriod, insertPeriodBefore };
};
