import axiosInstance from '@utils/axiosInstance';
import { revenues_by_invoice_v2_invoice_path, update_revenue_v2_invoice_path } from '@utils/routes';
import { MeterInvoice, type MeterInvoiceRevenue } from '@utils/types/meter-invoice';

export const getRevenuesByInvoice = async (invoiceUuid: string) => {
  return (await axiosInstance(false).get<MeterInvoiceRevenue[]>(revenues_by_invoice_v2_invoice_path(invoiceUuid))).data;
};

export type UpdateRevenuesByInvoiceBody = {
  invoice_revenues: (Pick<MeterInvoiceRevenue, 'uuid' | 'quantity' | 'unit_price' | 'label' | 'revenue_type'> & {
    _destroy?: number;
  })[];
};

export const updateRevenuesByInvoice = async (invoiceUuid: string, revenues: UpdateRevenuesByInvoiceBody) => {
  return (await axiosInstance(false).put<MeterInvoice>(update_revenue_v2_invoice_path(invoiceUuid), revenues)).data;
};
