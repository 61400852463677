import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from '@utils/types/user';
import BooksPage from './BooksPage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Books = ({ user }: { user: User }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BooksPage user={user} />
    </QueryClientProvider>
  );
};

export default Books;
