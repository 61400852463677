import React from 'react';
import { Tab, Tabs } from '@GDM/Tabs';
import { TabIds } from './bsm.types';
import { useBSM } from './context';
import { Filters } from './Filters';
import { TabContents } from './TabContents';

export const TabContainer = () => {
  const tabs: Tab<TabIds>[] = [
    { id: 'subscriptions', name: 'subscriptions.tabs.subscriptions.title' },
    { id: 'activity_tracking', name: 'subscriptions.tabs.activity_tracking.title' },
  ];

  const { activityTracks, subscriptions, getValues, setValue } = useBSM();

  /** Make sure filters are still relevant for the current tab. */
  const checkFilters = (tab: TabIds) => {
    const [email, dates] = getValues(['email', 'dates']);

    if (!email && !dates) return;

    if (tab === 'activity_tracking' && email && !activityTracks?.some((s) => s.email === email))
      setValue('email', null);

    if (tab === 'subscriptions') {
      setValue('dates', null);
      if (email && !subscriptions?.some((s) => s.email === email)) setValue('email', null);
    }
  };

  return (
    <Tabs tabs={tabs} onTabChange={checkFilters}>
      <Filters />
      <TabContents />
    </Tabs>
  );
};
