import React, { useMemo } from 'react';
import { Restricted } from '@components/Restricted';
import { FilterContainer } from '@GDM/Filters';
import { Input, RadioButtons, Select } from '@GDM/forms';
import { Toggle } from '@GDM/Toggle';
import { Option } from '@utils/types/common-types';
import MarketPlayer from '@utils/types/market_player';
import dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import { useRevenueContext } from '../../hooks/useRevenueContext';
import { RevenueContextType } from '../../revenue.types';
import { DateSelector } from '../DateSelector';
import {
  allOwnersOption,
  chartTableOptions,
  groupByInstallationOption,
  groupByMarketOption,
  groupByOptions,
  groupByRiskOption,
  resolutionOptions,
} from './filter-options';

export const Filters = () => {
  const {
    displayMode,
    groupBy,
    owners,
    players,
    resolution,
    selectedPlayers,
    setDisplayMode,
    setEndDate,
    setGroupBy,
    setResolution,
    setSelectedPlayers,
    setStartDate,
    setTopInstallationsLimit,
    sourceType,
    topInstallationsLimit,
    currencies,
    setSelectedCurrency,
    selectedCurrency,
    setGroupByMonth,
    groupByMonth,
  } = useRevenueContext();

  const canPickPlayers = (sourceType === 'installation' || sourceType === 'book') && players && players?.length > 0;
  const canGroupByInstallation = sourceType === 'book' || sourceType === 'market_player';
  const canGroupByRisk = resolution === 'daily' || resolution === 'hourly';
  const canGroupByMarket = resolution === 'monthly';

  const categorizationOptions: Option<RevenueContextType['groupBy']>[] = [
    ...groupByOptions,
    ...(canGroupByInstallation ? [groupByInstallationOption] : []),
    ...(canGroupByMarket ? [groupByMarketOption] : []),
    ...(canGroupByRisk ? [groupByRiskOption] : []),
  ];

  const PlayerOptions: Option[] = useMemo(() => {
    const mapMarketPlayerToOption = (player: MarketPlayer): Option => ({
      label: player.short_name,
      value: player.id,
    });

    const selectablePlayers: Option[] = uniqBy(
      [...(canPickPlayers ? players.map(mapMarketPlayerToOption) : [])],
      'value',
    );

    return [...(sourceType === 'book' ? [allOwnersOption] : []), ...selectablePlayers];
  }, [sourceType, canPickPlayers, players]);

  const defaultSelectedPlayer = sourceType === 'installation' && owners?.length === 1 ? owners[0] : null;

  const updateResolution = (value: RevenueContextType['resolution']): void => {
    const today = dayjs().tz('UTC');

    // Default is for monthly
    let updatedStartDate = today.subtract(1, 'year').startOf('month').toDate();
    let updatedEndDate = today.startOf('month').toDate();
    let updatedGroupBy = groupBy;

    if (value === 'hourly') {
      updatedStartDate = today.subtract(24, 'hour').startOf('hour').toDate();
      updatedEndDate = today.startOf('hour').toDate();
      if (groupBy === 'market') updatedGroupBy = null;
    }

    if (value === 'daily') {
      updatedStartDate = today.subtract(2, 'week').startOf('day').toDate();
      updatedEndDate = today.startOf('day').toDate();
      if (groupBy === 'market') updatedGroupBy = null;
    }

    if (value === 'monthly' && groupBy === 'risk') updatedGroupBy = null;

    setStartDate?.(updatedStartDate);
    setEndDate?.(updatedEndDate);
    setResolution?.(value);
    setGroupBy?.(updatedGroupBy);
  };

  const onGroupByMonthChange = (value: boolean): void => {
    setGroupByMonth(value);

    setResolution?.('monthly');
    setGroupBy?.(null);
  };

  const handleDisplayModeChange = (displayMode: RevenueContextType['displayMode']) => {
    if (displayMode === 'table' && groupByMonth) onGroupByMonthChange(false);

    setDisplayMode?.(displayMode);
  };

  return (
    <>
      <FilterContainer size="datepicker">
        <DateSelector />
      </FilterContainer>

      <Restricted permissions={['display:detailed_revenue']}>
        <FilterContainer size="fit">
          <RadioButtons
            options={resolutionOptions}
            selected={resolution}
            onChange={updateResolution}
            disabled={groupByMonth}
          />
        </FilterContainer>
      </Restricted>
      {canPickPlayers && (
        <FilterContainer size="fit">
          <Select
            placeholder="monitoring.installation.revenue.market_players"
            options={PlayerOptions}
            selectedOption={selectedPlayers?.[0] || defaultSelectedPlayer?.id || null}
            onChange={(option) => setSelectedPlayers?.(option?.value ? [option.value] : [])}
          />
        </FilterContainer>
      )}
      <FilterContainer size="fit">
        <Select
          placeholder="monitoring.installation.revenue.group_by.placeholder"
          options={categorizationOptions}
          selectedOption={groupBy}
          onChange={(option) => setGroupBy?.(option?.value ?? undefined)}
          classNamePrefix="group-by-select"
          isClearable
          isDisabled={groupByMonth}
        />
      </FilterContainer>
      {groupBy === 'installation' && (
        <FilterContainer size="fit">
          <Input
            min={0}
            max={50}
            type="number"
            value={topInstallationsLimit || ''}
            onChange={(e) => setTopInstallationsLimit?.(+e.target.value || null)}
            autoComplete="off"
            tooltip="monitoring.installation.revenue.installation_limit.tooltip"
          />
        </FilterContainer>
      )}

      <FilterContainer size="fit">
        <Select
          options={(currencies ?? []).map((currency) => ({ label: currency, value: currency }))}
          selectedOption={selectedCurrency}
          onChange={(option) => setSelectedCurrency?.(option?.value ?? undefined)}
        />
      </FilterContainer>

      <FilterContainer size="fit">
        <RadioButtons options={chartTableOptions} selected={displayMode} onChange={handleDisplayModeChange} />
      </FilterContainer>

      {displayMode === 'chart' && (
        <FilterContainer size="fit">
          <Toggle
            label="monitoring.installation.revenue.group_month"
            onChange={onGroupByMonthChange}
            value={groupByMonth}
          />
        </FilterContainer>
      )}
    </>
  );
};
