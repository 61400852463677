import React, { useState, useEffect } from 'react';
import { Alert } from '@GDM/Alert';
import { Input } from '@GDM/forms';
import { useUploadCsv } from '@pages/Simulations/Simulation/utils/useUploadCsv';

export const CsvFileInput = ({ simulationRef }: { simulationRef: string }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [invalidFile, setInvalidFile] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const request = useUploadCsv(simulationRef);

  useEffect(() => {
    if (request.loaded) setIsFileUploaded(true);
  }, [request.loaded]);

  const sendFile = () => {
    if (selectedFile == undefined) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    request.upload(formData);
  };

  const handleFileChange = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files) return;

    const cancel = !files.length;

    if (cancel) return;

    const file = files[0];

    if (file['type'] === 'text/csv') {
      setSelectedFile(file);
      setInvalidFile(false);
    } else {
      setInvalidFile(true);
    }
  };

  return (
    <div>
      <Input
        label="pricing.project.insert_csv_file"
        id="csv-file-pricing"
        full
        type="file"
        onChange={handleFileChange}
        placeholder="pricing.project.choose_loadcurve_csv_file"
        disabled={isFileUploaded}
        errorMessage={invalidFile ? 'pricing.project.only_csv_allowed' : null}
        suffixButtonProps={{
          variant: 'primary-2',
          onClick: sendFile,
          disabled: selectedFile === undefined || isFileUploaded,
          isLoading: request.loading,
          text: 'pricing.project.send_file',
        }}
      />

      {isFileUploaded && <Alert full variant="success" label="pricing.project.file_uploaded_successfully" />}
    </div>
  );
};
