import React, { useMemo } from 'react';
import { Table, TableBody, TableHead } from '@GDM/Table';
import { Text } from '@GDM/Text';
import useTranslation from '@hooks/useTranslation';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { IndexData } from '@utils/types/installation_index';
import dayjs from 'dayjs';
import { Line } from '../index-tab.definitions';
import { indexTableColumns } from './indexTableColumns';

export const IndexDeltaTable = ({ indexData, periods }: { indexData: IndexData; periods: string[] }) => {
  const { t } = useTranslation();
  const columns = useMemo(() => indexTableColumns(periods, false), [periods]);

  const data: Line[] = useMemo(() => {
    const _data = [];

    if (indexData.length > 2) {
      const line: Line = {};
      const firstIndex = indexData[0];
      const lastIndex = indexData[indexData.length - 1];

      periods.forEach((period, index) => {
        line[period] = lastIndex[index + 1] - firstIndex[index + 1];
      });

      _data.push(line);
    }

    return _data;
  }, [indexData, periods]);

  const dateRange = useMemo(() => {
    if (indexData.length > 2) {
      const firstIndex = indexData[0];
      const lastIndex = indexData[indexData.length - 1];

      return {
        start: dayjs(firstIndex[0] * 1000).format('YYYY-MM-DD HH:mm'),
        end: dayjs(lastIndex[0] * 1000).format('YYYY-MM-DD HH:mm'),
      };
    }

    return null;
  }, [indexData]);

  const table = useReactTable({
    columns,
    data,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {dateRange && (
        <Text
          icon="Info"
          className="mt-4 mb-2"
          type="normal"
          text={t('monitoring.installation.index_delta', { start_date: dateRange.start, end_date: dateRange.end })}
        />
      )}

      <Table data-cy="no-index-data" className="mb-4">
        <TableHead table={table} />
        <TableBody table={table} />
      </Table>
    </>
  );
};
