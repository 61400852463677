import { useRequest } from '@hooks/useRequest';
import { all_v2_installations_path } from '@utils/routes';
import { Installation, InstallationWithMeterInfo } from '@utils/types/installation';

export type Options = {
  type: 'all' | 'lead' | 'operational' | 'operational_with_draft' | 'with_simulations';
  includeInactive?: boolean;
  withMeterInfo?: boolean;
};

/**
 * @param options.type
 * `all` = All Installations, `lead` = Installation not managed on a day to day basis, `operational` = Monitoring installations, `operational_with_draft` = same as `operational` but includes draft installations, `with_simulations` = Installations including pricing simulations
 */
export function useInstallations<T extends Options>(options?: T) {
  const req = useRequest<(T['withMeterInfo'] extends true ? InstallationWithMeterInfo : Installation)[]>(
    all_v2_installations_path({
      type: options?.type || 'all',
      ...(options?.includeInactive ? { include_inactive: true } : {}),
      ...(options?.withMeterInfo ? { with_meter_info: true } : {}),
    }),
    'GET',
  );

  return req;
}
