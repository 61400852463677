import { ContractType } from '@utils/types/contract/unions';

export const ENERGY_PRODUCTION_SUBPERIOD_CONTRACT_TYPES: ContractType[] = [
  'ContractGo',
  'ContractGc',
  'ContractGo-LIGHT',
  'ContractSoa-LIGHT',
  'ContractCapaProxy',
  'ContractCr',
  'ContractCapa',
  'ContractSoa',
  'ContractCppa',
  'ContractSupplyCppa',
  'ContractSupplyUppa',
];

export const CONTRACT_TYPES_WITH_AGGREGATION_FEES: ContractType[] = [
  'ContractGo-LIGHT',
  'ContractSoa-LIGHT',
  'ContractCapaProxy',
  'ContractCr',
  'ContractSoa',
  'ContractCppa',
  'ContractSupplyCppa',
  'ContractSupplyUppa',
];

export const CONTRACT_TYPES_WITH_INDEX_FORMULA: ContractType[] = [
  'ContractGo',
  'ContractGc',
  'ContractSoa',
  'ContractCppa',
  'ContractCapaProxy',
  'ContractSupplyCppa',
  'ContractSupplyUppa',
];

export const CONTRACT_TYPES_WITH_NEGATIVE_PRICES: ContractType[] = [
  'ContractCr',
  'ContractSoa',
  'ContractCppa',
  'ContractCapaProxy',
  'ContractSupplyCppa',
  'ContractSupplyUppa',
];

export const CONTRACT_TYPES_WITH_PRICE_RULES: ContractType[] = [
  'ContractCapaProxy',
  'ContractCr',
  'ContractSoa',
  'ContractCppa',
  'ContractSupplyCppa',
  'ContractSupplyUppa',
];

export const CONTRACT_TYPES_WITH_HEDGE_PROFILE: ContractType[] = [
  'ContractSoa',
  'ContractCppa',
  'ContractCapaProxy',
  'ContractSupplyCppa',
  'ContractSupplyUppa',
];

export const CONTRACT_TYPES_WITH_DOUBLE_AGGREG_FEES: ContractType[] = [
  'ContractCr',
  'ContractSoa',
  'ContractCppa',
  'ContractCapaProxy',
  'ContractSupplyCppa',
  'ContractSupplyUppa',
];
