import React from 'react';
import ControlledInput, { ControlledInputProps } from '@components/FormInputs/ControlledInput';
import { HelpBoxProps } from '@GDM/HelpBox/HelpBox';
import { useHelpBoxContext } from '@pages/Contracts/Contract/contexts/helpBoxContext';
import { useFieldRegistrationTracker } from '@pages/Contracts/Contract/contexts/registeredFields';
import { FieldPath, FieldValues } from 'react-hook-form';

export const NumericInput = <TFieldValues extends FieldValues, TFieldName extends FieldPath<TFieldValues>>({
  helpBox,
  ...props
}: Omit<ControlledInputProps<TFieldValues, TFieldName>, 'shouldUnregister' | 'type' | 'onFocus' | 'onBlur'> & {
  helpBox?: Pick<HelpBoxProps, 'text' | 'title'>;
}) => {
  useFieldRegistrationTracker(props);
  const { handleFocus, handleBlur } = useHelpBoxContext();

  return (
    <ControlledInput
      size="lg"
      autoComplete="off"
      full
      step="any"
      {...props}
      onFocus={helpBox && handleFocus(helpBox)}
      onBlur={handleBlur}
      shouldUnregister
      type="number"
    />
  );
};
