import { useCallback, useEffect } from 'react';
import { getRequiredFields, useProgressStepper } from '@GDM/forms';
import { DeepPartial, FieldValues, useFormContext } from 'react-hook-form';
import { useInternalFormContext } from '../Form/Form.context';
import { getAvailableFields } from '../utils/getAvailableFields';
import { updateFormProgress } from '../utils/updateFormProgress';

export const useFormProgress = () => {
  const { watch, getValues } = useFormContext();
  const { markAsValid, updateProgress } = useProgressStepper();
  const { commonFields, steps, fieldSections, progressCallback, country } = useInternalFormContext();

  const updateSectionProgress = useCallback(
    (values: DeepPartial<FieldValues>) => {
      const availableFields = getAvailableFields(commonFields, values, country);
      const requiredFields = getRequiredFields(availableFields, values);

      updateFormProgress(steps, requiredFields, fieldSections, updateProgress, markAsValid)(values);
      progressCallback?.(markAsValid, updateProgress);
    },
    [progressCallback, steps, commonFields, fieldSections, markAsValid, updateProgress, country],
  );
  useEffect(() => {
    // Update the progress when the page/form is first loaded
    updateSectionProgress(getValues());

    const subscription = watch((values) => {
      // Update the progress when the form is updated
      updateSectionProgress(values);
    });

    return () => subscription.unsubscribe();
  }, [updateSectionProgress, getValues, watch]);
};
