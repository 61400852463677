import { TranslateFn } from '@hooks/useTranslation';
import { CountryCode } from '@utils/types/countries';
import { CSVExportColumn, RevenueContextType } from '../revenue.types';
import { getGroupLabel } from './getGroupLabel';
import { isGroupedData } from './type-helpers';

export const getColumnsFromRevenue = (
  revenue: RevenueContextType['revenue'],
  overview: RevenueContextType['overview'],
  t: TranslateFn,
  countryCode: CountryCode,
  currencyCode: string,
): CSVExportColumn[] => {
  if (!revenue) return [];

  const concatenatedColumns: { label: string; key?: string }[] = [{ label: t('common.period'), key: 'period' }];

  overview?.forEach((item) => {
    const label = t(`monitoring.installation.revenue.${item.field}`);

    if (!concatenatedColumns.find((c) => c.key === item.field)) {
      concatenatedColumns.push({ label: `${label} (${item.unit})`, key: item.field });
    }
  });

  if (isGroupedData(revenue)) {
    revenue.forEach((item) => {
      const label = t(getGroupLabel(item.group, item.label, countryCode));

      if (!concatenatedColumns.find((c) => c.label === item.label)) {
        concatenatedColumns.push({ label: `${label} (${currencyCode})`, key: item.label });
      }

      if (item.sub_groups) {
        item.sub_groups.forEach((group) => {
          const key = `${item.label}.${group.label}`;
          const groupLabel = t(`monitoring.installation.revenue.${group.label.toLocaleLowerCase()}`);
          concatenatedColumns.push({ label: `${label} - ${groupLabel} (${currencyCode})`, key });
        });
      }
    });
  }

  return concatenatedColumns;
};
