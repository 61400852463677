import React from 'react';
import { DateStatusFilter } from '@components/Contracts/ContractFilters/DateStatusFilter';
import { useConsumeContracts } from '@context/contracts.context';
import { Filters, FilterContainer } from '@GDM/Filters';
import { FilterType } from '@GDM/Filters/filters.types';
import type Book from '@utils/types/book';
import { Contract, ContractType, type Classification } from '@utils/types/contract';
import { ContractSubType } from '@utils/types/contract_sub_type';
import { CountryCode } from '@utils/types/countries';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { BookFilters } from './BookFilters';
import { BuyerFilter } from './BuyerFilter';
import { BuySellFilter } from './BuySellFilter';
import { CardIFilter } from './CardIFilter';
import { ClassificationFilter } from './ClassificationFilter';
import { ContractNbFilter } from './ContractNbFilter';
import { ContractSubTypeFilters } from './ContractSubTypeFilters';
import { ContractTypeFilter } from './ContractTypeFilter';
import { CountryFilter } from './CountryFilter';
import { DateFilter } from './DateFilter';
import { DeliveryProfileFilter } from './DeliveryProfileFilter';
import { InstallationFilter } from './InstallationFilter';
import { MarketFutureFilter } from './MarketFutureFilter';
import { StatusFilter } from './StatusFilter';

export type RemitSentState = 'all' | 'sent' | 'not_sent';

export type ContractsFiltersType = FilterType<{
  contract_type: ContractType[];
  buyer_id: string[];
  market_future_id: string | null;
  installation_country: CountryCode | null;
  installation_name: string[];
  sent_to_remit: RemitSentState | null;
  books: Book[];
  sub_type: ContractSubType | '' | null;
  card_i: string[];
  contract_nb: string[];
  status: Contract['status'] | null;
  date_status: 'ongoing' | 'expired' | 'not_started' | null;
  dates: [Date | null, Date | null];
  direction: Contract['direction'] | null;
  classification: Classification | null;
  delivery_profile: Contract['delivery_profile'] | null;
}>;

export const contractFiltersDefaultValues: ContractsFiltersType = {
  contract_type: [],
  buyer_id: [],
  market_future_id: null,
  installation_country: null,
  installation_name: [],
  contract_nb: [],
  sent_to_remit: null,
  books: [],
  card_i: [],
  sub_type: null,
  status: null,
  dates: [dayjs().startOf('year').toDate(), dayjs().add(1, 'year').startOf('year').toDate()],
  date_status: null,
  last_selected_filter: null,
  direction: null,
  classification: null,
  delivery_profile: null,
};

export const ContractFilters = ({ showBookFilter }: { showBookFilter?: boolean }) => {
  const form = useForm<ContractsFiltersType>({ defaultValues: contractFiltersDefaultValues });
  const { filterContracts } = useConsumeContracts();

  const handleReset = () => {
    form.reset(contractFiltersDefaultValues);
    filterContracts(contractFiltersDefaultValues);
  };

  return (
    <Filters form={form} className="p-0" onClear={handleReset} disableClearButton={!form.formState.isDirty}>
      <FilterContainer size="fit">
        <DateFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <DateStatusFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <InstallationFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <CountryFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <ContractTypeFilter />
      </FilterContainer>
      {showBookFilter && (
        <FilterContainer size="select">
          <BookFilters />
        </FilterContainer>
      )}
      <FilterContainer size="select">
        <BuyerFilter />
      </FilterContainer>
      <FilterContainer>
        <MarketFutureFilter />
      </FilterContainer>
      <FilterContainer>
        <ContractSubTypeFilters />
      </FilterContainer>
      <FilterContainer size="select">
        <ContractNbFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <CardIFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <StatusFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <BuySellFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <ClassificationFilter />
      </FilterContainer>
      <FilterContainer size="select">
        <DeliveryProfileFilter />
      </FilterContainer>
    </Filters>
  );
};
