import React from 'react';
import { Badge } from '@GDM/Badge';
import { Mandate } from '@utils/types/mandate';

export const MandateStatusBadge = ({ status }: { status: Mandate['status'] }) => {
  switch (status) {
    case 'signed':
      return <Badge variant="primary-1" label="common.signed" />;
    case 'no_signature':
      return <Badge variant="danger" label="common.not_signed" />;
    case 'expired':
      return <Badge variant="default" label="common.expired" />;
    case 'to_renew':
      return <Badge variant="default" label="common.to_renew" />;
    default:
      return <Badge variant="danger" label="common.unknown" />;
  }
};
