import dayjs from 'dayjs';
import { SimulationForm, WindConstraintForm } from '../utils/simulations.types';

export const WIND_CONSTRAINT_DEFAULT_VALUES: WindConstraintForm = {
  debut_mois_contrainte: dayjs().set('month', 0).startOf('day').toDate(),
  fin_mois_contrainte: dayjs().set('month', 11).startOf('day').toDate(),
  start: dayjs().startOf('day').toDate(),
  finish: dayjs().startOf('day').toDate(),
  maximum_power: null,
};

export const SIMULATION_FORM_DEFAULT_VALUES: SimulationForm = {
  start_date: dayjs().startOf('day').subtract(3, 'y').toDate(),
  end_date: dayjs().startOf('day').toDate(),
  neg_hours_limit: '0',
  no_strike: 'no_strike',
  fixed_stop_strike: null,
  dynamic_stop_strike: null,
  full_merchant: false,
  perturbate_prices: false,
  // solar
  orientation: null,
  inclination: null,
  tracker: false,
  total_power_solar: null,
  maxangle_tracker_morning: null,
  maxangle_tracker_evening: null,
  // wind
  height: null,
  nb_turb: null,
  turbine_id: null,
  total_power_wind: null,
  ppa_wind_bounds_attributes: [],
  // installations info
  installation_attributes: {
    id: null,
    name: '',
    longitude: null,
    latitude: null,
    energy: null,
  },
};
