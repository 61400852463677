import React from 'react';
import { Input, useInternalFormContext } from '@GDM/forms';
import { useInstallationField } from '@pages/Installations/Installation/hooks/useInstallationField';
import { useInstallationForm } from '@pages/Installations/Installation/hooks/useInstallationForm';
import { validateSiren } from '@utils/validators/siren';
import { Controller } from 'react-hook-form';
import MarketPlayerConfig from '../../../../../../../../../../config/data/market_players.yml';

const config = MarketPlayerConfig['shared'];

export const CompanyNumberInput = () => {
  const form = useInstallationForm();
  const { country } = useInternalFormContext();
  const { rules, disabled } = useInstallationField('company_number');

  const label = (country && config[country]?.company_number_name) || 'owner.company_number';

  const validate = (siren: string) => {
    if (country !== 'FR' || !siren) return true;

    return validateSiren(siren);
  };

  return (
    <Controller
      control={form.control}
      name="company_number"
      rules={{ ...rules, validate }}
      render={({ field, fieldState }) => (
        <Input
          {...field}
          type="text"
          label={label}
          size="lg"
          full
          disabled={disabled}
          data-cy="installation-owner"
          hasError={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      )}
    />
  );
};
