import { ContractsFiltersType } from '@components/Contracts/ContractFilters';
import { Contract } from '@utils/types/contract';
import type { DeepPartial } from 'react-hook-form';

export default function matchDeliveryProfile(contract: Contract, filters: DeepPartial<ContractsFiltersType>): boolean {
  if (!filters.delivery_profile || filters.delivery_profile === contract.delivery_profile) {
    return true;
  }

  return false;
}
