import React, { useEffect } from 'react';
import { useUser } from '@context/User.context';
import { Button } from '@GDM/Button/Button';
import { Section, sectionStyles } from '@GDM/forms';
import useTranslation from '@hooks/useTranslation';
import { formatMarketFutureDate } from '@utils/date/formatMarketFutureDate';
import { formatSystemDate } from '@utils/formatters';
import classNames from 'classnames';
import { useFieldArray } from 'react-hook-form';
import { ContractFormSectionProps, FormSectionLayout } from '../../components/FormSection/FormSectionLayout';
import { SectionHeader } from '../../components/SectionHeader';
import { SubPeriod } from './components/SubPeriod/SubPeriod';
import { SubPeriodActions } from './components/SubPeriodActions/SubPeriodActions';
import { SubPeriodHeader } from './components/SubPeriodHeader/SubPeriodHeader';
import { useDatePeriodIntreaction } from './hooks/useDatePeriodIntreaction';
import { useDateValidation } from './hooks/useDateValidation';
import { useInsertRemovePeriod } from './hooks/useInsertRemovePeriod';
import { useSubPeriodsToggle } from './hooks/useSubPeriodsToggle';
import styles from './subperiods.module.scss';

export const SubPeriods = ({ readOnly, globals, title, queries, form }: ContractFormSectionProps) => {
  const { marketFutures } = queries;
  const { control, trigger, getFieldState, watch } = form;
  const [contractType, startDate, endDate] = watch(['type', 'start_date', 'end_date']);

  const subPeriods = useFieldArray({
    control,
    name: 'contract_sub_periods_attributes',
    keyName: 'field_internal_id',
    rules: { required: true },
  });

  useEffect(() => {
    if (subPeriods.fields.length !== 0) return;
    subPeriods.append({
      id: null,
      dates: [startDate, endDate],
      display_price: null,
      aggreg_fees: [{ value: null, type: 'fixed' }],
      price_model: null,
      price_model_attributes: {},
      price_rule: null,
      price_unit: null,
      multiplier: null,
      volume_ratio: null,
    });
  }, [endDate, startDate, subPeriods]);

  const { collapsedIds, collapseAll, toggleSubPeriod, unCollapseAll } = useSubPeriodsToggle({
    readOnly,
    subPeriods,
  });

  const { removePeriod, insertPeriodBefore } = useInsertRemovePeriod({
    startDate,
    endDate,
    subPeriods,
    trigger,
    contractType,
  });

  const { t } = useTranslation();
  const { locale } = useUser();

  const getDatesValidation = useDateValidation(form, startDate, endDate);

  const { onDatePeriodClick, preSelectedPeriod } = useDatePeriodIntreaction({
    subPeriods,
    trigger,
    marketFutures,
    contractType,
  });

  const TITLE_MAP = {
    testing: 'sales_management.phases.testing',
    intermediate: 'sales_management.phases.intermediate',
  };

  return (
    <FormSectionLayout
      title={title}
      headerActions={
        <SectionHeader>
          <Button
            tooltip="common.un_collapse_all"
            icon="ChevronsDown"
            floating
            onClick={unCollapseAll}
            disabled={collapsedIds.size === 0}
          />
          <Button
            tooltip="common.collapse_all"
            className="ml-1"
            icon="ChevronsUp"
            floating
            onClick={collapseAll}
            disabled={collapsedIds.size === subPeriods.fields.length}
          />
        </SectionHeader>
      }
      body={
        <Section data-cy="sub-periods">
          <div className={classNames(styles.wrapper, 'p-3')}>
            {subPeriods?.fields?.map((period, index) => {
              const phaseType = period.phase_type;
              const title =
                (!!phaseType && TITLE_MAP[phaseType]) || `${t('sales_management.phases.period')} ${index + 1}`;
              const startDate = period.dates?.[0];
              const endDate = period.dates?.[1];
              const formattedDates = `${formatSystemDate(startDate)} / ${formatSystemDate(endDate)}`;
              const error = getFieldState(`contract_sub_periods_attributes.${index}`)?.error;
              const marketFutureName = formatMarketFutureDate(period.market_future, locale);
              const isCollapsed = collapsedIds.has(period.field_internal_id);
              const datesValidation = getDatesValidation(index);

              return (
                <SubPeriod
                  key={period.field_internal_id}
                  index={index}
                  header={
                    <SubPeriodHeader
                      title={t(title)}
                      badgeLabel={marketFutureName}
                      datesLabel={formattedDates}
                      isCollapsed={isCollapsed}
                      toggle={() => toggleSubPeriod(period.field_internal_id)}
                      remove={() => removePeriod(index)}
                      insertBefore={() => insertPeriodBefore(index)}
                      removeIsDisabled={subPeriods.fields.length <= 1}
                      insertBeforeIsDisabled={phaseType === 'testing'}
                      error={error}
                      readOnly={readOnly}
                    />
                  }
                  queries={queries}
                  readOnly={readOnly}
                  periodId={period.field_internal_id}
                  onDatePeriodClick={onDatePeriodClick}
                  form={form}
                  globals={globals}
                  isCollapsed={isCollapsed}
                  datesValidation={datesValidation}
                  preSelectedPeriod={preSelectedPeriod}
                />
              );
            })}
          </div>

          {!readOnly && (
            <div className={sectionStyles['section-footer']}>
              <SubPeriodActions
                form={form}
                subPeriods={subPeriods}
                contractType={contractType}
                contractStartDate={startDate}
                contractEndDate={endDate}
              />
            </div>
          )}
        </Section>
      }
    />
  );
};
