import { useMemo } from 'react';
import {
  RiskReportData,
  RiskReportDataWithOneFocus,
  RiskFocusData,
  RiskReportMetricValues,
  RiskReportDataWithTwoFocus,
} from '@pages/InternalRiskAnalysis/types';

export const useFormatReportData = (rawData: RiskReportData, isReportNested: boolean, isSplitByMetric: boolean) => {
  return useMemo(
    () => (isSplitByMetric ? createMetricBasedReportData(rawData, isReportNested) : rawData),
    [isReportNested, isSplitByMetric, rawData],
  );
};

const createMetricBasedReportData = (data: RiskReportData, isReportNested: boolean) => {
  if (!isReportNested) {
    const metricBasedReport: RiskReportDataWithOneFocus = {};
    Object.entries(data).forEach(([focus, metricValuesForEachDates]: [string, RiskFocusData]) => {
      Object.entries(metricValuesForEachDates).forEach(([date, metricValues]: [string, RiskReportMetricValues]) => {
        Object.entries(metricValues).forEach(([metric, value]) => {
          if (!metricBasedReport[metric]) metricBasedReport[metric] = {};
          if (!metricBasedReport[metric][date]) metricBasedReport[metric][date] = {};
          metricBasedReport[metric][date][focus] = value;
        });
      });
    });

    return metricBasedReport;
  }

  const nestedMetricBasedReport: RiskReportDataWithTwoFocus = {};
  Object.entries(data).forEach(([focus1, dataAlongSecondaryFocus]: [string, RiskReportDataWithOneFocus]) => {
    Object.entries(dataAlongSecondaryFocus).forEach(([focus2, metricValuesForEachDates]: [string, RiskFocusData]) => {
      Object.entries(metricValuesForEachDates).forEach(([date, metricValues]: [string, RiskReportMetricValues]) => {
        Object.entries(metricValues).forEach(([metric, value]) => {
          if (!nestedMetricBasedReport[metric]) nestedMetricBasedReport[metric] = {};
          if (!nestedMetricBasedReport[metric][focus1]) nestedMetricBasedReport[metric][focus1] = {};
          if (!nestedMetricBasedReport[metric][focus1][date]) nestedMetricBasedReport[metric][focus1][date] = {};

          nestedMetricBasedReport[metric][focus1][date][focus2] = value;
        });
      });
    });
  });

  return nestedMetricBasedReport;
};
