import dayjs from 'dayjs';
import { SimulationBody, SimulationForm, WindConstraintMonth } from './simulations.types';

/**
 * backend expect the values as french month names without accents
 */
const CONSTRAINT_MONTHS: Array<WindConstraintMonth> = [
  'janvier',
  'fevrier',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'aout',
  'septembre',
  'octobre',
  'novembre',
  'decembre',
];

export const serializeSimulationBody = (simulation: SimulationForm): SimulationBody => {
  return {
    ppa_simulation: {
      ...simulation,
      start_date: dayjs(simulation.start_date).utc(true).startOf('day').toDate(),
      end_date: dayjs(simulation.end_date).utc(true).startOf('day').toDate(),
      ppa_wind_bounds_attributes: simulation.ppa_wind_bounds_attributes.map((bound) => ({
        ...bound,
        start: dayjs(bound.start).utc(true).toDate(),
        finish: dayjs(bound.finish).utc(true).toDate(),
        debut_mois_contrainte: CONSTRAINT_MONTHS[dayjs(bound.debut_mois_contrainte).utc(true).startOf('day').month()],
        fin_mois_contrainte: CONSTRAINT_MONTHS[dayjs(bound.fin_mois_contrainte).utc(true).startOf('day').month()],
      })),
    },
  };
};
