import React from 'react';
import { Checkbox, CheckboxProps } from '@GDM/forms/Checkbox';
import useTranslation from '@hooks/useTranslation';

const formatLabel = (selectedLength: number, total?: number) => {
  if (!selectedLength) return '';

  if (!total) return `(${selectedLength})`;

  return `(${selectedLength} / ${total})`;
};

export const SelectAllCheckBox = ({
  selectedLength,
  total,
  ...rest
}: CheckboxProps & {
  selectedLength: number;
  total?: number;
}) => {
  const { t } = useTranslation();

  const label = t('common.select_all') + ' ' + formatLabel(selectedLength, total);

  return <Checkbox {...rest} label={label} />;
};
