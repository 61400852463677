import { DataPeriod } from '@utils/types/dataPeriod';
import { PowerMinMaxAverage } from '../production.types';
import { ComputeTimeSeriesAggregationProps, computeTimeSeriesAggregation } from './computeTimeSeriesAggregation';

export const computePowerMinMaxAverage = <T>({
  dataPeriod,
  ...aggregProps
}: ComputeTimeSeriesAggregationProps<T> & {
  dataPeriod: DataPeriod;
}): PowerMinMaxAverage => {
  const timeSeriesAggregation = computeTimeSeriesAggregation(aggregProps);
  // minute based periods come with power (kW) timeseries
  if (dataPeriod === 'minutes') {
    // A simple average of the power data points gives us the average power
    const average = timeSeriesAggregation.sum / timeSeriesAggregation.count;

    const millisecondsInAnHour = 1000 * 60 * 60;
    const elapsedTime = (timeSeriesAggregation.end - timeSeriesAggregation.start) / millisecondsInAnHour;

    return {
      min: timeSeriesAggregation.min,
      max: timeSeriesAggregation.max,
      energy: average * elapsedTime,
      average: average,
    };
  }

  // No extremum displayed for daily and monthly power period
  return {
    energy: timeSeriesAggregation.sum,
    // on monthly and daily periods data points are begining of a given period
    average: timeSeriesAggregation.sum / timeSeriesAggregation.hoursCount[dataPeriod],
  };
};
