import dayjs from 'dayjs';

/**
 * Formats dates to be used by the system and not the end user.
 * It will take any `Date` or date-like string and format it to _e.g_ `2022-10-29`.
 * Not providing an argument will use today as a date.
 */
export const formatSystemDate = (input?: string | number | Date | null): string => {
  return dayjs(input).format('YYYY-MM-DD');
};
