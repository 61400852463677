import { createContext, useContext } from 'react';
import { Installation, InstallationWithMeterInfo } from '@utils/types/installation';

export type InstallationsContext<T extends Installation | InstallationWithMeterInfo = Installation> = {
  installations: Array<T> | null;
  isLoading?: boolean;
};

export const installationsContext = createContext<InstallationsContext>({ installations: [] });

export const useInstallationsContext = <
  T extends Installation | InstallationWithMeterInfo = Installation,
>(): InstallationsContext<T> => useContext(installationsContext) as InstallationsContext<T>;
